import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  createSensor,
  fetchFloorplanSensorGroupStatus,
  ICreateSensor,
  IFetchFloorplanSensors,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import MobileSensor from './MobileSensor';
import { getFloorplanSensorGroupWithStatus } from '@selectors/sensors';

interface IProps {
  floorplanId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  error: state.sensors.form.error,
  loading: state.sensors.form.loading,
  sensorId: state.sensors.form.sensorId,
  activeSensorGroup: getFloorplanSensorGroupWithStatus(state, props.floorplanId),
  formLoading: (state.sensors.groupsWithStatus[props.floorplanId] || {}).loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createSensor: (properties: ICreateSensor) =>
    dispatch(createSensor.request(properties)),
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => {
    dispatch(fetchFloorplanSensorGroupStatus.request(properties));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileSensor);
