import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import React, { FunctionComponent } from 'react';

import './index.css';

interface IProps extends IconButtonProps {
  loading: boolean;
  children: React.ReactNode;
  circularProgressColor?: CircularProgressProps['color'];
}

const LoadingIconButton: FunctionComponent<IProps> = (properties: IProps) => {
  const {
    children,
    circularProgressColor = 'primary',
    color = 'default',
    disabled = false,
    loading,
    onClick = () => {},
    type = 'button',
  } = properties;

  return (
    <div className="loading-button-wrapper">
      <IconButton
        color={color}
        disabled={disabled || loading}
        onClick={onClick}
        type={type}
      >
        {children}
      </IconButton>
      {loading && (
        <CircularProgress
          className="loading-button"
          color={circularProgressColor}
          size={24}
        />
      )}
    </div>
  );
};

export default LoadingIconButton;
