import { createCanvas } from 'canvas';
import { Color, Path, Point, Raster } from 'paper';
import { Component } from 'react';

import { IToolEvent } from '../../../types';
import Paper from '../../Paper';
import MapImages from '../../MapImages';

interface IProps {
  color: boolean;
  onChange: (scale: number) => void;
  paper: Paper;
  mapImages: MapImages;
}

class Scaling extends Component<IProps> {
  private cursor: [
    (paper.Path.Circle | paper.Raster)?,
    (paper.Path.Circle | paper.Raster)?,
    (paper.Path.Circle | paper.Raster)?
  ] = [];

  private feature?: paper.Path.Line;

  private scalingClose = false;

  constructor(props: IProps) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  public componentDidMount() {
    const { paper } = this.props;
    const { tool } = paper;

    tool.on('mousedown', this.handleMouseDown);
    tool.on('mousemove', this.handleMouseMove);
  }

  public componentWillUnmount() {
    const { paper } = this.props;
    const { tool } = paper;

    tool.removeListener('mousedown', this.handleMouseDown);
    tool.removeListener('mousemove', this.handleMouseMove);
  }

  public handleMouseDown(event: IToolEvent) {
    const { onChange } = this.props;

    if (event.event.ctrlKey === true && event.event.shiftKey === false) {
      if (!this.feature || this.scalingClose === true) {
        if (this.feature) {
          this.feature.remove();
        }
        this.scalingClose = false;

        this.feature = new Path.Line(
          event.downPoint || ({} as paper.Point),
          event.downPoint || ({} as paper.Point)
        );
        this.feature.strokeColor = new Color('DodgerBlue');
        this.feature.strokeWidth = 12;
      } else {
        this.scalingClose = true;

        const startPoint = this.feature.getPointAt(0) || ({} as paper.Point);
        const endPoint = event.downPoint || ({} as paper.Point);
        this.feature.remove();
        this.feature = new Path.Line(startPoint, endPoint);
        this.feature.strokeColor = new Color('DodgerBlue');
        this.feature.strokeWidth = 12;

        const a = (startPoint.x || 0) - (endPoint.x || 0);
        const b = (startPoint.y || 0) - (endPoint.y || 0);

        onChange(Math.sqrt(a * a + b * b));
      }
    }
  }

  public handleMouseMove(event: IToolEvent) {
    const { color, paper, mapImages } = this.props;

    const point = event.point || ({} as paper.Point);

    if (event.event.ctrlKey === true) {
      document.body.style.cursor = 'none';

      if (
        this.cursor.length > 1 &&
        this.cursor[0] !== undefined &&
        this.cursor[1] !== undefined
      ) {
        if (this.cursor[2]) {
          this.cursor[2].remove();
        }

        if (
          mapImages.backgroundImageObj !== undefined &&
          mapImages.backgroundImageBWObj !== undefined
        ) {
          const scale = 600 / paper.getZoom() / 300;

          const canvas = createCanvas(32 * scale, 32 * scale);
          const ctx = canvas.getContext('2d');

          ctx.beginPath();
          ctx.arc(16 * scale, 16 * scale, 16 * scale, 0, Math.PI * 2, true);
          ctx.closePath();
          ctx.clip();

          const clipSize = 8 * scale;
          ctx.drawImage((
            color === true
              ? mapImages.backgroundImageObj
              : mapImages.backgroundImageBWObj
            ) as any,
            (point.x || 0) - clipSize / 2,
            (point.y || 0) - clipSize / 2,
            clipSize,
            clipSize,
            0,
            0,
            32 * scale,
            32 * scale
          );

          this.cursor[2] = new Raster({
            position: event.point,
            source: canvas.toDataURL(),
          });

          if (this.cursor[0] !== undefined && this.cursor[1] !== undefined) {
            this.cursor[0]!.position = event.point;
            this.cursor[0]!.bringToFront();
            this.cursor[1]!.position = event.point;
            this.cursor[1]!.bringToFront();
            this.cursor[0]!.scale(
              2 / paper.getZoom() / (this.cursor[0].bounds.width / 2)
            );
            this.cursor[1]!.scale(
              32 / paper.getZoom() / (this.cursor[1].bounds.width / 2)
            );
            this.cursor[1]!.strokeWidth = 1 / paper.getZoom();
          }
        }
      } else {
        this.cursor = [];

        const mouseFeature1 = new Path.Circle(new Point(point), 3);
        mouseFeature1.fillColor = new Color('blue');
        mouseFeature1.bringToFront();

        const mouseFeature2 = new Path.Circle(new Point(point), 32);
        mouseFeature2.strokeColor = new Color('black');
        mouseFeature2.strokeWidth = 1;
        mouseFeature2.bringToFront();

        this.cursor.push(mouseFeature1);
        this.cursor.push(mouseFeature2);
      }

      if (this.feature && this.scalingClose === false) {
        const startPoint = this.feature.getPointAt(0);
        this.feature.remove();
        this.feature = new Path.Line(startPoint, point);
        this.feature.strokeColor = new Color('DodgerBlue');
        this.feature.strokeWidth = 7;
      }
    } else {
      document.body.style.cursor = 'inherit';

      while (this.cursor.length > 0) {
        const cursorFeature = this.cursor.pop();
        if (cursorFeature !== undefined) {
          cursorFeature.remove();
        }
      }
    }
  }

  public render() {
    return null;
  }
}

export default Scaling;
