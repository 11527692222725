import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  createAccount,
  closeAccountForm,
  fetchAvailablePermissions,
  ICreateAccount,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import {
  getAvailablePermissions,
  isAvailablePermissionsLoading,
} from '@selectors/accounts';
import AccountForm from './AccountForm';

const mapStateToProps = (state: IAppState) => ({
  availablePermissions: getAvailablePermissions(state.accounts),
  availablePermissionsLoading: isAvailablePermissionsLoading(state.accounts),
  error: state.accounts.form.error,
  loading: state.accounts.form.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeAccountForm: () => dispatch(closeAccountForm()),
  createAccount: (properties: ICreateAccount) =>
    dispatch(createAccount.request(properties)),
  fetchAvailablePermissions: () =>
    dispatch(fetchAvailablePermissions.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountForm);
