import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { ICreateZone, IUpdateZone } from '@actions/index';
import Zone from '@models/Zone';
import { getRandomColor } from '@dashboard_utils/index';
import ColorPicker, {
  longHexRegex,
  shortHexRegex,
} from '../../common/MaterialUIColorPicker/ColorPicker';
import { Coordinate2D } from '../../../utils';
import { getZoneTypeName } from './utils';

export interface IProps {
  coordinates: Coordinate2D[];
  data?: Zone;
  floorplanId: string;
  id?: string;
  createZone: (properties: ICreateZone) => void;
  intl: IntlShape;
  onClose?: () => void;
  updateZone: (properties: IUpdateZone) => void;
  zones: Zone[];
}

interface IState {
  id?: string;
  color: string;
  name: string;
  type: string;
}

const messages = defineMessages({
  colorErrorMsg: {
    defaultMessage: 'A colour must follow the #rrggbb format',
    id: 'dashboard.forms.zoneform.field_color_error',
  },
  colorLabel: {
    defaultMessage: 'Colour',
    id: 'dashboard.forms.zoneform.label_color',
  },
});

class ZoneForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { data } = this.props;

    this.state = {
      color: data && data.color ? data.color : getRandomColor(),
      id: data ? data.id : '',
      name: data ? data.name : '',
      type: data ? data.type : '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'name'>);
    };
  }

  public handleColorChange(color: string) {
    this.setState({
      color,
    });
  }

  public handleCancel() {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }

    this.reset();
  }

  public handleSubmit() {
    const { color, id, name, type } = this.state;
    const { onClose, coordinates, createZone, floorplanId, updateZone } =
      this.props;

    if (id !== '') {
      updateZone({
        id: id!,
        color,
        coordinates,
        name,
        type,
      });
    } else {
      createZone({
        color,
        coordinates,
        floorplanId,
        name,
        type,
      });
    }

    if (onClose) {
      onClose();
    }

    this.reset();
  }

  public reset() {
    this.setState({
      color: getRandomColor(),
      id: '',
      name: '',
      type: '',
    });
  }

  public render() {
    const { floorplanId, intl, zones } = this.props;
    const { id, color, name, type } = this.state;

    const uniqueName = !zones.find(
      (z) => z.name === name && z.floorplanId === floorplanId && z.id !== id
    );

    const nameError = name.length === 0 || uniqueName === false;
    const typeError = type.length === 0;
    const colorError = !(shortHexRegex.test(color) || longHexRegex.test(color));

    const buttonDisabled = nameError || typeError || colorError;

    return (
      <>
        <CardContent>
          <FormControl required fullWidth margin="normal">
            <InputLabel htmlFor="zone-form-name">
              <FormattedMessage
                id="dashboard.forms.zoneform.label_name"
                defaultMessage="Name"
              />
            </InputLabel>
            <Input
              id="zone-form-name"
              value={name}
              onChange={this.handleChange('name')}
            />
            {uniqueName === false ? (
              <FormHelperText error>
                <FormattedMessage
                  id="dashboard.forms.zoneform.field_name_unique_error"
                  defaultMessage={
                    "The name has to be unique. Can't have multiple zones with the same name."
                  }
                />
              </FormHelperText>
            ) : (
              <FormHelperText>
                <FormattedMessage
                  id="dashboard.forms.zoneform.field_name_helper"
                  defaultMessage="A name must be specified"
                />
              </FormHelperText>
            )}
          </FormControl>

          <FormControl required fullWidth margin="normal">
            <InputLabel htmlFor="zone-form-type">
              <FormattedMessage
                id="dashboard.forms.zoneform.label_type"
                defaultMessage="Type"
              />
            </InputLabel>
            <Select
              id="zone-form-type"
              value={type}
              onChange={this.handleChange('type')}
              input={<Input name="type" />}
            >
              <MenuItem key="racks" value="racks">
                {getZoneTypeName('racks')}
              </MenuItem>
              <MenuItem key="ground_pallets" value="ground_pallets">
                {getZoneTypeName('ground_pallets')}
              </MenuItem>
              <MenuItem key="charging" value="charging">
                {getZoneTypeName('charging')}
              </MenuItem>
              <MenuItem key="loading" value="loading">
                {getZoneTypeName('loading')}
              </MenuItem>
              <MenuItem key="entry_exit" value="entry_exit">
                {getZoneTypeName('entry_exit')}
              </MenuItem>
              <MenuItem key="entry" value="entry">
                {getZoneTypeName('entry')}
              </MenuItem>
              <MenuItem key="exit" value="exit">
                {getZoneTypeName('exit')}
              </MenuItem>
              <MenuItem key="mixing" value="mixing">
                {getZoneTypeName('mixing')}
              </MenuItem>
              <MenuItem key="other" value="other">
                {getZoneTypeName('other')}
              </MenuItem>
            </Select>
            <FormHelperText>
              <FormattedMessage
                id="dashboard.forms.zoneform.field_type_helper"
                defaultMessage="A type must be selected"
              />
            </FormHelperText>
          </FormControl>

          <ColorPicker
            required
            value={color}
            onChange={this.handleColorChange}
            label={intl.formatMessage(messages.colorLabel)}
            error={colorError}
            errorMessage={intl.formatMessage(messages.colorErrorMsg)}
          />
        </CardContent>
        <CardActions>
          <Button onClick={this.handleCancel}>
            <FormattedMessage
              id="dashboard.forms.zoneform.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            onClick={this.handleSubmit}
            variant="contained"
            color={id ? 'secondary' : 'primary'}
            disabled={buttonDisabled}
          >
            {id ? (
              <FormattedMessage
                id="dashboard.forms.zoneform.button_edit"
                defaultMessage="Edit"
              />
            ) : (
              <FormattedMessage
                id="dashboard.forms.zoneform.button_create"
                defaultMessage="Create"
              />
            )}
          </Button>
        </CardActions>
      </>
    );
  }
}

export default injectIntl(ZoneForm);
