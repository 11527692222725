import React, { Fragment, FunctionComponent } from 'react';

import CoverageMap from '@models/CoverageMap';
import HeatMap from '@models/HeatMap';
import IteractiveMap from '@models/IteractiveMap';
import ScatterMap from '@models/ScatterMap';
import { ISimulatorConfig } from '@models/Simulation';
import SpaghettiMap from '@models/SpaghettiMap';
import ZoneHeatMap from '@models/ZoneHeatMap';
import ZoneSpaghettiMap from '@models/ZoneSpaghettiMap';
import { IDataRange, MetricState } from '@reducers/analytics';
import BeaconMapLayer from './BeaconMap';
import BackgroundLayer from './Background';
import BoundaryMapLayer from './BoundaryMap';
import CoverageMapLayer from './CoverageMap';
import ExteriorBoundaryMapLayer from './ExteriorBoundaryMap';
import EventMap from './EventMap';
import TaskMap from './TaskMap';
import FloorplanLayer from './Floorplan';
import FlowMapLayer from './FlowMap';
import GMaps from './GMaps';
import ThreeDMaps from './3DMap';
import HeatMapLayer from './HeatMap';
import ObstacleMapLayer from './ObstactleMap';
import LandmarkMapLayer from './LandmarkMap';
import RackMapLayer from './RackMap';
import ScatterMapLayer from './ScatterMap';
import SensorMapLayer from './SensorMap';
import SimulatorMapLayer from './SimulatorMap';
import SimulatorRoutingLayer from './SimulatorRouting';
import SpaghettiMapLayer from './SpaghettiMap';
import ZoneMapLayer from './ZoneMap';
import { ILayer } from '../../types';
import FloatLoading from '../../../FloatLoading';
import ItemLocationMap from './ItemLocationMap';

interface IProps {
  filterId?: string;
  mapType?: string;
  metricState: MetricState;
  id: string;
  color: boolean;
  dataRange: IDataRange;
  layers: Record<string, ILayer>;
  router: any;
}

/**
 * @description Component responsible for injected active layers into map
 * @param properties
 * @returns React DOM
 */
const Layers: FunctionComponent<IProps> = ({
  mapType = undefined,
  metricState,
  id,
  color,
  dataRange,
  filterId = undefined,
  layers,
  router,
}: IProps) => {
  const isLoading = dataRange.loading || metricState.loading;

  let activeMapLayer;
  switch (mapType) {
    case 'other': {
      activeMapLayer = (
        <CoverageMapLayer
          id={id}
          loading={isLoading}
          data={
            { anchors: (metricState.data || {}).other || [] } as CoverageMap
          }
        />
      );
      break;
    }

    case 'flowmap': {
      activeMapLayer = (
        <FlowMapLayer
          id={id}
          data={((metricState.data || {}).flowmap || {}) as IteractiveMap}
        />
      );
      break;
    }

    case 'grid_heatmap': {
      activeMapLayer = (
        <HeatMapLayer
          id={id}
          loading={isLoading}
          type="grid"
          data={((metricState.data || {}).grid_heatmap || {}) as HeatMap}
        />
      );
      break;
    }

    case 'zone_heatmap': {
      activeMapLayer = (
        <HeatMapLayer
          id={id}
          loading={isLoading}
          type="zone"
          data={((metricState.data || {}).zone_heatmap || {}) as ZoneHeatMap}
        />
      );
      break;
    }

    case 'trajectories': {
      activeMapLayer = (
        <SpaghettiMapLayer
          id={id}
          type="trajectory"
          loading={isLoading}
          data={((metricState.data || {}).trajectories || {}) as SpaghettiMap}
        />
      );
      break;
    }

    case 'zone_trajectories': {
      activeMapLayer = (
        <SpaghettiMapLayer
          id={id}
          type="zone"
          loading={isLoading}
          data={
            ((metricState.data || {}).zone_trajectories ||
              {}) as ZoneSpaghettiMap
          }
        />
      );
      break;
    }

    case 'scatter': {
      activeMapLayer = (
        <ScatterMapLayer
          id={id}
          loading={isLoading}
          data={((metricState.data || {}).scatter || {}) as ScatterMap}
        />
      );
      break;
    }

    default:
      activeMapLayer = null;
      break;
  }
  const activeLayers = Object.keys(layers).filter((k) => layers[k].active);
  const isGoogleMapsActive = activeLayers.find((k) => layers[k].id === 'gmaps');
  const is3DMapActive = activeLayers.find((k) => layers[k].id === 'threed');

  return (
    <FloatLoading loading={isLoading}>
      {activeLayers.map((k) => {
        if (!isGoogleMapsActive && !is3DMapActive) {
          if (layers[k].id === 'assets') {
            return (
              <BeaconMapLayer
                key={layers[k].id}
                id={id}
                filterId={filterId}
                router={router}
                showLiveData={(layers.assets || {}).showLiveData || false}
                showRawLiveData={(layers.assets || {}).showRawLiveData || false}
              />
            );
          }

          if (layers[k].id === 'floorplan') {
            return (
              <FloorplanLayer
                key={layers[k].id}
                id={id}
                color={color}
                isBackgroundActive={
                  !!(layers.satellite || {}).active ||
                  !!(layers.roadmap || {}).active
                }
              />
            );
          }

          if (layers[k].id === 'satellite' || layers[k].id === 'roadmap') {
            return (
              <BackgroundLayer
                key={layers[k].id}
                id={id}
                color={color}
                isSatellite={layers[k].id === 'satellite'}
              />
            );
          }

          if (layers[k].id === 'sensors') {
            return <SensorMapLayer key={layers[k].id} id={id} router={router} />;
          }

          if (layers[k].id === 'boundaries') {
            return (
              <Fragment key={layers[k].id}>
                <BoundaryMapLayer id={id} />
                <ObstacleMapLayer id={id} />
                <LandmarkMapLayer id={id} />
                <ExteriorBoundaryMapLayer key={layers[k].id} id={id} />
              </Fragment>
            );
          }

          if (layers[k].id === 'racks') {
            return <RackMapLayer key={layers[k].id} id={id} />;
          }

          if (layers[k].id === 'zones') {
            return (
              <ZoneMapLayer key={layers[k].id} id={id} filterId={filterId} />
            );
          }

          if (layers[k].id === 'itemLocations') {
            return <ItemLocationMap key={layers[k].id} id={id} />;
          }

          if (layers[k].id === 'simulator') {
            return (
              <SimulatorMapLayer
                key={layers[k].id}
                id={id}
                simulatorLayout={
                  ((layers[k] || {}).data || {}) as ISimulatorConfig
                }
              />
            );
          }

          if (layers[k].id === 'simulatorRouting') {
            return (
              <SimulatorRoutingLayer
                key={layers[k].id}
                id={id}
                simulatorLayout={
                  ((layers[k] || {}).data || {}) as ISimulatorConfig
                }
              />
            );
          }

          if (layers[k].id === 'events') {
            return <EventMap key={layers[k].id} id={id} filterId={filterId} />;
          }

          if (layers[k].id === 'tasks') {
            return <TaskMap key={layers[k].id} id={id} filterId={filterId} />;
          }
        }

        let heatmap;
        if (mapType === 'grid_heatmap') {
          heatmap = {
            type: 'grid',
            map: ((metricState.data || {}).grid_heatmap || {}) as HeatMap,
          };
        } else if (mapType === 'zone_heatmap') {
          heatmap = {
            type: 'zone',
            map: ((metricState.data || {}).zone_heatmap || {}) as ZoneHeatMap,
          };
        }
        let spaghetti;
        if (mapType === 'trajectories') {
          spaghetti = {
            type: 'grid',
            map: ((metricState.data || {}).trajectories || {}) as SpaghettiMap,
          };
        } else if (mapType === 'zone_trajectories') {
          spaghetti = {
            type: 'zone',
            map: ((metricState.data || {}).zone_trajectories ||
              {}) as ZoneSpaghettiMap,
          };
        }

        if (layers[k].id === 'gmaps') {
          return (
            <GMaps
              id={id}
              key={layers[k].id}
              heatmap={heatmap as any}
              spaghetti={spaghetti as any}
            />
          );
        }

        if (layers[k].id === 'threed') {
          return (
            <ThreeDMaps
              id={id}
              key={layers[k].id}
              color={color}
              showAssets={
                !!activeLayers.find((ky) => layers[ky].id === 'assets')
              }
              showBoundaries={
                !!activeLayers.find((ky) => layers[ky].id === 'boundaries')
              }
              showFloorplan={
                !!activeLayers.find((ky) => layers[ky].id === 'floorplan')
              }
              showSensors={
                !!activeLayers.find((ky) => layers[ky].id === 'sensors')
              }
              showRacks={!!activeLayers.find((ky) => layers[ky].id === 'racks')}
              showZones={!!activeLayers.find((ky) => layers[ky].id === 'zones')}
              heatmap={heatmap as any}
              spaghetti={spaghetti as any}
            />
          );
        }

        return null;
      })}
      {!isGoogleMapsActive && !is3DMapActive && activeMapLayer}
    </FloatLoading>
  );
};

export default Layers;
