import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import { IUpdateFloorplanProps, updateFloorplan } from '@actions/index';
import ENULocations from '@app/common/FullMap/ENULocations';
import { IGeoMapping } from '@models/IncompleteFloorplan';
import { IAppState } from '@reducers/index';
import { TransformationMatrix2D } from '../../../utils';
import GeoAlignment from './GeoAlignment';

interface IProps {
  floorplanId: string;
  onNext: () => void;
  onSkip: () => void;
  warehouseId: string;
}

const mapStateToProps = (state: IAppState, { floorplanId }: IProps) => ({
  floorplan: state.floorplans.floorplans[floorplanId],
  loading: state.floorplans.loading,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { floorplanId, onNext, onSkip, warehouseId }: IProps
) => ({
  onNext: (
    geoMapping: IGeoMapping[],
    intl: IntlShape,
    transformationMatrix: TransformationMatrix2D
  ) => {
    let properties: IUpdateFloorplanProps = {
      enuTransformationMatrix: undefined,
      geoMapping,
      geodeticCenterLatitude: undefined,
      geodeticCenterLongitude: undefined,
    };

    if (geoMapping && geoMapping.length === 2) {
      const enuLocations = new ENULocations(
        [geoMapping![0], geoMapping![1]],
        transformationMatrix
      );

      const geodeticCenter = enuLocations.getCenter();

      properties = {
        ...properties,
        enuTransformationMatrix: enuLocations.getENUTransformationMatrix(),
        geodeticCenterLatitude: (geodeticCenter || {}).latitude,
        geodeticCenterLongitude: (geodeticCenter || {}).longitude,
      };
    }

    dispatch(
      updateFloorplan.request({
        floorplanId,
        intl,
        properties,
        warehouseId,
      })
    );
    onNext();
  },
  onSkip: (intl: IntlShape) => {
    dispatch(
      updateFloorplan.request({
        floorplanId,
        intl,
        properties: { geoMapping: [] },
        warehouseId,
      })
    );
    onSkip();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GeoAlignment);
