import React, { FunctionComponent } from 'react';
import { ArrayFieldTemplateProps } from 'react-jsonschema-form';

interface IProps extends ArrayFieldTemplateProps {
  children: React.ReactNode
}

const ArrayField: FunctionComponent<IProps> = (properties: IProps) => {
  const { children } = properties;

  return <>{children}</>;
};

export default ArrayField;
