import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchReport, IFetchReport } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getSelectedLocale } from '@selectors/app';
import { getReport, isReportLoading } from '@selectors/reports';

import ReportsTab from './ReportsTab';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;

  return {
    loading: isReportLoading(state, filterId),
    report: getReport(state, filterId).report,

    locale: getSelectedLocale(state),
    measurementUnits: getAccountMeasurementUnits(state.authentication),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchReport: (properties: IFetchReport) =>
    dispatch(fetchReport.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTab);
