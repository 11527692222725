import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closeEmployeeForm,
  createEmployee,
  ICreateEmployee,
  IEditEmployee,
  updateEmployee,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import EmployeeForm from './EmployeeForm';

const mapStateToProps = (state: IAppState) => ({
  assets: state.assets.assets,
  employees: Object.values(state.employees.employees),
  loading: state.employees.form.loading,
  floorplans: state.floorplans.floorplans,
  warehouses: state.warehouses.warehouses,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeEmployeeForm: () => dispatch(closeEmployeeForm()),
  createEmployee: (properties: ICreateEmployee) =>
    dispatch(createEmployee.request(properties)),
  updateEmployee: (properties: IEditEmployee) =>
    dispatch(updateEmployee.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeForm);
