import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactourStep } from 'reactour';

import Disabler from './Disabler';

export default (
  disableTour: () => void,
  disabledTour: boolean
): ReactourStep[] => [
  {
    content: () => (
      <>
        <Typography variant="h4">
          <FormattedMessage
            id="tutorial.login.welcome.title"
            defaultMessage="Welcome to Logidot Dashboard!"
          />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage
            id="tutorial.login.welcome.intro"
            defaultMessage="I'm here to guide you and introduce our most important features. Let's start!"
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'body',
  },
  {
    content: () => (
      <>
        <Typography variant="body2">
          <FormattedMessage
            id="tutorial.login.welcome.fillusername"
            defaultMessage="Fill in your email or, if you are using or demo account, demo@logidot.com."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#email',
  },
  {
    content: () => (
      <>
        <Typography variant="body2">
          <FormattedMessage
            id="tutorial.login.welcome.fillpassword"
            defaultMessage='Fill in your password or "logidot" for the demo account.'
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#password',
  },
  {
    content: () => (
      <>
        <Typography variant="body2">
          <FormattedMessage
            id="tutorial.login.welcome.login"
            defaultMessage="Hit Log In!"
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#login',
  },
];
