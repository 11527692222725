import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export const productivityIcon = (
  <SvgIcon>
    <rect width="24" height="24" fill="none" />
    <path
      d="M107.91,104a3.21,3.21,0,0,0-.62,0c-1.2.21-1.68-.25-1.49-1.47a1.23,1.23,0,0,0-.8-1.54c-.87-.39-1.19.17-1.52.59-.75,1-1.38,1-2.07,0a2.32,2.32,0,0,0-.35-.35c-1.52-1.52-1.55-1.55-.19-3.1.42-.48.2-.78,0-1.21a.9.9,0,0,0-1-.73C98,96,98,96,98,94v-.37c0-1.8,0-1.84,1.82-1.84.67,0,.88-.31,1.17-.82s.08-.91-.31-1.26c-.12-.11-.22-.25-.35-.35-.86-.64-.94-1.26,0-1.89a3.73,3.73,0,0,0,.45-.44c.54-.51,1-1.32,1.64-1.44s1.07.79,1.55,1.29.72.23,1.11.08a1,1,0,0,0,.75-1c.1-2,.13-2,2.15-2h.49c1.65,0,1.66,0,1.66,1.65,0,.61,0,1,.73,1.38s1-.07,1.46-.47c1.19-1.15,1.19-1.15,2.33,0l.26.26c1.52,1.52,1.53,1.53.17,3.12-.29.33-.42.57-.16,1s.24.9,1,1C118,92,118,92,118,94.05a3.21,3.21,0,0,0,0,.62c.22,1.22-.28,1.65-1.48,1.48a1.18,1.18,0,0,0-1.49.69c-.41.89.07,1.27.56,1.64,1,.76.94,1.39,0,2.07a2.38,2.38,0,0,0-.36.35c-1.49,1.5-1.51,1.52-3.1.17A.78.78,0,0,0,111,101c-.37.22-.8.24-.85.89C110,104,109.92,104,107.91,104Zm.12-5.67a4.19,4.19,0,0,0,4.25-4.38,4.31,4.31,0,0,0-8.61.12A4.18,4.18,0,0,0,108,98.28Z"
      transform="translate(-96 -82)"
    />
  </SvgIcon>
);

export const efficiencyIcon = (
  <SvgIcon>
    <rect width="24" height="24" fill="none" />
    <g>
      <path
        d="M108,99.91a6.56,6.56,0,0,1-6.37-6.45,6.41,6.41,0,1,1,12.82.09A6.57,6.57,0,0,1,108,99.91Z"
        transform="translate(-96 -82)"
      />
      <path
        d="M108.59,102.66c-.25.59.35,1.67-.51,1.69s-.48-1-.47-1.61-.47-1.78.42-1.8S108.28,102.09,108.59,102.66Z"
        transform="translate(-96 -82)"
      />
      <path
        d="M100.93,100.89c-.38-.12-.55-.43-.3-.74a11.05,11.05,0,0,1,1.47-1.51c.36-.3.62-.06.69.34S101.3,100.91,100.93,100.89Z"
        transform="translate(-96 -82)"
      />
      <path
        d="M115.86,100.77a4.27,4.27,0,0,1-.53.66.43.43,0,0,1-.56-.12l-1.59-1.59c-.24-.25-.29-.53,0-.76s.45,0,.66.13C114.5,99.66,115.18,100.2,115.86,100.77Z"
        transform="translate(-96 -82)"
      />
      <path
        d="M108.55,84.8a8.57,8.57,0,0,1,0,.89.44.44,0,0,1-.51.42.43.43,0,0,1-.39-.41,2.77,2.77,0,0,1,0-.86c.1-.49-.38-1.39.52-1.36C108.85,83.51,108.41,84.36,108.55,84.8Z"
        transform="translate(-96 -82)"
      />
      <path
        d="M99.47,93.94h-.86c-.27,0-.52-.1-.55-.4s.25-.5.55-.55a2.69,2.69,0,0,1,.75,0c.5.05,1.32-.24,1.32.51S99.82,93.79,99.47,93.94Z"
        transform="translate(-96 -82)"
      />
      <path
        d="M116.79,94c-.42,0-.71,0-1,0a.41.41,0,0,1-.4-.4.44.44,0,0,1,.34-.47,2.79,2.79,0,0,1,.86-.08c.52.06,1.41-.29,1.41.54S117.1,93.71,116.79,94Z"
        transform="translate(-96 -82)"
      />
      <path
        d="M101,86.36c.33,0,1.8,1.5,1.72,1.8s-.29.5-.52.35a4.14,4.14,0,0,1-1.59-1.65C100.44,86.63,100.66,86.4,101,86.36Z"
        transform="translate(-96 -82)"
      />
      <path
        d="M114.94,86.82a2.36,2.36,0,0,1-1.31,1.34.36.36,0,0,1-.51-.37,2.23,2.23,0,0,1,1.29-1.41C114.66,86.26,114.88,86.47,114.94,86.82Z"
        transform="translate(-96 -82)"
      />
    </g>
  </SvgIcon>
);

export const safetyIcon = (
  <SvgIcon>
    <rect width="24" height="24" fill="none" />
    <path
      d="M108.07,104.28c-.74,0-1.47,0-2.21,0a2.16,2.16,0,0,1-2.42-2.38,20.12,20.12,0,0,1,0-2.33c.06-.76-.15-1.12-1-1a18.36,18.36,0,0,1-2.46,0,2.1,2.1,0,0,1-2.3-2.37c0-1.47,0-2.94,0-4.41a2.17,2.17,0,0,1,2.31-2.5c1.11-.08,2.53.48,3.24-.25s.13-2.06.19-3.13c.1-1.64.81-2.34,2.45-2.36q2.2,0,4.41,0c1.63,0,2.34.73,2.42,2.38a13.47,13.47,0,0,1,0,2.2c-.13,1,.34,1.27,1.25,1.18a14.44,14.44,0,0,1,2.08,0c1.7.07,2.41.81,2.43,2.5,0,1.43,0,2.86,0,4.28,0,1.67-.69,2.38-2.31,2.5-1.11.08-2.52-.48-3.24.25s-.13,2-.19,3.13a2.17,2.17,0,0,1-2.45,2.36C109.54,104.29,108.8,104.28,108.07,104.28Z"
      transform="translate(-96 -82)"
    />
  </SvgIcon>
);

export const sustainabilityIcon = (
  <SvgIcon>
    <rect width="24" height="24" fill="none" />
    <g>
      <path
        d="M111.73,100.33a7,7,0,0,0,.53-1.23,7.13,7.13,0,0,0,.31-1.29,5.71,5.71,0,0,0-.18-2.6,4.63,4.63,0,0,0-.55-1.17,5.65,5.65,0,0,0-.84-1c-.07-.08-.16-.14-.24-.22s-.16-.14-.25-.2a6.58,6.58,0,0,0-.54-.38l-.27-.17-.29-.16-.59-.32a11.7,11.7,0,0,1-1.18-.69l-.59-.37-.55-.41c-.38-.26-.72-.57-1.08-.87-.18-.14-.34-.31-.51-.47l-.25-.23-.24-.25L104,87.8l-.44-.53c-.29-.37-.57-.74-.82-1.13a13.13,13.13,0,0,1-.72-1.2l-.31-.63-.13-.33a1.22,1.22,0,0,1-.07-.17c0-.06,0-.11-.06-.18h.92c-.11.43-.22.9-.3,1.35s-.16.92-.22,1.38a21.49,21.49,0,0,0-.19,2.77q0,.69,0,1.38c0,.46.06.92.12,1.37s.13.9.22,1.35.21.88.35,1.31.29.85.47,1.26a10.32,10.32,0,0,0,.64,1.18,9.42,9.42,0,0,0,.8,1.08,9.56,9.56,0,0,0,1,1,9.73,9.73,0,0,0,1.1.81,9.48,9.48,0,0,0,1.2.68,11.46,11.46,0,0,0,1.3.54c.44.17.89.3,1.35.44l0,.12c-.24,0-.48,0-.72-.08s-.48-.07-.71-.12l-.36-.07-.35-.09c-.24-.06-.47-.14-.7-.21a11.44,11.44,0,0,1-1.36-.56,10.22,10.22,0,0,1-1.27-.77,8.77,8.77,0,0,1-1.14-1,10.64,10.64,0,0,1-1-1.15,11.27,11.27,0,0,1-1.38-2.67,12.61,12.61,0,0,1-.66-2.89c-.06-.5-.1-1-.11-1.48s0-1,0-1.47a23.24,23.24,0,0,1,.33-2.89c.08-.48.17-1,.28-1.43s.23-.94.38-1.42h0a.48.48,0,0,1,.92,0,1.21,1.21,0,0,0,.05.14.88.88,0,0,0,0,.15c0,.1.07.2.11.3s.18.39.27.59a10.59,10.59,0,0,0,.63,1.14c.22.37.47.73.73,1.09l.41.51.43.5c.07.08.14.17.22.25l.23.23.47.47c.33.29.65.59,1,.86l.52.41.54.37a12.91,12.91,0,0,0,1.14.71l.58.35.29.18.29.2c.2.13.37.28.55.42l.27.24c.08.08.17.15.25.24a6.06,6.06,0,0,1,.85,1.11,4.74,4.74,0,0,1,.55,1.29,6,6,0,0,1,.07,2.75,8.65,8.65,0,0,1-.38,1.32,8.12,8.12,0,0,1-.6,1.22Z"
        transform="translate(-96 -82)"
      />
      <path
        d="M104,91.32a6.29,6.29,0,0,0,.23.73,16.73,16.73,0,0,0,.79,1.83,19.07,19.07,0,0,0,1.44,2.41c.15.21.29.43.45.63l.24.32.25.32c.33.42.69.83,1.06,1.23.19.2.37.4.57.58l.59.56,1.18,1c.2.16.38.33.56.5l.54.47c.17.16.35.31.51.45l.48.43.85.74.66.59.42.38a1.64,1.64,0,0,1,.15.15l-.06.1-.2-.06-.54-.2c-.24-.08-.51-.21-.82-.35s-.66-.33-1-.54l-.57-.35c-.19-.13-.4-.25-.59-.4l-.61-.44-.6-.51c-.2-.18-.39-.37-.59-.57s-.37-.41-.56-.61-.37-.43-.55-.65l-.54-.66c-.17-.22-.35-.44-.52-.67s-.33-.46-.48-.69l-.24-.35-.22-.35c-.14-.23-.28-.46-.41-.69-.26-.47-.5-.92-.7-1.36s-.38-.87-.53-1.26-.27-.77-.37-1.09-.17-.62-.22-.86a7.25,7.25,0,0,1-.14-.76Z"
        transform="translate(-96 -82)"
      />
    </g>
  </SvgIcon>
);
