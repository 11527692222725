import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import React, { Component, createRef, RefObject } from 'react';
import { ColorResult } from 'react-color';
import { FormattedMessage } from 'react-intl';

import PickerDialog from './PickerDialog';

// Matches #FF00BB
export const longHexRegex = /^#([A-Fa-f0-9]{6})$/;
// Matches #F0B
export const shortHexRegex = /^#([A-Fa-f0-9]{3})$/;
// Matches #FF00BBAA
export const longHexRegexWithAlpha = /^#([A-Fa-f0-9]{8})$/;
// Matches #F0BA
export const shortHexRegexWithAlpha = /^#([A-Fa-f0-9]{4})$/;

interface IProps {
  value?: string;
  onChange: (color: string) => void;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
}

interface IState {
  show: boolean;
}

class ColorPicker extends Component<IProps, IState> {
  private readonly textFieldRef: RefObject<any>;

  constructor(props: IProps) {
    super(props);

    this.state = { show: false };

    this.textFieldRef = createRef();

    this.togglePicker = this.togglePicker.bind(this);
  }

  public togglePicker(show: boolean) {
    this.setState({ show });
  }

  public render() {
    const { onChange, error, errorMessage, label, value, required } =
      this.props;
    const { show } = this.state;

    return (
      <div style={{ paddingTop: '16px', paddingBottom: '8px' }}>
        <FormLabel style={{ fontSize: '0.75rem' }}>
          {label}
          {required ? ' *' : ''}
        </FormLabel>
        <div
          ref={this.textFieldRef}
          role="complementary"
          aria-hidden="true"
          style={{
            display: 'flex',
            backgroundColor: value,
            height: '30px',
            padding: '3px',
            marginTop: '6px',
            cursor: 'pointer',
            width: '80px',
            border: '1px solid #EFEFEF',
            color: '#E1E1E1',
            fontSize: '9px',
          }}
          onKeyDown={() => this.togglePicker(true)}
          onClick={() => this.togglePicker(true)}
        >
          {!value && (
            <>
              <FormatColorResetIcon />
              <FormattedMessage
                id="dashboard.colorpicker.nocolor"
                defaultMessage="No color selected"
              />
            </>
          )}
        </div>
        {error && errorMessage && (
          <FormHelperText style={{ color: 'red' }}>
            {errorMessage}
          </FormHelperText>
        )}

        <PickerDialog
          anchorEl={this.textFieldRef.current}
          open={show}
          value={value || ''}
          onClose={() => this.togglePicker(false)}
          onChangeComplete={(color: ColorResult) => onChange(color.hex)}
        />
      </div>
    );
  }
}

export default ColorPicker;
