import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactourStep } from 'reactour';

import Disabler from './Disabler';

export default (
  disableTour: () => void,
  disabledTour: boolean
): ReactourStep[] => [
  {
    content: () => (
      <>
        <Typography variant="h4">
          <FormattedMessage
            id="tutorial.rules.welcome.title"
            defaultMessage="Welcome to Logidot Rules!"
          />
        </Typography>
        <br />
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.rules.welcome.message"
            defaultMessage="Here you can create rules. Rules will trigger alerts that you can see on the previous page. Rules can be associated with specific assets or zones. You can use the map on this page to create zones."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'body',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.rules.list"
            defaultMessage="Here you find a list of existing rules, you can either edit, activate/deactivate or delete them. Click on a row to see details."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#rules-list',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.rules.add"
            defaultMessage="You can add a new rule by clicking on this button, a form will popup."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#add-rule',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.rules.map"
            defaultMessage="You can easily create a new zone by CTRL + Click and drag on this map. A form will popup where you can fill in zone details, like name and color."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#rules-map',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.rules.playergo"
            defaultMessage="Let's meet our Player."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'a#player',
  },
];
