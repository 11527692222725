import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchAvailablePermissions } from '@actions/index';
import { IAppState } from '@reducers/index';
import {
  getAvailablePermissions,
  isAvailablePermissionsLoading,
} from '@selectors/accounts';
import AccountPermissionsForm from './AccountPermissionsForm';

const mapStateToProps = (state: IAppState) => ({
  availablePermissions: getAvailablePermissions(state.accounts),
  loading: isAvailablePermissionsLoading(state.accounts),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAvailablePermissions: () =>
    dispatch(fetchAvailablePermissions.request()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPermissionsForm);
