export function getNavigatorLanguage() {
  return navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language || 'en-GB';
}

export function getMeasurementUnitsFromLocale() {
  const countryCode = getNavigatorLanguage().split('-')[1] || 'GB';

  if (
    countryCode === 'GB' || // Grand Britain
    countryCode === 'US' || // USA
    countryCode === 'LR' || // Liberia
    countryCode === 'MM' // Myanmar
  ) {
    return 'imperial';
  }

  return 'si';
}

export default {};
