import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import Loading from '@app/common/Loading';
import { Simulation } from '@models/Simulation';
import AnalyticsTab from '@app/dashboard/tabs/AnalyticsTab';
import { IFilterEntry, IFilterChange, LAST, IFilter } from '@reducers/filter';

interface IProps {
  onChange: (properties: IFilterChange) => void;
  addFilter: (properties: IFilterEntry) => void;
  fetchFloorplan: (id: string) => void;
  fetchSimulation: (id: string) => void;
  router: any;
  filters: Record<string, IFilter>;
  id: string;
  language: string;
  loading: boolean;
  simulation: Simulation;
  simulationRunId: string;
}

class Analytics extends Component<IProps> {
  public componentDidMount() {
    const {
      addFilter,
      fetchFloorplan,
      fetchSimulation,
      filters,
      id,
      simulation,
    } = this.props;

    if (simulation.id !== id) {
      fetchSimulation(id);
    }

    if (simulation.floorplanId && !filters.simulator) {
      fetchFloorplan(simulation.floorplanId);

      addFilter({
        id: 'simulator',
        floorplanId: simulation.floorplanId,
        warehouseId: simulation.warehouseId,
        autoRefresh: false,
        filterUpdated: false,
        last: LAST.WEEK,
      });
    }
  }

  public componentDidUpdate() {
    const { addFilter, fetchFloorplan, filters, onChange, simulation } =
      this.props;

    if ((simulation || {}).floorplanId !== (filters.simulator || {}).floorplanId) {
      fetchFloorplan(simulation.floorplanId);

      if (!filters.simulator) {
        addFilter({
          id: 'simulator',
          floorplanId: simulation.floorplanId,
          warehouseId: simulation.warehouseId,
          autoRefresh: false,
          filterUpdated: false,
          last: LAST.WEEK,
        });
      } else {
        onChange({
          id: 'simulator',
          floorplanId: simulation.floorplanId,
          warehouseId: simulation.warehouseId,
        });
      }
    }
  }

  public render() {
    const { language, loading, simulation } = this.props;

    const LinkRouter = (props: any) => (
      <Link {...props} component={RouterLink} />
    );

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '10px' }}>
          <LinkRouter
            color="inherit"
            to={`/${language}/dashboard/simulations/`}
          >
            <FormattedMessage
              id="dashboard.simulations.breadcrumbs.home"
              defaultMessage="Simulations"
            />
          </LinkRouter>
          <Typography color="textPrimary">
            <FormattedMessage
              id="dashboard.simulations.breadcrumbs.simulationrun"
              defaultMessage="Simulation"
            />
          </Typography>
          <Typography color="textPrimary">
            <FormattedMessage
              id="dashboard.simulations.breadcrumbs.analytics"
              defaultMessage="Analytics"
            />
          </Typography>
        </Breadcrumbs>
        <Loading loading={loading}>
          {simulation.floorplanId ? (
            <AnalyticsTab section="user" filterId="simulator" />
          ) : null}
        </Loading>
      </>
    );
  }
}

export default Analytics;
