import Typography from '@mui/material/Typography/Typography';
import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import Map from '@app/common/FullMap/Map';
import { IGeoMapping } from '@models/IncompleteFloorplan';
import MappingForm from './MappingForm';

const messages = defineMessages({
  floorplan: {
    defaultMessage: 'Floor plan',
    id: 'dashboard.datamap.floorplan',
  },
  sensors: {
    defaultMessage: 'Sensors',
    id: 'dashboard.livemap.layers.sensors',
  },
});

interface IProps {
  mappingChange?: (geoMapping: IGeoMapping[]) => void;
  floorplanId: string;
  geoMapping?: IGeoMapping[];
  intl: IntlShape;
}

interface IState {
  currentPosition: [number, number] | null;
  geoMapping: IGeoMapping[];
}

class GeoAlignmentForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { geoMapping } = props;

    this.state = {
      currentPosition: null,
      geoMapping: geoMapping || [],
    };

    this.handleMappingClose = this.handleMappingClose.bind(this);
    this.handleAddMapping = this.handleAddMapping.bind(this);
    this.mapClick = this.mapClick.bind(this);
  }

  public handleMappingClose() {
    this.setState({ currentPosition: null });
  }

  public handleAddMapping(mapping: IGeoMapping) {
    const { mappingChange } = this.props;
    const { geoMapping } = this.state;

    geoMapping.push(mapping);

    this.setState({ currentPosition: null, geoMapping }, () => {
      if (mappingChange) {
        mappingChange(geoMapping);
      }
    });
  }

  public mapClick(point: [number, number]) {
    const { mappingChange } = this.props;
    const { geoMapping } = this.state;

    const deleteMappingIndex = geoMapping.findIndex(
      (mapping) =>
        mapping.position[0] === point[0] && mapping.position[1] === point[1]
    );

    let currentPosition: [number, number] | null = point;
    if (deleteMappingIndex !== -1) {
      geoMapping.splice(deleteMappingIndex, 1);
      currentPosition = null;
    }

    this.setState({ currentPosition, geoMapping }, () => {
      if (mappingChange) {
        mappingChange(geoMapping);
      }
    });
  }

  public render() {
    const { floorplanId, intl } = this.props;
    const { currentPosition, geoMapping } = this.state;

    const help = (
      <>
        <Typography variant="h4">
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.geoalignment.title"
            defaultMessage="Geo Alignment"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.geoalignment.optional"
            defaultMessage="This step can be completed at a later time."
          />
        </Typography>
        <Typography variant="caption">
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.geoalignment.tutorial.p1"
            defaultMessage="Click on the map, in the point where you want to insert geo coordinates. You must insert two distinct points."
          />
          <br />
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.geoalignment.tutorial.p2"
            defaultMessage="Clicking on an already set point will delete it."
          />
        </Typography>
      </>
    );

    return (
      <div
        style={{
          bottom: 0,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Map
            floorplanId={floorplanId}
            disableColor
            help={help}
            layers={{
              floorplan: {
                id: 'floorplan',
                active: true,
                label: intl.formatMessage(messages.floorplan),
                primary: true,
                secondary: false,
              },
              sensors: {
                id: 'sensors',
                active: true,
                label: intl.formatMessage(messages.sensors),
                primary: true,
                secondary: false,
              },
            }}
            actions={{
              hover: {},
              geoMapping: {
                onClick: this.mapClick,
                geoMapping: JSON.parse(JSON.stringify(geoMapping)),
              },
            }}
          />
          {currentPosition !== null ? (
            <MappingForm
              position={currentPosition}
              handleCancel={this.handleMappingClose}
              handleMapping={this.handleAddMapping}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default injectIntl(GeoAlignmentForm);
