import { Point, Raster } from 'paper';
import { createCanvas } from 'canvas';
import { Component } from 'react';

import { transformMetersToPixels } from '@dashboard_utils/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import mapEvents, { orderLayers } from '../../eventEmitter';
import MapImages from '../../MapImages';
import Paper from '../../Paper';
import { defaultTransformationMatrix } from '../../../consts';

interface IRaster {
  id: string;
  raster: paper.Raster;
}

interface IProps {
  id: string;
  color: boolean;
  floorplan: IncompleteFloorplan;
  isBackgroundActive: boolean;
  mapImages: MapImages;
  paper: Paper;
}

class FloorplanLayer extends Component<IProps> {
  private map: IRaster | undefined;

  constructor(props: IProps) {
    super(props);

    this.sendToBack = this.sendToBack.bind(this);
  }

  public componentDidMount() {
    mapEvents.on('order_layers', this.sendToBack);

    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { color, isBackgroundActive } = this.props;

    if (
      prevProps.color !== color ||
      prevProps.isBackgroundActive !== isBackgroundActive
    ) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    mapEvents.removeListener('order_layers', this.sendToBack);

    this.clear();
  }

  public load() {
    const { color, floorplan, isBackgroundActive, mapImages, paper } =
      this.props;

    if (
      !this.map ||
      this.map.id !== `${floorplan.image}_${color}_${isBackgroundActive}`
    ) {
      // If mosaic background is active and floorplan has boundaries, cut off image outside of the boundaries
      if (
        isBackgroundActive &&
        floorplan.boundaries &&
        floorplan.boundaries.length
      ) {
        const image = new Image();
        image.setAttribute('crossOrigin', '');
        image.onload = () => {
          const imgCanvas = createCanvas(mapImages.width, mapImages.height);
          const ctx = imgCanvas.getContext('2d');
          const imgClipingCanvas = createCanvas(
            mapImages.width,
            mapImages.height
          );
          const clipingCtx = imgClipingCanvas.getContext('2d');

          // For each boundary draw inside image peace into the new canvas
          (floorplan.boundaries || []).forEach((boundary) => {
            const pxBoundary = boundary.coordinates.map((point) =>
              transformMetersToPixels(
                [point[0], point[1]],
                floorplan.transformationMatrix || defaultTransformationMatrix,
                floorplan.scale || 1
              )
            );

            clipingCtx.save();
            clipingCtx.beginPath();
            pxBoundary.forEach((point, index) => {
              if (index === 0) {
                clipingCtx.moveTo(point[0], point[1]);
              } else {
                clipingCtx.lineTo(point[0], point[1]);
              }
            });
            clipingCtx.closePath();
            clipingCtx.clip();
            // PaperJS is bottom, left oriented, so, image needs to be flipped vertically:
            clipingCtx.translate(0, mapImages.height);
            clipingCtx.scale(1, -1);
            clipingCtx.drawImage(image as any, 0, 0);
            ctx.save();
            ctx.translate(0, mapImages.height);
            ctx.scale(1, -1);
            ctx.drawImage(imgClipingCanvas, 0, 0);
            ctx.restore();
            clipingCtx.restore();
          });
          ctx.translate(0, mapImages.height);
          ctx.scale(1, -1);

          paper.scope.activate();
          this.map = {
            id: `${floorplan.image}_${color}_${isBackgroundActive}`,
            raster: new Raster({
              position: new Point(mapImages.width / 2, mapImages.height / 2),
              source: imgCanvas.toDataURL(),
            }),
          };

          orderLayers();
        };
        image.src =
          (color ? mapImages.backgroundImage : mapImages.backgroundImageBW) ||
          '';

        return;
      }

      paper.scope.activate();
      this.map = {
        id: `${floorplan.image}_${color}_${isBackgroundActive}`,
        raster: new Raster({
          position: new Point(mapImages.width / 2, mapImages.height / 2),
          source: color
            ? mapImages.backgroundImage
            : mapImages.backgroundImageBW,
        }),
      };

      orderLayers();

      return;
    }

    this.map.raster.addTo(paper.scope.project);

    orderLayers();
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    if (this.map) {
      this.map.raster.remove();
    }

    this.map = undefined;
  }

  public sendToBack(layerType: string) {
    if (layerType === 'floorplan' && this.map) {
      this.map.raster.sendToBack();
    }
  }

  public render() {
    return null;
  }
}

export default FloorplanLayer;
