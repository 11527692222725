import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { detect } from 'detect-browser';
import FileSaver from 'file-saver';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfOutlined from '@mui/icons-material/PictureAsPdfOutlined';

import { IFetchROIReport } from '@actions/reports';
import LoadingButton from '@app/common/LoadingButton';
import Warehouse from '@models/Warehouse';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Calc, {
  data,
  Dimensions,
  IAdditionalService,
  goals,
  formatPrice,
} from './Calc';
import Result from './Result';
import ROIResult from './ROIResult';
import PDF from '../ReportsTab/PDF';

interface IProps {
  loading: boolean;
  report: any;
  filterId: string;
  intl: IntlShape;
  warehouse: Warehouse;
  floorplan: IncompleteFloorplan;
  fetchROIReport: (properties: IFetchROIReport) => void;
}

interface IState {
  contractType: string;
  additionalServices: IAdditionalService[];
  additionalServicesTemp: string;
  subscriptionType: string;
  hardwareOption: string;
  pilotDuration: number;

  goal: string;
  numberOfMovedPallets: number;
  usedFleetCapacity: number;
  palletMovementPrice: number;
  margin: number;
  numberOfAccidents: number;
  avgReparationCost: number;
  daysDownAfterCrash: number;
  complianceFees: number;
  estimatedGainsInPalletMoved: number;
  estimatedReductionInAccidents: number;

  showPDF: boolean;
}

class FinacialsTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      contractType: 'partner',
      additionalServices: [],
      additionalServicesTemp: '',
      subscriptionType: 'essentials',
      hardwareOption: 'leased',
      pilotDuration: 6,

      goal: 'productivity',
      numberOfMovedPallets: 100000,
      usedFleetCapacity: 70,
      palletMovementPrice: 4.8,
      margin: 20,
      numberOfAccidents: 1,
      avgReparationCost: 750,
      daysDownAfterCrash: 5,
      complianceFees: 1000,
      estimatedGainsInPalletMoved: 20,
      estimatedReductionInAccidents: 10,

      showPDF: false,
    };

    this.handlePDFClose = this.handlePDFClose.bind(this);
    this.generatePdf = this.generatePdf.bind(this);
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;
      const { additionalServices } = this.state;

      if (key === 'additionalServices') {
        const service =
          data.services.find((s) => s.code === value) || ({} as any);

        additionalServices.push({
          ...service,
          code: `${service.code}_${additionalServices.length}`,
          days: 1,
        } as IAdditionalService);

        return this.setState({
          additionalServices,
          additionalServicesTemp: value,
        });
      }

      return this.setState({
        [key]: value,
      } as any);
    };
  }

  public handleServiceChange(index: number, key: keyof IAdditionalService) {
    return (e: any) => {
      const { additionalServices } = this.state;
      const { value } = e.target;

      if (!value) {
        return null;
      }

      // @ts-ignore
      additionalServices[index][key] = value as any;

      return this.setState({ additionalServices });
    };
  }

  public handlePDFClose() {
    this.setState({ showPDF: false });
  }

  public removeAdditionalService(index: number) {
    return () => {
      const { additionalServices } = this.state;

      additionalServices.splice(index, 1);

      this.setState({ additionalServices });
    };
  }

  public generatePdf() {
    const { fetchROIReport, floorplan, filterId, intl, warehouse } = this.props;

    const {
      contractType,
      additionalServices,
      subscriptionType,
      hardwareOption,
      pilotDuration,

      goal,
      numberOfMovedPallets,
      usedFleetCapacity,
      palletMovementPrice,
      margin,
      numberOfAccidents,
      avgReparationCost,
      daysDownAfterCrash,
      complianceFees,
      estimatedGainsInPalletMoved,
      estimatedReductionInAccidents,
    } = this.state;

    const twoDCalc = new Calc(
      data,

      warehouse.country || 'PT',
      floorplan.targetNumberOfForklifts || 100,
      contractType,
      floorplan.anchorType || 'wired',
      floorplan.tracking || 'indoor',
      floorplan.connectivity || 'wifi',
      additionalServices,
      floorplan.floorplanArea || 1000,
      subscriptionType,
      hardwareOption,
      pilotDuration,
      floorplan.aisleLength || 200,
      floorplan.numberOfAisles || 50,
      (floorplan.openAreasSpace || [500]).length,
      floorplan.openAreasSpace || [500],

      goal,
      warehouse.numberOfShifts || 3,
      warehouse.shiftHours || 8,
      warehouse.workingDays || 225,
      warehouse.targetForkliftType || 'electric',
      numberOfMovedPallets,
      usedFleetCapacity,
      palletMovementPrice,
      margin,
      numberOfAccidents,
      avgReparationCost,
      daysDownAfterCrash,
      complianceFees,
      estimatedGainsInPalletMoved,
      estimatedReductionInAccidents,

      Dimensions.twoD
    );

    const threeDCalc = new Calc(
      data,

      warehouse.country || 'PT',
      floorplan.targetNumberOfForklifts || 100,
      contractType,
      floorplan.anchorType || 'wired',
      floorplan.tracking || 'indoor',
      floorplan.connectivity || 'wifi',
      additionalServices,
      floorplan.floorplanArea || 1000,
      subscriptionType,
      hardwareOption,
      pilotDuration,
      floorplan.aisleLength || 200,
      floorplan.numberOfAisles || 50,
      (floorplan.openAreasSpace || [500]).length,
      floorplan.openAreasSpace || [500],

      goal,
      warehouse.numberOfShifts || 3,
      warehouse.shiftHours || 8,
      warehouse.workingDays || 225,
      warehouse.targetForkliftType || 'electric',
      numberOfMovedPallets,
      usedFleetCapacity,
      palletMovementPrice,
      margin,
      numberOfAccidents,
      avgReparationCost,
      daysDownAfterCrash,
      complianceFees,
      estimatedGainsInPalletMoved,
      estimatedReductionInAccidents,

      Dimensions.threeD
    );

    const dtl = threeDCalc.calcROI();
    const roi = threeDCalc.calcROI();

    fetchROIReport({
      filterId,
      intl,
      roiData: {
        numberOfAnchorsCorridor: [
          twoDCalc.getNumberOfCorridorAnchors(),
          threeDCalc.getNumberOfCorridorAnchors(),
        ],
        numberOfAnchorsOpenArea: [
          twoDCalc.getNumberOfOpenAreasAnchors(),
          threeDCalc.getNumberOfOpenAreasAnchors(),
        ],
        totalNumberOfAnchors: [
          twoDCalc.getTotalOfAnchors(),
          threeDCalc.getTotalOfAnchors(),
        ],
        totalNumberOfTags: [
          floorplan.targetNumberOfForklifts || 100,
          floorplan.targetNumberOfForklifts || 100,
        ],
        spaceByAnchor: [
          twoDCalc.getAncorAreaRatio(),
          threeDCalc.getAncorAreaRatio(),
        ],
        costByAnchor: [
          twoDCalc.getAnchorsCosts(),
          threeDCalc.getAnchorsCosts(),
        ],
        costByTag: [twoDCalc.getTagsCosts(), threeDCalc.getTagsCosts()],
        aditionalCosts: [
          twoDCalc.getAdditionalCosts(),
          threeDCalc.getAdditionalCosts(),
        ],
        leasingCosts: [
          twoDCalc.getLeasingCost() || 0,
          threeDCalc.getLeasingCost() || 0,
        ],
        contractType,
        softwareCosts: threeDCalc.getSoftwareCost(),
        packageName: threeDCalc.getPackage().name,
        packageCost: threeDCalc.getPackage().cost,
        laborCostAnchorInstallation: [
          twoDCalc.getAnchorsInstallationCosts(),
          threeDCalc.getAnchorsInstallationCosts(),
        ],
        laborCostTagInstallation: [
          twoDCalc.getTagInstallationCosts(),
          threeDCalc.getTagInstallationCosts(),
        ],
        tripExpenses: [
          twoDCalc.getTripExpenses('install'),
          threeDCalc.getTripExpenses('install'),
        ],
        uninstallement: [
          twoDCalc.getUninstallementCosts(),
          threeDCalc.getUninstallementCosts(),
        ],
        uninstallementTripExpenses: [
          twoDCalc.getTripExpenses('uninstall'),
          threeDCalc.getTripExpenses('uninstall'),
        ],
        maintenance: [
          data.maintenanceCosts.twoDimension,
          data.maintenanceCosts.threeDimension,
        ],
        additionalServicesCosts: additionalServices.map(
          (service: IAdditionalService) => ({
            name: service.name,
            cost: service.cost * service.days,
          })
        ),
        plotCost: [twoDCalc.getPilotCost(), threeDCalc.getPilotCost()],
        partnerUpfrontCost: [
          twoDCalc.getPartnerUpfrontCost(),
          threeDCalc.getPartnerUpfrontCost(),
        ],
        subscriptionCost: [
          twoDCalc.getPartnerSubscriptionCost(),
          threeDCalc.getPartnerSubscriptionCost(),
        ],
        availableTime: dtl.availableTime,
        usedFleetCapacity: threeDCalc.usedFleetCapacity,
        operatingTimeBefore: dtl.operatingTime.before,
        operatingTimeAfter: dtl.operatingTime.after,
        operatingTimeGain: dtl.operatingTime.gain,
        movedPalletsByForkliftBefore: dtl.movedPalletsByForklift.before,
        movedPalletsByForkliftAfter: dtl.movedPalletsByForklift.after,
        movedPalletsByForkliftGain:
          dtl.movedPalletsByForklift.after - dtl.movedPalletsByForklift.before,
        timeToMovePalletMinBefore: dtl.timeToMovePalletMin.before,
        timeToMovePalletMinAfter: dtl.timeToMovePalletMin.after,
        timeToMovePalletMinGain: dtl.timeToMovePalletMin.gain,
        avgNumberOfPalletsMovedDayForkBefore:
          dtl.avgNumberOfPalletsMovedDayFork.before,
        avgNumberOfPalletsMovedDayForkAfter:
          dtl.avgNumberOfPalletsMovedDayFork.after,
        avgNumberOfPalletsMovedDayForkGain:
          dtl.avgNumberOfPalletsMovedDayFork.after -
          dtl.avgNumberOfPalletsMovedDayFork.before,
        avgNumberOfPalletsMovedDayBefore: dtl.avgNumberOfPalletsMovedDay.before,
        avgNumberOfPalletsMovedDayAfter: dtl.avgNumberOfPalletsMovedDay.after,
        avgNumberOfPalletsMovedDayGain: dtl.avgNumberOfPalletsMovedDay.gain,
        totalPalletsMovedYearBefore: dtl.totalPalletsMovedYear.before,
        totalPalletsMovedYearAfter: dtl.totalPalletsMovedYear.after,
        totalPalletsMovedYearGain: dtl.totalPalletsMovedYear.gain,
        maxNumberOfTrucksServedDailyBefore:
          dtl.maxNumberOfTrucksServedDaily.before,
        maxNumberOfTrucksServedDailyAfter:
          dtl.maxNumberOfTrucksServedDaily.after,
        maxNumberOfTrucksServedDailyGain: dtl.maxNumberOfTrucksServedDaily.gain,
        revenuesBefore: dtl.revenues.before,
        revenuesAfter: dtl.revenues.after,
        revenuesGain: dtl.revenues.gain,
        profitBefore: dtl.profit.before,
        profitAfter: dtl.profit.after,
        profitGain: dtl.profit.gain,
        efficiencyCostsBefore: dtl.efficiencyCosts.before,
        efficiencyCostsAfter: dtl.efficiencyCosts.after,
        efficiencyCostsGain: dtl.efficiencyCosts.gain,
        operatorCostsByPalletBefore: dtl.operatorCostsByPallet.before,
        operatorCostsByPalletAfter: dtl.operatorCostsByPallet.after,
        operatorCostsByPalletGain: dtl.operatorCostsByPallet.gain,
        totalCostsYearBefore: dtl.totalCostsYear.before,
        totalCostsYearAfter: dtl.totalCostsYear.after,
        totalCostsYearGain: dtl.totalCostsYear.gain,
        savingsBefore: dtl.savings.before,
        savingsAfter: dtl.savings.after,
        savingsGain: dtl.savings.gain,
        numberOfCrashesBefore: dtl.numberOfCrashes.before,
        numberOfCrashesAfter: dtl.numberOfCrashes.after,
        numberOfCrashesGain: dtl.numberOfCrashes.gain,
        repairCostsBefore: dtl.repairCosts.before,
        repairCostsAfter: dtl.repairCosts.after,
        repairCostsGain: dtl.repairCosts.gain,
        downtimeHoursBefore: dtl.downtimeHours.before,
        downtimeHoursAfter: dtl.downtimeHours.after,
        downtimeHoursGain: dtl.downtimeHours.gain,
        profitDueToLessAccidentsBefore: dtl.profitDueToLessAccidents.before,
        profitDueToLessAccidentsAfter: dtl.profitDueToLessAccidents.after,
        profitDueToLessAccidentsGain: dtl.profitDueToLessAccidents.gain,
        reparationCostsYearBefore: dtl.reparationCostsYear.before,
        reparationCostsYearAfter: dtl.reparationCostsYear.after,
        reparationCostsYearGain: dtl.reparationCostsYear.gain,
        profitDueToLessAccidentsYearBefore:
          dtl.profitDueToLessAccidentsYear.before,
        profitDueToLessAccidentsYearAfter:
          dtl.profitDueToLessAccidentsYear.after,
        profitDueToLessAccidentsYearGain: dtl.profitDueToLessAccidentsYear.gain,
        complianceFeesBefore: dtl.complianceFees.before,
        complianceFeesAfter: dtl.complianceFees.after,
        complianceFeesGain: dtl.complianceFees.gain,
        totalCostsBefore: dtl.totalCosts.before,
        totalCostsAfter: dtl.totalCosts.after,
        totalCostsGain: dtl.totalCosts.gain,
        co2EmissionsBefore: dtl.co2Emissions.before,
        co2EmissionsAfter: dtl.co2Emissions.after,
        co2EmissionsGain: dtl.co2Emissions.gain,
        co2EmissionsByPalletBefore: dtl.co2EmissionsByPallet.before,
        co2EmissionsByPalletAfter: dtl.co2EmissionsByPallet.after,
        co2EmissionsByPalletGain: dtl.co2EmissionsByPallet.gain,
        co2EmissionsTotalTonsYearBefore: dtl.co2EmissionsTotalTonsYear.before,
        co2EmissionsTotalTonsYearAfter: dtl.co2EmissionsTotalTonsYear.after,
        co2EmissionsTotalTonsYearGain: dtl.co2EmissionsTotalTonsYear.gain,
        co2SavingsBefore: dtl.co2Savings.before,
        co2SavingsAfter: dtl.co2Savings.after,
        co2SavingsGain: dtl.co2Savings.gain,
        saving: roi.saving,
        profits: roi.profits,
        efficiency: roi.efficiency,
        safety: roi.safety,
        investiment: [twoDCalc.getTotalCost(), threeDCalc.getTotalCost()],
        roi: [
          ((roi.saving - twoDCalc.getTotalCost()) / twoDCalc.getTotalCost()) *
            100,
          ((roi.saving - threeDCalc.getTotalCost()) /
            threeDCalc.getTotalCost()) *
            100,
        ],
      },
    });

    this.setState({ showPDF: true });
  }

  public render() {
    const { floorplan, loading, report, warehouse } = this.props;

    const {
      contractType,
      additionalServices,
      additionalServicesTemp,
      subscriptionType,
      hardwareOption,
      pilotDuration,

      goal,
      numberOfMovedPallets,
      usedFleetCapacity,
      palletMovementPrice,
      margin,
      numberOfAccidents,
      avgReparationCost,
      daysDownAfterCrash,
      complianceFees,
      estimatedGainsInPalletMoved,
      estimatedReductionInAccidents,

      showPDF,
    } = this.state;

    const twoDCalc = new Calc(
      data,

      warehouse.country || 'PT',
      floorplan.targetNumberOfForklifts || 100,
      contractType,
      floorplan.anchorType || 'wired',
      floorplan.tracking || 'indoor',
      floorplan.connectivity || 'wifi',
      additionalServices,
      floorplan.floorplanArea || 1000,
      subscriptionType,
      hardwareOption,
      pilotDuration,
      floorplan.aisleLength || 200,
      floorplan.numberOfAisles || 50,
      (floorplan.openAreasSpace || [500]).length,
      floorplan.openAreasSpace || [500],

      goal,
      warehouse.numberOfShifts || 3,
      warehouse.shiftHours || 8,
      warehouse.workingDays || 225,
      warehouse.targetForkliftType || 'electric',
      numberOfMovedPallets,
      usedFleetCapacity,
      palletMovementPrice,
      margin,
      numberOfAccidents,
      avgReparationCost,
      daysDownAfterCrash,
      complianceFees,
      estimatedGainsInPalletMoved,
      estimatedReductionInAccidents,

      Dimensions.twoD
    );

    const threeDCalc = new Calc(
      data,

      warehouse.country || 'PT',
      floorplan.targetNumberOfForklifts || 100,
      contractType,
      floorplan.anchorType || 'wired',
      floorplan.tracking || 'indoor',
      floorplan.connectivity || 'wifi',
      additionalServices,
      floorplan.floorplanArea || 1000,
      subscriptionType,
      hardwareOption,
      pilotDuration,
      floorplan.aisleLength || 200,
      floorplan.numberOfAisles || 50,
      (floorplan.openAreasSpace || [500]).length,
      floorplan.openAreasSpace || [500],

      goal,
      warehouse.numberOfShifts || 3,
      warehouse.shiftHours || 8,
      warehouse.workingDays || 225,
      warehouse.targetForkliftType || 'electric',
      numberOfMovedPallets,
      usedFleetCapacity,
      palletMovementPrice,
      margin,
      numberOfAccidents,
      avgReparationCost,
      daysDownAfterCrash,
      complianceFees,
      estimatedGainsInPalletMoved,
      estimatedReductionInAccidents,

      Dimensions.threeD
    );

    const browser = detect();
    let url;

    if (report !== undefined) {
      const blob = new Blob([report], { type: 'application/pdf' });

      if (
        browser &&
        (browser.name === 'chrome' ||
          browser.name === 'firefox' ||
          browser.name === 'edge' ||
          browser.name === 'safari') &&
        browser.os !== 'Android OS' &&
        browser.os !== 'BlackBerry OS' &&
        browser.os !== 'Windows Mobile' &&
        browser.os !== 'iOS'
      ) {
        url = window.URL.createObjectURL(blob);
      } else {
        FileSaver.saveAs(blob, 'report.pdf');
      }
    }

    return (
      <>
        <Divider style={{ marginBottom: '20px' }} />
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={3}>
              <Card>
                <CardContent>
                  {contractType === 'partner' ? (
                    <>
                      <FormControl
                        required
                        fullWidth
                        variant="standard"
                        margin="normal"
                      >
                        <InputLabel htmlFor="form-subscription_type">
                          Subscription Type
                        </InputLabel>
                        <Select
                          id="form-subscription_type"
                          value={subscriptionType}
                          label="Subscription Type"
                          onChange={this.handleChange('subscriptionType')}
                        >
                          {data.packages.map((s) => (
                            <MenuItem key={s.code} value={s.code}>
                              {s.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        required
                        fullWidth
                        variant="standard"
                        margin="normal"
                      >
                        <InputLabel htmlFor="form-hardware_option">
                          Hardware Option
                        </InputLabel>
                        <Select
                          id="form-hardware_option"
                          value={hardwareOption}
                          label="Hardware Option"
                          onChange={this.handleChange('hardwareOption')}
                        >
                          <MenuItem value="leased">Leased</MenuItem>
                          <MenuItem value="purchased">Purchased</MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <FormControl required fullWidth margin="normal">
                      <TextField
                        label="Pilot duration (months)"
                        value={pilotDuration}
                        variant="standard"
                        type="number"
                        inputProps={{ min: 0, max: 6 }}
                        onChange={this.handleChange('pilotDuration')}
                      />
                    </FormControl>
                  )}
                  <FormControl
                    required
                    fullWidth
                    variant="standard"
                    margin="normal"
                  >
                    <InputLabel htmlFor="form-adicional_services">
                      Adicional Services
                    </InputLabel>
                    <Select
                      id="form-adicional_services"
                      value={additionalServicesTemp}
                      label=""
                      onChange={this.handleChange('additionalServices')}
                    >
                      {data.services.map((s) => (
                        <MenuItem key={s.code} value={s.code}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {additionalServices.map(
                    (service: IAdditionalService, index: number) => (
                      <Fragment key={index}>
                        <FormControl required fullWidth margin="normal">
                          <TextField
                            label={`${service.name}: Number of days`}
                            value={service.days}
                            variant="standard"
                            type="number"
                            inputProps={{ min: 1 }}
                            onChange={this.handleServiceChange(index, 'days')}
                          />
                        </FormControl>
                        <i
                          role="button"
                          className="fas fa-trash fa-lg"
                          aria-label=""
                          onClick={this.removeAdditionalService(index)}
                          onKeyDown={this.removeAdditionalService(index)}
                          tabIndex={0}
                        >
                          {' '}
                        </i>
                      </Fragment>
                    )
                  )}
                </CardContent>
              </Card>

              <Box mt={2}>
                <Card>
                  <CardContent>
                    <Typography variant="body2">Goals</Typography>

                    <FormControl required fullWidth margin="normal">
                      <Select
                        value={goal}
                        variant="standard"
                        label="Goal"
                        onChange={this.handleChange('goal')}
                      >
                        {goals.map((s) => (
                          <MenuItem key={s.code} value={s.code}>
                            {s.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </CardContent>
                </Card>
              </Box>

              <Box mt={2}>
                <Card>
                  <CardContent>
                    <Typography variant="body2">Financial</Typography>

                    <FormControl required fullWidth margin="normal">
                      <TextField
                        label="Pallet movement Price"
                        value={palletMovementPrice}
                        variant="standard"
                        type="number"
                        onChange={this.handleChange('palletMovementPrice')}
                      />
                    </FormControl>
                    <FormControl required fullWidth margin="normal">
                      <TextField
                        label="Margin - %"
                        value={margin}
                        variant="standard"
                        type="number"
                        inputProps={{ min: 0, max: 100, step: 0.01 }}
                        onChange={this.handleChange('margin')}
                      />
                    </FormControl>
                  </CardContent>
                </Card>
              </Box>

              <Box mt={2}>
                <Card>
                  <CardContent>
                    <Typography variant="body2">Gains</Typography>

                    <FormControl required fullWidth margin="normal">
                      <TextField
                        label="Estimated gain in Pallets moved (%)"
                        value={estimatedGainsInPalletMoved}
                        variant="standard"
                        type="number"
                        inputProps={{ min: 0, max: 100, step: 0.01 }}
                        onChange={this.handleChange(
                          'estimatedGainsInPalletMoved'
                        )}
                      />
                    </FormControl>
                    <FormControl required fullWidth margin="normal">
                      <TextField
                        label="Estimated reduction in FL Accidents (%)"
                        value={estimatedReductionInAccidents}
                        variant="standard"
                        type="number"
                        inputProps={{ min: 0, max: 100, step: 0.01 }}
                        onChange={this.handleChange(
                          'estimatedReductionInAccidents'
                        )}
                      />
                    </FormControl>
                  </CardContent>
                </Card>
              </Box>

              <Box mt={2}>
                <Card>
                  <CardContent>
                    <Typography variant="body2">Safety & Compliance</Typography>

                    <FormControl required fullWidth margin="normal">
                      <TextField
                        label="Number of accidents (FL / Year)"
                        value={numberOfAccidents}
                        variant="standard"
                        type="number"
                        inputProps={{ min: 0 }}
                        onChange={this.handleChange('numberOfAccidents')}
                      />
                    </FormControl>
                    <FormControl required fullWidth margin="normal">
                      <TextField
                        label="Average reparation cost (due to crash) / FL"
                        value={avgReparationCost}
                        variant="standard"
                        type="number"
                        inputProps={{ min: 0, step: 0.01 }}
                        onChange={this.handleChange('avgReparationCost')}
                      />
                    </FormControl>
                    <FormControl required fullWidth margin="normal">
                      <TextField
                        label="Days down after crash"
                        value={daysDownAfterCrash}
                        variant="standard"
                        type="number"
                        inputProps={{ min: 0, max: 365 }}
                        onChange={this.handleChange('daysDownAfterCrash')}
                      />
                    </FormControl>
                    <FormControl required fullWidth margin="normal">
                      <TextField
                        label="Total Compliance fees - Annual"
                        value={complianceFees}
                        variant="standard"
                        type="number"
                        inputProps={{ min: 0, step: 0.01 }}
                        onChange={this.handleChange('complianceFees')}
                      />
                    </FormControl>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} md={9} lg={9}>
              <Card>
                <CardHeader
                  title={
                    <FormattedMessage
                      id="dashboard.financials.title"
                      defaultMessage="Financial Details"
                    />
                  }
                />
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell>2D</TableCell>
                        <TableCell>3D</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={2}>
                          Number of Anchor / Corridor
                        </TableCell>
                        <TableCell>
                          {twoDCalc.getNumberOfCorridorAnchors()}
                        </TableCell>
                        <TableCell>
                          {threeDCalc.getNumberOfCorridorAnchors()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          Number of Anchor / Open area
                        </TableCell>
                        <TableCell>
                          {twoDCalc.getNumberOfOpenAreasAnchors()}
                        </TableCell>
                        <TableCell>
                          {threeDCalc.getNumberOfOpenAreasAnchors()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          Total number of Anchors
                        </TableCell>
                        <TableCell>{twoDCalc.getTotalOfAnchors()}</TableCell>
                        <TableCell>{threeDCalc.getTotalOfAnchors()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>Total number of Tags</TableCell>
                        <TableCell>
                          {floorplan.targetNumberOfForklifts || 100}
                        </TableCell>
                        <TableCell>
                          {floorplan.targetNumberOfForklifts || 100}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>Space / Anchor</TableCell>
                        <TableCell>{twoDCalc.getAncorAreaRatio()}</TableCell>
                        <TableCell>{threeDCalc.getAncorAreaRatio()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ verticalAlign: 'middle' }}
                          rowSpan={3}
                        >
                          <b className="vertical-label">Hardware Cost</b>
                        </TableCell>
                        <TableCell>Cost (Anchor)</TableCell>
                        <TableCell>
                          {formatPrice(twoDCalc.getAnchorsCosts())}
                        </TableCell>
                        <TableCell>
                          {formatPrice(threeDCalc.getAnchorsCosts())}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Cost (Tag)</TableCell>
                        <TableCell>
                          {formatPrice(twoDCalc.getTagsCosts())}
                        </TableCell>
                        <TableCell>
                          {formatPrice(threeDCalc.getTagsCosts())}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Additonal materials</TableCell>
                        <TableCell>
                          {formatPrice(twoDCalc.getAdditionalCosts())}
                        </TableCell>
                        <TableCell>
                          {formatPrice(threeDCalc.getAdditionalCosts())}
                        </TableCell>
                      </TableRow>
                      <TableRow className="bold">
                        <TableCell colSpan={2}>
                          Leasing option (Yearly)
                        </TableCell>
                        <TableCell>
                          {twoDCalc.getLeasingCost()
                            ? formatPrice(twoDCalc.getLeasingCost())
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {threeDCalc.getLeasingCost()
                            ? formatPrice(threeDCalc.getLeasingCost())
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow className="bold">
                        <TableCell colSpan={2}>Software Cost</TableCell>
                        <TableCell>
                          {contractType === 'pilot' ? 'One fee' : 'Yearly'}
                        </TableCell>
                        <TableCell>
                          {formatPrice(threeDCalc.getSoftwareCost())}
                        </TableCell>
                      </TableRow>
                      {contractType !== 'pilot' && (
                        <TableRow className="bold">
                          <TableCell colSpan={2}>Package</TableCell>
                          <TableCell>{threeDCalc.getPackage().name}</TableCell>
                          <TableCell>
                            {threeDCalc.getPackage().cost
                              ? formatPrice(threeDCalc.getPackage().cost)
                              : '-'}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell
                          style={{ verticalAlign: 'middle' }}
                          rowSpan={contractType === 'pilot' ? 5 : 3}
                        >
                          <b className="vertical-label">Labour Cost</b>
                        </TableCell>
                        <TableCell>Anchor Installation</TableCell>
                        <TableCell>
                          {formatPrice(twoDCalc.getAnchorsInstallationCosts())}
                        </TableCell>
                        <TableCell>
                          {formatPrice(
                            threeDCalc.getAnchorsInstallationCosts()
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Tag Installation</TableCell>
                        <TableCell>
                          {formatPrice(twoDCalc.getTagInstallationCosts())}
                        </TableCell>
                        <TableCell>
                          {formatPrice(threeDCalc.getTagInstallationCosts())}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Trip expenses</TableCell>
                        <TableCell>
                          {formatPrice(twoDCalc.getTripExpenses('install'))}
                        </TableCell>
                        <TableCell>
                          {formatPrice(threeDCalc.getTripExpenses('install'))}
                        </TableCell>
                      </TableRow>
                      {contractType === 'pilot' && (
                        <>
                          <TableRow>
                            <TableCell>Uninstallement</TableCell>
                            <TableCell>
                              {formatPrice(twoDCalc.getUninstallementCosts())}
                            </TableCell>
                            <TableCell>
                              {formatPrice(threeDCalc.getUninstallementCosts())}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Un. Trip expenses</TableCell>
                            <TableCell>
                              {formatPrice(
                                twoDCalc.getTripExpenses('uninstall')
                              )}
                            </TableCell>
                            <TableCell>
                              {formatPrice(
                                threeDCalc.getTripExpenses('uninstall')
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      <TableRow>
                        <TableCell
                          style={{ verticalAlign: 'middle' }}
                          rowSpan={1 + additionalServices.length}
                        >
                          <b className="vertical-label">
                            Services
                            <br />
                            Additional
                          </b>
                        </TableCell>
                        <TableCell>Maintenance (Yearly)</TableCell>
                        <TableCell>
                          {formatPrice(data.maintenanceCosts.twoDimension)}
                        </TableCell>
                        <TableCell>
                          {formatPrice(data.maintenanceCosts.threeDimension)}
                        </TableCell>
                      </TableRow>
                      {additionalServices.map((service: IAdditionalService) => (
                        <TableRow key={service.code}>
                          <TableCell>{service.name}</TableCell>
                          <TableCell>
                            <b>One fee</b>
                          </TableCell>
                          <TableCell>
                            {formatPrice(service.cost * service.days)}
                          </TableCell>
                        </TableRow>
                      ))}
                      {contractType === 'pilot' && (
                        <TableRow className="bold">
                          <TableCell colSpan={2}>Pilot Cost</TableCell>
                          <TableCell>
                            {formatPrice(twoDCalc.getPilotCost())}
                          </TableCell>
                          <TableCell>
                            {formatPrice(threeDCalc.getPilotCost())}
                          </TableCell>
                        </TableRow>
                      )}
                      {contractType === 'partner' && (
                        <>
                          <TableRow className="bold">
                            <TableCell colSpan={2}>Upfront cost</TableCell>
                            <TableCell>
                              {formatPrice(twoDCalc.getPartnerUpfrontCost())}
                            </TableCell>
                            <TableCell>
                              {formatPrice(threeDCalc.getPartnerUpfrontCost())}
                            </TableCell>
                          </TableRow>
                          <TableRow className="bold">
                            <TableCell colSpan={2}>Subscription Cost</TableCell>
                            <TableCell>
                              {formatPrice(
                                twoDCalc.getPartnerSubscriptionCost()
                              )}
                            </TableCell>
                            <TableCell>
                              {formatPrice(
                                threeDCalc.getPartnerSubscriptionCost()
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>

              <Box mt={2}>
                <Result calc={threeDCalc} />
              </Box>

              <Box mt={2}>
                <ROIResult twoDCalc={twoDCalc} threeDCalc={threeDCalc} />
              </Box>

              <Box
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                mt={2}
              >
                <LoadingButton
                  loading={loading}
                  onClick={this.generatePdf}
                  variant="contained"
                  color="secondary"
                >
                  <span style={{ display: 'flex', verticalAlign: 'center' }}>
                    <PictureAsPdfOutlined />
                    <span style={{ paddingLeft: '5px' }}>Generate Report</span>
                  </span>
                </LoadingButton>
              </Box>

              <Box mt={2}>
                <Dialog
                  open={showPDF}
                  maxWidth="lg"
                  fullWidth
                  onClose={this.handlePDFClose}
                >
                  <DialogTitle>
                    ROI Report
                    <IconButton
                      aria-label="close"
                      onClick={this.handlePDFClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    {(loading || report) && <PDF loading={loading} url={url} />}
                  </DialogContent>
                </Dialog>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

export default FinacialsTab;
