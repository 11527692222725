import React, { Component } from 'react';

import { Notification } from '@models/Notification';
import RuleAlert from './RuleAlert';

import '../index.css';

export interface IProps {
  min: number;
  max: number;
  onClick: (event: any) => void;
  ruleAlerts: Notification[];
  value: number;
}

class RuleAlertsSlider extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.getRelativePosition = this.getRelativePosition.bind(this);
  }

  public shouldComponentUpdate(nextProps: any) {
    const { max, min, ruleAlerts } = this.props;

    return (
      JSON.stringify(ruleAlerts) !== JSON.stringify(nextProps.ruleAlerts) ||
      nextProps.min !== min ||
      nextProps.max !== max
    );
  }

  /**
   * @description Calculates the relative position in the event line for an event starting with the given ts
   * @param       {number} start Event start ts
   */
  private getRelativePosition(start: number) {
    const { min, max } = this.props;

    return ((start - min) * 100) / (max - min);
  }

  public render() {
    const { onClick, ruleAlerts } = this.props;

    return (
      <div
        className="rule-alerts-slider"
        role="button"
        aria-label=" "
        tabIndex={0}
        onClick={onClick}
        onKeyDown={onClick}
      >
        {ruleAlerts.map((ea, index) => {
          const left = this.getRelativePosition(ea.ts);

          return (
            left <= 100 &&
            left >= 0 && <RuleAlert key={index} alert={ea} left={left} />
          );
        })}
      </div>
    );
  }
}

export default RuleAlertsSlider;
