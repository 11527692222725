import { Color, Path, Point, Matrix } from 'paper';
import { Component } from 'react';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { transformMetersToPixels } from '@dashboard_utils/index';
import Paper from '../../Paper';
import MapImages from '../../MapImages';
import { IPathFeature } from '../../../types';
import { defaultTransformationMatrix } from '../../../consts';

interface IProps {
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  paper: Paper;
}

class RackMapLayer extends Component<IProps> {
  private elements: IPathFeature[] = [];

  public componentDidMount() {
    this.load();
  }

  public componentWillUnmount() {
    this.clear();
  }

  public load() {
    const { floorplan, mapImages, paper } = this.props;

    paper.scope.activate();

    (floorplan.racks || []).forEach((rack, index) => {
      const path = new Path() as IPathFeature;
      path.strokeColor = new Color('#008000');
      path.strokeWidth = 3;
      path.strokeCap = 'round';
      path.dashArray = [10, 15];
      path.fillColor = new Color('#00800040');
      rack.coordinates.forEach((c) =>
        path.add(
          new Point(
            transformMetersToPixels(
              c as [number, number],
              floorplan.transformationMatrix || defaultTransformationMatrix,
              floorplan.scale || 1
            )
          )
        )
      );
      path.strokeScaling = false;
      path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
      path.closePath();

      path.featureInfo = {
        id: `rack_${index}`,
        title: rack.meta.name || `Rack #${index}`,
        type: 'rack',
      };

      this.elements.push(path);
    });
  }

  public clear() {
    this.elements.forEach((e) => e.remove());

    this.elements = [];
  }

  public render() {
    return null;
  }
}

export default RackMapLayer;
