import Card from '@mui/material/Card';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { IOrdersEvents } from '@api/websocket';

import './index.css';

export interface IProps {
  start: number;
  length: number;
  ordersEvents?: IOrdersEvents[];
}

interface IState {
  showOrderEvents: boolean;
}

class OrderSlider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showOrderEvents: false,
    };

    this.onShowClick = this.onShowClick.bind(this);
  }
  
  public onShowClick() {
    this.setState((currentState: IState) => ({
      showOrderEvents: !currentState.showOrderEvents,
    }));
  }

  private getRelativePosition(position: number) {
    const { start, length } = this.props;

    return ((position - start) * 100) / (length - start);
  }

  private getRelativeWidth(position1: number, position2: number) {
    const { start, length } = this.props;

    const interval = position2 - position1;

    return (interval * 100) / (length - start);
  }

  private getRelativePositionForInner(position: number, start: number, end: number) {
    return ((position - start) * 100) / (end - start);
  }

  public render() {
    const { ordersEvents } = this.props;
    const { showOrderEvents } = this.state;

    const orders: Record<string, IOrdersEvents[]> = {};
    (ordersEvents || []).forEach((o: IOrdersEvents) => {
      if (!orders[o.orderId]) {
        orders[o.orderId] = [];
      }

      orders[o.orderId].push(o);
    });

    return ordersEvents && ordersEvents!.length ? (
      <Card
        style={{ maxHeight: showOrderEvents ? '250px' : '24px' }}
      >
        <div
          className="activity-toggler"
          role="button"
          aria-label=" "
          tabIndex={0}
          onClick={this.onShowClick}
          onKeyDown={() => null}
        >
          {showOrderEvents ? (
            <FormattedMessage
              id="dashboard.events.timeline.hide_order_events"
              defaultMessage="Hide Order Events"
            />
          ) : (
            <FormattedMessage
              id="dashboard.events.timeline.show_order_events"
              defaultMessage="Show Order Events"
            />
          )}
          {showOrderEvents ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
        {showOrderEvents && (
          <div className="order-events">
            {
              Object.keys(orders).map((k) => {
                orders[k].sort((a, b) => a.ts - b.ts);

                let left = this.getRelativePosition(orders[k][0].ts);
                let width = this.getRelativeWidth(
                  orders[k][0].ts,
                  orders[k][orders[k].length - 1].ts
                );

                return (
                  <div key={k} className="order" style={{
                    marginLeft: `${left}%`,
                    width: `${width}%`,
                  }}>
                    {
                      orders[k].map((o) => new Array(o.items).fill(null).map((_, i) => (
                        <div
                          key={i}
                          className={o.eventtype === 'order_created'
                            ? 'creation'
                            : o.eventtype === 'item_assigned'
                              ? 'assigned'
                              : o.eventtype === 'item_picked'
                                ? 'picked'
                                : 'fulfill'}
                          style={{ marginLeft: `${this.getRelativePositionForInner(
                            o.ts,
                            orders[k][0].ts,
                            orders[k][orders[k].length - 1].ts
                          )}%` }}
                        ></div>
                      )))
                    }
                  </div>
                )
              })
            }
          </div>
        )}
      </Card>
    ) : null;
  }
}

export default OrderSlider;
