import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { login } from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import LoginPage from './LoginPage';

import './index.css';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account,
  error: state.authentication.login.error,
  language: getActiveLanguage(state),
  loading: state.authentication.login.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    login: (email: string, password: string) =>
      dispatch(login.request({ email, password })),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
