import { kdTree } from 'kd-tree-javascript';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import BuildIcon from '@mui/icons-material/Build';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import HouseIcon from '@mui/icons-material/House';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import TableChartIcon from '@mui/icons-material/TableChart';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import SaveIcon from '@mui/icons-material/Save';
import React, { Component, Fragment, FunctionComponent, useState } from 'react';
import { useDrag, useDrop, XYCoord } from 'react-dnd';
import { IntlShape } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import uuid from 'uuid/v4';

import { ICreateDiagram, IUpdateDiagram } from '@actions/diagrams';
import ColorPicker from '@app/common/MaterialUIColorPicker/ColorPicker';
import Asset from '@models/Asset';
import Diagram, { DiagramItem, Diagrams } from '@models/Diagram';
import { Employee } from '@models/Employee';
import Warehouse from '@models/Warehouse';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Zone from '@models/Zone';
import DrawItem, { itemWidth, itemHeight } from './DrawItem';

import './Composer.css';

interface Point {
  x: number;
  y: number;
}
const distance = (a: Point, b: Point) => (a.x - b.x) ** 2 + (a.y - b.y) ** 2;

interface DrawZoneProps {
  handleClick: (event: any, id: string) => void;
  items: Record<string, DiagramItem>;
  onDrop: (element: DiagramItem) => void;
  updateItemPosition: (id: string, x: number, y: number) => void;
  updateItemConnections: (id: string, connections: string[]) => void;
}
const DrawZone: FunctionComponent<DrawZoneProps> = ({
  handleClick,
  items,
  onDrop,
  updateItemConnections,
  updateItemPosition,
}: DrawZoneProps) => {
  const [position, updatePosition] = useState({ x: -1280, y: -1024 });
  const [drag, updateDrag] = useState({
    startPositionX: 0,
    startPositionY: 0,
    dragging: false,
  });

  const { x, y } = position;
  const { dragging, startPositionX, startPositionY } = drag;

  const onMouseDown = (event: any) => {
    if (((event.target || {}).classList || [])[0] === 'composer-dragger') {
      updateDrag({
        startPositionX: event.pageX,
        startPositionY: event.pageY,
        dragging: true,
      });
    }
  };

  const onMouseMove = (event: any) => {
    if (!dragging) {
      return;
    }

    const diffX = (startPositionX || 0) - event.pageX;
    const diffY = (startPositionY || 0) - event.pageY;

    const element = document.getElementsByClassName('composer-container')[0];

    const maxWidth = 7680 - element.clientWidth;
    const maxHeight = 4320 - element.clientHeight;

    let vX = x - diffX;
    if (vX > 0) {
      vX = 0;
    }
    if (vX < -maxWidth) {
      vX = -maxWidth;
    }
    let vY = y - diffY;
    if (vY > 0) {
      vY = 0;
    }
    if (vY < -maxHeight) {
      vY = -maxHeight;
    }

    updateDrag({
      startPositionX: event.pageX,
      startPositionY: event.pageY,
      dragging: true,
    });
    updatePosition({ x: vX, y: vY });
  };

  const onMouseUp = () => {
    updateDrag({ startPositionX, startPositionY, dragging: false });
  };

  const [, drop] = useDrop({
    accept: 'item',
    drop: (item: DiagramItem, monitor) => {
      const offset = monitor.getSourceClientOffset() || ({} as XYCoord);

      onDrop({
        ...item,
        position: {
          x: offset.x - x,
          y: offset.y - y,
        },
      });
    },
  });

  return (
    <div className="composer-container" ref={drop}>
      <div
        aria-hidden
        className="composer-dragger"
        style={{
          top: y,
          left: x,
          cursor: dragging ? 'move' : 'default',
          width: '7680px',
          height: '4320px',
        }}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onBlur={onMouseUp}
        onMouseOut={onMouseUp}
      >
        {Object.values(items).map((i) => (
          <DrawItem
            key={i.id}
            item={i}
            updateItemPosition={updateItemPosition}
            updateItemConnections={updateItemConnections}
            handleClick={handleClick}
          />
        ))}
        <svg
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
          }}
        >
          {Object.values(items).map((i, b) => {
            const { x: x2, y: y2 } = i.position || { x: 0, y: 0 };

            /**
             * Injects all corners of the item element and the middle points
             * into the kdTree to access witch one is the closest to the equivalent
             * in the connected element
             */
            const pointsL1 = [
              { x: x2, y: y2 },
              { x: x2 + itemWidth / 2, y: y2 },
              { x: x2 + itemWidth, y: y2 },
              { x: x2 + itemWidth, y: y2 + itemHeight / 2 },
              { x: x2 + itemWidth, y: y2 + itemHeight },
              { x: x2 + itemWidth / 2, y: y2 + itemHeight },
              { x: x2 + itemWidth, y: y2 + itemHeight },
              { x: x2 + itemWidth, y: y2 + itemHeight / 2 },
            ];

            return (
              <Fragment key={b}>
                {(i.connections || []).map((c, a) => {
                  const { x: x1, y: y1 } = (items[c] || {}).position || {
                    x: 0,
                    y: 0,
                  };
                  const color = (items[c] || {}).color || '#666';

                  let closestA = {} as Point;
                  let closestB = {} as Point;
                  let closestDistance = Infinity;
                  // eslint-disable-next-line new-cap
                  const tree = new kdTree(
                    [
                      { x: x1, y: y1 },
                      { x: x1 + itemWidth / 2, y: y1 },
                      { x: x1 + itemWidth, y: y1 },
                      { x: x1 + itemWidth, y: y1 + itemHeight / 2 },
                      { x: x1 + itemWidth, y: y1 + itemHeight },
                      { x: x1 + itemWidth / 2, y: y1 + itemHeight },
                      { x: x1 + itemWidth, y: y1 + itemHeight },
                      { x: x1 + itemWidth, y: y1 + itemHeight / 2 },
                    ],
                    distance,
                    ['x', 'y']
                  );

                  pointsL1.forEach((p) => {
                    const nearest = tree.nearest(p, 1);
                    const [point, d] = nearest[0] || [];
                    if (d < closestDistance) {
                      closestA = p;
                      closestB = point;
                      closestDistance = d;
                    }
                  });

                  return (
                    <Fragment key={`pointer_${a}_${b}`}>
                      <defs>
                        <marker
                          id={`pointer_${a}_${b}`}
                          markerWidth="20"
                          markerHeight="14"
                          refX="0"
                          refY="7"
                          orient="auto"
                        >
                          <polygon points="20 0, 20 14, 0 7" fill={color} />
                        </marker>
                      </defs>

                      <line
                        key={`${a}_${b}`}
                        x1={closestA.x || 0}
                        y1={closestA.y || 0}
                        x2={closestB.x || 0}
                        y2={closestB.y || 0}
                        stroke={color}
                        markerStart={`url(#pointer_${a}_${b})`}
                      />
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
        </svg>
      </div>
    </div>
  );
};

interface IDrawItem {
  type: string;
  data: any;
}
/**
 * Items presented into the draw options
 */
const ListItemC: FunctionComponent<IDrawItem> = ({ type, data }: IDrawItem) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type, data },
    type: 'item',
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  let icon;
  switch (type) {
    case 'warehouse':
      icon = <HouseIcon />;
      break;
    case 'floorplan':
      icon = <TableChartIcon />;
      break;
    case 'zone':
      icon = <AspectRatioIcon />;
      break;
    case 'asset':
      icon = <BuildIcon />;
      break;
    case 'employee':
      icon = <PersonPinIcon />;
      break;
    default:
      icon = <AddIcon />;
      break;
  }

  return (
    <ListItem button ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={data.name} />
    </ListItem>
  );
};

interface IProps {
  assets: Asset[];
  diagrams: Diagrams;
  employees: Employee[];
  warehouses: Warehouse[];
  floorplans: IncompleteFloorplan[];
  zones: Zone[];
  intl: IntlShape;
  createDiagram: (properties: ICreateDiagram) => void;
  updateDiagram: (properties: IUpdateDiagram) => void;
  router: any;
  language: string;
  redirectId?: string;
}
interface IState {
  anchorEl?: Element;
  id?: string;
  items: Record<string, DiagramItem>;
  name: string;
  toDelete: boolean;
  changed: boolean;
  selectedFeature?: string;
}
class DiagramComposer extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { diagrams, router } = props;

    const diagram = diagrams[router.params.id] || ({} as Diagram);

    this.state = {
      id: diagram.id,
      items: diagram.diagram || {},
      name: diagram.name || `Diagram #${Object.keys(diagrams).length + 1}`,
      toDelete: false,
      changed: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.showName = this.showName.bind(this);
    this.hideName = this.hideName.bind(this);
    this.updateItemPosition = this.updateItemPosition.bind(this);
    this.updateItemConnections = this.updateItemConnections.bind(this);
    this.activateDelete = this.activateDelete.bind(this);
    this.save = this.save.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { diagrams, router, language, redirectId } = this.props;
    const { id } = this.state;

    if (!prevProps.redirectId && !!redirectId) {
      router.navigate(
        `/${language}/dashboard/diagrams/diagram/${redirectId}/edit`
      );
    }

    if (id !== router.params.id) {
      const diagram = diagrams[router.params.id];

      if (diagram) {
        this.updateDiagram(diagram);
      }
    }
  }

  public handleClose() {
    return this.setState({ selectedFeature: undefined });
  }

  public handleClick(event: any, id: string) {
    const { toDelete, items } = this.state;

    if (!toDelete) {
      return this.setState({ selectedFeature: id });
    }

    const newItems = JSON.parse(JSON.stringify(items)) as Record<
      string,
      DiagramItem
    >;
    delete newItems[id];
    Object.values(newItems).forEach((i) => {
      const index = (i.connections || []).indexOf(id);
      if (index !== -1) {
        (newItems[i.id].connections || []).splice(index, 1);
      }
    });

    return this.setState({ items: newItems, toDelete: false });
  }

  public handleNameChange(event: any) {
    this.setState({ name: event.target.value, changed: true });
  }

  public handleDescriptionChange(id: string, event: any) {
    const { items } = this.state;

    this.setState({
      items: {
        ...items,
        [id]: { ...items[id], description: event.target.value },
      },
    });
  }

  public handleColorChange(id: string, color: string) {
    const { items } = this.state;

    this.setState({
      items: {
        ...items,
        [id]: { ...items[id], color },
      },
    });
  }

  public onDrop(element: DiagramItem) {
    const { items } = this.state;

    const id = uuid();

    this.setState({
      items: { ...items, [id]: { ...element, id } },
      changed: true,
    });
  }

  public updateDiagram(diagram: Diagram) {
    this.setState({
      id: diagram.id,
      name: diagram.name,
      items: diagram.diagram as Record<string, DiagramItem>,
    });
  }

  public updateItemPosition(id: string, x: number, y: number) {
    const { items } = this.state;

    this.setState({
      items: {
        ...items,
        [id]: {
          ...items[id],
          position: { x, y },
        },
      },
      changed: true,
    });
  }

  public updateItemConnections(id: string, connections: string[]) {
    const { items } = this.state;

    this.setState({
      items: {
        ...items,
        [id]: {
          ...items[id],
          connections,
        },
      },
      changed: true,
    });
  }

  public showName(event: any) {
    this.setState({ anchorEl: event.target });
  }

  public hideName() {
    this.setState({ anchorEl: undefined });
  }

  public activateDelete() {
    this.setState((prevState) => ({
      toDelete: !prevState.toDelete,
    }));
  }

  public save() {
    const { createDiagram, intl, updateDiagram } = this.props;
    const { id, name, items } = this.state;

    this.setState({ changed: false }, () => {
      if (id) {
        return updateDiagram({ id, name, diagram: items, intl });
      }

      return createDiagram({ name, diagram: items, intl });
    });
  }

  public render() {
    const {
      assets,
      diagrams,
      employees,
      language,
      router,
      warehouses,
      floorplans,
      zones,
    } = this.props;
    const { changed, toDelete, items, name, anchorEl, selectedFeature } =
      this.state;

    const diagram = diagrams[router.params.id] || {};

    return (
      <>
        <Grid
          container
          spacing={2}
          style={{ display: 'flex', flex: 1, maxHeight: '100%' }}
        >
          <Grid
            item
            sm={selectedFeature ? 6 : 9}
            style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{ marginBottom: '10px' }}
            >
              <Link
                color="inherit"
                to={`/${language}/dashboard/diagrams/`}
                component={RouterLink}
              >
                Diagrams
              </Link>
              <Typography color="textPrimary">
                {diagram.name || 'New diagram'}
              </Typography>
            </Breadcrumbs>

            <Card style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
              <div
                style={{
                  borderRight: '1px solid #EFEFEF',
                  backgroundColor: '#FCFCFC',
                  width: '48px',
                }}
              >
                <IconButton aria-label="Show Name" onClick={this.showName}>
                  <TextRotationNoneIcon />
                </IconButton>
                <IconButton
                  aria-label="Delete"
                  disabled={!Object.keys(items).length}
                  onClick={this.activateDelete}
                >
                  <DeleteIcon style={{ color: toDelete ? 'red' : undefined }} />
                </IconButton>
                <IconButton
                  aria-label="Save"
                  disabled={!changed}
                  onClick={this.save}
                >
                  <SaveIcon />
                </IconButton>
              </div>

              <DrawZone
                handleClick={this.handleClick}
                items={items}
                onDrop={this.onDrop}
                updateItemPosition={this.updateItemPosition}
                updateItemConnections={this.updateItemConnections}
              />
            </Card>
          </Grid>
          {selectedFeature && (
            <Grid item sm={3}>
              <Box mt={6}>
                <Card>
                  <CardHeader
                    title={((items[selectedFeature] || {}).data || {}).name}
                    action={
                      <IconButton aria-label="close" onClick={this.handleClose}>
                        <CloseIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <FormControl required fullWidth margin="normal">
                      <TextField
                        id="assets-form-name"
                        value={(items[selectedFeature] || {}).description || ''}
                        label="Description"
                        onChange={(event: any) =>
                          this.handleDescriptionChange(selectedFeature, event)
                        }
                      />
                    </FormControl>
                    <ColorPicker
                      value={(items[selectedFeature] || {}).color}
                      onChange={(color: string) =>
                        this.handleColorChange(selectedFeature, color)
                      }
                      label="Color"
                      error={false}
                    />
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          )}
          <Grid item sm={3} style={{ overflowY: 'scroll', height: '100%' }}>
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">Warehouses</ListSubheader>
              }
            >
              {warehouses.map((w) => (
                <ListItemC key={w.id} type="warehouse" data={w} />
              ))}
            </List>
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">Floorplans</ListSubheader>
              }
            >
              {floorplans.map((f) => (
                <ListItemC key={f.id} type="floorplan" data={f} />
              ))}
            </List>
            <List
              component="nav"
              subheader={<ListSubheader component="div">Zones</ListSubheader>}
            >
              {zones.map((z) => (
                <ListItemC key={z.id} type="zone" data={z} />
              ))}
            </List>
            <List
              component="nav"
              subheader={<ListSubheader component="div">Assets</ListSubheader>}
            >
              {assets.map((a) => (
                <ListItemC key={a.id} type="asset" data={a} />
              ))}
            </List>
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">Employees</ListSubheader>
              }
            >
              {employees.map((e) => (
                <ListItemC key={e.id} type="employee" data={e} />
              ))}
            </List>
          </Grid>
        </Grid>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={this.hideName}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Card style={{ width: '320px' }}>
            <CardHeader title="Diagram Name" />
            <CardContent>
              <FormControl required fullWidth margin="normal">
                <TextField
                  label="Name"
                  variant="standard"
                  value={name}
                  onChange={this.handleNameChange}
                />
              </FormControl>
            </CardContent>
          </Card>
        </Popover>
      </>
    );
  }
}

export default DiagramComposer;
