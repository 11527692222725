import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import MapGrid from './MapGrid';

const mapStateToProps = (state: IAppState) => ({
  filters: state.filter.filters,
});

export default connect(mapStateToProps)(MapGrid);
