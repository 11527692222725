import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import LoadingButton from '@app/common/LoadingButton/index';
import SensorInstalationForm from '../../forms/FloorplanConfigurationForm/SensorInstallation/SensorInstallationForm';

interface IProps {
  createFakeSensors: () => void;
  loading: boolean;
  nextDisabled: boolean;
  onBack: () => void;
  onNext: () => void;
  onRefresh: () => void;
}

const StepContainer: FunctionComponent<IProps> = ({
  createFakeSensors,
  loading,
  nextDisabled,
  onBack,
  onNext,
  onRefresh,
}: IProps) => (
  <Card className="step-card">
    <CardContent style={{ flex: 1, position: 'relative' }}>
      <div
        style={{
          bottom: 0,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 20,
        }}
      >
        <div
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <SensorInstalationForm createFakeSensors={createFakeSensors} loading={loading} installationComplete={!nextDisabled} />
          {!loading && !nextDisabled ?
            <Typography variant="subtitle2">
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.sensorinstallation.success"
                defaultMessage="Installation complete! Congrats!"
              />
            </Typography>
          : null}
        </div>
      </div>
    </CardContent>
    <CardActions style={{ justifyContent: 'flex-end' }}>
      <Box mr={1}>
        <Button onClick={onBack}>
          <FormattedMessage
            id="dashboard.setup.steps.button_back"
            defaultMessage="Back"
          />
        </Button>
      </Box>
      <Box mr={1}>
        <LoadingButton
          id="refresh"
          loading={loading}
          variant={nextDisabled ? 'contained' : 'text'}
          onClick={onRefresh}
          color={nextDisabled ? 'primary' : undefined}
        >
          <FormattedMessage
            id="dashboard.setup.steps.button_refresh"
            defaultMessage="Refresh"
          />
        </LoadingButton>
      </Box>
      <Box mr={1}>
        <Button
          variant="contained"
          onClick={onNext}
          color="primary"
          disabled={nextDisabled}
        >
          <FormattedMessage
            id="dashboard.setup.steps.button_next"
            defaultMessage="Next"
          />
        </Button>
      </Box>
    </CardActions>
  </Card>
);

export default StepContainer;
