import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closeTicketForm,
  createAssetTicket,
  CreateAssetTicket,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import TicketForm from './TicketForm';

const mapStateToProps = (state: IAppState) => ({
  loading: state.assets.tickets.ticketForm.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeTicketForm: () => dispatch(closeTicketForm()),
  createAssetTicket: (properties: CreateAssetTicket) =>
    dispatch(createAssetTicket.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketForm);
