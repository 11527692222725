import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { FunctionComponent } from 'react';
import { WidgetProps } from 'react-jsonschema-form';

import { Date, timeZone } from '@dashboard_utils/index';

const handleChange = (onChange: (value: any) => void) => (value: any) =>
  // TODO: the date must be converted to string, otherwise it will eventually become on object.
  //       This probably has to do with how react-jsonschema-form handles date, because they are
  //       defined as string, instead of the Date object.
  onChange((value || new Date(timeZone)).toLocaleString());

const DateWidget: FunctionComponent<WidgetProps> = (
  properties: WidgetProps
) => {
  const {
    schema: { description, title },
    required,
    value,
    onChange,
  } = properties;

  return (
    <FormControl required={required} fullWidth margin="normal">
      <DatePicker
        label={title}
        onChange={handleChange(onChange)}
        value={value}
        slotProps={{
          textField: { variant: 'standard' }
        }}
      />
      <FormHelperText>{description}</FormHelperText>
    </FormControl>
  );
};

export default DateWidget;
