import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IUpdateFloorplan } from '@actions/index';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '@app/dialogs/ConfirmDialog/types';
import { Date } from '@dashboard_utils/index';
import IncompleteFloorplan, { ItemLocation } from '@models/IncompleteFloorplan';
import { MapFeature, MapMetricData } from '../../types';
import DrawForm from '../../../../forms/DrawForm';
import ZoneForm from '../../../../forms/ZoneForm';
import ItemLocationForm from '../../../../forms/ItemLocationForm';
import { getZoneTypeName } from '../../../../forms/ZoneForm/utils';
import messages from '../../messages';
import mapEvents from '../eventEmitter';

interface IFeatureUpdate {
  mapId: string;
  featureId: string;
  props: Record<string, any>;
}

export interface IProps {
  confirm: (properties: IConfirmDialog) => void;
  id: string;
  /* Map feature that is currectly being edited */
  editFeature?: MapFeature;
  /* Map feature that is currectly being displayed */
  feature?: MapFeature;
  filterId?: string;
  metricData?: MapMetricData;
  toggleZoneMetrics: (id?: string, filterId?: string) => void;
  deleteZone?: (id: string) => void;
  updateSelectedFeature: (id: string, feature?: MapFeature) => void;
  updateEditFeature: (id: string, feature?: MapFeature) => void;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
  floorplan: IncompleteFloorplan;
  warehouseTz: string;

  intl: IntlShape;

  router: any;
}

/**
 * Component responsible for displaying map side popup.
 */
class FeatureInfo extends Component<IProps> {
  private featureUpdate: Record<string, any> | undefined;

  constructor(props: IProps) {
    super(props);

    this.handleFeatureUpdate = this.handleFeatureUpdate.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  public componentDidMount() {
    mapEvents.on('update-feature', this.handleFeatureUpdate);
  }

  public componentDidUpdate(prevProps: any) {
    const {
      editFeature,
      feature,
      id,
      router,
      updateEditFeature,
    } = this.props;

    const currentFeature = feature || ({} as MapFeature);

    if (
      (((prevProps.feature || {}).featureInfo || {}).props || {}).id !==
      ((currentFeature.featureInfo || {}).props || {}).id
    ) {
      if (
        router.query.sensorId &&
        router.query.sensorId !== ((currentFeature.featureInfo || {}).props || {}).id
      ) {
        router.navigate(router.params['*']);
      }

      if (editFeature) {
        updateEditFeature(id);
      }
    }
  }

  public componentWillUnmount() {
    mapEvents.removeListener('update-feature', this.handleFeatureUpdate);
  }

  public handleFeatureUpdate(featureUpdate: IFeatureUpdate) {
    const { id } = this.props;

    if (id === featureUpdate.mapId) {
      this.featureUpdate = featureUpdate;

      this.forceUpdate();
    }
  }

  /**
   * @description Deletes currently displayed feature
   */
  public handleDelete() {
    const {
      confirm,
      deleteZone,
      feature,
      floorplan,
      id,
      intl,
      updateFloorplan,
      updateSelectedFeature,
    } = this.props;

    const props = feature!.featureInfo.props || {};

    if (feature!.featureInfo.type === 'zone' && deleteZone) {
      confirm({
        confirmType: ConfirmDialogActions.DELETE,
        message: intl.formatMessage({
          defaultMessage: 'Are you sure that you want to delete this zone?',
          id: 'dashboard.forms.zoneform.delete_message',
        }),
        onConfirmation: () => deleteZone(props.id),
      });
    }

    if (feature!.featureInfo.type === 'itemLocation' && updateFloorplan) {
      updateFloorplan({
        floorplanId: floorplan.id,
        warehouseId: floorplan.warehouseId,
        properties: {
          itemLocations: (floorplan.itemLocations || []).filter(
            (i) => i.id !== props.id
          ),
        },
        intl,
        shallow: true,
      });
    }

    updateSelectedFeature(id);
  }

  public handleEdit() {
    const { id, feature, updateEditFeature } = this.props;

    updateEditFeature(id, feature);
  }

  public handleCloseClick() {
    const {
      editFeature,
      id,
      router,
      updateEditFeature,
      updateSelectedFeature,
    } = this.props;

    router.navigate(router.location.pathname, { replace: true });

    if (editFeature) {
      updateEditFeature(id);
    }

    updateSelectedFeature(id);

    mapEvents.emit('featureinfo-close', id);
  }

  /**
   * @description Opens zone metrics popup by triggering action.
   * @param {string} id Zone id
   */
  public openZoneMetrics(id: string) {
    const { filterId, toggleZoneMetrics } = this.props;

    toggleZoneMetrics(id, filterId);
  }

  public render() {
    const {
      editFeature,
      feature,
      floorplan,
      id,
      intl,
      metricData,
      updateEditFeature,
      warehouseTz,
    } = this.props;

    if (editFeature) {
      let form = null;
      if (editFeature.featureInfo.type === 'zone') {
        form = (
          <div style={{ textAlign: 'left' }}>
            <ZoneForm
              id={(editFeature.featureInfo.props || {}).id}
              coordinates={editFeature.featureInfo.coordinates || []}
              floorplanId={floorplan.id}
              onClose={this.handleCloseClick}
            />
          </div>
        );
      }

      if (editFeature.featureInfo.type === 'itemLocation') {
        form = (
          <div style={{ textAlign: 'left' }}>
            <ItemLocationForm
              location={
                {
                  ...(editFeature.featureInfo.props || {}),
                  position:
                    editFeature.featureInfo.coordinates ||
                    (editFeature.featureInfo.props || {}).position,
                } as ItemLocation
              }
              floorplanId={floorplan.id}
              onClose={this.handleCloseClick}
            />
          </div>
        );
      }

      if (
        editFeature.featureInfo.type === 'boundary' ||
        editFeature.featureInfo.type === 'obstacle' ||
        editFeature.featureInfo.type === 'rack' ||
        editFeature.featureInfo.type === 'landmark'
      ) {
        form = (
          <DrawForm
            id={id}
            editFeature={editFeature}
            updateEditFeature={updateEditFeature}
          />
        );
      }

      return (
        <div className="live-map-feature-info">
          <Card style={{ margin: '3px', textAlign: 'left' }}>
            <CardHeader
              title={editFeature.featureInfo.title}
              action={
                <IconButton aria-label="Close" onClick={this.handleCloseClick}>
                  <CloseIcon />
                </IconButton>
              }
            />
            {form}
          </Card>
        </div>
      );
    }

    if (feature) {
      let zoneMenu = null;
      if (
        (feature.featureInfo.type === 'zone' ||
          feature.featureInfo.type === 'itemLocation') &&
        (feature.featureInfo.props || {}).id
      ) {
        zoneMenu = (
          <CardActions>
            <Button onClick={this.handleEdit}>
              <FormattedMessage
                id="dashboard.forms.zoneform.button_edit"
                defaultMessage="Edit"
              />
            </Button>
            <Button onClick={this.handleDelete}>
              <FormattedMessage
                id="dashboard.forms.zoneform.button_delete"
                defaultMessage="Delete"
              />
            </Button>
            {(metricData || {}).tab === 'zone_metrics' ? (
              <Button
                onClick={() =>
                  this.openZoneMetrics((feature.featureInfo.props || {}).id)
                }
              >
                <FormattedMessage
                  id="dashboard.forms.zoneform.button_stats"
                  defaultMessage="Zone Metrics"
                />
              </Button>
            ) : null}
          </CardActions>
        );
      }

      let fProps = feature.featureInfo.props || {};
      if (
        this.featureUpdate &&
        this.featureUpdate.featureId === feature.featureInfo.id
      ) {
        fProps = this.featureUpdate.props;
      }

      return (
        <div className="live-map-feature-info">
          <Card style={{ margin: '3px', textAlign: 'center' }}>
            <CardHeader
              title={
                <span
                  dangerouslySetInnerHTML={{
                    __html: feature.featureInfo.title,
                  }}
                />
              }
              action={
                <IconButton aria-label="Close" onClick={this.handleCloseClick}>
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent style={{ padding: 0 }}>
              <Table>
                <TableBody>
                  {Object.keys(fProps)
                    .filter(
                      (k) =>
                        (fProps.dtype === 'rmc' ||
                          fProps.dtype === 'uwb' ||
                          (k !== 'ts' && k !== 'dtype')) &&
                        k !== 'segment' &&
                        k !== 'direction_raw' &&
                        k !== 'moving'
                    )
                    .map((k) => {
                      let value = fProps[k];

                      if (k === 'color') {
                        value = fProps[k].toCSS && (
                          <div
                            style={{
                              backgroundColor: fProps[k].toCSS(true),
                              height: '20px',
                              width: '50px',
                            }}
                          />
                        );
                      } else if (k === 'created') {
                        value = new Date(warehouseTz, fProps[k]).format();
                      } else if (k === 'horizontalVelocity') {
                        value =
                          fProps.moving === false
                            ? intl.formatMessage(messages.stopped)
                            : fProps.horizontalVelocity;
                      } else if (
                        feature.featureInfo.type === undefined &&
                        k === 'type'
                      ) {
                        value = intl.formatMessage(
                          messages[`sensor_${fProps[k]}`] || messages.none
                        );
                      } else if (
                        feature.featureInfo.type === 'zone' &&
                        k === 'zoneType'
                      ) {
                        value = getZoneTypeName(fProps[k]);
                      } else if (
                        feature.featureInfo.type === 'itemLocation' &&
                        k === 'position'
                      ) {
                        value = (fProps[k] || []).map((p: any) => p.toFixed(2)).join(', ');
                      }

                      return (
                        <TableRow hover key={k}>
                          <TableCell>
                            {messages[k] ? intl.formatMessage(messages[k]) : k}
                          </TableCell>
                          <TableCell>{value}</TableCell>
                        </TableRow>
                      );
                    })}
                  {(feature.featureInfo.type === 'boundary' ||
                    feature.featureInfo.type === 'obstacle' ||
                    feature.featureInfo.type === 'rack') && (
                    <TableRow hover>
                      <TableCell>{intl.formatMessage(messages.type)}</TableCell>
                      <TableCell>
                        {intl.formatMessage(
                          messages[`type_${feature.featureInfo.type}`]
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </CardContent>
            {zoneMenu}
          </Card>
        </div>
      );
    }

    return null;
  }
}

export default injectIntl(FeatureInfo);
