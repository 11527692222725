import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { closeImportStocksForm, importStocks } from '@actions/index';
import { IImport } from '@actions/types';
import { IAppState } from '@reducers/index';
import { isImportFormLoading } from '@selectors/stocks';
import ImportStocksForm from './ImportStocksForm';

const mapStateToProps = (state: IAppState) => ({
  error: state.items.importStocksForm.error,
  loading: isImportFormLoading(state.items),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeImportStocksForm: () => dispatch(closeImportStocksForm()),
  importStocks: (properties: IImport) =>
    dispatch(importStocks.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportStocksForm);
