import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import React, { FunctionComponent } from 'react';

interface IProps {
  disableTour: () => void;
  disabledTour: boolean;
}

const Disabler: FunctionComponent<IProps> = ({
  disableTour,
  disabledTour,
}: IProps) => {
  const control = (
    <Checkbox
      color="primary"
      size="small"
      checked={disabledTour}
      onChange={disableTour}
    />
  );

  const label = (
    <Typography variant="caption">
      <FormattedMessage
        id="tutorial.login.welcome.continue"
        defaultMessage="Continue without help"
      />
    </Typography>
  );

  return <FormControlLabel label={label} control={control} />;
};

export default Disabler;
