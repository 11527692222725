import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { toggleZoneMetrics, updateSelectedFeature } from '@actions/index';
import { getMapById } from '@selectors/app';
import { IAppState } from '@reducers/index';
import { MapFeature } from '../../../types';
import Select from './Select';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    editFeature: map.editFeature,
    paper: map.paper,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleZoneMetrics: (id?: string, filterId?: string) =>
    dispatch(toggleZoneMetrics(id, filterId)),
  updateSelectedFeature: (id: string, feature?: MapFeature) =>
    dispatch(updateSelectedFeature(id, feature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Select);
