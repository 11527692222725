import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Color, Group, Path, Point, Matrix } from 'paper';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { RuleAlerts } from '@actions/rules';
import { Assets } from '@models/Asset';
import IncompleteFloorplan, {
  IncompleteFloorplans,
} from '@models/IncompleteFloorplan';
import { Rules } from '@models/Rule';
import { Warehouses } from '@models/Warehouse';
import { Zones } from '@models/Zone';
import { Date, transformMetersToPixels } from '@dashboard_utils/index';
import { formatMessage } from '../../../../../utils/ruleUtils';
import { defaultTransformationMatrix } from '../../../consts';
import {
  ICircleFeature,
  IFeatureInfo,
  IGroupFeature,
  IPathFeature,
} from '../../../types';
import { orderLayers } from '../../eventEmitter';
import MapImages from '../../MapImages';
import Paper from '../../Paper';

interface IData {
  ruleAlerts: RuleAlerts;
  loading: boolean;
}

interface IProps {
  id: string;
  assets: Assets;
  data: IData;
  floorplan: IncompleteFloorplan;
  floorplans: IncompleteFloorplans;
  mapImages: MapImages;
  paper: Paper;
  rules: Rules;
  warehouses: Warehouses;
  warehouseTz: string;
  zones: Zones;
}

interface IState {
  ruleId?: string;
}

class EventMapLayer extends Component<IProps, IState> {
  private elements: paper.Group[] = [];

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.handleRuleChange = this.handleRuleChange.bind(this);
  }

  public componentDidMount() {
    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { data } = this.props;

    if (prevProps.data.loading !== data.loading) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    this.clear();
  }

  public handleRuleChange(event: any) {
    this.setState({ ruleId: event.target.value }, this.reload);
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((e) => e.remove());
    this.elements = [];
  }

  public load() {
    const {
      assets,
      data,
      floorplans,
      floorplan,
      mapImages,
      paper,
      warehouses,
      warehouseTz,
      zones,
    } = this.props;
    const { ruleId } = this.state;

    paper.scope.activate();

    data.ruleAlerts.list
      .filter(
        (notification) =>
          (!ruleId || notification.ruleId === ruleId) &&
          notification.x !== null &&
          notification.x !== undefined &&
          notification.y !== null &&
          notification.y !== undefined
      )
      .forEach((notification) => {
        const featureInfo: IFeatureInfo = {
          coordinates: [[notification.x, notification.y]],
          id: `rulealert_${notification.id}`,
          title: formatMessage(
            {
              ...notification,
              messageInfo: { assets, floorplans, warehouses, zones },
            },
            true
          ),
          props: {
            start: new Date(warehouseTz, notification.ts).format(),
          },
          type: 'eventmap',
        };

        let color = new Color('black');
        if (notification.level === 'info') {
          color = new Color('blue');
        } else if (notification.level === 'warn') {
          color = new Color('orange');
        } else if (notification.level === 'critical') {
          color = new Color('red');
        }

        const center = new Point(
          transformMetersToPixels(
            [notification.x, notification.y],
            floorplan.transformationMatrix || defaultTransformationMatrix,
            floorplan.scale || 1
          )
        );

        const path = new Path() as IPathFeature;
        path.strokeColor = color;
        path.fillColor = new Color('#ffffff00');
        path.strokeWidth = 6;
        path.strokeScaling = true;
        path.add(new Point([center.x, center.y + 40]));
        path.add(new Point([center.x - 40, center.y - 40]));
        path.add(new Point([center.x + 40, center.y - 40]));
        path.closePath();
        path.featureInfo = featureInfo;

        path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
        const circle = new Path.Circle(
          new Point([center.x, center.y - 26]),
          7
        ) as ICircleFeature;
        circle.fillColor = color;
        circle.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
        circle.featureInfo = featureInfo;

        const exclamPath = new Path() as IPathFeature;
        exclamPath.fillColor = color;
        exclamPath.add(new Point([center.x + 5, center.y - 15]));
        exclamPath.add(new Point([center.x - 5, center.y - 15]));
        exclamPath.add(new Point([center.x - 5, center.y + 15]));
        exclamPath.add(new Point([center.x + 5, center.y + 15]));
        exclamPath.closePath();
        exclamPath.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
        exclamPath.featureInfo = featureInfo;

        const group = new Group([path, circle, exclamPath]) as IGroupFeature;
        group.featureInfo = featureInfo;

        this.elements.push(group);
      });

    orderLayers();
  }

  public render() {
    const { rules, data } = this.props;
    const { ruleId } = this.state;

    return (
      <div
        style={{
          position: 'absolute',
          top: '5px',
          left: '12px',
          minWidth: '250px',
        }}
      >
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="eventmaprules">
            <FormattedMessage
              id="dashboard.filter.label_rules"
              defaultMessage="Rules"
            />
          </InputLabel>
          <Select value={ruleId || ''} onChange={this.handleRuleChange}>
            {Object.values(rules || {}).map((r) => {
              const alertsCount = data.ruleAlerts.list.filter(
                (ra) => ra.ruleId === r.id
              ).length;

              return (
                <MenuItem key={r.id} value={r.id}>
                  {r.name}
                  {alertsCount !== 0 && (
                    <b style={{ marginLeft: '5px' }}>
                      <span>(</span>
                      {alertsCount}
                      <span>)</span>
                    </b>
                  )}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default EventMapLayer;
