import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import {
  confirmDialog,
  deleteShift,
  fetchShifts,
  fetchEmployees,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { withRouter } from '@app/utils/withRouter';
import ShiftsTab from './ShiftsTab';
import { getActiveLanguage } from '@selectors/app';

const mapStateToProps = (state: IAppState) => ({
  data: Object.values(state.shifts.shifts),
  loading: state.shifts.loading,
  language: getActiveLanguage(state),
  employees: state.employees.employees,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  deleteShift: (id: string, intl: IntlShape) =>
    dispatch(deleteShift.request({ id, intl })),
  fetchShifts: (shallow?: boolean) =>
    dispatch(fetchShifts.request({ shallow })),
  fetchEmployees: () =>
    dispatch(fetchEmployees.request({ shallow: true })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShiftsTab)
);
