import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchRoutingData } from '@actions/index';
import { IAppState } from '@reducers/index';
import OperatorTab from './OperatorTab';

const mapStateToProps = (state: IAppState) => {
  const filterId=Object.keys(state.filter.filters)[0] || '';

  return {
    data: ((state.simulations.routing[filterId] || {}).config || {}).data,
    filters: state.filter.filters,
    filterId, 
    assets: state.assets.assets,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRoutingData: (floorplanId: string) => dispatch(fetchRoutingData.request({ floorplanId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperatorTab);
