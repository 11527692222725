import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Calc, { formatPrice, roundValue } from './Calc';

interface IProps {
  twoDCalc: Calc;
  threeDCalc: Calc;
}

const ROIResult: FunctionComponent<IProps> = ({
  twoDCalc,
  threeDCalc,
}: IProps) => {
  const roi = threeDCalc.calcROI();

  return (
    <Card>
      <CardHeader
        title={
          <FormattedMessage
            id="dashboard.financials.roi_results_title"
            defaultMessage="ROI Financial Results"
          />
        }
      />

      <CardContent>
        <Table>
          <TableHead>
            <TableRow style={{ fontSize: '3rem' }} className="bold">
              <TableCell>Savings</TableCell>
              <TableCell colSpan={2}>{formatPrice(roi.saving)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Increased Profits</TableCell>
              <TableCell>Efficiency</TableCell>
              <TableCell>Safety & Compliance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{formatPrice(roi.profits)}</TableCell>
              <TableCell>{formatPrice(roi.efficiency)}</TableCell>
              <TableCell>{formatPrice(roi.safety)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Investiment</TableCell>
              <TableCell>{formatPrice(twoDCalc.getTotalCost())}</TableCell>
              <TableCell>{formatPrice(threeDCalc.getTotalCost())}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ROI</TableCell>
              <TableCell>
                {roundValue(
                  ((roi.saving - twoDCalc.getTotalCost()) /
                    twoDCalc.getTotalCost()) *
                    100,
                  2
                )}
                <span>%</span>
              </TableCell>
              <TableCell>
                {roundValue(
                  ((roi.saving - threeDCalc.getTotalCost()) /
                    threeDCalc.getTotalCost()) *
                    100,
                  2
                )}
                <span>%</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default ROIResult;
