import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import { getZonesByFloorplan } from '@selectors/zones';
import ZoneMap from './ZoneMap';

interface IProps {
  id: string;
  filterId?: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { id, filterId } = props;

  const map = getMapById(state, id);
  const filter = state.filter.filters[filterId || ''] || {};

  return {
    editFeature: map.editFeature,
    mapImages: map.mapImages,
    paper: map.paper,

    floorplan: getFloorplanById(state, map.floorplanId)!,

    zones: getZonesByFloorplan(state, map.floorplanId),

    zoneIds: filter.zoneIds || [],
  };
};

export default connect(mapStateToProps)(ZoneMap);
