import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import * as actions from '@actions/index';
import { ForkliftTypes } from '@app/dashboard/tabs/FinancialsTab/Calc';
import { IAppState } from '@reducers/index';
import { getWarehouseById } from '@selectors/warehouses';
import EditWarehouse from './EditWarehouse';

interface IProps {
  onNext: () => void;
  warehouseId: string;
}

const mapStateToProps = (state: IAppState, { warehouseId }: IProps) => ({
  loading: state.warehouses.loading,
  name: getWarehouseById(state, warehouseId)!.name,
  site: getWarehouseById(state, warehouseId)!.site,
  timezone: getWarehouseById(state, warehouseId)!.timezone,
  country: getWarehouseById(state, warehouseId)!.country,
  metricsTotalPalletsMoved: getWarehouseById(state, warehouseId)!
    .metricsTotalPalletsMoved,
  metricsFleetUsedCapacity: getWarehouseById(state, warehouseId)!
    .metricsFleetUsedCapacity,
  numberOfShifts: getWarehouseById(state, warehouseId)!.numberOfShifts,
  shiftHours: getWarehouseById(state, warehouseId)!.shiftHours,
  workingDays: getWarehouseById(state, warehouseId)!.workingDays,
  targetForkliftType: getWarehouseById(state, warehouseId)!.targetForkliftType,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { warehouseId, onNext }: IProps
) => ({
  updateWarehouse: (
    intl: IntlShape,
    name: string,
    site: string,
    timezone: string,
    country: string,
    metricsTotalPalletsMoved?: number,
    metricsFleetUsedCapacity?: number,
    numberOfShifts?: number,
    shiftHours?: number,
    workingDays?: number,
    targetForkliftType?: keyof typeof ForkliftTypes
  ) => {
    dispatch(
      actions.updateWarehouse.request({
        id: warehouseId,
        intl,
        name,
        site,
        timezone,
        country,
        metricsTotalPalletsMoved,
        metricsFleetUsedCapacity,
        numberOfShifts,
        shiftHours,
        workingDays,
        targetForkliftType,
      })
    );
    onNext();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditWarehouse);
