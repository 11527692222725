import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { updateSelectedFeature as updateFeature } from '@actions/index';
import { SensorTypes } from '@models/Sensor';
import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import { getFloorplanSensorsByType } from '@selectors/sensors';
import { MapFeature } from '../../../types';
import SensorMap from './SensorMap';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    mapImages: map.mapImages,
    paper: map.paper,

    floorplan: getFloorplanById(state, map.floorplanId)!,

    sensors: getFloorplanSensorsByType(
      state,
      SensorTypes.STATIONARY,
      map.floorplanId
    ).filter(
      (s) =>
        s.position !== undefined &&
        s.position !== null &&
        (!map.activeSensorGroupId ||
          s.sensorGroupId === map.activeSensorGroupId)
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelectedFeature: (id: string, feature: MapFeature) =>
    dispatch(updateFeature(id, feature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorMap);
