import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { ICreateMaintenance } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import FetchError from '@models/FetchError';

export enum MaintenanceTypes {
  FUELFILL = 'fuelfill',
  OILCHANGE = 'oilchange',
  TIREPRESSURE = 'tirepressure',
  TIREEXCHAGE = 'tireexchange',
}

interface IState {
  observations: string;
  type: MaintenanceTypes;
}

interface IProps {
  assetId: string;
  createMaintenance: (properties: ICreateMaintenance) => void;
  close: () => void;
  intl: IntlShape;
  loading: boolean;
  error?: FetchError;
}

export const messages = defineMessages({
  maintenanceTypes_fuelfill: {
    defaultMessage: 'Fuel Fill',
    id: 'dashboard.assets.management.maintenance.maintenancetypes_fuelfill',
  },
  maintenanceTypes_oilchange: {
    defaultMessage: 'Oil Change',
    id: 'dashboard.assets.management.maintenance.maintenancetypes_oilchange',
  },
  maintenanceTypes_tireexchange: {
    defaultMessage: 'Tire Exchange',
    id: 'dashboard.assets.management.maintenance.maintenancetypes_tireexchange',
  },
  maintenanceTypes_tirepressure: {
    defaultMessage: 'Tire Pressure',
    id: 'dashboard.assets.management.maintenance.maintenancetypes_tirepressure',
  },
});

class TicketForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      type: MaintenanceTypes.FUELFILL,
      observations: '',
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { close, loading, error } = this.props;

    if (!loading && !error && prevProps.loading) {
      close();
    }
  }

  public handleChange(e: any) {
    const { name, value, type, checked } = e.target;

    this.setState({
      [name]: type === 'checkbox' ? checked : value || '',
    } as Pick<IState, 'type'>);
  }

  public handleCancel() {
    const { close } = this.props;

    close();
  }

  public handleSubmit() {
    const { assetId, createMaintenance, intl } = this.props;
    const { observations, type } = this.state;

    createMaintenance({ assetId, observations, type, intl });
  }

  public render() {
    const { close, intl, loading } = this.props;
    const { observations, type } = this.state;

    const error = observations.length === 0 || type.length === 0;
    const buttonDisabled = error;

    return (
      <Dialog open maxWidth="md" fullWidth>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.assets.management.maintenance.title"
            defaultMessage="Create Maintenance Record"
          />
          <IconButton
            aria-label="close"
            onClick={close}
            style={{ position: 'absolute', top: '16px', right: '16px' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl required fullWidth margin="normal" variant="standard">
            <InputLabel htmlFor="maintenance-form-type">
              <FormattedMessage
                id="dashboard.assets.management.maintenance.type_label"
                defaultMessage="Select maintenance type"
              />
            </InputLabel>
            <Select
              id="maintenance-form-type"
              value={type || ''}
              onChange={this.handleChange}
              name="type"
            >
              <MenuItem value="" disabled />
              {Object.keys(MaintenanceTypes).map((key: string) => {
                const value =
                  MaintenanceTypes[key as keyof typeof MaintenanceTypes];
                const k: keyof typeof messages = `maintenanceTypes_${value}`;

                return (
                  <MenuItem key={key} value={value}>
                    {intl.formatMessage(messages[k])}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl required fullWidth margin="normal">
            <TextField
              name="observations"
              label={
                <FormattedMessage
                  id="dashboard.assets.management.maintenance.observations_label"
                  defaultMessage="Observations"
                />
              }
              variant="standard"
              onChange={this.handleChange}
              value={observations}
              multiline
              rows={4}
              required
            />
          </FormControl>
        </DialogContent>
        <DialogActions style={{ display: 'block' }}>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color="secondary"
            fullWidth
            disabled={buttonDisabled}
            loading={loading}
          >
            <FormattedMessage
              id="dashboard.assets.management.maintenance.submit"
              defaultMessage="Submit Maintenance"
            />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(TicketForm);
