import { distance, scaleColors } from '@dashboard_utils/index';
import { createCanvas, Canvas } from 'canvas';
import { max, min, sum } from 'lodash';
import { lineIntersect, multiPolygon, lineString } from '@turf/turf';

import { IDrawDefinition } from '@models/types';
import { IPlan } from '@models/Plan';

const colorize = (pixels: any, gradient: any) => {
  for (let i = 0, j; i < pixels.length; i += 4) {
    j = pixels[i + 3] * 4;

    if (j) {
      /* eslint-disable no-param-reassign */
      pixels[i] = gradient[j];
      pixels[i + 1] = gradient[j + 1];
      pixels[i + 2] = gradient[j + 2];
      /* eslint-enable no-param-reassign */
    }
  }
};

const calcCoverageLine = (
  data: [number, number][],
  x: number,
  y: number,
  obstactlePoly: any,
  obstactleWallPoly: any,
  attenuationPoly: any,
  plan: IPlan
): Coverage | undefined => {
  // Get sensors withing coverage range
  const inRange = data.filter(
    // eslint-disable-next-line no-loop-func
    (d) =>
      d[0] >= x - plan.coverageBySensor &&
      d[0] <= x + plan.coverageBySensor &&
      d[1] >= y - plan.coverageBySensor &&
      d[1] <= y + plan.coverageBySensor
  );

  const clear = [];
  if (inRange.length > 2) {
    for (let r = 0; r < inRange.length; r += 1) {
      if (
        /*
         * If there is no obstacle interception between the current point
         * and the sensor in range, it's a clear sight sensor and can be
         * used in the distance calculation/filter.
         */
        !lineIntersect(lineString([[x, y], inRange[r]]), obstactlePoly).features
          .length
      ) {
        const rackAttenuations = lineIntersect(
          lineString([[x, y], inRange[r]]),
          attenuationPoly
        ).features;

        let rackAttenuation =
          rackAttenuations.length * plan.rackSignalAttenuation;
        if (rackAttenuations.length * plan.rackSignalAttenuation > 1) {
          rackAttenuation = 1;
        }

        const wallAttenuations = lineIntersect(
          lineString([[x, y], inRange[r]]),
          obstactleWallPoly
        ).features;

        let attenuationWall =
          wallAttenuations.length * plan.wallSignalAttenuation;
        if (wallAttenuations.length * plan.wallSignalAttenuation > 1) {
          attenuationWall = 1;
        }

        let attenuation = rackAttenuation + attenuationWall;
        if (attenuation > 1) {
          attenuation = 1;
        }

        clear.push({
          x: inRange[r][0],
          y: inRange[r][1],
          attenuation,
          reflections: rackAttenuations.length + wallAttenuations.length,
        });
      }
    }
  }

  const sortedClear = clear
    .map(
      // eslint-disable-next-line no-loop-func
      (c) => ({
        ...c,
        value: distance({ x, y }, c) + plan.coverageBySensor * c.attenuation,
      })
    )
    .filter((c) => c.value < plan.coverageBySensor)
    .sort((a, b) => (a.value > b.value ? 1 : -1));

  let value4 = -1;
  if (sortedClear.length > 3) {
    value4 =
      sum(
        sortedClear
          .slice(0, 4)
          .map((c) => (plan.pathLoss === 'simple' ? c.value : 1 / c.value ** 2))
      ) / 4;
  }

  let value3 = -1;
  if (sortedClear.length > 2) {
    value3 =
      sum(
        sortedClear
          .slice(0, 3)
          .map((c) => (plan.pathLoss === 'simple' ? c.value : 1 / c.value ** 2))
      ) / 3;
  }

  // eslint-disable-next-line no-loop-func
  const calculateSignalAngle = (c: Coverage): number => {
    const angle = Math.abs((Math.atan2(c.y - y, c.x - x) * 180) / Math.PI);

    if (angle <= 90 && angle >= 0) {
      return angle;
    }
    if (angle <= 180 && angle > 90) {
      return 180 - angle;
    }
    if (angle <= 270 && angle > 180) {
      return angle - 180;
    }
    if (angle < 360 && angle > 270) {
      return 360 - angle;
    }
    return 0;
  };

  let value = value3;
  let delutionOfPrecision;
  let reflectionFactor;

  if (value3 > value4 && value4 > -1) {
    delutionOfPrecision =
      sum(
        sortedClear
          .slice(0, 4)
          // eslint-disable-next-line no-loop-func
          .map(calculateSignalAngle)
      ) / 4;
    reflectionFactor = sum(
      sortedClear
        .slice(4, sortedClear.length)
        // eslint-disable-next-line no-loop-func
        .map((c) => c.reflections)
    );
    value = value4;
  } else {
    delutionOfPrecision =
      sum(
        sortedClear
          .slice(0, 3)
          // eslint-disable-next-line no-loop-func
          .map(calculateSignalAngle)
      ) / 3;
    reflectionFactor = sum(
      sortedClear
        .slice(3, sortedClear.length)
        // eslint-disable-next-line no-loop-func
        .map((c) => c.reflections)
    );
  }

  // We percentually remove deviation to target angle (30º or 45º) up to 10% of the map value
  if (plan.delutionOfPrecision !== 0) {
    let targetDev = (delutionOfPrecision * 100) / plan.delutionOfPrecision;
    // Normalize % of lost arrount target value
    if (targetDev > 100) {
      targetDev -= 100;
    } else {
      targetDev = 100 - targetDev;
    }

    // Up to 10% of signal lost
    const lost = 0.1 * targetDev * 0.01;
    value *= 1 + lost;
  }

  const maxReflection = 10;
  const minReflection = 2;
  // We remove reflection up to 30% of the map value, between thresholds
  if (plan.reflectionFactor !== 0 && reflectionFactor > minReflection) {
    if (reflectionFactor > maxReflection) {
      reflectionFactor = maxReflection;
    } else if (reflectionFactor < minReflection) {
      reflectionFactor = minReflection;
    }

    // Up to 30% of signal lost
    const lost =
      0.3 *
      ((reflectionFactor - minReflection) / (maxReflection - minReflection));

    value *= 1 + lost;
  }

  if (value > 0) {
    return {
      x,
      y,
      /**
       * To get the value weight for the heatmap, we calculate the distance
       * of the current point to each of the clear sight sensors and use the
       * closest sensors to average the distance.
       */
      value,
    };
  }

  return undefined;
};

interface Coverage {
  x: number;
  y: number;
  value: number;
}

class Heatmap {
  private canvas: Canvas;

  private ctx: any;

  private gradient?: Uint8ClampedArray;

  private obstacles?: IDrawDefinition[];

  private attenuations?: IDrawDefinition[];

  private minX: number;

  private minY: number;

  private maxX: number;

  private maxY: number;

  private gridSize = 1;

  private scale: number;

  public coverage = 0;

  public space = 0;

  public quality = 0;

  public coverageMap: Coverage[] = [];

  constructor(
    width: number,
    height: number,
    scale: number,
    obstacles: IDrawDefinition[],
    attenuations: IDrawDefinition[]
  ) {
    this.canvas = createCanvas(width, height);

    this.ctx = this.canvas.getContext('2d');

    const xs: number[] = [];
    const yz: number[] = [];
    obstacles.concat(attenuations).forEach((o) => {
      o.coordinates.forEach((c) => {
        xs.push(c[0]);
        yz.push(c[1]);
      });
    });
    this.minX = min(xs) || 0;
    this.minY = min(yz) || 0;
    this.maxX = max(xs) || 0;
    this.maxY = max(yz) || 0;

    this.attenuations = attenuations;
    this.obstacles = obstacles;
    this.scale = scale;
  }

  private genGradient() {
    const canvas = createCanvas(1, 256);
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 256);

    for (let c = 0; c < scaleColors.length; c += 1) {
      gradient.addColorStop(c / scaleColors.length, scaleColors[c]);
    }

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 1, 256);

    this.gradient = ctx.getImageData(0, 0, 1, 256).data;
  }

  public async buildCoverageLine(
    data: [number, number][],
    x: number,
    y: number,
    obstactlePoly: any,
    obstactleWallPoly: any,
    attenuationPoly: any,
    plan: IPlan
  ): Promise<void> {
    if (y < this.maxY) {
      // eslint-disable-next-line no-param-reassign
      y += this.gridSize;

      const result = calcCoverageLine(
        data,
        x,
        y,
        obstactlePoly,
        obstactleWallPoly,
        attenuationPoly,
        plan
      );

      if (result) {
        this.coverageMap.push(result);
      }

      if (y % 1000 === 0) {
        await Promise.resolve();
      }

      return this.buildCoverageLine(
        data,
        x,
        y,
        obstactlePoly,
        obstactleWallPoly,
        attenuationPoly,
        plan
      );
    }

    return undefined;
  }

  public draw(data: [number, number][], plan: IPlan) {
    /**
     * This code is async on purpose, this way we prevent blocking the event loop and crashing the UI due to heavy operations.
     */
    return new Promise((resolve) => {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.fillStyle = '#000000';

      const obstactlePoly = multiPolygon(
        (this.obstacles || [])
          .filter((o) => o.meta.type !== 'wall' && false)
          .map((o) => [[...o.coordinates, o.coordinates[0]]]) as any
      );
      const obstactleWallPoly = multiPolygon(
        (this.obstacles || [])
          .filter((o) => o.meta.type === 'wall' && false)
          .map((o) => [[...o.coordinates, o.coordinates[0]]]) as any
      );
      const attenuationPoly = multiPolygon(
        (this.attenuations || []).map((o) => [
          [...o.coordinates, o.coordinates[0]],
        ]) as any
      );

      let x = this.minX;
      this.coverageMap = [];

      const buildCoverage = () => {
        if (x < this.maxX) {
          this.buildCoverageLine(
            data,
            x,
            this.minY,
            obstactlePoly,
            obstactleWallPoly,
            attenuationPoly,
            plan
          ).then(() => {
            x += this.gridSize;

            buildCoverage();
          });
        } else {
          // Ideal average distance
          const bestCoverage =
            plan.pathLoss === 'simple'
              ? plan.simpleBestCoverage
              : plan.onerpowBestCoverage;
          // Poor average distance
          const poorestCoverage =
            plan.pathLoss === 'simple'
              ? plan.simplePoorestCoverage
              : plan.onerpowPoorestCoverage;

          const signalCoverage = this.coverageMap.filter((c) => c.value > 0);
          const qualityInfo = signalCoverage.map((c) => {
            let alpha = 0;
            if (c.value > bestCoverage || c.value < poorestCoverage) {
              alpha = c.value / poorestCoverage;
            } else if (c.value > poorestCoverage) {
              alpha = 1;
            }

            this.ctx.globalAlpha =
              plan.pathLoss === 'simple' ? 1 - alpha : alpha;
            this.ctx.fillRect(
              c.x * this.scale,
              this.canvas.height - c.y * this.scale,
              this.gridSize * this.scale,
              this.gridSize * this.scale
            );

            return this.ctx.globalAlpha;
          });
          this.ctx.globalAlpha = 1;

          if (!this.gradient) {
            this.genGradient();
          }

          const colored = this.ctx.getImageData(
            0,
            0,
            this.canvas.width,
            this.canvas.height
          );
          colorize(colored.data, this.gradient);
          this.ctx.putImageData(colored, 0, 0);

          this.space = this.coverageMap.length;
          this.coverage = signalCoverage.length;
          this.quality = sum(qualityInfo);

          resolve(this.canvas);
        }
      };

      buildCoverage();
    });
  }
}

export default Heatmap;
