import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RoomIcon from '@mui/icons-material/Room';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IFetchIntemsWithLocations } from '@actions/index';
import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import { withRouter } from '@app/utils/withRouter';
import { Item } from '@models/Item';

interface IProps {
  fetchItemsWithLocations: (properties: IFetchIntemsWithLocations) => void;
  intl: IntlShape;
  items: Item[];
  router: any;
  language: string;
  loading: boolean;
  openImportItemsForm: () => void;
  openImportStocksForm: () => void;
}

interface IState {
  anchorEl?: HTMLElement;
  columns: ITableColumn[];
}

const messages = {
  thCode: {
    defaultMessage: 'Code',
    id: 'dashboard.items.table.code',
  },
  thDescription: {
    defaultMessage: 'Description',
    id: 'dashboard.items.table.description',
  },
  thLocations: {
    defaultMessage: 'Location(s)',
    id: 'dashboard.items.table.locations',
  },
};

class ItemList extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      columns: [
        { label: intl.formatMessage(messages.thCode), name: 'code' },
        {
          label: intl.formatMessage(messages.thDescription),
          name: 'description',
        },
        { label: intl.formatMessage(messages.thLocations), name: 'locations' },
      ],
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleItemImport = this.handleItemImport.bind(this);
    this.handleStockImport = this.handleStockImport.bind(this);
    this.goToLocationsMapping = this.goToLocationsMapping.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  public handleClick(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleClose() {
    this.setState({ anchorEl: undefined });
  }

  public handleItemImport() {
    const { openImportItemsForm } = this.props;

    this.setState({ anchorEl: undefined }, openImportItemsForm);
  }

  public handleStockImport() {
    const { openImportStocksForm } = this.props;

    this.setState({ anchorEl: undefined }, openImportStocksForm);
  }

  public goToLocationsMapping() {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/items/locations`);
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const { fetchItemsWithLocations } = this.props;

    fetchItemsWithLocations({ shallow });
  }

  public render() {
    const { anchorEl, columns } = this.state;
    const { items, loading } = this.props;

    return (
      <Loading loading={loading}>
        <Card>
          <CardHeader
            action={
              <>
                <IconButton
                  aria-label="More"
                  aria-owns={anchorEl ? 'long-menu' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleItemImport}>
                    <ListItemIcon>
                      <CloudUploadIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <FormattedMessage
                          id="dashboard.items.importitems"
                          defaultMessage="Import Items"
                        />
                      }
                    />
                  </MenuItem>
                  <MenuItem onClick={this.handleStockImport}>
                    <ListItemIcon>
                      <CloudUploadIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <FormattedMessage
                          id="dashboard.items.importitemlocations"
                          defaultMessage="Import Stocks"
                        />
                      }
                    />
                  </MenuItem>
                  <MenuItem onClick={this.goToLocationsMapping}>
                    <ListItemIcon>
                      <RoomIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <FormattedMessage
                          id="dashboard.items.locationsmapping"
                          defaultMessage="Locations Mapping"
                        />
                      }
                    />
                  </MenuItem>
                </Menu>
              </>
            }
            title={
              <FormattedMessage
                id="dashboard.items.title"
                defaultMessage="Items / Locations"
              />
            }
          />
          <CardContent>
            <MUITable
              columns={columns}
              data={items.map((item) => ({
                ...item,
                locations: (item.locations || [])
                  .map((l) => `${l.location} (${l.quantity})`)
                  .join(', '),
              }))}
              tableId="items"
            />
          </CardContent>
        </Card>
      </Loading>
    );
  }
}

export default injectIntl(withRouter(ItemList));
