import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { FunctionComponent } from 'react';

import Filters from '@app/common/Filters';
import { IFilter } from '@reducers/filter';
import { cellSize } from '../../../utils';
import Walkthrough from '../../../utils/Walkthrough';
import PlayerTabContainer from './PlayerTabContainer';

interface IProps {
  filters: Record<string, IFilter>;
  theme: string;
}

const PlayerTabGrid: FunctionComponent<IProps> = ({
  filters,
  theme,
}: IProps) => {
  const filterKeys = Object.keys(filters).filter(
    (k) => k !== 'simulator' && k !== 'popup'
  );

  return (
    <Box className="tab-content-inner" p={2}>
      <Grid container spacing={2} flex={1}>
        {filterKeys.map((filterId, index) => (
          <Grid
            key={filterId}
            container
            item
            xs={cellSize(filterKeys, index)}
            direction="column"
            flex={1}
            style={{
              display: 'flex',
              height: '100%',
            }}
          >
            <Filters
              activeFilters={['zones', 'assets', 'assetTags', 'eventTypes']}
              filterId={filterId}
            >
              <PlayerTabContainer
                filterId={filterId}
                theme={theme}
              />
            </Filters>
          </Grid>
        ))}
      </Grid>
      <Walkthrough type="player" />
    </Box>
  );
};

export default PlayerTabGrid;
