import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid/Grid';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { ICreateAccountInvite } from '@actions/index';
import Loading from '@app/common/Loading';
import LoadingButton from '@app/common/LoadingButton';
import FetchError from '@models/FetchError';

interface IState {
  changes: Record<string, boolean>;
  name: string;
  email: string;
  permissions: Record<string, boolean>;
  operator: boolean;
}

interface IProps {
  availablePermissions: string[];
  availablePermissionsLoading: boolean;
  closeInviteForm: () => void;
  createAccountInvite: (properties: ICreateAccountInvite) => void;
  fetchAvailablePermissions: () => void;
  error?: FetchError;
  intl: IntlShape;
  loading: boolean;
}

class InviteForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const permissions = {} as Record<string, boolean>;

    this.state = {
      changes: {},
      email: '',
      name: '',
      permissions,
      operator: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePermissionsChange = this.handlePermissionsChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    const { fetchAvailablePermissions } = this.props;

    fetchAvailablePermissions();
  }

  public componentDidUpdate(prevProps: any) {
    const { availablePermissions } = this.props;

    if (
      JSON.stringify(prevProps.availablePermissions) !==
      JSON.stringify(availablePermissions)
    ) {
      this.setPermissions();
    }
  }

  public handlePermissionsChange(permission: string) {
    const { permissions } = this.state;

    permissions[permission] = !permissions[permission];

    this.setState({ permissions });
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { changes } = this.state;
      const { value, type, checked } = e.target;

      this.setState({
        [key]: type === 'checkbox' ? checked : value || '',
        changes: { ...changes, [key]: true },
      } as Pick<IState, 'changes'>);
    };
  }

  public handleCancel() {
    const { closeInviteForm } = this.props;

    closeInviteForm();
  }

  public handleSubmit() {
    const { createAccountInvite, intl } = this.props;
    const { email, name, permissions, operator } = this.state;

    const keys = Object.keys(permissions);
    const activePermissions = [];
    for (let i = 0; i < keys.length; i += 1) {
      if (permissions[keys[i]] === true) {
        activePermissions.push(keys[i]);
      }
    }

    this.setState({ changes: {} });
    createAccountInvite({
      email,
      intl,
      name,
      permissions: activePermissions,
      operator,
    });
  }

  public setPermissions() {
    const { availablePermissions } = this.props;

    const permissions = {} as Record<string, boolean>;

    for (let i = 0; i < availablePermissions.length; i += 1) {
      if (permissions[availablePermissions[i]] === undefined) {
        permissions[availablePermissions[i]] = false;
      }
    }

    this.setState({ permissions });
  }

  public render() {
    const { availablePermissionsLoading, error, loading } = this.props;
    const { email, changes, name, permissions, operator } =
      this.state;

    const isEmailAlreadySign =
      loading === false &&
      changes.email !== true &&
      error !== undefined &&
      error.errorCode === 'EmailAlreadySign';

    const hasError =
      email.length === 0 ||
      name.length === 0 ||
      isEmailAlreadySign === true;

    const buttonDisabled = hasError;

    return (
      <Dialog open fullWidth maxWidth="md">
        <DialogTitle>
          <FormattedMessage
            id="dashboard.forms.inviteform.title"
            defaultMessage="Create new invite"
          />
        </DialogTitle>
        <DialogContent>
          <Loading loading={availablePermissionsLoading}>
            <FormControl required fullWidth margin="normal">
              <TextField
                label={
                  <FormattedMessage
                    id="dashboard.forms.inviteform.label_name"
                    defaultMessage="Name"
                  />
                }
                variant="standard"
                value={name}
                onChange={this.handleChange('name')}
                helperText={
                  <FormattedMessage
                    id="dashboard.forms.inviteform.field_name_helper"
                    defaultMessage="A name must be specified"
                  />
                }
              />
            </FormControl>
            <FormControl required fullWidth margin="normal">
              <TextField
                label={
                  <FormattedMessage
                    id="dashboard.forms.inviteform.label_email"
                    defaultMessage="Email"
                  />
                }
                variant="standard"
                error={isEmailAlreadySign}
                value={email}
                type="email"
                autoComplete="email"
                onChange={this.handleChange('email')}
                helperText={
                  isEmailAlreadySign ? (
                    <FormHelperText error>
                      <FormattedMessage
                        id="dashboard.forms.inviteform.field_email_alreadysign_error"
                        defaultMessage="The submitted email is already signed in"
                      />
                    </FormHelperText>
                  ) : (
                    <FormattedMessage
                      id="dashboard.forms.inviteform.field_email_helper"
                      defaultMessage="An email must be specified"
                    />
                  )
                }
              />
            </FormControl>
            <Box mt={4}>
              <Divider variant="middle" />
            </Box>
            <Box mt={2}>
              <Grid container>
                <Grid item xs={6}>
                  {Object.keys(permissions)
                    .filter((p, index) => index % 2 === 0)
                    .map((p) => (
                      <FormControl required fullWidth key={p}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permissions[p] || false}
                              onChange={() => this.handlePermissionsChange(p)}
                              value="true"
                            />
                          }
                          label={p}
                        />
                      </FormControl>
                    ))}
                </Grid>
                <Grid item xs={6}>
                  {Object.keys(permissions)
                    .filter((p, index) => index % 2 !== 0)
                    .map((p) => (
                      <FormControl required fullWidth key={p}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permissions[p] || false}
                              onChange={() => this.handlePermissionsChange(p)}
                              value="true"
                            />
                          }
                          label={p}
                        />
                      </FormControl>
                    ))}
                </Grid>
              </Grid>
            </Box>
            <Box mt={4}>
              <FormControl required fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={operator || false}
                      onChange={this.handleChange('operator')}
                      value="true"
                    />
                  }
                  label="Operator account"
                />
              </FormControl>
            </Box>
          </Loading>
        </DialogContent>
        <DialogActions>
          <Box mr={1}>
            <Button onClick={this.handleCancel}>
              <FormattedMessage
                id="dashboard.forms.inviteform.button_cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </Box>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color="secondary"
            disabled={buttonDisabled}
            loading={loading}
          >
            <FormattedMessage
              id="dashboard.forms.inviteform.button_submit"
              defaultMessage="Create"
            />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(InviteForm);
