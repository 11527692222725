import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography/Typography';
import EditIcon from '@mui/icons-material/Edit';
import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { IUpdateFloorplan } from '@actions/floorplans';
import Map from '@app/common/FullMap/Map';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import ManualScaleForm from './ManualScaleForm';

export interface IProps {
  error: boolean;
  intl: IntlShape;
  onFeatureChange: (pixels: number) => void;
  onMeasuredDistanceChange: (event: any) => void;
  measurementUnits: string;
  updateFloorplan: (properties: IUpdateFloorplan) => any;
  value: string;
  floorplan: IncompleteFloorplan;
}
interface IState {
  manualEdition: boolean;
}

class DefineScale extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      manualEdition: false,
    };

    this.editScaleManually = this.editScaleManually.bind(this);
    this.handleManualFormSubmit = this.handleManualFormSubmit.bind(this);
    this.handleManualFormCancel = this.handleManualFormCancel.bind(this);
  }

  public handleManualFormSubmit(scale: number) {
    const { floorplan, intl, updateFloorplan } = this.props;

    this.setState({ manualEdition: false }, () =>
      updateFloorplan({
        floorplanId: floorplan.id,
        intl,
        properties: { scale },
        warehouseId: floorplan.warehouseId,
      })
    );
  }

  public handleManualFormCancel() {
    this.setState({ manualEdition: false });
  }

  public editScaleManually() {
    this.setState({ manualEdition: true });
  }

  public render() {
    const {
      error,
      intl,
      measurementUnits,
      onFeatureChange,
      onMeasuredDistanceChange,
      value,
      floorplan,
    } = this.props;
    const { manualEdition } = this.state;

    const messages = defineMessages({
      feets: {
        defaultMessage: 'feets',
        id: 'units.measurement.imperial.feets',
      },
      floorplan: {
        defaultMessage: 'Floor plan',
        id: 'dashboard.datamap.floorplan',
      },
      meters: {
        defaultMessage: 'meters',
        id: 'units.measurement.si.meters',
      },
    });

    const help = (
      <>
        <Typography variant="h4">
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.definescale.title"
            defaultMessage="Define Scale"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.definescale.description.p1"
            defaultMessage="Here you will be able to map pixels in the screen to {unit} in the actual floor plan."
            values={{
              unit:
                measurementUnits === 'si'
                  ? intl.formatMessage(messages.meters)
                  : intl.formatMessage(messages.feets),
            }}
          />
          <br />
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.definescale.description.p2"
            defaultMessage="To do so, draw a line in the map and insert its length in {unit} in the box below."
            values={{
              unit:
                measurementUnits === 'si'
                  ? intl.formatMessage(messages.meters)
                  : intl.formatMessage(messages.feets),
            }}
          />
          <br />
          <Typography variant="caption">
            <FormattedMessage
              id="dashboard.forms.floorplanconfigurationform.definescale.tutorial.p1"
              defaultMessage="Ctrl + Click (Cmd + Click on Mac) in two points of the floorplan to draw the line."
            />
          </Typography>
        </Typography>
      </>
    );

    return (
      <div
        style={{
          bottom: 0,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 50px)',
          }}
        >
          <Map
            disableColor
            floorplanId={floorplan.id}
            help={help}
            layers={{
              floorplan: {
                id: 'floorplan',
                active: true,
                label: intl.formatMessage(messages.floorplan),
                primary: true,
                secondary: false,
              },
            }}
            actions={{
              scaling: {
                onChange: onFeatureChange,
              },
            }}
          />
        </div>
        <div style={{ borderTop: '1px solid #e1e1e1' }}>
          <FormControl variant="standard" error={error}>
            <InputLabel htmlFor="step3-value-input">
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.definescale.label_measureddistance"
                defaultMessage="Measured distance"
              />
            </InputLabel>
            <Input
              id="step3-value-input"
              value={value}
              onChange={onMeasuredDistanceChange}
              endAdornment={
                <InputAdornment position="end">
                  {measurementUnits === 'si' ? 'm' : 'ft'}
                </InputAdornment>
              }
            />
            <FormHelperText error={error}>
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.definescale.field_measureddistance_help"
                defaultMessage="Please input a positive number."
              />
            </FormHelperText>
          </FormControl>
          <Typography variant="body1">
            <FormattedMessage
              id="dashboard.forms.floorplanconfigurationform.definescale.currentscale"
              defaultMessage="Current scale"
            />
            <span>: </span>
            <span>{(floorplan.scale || 0).toFixed(2)}</span>
            <EditIcon
              style={{ cursor: 'pointer', marginBottom: '-5px' }}
              onClick={this.editScaleManually}
            />
          </Typography>
        </div>
        {manualEdition ? (
          <ManualScaleForm
            scale={floorplan.scale}
            handleSubmit={this.handleManualFormSubmit}
            handleCancel={this.handleManualFormCancel}
          />
        ) : null}
      </div>
    );
  }
}

export default injectIntl(DefineScale);
