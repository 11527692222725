import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import LoadingButton from '@app/common/LoadingButton/index';

export interface IProps {
  image?: string;
  loading: boolean;
  name?: string;
  onBack: () => void;
  onNext: (intl: IntlShape, name: string, image?: File) => void;
  onSkip: (name: string, imageSrc?: string) => void;
  intl: IntlShape;
}

export interface IState {
  file?: File;
  image?: string;
  name: string;
}

const handleFileUploadClick = (event: any) => {
  event.stopPropagation();

  document.getElementById('update-floorplan-file-upload')!.click();
};

class CreateOrEditFloorplan extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { name, image } = props;

    this.state = {
      file: undefined,
      image,
      name: name || '',
    };

    this.handleImageRemoval = this.handleImageRemoval.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'name'>);
    };
  }

  public handleFileUpload(event: any) {
    const { files } = event.target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (e.target !== null) {
          this.setState({ image: e.target.result });
        }
      };

      reader.readAsDataURL(files[0]);

      this.setState({
        file: files[0],
      });
    }
  }

  public handleImageRemoval() {
    this.setState({ file: undefined, image: undefined });
  }

  public handleClear() {
    this.setState({ name: '', image: undefined });
  }

  public handleNext() {
    const { name, file } = this.state;
    const { intl, onNext } = this.props;

    onNext(intl, name, file);
  }

  public handleSkip() {
    const { name, image } = this.state;
    const { onSkip } = this.props;

    onSkip(name, image);
  }

  public render() {
    const { name, image } = this.state;
    const { onBack, loading } = this.props;

    return (
      <Card elevation={0}>
        <CardHeader
          title={
            <FormattedMessage
              id="dashboard.setup.steps.floorplan.title"
              defaultMessage="Create Floor plan"
            />
          }
        />
        <CardContent>
          <Typography variant="body1">
            <FormattedMessage
              id="dashboard.setup.steps.floorplan.description"
              defaultMessage="Here you will be able to create your first floor plan."
            />
          </Typography>
          <FormControl required>
            <InputLabel required htmlFor="setup-floorplan-name">
              <FormattedMessage
                id="dashboard.forms.floorplanform.label_name"
                defaultMessage="Name"
              />
            </InputLabel>
            <Input
              id="setup-floorplan-name"
              value={name}
              onChange={this.handleChange('name')}
            />
            <FormHelperText>
              <FormattedMessage
                id="dashboard.forms.floorplanform.field_name_helper"
                defaultMessage="Required"
              />
            </FormHelperText>
          </FormControl>
          {image ? (
            <div
              style={{
                height: '250px',
                position: 'relative',
                textAlign: 'center',
                width: '100%',
              }}
            >
              <ClearIcon
                color="error"
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                }}
                onClick={this.handleImageRemoval}
              />
              <img
                style={{
                  border: '1px solid black',
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
                src={image}
                alt="Floorplan"
              />
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <label htmlFor="update-floorplan-file-upload">
                <Button
                  style={{ width: '300px', height: '300px' }}
                  variant="outlined"
                  color="primary"
                  onClick={handleFileUploadClick}
                >
                  <FormattedMessage
                    id="dashboard.forms.floorplanform.button_uploadfloorplan"
                    defaultMessage="Upload Floor plan"
                  />
                </Button>
                <input
                  accept="image/*"
                  id="update-floorplan-file-upload"
                  type="file"
                  onInput={this.handleFileUpload}
                />
              </label>
            </div>
          )}
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Box mr={1}>
            <Button onClick={onBack}>
              <FormattedMessage
                id="dashboard.setup.steps.button_back"
                defaultMessage="Back"
              />
            </Button>
          </Box>
          <Box mr={1}>
            <Button onClick={this.handleClear}>
              <FormattedMessage
                id="dashboard.setup.steps.button_clear"
                defaultMessage="Clear"
              />
            </Button>
          </Box>
          <Box mr={1}>
            <LoadingButton
              onClick={this.handleNext}
              color="primary"
              variant="contained"
              loading={loading}
              disabled={!image || !name}
            >
              <FormattedMessage
                id="dashboard.setup.steps.button_next"
                defaultMessage="Next"
              />
            </LoadingButton>
          </Box>
          <Box mr={1}>
            <Button
              onClick={this.handleSkip}
              color="secondary"
              disabled={!name}
            >
              <FormattedMessage
                id="dashboard.setup.steps.button_skipinstallation"
                defaultMessage="Skip Installation"
              />
            </Button>
          </Box>
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(CreateOrEditFloorplan);
