import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ICreateSensor, IFetchFloorplanSensors } from '@actions/sensors';
import Account from '@models/Account';
import LoadingButton from '@app/common/LoadingButton';
import SensorGroupWithStatus from '@models/SensorGroupWithStatus';

interface IProps {
  activeSensorGroup: SensorGroupWithStatus | undefined;
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => void;
  error?: object;
  loading: boolean;
  formLoading: boolean;
  sensorId?: string;
  createSensor: (properties: ICreateSensor) => void;
  closeForm: () => void;
  account: Account;
  floorplanId: string;
}
interface IState {
  physicalAddress: string;
}

class MobileSensor extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      physicalAddress: '',
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    const { fetchActiveSensorGroup, floorplanId } = this.props;

    fetchActiveSensorGroup({ floorplanId });
  }

  public componentDidUpdate() {
    const { sensorId, account, closeForm } = this.props;
  
    if (sensorId) {
      const key = `mobile_sensor_${account.organisationId || 0}`;
      localStorage.setItem(key, sensorId);

      // @ts-ignore
      if (window.MessageInvoker) {
        // @ts-ignore
        window.MessageInvoker.postMessage(`store|${key}|${sensorId}`);
      }

      closeForm();
    }
  }

  public handleChange(key: keyof IState, e: any) {
    this.setState({
      [key]: e.target.value || '',
    });
  }

  public handleCancel() {
    const { account, closeForm } = this.props;
    
    const key = `mobile_sensor_question_${account.organisationId || 0}`;
    const value = Date.now().toString();
    localStorage.setItem(key, value);

    // @ts-ignore
    if (window.MessageInvoker) {
      // @ts-ignore
      window.MessageInvoker.postMessage(`store|${key}|${value}`);
    }

    closeForm();
  }

  public handleSubmit() {
    const { createSensor, floorplanId, activeSensorGroup } = this.props;
    const { physicalAddress } = this.state;

    createSensor({
      floorplanId,
      sensorGroupId: activeSensorGroup?.id || '',
      physicalAddress,
      type: 'mobile_app',
      position: [0, 0, 0],
    });
  }

  public render() {
    const { loading, error } = this.props;
    const { physicalAddress } = this.state;

    const disabledButton = physicalAddress.length === 0;

    return (
      <Dialog open maxWidth="md" fullWidth>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.mobilesensor.title"
            defaultMessage="Create a mobile sensor"
          />
        </DialogTitle>
        <DialogContent>
          <FormControl required fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.mobilesensor.label_physicaladdress"
                  defaultMessage="Physical Address"
                />
              }
              variant="standard"
              value={physicalAddress}
              onChange={(e: any) => this.handleChange('physicalAddress', e)}
              helperText={
                error ? (
                  <FormHelperText error>{
                    <FormattedMessage
                      id="dashboard.mobilesensor.existing_physical_address"
                      defaultMessage="Physical address already exists"
                    />
                  }</FormHelperText>
                ) : null
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            <FormattedMessage
              id="dashboard.mobilesensor.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color="secondary"
            disabled={disabledButton}
            loading={loading}
          >
            <FormattedMessage
              id="dashboard.mobilesensor.buttom_submit"
              defaultMessage="Create"
            />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default MobileSensor;