import Typography from '@mui/material/Typography';
import React, { FunctionComponent } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Map from '@app/common/FullMap/Map';

const messages = defineMessages({
  floorplan: {
    defaultMessage: 'Floor plan',
    id: 'dashboard.datamap.floorplan',
  },
  sensors: {
    defaultMessage: 'Sensors',
    id: 'dashboard.livemap.layers.sensors',
  },
});

interface IProps {
  aliningChange?: (points: number[][]) => void;
  floorplanId: string;
}

const SensorAlignmentForm: FunctionComponent<IProps> = ({
  aliningChange = undefined,
  floorplanId,
}: IProps) => {
  const intl = useIntl();

  const help = (
    <>
      <Typography variant="h4">
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.sensoralignment.title"
          defaultMessage="Sensor Alignment"
        />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.sensoralignment.description"
          defaultMessage="This sensor alignment step is needed to align the sensors in the map so it is possible to have correct representation of the live data."
        />
      </Typography>
      <Typography variant="caption">
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.sensoralignment.tutorial.p1"
          defaultMessage="Click + Drag on one of the sensors will drag the sensor set into position."
        />
        <br />
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.sensoralignment.tutorial.p2"
          defaultMessage="Shift + Drag on one of the sensors will rotate the set in the direction of movement."
        />
      </Typography>
    </>
  );

  return (
    <div
      style={{
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Map
          disableColor
          floorplanId={floorplanId}
          help={help}
          layers={{
            floorplan: {
              id: 'floorplan',
              active: true,
              label: intl.formatMessage(messages.floorplan),
              primary: true,
              secondary: false,
            },
          }}
          actions={{
            aligning: {
              onChange: aliningChange,
            },
          }}
        />
      </div>
    </div>
  );
};

export default SensorAlignmentForm;
