import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactourStep } from 'reactour';

import Disabler from './Disabler';

export default (
  disableTour: () => void,
  disabledTour: boolean
): ReactourStep[] => [
  {
    content: () => (
      <>
        <Typography variant="h4">
          <FormattedMessage
            id="tutorial.dashboard.welcome.title"
            defaultMessage="You have arrived at our dashboard home page"
          />
        </Typography>
        <br />
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.dashboard.welcome.message"
            defaultMessage={
              "Where you will have a quick overview of the current state of things in your warehouse. You can see the latest activity, alerts and some plots to help you get a general sense of what's going on."
            }
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'body',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.dashboard.filters.title"
            defaultMessage="You can switch between warehouses/spaces/zones any time by using this selection."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '.filters',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.dashboard.menu.title"
            defaultMessage="Click here to open our menu and get access to other areas of our dashboard."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '.navbar-menu-button',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.menu.liveview"
            defaultMessage={
              "Live view page. Where you can see what's going on at real time."
            }
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'a#live',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.menu.analytics"
            defaultMessage="Analytics, where you find insights into the collected information, heatmaps, plots and much more."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'div#analytics',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.menu.player"
            defaultMessage="Player, here you find our player, so you can play back old events and/or old data entries so you can see what happened in a past moment in time. You will also find details into asset activity."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'a#player',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.menu.rules"
            defaultMessage="Rules, this is where you can manage active/inactive rules, setup zones and manage them."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'a#rules',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.menu.sensors"
            defaultMessage="Sensors, this tab provides you informatiom about the hardware setup of our system."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'a#sensors',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.menu.reports"
            defaultMessage="Reports, in this page you can generate your own customized reports, for a give interval of time or a selection of assets and so on."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'a#reports',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.menu.settings"
            defaultMessage="Settings, and finally our management page, you can do things like setup new accounts and edit warehouse or floorplans setups."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'a#settings',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.menu.livego"
            defaultMessage="Let's start with Live View, click to go."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'a#live',
  },
];
