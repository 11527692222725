import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

function reloadPage() {
  window.location.reload();
}

const ErrorCard: FunctionComponent = () => (
  <Card style={{ textAlign: 'center', padding: 32 }}>
    <Typography variant="h4">Error</Typography>
    <Typography variant="body1" style={{ margin: '40px 0' }}>
      <FormattedMessage
        id="dashboard.errorcard.p1"
        defaultMessage="Unfortunately, an error occurred. It has already been reported and we are trying hard to fix it."
      />
      <br />
      <FormattedMessage
        id="dashboard.errorcard.p2"
        defaultMessage="To try again, click the Refresh button below."
      />
    </Typography>
    <Button variant="contained" color="primary" onClick={reloadPage}>
      <FormattedMessage
        id="dashboard.errorcard.button_refresh"
        defaultMessage="Refresh"
      />
    </Button>
  </Card>
);

export default ErrorCard;
