import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import { MetricState } from '@reducers/analytics';
import TablesTab from './TablesTab';

interface IProps {
  filterId: string;
  type: 'zone_heatmap' | 'zone_trajectories' | 'fixed_tables';
  section: 'user' | 'diagnostics';
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId, section, type } = props;
  const activeMetrics = state.analytics.activeMetrics[filterId || ''] || {};
  const metricId = section ? activeMetrics[section] || '' : '';

  const dataType = type === 'fixed_tables' ? type : 'maps';
  const activeId = (
    ((activeMetrics.plots || {})[metricId] || {})[dataType] || {}
  ).id;

  return {
    assets: state.assets.assets,
    floorplans: state.floorplans.floorplans,
    warehouses: state.warehouses.warehouses,
    zones: state.zones.zones,

    metric:
      (state.analytics.metrics[filterId] || {})[activeId || ''] ||
      ({ loading: !!activeId } as MetricState),
  };
};

export default connect(mapStateToProps)(TablesTab);
