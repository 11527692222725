import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-GB';
import ptLocale from 'date-fns/locale/pt';
import frLocale from 'date-fns/locale/fr';
import roLocale from 'date-fns/locale/ro';
import React, { PureComponent, lazy, Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  IFetchAccounts,
  IFetchAssets,
  IFetchOrganisationInformation,
  IFetchWarehouses,
  ReconnectingStates,
} from '@actions/index';
import ws from '@api/websocket';
import ActionFeedback from '@app/common/ActionFeedback';
import Loading from '@app/common/Loading';
import ErrorCatcher from '@app/common/ErrorCatcher';
import {
  de as deTranslation,
  en as enTranslation,
  es as esTranslation,
  fr as frTranslation,
  it as itTranslation,
  pt as ptTranslation,
  ro as roTranslation,
} from '@dashboard_utils/index';
import Account from '@models/Account';
import FetchError from '@models/FetchError';
import { PRIMARY_COLOR } from '../utils/colors';
import ConfirmDialog from '../dialogs/ConfirmDialog';

const AccountVerificationPage = lazy(
  () => import('../external/AccountVerificationPage')
);
const AccountSetupPage = lazy(
  () => import('../external/AccountSetupPage')
);
const Dashboard = lazy(() => import('../dashboard/Dashboard'));
const ErrorPage = lazy(() => import('../utils/ErrorPage'));
const ConErrorPage = lazy(() => import('../utils/ConErrorPage'));
const LoginPage = lazy(() => import('../external/LoginPage'));
const PasswordRecoveryPage = lazy(
  () => import('../external/PasswordRecoveryPage')
);
const PasswordResetPage = lazy(() => import('../external/PasswordResetPage'));
const SignUpPage = lazy(() => import('../external/SignUpPage'));
const Terms = lazy(() => import('../external/Terms/Terms'));

const FloorplanConfigurationFormDialog = lazy(
  () => import('../forms/FloorplanConfigurationForm/index')
);

const getTheme = (mode: string) =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: PRIMARY_COLOR,
      },
    },
    components: {
      MuiButtonBase: {
        root: {
          padding: undefined,
          display: undefined,
        },
      },
      MuiTab: {
        wrapper: {
          flexDirection: 'row',
        },
      },
      MUIDataTable: {
        paper: {
          boxShadow: 'none',
          flex: 1,
        },
      },
      MuiTableCell: {
        head: {
          fontWeight: 700,
        },
        root: {
          padding: 8,
        },
      },
      MuiGrid: {
        root: {
          maxHeight: '100%',
        },
        item: {
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
  } as ThemeOptions);

interface IProps {
  account?: Account;
  authenticated: boolean;
  changeLanguage: (language: string) => void;
  changeWSStatus: (reconnecting: ReconnectingStates) => void;
  fetchAccounts: (properties: IFetchAccounts) => void;
  fetchAssets: (properties: IFetchAssets) => void;
  fetchOrganisationInformation: (
    properties: IFetchOrganisationInformation
  ) => void;
  fetchWarehouses: (properties: IFetchWarehouses) => void;
  error?: FetchError;
  firstAccountCheck: boolean;
  language: string;
  locale: string;
  router: any;
  reconnecting?: ReconnectingStates;
  theme: string;
}

class App extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);

    this.handleWSDisconnection = this.handleWSDisconnection.bind(this);
    this.handleWSReconnected = this.handleWSReconnected.bind(this);
    this.handleWSFetchTrigger = this.handleWSFetchTrigger.bind(this);
    this.handleWSReconnecting = this.handleWSReconnecting.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  public componentDidMount() {
    const { account, reconnecting } = this.props;

    if ((account || {}).organisationId) {
      this.fetchData();

      if (reconnecting === ReconnectingStates.DISCONNECTED) {
        ws.reconnect();
      }
    }

    window.addEventListener('WSDisconnected', this.handleWSDisconnection, {
      passive: true,
    });
    window.addEventListener('WSReconnected', this.handleWSReconnected, {
      passive: true,
    });
    window.addEventListener('WSFetchTrigger', this.handleWSFetchTrigger, {
      passive: true,
    });
    window.addEventListener('WSReconnecting', this.handleWSReconnecting, {
      passive: true,
    });
  }

  public componentDidUpdate(prevProps: any) {
    const { account, changeLanguage, router, language } =
      this.props;

    const organisationId = (account || {}).organisationId || '';

    /**
     * If an organisation is selected, or the organisation selection changes,
     * fetchs base data for that organisation.
     * Organisation info, warehouse, assets data.
     */
    if (
      (prevProps.account || {}).organisationId !== organisationId &&
      organisationId
    ) {
      // Also triggers ws reconnection to update ws client organisation id.
      ws.reconnect(false);

      this.fetchData();
    }

    /**
     * Check if the language in use matchs active language (router parameter),
     * if not, trigger language change to match router parameter.
     */
    const activeLanguage = router.params.language || 'en';

    if (language !== activeLanguage) {
      changeLanguage(activeLanguage);
    }

    /**
     * On account locale update, triggers route language parameter
     * change to match new account locale for the active organisation.
     */

    const accountMeta =
      ((account || {}).organisations || {})[organisationId] || {};
    const prevAccountMeta =
      ((prevProps.account || {}).organisations || {})[
        (prevProps.account || {}).organisationId || ''
      ] || {};

    if (accountMeta.locale && accountMeta.locale !== prevAccountMeta.locale) {
      router.navigate(
        location.pathname.replace(
          `/${activeLanguage}/`,
          `/${((accountMeta || {}).locale || '').split('-')[0]}/`
        )
      );
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('WSDisconnected', this.handleWSDisconnection);
    window.removeEventListener('WSReconnected', this.handleWSReconnected);
    window.removeEventListener('WSFetchTrigger', this.handleWSFetchTrigger);
    window.removeEventListener('WSReconnecting', this.handleWSReconnecting);
  }

  public handleWSDisconnection() {
    const { changeWSStatus } = this.props;

    changeWSStatus(ReconnectingStates.DISCONNECTED);
  }

  public handleWSReconnected() {
    const { changeWSStatus } = this.props;

    changeWSStatus(ReconnectingStates.CONNECTED);
  }

  public handleWSFetchTrigger() {
    this.fetchData(true);
  }

  public handleWSReconnecting() {
    const { changeWSStatus } = this.props;

    changeWSStatus(ReconnectingStates.RECONNECTING);
  }

  public fetchData(shallow?: boolean) {
    const {
      account,
      fetchAccounts,
      fetchAssets,
      fetchOrganisationInformation,
      fetchWarehouses,
    } = this.props;

    if ((account || {}).organisationId) {
      fetchAssets({ shallow });
      fetchOrganisationInformation({ shallow });
      fetchWarehouses({ shallow });
      fetchAccounts({ shallow });
    }
  }

  public render() {
    const { authenticated, error, firstAccountCheck, language, locale, theme } =
      this.props;

    let localeTools = enLocale;
    if (locale.indexOf('de') === 0) {
      localeTools = deLocale;
    } else if (locale.indexOf('es') === 0) {
      localeTools = esLocale;
    } else if (locale.indexOf('fr') === 0) {
      localeTools = frLocale;
    } else if (locale.indexOf('pt') === 0) {
      localeTools = ptLocale;
    } else if (locale.indexOf('ro') === 0) {
      localeTools = roLocale;
    }

    let messages = enTranslation;
    if (language === 'de') {
      messages = deTranslation;
    }
    if (language === 'es') {
      messages = esTranslation;
    }
    if (language === 'fr') {
      messages = frTranslation;
    }
    if (language === 'it') {
      messages = itTranslation;
    }
    if (language === 'pt') {
      messages = ptTranslation;
    }
    if (language === 'ro') {
      messages = roTranslation;
    }

    return (
      <ThemeProvider theme={getTheme(theme)}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeTools}>
          <CssBaseline>
            <IntlProvider
              key={locale}
              locale={locale}
              messages={messages}
              defaultLocale="en-GB"
            >
              <Suspense fallback={<Loading loading />}>
                <ErrorCatcher errorComponent={<ErrorPage />}>
                  {error && error.code === 0 ? (
                    <ConErrorPage />
                  ) : (
                    <Loading loading={!firstAccountCheck}>
                      <Routes>
                        <Route
                          path="/accounts/verify"
                          element={(
                            <AccountVerificationPage/>
                          )}
                        />
                        <Route
                          path="/accounts/invite"
                          element={(
                            <AccountSetupPage/>
                          )}
                        />
                        <Route
                          path="/error"
                          element={<ErrorPage/>}
                        />
                        <Route
                          path="/terms"
                          element={<Terms/>}
                        />
                        <Route
                          path="/privacy-policy"
                          element={<Terms/>}
                        />

                        {authenticated ? (
                          <>
                            <Route
                              path="/login"
                              element={<LoginPage/>}
                            />
                            <Route
                              path="/dashboard/settings/warehouses/:warehouseId/:floorplanId"
                              element={<FloorplanConfigurationFormDialog />}
                            />
                            <Route
                              path="/dashboard/settings/warehouses/:warehouseId/:floorplanId/:tab"
                              element={<FloorplanConfigurationFormDialog />}
                            />
                            <Route
                              path="/dashboard/*"
                              element={<Dashboard />}
                            />
                            <Route
                              path="*"
                              element={<Navigate to={`/${language}/dashboard`} />}
                            />
                          </>
                        ) : (
                          <>
                            <Route
                              path="/login"
                              element={<LoginPage/>}
                            />
                            <Route
                              path="/password-recovery"
                              element={<PasswordRecoveryPage />}
                            />
                            <Route
                              path="/accounts/password-reset"
                              element={(
                                <PasswordResetPage/>
                              )}
                            />
                            <Route
                              path="/signup"
                              element={<SignUpPage/>}
                            />
                            <Route
                              path="*"
                              element={<Navigate to={`/${language}/login`} replace />}
                            />
                          </>
                        )}
                      </Routes>
                    </Loading>
                  )}
                </ErrorCatcher>
              </Suspense>
              <ConfirmDialog />
              <ActionFeedback />
            </IntlProvider>
          </CssBaseline>
        </LocalizationProvider>
        <canvas id="hidden" hidden />
      </ThemeProvider>
    );
  }
}

export default App;
