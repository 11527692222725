import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ICreatePreCheck, IEditPreCheck } from '@actions/index';
import CustomTableCell from '@app/common/mui-custom/CustomTableCell';
import LoadingButton from '@app/common/LoadingButton';
import PreCheck, { IPreCheckQuestion, ResponseTypes } from '@models/PreCheck';

interface IState {
  id?: string;
  name: string;
  questions: IPreCheckQuestion[];
}

interface IProps {
  closePreCheckForm: () => void;
  createPreCheck: (properties: ICreatePreCheck) => void;
  editPreCheck: (properties: IEditPreCheck) => void;
  data?: PreCheck;
  loading: boolean;
}

class PreCheckForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { data } = this.props;

    this.state = {
      id: data !== undefined ? data.id : undefined,
      name: data !== undefined ? data.name : '',
      questions: data !== undefined ? data.questions : [],
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddCheck = this.handleAddCheck.bind(this);
  }

  public handleCheckChange(key: keyof IPreCheckQuestion, index: number) {
    return (e: any) => {
      const { value } = e.target;
      const { questions } = this.state;

      // @ts-ignore
      questions[index][key] = value;

      this.setState({ questions });
    };
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value || '',
      } as Pick<IState, 'name'>);
    };
  }

  public handleAddCheck() {
    const { questions } = this.state;

    questions.push({
      description: '',
      index: (questions.length ? questions[questions.length - 1].index : 0) + 1,
      responseType: ResponseTypes.YESNO,
    });

    this.setState({ questions });
  }

  public handleRemoveCheck(index: number) {
    const { questions } = this.state;

    questions.splice(index, 1);

    this.setState({ questions });
  }

  public handleCancel() {
    const { closePreCheckForm } = this.props;

    closePreCheckForm();
  }

  public handleSubmit() {
    const { id, name, questions } = this.state;

    const { createPreCheck, editPreCheck } = this.props;

    if (id !== undefined) {
      editPreCheck({
        id,
        name,
        questions,
      });
    } else {
      createPreCheck({ name, questions });
    }
  }

  public render() {
    const { loading } = this.props;
    const { questions, id, name } = this.state;

    const error =
      name.length === 0 ||
      questions.length === 0 ||
      questions.filter(
        (c) =>
          c.description.length === 0 ||
          c.responseType.length === 0 ||
          (!c.scale && c.responseType === ResponseTypes.SCALE)
      ).length > 0;

    const buttonDisabled = error;

    return (
      <Dialog open maxWidth="lg" fullWidth>
        <DialogTitle>
          {id ? (
            <FormattedMessage
              id="dashboard.forms.precheck.title_edit"
              defaultMessage="Edit Pre-Check List {name}"
              values={{ name }}
            />
          ) : (
            <FormattedMessage
              id="dashboard.forms.precheck.title_create"
              defaultMessage="Add Pre-Check List"
            />
          )}
        </DialogTitle>
        <DialogContent>
          <FormControl required fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.forms.precheck.label_name"
                  defaultMessage="Name"
                />
              }
              variant="standard"
              value={name}
              onChange={this.handleChange('name')}
              helperText={
                !name ? (
                  <FormattedMessage
                    id="dashboard.forms.precheck.field_name_helper"
                    defaultMessage="A name must be specified"
                  />
                ) : null
              }
            />
          </FormControl>
          <Table style={{ marginTop: '40px', marginBottom: '20px' }}>
            <TableHead>
              <TableRow>
                <CustomTableCell>
                  <FormattedMessage
                    id="dashboard.forms.precheck.questions.description"
                    defaultMessage="Description"
                  />
                </CustomTableCell>
                <CustomTableCell>
                  <FormattedMessage
                    id="dashboard.forms.precheck.questions.responsetype"
                    defaultMessage="Response Type"
                  />
                </CustomTableCell>
                <CustomTableCell>
                  <FormattedMessage
                    id="dashboard.forms.precheck.questions.scale"
                    defaultMessage="Scale"
                  />
                </CustomTableCell>
                <CustomTableCell />
              </TableRow>
            </TableHead>
            {questions.map((check, index) => {
              const { description, responseType, scale } = check;

              return (
                <TableBody key={index}>
                  <TableRow hover={false}>
                    <CustomTableCell>
                      <FormControl fullWidth margin="dense">
                        <Input
                          value={description}
                          onChange={this.handleCheckChange(
                            'description',
                            index
                          )}
                        />
                        {!description ? (
                          <FormHelperText>
                            <FormattedMessage
                              id="dashboard.forms.precheck.field_description_helper"
                              defaultMessage="A description must be specified"
                            />
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </CustomTableCell>
                    <CustomTableCell>
                      <FormControl fullWidth margin="dense">
                        <Select
                          value={responseType}
                          onChange={this.handleCheckChange(
                            'responseType',
                            index
                          )}
                          input={
                            <Input name="responseType" id="responseType" />
                          }
                        >
                          {Object.keys(ResponseTypes).map((key: string) => (
                            <MenuItem
                              key={key}
                              value={
                                ResponseTypes[key as keyof typeof ResponseTypes]
                              }
                            >
                              <FormattedMessage
                                id={`dashboard.forms.precheck.checks.responsetype_${
                                  ResponseTypes[
                                    key as keyof typeof ResponseTypes
                                  ]
                                }`}
                                defaultMessage={
                                  ResponseTypes[
                                    key as keyof typeof ResponseTypes
                                  ]
                                }
                              />
                            </MenuItem>
                          ))}
                        </Select>
                        {!responseType ? (
                          <FormHelperText>
                            <FormattedMessage
                              id="dashboard.forms.precheck.field_responsetype_helper"
                              defaultMessage="A response type must be specified"
                            />
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </CustomTableCell>
                    <CustomTableCell>
                      {responseType === ResponseTypes.SCALE ? (
                        <FormControl fullWidth margin="dense">
                          <Input
                            value={scale || ''}
                            type="number"
                            onChange={this.handleCheckChange('scale', index)}
                          />
                          {!scale ? (
                            <FormHelperText>
                              <FormattedMessage
                                id="dashboard.forms.precheck.field_scale_helper"
                                defaultMessage="A scale type must be specified"
                              />
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      ) : null}
                    </CustomTableCell>
                    <CustomTableCell
                      style={{ textAlign: 'right', paddingRight: '3px' }}
                    >
                      <RemoveIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.handleRemoveCheck(index)}
                      />
                    </CustomTableCell>
                  </TableRow>
                </TableBody>
              );
            })}
            <TableBody>
              <TableRow hover={false}>
                <CustomTableCell
                  style={{ textAlign: 'right', paddingRight: '3px' }}
                  colSpan={4}
                >
                  <AddIcon
                    style={{ cursor: 'pointer' }}
                    onClick={this.handleAddCheck}
                  />
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Box mr={1}>
            <Button onClick={this.handleCancel}>
              <FormattedMessage
                id="dashboard.forms.precheck.button_cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </Box>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color={id ? 'secondary' : 'primary'}
            disabled={buttonDisabled}
            loading={loading}
          >
            {id ? (
              <FormattedMessage
                id="dashboard.forms.precheck.buttom_submit_edit"
                defaultMessage="Edit"
              />
            ) : (
              <FormattedMessage
                id="dashboard.forms.precheck.buttom_submit_create"
                defaultMessage="Create"
              />
            )}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PreCheckForm;
