import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import * as actions from '@actions/index';
import { IAppState } from '@reducers/index';
import CreateFloorplan from './CreateFloorplan';

interface IProps {
  onNext: () => void;
  warehouseId: string;
}

const mapStateToProps = (state: IAppState) => ({
  loading: state.floorplans.loading,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { onNext, warehouseId }: IProps
) => ({
  createFloorplan: (intl: IntlShape, name: string, image?: File) => {
    dispatch(
      actions.createFloorplan.request({
        intl,
        properties: { image, name },
        warehouseId,
      })
    );
    onNext();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateFloorplan);
