import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import React, { Component } from 'react';

import { ICreateEmployee, IEditEmployee } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import CustomTableCell from '@app/common/mui-custom/CustomTableCell';
import { Date, timeZone } from '@dashboard_utils/index';
import Asset, { Assets } from '@models/Asset';
import { Employee } from '@models/Employee';
import { IncompleteAssetAssociation } from '@models/IncompleteAssetAssociation';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import { Warehouses } from '@models/Warehouse';
import { WarehouseWithFloorplans } from '@models/WarehouseWithFloorplans';
import {
  areEmployeesAssetAssociationsDuplicated,
  isEmployeeAssetAssociationDuplicated,
} from '@selectors/employees';
import {
  areAssociationsOverlapped,
  isAssociationOverlapped,
} from '@selectors/utils';
import { findWarehouseByFloorplanId } from '../../utils/floorplanUtils';
import PasswordFields from '../AccountForm/PasswordFields';

const messages = defineMessages({
  altText: {
    defaultMessage: 'Employee image',
    id: 'dashboard.forms.employeeform.img_alt',
  },
});

const handleFileUploadClick = (event: any) => {
  event.stopPropagation();

  document.getElementById('form-image')!.click();
};

export interface IState {
  assetAssociations: IncompleteAssetAssociation[];
  file?: File;
  id?: string;
  image?: string;
  name: string;
  email: string;
  password: string;
  repassword: string;
}

interface IProps {
  assets: Assets;
  closeEmployeeForm: () => void;
  createEmployee: (properties: ICreateEmployee) => void;
  floorplans: IncompleteFloorplans;
  updateEmployee: (properties: IEditEmployee) => void;
  employees: Employee[];
  data?: Employee;
  intl: IntlShape;
  loading: boolean;
  warehouses: Warehouses;
}

class EmployeeForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { data } = props;

    this.state = {
      assetAssociations:
        data !== undefined
          ? (data.assetAssociations as IncompleteAssetAssociation[])
          : [],
      id: (data || {}).id,
      image: (data || {}).image || '',
      name: (data || {}).name || '',
      email: (data || {}).email || '',
      password: '',
      repassword: '',
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleImageRemoval = this.handleImageRemoval.bind(this);
    this.handleAddAssetAssociation = this.handleAddAssetAssociation.bind(this);
    this.handleAssetAssociationChange =
      this.handleAssetAssociationChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value || '',
      } as Pick<IState, 'name'>);
    };
  }

  public handleFileUpload(event: any) {
    const { files } = event.target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (e.target !== null) {
          this.setState({ image: e.target.result });
        }
      };

      reader.readAsDataURL(files[0]);

      this.setState({
        file: files[0],
      });
    }
  }

  public handleImageRemoval() {
    this.setState({ image: undefined });
  }

  public handleAddAssetAssociation() {
    const { assetAssociations } = this.state;

    assetAssociations.push({ from: Date.now() });

    this.setState({ assetAssociations });
  }

  public handleRemoveAssetAssociation(index: number) {
    const { assetAssociations } = this.state;

    assetAssociations.splice(index, 1);

    this.setState({ assetAssociations });
  }

  public handleAssetAssociationChange(
    key: keyof IncompleteAssetAssociation,
    index: number
  ) {
    return (e: any) => {
      const { value } = e.target;
      const { assetAssociations } = this.state;

      const assetAssociation: any = assetAssociations[index];
      assetAssociation[key] = value;

      this.setState({ assetAssociations });
    };
  }

  public handleSensorAssociationDateChange(
    key: keyof IncompleteAssetAssociation,
    warehouseTz: string,
    index: number,
    date: Date
  ) {
    const { assetAssociations } = this.state;

    // On keystroke date can be incomplete, update only when valid
    if (date) {
      // KeyboardDatepicker returns a string when edited by keyboard
      // that string has to be read into the target timezone and converted to ts
      // @ts-ignore
      assetAssociations[index][key] =
        date !== null
          ? new Date(warehouseTz, date, warehouseTz).getTime()
          : undefined;

      this.setState({ assetAssociations });
    }
  }

  public handleCancel() {
    const { closeEmployeeForm } = this.props;

    closeEmployeeForm();
  }

  public handleSubmit() {
    const { assetAssociations, file, id, image, name, email, password } = this.state;
    const { createEmployee, intl, updateEmployee } = this.props;

    if (id !== undefined) {
      updateEmployee({
        assetAssociations,
        file,
        id,
        image,
        intl,
        name,
        email,
        password,
      });
    } else {
      createEmployee({
        assetAssociations,
        file,
        intl,
        name,
        email,
        password,
      });
    }
  }

  public render() {
    const { assets, floorplans, employees, intl, loading, warehouses } =
      this.props;
    const { assetAssociations, id, image, name, email, password, repassword } = this.state;

    const doPasswordsMatch =
      password.length > 0 && repassword.length > 0
        ? password === repassword
        : true;
    const error =
      name.length === 0 ||
      email.length === 0 ||
      assetAssociations.find(
        (sa) =>
          sa.from === undefined ||
          sa.from === null ||
          sa.assetId === undefined ||
          sa.assetId === null
      ) !== undefined ||
      doPasswordsMatch === false ||
      areAssociationsOverlapped(assetAssociations) ||
      areEmployeesAssetAssociationsDuplicated(
        employees,
        id || '',
        assetAssociations
      );

    const buttonDisabled = error;

    return (
      <Dialog open fullWidth maxWidth="sm">
        <DialogTitle>
          {id ? (
            <FormattedMessage
              id="dashboard.forms.employeeform.title_edit"
              defaultMessage="Edit Employee {name}"
              values={{ name }}
            />
          ) : (
            <FormattedMessage
              id="dashboard.forms.employeeform.title_create"
              defaultMessage="Add Employee"
            />
          )}
        </DialogTitle>
        <DialogContent>
          {image ? (
            <div style={{ position: 'relative' }}>
              <ClearIcon
                color="error"
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '160px',
                  top: '10px',
                }}
                onClick={this.handleImageRemoval}
              />
              <Avatar
                alt={intl.formatMessage(messages.altText)}
                src={image}
                style={{ width: 200, height: 200, margin: 'auto' }}
              />
            </div>
          ) : (
            <>
              <div style={{ position: 'relative' }}>
                <label htmlFor="form-image">
                  <Button
                    style={{ width: '100%', height: '250px' }}
                    variant="outlined"
                    color="primary"
                    onClick={handleFileUploadClick}
                  >
                    <FormattedMessage
                      id="dashboard.forms.employeeform.upload_button"
                      defaultMessage="Upload image"
                    />
                  </Button>
                  <input
                    id="form-image"
                    accept="image/*"
                    type="file"
                    style={{ display: 'none' }}
                    onInput={this.handleFileUpload}
                  />
                </label>
              </div>
            </>
          )}
          <FormControl required fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.forms.employeeform.label_name"
                  defaultMessage="Name"
                />
              }
              variant="standard"
              value={name}
              onChange={this.handleChange('name')}
              helperText={
                <FormattedMessage
                  id="dashboard.forms.employeeform.field_name_helper"
                  defaultMessage="A name must be specified"
                />
              }
            />
          </FormControl>
          <FormControl required fullWidth margin="normal">
            <TextField
              label="email"
              variant="standard"
              value={email}
              onChange={this.handleChange('email')}
              helperText="A email must be specified"
            />
          </FormControl>
          <PasswordFields
            doPasswordsMatch={doPasswordsMatch}
            handleChange={this.handleChange}
            password={password}
            repassword={repassword}
          />
          <Typography
            variant="subtitle1"
            style={{
              borderBottom: '1px solid #CCC',
              marginBottom: '10px',
              marginTop: '30px',
            }}
          >
            <FormattedMessage
              id="dashboard.forms.employeeform.assetassociations.title"
              defaultMessage="Asset Associations"
            />
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell>
                  <FormattedMessage
                    id="dashboard.forms.employeeform.assetassociations.from"
                    defaultMessage="From"
                  />
                </CustomTableCell>
                <CustomTableCell>
                  <FormattedMessage
                    id="dashboard.forms.employeeform.assetassociations.to"
                    defaultMessage="To"
                  />
                </CustomTableCell>
                <CustomTableCell>
                  <FormattedMessage
                    id="dashboard.forms.employeeform.assetassociations.sensor"
                    defaultMessage="Asset"
                  />
                </CustomTableCell>
                <CustomTableCell />
              </TableRow>
            </TableHead>
            {assetAssociations.map((aa, index) => {
              const isDuplicated = isEmployeeAssetAssociationDuplicated(
                employees,
                index,
                id || '',
                assetAssociations
              );
              const isOverlapped = isAssociationOverlapped(
                index,
                assetAssociations
              );

              const a = assets[aa.assetId || ''] || {};
              const warehouse =
                findWarehouseByFloorplanId(
                  warehouses,
                  floorplans,
                  a.floorplanId || ''
                ) || ({} as WarehouseWithFloorplans);

              const warehouseTz = warehouse.timezone || timeZone;

              return (
                <TableBody key={index}>
                  <TableRow hover={false}>
                    <CustomTableCell>
                      <FormControl
                        required
                        error={isOverlapped === true || isDuplicated === true}
                      >
                        <DateTimePicker
                          value={
                            new Date(
                              warehouseTz,
                              aa.from || new Date(warehouseTz)
                            )
                          }
                          maxDate={
                            new Date(
                              warehouseTz,
                              aa.to || new Date(warehouseTz)
                            )
                          }
                          onChange={(date: any) => {
                            this.handleSensorAssociationDateChange(
                              'from',
                              warehouseTz,
                              index,
                              date
                            );
                          }}
                          slotProps={{
                            textField: { variant: 'standard' }
                          }}
                          ampm={false}
                        />
                      </FormControl>
                    </CustomTableCell>
                    <CustomTableCell>
                      <FormControl
                        required
                        error={
                          (isOverlapped === true || isDuplicated === true) &&
                          aa.to !== undefined
                        }
                      >
                        <DateTimePicker
                          value={
                            aa.to ? new Date(warehouseTz, aa.to) : undefined
                          }
                          minDate={
                            new Date(
                              warehouseTz,
                              aa.from || new Date(warehouseTz)
                            ) as any
                          }
                          onChange={(date: any) => {
                            this.handleSensorAssociationDateChange(
                              'to',
                              warehouseTz,
                              index,
                              date
                            );
                          }}
                          slotProps={{
                            textField: { variant: 'standard' }
                          }}
                          ampm={false}
                        />
                      </FormControl>
                    </CustomTableCell>
                    <CustomTableCell>
                      <FormControl fullWidth variant="standard" margin="dense">
                        <Select
                          value={aa.assetId || ''}
                          required
                          onChange={this.handleAssetAssociationChange(
                            'assetId',
                            index
                          )}
                          input={<Input name="assetId" />}
                          error={isOverlapped === true || isDuplicated === true}
                        >
                          {Object.values(assets).map((asset: Asset) => (
                            <MenuItem key={asset.id} value={asset.id}>
                              {asset.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </CustomTableCell>
                    <CustomTableCell
                      style={{ textAlign: 'right', paddingRight: '3px' }}
                    >
                      <RemoveIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.handleRemoveAssetAssociation(index)}
                      />
                    </CustomTableCell>
                  </TableRow>
                  {isOverlapped || isDuplicated ? (
                    <TableRow hover={false}>
                      <TableCell colSpan={3}>
                        <FormHelperText error>
                          {isOverlapped ? (
                            <FormattedMessage
                              id="dashboard.forms.employeeform.assetassociations.overlap_error"
                              defaultMessage="This asset association entry overlaps"
                            />
                          ) : (
                            <FormattedMessage
                              id="dashboard.forms.employeeform.assetassociations.overlapingassociation_error"
                              defaultMessage="There are other employees with an overlapping asset association"
                            />
                          )}
                        </FormHelperText>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              );
            })}
            <TableBody>
              <TableRow hover={false}>
                <CustomTableCell style={{ textAlign: 'right' }} colSpan={4}>
                  <AddIcon
                    style={{ cursor: 'pointer' }}
                    onClick={this.handleAddAssetAssociation}
                  />
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Box mr={1}>
            <Button onClick={this.handleCancel}>
              <FormattedMessage
                id="dashboard.forms.assetform.button_cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </Box>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color={id ? 'secondary' : 'primary'}
            disabled={buttonDisabled}
            loading={loading}
          >
            {id ? (
              <FormattedMessage
                id="dashboard.forms.employeeform.button_submit_edit"
                defaultMessage="Edit"
              />
            ) : (
              <FormattedMessage
                id="dashboard.forms.employeeform.button_submit_create"
                defaultMessage="Create"
              />
            )}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(EmployeeForm);
