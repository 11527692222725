import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import SensorInstallationForm from './SensorInstallationForm';

interface IProps {
  createFakeSensors: () => void;
  loading: boolean;
  installationComplete: boolean;
}

const SensorInstallation: FunctionComponent<IProps> = ({
  createFakeSensors,
  installationComplete,
  loading,
}: IProps) => (
  <Card className="step-card">
    <CardContent style={{ flex: 1, position: 'relative' }}>
      <div
        style={{
          bottom: 0,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 20,
        }}
      >
        <div
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <SensorInstallationForm createFakeSensors={createFakeSensors} loading={loading} installationComplete={installationComplete} />
          {!loading && installationComplete ?
            <Typography variant="subtitle2">
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.sensorinstallation.success"
                defaultMessage="Installation complete! Congrats!"
              />
            </Typography>
          : null}
        </div>
      </div>
    </CardContent>
  </Card>
);

export default SensorInstallation;
