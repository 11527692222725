import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PRIMARY_COLOR } from '@app/utils/colors';

export interface IProps {
  assetName?: string;
  color?: string;
  left: number;
  active: boolean;
  width: number;
}

interface IState {
  show: boolean;
  positionX: number;
}

class EventActivity extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      positionX: 0,
      show: false,
    };

    this.showEvent = this.showEvent.bind(this);
    this.hideEvent = this.hideEvent.bind(this);
    this.moveShowEvent = this.moveShowEvent.bind(this);
  }

  /**
   * @description Display buble information for an given activity event
   * @param       {any} event Mouse event - mouse enter or mouse leave
   */
  private showEvent = (event: any) => {
    const currentTargetRect = event.currentTarget.getBoundingClientRect();

    const currentPositionX = event.pageX - currentTargetRect.left;

    this.setState({ positionX: currentPositionX, show: true });
  };

  /**
   * @description Hide buble information for an given activity event
   */
  private hideEvent = () => {
    this.setState({ show: false });
  };

  /**
   * @description Updates event info buble position for an given event
   * @param       {any} event Mouse move
   */
  private moveShowEvent = (event: any) => {
    const currentTargetRect = event.currentTarget.getBoundingClientRect();

    const currentPositionX = event.pageX - currentTargetRect.left;

    this.setState({ positionX: currentPositionX });
  };

  public render() {
    const { assetName, color, left, active, width } = this.props;
    const { positionX, show } = this.state;

    return (
      <div
        className="chunk"
        style={{
          backgroundColor: color,
          left: `${left}%`,
          width: `${width}%`,
          border: show ? `1px solid ${PRIMARY_COLOR}` : 'unset',
        }}
        onMouseEnter={this.showEvent}
        onMouseLeave={this.hideEvent}
        onMouseMove={this.moveShowEvent}
      >
        {show && (
          <div className="event-info" style={{ left: `${positionX}px` }}>
            <span className="buble">
              {assetName}
              <span>: </span>
              <b>
                {active ? (
                  <FormattedMessage
                    id="dashboard.events.event.status.active"
                    defaultMessage="Active"
                  />
                ) : (
                  <FormattedMessage
                    id="dashboard.events.event.status.idle"
                    defaultMessage="Idle"
                  />
                )}
              </b>
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default EventActivity;
