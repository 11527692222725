import Box from '@mui/material/Box';
import React, { FunctionComponent, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Loading from '@app/common/Loading';
const ShiftForm = lazy(() => import('./ShiftForm'));
const Tab = lazy(() => import('./Tab'));

interface IProps {
  language: string;
}

const RouterComp: FunctionComponent<IProps> = ({ language }: IProps) => (
  <Box className="tab-content-inner" p={2}>
    <Suspense fallback={<Loading loading />}>
      <Routes>
        <Route
          path="/:id/edit"
          element={<ShiftForm />}
        />
        <Route
          path="/new"
          element={<ShiftForm />}
        />
        <Route
          path="/"
          element={<Tab />}
        />
        <Route
          path="*"
          element={<Navigate to={`/${language}/dashboard/shifts`} replace />}
        />
      </Routes>
    </Suspense>
  </Box>
);

export default RouterComp;
