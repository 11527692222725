import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { ICreateWarehouse, IUpdateWarehouse } from '@actions/index';
import Warehouse from '@models/Warehouse';
import WarehouseFormContent from './WarehouseFormContent';
import { IState } from './types';

interface IProps {
  createWarehouse: (properties: ICreateWarehouse) => void;
  data?: Warehouse;
  intl: IntlShape;
  onClose: () => void;
  updateWarehouse: (properties: IUpdateWarehouse) => void;
}

export interface IOption {
  label: string;
  value: string;
}

class WarehouseFormDialog extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { data } = props;

    this.state = {
      id: data ? data.id : undefined,
      name: data ? data.name : '',
      site: data ? data.site : '',
      timezone: data ? data.timezone : '',
      country: data ? data.country : 'PT',
      metricsTotalPalletsMoved: (data || {}).metricsTotalPalletsMoved,
      metricsFleetUsedCapacity: (data || {}).metricsFleetUsedCapacity,
      numberOfShifts: (data || {}).numberOfShifts,
      shiftHours: (data || {}).shiftHours,
      workingDays: (data || {}).workingDays,
      targetForkliftType: (data || {}).targetForkliftType,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      // timezone is a result of react-select onChange event
      // and therefore a { value: '', label: '' } object
      if (key === 'timezone') {
        this.setState({ timezone: e.value });
      } else {
        const { value, type } = e.target;

        this.setState({
          [key]: type === 'number' && value !== '' ? Number(value) : value,
        } as Pick<IState, 'name'>);
      }
    };
  }

  public handleCancel() {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }

    this.reset();
  }

  public handleSubmit() {
    const {
      id,
      name,
      site,
      timezone,
      country,
      metricsTotalPalletsMoved,
      metricsFleetUsedCapacity,
      numberOfShifts,
      shiftHours,
      workingDays,
      targetForkliftType,
    } = this.state;
    const { createWarehouse, intl, onClose, updateWarehouse } = this.props;

    if (id !== undefined) {
      updateWarehouse({
        id,
        intl,
        name,
        site,
        timezone,
        country,
        metricsTotalPalletsMoved,
        metricsFleetUsedCapacity,
        numberOfShifts,
        shiftHours,
        workingDays,
        targetForkliftType,
      });
    } else {
      createWarehouse({
        intl,
        name,
        site,
        timezone,
        country,
        metricsTotalPalletsMoved,
        metricsFleetUsedCapacity,
        numberOfShifts,
        shiftHours,
        workingDays,
        targetForkliftType,
      });
    }

    if (onClose) {
      onClose();
    }

    this.reset();
  }

  public reset() {
    this.setState({
      id: undefined,
      name: '',
      site: '',
      timezone: '',
      country: 'PT',
      metricsTotalPalletsMoved: undefined,
      metricsFleetUsedCapacity: undefined,
      numberOfShifts: undefined,
      shiftHours: undefined,
      workingDays: undefined,
      targetForkliftType: undefined,
    });
  }

  public render() {
    const {
      id,
      name,
      site,
      timezone,
      country,
      metricsTotalPalletsMoved,
      metricsFleetUsedCapacity,
      numberOfShifts,
      shiftHours,
      workingDays,
      targetForkliftType,
    } = this.state;

    const nameError = name.length === 0;
    const timezoneError = timezone.length === 0;

    const buttonDisabled = nameError || timezoneError;

    return (
      <Dialog fullWidth maxWidth="sm" open>
        <DialogTitle>
          {id ? (
            <FormattedMessage
              id="dashboard.forms.warehouseform.title_edit"
              defaultMessage="Edit Warehouse {name}"
              values={{ name }}
            />
          ) : (
            <FormattedMessage
              id="dashboard.forms.warehouseform.title_create"
              defaultMessage="Add Warehouse"
            />
          )}
        </DialogTitle>
        <DialogContent>
          <WarehouseFormContent
            name={name}
            site={site}
            timezone={timezone}
            country={country}
            metricsTotalPalletsMoved={metricsTotalPalletsMoved}
            metricsFleetUsedCapacity={metricsFleetUsedCapacity}
            numberOfShifts={numberOfShifts}
            shiftHours={shiftHours}
            workingDays={workingDays}
            targetForkliftType={targetForkliftType}
            handleChange={this.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            <FormattedMessage
              id="dashboard.forms.warehouseform.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            onClick={this.handleSubmit}
            variant="contained"
            color={id ? 'secondary' : 'primary'}
            disabled={buttonDisabled}
          >
            {id ? (
              <FormattedMessage
                id="dashboard.forms.warehouseform.button_edit"
                defaultMessage="Edit"
              />
            ) : (
              <FormattedMessage
                id="dashboard.forms.warehouseform.button_create"
                defaultMessage="Create"
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(WarehouseFormDialog);
