import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import ZonesForm from '@app/forms/FloorplanConfigurationForm/Zones/ZonesForm';
import LoadingButton from '@app/common/LoadingButton/index';
import Zone from '@models/Zone';

interface IProps {
  floorplanId: string;
  loading: boolean;
  onBack: () => void;
  onNext: () => void;
  onSkip: () => void;
  zones: Zone[];
}

const StepContainer: FunctionComponent<IProps> = ({
  floorplanId,
  loading,
  onSkip,
  onBack,
  onNext,
  zones,
}: IProps) => (
  <Card className="step-card">
    <CardContent style={{ flex: 1, position: 'relative' }}>
      <ZonesForm floorplanId={floorplanId} />
    </CardContent>
    <CardActions style={{ justifyContent: 'flex-end' }}>
      <Box mr={1}>
        <Button onClick={onBack}>
          <FormattedMessage
            id="dashboard.setup.steps.button_back"
            defaultMessage="Back"
          />
        </Button>
      </Box>
      <Box mr={1}>
        <Button onClick={onSkip}>
          <FormattedMessage
            id="dashboard.setup.steps.button_skip"
            defaultMessage="Skip"
          />
        </Button>
      </Box>
      <LoadingButton
        variant="contained"
        onClick={onNext}
        color="primary"
        loading={loading}
        disabled={!zones.length}
      >
        <FormattedMessage
          id="dashboard.setup.steps.button_next"
          defaultMessage="Next"
        />
      </LoadingButton>
    </CardActions>
  </Card>
);

export default StepContainer;
