import React, { FunctionComponent } from 'react';
import { IntlShape } from 'react-intl';

import CreateOrEditFloorplan from './CreateOrEditFloorplan';

interface IProps {
  image: string;
  loading: boolean;
  name: string;
  onBack: () => void;
  onSkip: (name: string, imageSrc?: string) => void;
  updateFloorplan: (intl: IntlShape, name: string, image?: File) => void;
}

const EditFloorplan: FunctionComponent<IProps> = ({
  image,
  loading,
  name,
  onBack,
  onSkip,
  updateFloorplan,
}: IProps) => (
  <CreateOrEditFloorplan
    onNext={updateFloorplan}
    image={image}
    name={name}
    loading={loading}
    onBack={onBack}
    onSkip={onSkip}
  />
);

export default EditFloorplan;
