import React, { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';

import Landing from './Landing';
import Details from './Details';
import Walkthrough from '../../utils/Walkthrough';
import { withRouter } from '@app/utils/withRouter';

interface IProps {
  language: string;
}

const Home: FunctionComponent<IProps> = ({ language }: IProps) => (
  <Box className="tab-content-inner" p={2}>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/details" element={<Details />} />
      <Route
        path="*"
        element={<Navigate to={`/${language}/dashboard/`} replace />}
      />
    </Routes>
    <Walkthrough type="dashboard" />
  </Box>
);

export default withRouter(Home);
