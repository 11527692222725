import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  assignAssetTicket,
  AssignAssetTicket,
  fetchAccounts,
} from '@actions/index';
import Account from '@models/Account';
import { IAppState } from '@reducers/index';
import AssignmentForm from './AssignmentForm';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account || ({} as Account),
  error: state.assets.tickets.assignForm.error,
  loading: state.assets.tickets.assignForm.loading,
  formLoading: state.accounts.loading,
  accounts: Object.values(state.accounts.accounts || {}),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  assignAssetTicket: (properties: AssignAssetTicket) =>
    dispatch(assignAssetTicket.request(properties)),
  fetchAccounts: () => dispatch(fetchAccounts.request({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentForm);
