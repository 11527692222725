import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { Component } from 'react';

import { SensorSettings } from '@models/Plan';

interface IProps {
  dealWithSensor: (properties: SensorSettings) => void;
  handleDelete: (id: string) => void;
  close: () => void;
  data?: SensorSettings;
}

class SensorSettingsComponent extends Component<IProps, SensorSettings> {
  constructor(props: IProps) {
    super(props);

    const { data } = props;

    this.state = {
      id: (data || {}).id,
      identifier: (data || {}).identifier || '',
      positionX: (data || {}).positionX || 0,
      positionY: (data || {}).positionY || 0,
      positionZ: (data || {}).positionZ || 0,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { data } = this.props;

    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      this.updateForm();
    }
  }

  public handleChange(key: keyof SensorSettings) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({ [key]: value } as Pick<SensorSettings, 'identifier'>);
    };
  }

  public handleCancel() {
    const { close } = this.props;

    close();
  }

  public handleSubmit() {
    const { dealWithSensor } = this.props;

    dealWithSensor(this.state);
  }

  public updateForm() {
    const { data } = this.props;

    this.setState({
      id: (data || {}).id,
      identifier: (data || {}).identifier || '',
      positionX: (data || {}).positionX || 0,
      positionY: (data || {}).positionY || 0,
      positionZ: (data || {}).positionZ || 0,
    });
  }

  public render() {
    const { id, identifier, positionX, positionY, positionZ } = this.state;
    const { handleDelete } = this.props;

    return (
      <Card className="popup-card">
        <CardContent>
          <Typography variant="caption">Sensor Settings</Typography>
          <FormControl required fullWidth margin="normal">
            <TextField
              label="Identifier"
              value={identifier}
              variant="standard"
              onChange={this.handleChange('identifier')}
            />
          </FormControl>
          <FormControl required fullWidth margin="normal">
            <TextField
              label="Position X"
              value={positionX}
              variant="standard"
              type="number"
              inputProps={{ step: 0.01 }}
              onChange={this.handleChange('positionX')}
            />
          </FormControl>
          <FormControl required fullWidth margin="normal">
            <TextField
              label="Position Y"
              value={positionY}
              variant="standard"
              type="number"
              inputProps={{ step: 0.01 }}
              onChange={this.handleChange('positionY')}
            />
          </FormControl>
          <FormControl required fullWidth margin="normal">
            <TextField
              label="Position Z"
              value={positionZ}
              variant="standard"
              type="number"
              inputProps={{ step: 0.01 }}
              onChange={this.handleChange('positionZ')}
            />
          </FormControl>
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <Button color="secondary" onClick={this.handleSubmit}>
            {id ? 'Update' : 'Create'}
          </Button>
          {id && (
            <IconButton color="secondary" onClick={() => handleDelete(id)}>
              <DeleteIcon />
            </IconButton>
          )}
        </CardActions>
      </Card>
    );
  }
}

export default SensorSettingsComponent;
