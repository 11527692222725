import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IFetchReportData, IUpdateAccountReport } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import Account, { ReportConfig } from '@models/Account';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import MetricMeta from '@models/MetricMeta';
import Warehouse from '@models/Warehouse';
import { getAccountMeta } from '@selectors/accounts';
import ReportCompositionForm from '../../../tabs/ReportsTab/ReportForm/ReportCompositionForm';

interface FloorplanReport {
  floorplanId: string;
  automaticReport: boolean;
  floorplanSensorPlacement: boolean;
  plots: Record<string, Record<string, Record<string, boolean>>>;
  reportPeriodicity: string;
}

interface IProps {
  account: Account;
  changed: boolean;
  intl: IntlShape;
  loading: boolean;
  metrics: MetricMeta[];
  floorplans: IncompleteFloorplan[];
  warehouses: Warehouse[];
  updateAccountReport: (properties: IUpdateAccountReport) => void;
}

interface IState {
  changed: boolean;
  reportConfigs: Record<string, FloorplanReport>;
}

const DEFAULT_PERIODICITY = 'weekly';

class Reports extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { account } = this.props;

    const accountMeta = getAccountMeta(account);

    this.state = {
      changed: false,
      reportConfigs: {},
    };

    const reportConfigs: Record<string, FloorplanReport> = {};
    (accountMeta.reportConfigs || []).forEach((config) => {
      reportConfigs[config.floorplanId] = {
        ...config,
        plots: this.generateFormPlots(config),
        reportPeriodicity: config.reportPeriodicity || DEFAULT_PERIODICITY,
      };
    });

    this.state = {
      changed: false,
      reportConfigs,
    };

    this.generateFormPlots = this.generateFormPlots.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleSectionChange = this.handleSectionChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { changed, metrics } = this.props;

    if (prevProps.changed !== changed) {
      this.setChange();
    }
    if (JSON.stringify(prevProps.metrics) !== JSON.stringify(metrics)) {
      this.updateForm();
    }
  }

  public handleChange(floorplanId: string, e: any) {
    const { name } = e.target;
    const value: string | boolean =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState((prevState) => {
      const floorplanReport: FloorplanReport = prevState.reportConfigs[
        floorplanId
      ] || {
        plots: {},
      };

      return {
        reportConfigs: {
          ...prevState.reportConfigs,
          [floorplanId]: {
            ...floorplanReport,
            floorplanId,
            [name]: value,
          },
        },
        changed: value !== floorplanReport[name as 'automaticReport'],
      } as Pick<IState, 'changed'>;
    });
  }

  public handleSelectionChange(
    floorplanId: string,
    metridId: string,
    type: string,
    id: string
  ) {
    return (e: any) => {
      const { reportConfigs } = this.state;

      const active =
        e.target.type === 'checkbox' ? e.target.checked : e.target.value;

      if (!reportConfigs[floorplanId]) {
        reportConfigs[floorplanId] = {
          floorplanId,
          automaticReport: true,
          floorplanSensorPlacement: true,
          reportPeriodicity: DEFAULT_PERIODICITY,
          plots: {},
        };
      }
      if (!reportConfigs[floorplanId].plots[metridId]) {
        reportConfigs[floorplanId].plots[metridId] = {};
      }
      if (!reportConfigs[floorplanId].plots[metridId][type]) {
        reportConfigs[floorplanId].plots[metridId][type] = {};
      }

      reportConfigs[floorplanId].plots[metridId][type][id] = active;

      // eslint-disable-next-line prettier/prettier
      this.setState({ reportConfigs, changed: true } as Pick<IState, 'reportConfigs'>);
    };
  }

  public handleSectionChange(
    floorplanId: string,
    e: any,
    analytics: { type: string; id: string }[]
  ) {
    const { reportConfigs } = this.state;
    const { name, checked } = e.target;

    if (!reportConfigs[floorplanId]) {
      reportConfigs[floorplanId] = {
        floorplanId,
        automaticReport: true,
        floorplanSensorPlacement: true,
        reportPeriodicity: DEFAULT_PERIODICITY,
        plots: {},
      };
    }
    if (!reportConfigs[floorplanId].plots[name]) {
      reportConfigs[floorplanId].plots[name] = {};
    }
    analytics.forEach(({ type, id }) => {
      if (!reportConfigs[floorplanId].plots[name][type]) {
        reportConfigs[floorplanId].plots[name][type] = {};
      }

      reportConfigs[floorplanId].plots[name][type][id] = checked;
    });

    // eslint-disable-next-line prettier/prettier
    this.setState({ reportConfigs, changed: true } as Pick<IState, 'reportConfigs'>);
  }

  public handleReset() {
    const { account } = this.props;

    const accountMeta = getAccountMeta(account);

    const reportConfigs: Record<string, FloorplanReport> = {};
    (accountMeta.reportConfigs || []).forEach((config) => {
      reportConfigs[config.floorplanId] = {
        ...config,
        plots: this.generateFormPlots(config, false),
        reportPeriodicity: config.reportPeriodicity || DEFAULT_PERIODICITY,
      };
    });

    // eslint-disable-next-line prettier/prettier
    this.setState({ reportConfigs, changed: false } as Pick<IState, 'reportConfigs'>);
  }

  public handleSubmit() {
    const { intl, metrics, updateAccountReport } = this.props;
    const { reportConfigs } = this.state;

    updateAccountReport({
      reportConfigs: Object.values(reportConfigs).map((r) => {
        const data: IFetchReportData = {};
        Object.keys(reportConfigs[r.floorplanId].plots).forEach((k) => {
          data[k] = {
            others: {
              // eslint-disable-next-line camelcase
              fixed_plots:
                (reportConfigs[r.floorplanId].plots[k].fixed_plots || {})
                  .default || false,
            },
            maps: Object.keys(reportConfigs[r.floorplanId].plots[k].maps || {})
              .filter((m) => !!reportConfigs[r.floorplanId].plots[k].maps[m])
              .map((m) => ({
                id: m.split('|')[0],
                type: m.split('|')[1],
              })),
            aggregations: Object.keys(
              reportConfigs[r.floorplanId].plots[k].aggregation_plots || {}
            )
              .filter(
                (m) =>
                  !!reportConfigs[r.floorplanId].plots[k].aggregation_plots[m]
              )
              .map((index) => {
                const metric = metrics.find((m) => m.id === k);

                return ((metric || {}).defaultAggregations || [])[
                  Number(index)
                ];
              }),
          };
        });

        return {
          floorplanId: r.floorplanId,
          automaticReport: r.automaticReport,
          floorplanSensorPlacement: r.floorplanSensorPlacement,
          reportPeriodicity: r.reportPeriodicity,
          data,
        };
      }),
      intl,
    });
  }

  public setChange() {
    const { changed } = this.props;

    this.setState({ changed });
  }

  public updateForm() {
    const { reportConfigs } = this.state;
    const { account } = this.props;

    const accountMeta = getAccountMeta(account);

    Object.keys(reportConfigs).forEach((k) => {
      reportConfigs[k] = {
        ...reportConfigs[k],
        plots: this.generateFormPlots(
          (accountMeta.reportConfigs || []).find((c) => c.floorplanId === k) ||
            ({} as ReportConfig)
        ),
      };
    });

    this.setState({ reportConfigs, changed: false });
  }

  public generateFormPlots(
    reportConfig: ReportConfig,
    // If useState = true, current form status is maintained
    useState = true
  ) {
    const { metrics } = this.props;
    const { reportConfigs } = this.state;

    const fId = reportConfig.floorplanId;

    const plots: Record<string, Record<string, Record<string, boolean>>> = {};
    Object.keys((reportConfig || {}).data || {}).forEach((k) => {
      const metric = metrics.find((m) => m.id === k);

      if (metric) {
        const existingR = useState
          ? ((reportConfigs[fId] || {}).plots || {})[metric.id] || {}
          : {};

        const maps: Record<string, boolean> = {};
        (reportConfig.data[k].maps || []).forEach((m) => {
          if (k === m.id && (metric.returns || []).indexOf(m.type) !== -1) {
            const existingValue = (existingR.maps || {})[`${m.id}|${m.type}`];

            maps[`${m.id}|${m.type}`] =
              existingValue !== undefined ? existingValue : true;
          }
        });
        const aggregationPlots: Record<string, boolean> = {};
        (reportConfig.data[k].aggregations || []).forEach((a, index) => {
          if (
            (metric.defaultAggregations || []).find(
              (b) =>
                b.level1 === a.level1 &&
                b.level1Option === a.level1Option &&
                b.level2 === a.level2 &&
                b.level2Option === a.level2Option &&
                b.level3 === a.level3 &&
                b.level3Option === a.level3Option
            )
          ) {
            const existingValue = (existingR.aggregationPlots || {})[
              String(index)
            ];

            aggregationPlots[String(index)] =
              existingValue !== undefined ? existingValue : true;
          }
        });

        const existingValueFP = (existingR.fixed_plots || {}).default;

        plots[k] = {
          // eslint-disable-next-line camelcase
          fixed_plots: {
            default:
              ((reportConfig.data[k].others || {}).fixed_plots ||
                existingValueFP) &&
              (metric.returns || []).indexOf('fixed_plots') !== -1,
          },
          maps,
          // eslint-disable-next-line camelcase
          aggregation_plots: aggregationPlots,
        };
      }
    });

    return plots;
  }

  public render() {
    const { account, floorplans, loading, metrics, warehouses } = this.props;
    const { reportConfigs, changed } = this.state;

    let error = false;
    Object.keys(reportConfigs || {}).forEach((f) => {
      if (
        reportConfigs[f].automaticReport !== false &&
        (reportConfigs[f].reportPeriodicity || '').length === 0
      ) {
        error = true;
      }
    });

    const buttonDisabled = error;

    return (
      <>
        <Card>
          <CardHeader
            avatar={<BarChartIcon />}
            title={account.name}
            subheader={
              <FormattedMessage
                id="dashboard.forms.accountreports.title"
                defaultMessage="Reports"
              />
            }
          />
        </Card>
        <Box mt={2} />
        {warehouses.map((w) => (
          <Box mt={2} key={w.id}>
            <Typography variant="h6">{w.name}</Typography>
            {floorplans
              .filter((f) => f.warehouseId === w.id)
              .map((f) => (
                <Accordion key={f.id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1">{f.name}</Typography>
                    <Typography
                      variant="caption"
                      style={{ marginLeft: '5px', marginTop: '5px' }}
                    >
                      [
                      {!(reportConfigs[f.id] || {}).automaticReport ? (
                        <FormattedMessage
                          id="dashboard.forms.accountreports.label_inactive"
                          defaultMessage="Delivery Inactive"
                        />
                      ) : (
                        <FormattedMessage
                          id="dashboard.forms.accountreports.label_active"
                          defaultMessage="Delivery Active"
                        />
                      )}
                      ]
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item sm={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                (reportConfigs[f.id] || {}).automaticReport ||
                                false
                              }
                              name="automaticReport"
                              onChange={(e: any) => this.handleChange(f.id, e)}
                              value
                            />
                          }
                          label={
                            <FormattedMessage
                              id="dashboard.forms.accountreports.label_sendreport"
                              defaultMessage="Send reports periodically by email"
                            />
                          }
                        />
                      </Grid>
                      {(reportConfigs[f.id] || {}).automaticReport === true ? (
                        <>
                          <Grid item sm={12}>
                            <FormControl
                              required
                              fullWidth
                              variant="standard"
                              margin="normal"
                            >
                              <Select
                                label={
                                  <FormattedMessage
                                    id="dashboard.forms.accountreports.label_periodicity"
                                    defaultMessage="Periodicity"
                                  />
                                }
                                value={
                                  (reportConfigs[f.id] || {})
                                    .reportPeriodicity || ''
                                }
                                name="reportPeriodicity"
                                onChange={(e: any) =>
                                  this.handleChange(f.id, e)
                                }
                                input={
                                  <Input
                                    name="periodicity"
                                    id="form-periodicity"
                                  />
                                }
                              >
                                <MenuItem value="daily">
                                  <FormattedMessage
                                    id="dashboard.forms.accountreports.option_daily"
                                    defaultMessage="Daily"
                                  />
                                </MenuItem>
                                <MenuItem value="weekly">
                                  <FormattedMessage
                                    id="dashboard.forms.accountreports.option_weekly"
                                    defaultMessage="Weekly"
                                  />
                                </MenuItem>
                                <MenuItem value="monthly">
                                  <FormattedMessage
                                    id="dashboard.forms.accountreports.option_monthly"
                                    defaultMessage="Monthly"
                                  />
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item sm={12}>
                            <div style={{ width: '100%' }}>
                              <ReportCompositionForm
                                filterId="reportGenerator"
                                metrics={metrics}
                                loading={loading}
                                floorplanSensorPlacement={
                                  (reportConfigs[f.id] || {})
                                    .floorplanSensorPlacement || false
                                }
                                plots={(reportConfigs[f.id] || {}).plots || {}}
                                handleChange={(e: any) =>
                                  this.handleChange(f.id, e)
                                }
                                handleSectionChange={(
                                  e: any,
                                  analytics: { type: string; id: string }[]
                                ) =>
                                  this.handleSectionChange(f.id, e, analytics)
                                }
                                handleSelectionChange={(
                                  metridId: string,
                                  type: string,
                                  id: string
                                ) =>
                                  this.handleSelectionChange(
                                    f.id,
                                    metridId,
                                    type,
                                    id
                                  )
                                }
                              />
                            </div>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        ))}
        <Box mt={2} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          {changed ? (
            <>
              <LoadingButton
                onClick={this.handleSubmit}
                variant="contained"
                color="secondary"
                disabled={buttonDisabled}
                loading={loading}
              >
                <FormattedMessage
                  id="dashboard.forms.accountreports.button_submit"
                  defaultMessage="Update"
                />
              </LoadingButton>
              <Box mr={2}>
                <Button
                  onClick={this.handleReset}
                  style={{ marginLeft: '10px' }}
                >
                  <FormattedMessage
                    id="dashboard.forms.accountreports.button_cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </Box>
            </>
          ) : null}
        </Box>
      </>
    );
  }
}

export default injectIntl(Reports);
