class ImageStore {
  private list: Record<string, HTMLImageElement>;

  constructor() {
    this.list = {};
  }

  public put(url: string, img: HTMLImageElement) {
    this.list[url] = img;
  }

  public get(url: string) {
    return this.list[url];
  }
}

export default ImageStore;
