import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import BuildIcon from '@mui/icons-material/Build';
import HouseIcon from '@mui/icons-material/House';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import TableChartIcon from '@mui/icons-material/TableChart';
import React, { Component, FunctionComponent } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { DiagramItem } from '@models/Diagram';

export const itemWidth = 260;
export const itemHeight = 45;

interface IProps {
  item: DiagramItem;
  updateItemPosition: (id: string, x: number, y: number) => void;
  updateItemConnections: (id: string, connections: string[]) => void;
  handleClick: (event: any, id: string) => void;
}

interface IState {
  dragging: boolean;
  startPositionX?: number;
  startPositionY?: number;
  diffPositionX: number;
  diffPositionY: number;
}

interface ItemProps {
  item: DiagramItem;
  handleClick: (event: any) => any;
  onMouseDown: (event: any) => any;
  onConnection: (originItem: DiagramItem) => void;
}
const Item: FunctionComponent<ItemProps> = ({
  handleClick,
  item,
  onMouseDown,
  onConnection,
}: ItemProps) => {
  const [{ isDragging }, drag] = useDrag({
    item,
    type: 'connection',
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'connection',
    drop: (i: DiagramItem) => {
      onConnection(i);
    },
  });

  let icon;
  switch (item.type) {
    case 'warehouse':
      icon = <HouseIcon fontSize="large" />;
      break;
    case 'floorplan':
      icon = <TableChartIcon fontSize="large" />;
      break;
    case 'zone':
      icon = <AspectRatioIcon fontSize="large" />;
      break;
    case 'asset':
      icon = <BuildIcon fontSize="large" />;
      break;
    case 'employee':
      icon = <PersonPinIcon fontSize="large" />;
      break;
    default:
      icon = <AddIcon fontSize="large" />;
      break;
  }

  return (
    <ListItem
      ref={drop}
      className="noselect"
      style={{
        cursor: 'pointer',
        width: `${itemWidth}px`,
        height: `${itemHeight}px`,
        color: item.color || '#666',
        border: item.color ? `1px solid ${item.color}` : '1px solid #E1E1E1',
        backgroundColor: item.color ? `${item.color}6F` : '#F9F9F9',
        position: 'absolute',
        top: (item.position || {}).y || 0,
        left: (item.position || {}).x || 0,
        borderRadius: '3px',
      }}
      onMouseDown={onMouseDown}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={item.data.name} secondary={item.description} />
      <ListItemIcon
        ref={drag}
        style={{ float: 'right', minWidth: '12px', paddingRight: '6px' }}
        onMouseDown={(event) => event.stopPropagation()}
      >
        <LabelImportantIcon
          fontSize="small"
          style={{ opacity: isDragging ? 0.7 : 1 }}
          onClick={handleClick}
        />
      </ListItemIcon>
    </ListItem>
  );
};

class DrawItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      dragging: false,
      diffPositionX: 0,
      diffPositionY: 0,
    };

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onConnection = this.onConnection.bind(this);
  }

  public componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove, { passive: true });
    document.addEventListener('mouseup', this.onMouseUp, { passive: true });
  }

  public componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  public onMouseDown(event: any) {
    this.setState({
      startPositionX: event.pageX,
      startPositionY: event.pageY,
      diffPositionX: 0,
      diffPositionY: 0,
      dragging: true,
    });
  }

  public onMouseMove(event: any) {
    const { item } = this.props;
    const { dragging, startPositionX, startPositionY } = this.state;
    let { diffPositionX, diffPositionY } = this.state;

    if (!dragging) {
      return;
    }

    event.stopPropagation();

    if (!item.position) {
      item.position = { x: 0, y: 0 };
    }
    diffPositionX = (startPositionX || 0) - event.pageX;
    diffPositionY = (startPositionY || 0) - event.pageY;

    this.setState({ diffPositionX, diffPositionY });
  }

  public onMouseUp() {
    const { updateItemPosition, item } = this.props;
    const { dragging, diffPositionX, diffPositionY } = this.state;

    if (!dragging) {
      return;
    }

    updateItemPosition(
      item.id,
      ((item.position || {}).x || 0) - diffPositionX,
      ((item.position || {}).y || 0) - diffPositionY
    );

    this.setState({
      dragging: false,
      diffPositionX: 0,
      diffPositionY: 0,
    });
  }

  public onConnection(newConnection: DiagramItem) {
    const { item, updateItemConnections } = this.props;

    if (newConnection.id === item.id) {
      return;
    }

    const connections = item.connections || [];
    const index = connections.indexOf(newConnection.id);
    if (index !== -1) {
      connections.splice(index, 1);
    } else {
      connections.push(newConnection.id);
    }

    updateItemConnections(item.id, connections);
  }

  public render() {
    const { item, handleClick } = this.props;
    const { diffPositionX, diffPositionY } = this.state;

    return (
      <Item
        item={{
          ...item,
          position: {
            x: ((item.position || {}).x || 0) - diffPositionX,
            y: ((item.position || {}).y || 0) - diffPositionY,
          },
        }}
        onMouseDown={this.onMouseDown}
        onConnection={this.onConnection}
        handleClick={(event: any) => handleClick(event, item.id)}
      />
    );
  }
}

export default DrawItem;
