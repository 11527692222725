import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { PhoneNumberUtil } from 'google-libphonenumber';
import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';

import { IUpdateAccountContacts } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import Account from '@models/Account';
import { getAccountMeta } from '@selectors/accounts';
import { validateEmail } from '../../../../utils/formUtils';

const messages = {
  mobile: {
    defaultMessage: 'Mobile',
    id: 'dashboard.account.settings.contacts.label_mobile',
  },
  whatsapp: {
    defaultMessage: 'WhatsApp',
    id: 'dashboard.account.settings.contacts.label_whatsapp',
  },
};

interface IProps {
  account: Account;
  changed: boolean;
  intl: IntlShape;
  loading: boolean;
  locale: string;
  updateAccountContacts: (properties: IUpdateAccountContacts) => void;
}

interface IState {
  changed: boolean;
  contactsEmail?: string;
  contactsMobile?: string;
  contactsWhatsapp?: string;
}

class Contacts extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { account, changed } = props;
    const { contactsEmail, contactsMobile, contactsWhatsapp } =
      getAccountMeta(account);

    this.state = {
      changed,
      contactsEmail,
      contactsMobile,
      contactsWhatsapp,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { changed } = this.props;

    if (prevProps.changed !== changed) {
      this.setChanges();
    }
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const value: string | boolean =
        e.target.type === 'checkbox' ? e.target.checked : e.target.value;

      this.setState(
        (prevState) =>
          ({
            [key]: value,
            changed: value !== prevState[key],
          } as Pick<IState, 'changed'>)
      );
    };
  }

  public handleNumberChange(key: keyof IState) {
    return (value: string) => {
      this.setState(
        (prevState) =>
          ({
            [key]: value,
            changed: value !== prevState[key],
          } as Pick<IState, 'changed'>)
      );
    };
  }

  public handleSubmit() {
    const { contactsEmail, contactsMobile, contactsWhatsapp } = this.state;
    const { intl, updateAccountContacts } = this.props;

    updateAccountContacts({
      intl,
      properties: { contactsEmail, contactsMobile, contactsWhatsapp },
    });
  }

  public handleReset() {
    const { account } = this.props;
    const { contactsEmail, contactsMobile, contactsWhatsapp } =
      getAccountMeta(account);

    this.setState({
      changed: false,
      contactsEmail,
      contactsMobile,
      contactsWhatsapp,
    });
  }

  public setChanges() {
    const { changed } = this.props;

    this.setState({ changed });
  }

  public validatePhoneNumberContainer(key: keyof IState) {
    return (value: string): boolean => {
      const stateValue = this.state[key];
      if (!stateValue) {
        return true;
      }

      return PhoneNumberUtil.getInstance().isValidNumber(
        PhoneNumberUtil.getInstance().parseAndKeepRawInput(`+${value}`)
      );
    };
  }

  public render() {
    const { account, intl, loading, locale } = this.props;
    const { changed, contactsEmail, contactsMobile, contactsWhatsapp } =
      this.state;

    const error =
      (contactsEmail && !validateEmail(contactsEmail)) ||
      (contactsMobile && !PhoneNumberUtil.getInstance().isValidNumber(
        PhoneNumberUtil.getInstance().parseAndKeepRawInput(`+${contactsMobile}`)
      )) ||
      (contactsWhatsapp && !PhoneNumberUtil.getInstance().isValidNumber(
        PhoneNumberUtil.getInstance().parseAndKeepRawInput(`+${contactsWhatsapp}`)
      ));

    return (
      <Card style={{ overflow: 'visible' }}>
        <CardHeader
          avatar={<AccountCircleIcon />}
          title={account.name}
          subheader={
            <FormattedMessage
              id="dashboard.account.settings.menu.account_contacts"
              defaultMessage="My Contacts"
            />
          }
        />
        <CardContent>
          <FormControl required={false} fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.account.settings.contacts.label_email"
                  defaultMessage="Email"
                />
              }
              variant="standard"
              value={contactsEmail || ''}
              type="email"
              error={(contactsEmail && !validateEmail(contactsEmail)) || false}
              onChange={this.handleChange('contactsEmail')}
            />
          </FormControl>
          <FormControl
            required={false}
            fullWidth
            margin="normal"
            style={{ marginTop: '30px', zIndex: 4 }}
          >
            <InputLabel shrink style={{ marginTop: '-20px' }}>
              {intl.formatMessage(messages.mobile)}
            </InputLabel>
            <PhoneInput
              id="form-mobile"
              isValid={this.validatePhoneNumberContainer('contactsMobile')}
              country={((locale || '').split('-')[1] || 'GB').toLowerCase()}
              value={contactsMobile || ''}
              onChange={this.handleNumberChange('contactsMobile')}
            />
          </FormControl>
          <FormControl
            required={false}
            fullWidth
            margin="normal"
            style={{ marginTop: '30px', zIndex: 3 }}
          >
            <InputLabel shrink style={{ marginTop: '-20px' }}>
              {intl.formatMessage(messages.whatsapp)}
            </InputLabel>
            <PhoneInput
              id="form-whatsapp"
              isValid={this.validatePhoneNumberContainer('contactsWhatsapp')}
              disabled
              country={((locale || '').split('-')[1] || 'GB').toLowerCase()}
              value={contactsWhatsapp || ''}
              onChange={this.handleNumberChange('contactsWhatsapp')}
            />
          </FormControl>
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          {changed ? (
            <>
              <Box mr={2}>
                <Button onClick={this.handleReset}>
                  <FormattedMessage
                    id="dashboard.account.settings.contacts.button_cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </Box>
              <LoadingButton
                onClick={this.handleSubmit}
                variant="contained"
                color="secondary"
                disabled={error || loading}
                loading={loading}
              >
                <FormattedMessage
                  id="dashboard.account.settings.contacts.button_submit"
                  defaultMessage="Update"
                />
              </LoadingButton>
            </>
          ) : null}
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(Contacts);
