import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchItemsWithLocations,
  IFetchIntemsWithLocations,
  openImportItemsForm,
  openImportStocksForm,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import { getItemList, isItemListLoading } from '@selectors/items';

import ItemList from './ItemList';

const mapStateToProps = (state: IAppState) => ({
  items: getItemList(state.items),
  language: getActiveLanguage(state),
  loading: isItemListLoading(state.items),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchItemsWithLocations: (properties: IFetchIntemsWithLocations) =>
    dispatch(fetchItemsWithLocations.request(properties)),
  openImportItemsForm: () => dispatch(openImportItemsForm()),
  openImportStocksForm: () => dispatch(openImportStocksForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
