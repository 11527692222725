import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactourStep } from 'reactour';

import Disabler from './Disabler';

export default (
  disableTour: () => void,
  disabledTour: boolean
): ReactourStep[] => [
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.welcome.message"
            defaultMessage="Here you can keep track of what happened at a given time in your warehouse. It's like watching a movie!"
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'body',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.activity"
            defaultMessage="Part of our events player is an event and activity detail of assets in the selected period. Hover it with the mouse for more information."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '.events-slider-container',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.controls"
            defaultMessage="Here you find the controls for the player."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#controls',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.controls.playertimeline"
            defaultMessage="Player timeline, click to change player time."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '.event-timeslider',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.controls.slowdown"
            defaultMessage="Allows you to decrease player speed."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#slow-down',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.controls.previous"
            defaultMessage="Get back 1 minute."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#previous',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.controls.play"
            defaultMessage="Play/pause player."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#play',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.controls.next"
            defaultMessage="Move forward 1 minute."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#next',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.controls.speedup"
            defaultMessage="Allows you to increase player speed."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#speed-up',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.controls.backwards"
            defaultMessage="Reverse player direction, you can play backward or forward from a given moment."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#backwards',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.controls.playerts"
            defaultMessage="Player time."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#player-ts',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.controls.activitygraph"
            defaultMessage="Activity graph. Each line represents an asset, this way you can easily see if the asset was active or inactive at that point in time. You will also find here alerts. Click anywhere to make the player jump to that time."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '.event-slider',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.player.controls.sensorsgo"
            defaultMessage="Let's check out your physical installation."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'a#sensors',
  },
];
