import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactourStep } from 'reactour';

import Disabler from './Disabler';

export default (
  disableTour: () => void,
  disabledTour: boolean
): ReactourStep[] => [
  {
    content: () => (
      <>
        <Typography variant="h4">
          <FormattedMessage
            id="tutorial.liveview.welcome.title"
            defaultMessage="Welcome to Logidot Live View!"
          />
        </Typography>
        <br />
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.liveview.welcome.message"
            defaultMessage={
              "This is our live view, where you can track everything that it's happening at this moment in your warehouse."
            }
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'body',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.liveview.filters.message"
            defaultMessage="In the filters you can switch between your warehouses and/or floor plans. You can also filter map information for specific assets."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '.filters',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.liveview.map.message"
            defaultMessage="This is our map. You can see our physical setup represented with red dots and your assets moving."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '.map',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.liveview.map.options"
            defaultMessage="Here you can zoom in and out. SHIFT + Mouse Wheel will also do the trick."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '.map-control',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.liveview.map.mapfit"
            defaultMessage="Here you can fit the map to the available space. And reset, if you like."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#map-fitview',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.liveview.map.layers"
            defaultMessage="Here you find options to interact with the map, you can enable or disable view layers, like sensors and zones, and hide or show boundaries and obstacles."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#map-layers',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.liveview.map.maphelp"
            defaultMessage="You will find tips on how to interact with the map here too."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#map-help',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.liveview.analyticsgo"
            defaultMessage="Let's go to Analytics!"
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'a#analytics',
  },
];
