import { listTimeZones } from 'timezone-support/dist/index-2012-2022';
import React, { Component } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';

interface IProps {
  timezone: string;
  onChange: (value: ValueType<IOption>) => any;
}

interface IState {
  timezones: IOption[];
}
interface IOption {
  label: string;
  value: string;
}

class TimezonesSelect extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      timezones: listTimeZones().map((t) => ({
        label: t,
        value: t,
      })),
    };
  }

  public shouldComponentUpdate(nextProps: any) {
    const { timezone } = this.props;

    return timezone !== nextProps.timezone;
  }

  public render() {
    const { onChange, timezone } = this.props;
    const { timezones } = this.state;

    const value = { label: timezone, value: timezone };

    return (
      <Select
        className="menu-outer-top"
        id="select-timezone"
        options={timezones}
        value={value}
        onChange={onChange}
        placeholder="Select a timezone"
        menuPortalTarget={document.querySelector('body')}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    );
  }
}

export default TimezonesSelect;
