import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IUpdateFloorplan } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import DefineScaleForm from './DefineScaleForm';

export interface IProps {
  floorplan: IncompleteFloorplan;
  intl: IntlShape;
  loading: boolean;
  measurementUnits: string;
  updateFloorplan: (properties: IUpdateFloorplan) => any;
}

export interface IState {
  error: boolean;
  hasChanges: boolean;
  value: string;
  pixels: number;
}

class DefineScale extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      error: false,
      hasChanges: false,
      pixels: 1,
      value: '',
    };

    this.onClear = this.onClear.bind(this);
    this.onMeasuredDistanceChange = this.onMeasuredDistanceChange.bind(this);
    this.onFeatureChange = this.onFeatureChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleSubmit() {
    const { pixels, value } = this.state;
    const { floorplan, intl, measurementUnits, updateFloorplan } = this.props;
    // Convert feet to meters if measurement units defined are imperial
    const meters =
      Number.parseFloat(value) * (measurementUnits === 'si' ? 1 : 0.3048);

    const scale = pixels / meters;

    this.setState({ hasChanges: false }, () =>
      updateFloorplan({
        floorplanId: floorplan.id,
        intl,
        properties: { scale },
        warehouseId: floorplan.warehouseId,
      })
    );
  }

  public onClear() {
    this.setState({ value: '' });
  }

  public onMeasuredDistanceChange(event: any) {
    const { value } = event.target;

    this.setState({
      error: !value.match(/^\d*(\.\d+)?$/) && value !== '',
      hasChanges: true,
      value,
    });
  }

  public onFeatureChange(pixels: number) {
    this.setState({ pixels });
  }

  public render() {
    const { floorplan, loading, measurementUnits, updateFloorplan } =
      this.props;
    const { error, hasChanges, value } = this.state;

    return (
      <>
        <Card className="step-card">
          <CardContent style={{ flex: 1, position: 'relative' }}>
            <DefineScaleForm
              error={error}
              floorplan={floorplan}
              measurementUnits={measurementUnits}
              onMeasuredDistanceChange={this.onMeasuredDistanceChange}
              onFeatureChange={this.onFeatureChange}
              updateFloorplan={updateFloorplan}
              value={value}
            />
          </CardContent>
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <LoadingButton
              loading={loading}
              disabled={!hasChanges || error || value === ''}
              onClick={this.handleSubmit}
              color="secondary"
              variant="contained"
            >
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.button_save"
                defaultMessage="Save Changes"
              />
            </LoadingButton>
          </CardActions>
        </Card>
      </>
    );
  }
}

export default injectIntl(DefineScale);
