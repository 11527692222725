import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import TaskMap from './TaskMap';

interface IProps {
  id: string;
  filterId?: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId, id } = props;

  const map = getMapById(state, id);
  const filter = state.filter.filters[filterId || ''] || {};

  return {
    mapImages: map.mapImages,
    paper: map.paper,
    floorplan: getFloorplanById(state, map.floorplanId)!,
    data: {
      tasks: Object.values(state.tasks.tasks).filter((d) =>
        d.warehouseId === filter.warehouseId &&
        d.floorplanId === filter.floorplanId &&
        (
          !(filter.employeeIds || []).length ||
          !!d.employees.find((eId) => filter.employeeIds?.find((id) => id === eId))
        )
      ),
      loading: state.tasks.loading || state.tasks.form.loading,
    },
  };
};

export default connect(mapStateToProps)(TaskMap);
