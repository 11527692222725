import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import React, { Component } from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';

import { FetchTickets } from '@actions/tickets';
import Ticket from '@models/Ticket';
import Landing from './Landing/Landing';
import TicketForm from './TicketForm';
import TicketC from './Ticket';

interface IProps {
  fetchTickets: (properties: FetchTickets) => void;
  language: string;
  loading: boolean;
  tickets: Ticket[];
}
interface IState {
  ticketStatus: 'open' | 'archived';
}

class Tickets extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      ticketStatus: 'open',
    };

    this.handleSelection = this.handleSelection.bind(this);
  }

  public componentDidMount() {
    const { fetchTickets } = this.props;
    const { ticketStatus } = this.state;

    fetchTickets({ ticketStatus });
  }

  public handleSelection(event: any) {
    const { fetchTickets } = this.props;

    this.setState({ ticketStatus: event.target.value }, () => {
      fetchTickets({ ticketStatus: event.target.value });
    });
  }

  public render() {
    const { language, loading, tickets } = this.props;
    const { ticketStatus } = this.state;

    return (
      <Box className="tab-content-inner" p={2}>
        <Grid container spacing={2} flex={1} style={{ height: '100%' }}>
          <Grid item sm={9} style={{ overflowY: 'scroll', height: '100%' }}>
            <Routes>
              <Route
                path="/:language/dashboard/tickets"
                element={<Landing />}
              />
              <Route
                path="/:language/dashboard/tickets/new"
                element={<TicketForm />}
              />
              <Route
                path="/:language/dashboard/tickets/id/:id"
                element={<TicketC />}
              />
              <Route
                path="/:language/dashboard/tickets/id/:id/edit"
                element={<TicketForm />}
              />
              <Route
                path="*"
                element={<Navigate to={`/${language}/dashboard/tickets`} replace />}
              />
            </Routes>
          </Grid>
          <Grid item sm={3} style={{ height: '100%' }}>
            <FormControl fullWidth margin="none">
              <Select
                value={ticketStatus || ''}
                required
                onChange={this.handleSelection}
              >
                <MenuItem value="open">Open Tickets</MenuItem>
                <MenuItem value="archived">Archived</MenuItem>
              </Select>
            </FormControl>
            {loading ? <LinearProgress /> : null}
            <Box
              style={{
                overflowY: 'scroll',
                height: 'calc(100% - 56px)',
              }}
            >
              <List
                component="nav"
                subheader={
                  <ListSubheader component="div">My tickets</ListSubheader>
                }
              >
                {tickets.map((t) => (
                  <ListItem
                    button
                    key={t.id}
                    component={Link}
                    to={`/${language}/dashboard/tickets/id/${t.id}`}
                  >
                    <ListItemAvatar>
                      <Avatar>T</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t.subject}
                      secondary={
                        <>
                          <Typography variant="body2" component="span">
                            {t.description}
                          </Typography>
                          <br />
                          <Typography variant="caption" component="span">
                            {format(new Date(t.created), 'yyyy-MM-dd HH:mm')}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default Tickets;
