import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip, { ChipProps } from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectProps } from '@mui/material/Select/Select';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BuildIcon from '@mui/icons-material/Build';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import React, { Component, Fragment, FunctionComponent, ReactNode, RefAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import uuid from 'uuid/v4';

import { IFetchTemplates } from '@actions/index';
import Account from '@models/Account';
import Asset from '@models/Asset';
import Zone from '@models/Zone';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import { IFilterEntry, IFilterChange } from '@reducers/filter';
import { RuleAlerts } from '@actions/rules';
import Rule from '@models/Rule';
import { RuleTemplates } from '@models/RuleTemplate';
import { WarehousesWithCompleteFloorplans } from '@models/WarehouseWithCompleteFloorplans';
import { getAccountMeta, hasPermission } from '@selectors/accounts';
import { ITag } from '@selectors/assets';
import { getFirstFloorplanByWarehouse } from '@selectors/warehouses';
import { getWarehouseIdByFloorplanId } from '../../utils/floorplanUtils';
import { Employees } from '@models/Employee';
import { Teams } from '@models/Team';

interface IMenuItemProps {
  disabled?: boolean;
  value: string;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
});

const StyledChip = styled(Chip)(() => ({
  height: '20px',
}));

export const MenuChip: FunctionComponent<Omit<ChipProps, "ref"> & RefAttributes<HTMLDivElement>> = React.forwardRef(
  (properties: ChipProps, ref: any) => <StyledChip ref={ref} {...properties} />
);

const DMenuItem: FunctionComponent<IMenuItemProps> = React.forwardRef(
  (properties: IMenuItemProps, ref: any) => {
    const { children, disabled } = properties;

    const StyledMenuItem = styled(MenuItem)(() => ({
      fontWeight: disabled ? 'bold' : 'normal',
    }));

    return (
      <StyledMenuItem ref={ref} {...properties}>
        {children}
      </StyledMenuItem>
    );
  }
);

export const TransparentMenuItem: FunctionComponent<Omit<IMenuItemProps, "ref"> & RefAttributes<HTMLDivElement>> = React.forwardRef(
  (properties: IMenuItemProps, ref: any) => {
    const { children, disabled } = properties;

    const StyledMenuItem = styled(MenuItem)(() => ({
      backgroundColor: 'transparent !important',
      height: disabled ? '0px' : 'auto',
      opacity: disabled ? '0 !important' : 1,
      justifyContent: 'center',
      overflow: 'hidden',
      transition: 'none !important',
    }));

    return (
      <StyledMenuItem ref={ref} {...properties}>
        {children}
      </StyledMenuItem>
    );
  }
);

const StyledSelect = styled(Select)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

export const TransparentSelect: FunctionComponent<Omit<SelectProps, "ref"> & RefAttributes<HTMLDivElement>> = React.forwardRef(
  (properties, ref: any) => {
    const { children } = properties;

    const classes = useStyles();

    return (
      <StyledSelect
        ref={ref}
        MenuProps={{
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          classes: { paper: classes.root },
        }}
        disableUnderline
        multiple
        className={classes.root}
        {...properties}
      >
        {children}
      </StyledSelect>
    );
  }
);

interface IAction {
  id: string;
  action: () => void;
}

interface IProps {
  activeFilters?: string[];
  account: Account;
  actions?: IAction[];
  addFilter: (properties: IFilterEntry) => void;
  assets: Asset[];
  assetsTags: ITag[];
  deleteFilter: (properties: { id: string }) => void;
  employees: Employees;
  teams: Teams;
  fetchTemplates: (properties: IFetchTemplates) => void;
  fetchRules: () => void;
  floorplans: IncompleteFloorplans;
  filterId: string;
  filter: IFilterEntry;
  filterKeys: string[];
  locale: string;
  otherFilters?: ReactNode;
  router: any;
  onChange: (properties: IFilterChange) => void;
  measurementUnits: string;
  templates?: RuleTemplates;
  ruleAlerts: RuleAlerts;
  rules: Record<string, Rule>;
  warehouses: WarehousesWithCompleteFloorplans;
  zones: Zone[];
  children?: React.ReactNode;
}

interface IState {
  showHidden: boolean;
}

class Filters extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { account } = this.props;

    this.state = {
      showHidden:
        hasPermission(account, 'settings') &&
        getAccountMeta(account).showHiddenFloorplans,
    };

    this.duplicateMap = this.duplicateMap.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.renderActiveEvents = this.renderActiveEvents.bind(this);
    this.renderActiveAssets = this.renderActiveAssets.bind(this);
    this.renderActiveTags = this.renderActiveTags.bind(this);
    this.renderActiveZones = this.renderActiveZones.bind(this);
    this.onSelectWarehouse = this.onSelectWarehouse.bind(this);
    this.onSelectFloorplan = this.onSelectFloorplan.bind(this);
    this.renderActiveRules = this.renderActiveRules.bind(this);
    this.renderActiveRuleTemplates = this.renderActiveRuleTemplates.bind(this);
    this.renderActiveEmployees = this.renderActiveEmployees.bind(this);
    this.renderActiveTeams = this.renderActiveTeams.bind(this);
    this.onMutiSelect = this.onMutiSelect.bind(this);
  }

  public componentDidMount() {
    const {
      activeFilters,
      filter,
      filterId,
      floorplans,
      router,
      onChange,
      warehouses,
    } = this.props;
    const { showHidden } = this.state;

    if (!filter.warehouseId || !warehouses[filter.warehouseId]) {
      const defaultId = Object.keys(warehouses)[0];

      if (defaultId) {
        const floorplan = getFirstFloorplanByWarehouse(
          floorplans,
          defaultId,
          showHidden
        );

        onChange({
          id: filterId,
          warehouseId: defaultId,
          floorplanId: floorplan !== undefined ? floorplan.id : undefined,
        });
      }
    } else if (!filter.floorplanId || !floorplans[filter.floorplanId]) {
      const floorplan = getFirstFloorplanByWarehouse(
        floorplans,
        filter.warehouseId,
        showHidden
      );

      if (floorplan !== undefined) {
        onChange({
          id: filterId,
          warehouseId: filter.warehouseId,
          floorplanId: floorplan.id,
        });
      }
    }

    if (router.query.floorplan !== undefined) {
      const whId = getWarehouseIdByFloorplanId(floorplans, router.query.floorplan);

      if (whId !== undefined) {
        onChange({
          id: filterId,
          warehouseId: whId,
          floorplanId: router.query.floorplan,
        });
      }
    }

    if (
      ((activeFilters || []).indexOf('assets') === -1 &&
        (filter.assetIds || []).length) ||
      ((activeFilters || []).indexOf('assetTags') === -1 &&
        (filter.tags || []).length) ||
      ((activeFilters || []).indexOf('rules') === -1 &&
        (filter.ruleIds || []).length) ||
      ((activeFilters || []).indexOf('ruleTemplates') === -1 &&
        (filter.templateIds || []).length) ||
      ((activeFilters || []).indexOf('eventTypes') === -1 &&
        (filter.eventIds || []).length) ||
      ((activeFilters || []).indexOf('employees') === -1 &&
        (filter.employeeIds || []).length) ||
      ((activeFilters || []).indexOf('teams') === -1 &&
        (filter.teamIds || []).length)
    ) {
      onChange({
        id: filterId,
        assetIds:
          (activeFilters || []).indexOf('assets') === -1 ? [] : filter.assetIds,
        tags:
          (activeFilters || []).indexOf('assetTags') === -1 ? [] : filter.tags,
        ruleIds:
          (activeFilters || []).indexOf('rules') === -1 ? [] : filter.ruleIds,
        templateIds:
          (activeFilters || []).indexOf('ruleTemplates') === -1
            ? []
            : filter.templateIds,
        eventIds:
          (activeFilters || []).indexOf('eventTypes') === -1
            ? []
            : filter.eventIds,
        employeeIds:
          (activeFilters || []).indexOf('employees') === -1
            ? []
            : filter.employeeIds,
        teamIds:
          (activeFilters || []).indexOf('teams') === -1
            ? []
            : filter.teamIds,
      });
    }

    this.fetchData();
  }

  public componentDidUpdate(prevProps: any) {
    const { activeFilters, filter, filterId, floorplans, router, onChange } =
      this.props;

    if (router.query.floorplan !== prevProps.router.query.floorplan) {
      const whId = getWarehouseIdByFloorplanId(floorplans, router.query.floorplan);

      if (whId !== undefined) {
        onChange({
          id: filterId,
          warehouseId: whId,
          floorplanId: router.query.floorplan,
        });
      }
    }

    if (
      ((activeFilters || []).indexOf('assets') === -1 &&
        (filter.assetIds || []).length) ||
      ((activeFilters || []).indexOf('assetTags') === -1 &&
        (filter.tags || []).length) ||
      ((activeFilters || []).indexOf('rules') === -1 &&
        (filter.ruleIds || []).length) ||
      ((activeFilters || []).indexOf('ruleTemplates') === -1 &&
        (filter.templateIds || []).length) ||
      ((activeFilters || []).indexOf('eventTypes') === -1 &&
        (filter.eventIds || []).length) ||
      ((activeFilters || []).indexOf('employees') === -1 &&
        (filter.employeeIds || []).length) ||
      ((activeFilters || []).indexOf('teams') === -1 &&
        (filter.teamIds || []).length)
    ) {
      onChange({
        id: filterId,
        assetIds:
          (activeFilters || []).indexOf('assets') === -1 ? [] : filter.assetIds,
        tags:
          (activeFilters || []).indexOf('assetTags') === -1 ? [] : filter.tags,
        ruleIds:
          (activeFilters || []).indexOf('rules') === -1 ? [] : filter.ruleIds,
        templateIds:
          (activeFilters || []).indexOf('ruleTemplates') === -1
            ? []
            : filter.templateIds,
        eventIds:
          (activeFilters || []).indexOf('eventTypes') === -1
            ? []
            : filter.eventIds,
        employeeIds:
          (activeFilters || []).indexOf('employees') === -1
            ? []
            : filter.employeeIds,
        teamIds:
          (activeFilters || []).indexOf('teams') === -1
            ? []
            : filter.teamIds,
      });
    }

    if (
      JSON.stringify(prevProps.activeFilters) !==
        JSON.stringify(activeFilters) &&
      ((activeFilters || []).indexOf('eventTypes') !== -1 ||
        (activeFilters || []).indexOf('ruleTemplates') !== -1 ||
        (activeFilters || []).indexOf('rules') !== -1)
    ) {
      this.fetchData();
    }
  }

  public onSelectWarehouse(event: any) {
    const { filterId, floorplans, onChange } = this.props;
    const { showHidden } = this.state;

    let floorplan;
    if (event.target.value !== '') {
      floorplan = getFirstFloorplanByWarehouse(
        floorplans,
        event.target.value,
        showHidden
      );
    }

    onChange({
      id: filterId,
      warehouseId: event.target.value,
      floorplanId: floorplan !== undefined ? floorplan.id : undefined,
    });
  }

  public onSelectFloorplan(event: any) {
    const { filterId, onChange } = this.props;

    onChange({
      id: filterId,
      floorplanId: event.target.value,
      assetIds: [],
      tags: [],
      zoneIds: [],
    });
  }

  public onMutiSelect(name: keyof IFilterChange, event: any) {
    const { filterId, onChange } = this.props;

    const changeObj = {
      id: filterId,
      [name]: event.target.value.filter((v: string) => !!v),
    };

    if (name === 'tags') {
      changeObj.assetIds = [];
    }
    if (name === 'templateIds') {
      changeObj.ruleIds = [];
    }

    onChange(changeObj);
  }

  public fetchData() {
    const {
      activeFilters,
      fetchRules,
      fetchTemplates,
      locale,
      measurementUnits,
    } = this.props;

    if (
      (activeFilters || []).indexOf('eventTypes') !== -1 ||
      (activeFilters || []).indexOf('ruleTemplates') !== -1
    ) {
      fetchTemplates({ locale, units: measurementUnits });
    }
    if ((activeFilters || []).indexOf('rules') !== -1) {
      fetchRules();
    }
  }

  public duplicateMap() {
    const { addFilter, filter } = this.props;

    const id = uuid();

    addFilter({ ...filter, id });
  }

  public deleteFilter() {
    const { deleteFilter, filterId } = this.props;

    deleteFilter({ id: filterId });
  }

  public renderActiveTags(selected: any) {
    const { assetsTags } = this.props;

    return selected.map((label: string, index: number) => {
      if (label === '') {
        return (
          <i key="0" style={{ color: '#c1c1c1' }}>
            <FormattedMessage
              id="dashboard.filter.label_assettag"
              defaultMessage="Asset Tag"
            />
          </i>
        );
      }

      const tag =
        assetsTags.find((t: ITag) => t.label === label) || ({} as ITag);

      return (
        <Fragment key={tag.label}>
          <span style={{ color: tag.color || '#ffffff' }}>
            <b>{tag.label}</b>
            <span> </span>
            <small>
              <span>(</span>
              {tag.numberOf}
              <span>)</span>
            </small>
          </span>
          {index !== selected.length - 1 ? <span>, </span> : null}
        </Fragment>
      );
    });
  }

  public renderActiveZones(selected: any) {
    const { zones } = this.props;

    return selected.map((id: string, index: number) => {
      if (id === '') {
        return (
          <i key="0" style={{ color: '#c1c1c1' }}>
            <FormattedMessage
              id="dashboard.filter.label_zone"
              defaultMessage="Zone"
            />
          </i>
        );
      }

      const zone = zones.find((z) => z.id === id) || ({} as Zone);

      return (
        <Fragment key={id}>
          <span style={{ color: zone.color || '#ffffff' }}>{zone.name}</span>
          {index !== selected.length - 1 ? <span>, </span> : null}
        </Fragment>
      );
    });
  }

  public renderActiveAssets(selected: any) {
    const { assets } = this.props;

    return (
      <div>
        {selected.map((id: any, index: number) => {
          if (id === '') {
            return (
              <i key={id} style={{ color: '#c1c1c1' }}>
                <FormattedMessage
                  id="dashboard.filter.label_asset"
                  defaultMessage="Asset"
                />
              </i>
            );
          }

          const asset = assets.find((a: Asset) => a.id === id) || ({} as Asset);

          return (
            <Fragment key={id}>
              <span style={{ color: asset.color || '#ffffff' }}>
                {asset.name}
              </span>
              {index !== selected.length - 1 ? <span>, </span> : null}
            </Fragment>
          );
        })}
      </div>
    );
  }

  public renderActiveRules(selected: any) {
    const { rules } = this.props;

    return (
      <div>
        {selected.map((id: any, index: number) => {
          const rule = (rules || {})[id] || {};

          if (id === '') {
            return (
              <i key={id} style={{ color: '#c1c1c1' }}>
                <FormattedMessage
                  id="dashboard.filter.label_rules"
                  defaultMessage="Rules"
                />
              </i>
            );
          }

          return (
            <Fragment key={id}>
              <span>{rule.name}</span>
              {index !== selected.length - 1 ? <span>, </span> : null}
            </Fragment>
          );
        })}
      </div>
    );
  }

  public renderActiveRuleTemplates(selected: any) {
    const { templates } = this.props;

    return (
      <div>
        {selected.map((id: any, index: number) => {
          const template = (templates || {})[id] || {};

          if (id === '') {
            return (
              <i key={id} style={{ color: '#c1c1c1' }}>
                <FormattedMessage
                  id="dashboard.filter.label_ruletemplates"
                  defaultMessage="Rule Templates"
                />
              </i>
            );
          }

          return (
            <Fragment key={id}>
              <span>{template.name}</span>
              {index !== selected.length - 1 ? <span>, </span> : null}
            </Fragment>
          );
        })}
      </div>
    );
  }

  public renderActiveEvents(selected: any) {
    const { templates } = this.props;

    return (
      <div>
        {selected.map((id: any, index: number) => {
          if (id === '') {
            return (
              <i key={id} style={{ color: '#c1c1c1' }}>
                <FormattedMessage
                  id="dashboard.filter.label_eventtype"
                  defaultMessage="Event Type"
                />
              </i>
            );
          }
          const template = (templates || {})[id] || {};

          return (
            <div
              key={id}
              style={{
                float: 'left',
                margin: '0px 5px',
              }}
            >
              <div style={{ float: 'left' }}>{template.name}</div>
              {index !== selected.length - 1 ? (
                <div style={{ float: 'left' }}>, </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  }

  public renderActiveEmployees(selected: any) {
    const { employees } = this.props;

    return (
      <div>
        {selected.map((id: any, index: number) => {
          const employee = (employees || {})[id] || {};

          if (id === '') {
            return (
              <i key={id} style={{ color: '#c1c1c1' }}>
                <FormattedMessage
                  id="dashboard.filter.label_employees"
                  defaultMessage="Employees"
                />
              </i>
            );
          }

          return (
            <Fragment key={id}>
              <span>{employee.name}</span>
              {index !== selected.length - 1 ? <span>, </span> : null}
            </Fragment>
          );
        })}
      </div>
    );
  }

  public renderActiveTeams(selected: any) {
    const { teams } = this.props;

    return (
      <div>
        {selected.map((id: any, index: number) => {
          const team = (teams || {})[id] || {};

          if (id === '') {
            return (
              <i key={id} style={{ color: '#c1c1c1' }}>
                <FormattedMessage
                  id="dashboard.filter.label_teams"
                  defaultMessage="Teams"
                />
              </i>
            );
          }

          return (
            <Fragment key={id}>
              <span>{team.name}</span>
              {index !== selected.length - 1 ? <span>, </span> : null}
            </Fragment>
          );
        })}
      </div>
    );
  }

  public render() {
    const {
      activeFilters,
      assets,
      assetsTags,
      children,
      employees,
      teams,
      floorplans,
      filter,
      filterId,
      filterKeys,
      otherFilters,
      warehouses,
      ruleAlerts,
      rules,
      templates,
      zones,
    } = this.props;
    const { showHidden } = this.state;

    let assetsData;
    if (
      (activeFilters || []).indexOf('assets') !== -1 ||
      (activeFilters || []).indexOf('assetTags') !== -1
    ) {
      assetsData = (
        <Box mb={1} ml={1}>
          <Card>
            <Grid container wrap="nowrap">
              <Grid item>
                <Grid container>
                  <BuildIcon
                    style={{
                      margin: '2px 8px',
                      color: '#e1e1e1',
                      marginRight: '8px',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  {(activeFilters || []).indexOf('assetTags') !== -1 ? (
                    <>
                      <FormControl variant="standard">
                        <TransparentSelect
                          onChange={(e) => this.onMutiSelect('tags', e)}
                          renderValue={this.renderActiveTags}
                          value={
                            (filter.tags || []).length === 0
                              ? ['']
                              : filter.tags || []
                          }
                        >
                          <TransparentMenuItem value="" disabled>
                            <FormattedMessage
                              id="dashboard.filter.label_assettag"
                              defaultMessage="Asset Tag"
                            />
                          </TransparentMenuItem>
                          {assetsTags.map((tag) => {
                            const lbl = (
                              <>
                                <b>{tag.label}</b>
                                <span> </span>
                                <small>
                                  <span>(</span>
                                  {tag.numberOf}
                                  <span>)</span>
                                </small>
                              </>
                            );

                            const active =
                              (filter.tags || []).findIndex(
                                (t) => t === tag.label
                              ) !== -1;

                            return (
                              <TransparentMenuItem
                                key={tag.label}
                                value={tag.label}
                              >
                                <MenuChip
                                  style={{
                                    backgroundColor: active
                                      ? tag.color
                                      : '#fff',
                                    color: active ? '#fff' : tag.color,
                                    cursor: 'pointer',
                                    border: `1px solid ${tag.color || '#CCC'}`,
                                  }}
                                  label={lbl}
                                />
                              </TransparentMenuItem>
                            );
                          })}
                        </TransparentSelect>
                      </FormControl>
                    </>
                  ) : null}
                  {(activeFilters || []).indexOf('assets') !== -1 ? (
                    <>
                      {(activeFilters || []).indexOf('assetTags') !== -1 && (
                        <Typography className="greater-than" variant="body1">
                          {'>'}
                        </Typography>
                      )}
                      <FormControl variant="standard">
                        <TransparentSelect
                          onChange={(e) => this.onMutiSelect('assetIds', e)}
                          renderValue={this.renderActiveAssets}
                          value={
                            (filter.assetIds || []).length === 0
                              ? ['']
                              : filter.assetIds || []
                          }
                        >
                          <TransparentMenuItem value="" disabled>
                            <FormattedMessage
                              id="dashboard.filter.label_asset"
                              defaultMessage="Asset"
                            />
                          </TransparentMenuItem>
                          {assets
                            .filter(
                              (a) =>
                                filter.warehouseId ===
                                  getWarehouseIdByFloorplanId(
                                    floorplans,
                                    a.floorplanId
                                  ) &&
                                (filter.floorplanId === undefined ||
                                  a.floorplanId === filter.floorplanId)
                            )
                            .map(({ id, name, color }) => {
                              const active =
                                (filter.assetIds || []).findIndex(
                                  (a) => a === id
                                ) !== -1;

                              const lbl = (
                                <span style={{ display: 'flex' }}>
                                  <span
                                    style={{
                                      display: 'inline-block',
                                      backgroundColor: active ? '#fff' : color,
                                      borderRadius: '15px',
                                      height: '15px',
                                      marginRight: '5px',
                                      marginTop: '2px',
                                      width: '15px',
                                    }}
                                  />
                                  <span>{name}</span>
                                </span>
                              );

                              return (
                                <TransparentMenuItem key={id} value={id}>
                                  <MenuChip
                                    style={{
                                      backgroundColor: active ? color : '#fff',
                                      border: `1px solid ${color || '#CCC'}`,
                                      color: active ? '#fff' : color,
                                      cursor: 'pointer',
                                    }}
                                    label={lbl}
                                  />
                                </TransparentMenuItem>
                              );
                            })}
                        </TransparentSelect>
                      </FormControl>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
      );
    }

    let rulesData;
    if (
      (activeFilters || []).indexOf('rules') !== -1 ||
      (activeFilters || []).indexOf('ruleTemplates') !== -1 ||
      (activeFilters || []).indexOf('eventTypes') !== -1
    ) {
      rulesData = (
        <Box mb={1} ml={1}>
          <Card>
            <Grid container wrap="nowrap">
              <Grid item>
                <Grid container>
                  <AssignmentLateIcon
                    style={{
                      margin: '2px 8px',
                      color: '#e1e1e1',
                      marginRight: '8px',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  {(activeFilters || []).indexOf('ruleTemplates') !== -1 ? (
                    <>
                      <FormControl variant="standard">
                        <TransparentSelect
                          value={
                            !(filter.templateIds || []).length
                              ? ['']
                              : filter.templateIds || []
                          }
                          onChange={(e) => this.onMutiSelect('templateIds', e)}
                          renderValue={this.renderActiveRuleTemplates}
                        >
                          <TransparentMenuItem value="" disabled>
                            <FormattedMessage
                              id="dashboard.filter.label_ruletemplates"
                              defaultMessage="Rule Templates"
                            />
                          </TransparentMenuItem>
                          {Object.values(templates || {}).map((template) => {
                            const active =
                              (filter.templateIds || []).findIndex(
                                (t) => t === template.id
                              ) !== -1;

                            return (
                              <TransparentMenuItem
                                key={template.id}
                                value={template.id}
                              >
                                <MenuChip
                                  style={{
                                    backgroundColor: active
                                      ? '#FF9500'
                                      : '#fff',
                                    color: active ? '#fff' : '#FF9500',
                                    cursor: 'pointer',
                                    border: `1px solid ${'#FF9500' || '#CCC'}`,
                                  }}
                                  label={template.name}
                                />
                              </TransparentMenuItem>
                            );
                          })}
                        </TransparentSelect>
                      </FormControl>
                    </>
                  ) : null}
                  {(activeFilters || []).indexOf('rules') !== -1 ? (
                    <>
                      {(activeFilters || []).indexOf('rule') !== -1 && (
                        <Typography className="greater-than" variant="body1">
                          {'>'}
                        </Typography>
                      )}
                      <FormControl variant="standard">
                        <TransparentSelect
                          value={
                            !(filter.ruleIds || []).length
                              ? ['']
                              : filter.ruleIds || []
                          }
                          onChange={(e) => this.onMutiSelect('ruleIds', e)}
                          renderValue={this.renderActiveRules}
                        >
                          <TransparentMenuItem value="" disabled>
                            <FormattedMessage
                              id="dashboard.filter.label_rules"
                              defaultMessage="Rules"
                            />
                          </TransparentMenuItem>
                          {Object.values(rules || {})
                            .filter(
                              (r) =>
                                (filter.templateIds || []).length === 0 ||
                                (filter.templateIds || []).indexOf(
                                  r.templateId
                                ) !== -1
                            )
                            .map((r) => {
                              const alertsCount = ruleAlerts.list.filter(
                                (ra) => ra.ruleId === r.id
                              ).length;

                              const lbl = (
                                <>
                                  {r.name}
                                  {alertsCount !== 0 && (
                                    <b style={{ marginLeft: '5px' }}>
                                      <span>(</span>
                                      {alertsCount}
                                      <span>)</span>
                                    </b>
                                  )}
                                </>
                              );

                              const active =
                                (filter.ruleIds || []).findIndex(
                                  (rr) => rr === r.id
                                ) !== -1;

                              return (
                                <TransparentMenuItem key={r.id} value={r.id}>
                                  <MenuChip
                                    style={{
                                      backgroundColor: active ? '#333' : '#fff',
                                      color: active ? '#fff' : '#333',
                                      cursor: 'pointer',
                                      border: `1px solid ${'#333' || '#CCC'}`,
                                    }}
                                    label={lbl}
                                  />
                                </TransparentMenuItem>
                              );
                            })}
                        </TransparentSelect>
                      </FormControl>
                    </>
                  ) : null}
                  {(activeFilters || []).indexOf('eventTypes') !== -1 ? (
                    <>
                      {((activeFilters || []).indexOf('rule') !== -1 ||
                        (activeFilters || []).indexOf('ruleTemplates') !==
                          -1) && (
                        <Typography className="greater-than" variant="body1">
                          {'>'}
                        </Typography>
                      )}
                      <FormControl variant="standard">
                        <TransparentSelect
                          value={
                            (filter.eventIds || []).length === 0
                              ? ['']
                              : filter.eventIds || []
                          }
                          onChange={(e) => this.onMutiSelect('eventIds', e)}
                          renderValue={this.renderActiveEvents}
                        >
                          <TransparentMenuItem value="" disabled>
                            <FormattedMessage
                              id="dashboard.filter.label_eventtype"
                              defaultMessage="Event Type"
                            />
                          </TransparentMenuItem>
                          {Object.values(templates || {}).map((t) => {
                            const eventCount = ruleAlerts.list.filter(
                              (ra) => ra.templateId === t.id
                            ).length;

                            const lbl = (
                              <>
                                {t.name}
                                {eventCount !== 0 && (
                                  <b style={{ marginLeft: '5px' }}>
                                    {ruleAlerts !== undefined
                                      ? `(${eventCount})`
                                      : ''}
                                  </b>
                                )}
                              </>
                            );

                            const active =
                              (filter.eventIds || []).findIndex(
                                (e) => e === t.id
                              ) !== -1;

                            return (
                              <TransparentMenuItem key={t.id} value={t.id}>
                                <MenuChip
                                  style={{
                                    backgroundColor: active
                                      ? '#0B3861'
                                      : '#fff',
                                    color: active ? '#fff' : '#0B3861',
                                    cursor: 'pointer',
                                    border: `1px solid ${'#0B3861' || '#CCC'}`,
                                  }}
                                  label={lbl}
                                />
                              </TransparentMenuItem>
                            );
                          })}
                        </TransparentSelect>
                      </FormControl>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
      );
    }

    let employeesData;
    if ((activeFilters || []).indexOf('employees') !== -1) {
      employeesData = (
        <Box mb={1} ml={1}>
          <Card>
            <Grid container wrap="nowrap">
              <Grid item>
                <Grid container>
                  <PersonIcon
                    style={{
                      margin: '2px 8px',
                      color: '#e1e1e1',
                      marginRight: '8px',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <FormControl variant="standard">
                    <TransparentSelect
                      value={
                        !(filter.employeeIds || []).length
                          ? ['']
                          : filter.employeeIds || []
                      }
                      onChange={(e) => this.onMutiSelect('employeeIds', e)}
                      renderValue={this.renderActiveEmployees}
                    >
                      <TransparentMenuItem value="" disabled>
                        <FormattedMessage
                          id="dashboard.filter.label_employees"
                          defaultMessage="Employees"
                        />
                      </TransparentMenuItem>
                      {Object.values(employees || {})
                        .map((employee) => {
                          const active =
                          (filter.employeeIds || []).findIndex(
                            (e) => e === employee.id
                          ) !== -1;

                          return (
                            <TransparentMenuItem key={employee.id} value={employee.id}>
                              <MenuChip
                                style={{
                                  backgroundColor: active ? '#333' : '#fff',
                                  color: active ? '#fff' : '#333',
                                  cursor: 'pointer',
                                  border: `1px solid ${'#333' || '#CCC'}`,
                                }}
                                label={employee.name}
                              />
                            </TransparentMenuItem>
                          );
                        })}
                    </TransparentSelect>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
      );
    }

    let teamsData;
    if ((activeFilters || []).indexOf('teams') !== -1) {
      teamsData = (
        <Box mb={1} ml={1}>
          <Card>
            <Grid container wrap="nowrap">
              <Grid item>
                <Grid container>
                  <PersonIcon
                    style={{
                      margin: '2px 8px',
                      color: '#e1e1e1',
                      marginRight: '8px',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <FormControl variant="standard">
                    <TransparentSelect
                      value={
                        !(filter.teamIds || []).length
                          ? ['']
                          : filter.teamIds || []
                      }
                      onChange={(e) => this.onMutiSelect('teamIds', e)}
                      renderValue={this.renderActiveTeams}
                    >
                      <TransparentMenuItem value="" disabled>
                        <FormattedMessage
                          id="dashboard.filter.label_teams"
                          defaultMessage="Teams"
                        />
                      </TransparentMenuItem>
                      {Object.values(teams || {})
                        .map((team) => {
                          const active =
                          (filter.teamIds || []).findIndex(
                            (e) => e === team.id
                          ) !== -1;

                          return (
                            <TransparentMenuItem key={team.id} value={team.id}>
                              <MenuChip
                                style={{
                                  backgroundColor: active ? '#333' : '#fff',
                                  color: active ? '#fff' : '#333',
                                  cursor: 'pointer',
                                  border: `1px solid ${'#333' || '#CCC'}`,
                                }}
                                label={team.name}
                              />
                            </TransparentMenuItem>
                          );
                        })}
                    </TransparentSelect>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
      );
    }

    const showDuplication =
      filterKeys.indexOf(filter.id) === filterKeys.length - 1;

    return (
      <>
        <Grid container>
          <Box mb={1}>
            <Card>
              <Grid container wrap="nowrap">
                <Grid item>
                  <Grid container>
                    <LocationOnIcon
                      style={{
                        margin: '2px 8px',
                        color: '#e1e1e1',
                        marginRight: '8px',
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    {filterId !== 'simulator' && (
                      <FormControl variant="standard">
                        <Select
                          disableUnderline
                          value={filter.warehouseId || ''}
                          onChange={this.onSelectWarehouse}
                          disabled={filterId === 'simulator'}
                        >
                          <DMenuItem value="" disabled>
                            <FormattedMessage
                              id="dashboard.filter.label_warehouse"
                              defaultMessage="Warehouse"
                            />
                          </DMenuItem>
                          {Object.keys(warehouses).map((id) => (
                            <DMenuItem key={id} value={id}>
                              {warehouses[id].name}
                            </DMenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {filterId !== 'simulator' && (
                      <>
                        <Typography className="greater-than" variant="body1">
                          {'>'}
                        </Typography>
                        <FormControl variant="standard">
                          <Select
                            disableUnderline
                            value={filter.floorplanId || ''}
                            onChange={this.onSelectFloorplan}
                          >
                            <DMenuItem value="" disabled>
                              <FormattedMessage
                                id="dashboard.filter.label_floor"
                                defaultMessage="Floor"
                              />
                            </DMenuItem>
                            {Object.values(floorplans)
                              .filter(
                                (fp) =>
                                  fp.warehouseId === filter.warehouseId &&
                                  (!fp.hidden || showHidden)
                              )
                              .map(({ id, name, hidden }) => (
                                <DMenuItem key={id} value={id}>
                                  {hidden === true ? (
                                    <VisibilityOffIcon
                                      style={{
                                        height: '15px',
                                        marginRight: '10px',
                                        opacity: 0.5,
                                        width: '15px',
                                      }}
                                    />
                                  ) : null}
                                  {name}
                                </DMenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </>
                    )}
                    {(activeFilters || []).indexOf('zones') !== -1 ? (
                      <>
                        {filterId !== 'simulator' && (
                          <Typography className="greater-than" variant="body1">
                            {'>'}
                          </Typography>
                        )}
                        <FormControl variant="standard">
                          <TransparentSelect
                            onChange={(e) => this.onMutiSelect('zoneIds', e)}
                            renderValue={this.renderActiveZones}
                            value={
                              (filter.zoneIds || []).length === 0
                                ? ['']
                                : filter.zoneIds || []
                            }
                          >
                            <TransparentMenuItem value="" disabled>
                              <FormattedMessage
                                id="dashboard.filter.label_zone"
                                defaultMessage="Zone"
                              />
                            </TransparentMenuItem>
                            {Object.values(zones)
                              .filter(
                                (z) => z.floorplanId === filter.floorplanId
                              )
                              .map((zone) => {
                                const active =
                                  (filter.zoneIds || []).findIndex(
                                    (z) => z === zone.id
                                  ) !== -1;

                                return (
                                  <TransparentMenuItem
                                    key={zone.id}
                                    value={zone.id}
                                  >
                                    <MenuChip
                                      style={{
                                        backgroundColor: active
                                          ? zone.color
                                          : '#fff',
                                        color: active ? '#fff' : zone.color,
                                        cursor: 'pointer',
                                        border: `1px solid ${
                                          zone.color || '#CCC'
                                        }`,
                                      }}
                                      label={zone.name}
                                    />
                                  </TransparentMenuItem>
                                );
                              })}
                          </TransparentSelect>
                        </FormControl>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Box>
          {assetsData}
          {rulesData}
          {employeesData}
          {teamsData}
          {otherFilters}
          <Box mb={1} ml={1}>
            {showDuplication && (
              <IconButton id="add-map" size="small" onClick={this.duplicateMap}>
                <AddCircleOutlineIcon />
              </IconButton>
            )}
            {filterKeys.length > 1 ? (
              <IconButton
                id="delete-map"
                size="small"
                onClick={this.deleteFilter}
              >
                <HighlightOffIcon />
              </IconButton>
            ) : null}
          </Box>
        </Grid>
        {children}
      </>
    );
  }
}

export default Filters;
