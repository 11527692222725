import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IUpdateFloorplan } from '@actions/index';
import Loading from '@app/common/Loading';
import LoadingButton from '@app/common/LoadingButton';
import { IncompleteFloorplans, ItemLocation } from '@models/IncompleteFloorplan';
import ItemLocationsForm from './ItemLocationsForm';

interface IProps {
  floorplanId: string;
  floorplans: IncompleteFloorplans;
  itemLocations?: ItemLocation[];
  intl: IntlShape;
  loading: boolean;
  updateFloorplan: (properties: IUpdateFloorplan) => any;
  warehouseId: string;
}

interface IState {
  hasChanges: boolean;
  itemLocations: ItemLocation[];
}

class ItemLocations extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { itemLocations } = props;

    this.state = {
      itemLocations: itemLocations || [],
      hasChanges: false,
    };

    this.mappingChange = this.mappingChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleSubmit() {
    const { itemLocations } = this.state;
    const { floorplanId, intl, warehouseId, updateFloorplan } =
      this.props;

    this.setState({ hasChanges: false }, () => {
      updateFloorplan({
        floorplanId,
        intl,
        properties: { itemLocations },
        warehouseId,
      });
    });
  }

  public mappingChange(itemLocations: ItemLocation[]) {
    this.setState({
      itemLocations,
      hasChanges: true,
    });
  }

  public render() {
    const { floorplanId, loading } = this.props;
    const { itemLocations, hasChanges } = this.state;

    return (
      <Card className="step-card">
        <CardContent style={{ flex: 1, position: 'relative' }}>
          <Loading loading={loading}>
            <ItemLocationsForm
              mappingChange={this.mappingChange}
              itemLocations={itemLocations}
              floorplanId={floorplanId}
            />
          </Loading>
        </CardContent>
        <CardActions
          style={{ borderTop: '1px solid #e1e1e1', justifyContent: 'flex-end' }}
        >
          <LoadingButton
            disabled={!hasChanges}
            loading={loading}
            onClick={this.handleSubmit}
            color="secondary"
            variant="contained"
          >
            <FormattedMessage
              id="dashboard.forms.floorplanconfigurationform.button_save"
              defaultMessage="Save Changes"
            />
          </LoadingButton>
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(ItemLocations);
