import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  confirmDialog,
  deleteZone,
  IUpdateFloorplan,
  toggleZoneMetrics,
  updateEditFeature,
  updateFloorplan,
  updateSelectedFeature,
} from '@actions/index';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { getWarehouseIdByFloorplanId } from '@app/utils/floorplanUtils';
import { withRouter } from '@app/utils/withRouter';
import { timeZone } from '@dashboard_utils/index';
import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import { getWarehouseById } from '@selectors/warehouses';
import FeatureInfo from './FeatureInfo';
import { MapFeature } from '../../types';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    editFeature: map.editFeature,
    feature: map.feature,
    mapImages: map.mapImages,
    paper: map.paper,

    floorplan: getFloorplanById(state, map.floorplanId)!,
    warehouseTz:
      (
        getWarehouseById(
          state,
          getWarehouseIdByFloorplanId(
            state.floorplans.floorplans,
            map.floorplanId
          ) || ''
        ) || {}
      ).timezone || timeZone,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  deleteZone: (id: string) => dispatch(deleteZone.request({ id })),
  updateSelectedFeature: (id: string, feature?: MapFeature) =>
    dispatch(updateSelectedFeature(id, feature)),
  updateEditFeature: (id: string, feature?: MapFeature) =>
    dispatch(updateEditFeature(id, feature)),
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
  toggleZoneMetrics: (id?: string, filterId?: string) =>
    dispatch(toggleZoneMetrics(id, filterId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeatureInfo)
);
