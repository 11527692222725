import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import LoadingButton from '@app/common/LoadingButton';
import { Box } from '@mui/system';
import { Breadcrumbs, TableCell } from '@mui/material';
import Loading from '@app/common/Loading';
import FetchError from '@models/FetchError';
import { ICreateShift, IUpdateShift } from '@actions/index';
import { Shift } from '@models/Shift';
import { Employees } from '@models/Employee';

const intervals = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

interface IProps {
  createShift: (properties: ICreateShift) => void;
  updateShift: (properties: IUpdateShift) => void;
  employeeMap: Employees;
  fetchEmployees: (shallow?: boolean) => void;
  fetchShifts: () => void;
  intl: IntlShape;
  language: string;
  loading: boolean;
  error?: FetchError;
  data?: Shift;
  router: any;
}

export interface IState {
  id?: string;
  name: string;
  employees: string[];
  activePeriods: Record<number, boolean>;
  tmpPeriods: Record<number, boolean>;
  startClickIndex?: number;
  submitted: boolean;
}

class ShiftForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { data } = this.props;

    this.state = {
      id: (data || {}).id,
      name: (data || {}).name || '',
      employees: (data || {}).employees || [],
      activePeriods: (data || {}).periods || {},
      tmpPeriods: {},
      submitted: false,
    };

    this.setDragDown = this.setDragDown.bind(this);
    this.setDragUp = this.setDragUp.bind(this);
    this.setDragMove = this.setDragMove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();
  }

  public componentDidUpdate(prevProps: any) {
    const { submitted } = this.state;
    const { data, language, router, loading, error } = this.props;

    if (prevProps.loading == true && loading === false && submitted === true && !error) {
      return router.navigate(`/${language}/dashboard/shifts`);
    }

    if (!prevProps.data && data) {
      this.setState({
        id: (data || {}).id,
        name: (data || {}).name || '',
        activePeriods: (data || {}).periods || {},
        tmpPeriods: {},
        submitted: false,
      });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  public handleChange(e: any) {
    const { name } = e.target;
    const value: string | boolean =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    const newState: any = {};
    newState[name] = value || '';

    this.setState(newState as Pick<IState, 'name'>);
  }

  public handleCancel() {
    const { language, router } = this.props;

    router.navigate(`/${language}/dashboard/shifts`);
  }

  public setDragDown(e: any, i: number) {
    e.stopPropagation();

    this.setState({ startClickIndex: i });
  }

  public setDragUp(e: any, i: number) {
    e.stopPropagation();

    const { startClickIndex, activePeriods } = this.state;

    if (startClickIndex === undefined) {
      return;
    }

    const cpActivePeriods = { ...activePeriods };
    for (let y = startClickIndex || 0; y <= i; y += 1) {
      cpActivePeriods[y] = !cpActivePeriods[y];
    }
    this.setState({ activePeriods: cpActivePeriods, tmpPeriods: {}, startClickIndex: undefined });
  }

  public setDragMove(e: any, i: number) {
    e.stopPropagation();

    const { startClickIndex } = this.state;

    if (startClickIndex === undefined) {
      return;
    }

    const cpTmpPeriods: Record<number, boolean> = {};
    for (let y = startClickIndex || 0; y <= i; y += 1) {
      cpTmpPeriods[y] = !cpTmpPeriods[y];
    }
    this.setState({ tmpPeriods: cpTmpPeriods });
  }

  public handleSubmit() {
    const { id, name, employees, activePeriods } = this.state;
    const { createShift, updateShift, intl } = this.props;

    this.setState({ submitted: true }, () => {
      if (id) {
        return updateShift({
          id,
          name,
          employees,
          periods: activePeriods,
          intl,
        });
      }

      createShift({
        name,
        periods: activePeriods,
        employees,
        intl,
      });
    });
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const { fetchEmployees, fetchShifts } = this.props;

    if (!shallow) {
      fetchShifts();
    }

    fetchEmployees(shallow);
  }

  public render() {
    const { language, loading, employeeMap } = this.props;
    const {
      id,
      name,
      employees,
      activePeriods,
      tmpPeriods,
    } = this.state;

    const error = name.length === 0;

    const buttonDisabled = error;

    const LinkRouter = (props: any) => (
      <Link {...props} component={RouterLink} />
    );

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '10px' }}>
          <LinkRouter
            color="inherit"
            to={`/${language}/dashboard/shifts/`}
          >
            <FormattedMessage
              id="dashboard.shifts.breadcrumbs.home"
              defaultMessage="Shifts"
            />
          </LinkRouter>
          <Typography color="textPrimary">
            {!id ? (
              <FormattedMessage
                id="dashboard.forms.shiftform.button_create"
                defaultMessage="Create Shift"
              />
            ) : (
              <FormattedMessage
                id="dashboard.forms.shiftform.button_update"
                defaultMessage="Update Shift"
              />
            )}
          </Typography>
        </Breadcrumbs>
        <Loading loading={loading}>
          <Card style={{ overflow: 'visible' }}>
            <CardContent>
              <FormControl required fullWidth margin="normal">
                <TextField
                  name="name"
                  variant="standard"
                  onChange={this.handleChange}
                  value={name}
                  label={
                    <FormattedMessage
                      id="dashboard.forms.shiftform.label_name"
                      defaultMessage="Shift name"
                    />
                  }
                />
                <FormHelperText>
                  <FormattedMessage
                    id="dashboard.forms.shiftform.field_name_helper"
                    defaultMessage="A shift name must be specified"
                  />
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="employees" variant="standard">Employees</InputLabel>
                <Select
                  labelId="employees"
                  multiple
                  onChange={this.handleChange}
                  name="employees"
                  variant="standard"
                  value={employees}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={(employeeMap[value] || {}).name || ''} />
                      ))}
                    </Box>
                  )}
                >
                  {Object.values(employeeMap).map((e) => (
                    <MenuItem
                      key={e.id}
                      value={e.id}
                    >
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box mt={4}>
                <Table id="schedule">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Monday</TableCell>
                      <TableCell>Tuesday</TableCell>
                      <TableCell>Wednesday</TableCell>
                      <TableCell>Thursday</TableCell>
                      <TableCell>Friday</TableCell>
                      <TableCell>Saturday</TableCell>
                      <TableCell>Sunday</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {intervals.map((i, idx) => (
                      <TableRow key={i}>
                        <TableCell>{i}</TableCell>
                        <TableCell
                          onMouseDown={(e) => this.setDragDown(e, idx)}
                          onMouseUp={(e) => this.setDragUp(e, idx)}
                          onMouseEnter={(e) => this.setDragMove(e, idx)}
                        >
                          {activePeriods[idx] ? (
                            <div className="period" key={idx}></div>
                          ) : null}
                          {tmpPeriods[idx] ? (
                            <div className="tmp-period" key={`t_${idx}`}></div>
                          ) : null}
                        </TableCell>
                        <TableCell
                          onMouseDown={(e) => this.setDragDown(e, intervals.length + idx)}
                          onMouseUp={(e) => this.setDragUp(e, intervals.length + idx)}
                          onMouseEnter={(e) => this.setDragMove(e, intervals.length + idx)}
                        >
                          {activePeriods[intervals.length + idx] ? (
                            <div className="period" key={idx}></div>
                          ) : null}
                          {tmpPeriods[intervals.length + idx] ? (
                            <div className="tmp-period" key={`t_${idx}`}></div>
                          ) : null}
                        </TableCell>
                        <TableCell
                          onMouseDown={(e) => this.setDragDown(e, intervals.length * 2 + idx)}
                          onMouseUp={(e) => this.setDragUp(e, intervals.length * 2 + idx)}
                          onMouseEnter={(e) => this.setDragMove(e, intervals.length * 2 + idx)}
                        >
                          {activePeriods[intervals.length * 2 + idx] ? (
                            <div className="period" key={idx}></div>
                          ) : null}
                          {tmpPeriods[intervals.length * 2 + idx] ? (
                            <div className="tmp-period" key={`t_${idx}`}></div>
                          ) : null}
                        </TableCell>
                        <TableCell
                          onMouseDown={(e) => this.setDragDown(e, intervals.length * 3 + idx)}
                          onMouseUp={(e) => this.setDragUp(e, intervals.length * 3 + idx)}
                          onMouseEnter={(e) => this.setDragMove(e, intervals.length * 3 + idx)}
                        >
                          {activePeriods[intervals.length * 3 + idx] ? (
                            <div className="period" key={idx}></div>
                          ) : null}
                          {tmpPeriods[intervals.length * 3 + idx] ? (
                            <div className="tmp-period" key={`t_${idx}`}></div>
                          ) : null}
                        </TableCell>
                        <TableCell
                          onMouseDown={(e) => this.setDragDown(e, intervals.length * 4 + idx)}
                          onMouseUp={(e) => this.setDragUp(e, intervals.length * 4 + idx)}
                          onMouseEnter={(e) => this.setDragMove(e, intervals.length * 4 + idx)}
                        >
                          {activePeriods[intervals.length * 4 + idx] ? (
                            <div className="period" key={idx}></div>
                          ) : null}
                          {tmpPeriods[intervals.length * 4 + idx] ? (
                            <div className="tmp-period" key={`t_${idx}`}></div>
                          ) : null}
                        </TableCell>
                        <TableCell
                          onMouseDown={(e) => this.setDragDown(e, intervals.length * 5 + idx)}
                          onMouseUp={(e) => this.setDragUp(e, intervals.length * 5 + idx)}
                          onMouseEnter={(e) => this.setDragMove(e, intervals.length * 5 + idx)}
                        >
                          {activePeriods[intervals.length * 5 + idx] ? (
                            <div className="period" key={idx}></div>
                          ) : null}
                          {tmpPeriods[intervals.length * 5 + idx] ? (
                            <div className="tmp-period" key={`t_${idx}`}></div>
                          ) : null}
                        </TableCell>
                        <TableCell
                          onMouseDown={(e) => this.setDragDown(e, intervals.length * 6 + idx)}
                          onMouseUp={(e) => this.setDragUp(e, intervals.length * 6 + idx)}
                          onMouseEnter={(e) => this.setDragMove(e, intervals.length * 6 + idx)}
                        >
                          {activePeriods[intervals.length * 6 + idx] ? (
                            <div className="period" key={idx}></div>
                          ) : null}
                          {tmpPeriods[intervals.length * 6 + idx] ? (
                            <div className="tmp-period" key={`t_${idx}`}></div>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </CardContent>
            <CardActions style={{ justifyContent: 'flex-end' }}>
              <Box pr={1}>
                <Button onClick={this.handleCancel}>
                  <FormattedMessage
                    id="dashboard.forms.shiftform.button_cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </Box>
              <LoadingButton
                onClick={this.handleSubmit}
                variant="contained"
                color="secondary"
                disabled={buttonDisabled}
                loading={loading}
              >
                {!id ? (
                  <FormattedMessage
                    id="dashboard.forms.shiftform.button_create"
                    defaultMessage="Create Shift"
                  />
                ) : (
                  <FormattedMessage
                    id="dashboard.forms.shiftform.button_update"
                    defaultMessage="Update Shift"
                  />
                )}
              </LoadingButton>
            </CardActions>
          </Card>
        </Loading>
      </>
    );
  }
}

export default injectIntl(ShiftForm);
