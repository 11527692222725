import React, { FunctionComponent } from 'react';
import { GetTrackProps, SliderItem } from 'react-compound-slider';

import { PRIMARY_COLOR } from '@app/utils/colors';

interface IProps {
  theme: any;
  getTrackProps: GetTrackProps;
  source: SliderItem;
  target: SliderItem;
}

const Track: FunctionComponent<IProps> = (properties: IProps) => {
  const { source, target, getTrackProps } = properties;

  return (
    <div
      className="range-slider-track"
      style={{
        backgroundColor: PRIMARY_COLOR,
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
};

export default Track;
