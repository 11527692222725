import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import RedoTwoToneIcon from '@mui/icons-material/RedoTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopIcon from '@mui/icons-material/Stop';
import clsx from 'clsx';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { ICreateSimulation, IStopSimulation } from '@actions/index';
import { Date } from '@dashboard_utils/index';
import { Assets } from '@models/Asset';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import {
  Simulation,
  ISimulationWithNames,
  SimulationStatus,
  SimulationStatusCodes,
} from '@models/Simulation';
import { Warehouses } from '@models/Warehouse';
import CustomTableCell from '@app/common/mui-custom/CustomTableCell';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '../../../../dialogs/ConfirmDialog/types';
import { getAssetTypeName } from '../../../../forms/AssetForm/utils';
import { simulationLengths } from '../SimulationForm/SimulationForm';

const messages = {
  more: {
    defaultMessage: 'More',
    id: 'dashboard.simulations.menu_aria_label',
  },
  simulationDeleteMsg: {
    defaultMessage: 'Are you sure that you want to DELETE this simulation?',
    id: 'dashboard.simulations.deletemsg',
  },
  simulationStopMsg: {
    defaultMessage: 'Are you sure that you want to STOP this simulation?',
    id: 'dashboard.simulations.stopmsg',
  },
};

const simulationStatus = (
  status: SimulationStatus,
  startTs: number,
  data: ISimulationWithNames
) => {
  if (status === SimulationStatus.CANCELLED) {
    return (
      <b style={{ color: 'grey' }}>
        <FormattedMessage
          id="dashboard.simulations.status.cancelled"
          defaultMessage="Cancelled"
        />
      </b>
    );
  }

  if (status === SimulationStatus.PENDING) {
    return (
      <b style={{ color: 'orange' }}>
        <FormattedMessage
          id="dashboard.simulations.status.pending"
          defaultMessage="Pending"
        />
      </b>
    );
  }

  if (status === SimulationStatus.STARTED) {
    return (
      <b style={{ color: 'orange' }}>
        <FormattedMessage
          id="dashboard.simulations.status.started"
          defaultMessage="Started"
        />
      </b>
    );
  }

  if (status === SimulationStatus.RUNNING) {
    const now = new Date(data.warehouseTimeZone).getTime();
    const progressMetric = (now - startTs) / (data.progressPercentage || 1);
    const etaMinutes = Math.ceil(
      ((100 - (data.progressPercentage || 0)) * progressMetric) / (60 * 1000)
    );

    return (
      <>
        <Box position="relative" display="inline-flex">
          <CircularProgress
            variant="determinate"
            value={data.progressPercentage || 0}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption" component="div" color="textSecondary">
              {`${Math.round((data.progressPercentage || 0) * 10) / 10}%`}
            </Typography>
          </Box>
        </Box>
        <i style={{ display: 'block', color: 'grey' }}>
          <FormattedMessage
            id="dashboard.simulations.status.running"
            defaultMessage="Running... ({etaMinutes} minute(s) left)"
            values={{ etaMinutes }}
          />
        </i>
      </>
    );
  }

  if (status === SimulationStatus.FINISHED) {
    return (
      <b style={{ color: 'green' }}>
        <FormattedMessage
          id="dashboard.simulations.status.done"
          defaultMessage="Terminated"
        />
      </b>
    );
  }

  if (status === SimulationStatus.FAILED) {
    return (
      <b style={{ color: 'red' }}>
        <FormattedMessage
          id="dashboard.simulations.status.failed"
          defaultMessage="Failed"
        />
      </b>
    );
  }

  return (
    <b style={{ color: 'red' }}>
      <FormattedMessage
        id="dashboard.simulations.status.unknown"
        defaultMessage="Unknown"
      />
    </b>
  );
};

interface IProps {
  assets: Assets;
  confirm: (properties: IConfirmDialog) => void;
  language: string;

  router: any;

  onSimulationDelete: (id: string) => void;
  onSimulationStop: (properties: IStopSimulation) => void;
  createSimulation: (properties: ICreateSimulation) => void;
  simulation: Simulation;
  floorplans: IncompleteFloorplans;
  warehouses: Warehouses;

  columns: boolean[];
  data: ISimulationWithNames;
  colSpan: number;
  rowData: any;

  floorplanId: string;

  intl: IntlShape;
}

interface IAnchorEL {
  menu?: HTMLElement;
  expanded?: boolean;
}

interface IState {
  anchorEl: IAnchorEL;
}

class SimulationTableLine extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: {},
    };
  }

  public handleMenu() {
    return (event: any) => {
      const { anchorEl } = this.state;

      this.setState({
        anchorEl: {
          ...anchorEl,
          menu: event.currentTarget,
        },
      });
    };
  }

  public handleMenuClose() {
    const { anchorEl } = this.state;

    this.setState({
      anchorEl: {
        ...anchorEl,
        menu: undefined,
      },
    });
  }

  public handleRetry(data: Simulation) {
    const { createSimulation, floorplanId } = this.props;

    createSimulation({
      agents: data.agents,
      floorplanId,
      modelFloorplanId: data.modelFloorplanId,
      length: data.length,
      name: data.name,
      orderVolume: data.orderVolume,
      router: data.router,
      routerDynamic: data.routerDynamic,
      inputCsv: data.inputCsv || undefined,
      startTs: data.startTs,
      shifts: data.shifts,
    });
  }

  public handleDelete(id: string) {
    const { confirm, intl, onSimulationDelete } = this.props;
    const { anchorEl } = this.state;

    this.setState(
      {
        anchorEl: {
          ...anchorEl,
          menu: undefined,
        },
      },
      () => {
        confirm({
          confirmType: ConfirmDialogActions.DELETE,
          message: intl.formatMessage(messages.simulationDeleteMsg),
          onConfirmation: () => {
            onSimulationDelete(id);
          },
        });
      }
    );
  }

  public handleExpandableRow() {
    const { anchorEl } = this.state;

    this.setState(
      {
        anchorEl: {
          ...anchorEl,
          expanded: !anchorEl.expanded,
          menu: undefined,
        },
      },
      () => setTimeout(() => window.dispatchEvent(new Event('resize')), 300)
    );
  }

  public goToPlayer(id: string) {
    const { router, language } = this.props;
    const { anchorEl } = this.state;

    this.setState(
      {
        anchorEl: {
          ...anchorEl,
          menu: undefined,
        },
      },
      () => router.navigate(`/${language}/dashboard/simulations/${id}/player`)
    );
  }

  public goToAnalytics(id: string) {
    const { router, language } = this.props;
    const { anchorEl } = this.state;

    this.setState(
      {
        anchorEl: {
          ...anchorEl,
          menu: undefined,
        },
      },
      () => router.navigate(`/${language}/dashboard/simulations/${id}/analytics`)
    );
  }

  public stopSimulation(id: string) {
    const { confirm, intl, onSimulationStop } = this.props;
    const { anchorEl } = this.state;

    this.setState(
      {
        anchorEl: {
          ...anchorEl,
          menu: undefined,
        },
      },
      () => {
        confirm({
          confirmType: ConfirmDialogActions.STOP,
          message: intl.formatMessage(messages.simulationStopMsg),
          onConfirmation: () => {
            onSimulationStop({ id, intl });
          },
        });
      }
    );
  }

  public generateCSVHref(csv: string) {
    return window.encodeURI(`data:text/csv;charset=utf-8,${csv}`);
  }

  public render() {
    const {
      assets,
      columns,
      colSpan,
      data,
      floorplans,
      intl,
      rowData,
      simulation,
      warehouses,
    } = this.props;
    const { anchorEl } = this.state;

    const chevronRotation = clsx('chevron', {
      rotate: (anchorEl || {}).expanded === true,
    });

    let statusCode = null;
    if (data.statusCode === SimulationStatusCodes.NoDropZonesDefined) {
      statusCode = (
        <i style={{ display: 'block', color: 'grey' }}>
          <FormattedMessage
            id="dashboard.simulations.statuscodes.nodropzonesdefined"
            defaultMessage="No drop zones defined"
          />
        </i>
      );
    } else if (data.statusCode === SimulationStatusCodes.InternalError) {
      statusCode = (
        <i style={{ display: 'block', color: 'grey' }}>
          <FormattedMessage
            id="dashboard.simulations.statuscodes.internalerror"
            defaultMessage="Internal Error"
          />
        </i>
      );
    } else if (data.statusCode === SimulationStatusCodes.UnknownError) {
      statusCode = (
        <i style={{ display: 'block', color: 'grey' }}>
          <FormattedMessage
            id="dashboard.simulations.statuscodes.unknownerror"
            defaultMessage="Unknown Internal Error"
          />
        </i>
      );
    } else if (data.statusCode === SimulationStatusCodes.UnableToCommunicate) {
      statusCode = (
        <i style={{ display: 'block', color: 'grey' }}>
          <FormattedMessage
            id="dashboard.simulations.statuscodes.unabletocommunicate"
            defaultMessage="Trouble loading data. Please try again."
          />
        </i>
      );
    }

    const stopMenu = (
      <MenuItem onClick={() => this.stopSimulation(data.id)}>
        <ListItemIcon>
          <StopIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <FormattedMessage
              id="dashboard.simulations.stop"
              defaultMessage="Stop"
            />
          }
        />
      </MenuItem>
    );

    const customTableCellStyle = { borderBottom: '0px', fontWeight: 700 };

    return (
      <>
        <TableRow>
          <TableCell>
            <ChevronRightIcon
              className={chevronRotation}
              onClick={() => this.handleExpandableRow()}
              style={{ cursor: 'pointer' }}
            />
          </TableCell>
          {columns[1] && <TableCell>{rowData[1]}</TableCell>}
          {columns[2] && (
            <TableCell>
              {new Date(data.warehouseTimeZone, rowData[2]).format()}
            </TableCell>
          )}
          {columns[3] && (
            <TableCell>
              {new Date(data.warehouseTimeZone, rowData[3]).format()}
            </TableCell>
          )}
          {columns[4] && (
            <TableCell>
              {
                (simulationLengths.find((l) => l.value === rowData[4]) || {})
                  .label
              }
            </TableCell>
          )}
          {columns[5] && (
            <TableCell>
              {simulationStatus(
                rowData[5],
                new Date(data.warehouseTimeZone, rowData[2]).getTime(),
                data
              )}
              {statusCode}
            </TableCell>
          )}
          {columns[6] && <TableCell>{rowData[6]}</TableCell>}
          {columns[7] && <TableCell>{rowData[7]}</TableCell>}
          <TableCell>
            <div style={{ float: 'right' }}>
              <IconButton
                aria-label={intl.formatMessage(messages.more)}
                aria-owns={anchorEl.menu ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleMenu()}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl.menu}
                open={!!anchorEl.menu}
                onClose={() => this.handleMenuClose()}
              >
                <MenuItem key={0} onClick={() => this.goToPlayer(data.id)}>
                  <ListItemIcon>
                    <PlayCircleOutlineIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.simulations.player"
                        defaultMessage="Player"
                      />
                    }
                  />
                </MenuItem>
                <MenuItem key={1} onClick={() => this.goToAnalytics(data.id)}>
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.simulations.analytics"
                        defaultMessage="Analytics"
                      />
                    }
                  />
                </MenuItem>
                {data.status === SimulationStatus.STARTED ||
                data.status === SimulationStatus.PENDING ||
                data.status === SimulationStatus.RUNNING
                  ? stopMenu
                  : null}
                <MenuItem onClick={() => this.handleRetry(data)}>
                  <ListItemIcon>
                    <RedoTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Re-run simulation" />
                </MenuItem>
                <MenuItem onClick={() => this.handleDelete(data.id)}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.simulations.delete"
                        defaultMessage="Delete Simulation"
                      />
                    }
                  />
                </MenuItem>
              </Menu>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={colSpan}
            padding="none"
            style={{ borderBottom: 'none' }}
          >
            <Collapse in={(anchorEl || {}).expanded === true}>
              <div style={{ padding: '14px 40px 14px 16px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Table style={{ marginTop: '60px' }}>
                      <TableBody>
                        <TableRow hover={false}>
                          <CustomTableCell style={customTableCellStyle}>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.label_name"
                              defaultMessage="Simulation name"
                            />
                            :
                          </CustomTableCell>
                          <CustomTableCell style={{ borderBottom: '0px' }}>
                            {simulation.name}
                          </CustomTableCell>
                        </TableRow>
                        <TableRow hover={false}>
                          <CustomTableCell style={customTableCellStyle}>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.label_ordervolume"
                              defaultMessage="Order Volume"
                            />
                            :
                          </CustomTableCell>
                          <CustomTableCell style={{ borderBottom: '0px' }}>
                            <span>{simulation.orderVolume}</span>
                            <span>%</span>
                          </CustomTableCell>
                        </TableRow>
                        <TableRow hover={false}>
                          <CustomTableCell style={customTableCellStyle}>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.label_startts"
                              defaultMessage="Starting Date"
                            />
                            :
                          </CustomTableCell>
                          <CustomTableCell style={{ borderBottom: '0px' }}>
                            {new Date(
                              data.warehouseTimeZone,
                              simulation.startTs
                            ).format()}
                          </CustomTableCell>
                        </TableRow>
                        <TableRow hover={false}>
                          <CustomTableCell style={customTableCellStyle}>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.label_length"
                              defaultMessage="Simulation length"
                            />
                            :
                          </CustomTableCell>
                          <CustomTableCell style={{ borderBottom: '0px' }}>
                            {
                              (
                                simulationLengths.find(
                                  (simulationLength) =>
                                    simulationLength.value === simulation.length
                                ) || {}
                              ).label
                            }
                          </CustomTableCell>
                        </TableRow>
                        <TableRow hover={false}>
                          <CustomTableCell style={customTableCellStyle}>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.label_router"
                              defaultMessage="Router"
                            />
                            :
                          </CustomTableCell>
                          <CustomTableCell style={{ borderBottom: '0px' }}>
                            {simulation.router}
                          </CustomTableCell>
                        </TableRow>
                        {simulation.router ? (
                          <TableRow hover={false}>
                            <CustomTableCell style={customTableCellStyle}>
                              <FormattedMessage
                                id="dashboard.forms.simulationform.label_dynamicrouting"
                                defaultMessage="Dynamic Routing"
                              />
                              :
                            </CustomTableCell>
                            <CustomTableCell style={{ borderBottom: '0px' }}>
                              {simulation.routerDynamic ? (
                                <FormattedMessage
                                  id="boolean.true"
                                  defaultMessage="True"
                                />
                              ) : (
                                <FormattedMessage
                                  id="boolean.false"
                                  defaultMessage="False"
                                />
                              )}
                            </CustomTableCell>
                          </TableRow>
                        ) : null}
                        <TableRow hover={false}>
                          <CustomTableCell style={customTableCellStyle}>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.label_warehouse"
                              defaultMessage="Warehouse"
                            />
                            :
                          </CustomTableCell>
                          <CustomTableCell style={{ borderBottom: '0px' }}>
                            {(warehouses[simulation.warehouseId] || {}).name}
                          </CustomTableCell>
                        </TableRow>
                        <TableRow hover={false}>
                          <CustomTableCell style={customTableCellStyle}>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.label_floorplan"
                              defaultMessage="Floor plan"
                            />
                            :
                          </CustomTableCell>
                          <CustomTableCell style={{ borderBottom: '0px' }}>
                            {
                              (
                                Object.values(floorplans).find(
                                  (fp) =>
                                    fp.id === simulation.originalFloorplanId
                                ) || {}
                              ).name
                            }
                          </CustomTableCell>
                        </TableRow>
                        <TableRow hover={false}>
                          <CustomTableCell style={customTableCellStyle}>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.label_modelfloorplan"
                              defaultMessage="Floor plan for models"
                            />
                            :
                          </CustomTableCell>
                          <CustomTableCell style={{ borderBottom: '0px' }}>
                            {
                              (
                                Object.values(floorplans).find(
                                  (fp) =>
                                    fp.id === simulation.modelFloorplanId
                                ) || {}
                              ).name
                            }
                          </CustomTableCell>
                        </TableRow>
                        {simulation.inputCsv && (<TableRow hover={false}>
                          <CustomTableCell style={customTableCellStyle}>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.label_input_file"
                              defaultMessage="Input File"
                            />
                            :
                          </CustomTableCell>
                          <CustomTableCell style={{ borderBottom: '0px' }}>
                            <a href={this.generateCSVHref(simulation.inputCsv)} download="input.csv">Download</a>
                          </CustomTableCell>
                        </TableRow>)}
                        {simulation.outputCsv && (<TableRow hover={false}>
                          <CustomTableCell style={customTableCellStyle}>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.label_output_file"
                              defaultMessage="Output File"
                            />
                            :
                          </CustomTableCell>
                          <CustomTableCell style={{ borderBottom: '0px' }}>
                            <a href={this.generateCSVHref(simulation.outputCsv)} download="output.csv">Download</a>
                          </CustomTableCell>
                        </TableRow>)}
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <CustomTableCell rowSpan={2}>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.agents.number"
                              defaultMessage="Agent"
                            />
                          </CustomTableCell>
                          <CustomTableCell colSpan={2}>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.agents.link"
                              defaultMessage="Link"
                            />
                          </CustomTableCell>
                        </TableRow>
                        <TableRow>
                          <CustomTableCell>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.agents.asset"
                              defaultMessage="Existing Asset"
                            />
                          </CustomTableCell>
                          <CustomTableCell>
                            <FormattedMessage
                              id="dashboard.forms.simulationform.agents.assettype"
                              defaultMessage="Asset Type"
                            />
                          </CustomTableCell>
                        </TableRow>
                      </TableHead>
                      {(simulation.agents || []).map((a, index) => (
                        <TableBody key={index}>
                          <TableRow hover={false}>
                            <CustomTableCell>{index + 1}</CustomTableCell>
                            <CustomTableCell>
                              {a.assetId
                                ? (assets[a.assetId] || {}).name || 'NA'
                                : null}
                            </CustomTableCell>
                            <CustomTableCell>
                              {a.type ? getAssetTypeName(a.type) : null}
                            </CustomTableCell>
                          </TableRow>
                        </TableBody>
                      ))}
                    </Table>
                  </Grid>
                </Grid>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
}

export default injectIntl(SimulationTableLine);
