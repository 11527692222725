import { InputLabel } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { MapFeature } from '../common/FullMap/types';

interface IProps {
  id: string;
  editFeature: MapFeature;
  updateEditFeature: (id: string, feature?: MapFeature) => void;
}

const DrawForm: FunctionComponent<IProps> = ({
  editFeature,
  id,
  updateEditFeature,
}: IProps) => {
  const { featureInfo } = editFeature;
  const fProps = featureInfo.props || {};
  const { name, type } = fProps;

  const handleChange = (n: string, e: any) => {
    updateEditFeature(id, {
      ...editFeature,
      featureInfo: {
        ...featureInfo,
        props: { ...fProps, [n]: e.target.value },
      },
    } as any);
  };

  return (
    <CardContent>
      <FormControl fullWidth margin="normal">
        <TextField
          label={
            <FormattedMessage
              id="dashboard.forms.drawform.label_name"
              defaultMessage="Name"
            />
          }
          value={name}
          variant="standard"
          onChange={(e: any) => handleChange('name', e)}
        />
      </FormControl>
      {featureInfo.type === 'obstacle' && (
        <FormControl fullWidth variant="standard">
          <InputLabel>
            <FormattedMessage
              id="dashboard.forms.drawform.label_type"
              defaultMessage="Type"
            />
          </InputLabel>
          <Select
            value={type || ''}
            onChange={(e: any) => handleChange('type', e)}
          >
            <MenuItem value="" selected disabled>
              Choose type
            </MenuItem>
            <MenuItem value="wall">Wall</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
      )}
    </CardContent>
  );
};

export default DrawForm;
