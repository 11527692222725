import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ConfigurationFileTypes,
  ICreateConfigFile,
  IFetchFloorplanSensors,
  IUpdateConfigFile,
} from '@actions/index';
import { ConfigFile } from '@models/ConfigFile';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import SensorGroupWithStatus from '@models/SensorGroupWithStatus';
import { Warehouses } from '@models/Warehouse';
import ConfigFileFormContent from './ConfigFileFormContent';
import { IState } from './types';

interface IProps {
  activeSensorGroup: SensorGroupWithStatus | undefined;
  allowEdition: boolean;
  createConfigFile: (properties: ICreateConfigFile) => void;
  fetchSensorGroupsByFloorplan: (properties: IFetchFloorplanSensors) => void;
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => void;
  onClose: () => void;
  updateConfigFile: (properties: IUpdateConfigFile) => void;
  data?: ConfigFile;
  floorplanId?: string;
  floorplans: IncompleteFloorplans;
  warehouseId?: string;
  warehouses: Warehouses;
}

class ConfigFileFormDialog extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { activeSensorGroup, data, floorplanId, warehouseId } = this.props;

    this.state = {
      content: data ? data.content : '',
      filename: data ? data.filename : '',
      floorplanId: data ? data.floorplanId : floorplanId,
      id: data ? data.id : undefined,
      sensorGroupId: data
        ? data.sensorGroupId
        : (activeSensorGroup || ({} as SensorGroupWithStatus)).id,
      warehouseId: data ? data.warehouseId : warehouseId,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    const {
      fetchActiveSensorGroup,
      fetchSensorGroupsByFloorplan,
      floorplanId,
    } = this.props;

    if (floorplanId) {
      fetchActiveSensorGroup({ floorplanId });
      fetchSensorGroupsByFloorplan({ floorplanId });
    }
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      let { floorplanId, sensorGroupId } = this.state;

      if (key === 'warehouseId') {
        floorplanId = undefined;
        sensorGroupId = undefined;
      } else if (key === 'floorplanId') {
        sensorGroupId = undefined;
      }

      this.setState(
        (prevState) => ({
          ...prevState,
          floorplanId,
          sensorGroupId,
          [key]: value,
        }),
        () => {
          if (key === 'floorplanId' && value) {
            const { fetchSensorGroupsByFloorplan } = this.props;

            fetchSensorGroupsByFloorplan({ floorplanId: value });
          }
        }
      );
    };
  }

  public handleCancel() {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }

    this.reset();
  }

  public handleSubmit() {
    const { content, filename, floorplanId, id, sensorGroupId, warehouseId } =
      this.state;
    const { onClose, createConfigFile, updateConfigFile } = this.props;

    if (id === undefined) {
      createConfigFile({
        content,
        filename,
        floorplanId,
        sensorGroupId,
        type: ConfigurationFileTypes.PROCESSING,
        version: 0,
        warehouseId,
      });
    } else {
      updateConfigFile({
        content,
        filename,
        id,
        type: ConfigurationFileTypes.PROCESSING,
      });
    }

    if (onClose) {
      onClose();
    }

    this.reset();
  }

  public reset() {
    const { activeSensorGroup, floorplanId, warehouseId } = this.props;

    this.setState({
      content: '',
      filename: '',
      floorplanId,
      id: undefined,
      sensorGroupId: (activeSensorGroup || ({} as SensorGroupWithStatus)).id,
      warehouseId,
    });
  }

  public render() {
    const { allowEdition, floorplans, warehouses } = this.props;
    const { content, filename, floorplanId, id, sensorGroupId, warehouseId } =
      this.state;

    const filenameError = filename.length === 0;
    const contentError = content.length === 0;

    const buttonDisabled = filenameError || contentError;

    let title = (
      <FormattedMessage
        id="dashboard.forms.configfileform.title_create"
        defaultMessage="Add config file"
      />
    );

    if (allowEdition === false) {
      title = (
        <FormattedMessage
          id="dashboard.forms.configfileform.title_view"
          defaultMessage="View config file {filename}"
          values={{ filename }}
        />
      );
    } else if (id) {
      title = (
        <FormattedMessage
          id="dashboard.forms.configfileform.title_edit"
          defaultMessage="Edit config file {filename}"
          values={{ filename }}
        />
      );
    }

    return (
      <Dialog fullScreen open>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <ConfigFileFormContent
            allowEdition={allowEdition}
            content={content}
            filename={filename}
            floorplanId={floorplanId}
            id={id}
            sensorGroupId={sensorGroupId}
            warehouseId={warehouseId}
            floorplans={floorplans}
            warehouses={warehouses}
            handleChange={this.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            {allowEdition === true ? (
              <FormattedMessage
                id="dashboard.forms.configfileform.button_cancel"
                defaultMessage="Cancel"
              />
            ) : (
              <FormattedMessage
                id="dashboard.forms.configfileform.button_back"
                defaultMessage="Back"
              />
            )}
          </Button>
          {allowEdition === true ? (
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color={id ? 'secondary' : 'primary'}
              disabled={buttonDisabled}
            >
              {id ? (
                <FormattedMessage
                  id="dashboard.forms.configfileform.button_edit"
                  defaultMessage="Edit"
                />
              ) : (
                <FormattedMessage
                  id="dashboard.forms.configfileform.button_create"
                  defaultMessage="Create"
                />
              )}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfigFileFormDialog;
