import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactourStep } from 'reactour';

import Disabler from './Disabler';

export default (
  disableTour: () => void,
  disabledTour: boolean
): ReactourStep[] => [
  {
    content: () => (
      <>
        <Typography variant="subtitle2">
          <FormattedMessage
            id="tutorial.sensors.welcome.title"
            defaultMessage="All physical hardware from the Logidot system can be found here."
          />
        </Typography>
        <br />
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.sensors.welcome.message"
            defaultMessage="From gateway to sensor status, you will find information about it here."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'body',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.sensors.hardwarestatus"
            defaultMessage="Here you can check status and basic information about our gateway."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '.hardware-status',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.sensors.hardwarecontrols"
            defaultMessage="And here you can give commands to the overall setup."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '.hardware-controls',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.sensors.sensorlist"
            defaultMessage="This tab also provides you with the list and basic information about the sensors that are currently collecting information of your assets."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'table',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.sensors.reportsgo"
            defaultMessage="Almost done, let's figure out how to generate Reports"
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'a#reports',
  },
];
