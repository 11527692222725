import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { updateScale } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import { LayerScale } from '../../LayerScale/LayerScale';
import HeatMap from './HeatMap';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    mapImages: map.mapImages,
    paper: map.paper,

    floorplan: getFloorplanById(state, map.floorplanId)!,
    zones: state.zones.zones,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateScale: (id: string, scale?: LayerScale) =>
    dispatch(updateScale(id, scale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeatMap);
