import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { ForkliftTypes } from '@app/dashboard/tabs/FinancialsTab/Calc';
import LoadingButton from '@app/common/LoadingButton';
import { IState } from '../../forms/WarehouseForm/types';
import WarehouseFormContent from '../../forms/WarehouseForm/WarehouseFormContent';

interface IProps {
  intl: IntlShape;
  onNext: (
    intl: IntlShape,
    name: string,
    site: string,
    timezone: string,
    country: string,
    metricsTotalPalletsMoved?: number,
    metricsFleetUsedCapacity?: number,
    numberOfShifts?: number,
    shiftHours?: number,
    workingDays?: number,
    targetForkliftType?: keyof typeof ForkliftTypes
  ) => void;
  name: string;
  site: string;
  timezone: string;
  country: string;
  metricsTotalPalletsMoved?: number;
  metricsFleetUsedCapacity?: number;
  numberOfShifts?: number;
  shiftHours?: number;
  workingDays?: number;
  targetForkliftType?: keyof typeof ForkliftTypes;
  loading: boolean;
}

class CreateOrEditWarehouse extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const {
      name,
      site,
      timezone,
      country,
      metricsTotalPalletsMoved,
      metricsFleetUsedCapacity,
      numberOfShifts,
      shiftHours,
      workingDays,
      targetForkliftType,
    } = props;

    this.state = {
      name,
      site,
      timezone,
      country,
      metricsTotalPalletsMoved,
      metricsFleetUsedCapacity,
      numberOfShifts,
      shiftHours,
      workingDays,
      targetForkliftType,
    };

    this.handleNext = this.handleNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      // timezone is a result of react-select onChange event
      // and therefore a { value: '', label: '' } object
      if (key === 'timezone') {
        this.setState({ timezone: e.value });
      } else {
        const { value, type } = e.target;

        this.setState({
          [key]: type === 'number' && value !== '' ? Number(value) : value,
        } as Pick<IState, 'name'>);
      }
    };
  }

  public handleNext() {
    const {
      name,
      site,
      timezone,
      country,
      metricsTotalPalletsMoved,
      metricsFleetUsedCapacity,
      numberOfShifts,
      shiftHours,
      workingDays,
      targetForkliftType,
    } = this.state;
    const { intl, onNext } = this.props;

    onNext(
      intl,
      name,
      site,
      timezone,
      country,
      metricsTotalPalletsMoved,
      metricsFleetUsedCapacity,
      numberOfShifts,
      shiftHours,
      workingDays,
      targetForkliftType
    );
  }

  public render() {
    const { loading } = this.props;
    const {
      name,
      site,
      timezone,
      country,
      metricsTotalPalletsMoved,
      metricsFleetUsedCapacity,
      numberOfShifts,
      shiftHours,
      workingDays,
      targetForkliftType,
    } = this.state;

    return (
      <Card elevation={0}>
        <CardHeader
          title={
            <FormattedMessage
              id="dashboard.setup.steps.warehouse.title"
              defaultMessage="Create a Warehouse"
            />
          }
        />
        <CardContent>
          <Typography variant="body1">
            <FormattedMessage
              id="dashboard.setup.steps.warehouse.description"
              defaultMessage="Here you will be able to create your first warehouse."
            />
          </Typography>
          <WarehouseFormContent
            {...this.props}
            name={name}
            site={site}
            timezone={timezone}
            country={country}
            metricsTotalPalletsMoved={metricsTotalPalletsMoved}
            metricsFleetUsedCapacity={metricsFleetUsedCapacity}
            numberOfShifts={numberOfShifts}
            shiftHours={shiftHours}
            workingDays={workingDays}
            targetForkliftType={targetForkliftType}
            handleChange={this.handleChange}
          />
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <LoadingButton
            disabled={name === ''}
            variant="contained"
            onClick={this.handleNext}
            color="primary"
            loading={loading}
          >
            <FormattedMessage
              id="dashboard.setup.steps.button_next"
              defaultMessage="Next"
            />
          </LoadingButton>
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(CreateOrEditWarehouse);
