import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import TagSharp from '@mui/icons-material/TagSharp';

import Filters from '@app/common/Filters';
import { TransparentMenuItem, TransparentSelect, MenuChip } from '@app/common/Filters/Filters';
import { IFilter } from '@reducers/filter';
import { cellSize } from '../../../utils';
import TasksTabContainer from './TasksTabContainer';
import { TaskStatus } from '@models/Task';

interface IProps {
  filters: Record<string, IFilter>;
}

interface IProps {
  filters: Record<string, IFilter>;
}
interface IState {
  taskStatusFilters: Record<string, TaskStatus[]>;
}

class TasksTabGrid extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      taskStatusFilters: {},
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.renderActiveStatus = this.renderActiveStatus.bind(this);
  }

  public handleFilterChange(filterId: string, e: any) {
    this.setState({
      taskStatusFilters: {
        ...this.state.taskStatusFilters,
        [filterId]: e.target.value.filter((v: string) => !!v)
      },
    });
  }

  public toCamelCase(str: string){
    return `${(str[0] || '').toUpperCase()}${str.split('').slice(1).join('')}`;
  }

  public renderActiveStatus(selected: any) {
    return selected.map((label: string, index: number) => {
      if (label === '') {
        return (
          <i key="0" style={{ color: '#c1c1c1' }}>
            <FormattedMessage
              id="dashboard.tasks.view.task_type_custom_filter"
              defaultMessage="Task Type"
            />
          </i>
        );
      }

      return (
        <Fragment key={label}>
          <b style={{ color: '#666' }}>{this.toCamelCase(label)}</b>
          {index !== selected.length - 1 ? <span>, </span> : null}
        </Fragment>
      );
    });
  }

  public render() {
    const { filters } = this.props;
    const { taskStatusFilters } = this.state;

    const filterKeys = Object.keys(filters).filter(
      (k) => k !== 'simulator' && k !== 'popup'
    );

    return (
      <Box className="tab-content-inner" p={2}>
        <Grid container spacing={2} style={{ flex: 1 }}>
          {filterKeys.map((filterId, index) => {
            const taskTypeFilter = (
              <Box ml={2} mb={1}>
                <Card>
                  <Grid container wrap="nowrap">
                    <Grid item>
                      <Grid container>
                        <TagSharp
                          style={{
                            margin: '2px 8px',
                            color: '#e1e1e1',
                            marginRight: '8px',
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        <FormControl variant="standard">
                          <TransparentSelect
                            onChange={(e) => this.handleFilterChange(filterId, e)}
                            renderValue={this.renderActiveStatus}
                            value={
                              (taskStatusFilters[filterId] || []).length === 0
                                ? ['']
                                : taskStatusFilters[filterId] || []
                            }
                          >
                            <TransparentMenuItem value="" disabled>
                              <FormattedMessage
                                id="dashboard.tasks.view.task_type_custom_filter"
                                defaultMessage="Task Type"
                              />
                            </TransparentMenuItem>
                            <TransparentMenuItem value="pending">
                              <MenuChip style={{ cursor: 'pointer' }} label="Pending" />
                            </TransparentMenuItem>
                            <TransparentMenuItem value="progress">
                              <MenuChip style={{ cursor: 'pointer' }} label="Progress" />
                            </TransparentMenuItem>
                            <TransparentMenuItem value="done">
                              <MenuChip style={{ cursor: 'pointer' }} label="Done" />
                            </TransparentMenuItem>
                          </TransparentSelect>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            );

            return (
              <Grid
                key={filterId}
                container
                item
                xs={cellSize(filterKeys, index)}
                direction="column"
                flex={1}
                style={{
                  display: 'flex',
                  minHeight: '100%',
                }}
              >
                <Filters filterId={filterId} activeFilters={['employees', 'teams', 'zones']} otherFilters={taskTypeFilter}>
                  <TasksTabContainer filterId={filterId} statusFilter={taskStatusFilters[filterId] || []} />
                </Filters>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }
}

export default TasksTabGrid;

