import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { injectIntl } from 'react-intl';

import { IAppState } from '@reducers/index';
import { fetchROIReport, IFetchROIReport } from '@actions/reports';
import FinancialsTab from './FinancialsTab';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;
  const filter = state.filter.filters[filterId] || {};

  return {
    filterId,
    loading: (state.reports.roiReports[filterId] || {}).loading || false,
    report: (state.reports.roiReports[filterId] || {}).report,
    warehouse: state.warehouses.warehouses[filter.warehouseId || ''],
    floorplan: state.floorplans.floorplans[filter.floorplanId || ''],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchROIReport: (properties: IFetchROIReport) =>
    dispatch(fetchROIReport.request(properties)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FinancialsTab));
