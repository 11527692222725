import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactourStep } from 'reactour';

import Disabler from './Disabler';

export default (
  disableTour: () => void,
  disabledTour: boolean
): ReactourStep[] => [
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.reports.welcome.message"
            defaultMessage="In this page you can generate reports consisting in a PDF document that you can share on any platform."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'body',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.reports.filters"
            defaultMessage="In this section of the form, you will filter the information that will appear on the report."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#filters',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.reports.summary"
            defaultMessage="You can input an customized summary that will appear on the document."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#summary',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.reports.dataperiod"
            defaultMessage="Select the data period that you want to see."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#dataperiod',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.reports.selection"
            defaultMessage="And them select the information to be included, from sensor placement to plots, heat maps and so on."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#floorplan-options',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.reports.generate"
            defaultMessage={
              'Hit "Generate Report" and you will get the resulting PDF file.'
            }
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#generate',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.reports.done"
            defaultMessage="Hope that you enjoy our tour, if you need further help or want to give us feedback or just inquire about our solution, just find the chat support bubble present in any page."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'body',
  },
];
