import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closePreCheckForm,
  createPreCheck,
  editPreCheck,
  ICreatePreCheck,
  IEditPreCheck,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { isPreCheckFormLoading } from '@selectors/prechecks';
import PreCheckForm from './PreCheckForm';

const mapStateToProps = (state: IAppState) => ({
  data: state.prechecks.form.data,
  loading: isPreCheckFormLoading(state.prechecks),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closePreCheckForm: () => dispatch(closePreCheckForm()),
  createPreCheck: (properties: ICreatePreCheck) =>
    dispatch(createPreCheck.request(properties)),
  editPreCheck: (properties: IEditPreCheck) =>
    dispatch(editPreCheck.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreCheckForm);
