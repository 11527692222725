import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import {
  fetchFloorplanSensorGroupStatus,
  IFetchFloorplanSensors,
  updateFloorplan,
} from '@actions/index';
import { SensorTypes } from '@models/Sensor';
import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import {
  getFloorplanSensorsByType,
  getFloorplanSensorGroupWithStatus,
  isSensorByFloorplanLoading,
} from '@selectors/sensors';
import { TransformationMatrix2D } from '../../../utils';
import StepContainer from './StepContainer';

interface IProps {
  floorplanId: string;
  onNext: () => void;
  warehouseId: string;
}

const mapStateToProps = (state: IAppState, { floorplanId }: IProps) => ({
  activeSensorGroup: getFloorplanSensorGroupWithStatus(
    state,
    floorplanId || ''
  ),
  floorplan: state.floorplans.floorplans[floorplanId],
  loading: isSensorByFloorplanLoading(state, floorplanId),
  measurementUnits: getAccountMeasurementUnits(state.authentication),
  sensors: getFloorplanSensorsByType(
    state,
    SensorTypes.STATIONARY,
    floorplanId
  ),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { floorplanId, onNext, warehouseId }: IProps
) => ({
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => {
    dispatch(fetchFloorplanSensorGroupStatus.request(properties));
  },
  onNext: (transformationMatrix: TransformationMatrix2D, intl: IntlShape) => {
    dispatch(
      updateFloorplan.request({
        floorplanId,
        intl,
        properties: {
          transformationMatrix,
        },
        warehouseId,
      })
    );
    onNext();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StepContainer);
