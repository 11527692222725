import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import React, { FunctionComponent } from 'react';
import { WidgetProps } from 'react-jsonschema-form';

const handleChange = (onChange: (value: any) => void) => (event: any) =>
  onChange(event.target.value);

const TextWidget: FunctionComponent<WidgetProps> = (
  properties: WidgetProps
) => {
  const {
    schema: { type, description },
    // @ts-ignore This property is not always passed, see: https://github.com/mozilla-services/react-jsonschema-form/blob/master/src/components/fields/StringField.js#L43
    label,
    required,
    value,
    onChange,
    // @ts-ignore TypeScript types to do not include `rawErrors`, but they exist, see: https://github.com/mozilla-services/react-jsonschema-form/blob/master/src/components/widgets/BaseInput.js#L22
    rawErrors = [],
  } = properties;

  return (
    <FormControl
      required={required}
      fullWidth
      margin="normal"
      error={rawErrors.length > 0}
    >
      <InputLabel>{label}</InputLabel>
      <Input
        value={value || ''}
        onChange={handleChange(onChange)}
        type={type as string | undefined}
      />
      {description ? <FormHelperText>{description}</FormHelperText> : null}
      {rawErrors.map((error: string) => (
        <FormHelperText key={error} error>
          {error}
        </FormHelperText>
      ))}
    </FormControl>
  );
};

export default TextWidget;
