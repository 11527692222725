import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { take } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IFetchFloorplanSensors } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import { defaultTransformationMatrix } from '@app/common/FullMap/consts';
import { Date } from '@dashboard_utils/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Sensor from '@models/Sensor';
import SensorGroupWithStatus from '@models/SensorGroupWithStatus';
import {
  convertTo3x3Matrix,
  Coordinate2D,
  calculateAffineTransformMatrix,
  Coordinate2DMatrix,
  TransformationMatrix2D,
} from '../../../utils';
import SensorAlignmentForm from '../../forms/FloorplanConfigurationForm/SensorAlignment/SensorAlignmentForm';

interface IProps {
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => void;
  activeSensorGroup: SensorGroupWithStatus | undefined;
  floorplan: IncompleteFloorplan;
  loading: boolean;
  onBack: () => void;
  onNext: (transformationMatrix: TransformationMatrix2D, intl: IntlShape) => void;
  intl: IntlShape;
  sensors: Sensor[];
}

interface IState {
  initialPoints?: Coordinate2DMatrix;
  points?: number[][];
  sensorPositions?: number[][];
}

const RANDOM_LOCATION = [Date.now(), Date.now()];

class StepContainer extends Component<IProps, IState> {
  public static getDerivedStateFromProps(props: IProps, state: IState): IState {
    const { activeSensorGroup, sensors } = props;
    let { initialPoints, sensorPositions } = state;

    const activeSensorGroupId = (
      activeSensorGroup || ({} as SensorGroupWithStatus)
    ).id;

    const activeSensors = sensors.filter(
      (s) => !!s.position && s.sensorGroupId === activeSensorGroupId
    );

    if (activeSensors.length > 1) {
      sensorPositions = take(
        activeSensors.map((s) => [s.position![0], s.position![1]]),
        3
      );

      while (sensorPositions.length < 3) {
        sensorPositions.push(RANDOM_LOCATION);
      }

      initialPoints = convertTo3x3Matrix(
        sensorPositions as [Coordinate2D, Coordinate2D, Coordinate2D]
      );
    }

    return {
      ...state,
      initialPoints,
      sensorPositions,
    };
  }

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.aliningChange = this.aliningChange.bind(this);
    this.onNext = this.onNext.bind(this);
  }

  public componentDidMount() {
    const { fetchActiveSensorGroup, floorplan } = this.props;

    fetchActiveSensorGroup({ floorplanId: floorplan.id });
  }

  public componentDidUpdate(prevProps: any) {
    const { fetchActiveSensorGroup, floorplan } = this.props;

    if (prevProps.floorplan.id !== floorplan.id) {
      fetchActiveSensorGroup({ floorplanId: floorplan.id });
    }
  }

  public onNext() {
    const { points, initialPoints } = this.state;
    const { floorplan, intl, onNext } = this.props;

    let newTransformationMatrix =
      floorplan.transformationMatrix || defaultTransformationMatrix;

    if (points && initialPoints) {
      newTransformationMatrix = calculateAffineTransformMatrix(
        initialPoints,
        points
      ) as TransformationMatrix2D;
    }

    onNext(newTransformationMatrix, intl);
  }

  public aliningChange(points: number[][]) {
    const { sensorPositions } = this.state;

    if (sensorPositions) {
      while (points.length < 3) {
        points.push([
          points[0][0] - sensorPositions[0][0] + RANDOM_LOCATION[0],
          points[0][1] - sensorPositions[0][1] + RANDOM_LOCATION[1],
        ]);
      }
    }

    this.setState({
      points: convertTo3x3Matrix(
        take(points, 3) as [Coordinate2D, Coordinate2D, Coordinate2D]
      ),
    });
  }

  public render() {
    const { floorplan, onBack, loading } = this.props;

    return (
      <Card id="alignment-setup" className="step-card">
        <CardContent style={{ flex: 1, position: 'relative' }}>
          <SensorAlignmentForm
            aliningChange={this.aliningChange}
            floorplanId={floorplan.id}
          />
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Box mr={1}>
            <Button onClick={onBack}>
              <FormattedMessage
                id="dashboard.setup.steps.button_back"
                defaultMessage="Back"
              />
            </Button>
          </Box>
          <Box mr={1}>
            <LoadingButton
              variant="contained"
              onClick={this.onNext}
              color="primary"
              loading={loading}
            >
              <FormattedMessage
                id="dashboard.setup.steps.button_next"
                defaultMessage="Next"
              />
            </LoadingButton>
          </Box>
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(StepContainer);
