import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { FunctionComponent } from 'react';

import Filters from '@app/common/Filters';
import { IFilter } from '@reducers/filter';
import { cellSize } from '../../../utils';
import FinancialsTabContainer from './FinancialsTabContainer';

interface IProps {
  filters: Record<string, IFilter>;
}

const FinantialsTabGrid: FunctionComponent<IProps> = ({ filters }: IProps) => {
  const filterKeys = Object.keys(filters).filter(
    (k) => k !== 'simulator' && k !== 'popup'
  );

  return (
    <Box className="tab-content-inner" p={2}>
      <Grid container spacing={2} flex={1}>
        {filterKeys.map((filterId, index) => (
          <Grid
            key={filterId}
            container
            item
            xs={cellSize(filterKeys, index)}
            direction="column"
            flex={1}
            style={{
              display: 'flex',
              height: '100%',
            }}
          >
            <Filters filterId={filterId}>
              <FinancialsTabContainer filterId={filterId} />
            </Filters>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FinantialsTabGrid;
