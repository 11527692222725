import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { createRoutingPaths } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import SimulatorRouting from './SimulatorRouting';
import { getFloorplanSensorsByType } from '@selectors/sensors';
import { SensorTypes } from '@models/Sensor';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    mapImages: map.mapImages,
    paper: map.paper,

    floorplan: getFloorplanById(state, map.floorplanId)!,

    assets: Object.values(state.assets.assets).filter(
      (a) => a.floorplanId === map.floorplanId
    ),
    beacons: getFloorplanSensorsByType(
      state,
      SensorTypes.BEACON,
      map.floorplanId
    ).filter((s) => s.position !== undefined && s.position !== null),

    loading: ((state.simulations.routing[map.floorplanId || ''] || {}).paths || {}).loading,
    data: ((state.simulations.routing[map.floorplanId || ''] || {}).paths || {}).data || { path: [], orders: [] },
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createRoutingPaths: (floorplanId: string, position: [number, number], points: [number, number][]) =>
    dispatch(createRoutingPaths.request({ floorplanId, position, points })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SimulatorRouting);
