import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { confirmDialog } from '@actions/app';
import { deleteDiagram, IDeleteDiagram } from '@actions/diagrams';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import DiagramTab from './DiagramTab';

const mapStateToProps = (state: IAppState) => ({
  diagrams: Object.values(state.diagrams.diagrams),
  language: getActiveLanguage(state),
  loading: state.diagrams.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  deleteDiagram: (properties: IDeleteDiagram) =>
    dispatch(deleteDiagram.request(properties)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DiagramTab)
);
