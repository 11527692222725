import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PersonIcon from '@mui/icons-material/Person';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RouterIcon from '@mui/icons-material/Router';
import SettingsIcon from '@mui/icons-material/SettingsSharp';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import React, { PureComponent, lazy, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import ws from '@api/websocket';

import { ReconnectingStates } from '@actions/index';
import Loading from '@app/common/Loading';
import { PRIMARY_COLOR } from '@app/utils/colors';
import Account from '@models/Account';
import { hasPermission } from '@selectors/accounts';
import Chat from './Chat';
import ErrorCard from '../ErrorCard';
import Tabs from './Tabs';
import Navbar from '../Navbar';
import ModeSelector from '../Navbar/ModeSelector';
import Menu from '../Menu';
import { TabType } from '../Menu/Menu';
import shortcutsState, { updateShortCuts } from '../Menu/shortcuts';
import ShortCutMenu from '../ShortCutMenu';
import ShallowLoading from '../../ShallowLoading';
import { SupporttedLanguages } from '../../../types';

import './Dashboard.css';
import AccountMeta from '@models/AccountMeta';

const Setup = lazy(() => import('../../setup/SetupContainer'));
const OperatorTab = lazy(() => import('../tabs/OperatorTab'));

const liveData = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <MyLocationIcon />,
  id: 'live',
  link: `/${language}/dashboard/live/`,
  path: '/live',
  title: (
    <FormattedMessage id="dashboard.itab.live_data" defaultMessage="Live Map" />
  ),
  type: TabType.APPLICATIONS,
});
const liveRawData = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <EditLocationIcon />,
  id: 'rawlive',
  link: `/${language}/dashboard/rawlive/`,
  path: '/rawlive',
  title: (
    <FormattedMessage
      id="dashboard.itab.raw_live_data"
      defaultMessage="Raw Live Map"
    />
  ),
  type: TabType.MANAGER,
});
const analyticsTab = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <ShowChartIcon />,
  id: 'analytics',
  link: `/${language}/dashboard/analytics/`,
  path: '/analytics/',
  title: (
    <FormattedMessage
      id="dashboard.itab.analytics"
      defaultMessage="Analytics"
    />
  ),
  type: TabType.APPLICATIONS,
});
const diagnostics = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <DonutSmallIcon />,
  id: 'diagnostics',
  link: `/${language}/dashboard/diagnostics/`,
  path: '/diagnostics/',
  title: (
    <FormattedMessage
      id="dashboard.itab.diagnostics"
      defaultMessage="Diagnostics"
    />
  ),
  type: TabType.MANAGER,
});
const diagrams = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <AccountTreeIcon />,
  id: 'diagrams',
  link: `/${language}/dashboard/diagrams/`,
  path: '/diagrams/*',
  title: <span>Diagrams</span>,
  type: TabType.UTILS,
});
const financials = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <AttachMoneyIcon />,
  id: 'financials',
  link: `/${language}/dashboard/financials/`,
  path: '/financials/',
  title: <span>Financials</span>,
  type: TabType.UTILS,
});
const alerts = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <AnnouncementIcon />,
  id: 'alerts',
  link: `/${language}/dashboard/alerts/`,
  path: '/alerts/',
  title: (
    <FormattedMessage id="dashboard.itab.alerts" defaultMessage="Alerts" />
  ),
  type: TabType.MANAGER,
});
const player = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <PlayCircleOutlineIcon />,
  id: 'player',
  link: `/${language}/dashboard/player/`,
  path: '/player/',
  title: (
    <FormattedMessage id="dashboard.itab.player" defaultMessage="Player" />
  ),
  type: TabType.APPLICATIONS,
});
const rules = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <FormatListNumberedIcon />,
  id: 'rules',
  link: `/${language}/dashboard/rules/`,
  path: '/rules/',
  title: (
    <FormattedMessage
      id="dashboard.itab.rules"
      defaultMessage="Zones & Rules"
    />
  ),
  type: TabType.UTILS,
});
const assets = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <DeviceHubIcon />,
  id: 'assets',
  link: `/${language}/dashboard/assets/`,
  path: '/assets/',
  title: (
    <FormattedMessage id="dashboard.itab.assets" defaultMessage="Assets" />
  ),
  type: TabType.APPLICATIONS,
});
const employees = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <PersonIcon />,
  id: 'employees',
  link: `/${language}/dashboard/employees/`,
  path: '/employees/',
  title: (
    <FormattedMessage
      id="dashboard.itab.employees"
      defaultMessage="Employees"
    />
  ),
  type: TabType.APPLICATIONS,
});
const items = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <AllInboxIcon />,
  id: 'items',
  link: `/${language}/dashboard/items/`,
  path: '/items/*',
  title: (
    <FormattedMessage
      id="dashboard.itab.items"
      defaultMessage="Items / Locations"
    />
  ),
  type: TabType.MANAGER,
});
const orders = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <AssignmentIcon />,
  id: 'orders',
  link: `/${language}/dashboard/orders/`,
  path: '/orders/',
  title: (
    <FormattedMessage id="dashboard.itab.orders" defaultMessage="Orders" />
  ),
  type: TabType.APPLICATIONS,
});
const sensors = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <RouterIcon />,
  id: 'sensors',
  link: `/${language}/dashboard/sensors/`,
  path: '/sensors/',
  title: (
    <FormattedMessage id="dashboard.itab.sensors" defaultMessage="Sensors" />
  ),
  type: TabType.MANAGER,
});
const simulations = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <InsertChartIcon />,
  id: 'simulations',
  link: `/${language}/dashboard/simulations/`,
  path: '/simulations/*',
  title: (
    <FormattedMessage
      id="dashboard.itab.simulations"
      defaultMessage="Simulations"
    />
  ),
  type: TabType.APPLICATIONS,
});
const routing = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <ForkLeftIcon />,
  id: 'routing',
  link: `/${language}/dashboard/routing/`,
  path: '/routing/*',
  title: (
    <FormattedMessage
      id="dashboard.itab.routing"
      defaultMessage="Routing"
    />
  ),
  type: TabType.APPLICATIONS,
});
const oldRouting = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <ForkLeftIcon />,
  id: 'old-routing',
  link: `/${language}/dashboard/old-routing/`,
  path: '/old-routing/*',
  title: (
    <FormattedMessage
      id="dashboard.itab.oldrouting"
      defaultMessage="Old Routing"
    />
  ),
  type: TabType.APPLICATIONS,
});
const reports = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <InsertDriveFileIcon />,
  id: 'reports',
  link: `/${language}/dashboard/reports/`,
  path: '/reports/',
  title: (
    <FormattedMessage id="dashboard.itab.reports" defaultMessage="Reports" />
  ),
  type: TabType.UTILS,
});
const settings = (language: SupporttedLanguages) => ({
  disabled: false,
  shortcutColor: PRIMARY_COLOR,
  icon: <SettingsIcon />,
  id: 'settings',
  link: `/${language}/dashboard/settings/`,
  path: '/settings/',
  title: (
    <FormattedMessage id="dashboard.itab.settings" defaultMessage="Settings" />
  ),
  type: TabType.UTILS,
});
const tickets = (language: SupporttedLanguages) => ({
  disabled: false,
  shortcutColor: PRIMARY_COLOR,
  icon: <ContactSupportIcon />,
  id: 'tickets',
  link: `/${language}/dashboard/tickets/`,
  path: '/tickets/',
  title: (
    <FormattedMessage
      id="dashboard.itab.tickets"
      defaultMessage="Tickets & Support"
    />
  ),
  type: TabType.HELP,
});
const rtlsPlanner = (language: SupporttedLanguages) => ({
  disabled: false,
  icon: <NetworkCheckIcon />,
  id: 'rtlsplanner',
  link: `/${language}/dashboard/rtlsplanner/`,
  path: '/rtlsplanner/*',
  title: (
    <FormattedMessage
      id="dashboard.itab.rtlsplanner"
      defaultMessage="RTLS Planner"
    />
  ),
  type: TabType.MANAGER,
});

interface IProps {
  account?: Account;
  accounts: AccountMeta[];
  fetchAccount: () => void;
  firstWarehouseFetch: boolean;
  error?: object;
  language: SupporttedLanguages;
  loading: boolean;
  reconnecting?: ReconnectingStates;
  setupNeeded: boolean;
  modeSelector: boolean;
}

interface IState {
  shortcuts: Record<string, boolean>;
  activeChat: boolean;
}

class Dashboard extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      shortcuts: shortcutsState,
      activeChat: false,
    };

    this.updateShortcut = this.updateShortcut.bind(this);
    this.readGPSData = this.readGPSData.bind(this);
    this.toggleChat = this.toggleChat.bind(this);
  }

  public componentDidMount() {
    const { account, fetchAccount } = this.props;

    fetchAccount();

    if (account && account.organisationId) {
      // @ts-ignore
      if (window.MessageInvoker) {
        // @ts-ignore
        window.MessageInvoker.postMessage('gps');
      }
    }

    window.addEventListener('native_location', this.readGPSData);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('native_location', this.readGPSData);
  }

  public readGPSData(e: any) {
    const { account } = this.props;
  
    const sensorId = localStorage.getItem(`mobile_sensor_${account?.organisationId || 0}`);
    if (e && e.detail && sensorId) {
      ws.sendMobileDataPoint({ sensorId, ...e.detail})
    }
  }

  private getAccountTabs(account?: Account) {
    const { language } = this.props;

    const tabs = [];
    tabs.push(liveData(language));

    if (account && hasPermission(account, 'diagnostics')) {
      tabs.push(liveRawData(language));
    }

    tabs.push(analyticsTab(language));

    if (account && hasPermission(account, 'diagnostics')) {
      tabs.push(diagnostics(language));
      tabs.push(rtlsPlanner(language));
    }

    tabs.push(diagrams(language));
    tabs.push(financials(language));
    tabs.push(alerts(language));
    tabs.push(player(language));
    tabs.push(rules(language));
    tabs.push(assets(language));
    tabs.push(employees(language));
    tabs.push(sensors(language));
    tabs.push(simulations(language));
    tabs.push(routing(language));
    tabs.push(oldRouting(language));
    tabs.push(items(language));
    tabs.push(orders(language));
    tabs.push(reports(language));

    if (account && hasPermission(account, 'settings')) {
      tabs.push(settings(language));
    }
    tabs.push(tickets(language));

    return tabs;
  }

  public updateShortcut(id: string) {
    const { shortcuts } = this.state;

    const data = { ...shortcuts, [id]: !shortcuts[id] };

    this.setState({ shortcuts: data }, () => updateShortCuts(data));
  }

  public toggleChat() {
    const { activeChat } = this.state;

    this.setState({ activeChat: !activeChat });
  }

  public render() {
    const {
      account,
      accounts,
      error,
      firstWarehouseFetch,
      setupNeeded,
      language,
      loading,
      modeSelector,
    } = this.props;
    const { shortcuts, activeChat } = this.state;

    let content;
    if (error) {
      content = (
        <div style={{ margin: '0px auto' }}>
          <ErrorCard />
        </div>
      );
    } else if ((account && !account.organisationId) || modeSelector) {
      content = <ModeSelector />;
    } else {
      const tabs = this.getAccountTabs(account);
      content = (
        <Loading loading={!firstWarehouseFetch}>
          {setupNeeded ? (
            <Suspense fallback={<Loading loading />}>
              <Setup />
            </Suspense>
          ) : (
            <>
              <div id="back-to-top-anchor" />
              <Navbar floating={!!account?.organisations[account.organisationId || '0'].operator} />
              <Menu
                tabs={tabs}
                shortcuts={shortcuts}
                updateShortcut={this.updateShortcut}
                activeChat={activeChat}
                toggleChat={this.toggleChat}
              />
              {
                account?.organisations[account.organisationId || '0'].operator
                ? <OperatorTab />
                : <Paper className="dashboard-wrapper">
                  {account && (
                    <ShortCutMenu
                      account={account}
                      accounts={accounts}
                      language={language}
                      tabs={tabs}
                      shortcuts={shortcuts}
                      activeChat={activeChat}
                      toggleChat={this.toggleChat}
                    />
                  )}
                  <Box className="tab-content">
                    <Loading loading={loading}>
                      <ShallowLoading />
                      <Suspense fallback={<Loading loading />}>
                        <Tabs language={language} tabs={tabs} />
                      </Suspense>
                    </Loading>
                  </Box>
                  {activeChat ? (<Chat toggleChat={this.toggleChat} />) : null}
                </Paper>
              }
            </>
          )}
        </Loading>
      );
    }

    return content;
  }
}

export default Dashboard;
