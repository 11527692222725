import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IFetchFloorplanSensors, IUpdateFloorplan } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { IDrawDefinition } from '@models/types';
import DrawDefinitionForm from './DrawDefinitionForm';

interface IProps {
  draws?: IDrawDefinition[];
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => void;
  floorplan: IncompleteFloorplan;
  intl: IntlShape;
  loading: boolean;
  minNumberFeatures?: number;
  type: string;
  updateFloorplan: (properties: IUpdateFloorplan) => any;
}

interface IState {
  drawings: IDrawDefinition[];
  hasChanges: boolean;
}

class DrawDefinition extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      drawings: props.draws || [],
      hasChanges: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDrawChange = this.handleDrawChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  public componentDidMount() {
    const { fetchActiveSensorGroup, floorplan } = this.props;

    fetchActiveSensorGroup({ floorplanId: floorplan.id });
  }

  public componentDidUpdate(prevProps: any) {
    const { fetchActiveSensorGroup, floorplan } = this.props;

    if (JSON.stringify(prevProps.floorplan) !== JSON.stringify(floorplan)) {
      fetchActiveSensorGroup({ floorplanId: floorplan.id });
    }
  }

  public handleSubmit() {
    const { floorplan, intl, type, updateFloorplan } = this.props;
    const { drawings } = this.state;

    let properties: any = { racks: drawings };
    if (type === 'exteriorBoundaries') {
      properties = { exteriorBoundaries: drawings };
    } else if (type === 'boundaries') {
      properties = { boundaries: drawings };
    } else if (type === 'obstacles') {
      properties = { obstacles: drawings };
    } else if (type === 'landmarks') {
      properties = { landmarks: drawings };
    }

    this.setState({ hasChanges: false }, () =>
      updateFloorplan({
        floorplanId: floorplan.id,
        intl,
        properties,
        warehouseId: floorplan.warehouseId,
      })
    );
  }

  public handleDrawChange(drawings: IDrawDefinition[]) {
    this.setState({ drawings, hasChanges: true });
  }

  public onChange() {
    this.setState({ hasChanges: true });
  }

  public render() {
    const { draws, floorplan, loading, minNumberFeatures, type } = this.props;
    const { drawings, hasChanges } = this.state;

    return (
      <Card className="step-card">
        <CardContent style={{ flex: 1, position: 'relative' }}>
          <DrawDefinitionForm
            drawings={drawings}
            floorplan={floorplan}
            handleDrawChange={this.handleDrawChange}
            type={type}
          />
        </CardContent>
        <CardActions
          style={{ borderTop: '1px solid #e1e1e1', justifyContent: 'flex-end' }}
        >
          {!draws && drawings.length === 0 && (
            <LoadingButton
              loading={loading}
              onClick={this.handleSubmit}
              color="primary"
              variant="contained"
            >
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.button_skip"
                defaultMessage="Skip"
              />
            </LoadingButton>
          )}
          <LoadingButton
            loading={loading}
            disabled={!hasChanges || drawings.length < minNumberFeatures!}
            onClick={this.handleSubmit}
            color="secondary"
            variant="contained"
          >
            <FormattedMessage
              id="dashboard.forms.floorplanconfigurationform.button_save"
              defaultMessage="Save Changes"
            />
          </LoadingButton>
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(DrawDefinition);
