import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { updateSelectedFeature as updateFeature } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import { MapFeature } from '../../../types';
import ItemLocationMap from './ItemLocationMap';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    mapImages: map.mapImages,
    paper: map.paper,

    floorplan: getFloorplanById(state, map.floorplanId)!,  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelectedFeature: (id: string, feature: MapFeature) =>
    dispatch(updateFeature(id, feature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemLocationMap);
