import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import LoadingButton from '@app/common/LoadingButton/index';
import FetchError from '@models/FetchError';
import { Box, Grid } from '@mui/material';

interface IProps {
  language: string;
  loading: boolean;
  signUp: (
    nam: string,
    company: string,
    email: string,
    password: string
  ) => void;
  error?: FetchError;
  signUpSuccess: boolean;
}

interface IState {
  name: string;
  company: string;
  email: string;
  submitted: boolean;
  error?: FetchError;
  password: string;
}

class SignUpPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      company: '',
      email: '',
      name: '',
      password: '',
      submitted: false,
    };

    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { error } = this.props;

    if (prevProps.error !== error && error) {
      this.showError(error!);
    }
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'name'>);
    };
  }

  public handleSubmit() {
    const { name, company, email, password } = this.state;
    const { signUp } = this.props;

    if (
      name.length === 0 ||
      password.length === 0 ||
      email.length === 0 ||
      company.length === 0
    ) {
      this.setState({ submitted: true });

      return;
    }

    signUp(name, company, email, password);
  }

  public showError(error: FetchError) {
    this.setState({ error });
  }

  public closeSnackbar() {
    this.setState({ error: undefined });
  }

  public render() {
    const { error, name, password, email, company, submitted } = this.state;
    const { language, loading, signUpSuccess } = this.props;

    const title = (
      <FormattedMessage
        id="external.signuppage.title"
        defaultMessage="Sign Up"
      />
    );

    return (
      <>
        <div id="banner">
          <video muted autoPlay loop poster="/assets/background.jpg">
            <source src="/assets/banner.mp4" type="video/mp4" />
            <track kind="captions" />
          </video>
        </div>

        <Grid container justifyContent="center" style={{ zIndex: 1 }}>
          <Grid item sm={4}>
            <Box mt={12} ml={2} mr={2} mb={2}>
              <Card className="container">
                <CardHeader title={title} />
                {signUpSuccess ? (
                  <CardContent>
                    <Typography variant="body1">
                      <FormattedMessage
                        id="external.signuppage.success_msg"
                        defaultMessage="Congratulations! You just sign up. Please check your mail for our verification code."
                      />
                    </Typography>
                    <Box mt={2}>
                      <Typography variant="body1">
                        <Link to={`/${language}/login/`}>
                          <FormattedMessage
                            id="external.signuppage.alreadyhasaccount_login"
                            defaultMessage="Log in"
                          />
                        </Link>
                      </Typography>
                    </Box>
                  </CardContent>
                ) : (
                  <>
                    <CardContent>
                      <FormControl
                        required
                        fullWidth
                        margin="normal"
                        error={submitted === true && name.length === 0}
                      >
                        <TextField
                          label={
                            <FormattedMessage
                              id="external.signuppage.label_name"
                              defaultMessage="Name"
                            />
                          }
                          name="name"
                          onChange={this.handleChange('name')}
                          value={name}
                          variant="standard"
                          required
                        />
                        {submitted === true && name.length === 0 ? (
                          <FormHelperText error>
                            <FormattedMessage
                              id="external.signuppage.field_name_helper"
                              defaultMessage="An account name must be specified"
                            />
                          </FormHelperText>
                        ) : null}
                      </FormControl>

                      <FormControl
                        required
                        fullWidth
                        margin="normal"
                        error={submitted === true && company.length === 0}
                      >
                        <TextField
                          label={
                            <FormattedMessage
                              id="external.signuppage.label_company"
                              defaultMessage="Company"
                            />
                          }
                          name="company"
                          onChange={this.handleChange('company')}
                          value={company}
                          variant="standard"
                          required
                        />
                        {submitted === true && company.length === 0 ? (
                          <FormHelperText error>
                            <FormattedMessage
                              id="external.signuppage.field_helper"
                              defaultMessage="A company name must be specified"
                            />
                          </FormHelperText>
                        ) : null}
                      </FormControl>

                      <FormControl
                        required
                        fullWidth
                        margin="normal"
                        error={submitted === true && email.length === 0}
                      >
                        <TextField
                          label={
                            <FormattedMessage
                              id="external.signuppage.label_email"
                              defaultMessage="Email"
                            />
                          }
                          name="email"
                          onChange={this.handleChange('email')}
                          value={email}
                          variant="standard"
                          required
                        />
                        {submitted === true && email.length === 0 ? (
                          <FormHelperText error>
                            <FormattedMessage
                              id="external.signuppage.field_email_helper"
                              defaultMessage="An email must be specified"
                            />
                          </FormHelperText>
                        ) : null}
                      </FormControl>

                      <FormControl
                        required
                        fullWidth
                        margin="normal"
                        error={submitted === true && password.length === 0}
                      >
                        <TextField
                          label={
                            <FormattedMessage
                              id="external.signuppage.label_password"
                              defaultMessage="Password"
                            />
                          }
                          type="password"
                          name="password"
                          onChange={this.handleChange('password')}
                          value={password}
                          variant="standard"
                          required
                        />
                        {submitted === true && password.length === 0 ? (
                          <FormHelperText error>
                            <FormattedMessage
                              id="external.signuppage.field_password_helper"
                              defaultMessage="A password must be specified"
                            />
                          </FormHelperText>
                        ) : null}
                      </FormControl>

                      <Box mt={2}>
                        <LoadingButton
                          onClick={this.handleSubmit}
                          variant="contained"
                          color="primary"
                          loading={loading}
                          fullWidth
                        >
                          <FormattedMessage
                            id="external.signuppage.button_submit"
                            defaultMessage="Create New Account"
                          />
                        </LoadingButton>
                      </Box>

                      <Box mt={2}>
                        <Typography variant="body1">
                          <FormattedMessage
                            id="external.signuppage.alreadyhasaccount_msg"
                            defaultMessage="Already have an account?"
                          />
                          <span> </span>
                          <Link to={`/${language}/login/`}>
                            <FormattedMessage
                              id="external.signuppage.alreadyhasaccount_login"
                              defaultMessage="Log in"
                            />
                          </Link>
                        </Typography>
                      </Box>
                    </CardContent>
                  </>
                )}
              </Card>
            </Box>
          </Grid>
        </Grid>

        <Snackbar
          open={!!error}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={this.closeSnackbar}
          autoHideDuration={3000}
          message={<span>{(error || ({} as FetchError)).errorMsg}</span>}
        />
      </>
    );
  }
}

export default SignUpPage;
