import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { Component } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import Loading from '@app/common/Loading';
import { findWarehouseByFloorplanId } from '@app/utils/floorplanUtils';
import MUITable, { ITableColumn } from '@app/common/Table';
import { Date, timeZone } from '@dashboard_utils/index';
import CompleteAsset from '@models/CompleteAsset';
import Maintenance from '@models/Maintenance';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import { Warehouses } from '@models/Warehouse';
import { WarehouseWithFloorplans } from '@models/WarehouseWithFloorplans';
import { messages as mMessages } from '../../forms/Maintenance/Maintenance';

const messages = defineMessages({
  thMaintenanceAccountName: {
    defaultMessage: 'Operator',
    id: 'dashboard.assets.management.maintenance.table.accountname',
  },
  thMaintenanceCreated: {
    defaultMessage: 'Created',
    id: 'dashboard.assets.management.maintenance.table.created',
  },
  thMaintenanceObservations: {
    defaultMessage: 'Observations',
    id: 'dashboard.assets.management.maintenance.table.observations',
  },
  thMaintenanceType: {
    defaultMessage: 'Type',
    id: 'dashboard.assets.management.maintenance.table.type',
  },
});

interface IProps {
  asset: CompleteAsset;
  fetchMaintenancesByAsset: (assetId: string) => void;
  intl: IntlShape;
  maintenances: Maintenance[];
  loading: boolean;
  floorplans: IncompleteFloorplans;
  warehouses: Warehouses;
}
interface IState {
  columns: ITableColumn[];
}

class Maintenances extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      columns: [
        {
          label: intl.formatMessage(messages.thMaintenanceCreated),
          name: 'created',
        },
        {
          label: intl.formatMessage(messages.thMaintenanceAccountName),
          name: 'accountName',
        },
        { label: intl.formatMessage(messages.thMaintenanceType), name: 'type' },
        {
          label: intl.formatMessage(messages.thMaintenanceObservations),
          name: 'observations',
        },
      ],
    };

    this.customMaintenanceRowRender =
      this.customMaintenanceRowRender.bind(this);
  }

  public componentDidMount() {
    const { asset, fetchMaintenancesByAsset } = this.props;

    fetchMaintenancesByAsset(asset.id);
  }

  public customMaintenanceRowRender(
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    const { asset, intl, floorplans, warehouses } = this.props;

    const warehouse =
      findWarehouseByFloorplanId(warehouses, floorplans, asset.floorplanId) ||
      ({} as WarehouseWithFloorplans);
    const warehouseTz = warehouse.timezone || timeZone;

    const getMaintenanceType = (type: string) =>
      // @ts-ignore
      intl.formatMessage(mMessages[`maintenanceTypes_${type}`]);

    return (
      <TableRow key={rowIndex}>
        {columns[0] && (
          <TableCell>{new Date(warehouseTz, rowData[0]).format()}</TableCell>
        )}
        {columns[1] && <TableCell>{rowData[1]}</TableCell>}
        {columns[2] && <TableCell>{getMaintenanceType(rowData[2])}</TableCell>}
        {columns[3] && <TableCell>{rowData[3]}</TableCell>}
      </TableRow>
    );
  }

  public render() {
    const { maintenances, loading } = this.props;
    const { columns } = this.state;

    return (
      <Loading loading={loading}>
        <MUITable
          columns={columns}
          customRowRender={this.customMaintenanceRowRender}
          data={maintenances}
          tableId="asset-maintenances"
        />
      </Loading>
    );
  }
}

export default injectIntl(Maintenances);
