import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchFloorplanSensors,
  IUpdateFloorplan,
  updateFloorplan,
} from '@actions/index';
import axios from '@api/axios';
import { fakeSensors } from '@datasets/sensors';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { IAppState } from '@reducers/index';
import SensorInstallation from './SensorInstallation';

interface IProps {
  floorplan: IncompleteFloorplan;
  installationComplete: boolean;
  loading: boolean;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
}

const mapStateToProps = (state: IAppState, { floorplan }: IProps) => ({
  floorplanId: floorplan.id,
  image: floorplan.image!,
});

const mapDispatchToProps = (dispatch: Dispatch, { floorplan }: IProps) => ({
  // FIXME: Debug only
  createFakeSensors: async () => {
    const {
      data: { id: sensorGroupId },
    } = await axios.post('/sensors/groups', {
      floorplanId: floorplan.id,
    });

    const promises = fakeSensors.map((s) =>
      axios.post('/sensors', {
        floorplanId: floorplan.id,
        physicalAddress: s.physicalAddress,
        position: s.position,
        sensorGroupId,
        type: s.type,
      })
    );

    Promise.all(promises).then(() => {
      dispatch(
        fetchFloorplanSensors.request({
          floorplanId: floorplan.id,
        })
      );
    });
  },
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorInstallation);
