import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closeTeamForm,
  createTeam,
  ICreateTeam,
  IEditTeam,
  updateTeam,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import TeamForm from './TeamForm';

const mapStateToProps = (state: IAppState) => ({
  employeeMap: state.employees.employees,
  teams: Object.values(state.teams.teams),
  loading: state.teams.form.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeTeamForm: () => dispatch(closeTeamForm()),
  createTeam: (properties: ICreateTeam) =>
    dispatch(createTeam.request(properties)),
  updateTeam: (properties: IEditTeam) =>
    dispatch(updateTeam.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamForm);
