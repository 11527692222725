import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactourStep } from 'reactour';

import Disabler from './Disabler';

export default (
  disableTour: () => void,
  disabledTour: boolean
): ReactourStep[] => [
  {
    content: () => (
      <>
        <Typography variant="h4">
          <FormattedMessage
            id="tutorial.analytics.welcome.title"
            defaultMessage="Welcome to our analytics page!"
          />
        </Typography>
        <br />
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.analytics.welcome.message"
            defaultMessage="Here you find useful plots and maps that can give you insight into what's going on in your warehouse."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'body',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.analytics.tabs.message"
            defaultMessage="Our analytics are splitted across multiple areas."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'div[role="tablist"]',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.analytics.tabs.floorplan"
            defaultMessage="They consist in maps, selected by default, where you find heat, scatter and spaghetti maps of activity, velocities, idle time and so on."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#tab-maps',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.analytics.tabs.analytics"
            defaultMessage="And plots, a theme spit list of useful plots generated with the information collected."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '#tab-analytics',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.analytics.tabs.maplist"
            defaultMessage="Here you will find all available maps for selection. You can only see one at a time."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'button#show-layers',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.analytics.tabs.slider"
            defaultMessage="You can adjust the interval of data that you see on analytics."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '.date-time-range-slider-wrapper',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.analytics.tabs.slidercustom"
            defaultMessage="Or just select a pre customized interval."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: '.dtrs-with-hot-dates-select',
  },
  {
    content: () => (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="tutorial.analytics.rulesgo"
            defaultMessage="Lets see how to create Rules."
          />
        </Typography>
        <Disabler disableTour={disableTour} disabledTour={disabledTour} />
      </>
    ),
    selector: 'a#rules',
  },
];
