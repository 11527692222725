import { connect } from 'react-redux';

import { getWarehouseIdByFloorplanId } from '@app/utils/floorplanUtils';
import { timeZone } from '@dashboard_utils/index';
import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import { getRuleAlerts, isRuleAlertsLoading } from '@selectors/rules';
import { getWarehouseById } from '@selectors/warehouses';
import EventMap from './EventMap';

interface IProps {
  id: string;
  filterId?: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId, id } = props;

  const map = getMapById(state, id);

  return {
    mapImages: map.mapImages,
    paper: map.paper,
    floorplan: getFloorplanById(state, map.floorplanId)!,
    data: {
      ruleAlerts: getRuleAlerts(state, filterId || ''),
      loading: isRuleAlertsLoading(state, filterId || ''),
    },

    assets: state.assets.assets,
    floorplans: state.floorplans.floorplans,
    warehouses: state.warehouses.warehouses,
    warehouseTz:
      (
        getWarehouseById(
          state,
          getWarehouseIdByFloorplanId(
            state.floorplans.floorplans,
            map.floorplanId
          ) || ''
        ) || {}
      ).timezone || timeZone,
    rules: state.rules.rules,
    zones: state.zones.zones,
  };
};

export default connect(mapStateToProps)(EventMap);
