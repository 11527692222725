import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { createAssetDocument, CreateAssetDocument } from '@actions/index';
import { IAppState } from '@reducers/index';
import DocumentForm from './DocumentForm';

const mapStateToProps = (state: IAppState) => ({
  error: state.assets.documents.documentForm.error,
  loading: state.assets.documents.documentForm.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createAssetDocument: (properties: CreateAssetDocument) =>
    dispatch(createAssetDocument.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentForm);
