import { connect } from 'react-redux';

import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import PlayerTabGrid from './PlayerTabGrid';

import './index.css';

const mapStateToProps = (state: IAppState) => ({
  filters: state.filter.filters,
  theme: state.app.theme,
});

export default withRouter(connect(mapStateToProps)(PlayerTabGrid));
