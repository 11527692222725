import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import CardActions from '@mui/material/CardActions';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';
import React, { Component } from 'react';
import timeAgo from '@app/utils/timeAgo';

import { ICreateMessage, IFetchAccounts, IFetchMessages } from '@actions/index';
import Account from '@models/Account';
import AccountMeta from '@models/AccountMeta';
import Loading from '@app/common/Loading';
import Messages from './Messages';

import './index.css';

interface IProps {
  account?: Account;
  accounts: AccountMeta[];
  fetchAccounts: (props: IFetchAccounts) => void;
  locale: string;
  fetchMessages: (props: IFetchMessages) => void;
  toggleChat: () => void;
  loading: boolean;
  messageLoading: boolean;
  createMessage: (props: ICreateMessage) => void;
}
interface IState {
  activeAccount?: AccountMeta;
  message?: string;
}
  
class Chat extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  public componentDidMount() {
    const { fetchAccounts } = this.props;

    fetchAccounts({ shallow: true });
  }

  public selectAccount(a?: AccountMeta) {
    const { fetchMessages }= this.props;

    this.setState({ activeAccount: a }, () => {
      if (a) {
        fetchMessages({ toAccountId: a.id });

        const obj = document.getElementsByClassName('chat-container')[0] || {};
        obj.scrollTo({ top: obj.scrollHeight, behavior: 'smooth' });
      }
    });
  }

  public handleChange(key: keyof IState, e: any) {
    this.setState({
      [key]: e.target.value || '',
    });
  }

  public handleMessage(e: any) {
    if (e.which === 13) {
      this.sendMessage();
    }
  }

  public sendMessage() {
    const { createMessage } = this.props;
    const { message } = this.state;

    if (!message) {
      return;
    }

    this.setState({ message: '' }, () => {
      createMessage({ message, broadcast: true });
    });
  }

  public render() {
    const { activeAccount, message } = this.state;
    const { account, accounts, loading, messageLoading, locale, toggleChat } = this.props;

    return (
      <Paper>
        <Loading loading={loading}>
          <div className="chat-container">
            <List>
              <ListSubheader className="chat-header">
                <h4>Chats</h4>

                <IconButton aria-label="send" onClick={() => toggleChat()}>
                  <CloseIcon />
                </IconButton>
              </ListSubheader>

              {accounts.filter((a) => a.id !== (account || {}).id).map((a) => (
                <Box p={1} key={a.id}>
                  <Card>
                    <CardHeader
                      avatar={
                        <Avatar
                          alt={a.authImage}
                          src={a.authName}
                          style={{
                            backgroundColor: '#efefef',
                            border: a.lastSeen > Date.now() - 60 * 1000
                              ? '2px solid #009900'
                              : '2px solid transparent'
                          }}
                        ></Avatar>
                      }
                      action={
                        <IconButton aria-label="send" onClick={() => this.selectAccount(a)}>
                          <Badge badgeContent={a.messagesUnseen || 0} max={999} color="secondary">
                            <CommentIcon />
                          </Badge>
                        </IconButton>
                      }
                      title={a.authName}
                      subheader={(
                        <>
                          <div>{a.authEmail}</div>
                          {a.lastSeen && <i>Last seen {timeAgo(new Date(a.lastSeen).toISOString(), locale)}</i>}
                        </>
                      )}
                    />
                  </Card>
                </Box>
              ))}
            </List>
            {activeAccount ? (
              <Box p={1}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        alt={activeAccount.authImage}
                        src={activeAccount.authName}
                        style={{
                          backgroundColor: '#efefef',
                          border: activeAccount.lastSeen > Date.now() - 60 * 1000
                            ? '2px solid #009900'
                            : '2px solid transparent'
                        }}
                      ></Avatar>
                    }
                    action={
                      <IconButton aria-label="send" onClick={() => this.selectAccount()}>
                        <CloseIcon />
                      </IconButton>
                    }
                    title={activeAccount.authName}
                    subheader={activeAccount && (
                      <>
                        <div>{activeAccount.authEmail}</div>
                        {activeAccount.lastSeen && <i>Last seen {timeAgo(new Date(activeAccount.lastSeen).toISOString(), locale)}</i>}
                      </>
                    )}
                    className="header"
                  />
                  <Messages accountId={activeAccount.id}></Messages>
                </Card>
              </Box>
            ) : !(account?.organisations[account?.organisationId || ''] || {} as AccountMeta).operator ? (
              <Box p={1}>
                <Card>
                  <CardActions>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        label="Send Message To Everyone"
                        variant="standard"
                        value={message || ''}
                        onChange={(e) => this.handleChange('message', e)}
                        onKeyUp={this.handleMessage}
                        placeholder="Send Message To Everyone"
                      />
                    </FormControl>
                    <IconButton aria-label="send" onClick={this.sendMessage} disabled={messageLoading}>
                      <SendIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              </Box>
            ) : null}
          </div>
        </Loading>
      </Paper>
    );
  }
}

export default Chat;