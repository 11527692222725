import React, { FunctionComponent } from 'react';
import { IntlShape } from 'react-intl';

import IncompleteFloorplan, { IGeoMapping } from '@models/IncompleteFloorplan';
import { WarehouseWithFloorplans } from '@models/WarehouseWithFloorplans';
import Loading from '@app/common/Loading';
import { TransformationMatrix2D } from '../../utils';
import Setup from './Setup';

interface IProps {
  activeStep: number;
  error?: object;
  fetchStationarySensors: (floorplanId: string) => void;
  floorplan?: IncompleteFloorplan;
  loading: boolean;
  onSkipInstallation: (
    image: File | undefined,
    intl: IntlShape,
    name: string,
    scale: number,
    transformationMatrix: TransformationMatrix2D,
    geoMapping: IGeoMapping[],
    warehouseId: string,
    floorplanId?: string
  ) => void;
  skipZoneSetup: (floorplanId: string) => void;
  warehouse?: WarehouseWithFloorplans;

  firstFetch: boolean;
  sensorsLoading: boolean;
}

const SetupLoader: FunctionComponent<IProps> = ({
  activeStep,
  error = undefined,
  fetchStationarySensors,
  firstFetch,
  floorplan = undefined,
  loading,
  onSkipInstallation,
  sensorsLoading,
  warehouse = undefined,
  skipZoneSetup,
}: IProps) => (
  <Loading loading={!firstFetch && sensorsLoading}>
    <Setup
      activeStep={activeStep}
      fetchStationarySensors={fetchStationarySensors}
      floorplan={floorplan}
      error={error}
      loading={loading}
      onSkipInstallation={onSkipInstallation}
      warehouse={warehouse}
      skipZoneSetup={skipZoneSetup}
    />
  </Loading>
);

export default SetupLoader;
