import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import Account from '@models/Account';
import { Team } from '@models/Team';
import { Warehouses } from '@models/Warehouse';
import { hasPermission } from '@selectors/accounts';
import { ConfirmDialogActions, IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import MUITable, { ITableColumn } from '@app/common/Table';
import Loading from '@app/common/Loading';
import TeamForm from './TeamForm';
import { Employees } from '@models/Employee';

const messages = {
  deleteMsg: {
    defaultMessage: 'Are you sure that you want to DELETE this team?',
    id: 'dashboard.teams.delete_message',
  },
  thName: {
    defaultMessage: 'Name',
    id: 'dashboard.teams.table.name',
  },
  thEmployees: {
    defaultMessage: 'Employees',
    id: 'dashboard.teams.table.employees',
  },
};

export interface IAnchorEL {
  menu: HTMLElement | undefined;
  expanded: boolean;
}

interface IProps {
  account: Account;
  confirm: (props: IConfirmDialog) => void;
  data: Team[];
  deleteTeam: (id: string, intl: IntlShape) => void;
  employees: Employees;
  fetchTeams: (shallow?: boolean) => void;
  intl: IntlShape;
  openForm: (data?: any) => void;
  language: string;
  loading: boolean;
  warehouses: Warehouses;
  teamFormOpen: boolean;
  teamFormData?: Team;
  router: any;
}

interface IState {
  anchorEl: Record<number, IAnchorEL>;
  columns: ITableColumn[];
  page: number;
}

class TeamTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      anchorEl: {},
      columns: [
        { label: intl.formatMessage(messages.thName), name: 'name' },
        { label: intl.formatMessage(messages.thEmployees), name: 'employees' },
        {
          label: ' ',
          name: 'menu',
          options: { interaction: false },
        },
      ],
      page: 1,
    };

    this.customRowRender = this.customRowRender.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  public handleAdd() {
    const { openForm } = this.props;

    openForm();
  }

  public handleMenu(index: number, event: any) {
    const { anchorEl } = this.state;

    this.setState({
      anchorEl: {
        ...anchorEl,
        [index]: {
          ...anchorEl[index],
          menu: event.currentTarget,
        },
      },
    });
  }

  public handleMenuClose(index: number) {
    const { anchorEl } = this.state;
    anchorEl[index].menu = undefined;
    this.setState({ anchorEl });
  }

  public handleEdit(data: any, index: number) {
    const { openForm } = this.props;
    const { anchorEl } = this.state;

    openForm(data);
    anchorEl[index].menu = undefined;

    this.setState({ anchorEl });
  }

  public handleDelete(id: string, index: number) {
    const { confirm, deleteTeam, intl } = this.props;
    const { anchorEl } = this.state;

    anchorEl[index].menu = undefined;

    this.setState({ anchorEl }, () => {
      confirm({
        confirmType: ConfirmDialogActions.DELETE,
        message: intl.formatMessage(messages.deleteMsg),
        onConfirmation: () => deleteTeam(id, intl),
      });
    });
  }

  public handlePageChange(event: any, page: number) {
    this.setState({ page });
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const { fetchTeams } = this.props;

    fetchTeams(shallow);
  }

  public customRowRender(
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    const { account, data, employees } = this.props;
    const { anchorEl } = this.state;

    return (
      <Fragment key={rowIndex}>
        <TableRow key={rowIndex}>
          {columns[0] && <TableCell>{rowData[0]}</TableCell>}
          {columns[1] && <TableCell>
            <AvatarGroup max={9} style={{ flexDirection: 'row' }}>
              {rowData[1].map((employeeId: number) =>
                <Avatar key={employeeId}
                  alt={(employees[employeeId] || {}).name}
                  src={(employees[employeeId] || {}).image}
                  style={{ backgroundColor: '#efefef' }} />)}
              </AvatarGroup>
            </TableCell>}
          {columns[2] && (
            <TableCell>
              {(hasPermission(account, 'edit_asset') ||
                hasPermission(account, 'del_asset')) && (
                <div style={{ float: 'right' }}>
                  <IconButton
                    aria-label="More"
                    aria-owns={
                      (anchorEl[dataIndex] || {}).menu ? 'long-menu' : undefined
                    }
                    aria-haspopup="true"
                    onClick={(event: any) => this.handleMenu(dataIndex, event)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={(anchorEl[dataIndex] || {}).menu}
                    open={!!(anchorEl[dataIndex] || {}).menu}
                    onClose={() => this.handleMenuClose(dataIndex)}
                  >
                    {hasPermission(account, 'edit_asset') && (
                      <MenuItem onClick={() => this.handleEdit(data[dataIndex], dataIndex)}>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <FormattedMessage
                              id="dashboard.teams.editteam"
                              defaultMessage="Edit"
                            />
                          }
                        />
                      </MenuItem>
                    )}
                    {hasPermission(account, 'del_asset') && (
                      <MenuItem onClick={() => this.handleDelete(data[dataIndex].id, dataIndex)}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <FormattedMessage
                              id="dashboard.teams.deleteteam"
                              defaultMessage="Delete"
                            />
                          }
                        />
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              )}
            </TableCell>
          )}
        </TableRow>
      </Fragment>
    );
  }

  public render() {
    const { account, data, teamFormOpen, teamFormData, loading } = this.props;
    const { columns } = this.state;

    return (
      <>
        <Loading loading={loading}>
          {hasPermission(account, 'add_asset') && (
            <Box mt={2}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button id="add-team" onClick={this.handleAdd}>
                  <AddIcon style={{ marginRight: '5px' }} />
                  <FormattedMessage
                    id="dashboard.teams.addteam"
                    defaultMessage="Add Team"
                  />
                </Button>
              </div>
            </Box>
          )}

          <div className="table-container">
            <Card style={{ flex: 1 }}>
              <CardContent>
                <MUITable
                  columns={columns}
                  data={data}
                  customRowRender={this.customRowRender}
                  tableId="teams"
                />
              </CardContent>
            </Card>
          </div>
        </Loading>
        {teamFormOpen ? <TeamForm data={teamFormData} /> : null}
      </>
    );
  }
}

export default injectIntl(TeamTab);
