import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  deletePreCheck,
  fetchPreCheckList,
  IDeletePreCheck,
  openPreCheckForm,
} from '@actions/prechecks';
import PreCheck from '@models/PreCheck';
import { IAppState } from '@reducers/index';
import { getPreCheckList, isPreCheckListsLoading } from '@selectors/prechecks';

import PreCheckLists from './PreCheckLists';

const mapStateToProps = (state: IAppState) => ({
  loading: isPreCheckListsLoading(state.prechecks),
  openForm: state.prechecks.form.active,
  preChecks: getPreCheckList(state.prechecks),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deletePreCheck: (preCheck: IDeletePreCheck) =>
    dispatch(deletePreCheck.request(preCheck)),
  fetchPreCheckList: () => dispatch(fetchPreCheckList.request()),
  openPreCheckForm: (data?: PreCheck) => dispatch(openPreCheckForm(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreCheckLists);
