import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import {
  getFromLocalStorage,
  saveToLocalStorage,
} from '@app/utils/localStorageUtils';
import { messages } from '@definitions/plots';
import { IActiveMetrics, MetricsMetaState } from '@reducers/analytics';
import PlotsTab from '../PlotsTab';
import LayerProvider from '../LayerProvider';

interface IProps {
  intl: IntlShape;
  /* Metrics meta */
  meta: Record<string, MetricsMetaState>;
  /* Active metrics information */
  activeMetrics: Record<string, IActiveMetrics>;
  /* Analytics section */
  section: 'user' | 'diagnostics';
}

interface IState {
  tab: number;
  tabs: string[];
}

/**
 * Opens zone metrics tabs provider. Reacts to active metric.
 */
class ZoneMetrics extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const tabs = this.generateTabs();
    // Attempt to get last selected tab from local storage
    const tab = Number(
      getFromLocalStorage('analytics_selected_tab_popup') || 0
    );

    this.state = {
      tab: tabs[tab] ? tab : 0,
      tabs,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  private handleTabChange(_: any, tab: number) {
    this.setState({ tab }, () =>
      // Save selected tab to local storage
      saveToLocalStorage('analytics_selected_tab_popup', String(tab))
    );
  }

  /**
   * @description Generates tab id array for the needed tabs for the active metric.
   * Zone metrics only care about aggregation_plots & fixed_plots.
   * @returns {string[]} tabs
   */
  public generateTabs() {
    const { meta, activeMetrics, section } = this.props;

    const metricId = (activeMetrics.popup || {})[section];

    const tabs: string[] = [];
    const metric = ((meta.popup || {}).metrics || []).find(
      (m) => m.id === metricId
    );

    if (metric && metric.returns.indexOf('aggregation_plots') !== -1) {
      tabs.push('aggregation_plots');
    }
    if (metric && metric.returns.indexOf('fixed_plots') !== -1) {
      tabs.push('fixed_plots');
    }

    return tabs;
  }

  public render() {
    const { activeMetrics, meta, intl, section } = this.props;
    const { tab, tabs } = this.state;

    const activeFilters = ['assets', 'assetTags', 'zones'];
    const metricId = (activeMetrics.popup || {})[section];

    if (
      (((meta.popup || {}).metrics || []).find((m) => m.id === metricId) || {})
        .rule_filters
    ) {
      activeFilters.push('rules');
      activeFilters.push('ruleTemplates');
    }

    let tabContent = null;
    switch (tabs[tab]) {
      case 'fixed_plots':
      case 'aggregation_plots':
        tabContent = (
          <PlotsTab section={section} type={tabs[tab]} filterId="popup" />
        );
        break;

      default:
        tabContent = null;
        break;
    }

    return (
      <div style={{ overflow: 'auto' }}>
        {tabs.length > 1 && (
          <Box style={{ flex: 1 }} mb={1}>
            <AppBar position="static" color="default" style={{ zIndex: 0 }}>
              <Tabs
                value={tab}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
                onChange={this.handleTabChange}
                centered
              >
                {tabs.map((k) => (
                  <Tab
                    id={`tab-${k}`}
                    label={intl.formatMessage(messages[`tab_${k}`])}
                    key={k}
                  />
                ))}
              </Tabs>
            </AppBar>
          </Box>
        )}
        <LayerProvider
          activeMetrics={activeMetrics.popup || {}}
          filterId="popup"
          section={section}
          tab={tabs[tab]}
        />
        {(activeMetrics.popup || {})[section] && tabContent}
      </div>
    );
  }
}

export default injectIntl(ZoneMetrics);
