import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  fetchMetric,
  FetchMetric,
  toggleMetricPlot,
  ToggleMetricPlot,
} from '@actions/index';
import { getSelectedLocale } from '@selectors/app';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getCompleteWarehouses } from '@selectors/warehouses';
import { IAppState } from '@reducers/index';
import LayerProvider from './LayerProvider';

interface IProps {
  filterId: string;
  section: 'user' | 'diagnostics';
}
const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId, section } = props;

  const filter = state.filter.filters[filterId] || {};
  const metricId = (state.analytics.activeMetrics[filterId] || {})[section];

  return {
    warehouseId: filter.warehouseId || '',
    floorplanId: filter.floorplanId || '',
    zoneIds: filter.zoneIds || [],

    tags: filter.tags || [],
    assetIds: filter.assetIds || [],

    templateIds: filter.templateIds || [],
    ruleIds: filter.ruleIds || [],

    startDate: filter.startDate,
    endDate: filter.endDate,
    filterUpdated: filter.filterUpdated,

    meta: state.analytics.meta[filterId] || {},
    metricState:
      (state.analytics.metrics[filterId] || {})[metricId || ''] || {},

    locale: getSelectedLocale(state),
    measurementUnits: getAccountMeasurementUnits(state.authentication),

    warehouses: getCompleteWarehouses(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMetric: (properties: FetchMetric) =>
    dispatch(fetchMetric.request(properties)),
  toggleMetricPlot: (properties: ToggleMetricPlot) =>
    dispatch(toggleMetricPlot(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayerProvider);
