import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import { format } from 'date-fns';
import React, { Component } from 'react';

import { IOrdersEvents } from '@api/websocket';
import PlayerControls from '@app/common/PlayerControls';
import { Date } from '@dashboard_utils/index';
import { ISimulationData, Simulation } from '@models/Simulation';
import { IIntervalState } from '@reducers/simulations';
import mapEvents from '../../../../../../common/FullMap/Map/eventEmitter';
import OrderSlider from './OrderSlider';
import TimeSlider from './TimeSlider';

const STEP = 1000;
const LOADING_INTERVAL = 30 * 60 * 1000;

interface IProps {
  data: Record<string, ISimulationData[]>;
  fetchSimulationData: (id: string, timestamp: number) => void;
  id: string;
  intervals: Record<string, IIntervalState>;
  simulation: Simulation;
  ordersEvents?: IOrdersEvents[];
}
interface IState {
  direction: number;
  playing: boolean;
  speed: number;
  timestamp: number;
  streamHolded: boolean;
}

const handleNewMessage = (data: any) => {
  mapEvents.emit('simulator-data', data);
};

let playerTimestamp = 0;
let isHolded = false;
let isHoldedByError = false;
class Player extends Component<IProps, IState> {
  private interval: any;

  private updateInterval: any;

  constructor(props: IProps) {
    super(props);

    const { simulation } = props;

    playerTimestamp = simulation.startTs;

    this.state = {
      direction: 1,
      playing: false,
      speed: 0,
      streamHolded: false,
      timestamp: playerTimestamp - (playerTimestamp % STEP),
    };

    this.backwards = this.backwards.bind(this);
    this.createLoop = this.createLoop.bind(this);
    this.backward = this.backward.bind(this);
    this.forward = this.forward.bind(this);
    this.fastRewind = this.fastRewind.bind(this);
    this.fastForward = this.fastForward.bind(this);
    this.getIntervalInfo = this.getIntervalInfo.bind(this);
    this.loadIntervalIfNeeded = this.loadIntervalIfNeeded.bind(this);
    this.pause = this.pause.bind(this);
    this.start = this.start.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
  }

  public componentDidMount() {
    this.interval = this.createLoop();
    // Every 1s updates player time (prevents frequent rendering)
    this.updateInterval = setInterval(this.updateState, STEP / 2);
  }

  public componentWillUnmount() {
    clearInterval(this.interval);

    clearInterval(this.updateInterval);
  }

  private handleSliderChange(value: number) {
    clearInterval(this.interval);

    playerTimestamp = value;

    this.setState(
      {
        timestamp: value,
      },
      () => {
        this.interval = this.createLoop();
      }
    );
  }

  private getInterval(ts: number) {
    const { intervals } = this.props;

    const keys = Object.keys(intervals);
    for (let i = 0; i < keys.length; i += 1) {
      if (ts >= Number(keys[i]) && ts <= Number(keys[i]) + LOADING_INTERVAL) {
        return keys[i];
      }
    }

    return undefined;
  }

  private getIntervalInfo(ts: number) {
    const { intervals } = this.props;

    const interval = this.getInterval(ts);

    if (interval !== undefined) {
      return intervals[interval];
    }

    return { loading: true };
  }

  public start() {
    const { timestamp } = this.state;
    const { simulation } = this.props;

    isHoldedByError = false;
    playerTimestamp = timestamp || simulation.startTs;

    this.setState({ playing: true });
  }

  public pause() {
    this.setState({ playing: false });
  }

  public backward() {
    const { timestamp } = this.state;

    if (timestamp) {
      clearInterval(this.interval);

      const ts =
        (timestamp || 0) - 60 * 1000 < 0 ? 0 : (timestamp || 0) - 60 * 1000;

      playerTimestamp = ts;

      this.setState({ timestamp: ts }, () => {
        this.interval = this.createLoop();
      });
    }
  }

  public forward() {
    const { timestamp } = this.state;
    const { simulation } = this.props;

    if (timestamp) {
      clearInterval(this.interval);

      const ts =
        (timestamp || 0) + 60 * 1000 > simulation.startTs + simulation.length
          ? simulation.startTs + simulation.length
          : (timestamp || 0) + 60 * 1000;

      playerTimestamp = ts;

      this.setState({ timestamp: ts }, () => {
        this.interval = this.createLoop();
      });
    }
  }

  public fastRewind() {
    const { speed } = this.state;

    if (speed > 0.25) {
      this.setState({ speed: (speed || 1) / 2 });
    }
  }

  public fastForward() {
    const { speed } = this.state;

    if (speed < 256) {
      this.setState({ speed: (speed || 1) * 2 });
    }
  }

  public backwards() {
    const { direction } = this.state;

    clearInterval(this.interval);

    this.setState({ direction: direction * -1 }, () => {
      this.interval = this.createLoop();
    });
  }

  public updateState() {
    const { playing } = this.state;

    const streamHolded = isHolded;

    if (playing === true) {
      // Make sure that setted timestamp fits the selected step of the slider
      this.setState({
        playing: isHoldedByError ? false : playing,
        streamHolded,
        timestamp: playerTimestamp - (playerTimestamp % STEP),
      });
    }
  }

  public createLoop() {
    // Every 10ms retrieves data from sensor data array and sends it to the player
    return setInterval(() => {
      const { direction, playing, speed, timestamp } = this.state;
      const { simulation } = this.props;

      const playerSpeed = speed || 1;

      if (timestamp !== undefined && playerTimestamp !== undefined) {
        const { data } = this.props;

        if (playing === true) {
          let ts = playerTimestamp;

          if (
            ts < simulation.startTs ||
            ts > simulation.startTs + simulation.length
          ) {
            playerTimestamp = simulation.startTs;

            return this.setState({
              playing: false,
              timestamp: playerTimestamp,
            });
          }

          for (
            ts;
            (direction === 1 && ts <= playerTimestamp + 10 * playerSpeed) ||
            (direction === -1 && ts >= playerTimestamp - 10 * playerSpeed);
            ts += 1 * direction
          ) {
            const intervalData = data[ts];

            const intervalInfo = this.getIntervalInfo(ts);

            if (!intervalInfo || intervalInfo.loading) {
              isHolded = true;

              break;
            } else {
              isHolded = false;

              if (intervalData) {
                handleNewMessage(
                  intervalData.sort((a: any, b: any) => {
                    if (direction === -1 && a.ts < b.ts) {
                      return 1;
                    }
                    if (direction === 1 && a.ts > b.ts) {
                      return 1;
                    }
                    if (direction === -1 && a.ts > b.ts) {
                      return -1;
                    }
                    if (direction === 1 && a.ts < b.ts) {
                      return -1;
                    }
                    return 0;
                  })
                );
              }
            }
          }

          playerTimestamp = ts;

          return this.loadIntervalIfNeeded(playerTimestamp);
        }

        return null;
      }

      return null;
    }, 10);
  }

  private loadIntervalIfNeeded(ts: number) {
    const { fetchSimulationData, id, intervals } = this.props;
    const { speed, direction } = this.state;

    const interval = this.getInterval(ts);

    let requestTs = ts + LOADING_INTERVAL * direction;
    if (interval !== undefined) {
      requestTs = Number(interval) + LOADING_INTERVAL * direction;
    } else if (direction === 1) {
      requestTs = ts;
    }

    // If the current player ts is bigger than loading margin (10s)
    // and there is no subsequent interval, then loading is needed
    if (
      interval === undefined ||
      (ts + 10 * (speed || 1) * 60 * 1000 >= Number(interval) &&
        (intervals[requestTs] === undefined ||
          (!intervals[requestTs].loading &&
            intervals[requestTs].erroredAttempts &&
            ((intervals[requestTs].erroredAttempts || 0) < 3 ||
              (intervals[requestTs].lastErrorTs || 0) < Date.now() - STEP))))
    ) {
      isHoldedByError = false;

      // eslint-disable-next-line no-console
      console.log(`Requesting ${requestTs} to ${requestTs + LOADING_INTERVAL}`);

      fetchSimulationData(id, requestTs);
    } else if (
      !(intervals[requestTs] || {}).loading &&
      ((intervals[requestTs] || {}).erroredAttempts || 0) > 2
    ) {
      isHoldedByError = true;
    }
  }

  public render() {
    const { direction, playing, speed, streamHolded, timestamp } = this.state;
    const { ordersEvents, simulation } = this.props;

    return (
      <>
        {streamHolded && playing ? <LinearProgress /> : null}
        <Card style={{ marginTop: '10px' }}>
          <CardContent
            style={{
              padding: '0px',
              textAlign: 'center',
            }}
          >
            <OrderSlider
              start={simulation.startTs}
              length={(simulation.startTs || 0) + (simulation.length || 0)}
              ordersEvents={ordersEvents}
            />
            <TimeSlider
              value={timestamp || simulation.startTs}
              start={simulation.startTs}
              length={(simulation.startTs || 0) + (simulation.length || 0)}
              onChange={this.handleSliderChange}
            />
          </CardContent>
          <PlayerControls
            direction={direction}
            playing={playing}
            speed={speed}
            time={format(
              timestamp || simulation.startTs || 0,
              'yyyy-MM-dd HH:mm:ss'
            )}
            fastRewind={this.fastRewind}
            backward={this.backward}
            pause={this.pause}
            start={this.start}
            forward={this.forward}
            fastForward={this.fastForward}
            backwards={this.backwards}
          />
        </Card>
      </>
    );
  }
}

export default Player;
