import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import React, { FunctionComponent } from 'react';
import { useDrag } from 'react-dnd'

import { Task, TaskPriority } from '@models/Task';
import { Employees } from '@models/Employee';
import { Grid } from '@mui/material';

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface IProps {
  index: number;
  openModal: (task: Task) => void;
  task: Task;
  employees: Employees;
}

const TaskView: FunctionComponent <IProps> = (properties) => {
  const { employees, index, openModal, task } = properties;

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'task', task, index },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    type: 'task'
  })

  return (
    <div ref={drag}>
      <Box pb={2}>
        <Card style={{ cursor: 'pointer', opacity: isDragging ? 0.5 : 1 }} onClick={() => openModal(task)}>
          <CardContent>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="subtitle1" component="span">{task.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Box style={{ textAlign: 'end' }}>
                  {
                    task.priority === TaskPriority.LOW
                      ? <Chip label="Low Priority" color="default"></Chip>
                      : task.priority === TaskPriority.HIGH
                        ? <Chip label="High Priority" color="error"></Chip>
                        : null
                  }
                </Box>
              </Grid>
            </Grid>
            <Box pt={2} pb={2}>
              <AvatarGroup max={9}>
                {task.employees.map((employeeId) =>
                  <Avatar key={employeeId}
                    alt={(employees[employeeId] || {}).name}
                    src={(employees[employeeId] || {}).image}
                    style={{ backgroundColor: '#efefef' }} />)}
              </AvatarGroup>
            </Box>
            {task.subtasks.length > 0 && (
              <LinearProgressWithLabel
                value={(task.subtasks.filter((st) => st.active).length / task.subtasks.length) * 100}
              />
            )}
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

export default TaskView;
