import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { parse } from 'qs';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import LoadingButton from '@app/common/LoadingButton/index';
import Account from '@models/Account';
import FetchError from '@models/FetchError';
import Walkthrough from '../../utils/Walkthrough';

interface IProps {
  account?: Account;
  language: string;
  loading: boolean;
  login: (email: string, password: string) => void;
  error?: FetchError;
  router: any;
}

interface IState {
  email: string;
  submitted: boolean;
  resetedAlert: boolean;
  password: string;
  error?: FetchError;
}

class LoginPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { router } = props;

    this.state = {
      email: '',
      submitted: false,
      password: '',
      resetedAlert: router.query.reseted === 'true',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.closeResetedSnackbar = this.closeResetedSnackbar.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { account, error, router, language, loading } = this.props;

    if (prevProps.error !== error && error) {
      this.showError();
    }
    if ((prevProps.loading !== loading && !loading && !error) || account) {
      if (router.query.redirect) {
        window.location.href = router.query.redirect as string;
      } else {
        router.navigate(`/${language}/dashboard`);
      }
    }
  }

  private handleKeyPress(e: any) {
    if (e.which === 13) {
      this.handleSubmit();
    }
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'email'>);
    };
  }

  public handleSubmit() {
    const { email, password } = this.state;
    const { login } = this.props;

    if (email.length === 0 || password.length === 0) {
      this.setState({ submitted: true });

      return;
    }

    login(email, password);
  }

  public showError() {
    const { error } = this.props;

    this.setState({ error });
  }

  public closeSnackbar() {
    this.setState({ error: undefined });
  }

  public closeResetedSnackbar() {
    this.setState({ resetedAlert: false });
  }

  public render() {
    const { password, email, error, submitted, resetedAlert } = this.state;
    const { language, loading, router } = this.props;

    const query = parse(router.query.search, { ignoreQueryPrefix: true });

    let errorMessage = (
      <FormattedMessage
        id="external.loginpage.error"
        defaultMessage="Something went wrong, please try again or contact our support."
      />
    );

    if (error && error.errorCode === 'WrongPassword') {
      errorMessage = (
        <FormattedMessage
          id="external.loginpage.wrongpassword"
          defaultMessage="Email and password do not match."
        />
      );
    }

    const hasError = !email.length || !password.length;

    return (
      <>
        <div id="banner">
          <video muted autoPlay loop poster="/assets/background.jpg">
            <source src="/assets/banner.mp4" type="video/mp4" />
            <track kind="captions" />
          </video>
        </div>

        <Grid container justifyContent="center" style={{ zIndex: 1 }}>
          <Grid item sm={4}>
            <Box mt={12} ml={2} mr={2} mb={2}>
              <Card className="container">
                {!query.redirect && <Walkthrough type="wellcome" />}
                <CardHeader
                  title={
                    <FormattedMessage
                      id="external.loginpage.title"
                      defaultMessage="Login"
                    />
                  }
                />
                <CardContent>
                  <FormControl
                    required
                    fullWidth
                    margin="normal"
                    error={submitted === true && email.length === 0}
                  >
                    <TextField
                      label={
                        <FormattedMessage
                          id="external.loginpage.label_email"
                          defaultMessage="Email"
                        />
                      }
                      name="email"
                      onChange={this.handleChange('email')}
                      onKeyPress={this.handleKeyPress}
                      value={email}
                      variant="standard"
                      required
                      helperText={
                        submitted === true && email.length === 0 ? (
                          <FormHelperText error>
                            <FormattedMessage
                              id="external.loginpage.field_email_help"
                              defaultMessage="An email must be specified"
                            />
                          </FormHelperText>
                        ) : null
                      }
                    />
                  </FormControl>

                  <FormControl
                    required
                    fullWidth
                    margin="normal"
                    error={submitted === true && password.length === 0}
                  >
                    <TextField
                      label={
                        <FormattedMessage
                          id="external.loginpage.label_password"
                          defaultMessage="Password"
                        />
                      }
                      type="password"
                      name="password"
                      onChange={this.handleChange('password')}
                      onKeyPress={this.handleKeyPress}
                      value={password}
                      variant="standard"
                      required
                      helperText={
                        submitted === true && password.length === 0 ? (
                          <FormHelperText error>
                            <FormattedMessage
                              id="external.loginpage.field_password_help"
                              defaultMessage="A password must be specified"
                            />
                          </FormHelperText>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    margin="normal"
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <Link
                      to={`/${language}/password-recovery`}
                      component={RouterLink}
                    >
                      <FormattedMessage
                        id="external.loginpage.password_recovery"
                        defaultMessage="Forgot your password?"
                      />
                    </Link>
                  </FormControl>

                  <LoadingButton
                    id="login"
                    onClick={this.handleSubmit}
                    variant="contained"
                    color="primary"
                    loading={loading}
                    fullWidth
                    disabled={hasError}
                  >
                    <FormattedMessage
                      id="external.loginpage.button_submit"
                      defaultMessage="Log in"
                    />
                  </LoadingButton>

                  <Box mt={2}>
                    <Typography variant="body1">
                      <FormattedMessage
                        id="external.loginpage.account"
                        defaultMessage={"Don't have an account?"}
                      />
                      <span> </span>
                      <Link to={`/${language}/signup`} component={RouterLink}>
                        <FormattedMessage
                          id="external.loginpage.signup"
                          defaultMessage="Create one"
                        />
                      </Link>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>

        <Snackbar
          open={!!error && !loading}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={this.closeSnackbar}
          autoHideDuration={3000}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <Snackbar
          open={!!resetedAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={this.closeResetedSnackbar}
          autoHideDuration={3000}
        >
          <Alert severity="success">
            <FormattedMessage
              id="external.loginpage.reseted"
              defaultMessage="Your password was reseted with success"
            />
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default LoginPage;
