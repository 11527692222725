import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import LoadingButton from '@app/common/LoadingButton';

interface IProps {
  createFakeSensors: () => void;
  installationComplete: boolean;
  loading: boolean;
}

const SensorInstallationForm: FunctionComponent<IProps> = (props: IProps) => {
  const { createFakeSensors, installationComplete, loading } = props;

  // @ts-ignore
  window.createFakeSensors = () => createFakeSensors();

  return (
    <>
      <Typography variant="h4">
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.sensorinstallation.title"
          defaultMessage="Sensor Installation"
        />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.sensorinstallation.description.p1"
          defaultMessage="This part of the setup must be done physically, as such, we ask you to wait until our team has done the necessary work."
        />
        <br />
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.sensorinstallation.description.p2"
          defaultMessage="The Refresh button will check if everything has been setup correctly."
        />
        <br />
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.sensorinstallation.description.p3"
          defaultMessage="When this happens, you will be automatically redirected to the next step."
        />
      </Typography>

      {!installationComplete ? <Box mt={4}>
        <LoadingButton
          onClick={createFakeSensors}
          variant="contained"
          color="primary"
          disabled={loading}
          loading={loading}
        >
          Skip (Use Sample)
        </LoadingButton>
      </Box> : null}
    </>
  );
}

export default SensorInstallationForm;
