import { Color, Matrix, Path, Point } from 'paper';
import { Component } from 'react';

import { Assets } from '@models/Asset';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import SpaghettiMap from '@models/SpaghettiMap';
import ZoneSpaghettiMap from '@models/ZoneSpaghettiMap';
import { Zones } from '@models/Zone';
import {
  generateSpaghettiMap,
  generateZoneSpaghettiMap,
} from '@dashboard_utils/index';
import { orderLayers } from '../../eventEmitter';
import Paper from '../../Paper';
import MapImages from '../../MapImages';
import { defaultTransformationMatrix } from '../../../consts';

interface IProps {
  assets: Assets;
  data: SpaghettiMap | ZoneSpaghettiMap;
  floorplan: IncompleteFloorplan;
  type: 'zone' | 'trajectory';
  mapImages: MapImages;
  paper: Paper;
  loading: boolean;
  zones: Zones;
}

class SpaghettiMapLayer extends Component<IProps> {
  private elements: paper.Path[] = [];

  public componentDidMount() {
    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { assets, data } = this.props;

    if (
      JSON.stringify(data) !== JSON.stringify(prevProps.data) ||
      JSON.stringify(assets) !== JSON.stringify(prevProps.assets)
    ) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    this.clear();
  }

  public load() {
    const { assets, data, floorplan, paper, mapImages, type, zones } =
      this.props;

    paper.scope.activate();

    if (type === 'trajectory') {
      this.elements = generateSpaghettiMap(
        { Matrix, Path, Point },
        assets,
        mapImages.height,
        data,
        floorplan.transformationMatrix || defaultTransformationMatrix,
        floorplan.scale || 1
      );
    } else {
      this.elements = generateZoneSpaghettiMap(
        { Color, Matrix, Path, Point },
        zones,
        mapImages.height,
        data,
        floorplan.transformationMatrix || defaultTransformationMatrix,
        floorplan.scale || 1
      );
    }

    orderLayers();
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((e) => e.remove());

    this.elements = [];
  }

  public render() {
    return null;
  }
}

export default SpaghettiMapLayer;
