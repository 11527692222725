import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

const ConErrorPage: FunctionComponent<{}> = () => (
  <Grid container justifyContent="center">
    <Grid item sm={4}>
      <Box p={4}>
        <Card>
          <CardHeader
            title={
              <FormattedMessage
                id="dashboard.conerrorpage.title"
                defaultMessage="Something is up with our network"
              />
            }
          />
          <CardContent>
            <Typography variant="body1">
              <FormattedMessage
                id="dashboard.conerrorpage.description"
                defaultMessage="Give us a moment to fix this and please try again."
              />
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => window.location.reload()}
            >
              <FormattedMessage
                id="dashboard.conerrorpage.button"
                defaultMessage="Retry"
              />
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Grid>
  </Grid>
);

export default ConErrorPage;
