import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import {
  confirmDialog,
  deleteTeam,
  fetchTeams,
  openTeamForm,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { Team } from '@models/Team';
import TeamsTab from './Teams';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { withRouter } from '@app/utils/withRouter';
import { getActiveLanguage } from '@selectors/app';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account!,
  warehouses: state.warehouses.warehouses,
  employees: state.employees.employees,
  data: Object.values(state.teams.teams),
  language: getActiveLanguage(state),
  loading: state.teams.loading,
  teamFormData: state.teams.form.data,
  teamFormOpen: state.teams.form.active,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  deleteTeam: (id: string, intl: IntlShape) =>
    dispatch(deleteTeam.request({ id, intl })),
  fetchTeams: (shallow?: boolean) =>
    dispatch(fetchTeams.request({ shallow })),
  openForm: (team?: Team) => dispatch(openTeamForm(team)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamsTab)
);
