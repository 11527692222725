import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { accountVerification } from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import AccountVerificationPage from './AccountVerificationPage';

import './index.css';

const mapStateToProps = (state: IAppState) => ({
  error: state.authentication.verification.error,
  language: getActiveLanguage(state),
  loading: state.authentication.verification.loading,
  verificationSuccess: state.authentication.verification.success,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    accountVerification: (email: string, verification: string) =>
      dispatch(accountVerification.request({ email, verification })),
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountVerificationPage));
