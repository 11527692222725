import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { Date } from '@dashboard_utils/index';
import { getZoneTypeName } from '../../../../forms/ZoneForm/utils';
import { HoverFeature } from '../../types';
import messages from '../../messages';
import mapEvents from '../eventEmitter';

interface IHoverUpdate {
  mapId: string;
  featureId: string;
  props: Record<string, any>;
}

interface IProps {
  id: string;
  feature?: HoverFeature;
  intl: IntlShape;
  warehouseTz: string;
}

interface IPosition {
  y: number;
  x: number;
}

const formatValue = (
  feature: HoverFeature,
  fProps: any,
  k: string,
  intl: IntlShape,
  warehouseTz: string
) => {
  if (k === 'created') {
    return new Date(warehouseTz, fProps[k]).format();
  }

  if (k === 'color') {
    return (
      fProps[k].toCSS && (
        <div
          style={{
            backgroundColor: fProps[k].toCSS(true),
            height: '20px',
            width: '50px',
          }}
        />
      )
    );
  }

  if (k === 'horizontalVelocity') {
    return fProps.moving === false
      ? intl.formatMessage(messages.stopped)
      : fProps[k];
  }

  if (feature.info.type === 'zone' && k === 'zoneType') {
    return getZoneTypeName(fProps[k]);
  }

  if (feature.info.type === undefined && k === 'type') {
    return intl.formatMessage(messages[`sensor_${fProps[k]}`] || messages.none);
  }

  return fProps[k];
};

/**
 * Component responsible for adding map houver popup and managing it's state
 */
class MapHover extends Component<IProps> {
  private hoverUpdate: Record<string, any> | undefined;

  private position: IPosition | undefined;

  constructor(props: IProps) {
    super(props);

    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.handleHoverpdate = this.handleHoverpdate.bind(this);
  }

  public componentDidMount() {
    mapEvents.on('hover-position', this.handlePositionChange);
    mapEvents.on('update-hover', this.handleHoverpdate);
  }

  public componentDidUpdate(prevProps: any) {
    const { feature } = this.props;

    if (prevProps.feature !== feature) {
      this.position = undefined;
      this.forceUpdate();
    }
  }

  public componentWillUnmount() {
    mapEvents.removeListener('hover-position', this.handlePositionChange);
    mapEvents.removeListener('update-hover', this.handleHoverpdate);
  }

  public handleHoverpdate(hoverUpdate: IHoverUpdate) {
    const { id } = this.props;

    if (id === hoverUpdate.mapId) {
      this.hoverUpdate = hoverUpdate;

      this.forceUpdate();
    }
  }

  public handlePositionChange(position: IPosition) {
    this.position = position;
    this.forceUpdate();
  }

  public render() {
    const { feature, intl, warehouseTz } = this.props;
    const { x, y } = this.position || feature || { x: 0, y: 0 };

    if (!feature) {
      return null;
    }

    let fProps = feature.info.props || {};
    if (this.hoverUpdate && this.hoverUpdate.featureId === feature.info.id) {
      fProps = this.hoverUpdate.props;
    }

    return (
      <Paper
        className="map-tooltip"
        style={{ top: `${y + 15}px`, left: `${x}px` }}
      >
        <Typography
          color="textSecondary"
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: feature.info.title,
          }}
        />
        <Table>
          <TableBody>
            {Object.keys(fProps)
              .filter(
                (k) =>
                  (fProps.dtype === 'rmc' ||
                    fProps.dtype === 'uwb' ||
                    (k !== 'ts' && k !== 'dtype')) &&
                  k !== 'segment' &&
                  k !== 'direction_raw' &&
                  k !== 'moving'
              )
              .map((k) => {
                if (!fProps[k]) {
                  return null;
                }

                const value = formatValue(
                  feature,
                  fProps,
                  k,
                  intl,
                  warehouseTz
                );

                return (
                  <TableRow key={k}>
                    <TableCell style={{ padding: '0px', border: 'none' }}>
                      <Typography>
                        {messages[k] ? intl.formatMessage(messages[k]) : k}
                        <span>: </span>
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        border: 'none',
                        padding: '0px',
                        paddingLeft: '5px',
                      }}
                    >
                      {value}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default injectIntl(MapHover);
