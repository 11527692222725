import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  addFilter,
  fetchFloorplan,
  fetchSimulation,
  onChange,
  fetchOrdersEvents,
  IFetchOrdersEvents,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { ISimulationWithConfig } from '@models/Simulation';
import { IAppState } from '@reducers/index';
import { IFilter, IFilterChange } from '@reducers/filter';
import { getActiveLanguage } from '@selectors/app';
import { getSimulation } from '@selectors/simulations';
import PlayerContainer from './Player';

interface IProps {
  router: any;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const simulation = getSimulation(
    state.simulations,
    props.router.params.id
  ) as ISimulationWithConfig;
  
  return {
    id: props.router.params.id,
    filters: state.filter.filters,
    language: getActiveLanguage(state),
    loading: state.simulations.loading || state.floorplans.loading ||
      (state.orders.ordersEvents[simulation.floorplanId || ''] || {}).loading,
    simulation,
    ordersEvents: (state.orders.ordersEvents[simulation.floorplanId || ''] || {}).data,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addFilter: (properties: IFilter) => dispatch(addFilter(properties)),
  fetchFloorplan: (id: string) => dispatch(fetchFloorplan.request({ id })),
  fetchSimulation: (id: string) => dispatch(fetchSimulation.request({ id })),
  onChange: (properties: IFilterChange) => dispatch(onChange(properties)),
  fetchOrdersEvents: (properties: IFetchOrdersEvents) =>
    dispatch(fetchOrdersEvents.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerContainer));
