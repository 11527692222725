import Drawer from '@mui/material/Drawer';
import React, { FunctionComponent } from 'react';

import { IActiveMetrics, MetricsMetaState } from '@reducers/analytics';
import MetricsProvider from '../MetricsProvider';
import ZoneMetricsComponent from './ZoneMetrics';

interface IProps {
  children: React.ReactElement;
  meta: Record<string, MetricsMetaState>;
  activeMetrics: Record<string, IActiveMetrics>;
  toggleZoneMetrics: (id?: string) => void;
  /* If popup is open or not */
  zoneMetrics: boolean;
}

/**
 * Opens zone metrics popup (Mui Drawer).
 */
const Container: FunctionComponent<IProps> = ({
  activeMetrics,
  children,
  meta,
  toggleZoneMetrics,
  zoneMetrics,
}: IProps) => (
  <>
    <Drawer
      anchor="right"
      open={zoneMetrics}
      onClose={() => toggleZoneMetrics()}
      className="zone-metric"
    >
      <MetricsProvider section="user" filterId="popup">
        <ZoneMetricsComponent
          section="user"
          meta={meta}
          activeMetrics={activeMetrics}
        />
      </MetricsProvider>
    </Drawer>
    {children}
  </>
);

export default Container;
