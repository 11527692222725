import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { Component } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import Tour from 'reactour';

import { PRIMARY_COLOR } from '@app/utils/colors';
import { saveToLocalStorage } from '../localStorageUtils';
import getSteps from './tutorials';

const version = process.env.REACT_APP_VERSION;
const accentColor = `linear-gradient(to right, ${PRIMARY_COLOR}, ${PRIMARY_COLOR})`;
const disableBody = (target: any) => disableBodyScroll(target);
const enableBody = (target: any) => enableBodyScroll(target);

const messages = defineMessages({
  showWalkThrough: {
    defaultMessage: 'Show walk-through help.',
    id: 'dashboard.walkthrough',
  },
});

interface IProps {
  intl: IntlShape;
  theme: 'light' | 'dark';
  toggleWalkthough: (open: boolean) => void;
  type: string;
  tourStep?: number;
  walkthrough: boolean;
}

interface IState {
  disabledTour: boolean;
  step: number;
}

class Walktrough extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      disabledTour: false,
      step: 0,
    };

    this.closeTour = this.closeTour.bind(this);
    this.saveStep = this.saveStep.bind(this);
    this.showWalkThrough = this.showWalkThrough.bind(this);
    this.disableTour = this.disableTour.bind(this);
  }

  public showWalkThrough() {
    const { toggleWalkthough } = this.props;

    saveToLocalStorage(`walkthrough_${version}`, 'enabled');

    toggleWalkthough(true);
  }

  public closeTour() {
    const { toggleWalkthough } = this.props;

    toggleWalkthough(false);
  }

  public saveStep(newStep: number) {
    const { step } = this.state;

    if (step !== newStep) {
      this.setState({ step: newStep });
    }
  }

  public disableTour() {
    this.setState((currentState) => {
      const disabledTour = !currentState.disabledTour;

      saveToLocalStorage(
        `walkthrough_${version}`,
        disabledTour ? 'disabled' : 'enabled'
      );

      return { disabledTour: !currentState.disabledTour };
    });
  }

  public render() {
    const { intl, theme, tourStep, type, walkthrough } = this.props;
    const { disabledTour, step } = this.state;

    return (
      <>
        {theme === 'dark' ? (
          <style
            dangerouslySetInnerHTML={{
              __html: '.mask { background-color: #424242; }',
            }}
          />
        ) : null}
        <div className="walkthrough">
          <Tooltip title={intl.formatMessage(messages.showWalkThrough)}>
            <IconButton
              size="small"
              aria-label={intl.formatMessage(messages.showWalkThrough)}
              onClick={this.showWalkThrough}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Tour
          accentColor={accentColor}
          isOpen={walkthrough || false}
          className="mask"
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
          onRequestClose={this.closeTour}
          getCurrentStep={this.saveStep}
          rounded={5}
          startAt={step || tourStep || 0}
          steps={getSteps(type, this.disableTour, disabledTour)}
        />
      </>
    );
  }
}

export default injectIntl(Walktrough);
