import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { closeNotification } from '@actions/index';
import { IAppState } from '@reducers/index';
import ActionFeedback from './ActionFeedback';

const mapStateToProps = ({ actionFeedback }: IAppState) => ({
  notifications: actionFeedback.messages,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeNotification: (id: string) => dispatch(closeNotification(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionFeedback);
