import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import * as actions from '@actions/index';
import { ForkliftTypes } from '@app/dashboard/tabs/FinancialsTab/Calc';
import { IAppState } from '@reducers/index';
import CreateWarehouse from './CreateWarehouse';

interface IProps {
  onNext: () => void;
}

const mapStateToProps = (state: IAppState) => ({
  loading: state.warehouses.loading,
});

const mapDispatchToProps = (dispatch: Dispatch, { onNext }: IProps) => ({
  createWarehouse: (
    intl: IntlShape,
    name: string,
    site: string,
    timezone: string,
    country: string,
    metricsTotalPalletsMoved?: number,
    metricsFleetUsedCapacity?: number,
    numberOfShifts?: number,
    shiftHours?: number,
    workingDays?: number,
    targetForkliftType?: keyof typeof ForkliftTypes
  ) => {
    dispatch(
      actions.createWarehouse.request({
        intl,
        name,
        site,
        timezone,
        country,
        metricsTotalPalletsMoved,
        metricsFleetUsedCapacity,
        numberOfShifts,
        shiftHours,
        workingDays,
        targetForkliftType,
      })
    );
    onNext();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateWarehouse);
