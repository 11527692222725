import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { destroyMap, initMap, IMap, toggleMapHelp } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getFloorplanById } from '@selectors/floorplans';
import { getFloorplanSensorGroupWithStatus } from '@selectors/sensors';
import MapContainer from './MapContainer';

import './index.css';

interface IProps {
  floorplanId?: string;
  filterId?: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId, floorplanId } = props;

  const filter = state.filter.filters[filterId || ''] || {};

  return {
    activeSensorGroup: getFloorplanSensorGroupWithStatus(
      state,
      filter.floorplanId || floorplanId || ''
    ),
    floorplan: getFloorplanById(state, filter.floorplanId || floorplanId || ''),
    map: state.app.map,
    measurementUnits: getAccountMeasurementUnits(state.authentication),
    zoneIds: filter.zoneIds || [],
    zones: state.zones.zones,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  destroyMap: (id: string) => dispatch(destroyMap(id)),
  initMap: (props: IMap) => dispatch(initMap(props)),
  toggleMapHelp: (id: string, help?: React.ReactElement | string) => {
    dispatch(toggleMapHelp(id, help));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
