import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StopIcon from '@mui/icons-material/Stop';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ConfirmDialogActions, IConfirmDialog } from './types';

interface IProps extends IConfirmDialog {
  open: boolean;
  onClose: () => void;
}

class ConfirmDialog extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  public handleCancel() {
    const { onCancelation, onClose } = this.props;

    if (onCancelation) {
      onCancelation();
    }

    onClose();
  }

  public handleConfirm() {
    const { onConfirmation, onClose } = this.props;

    onConfirmation();
    onClose();
  }

  public render() {
    const { confirmType, message, open } = this.props;

    let title = null;
    if (confirmType === ConfirmDialogActions.ACTION) {
      title = (
        <FormattedMessage
          id="dashboard.dialogs.actionconfirm.title"
          defaultMessage="Confirm"
        />
      );
    } else if (confirmType === ConfirmDialogActions.DELETE) {
      title = (
        <FormattedMessage
          id="dashboard.dialogs.deletionconfirm.title"
          defaultMessage="Confirm DELETION"
        />
      );
    } else if (confirmType === ConfirmDialogActions.HIDE) {
      title = (
        <FormattedMessage
          id="dashboard.dialogs.hideconfirm.title"
          defaultMessage="Confirm HIDE"
        />
      );
    } else if (confirmType === ConfirmDialogActions.SHOW) {
      title = (
        <FormattedMessage
          id="dashboard.dialogs.showconfirm.title"
          defaultMessage="Confirm SHOW"
        />
      );
    } else if (confirmType === ConfirmDialogActions.STOP) {
      title = (
        <FormattedMessage
          id="dashboard.dialogs.stopconfirm.title"
          defaultMessage="Confirm STOP"
        />
      );
    }

    let cancel = null;
    if (confirmType === ConfirmDialogActions.ACTION) {
      cancel = (
        <FormattedMessage
          id="dashboard.dialogs.actionconfirm.buttom_cancel"
          defaultMessage="Cancel"
        />
      );
    } else if (confirmType === ConfirmDialogActions.DELETE) {
      cancel = (
        <FormattedMessage
          id="dashboard.dialogs.deletionconfirm.buttom_cancel"
          defaultMessage="Cancel"
        />
      );
    } else if (confirmType === ConfirmDialogActions.HIDE) {
      cancel = (
        <FormattedMessage
          id="dashboard.dialogs.hideconfirm.buttom_cancel"
          defaultMessage="Cancel"
        />
      );
    } else if (confirmType === ConfirmDialogActions.SHOW) {
      cancel = (
        <FormattedMessage
          id="dashboard.dialogs.showconfirm.buttom_cancel"
          defaultMessage="Cancel"
        />
      );
    } else if (confirmType === ConfirmDialogActions.STOP) {
      cancel = (
        <FormattedMessage
          id="dashboard.dialogs.stopconfirm.buttom_cancel"
          defaultMessage="Cancel"
        />
      );
    }

    let confirm = null;
    if (confirmType === ConfirmDialogActions.ACTION) {
      confirm = (
        <>
          <CheckIcon />
          <FormattedMessage
            id="dashboard.dialogs.actionconfirm.buttom_submit"
            defaultMessage="Confirm"
          />
        </>
      );
    } else if (confirmType === ConfirmDialogActions.DELETE) {
      confirm = (
        <>
          <DeleteForeverIcon />
          <FormattedMessage
            id="dashboard.dialogs.deletionconfirm.buttom_submit"
            defaultMessage="DELETE"
          />
        </>
      );
    } else if (confirmType === ConfirmDialogActions.HIDE) {
      confirm = (
        <>
          <DeleteForeverIcon />
          <FormattedMessage
            id="dashboard.dialogs.hideconfirm.buttom_submit"
            defaultMessage="HIDE"
          />
        </>
      );
    } else if (confirmType === ConfirmDialogActions.SHOW) {
      confirm = (
        <>
          <DeleteForeverIcon />
          <FormattedMessage
            id="dashboard.dialogs.showconfirm.buttom_submit"
            defaultMessage="SHOW"
          />
        </>
      );
    } else if (confirmType === ConfirmDialogActions.STOP) {
      confirm = (
        <>
          <StopIcon />
          <FormattedMessage
            id="dashboard.dialogs.stopconfirm.buttom_submit"
            defaultMessage="STOP"
          />
        </>
      );
    }

    return (
      <Dialog open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div>{message}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>{cancel}</Button>
          <Button
            onClick={this.handleConfirm}
            variant="contained"
            color="secondary"
          >
            {confirm}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmDialog;
