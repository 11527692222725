import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchRoutingData, onMyAssetChange } from '@actions/index';
import { IAppState } from '@reducers/index';
import { IFilter } from '@reducers/filter';
import Routing from './Routing';

interface IProps {
  filter: IFilter;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  loading: ((state.simulations.routing[props.filter.floorplanId || ''] || {}).config || {}).loading,
  data: ((state.simulations.routing[props.filter.floorplanId || ''] || {}).config || {}).data,
  assets: Object.values(state.assets.assets).filter((a) => a.floorplanId === props.filter.floorplanId),
  myAssetId: ((state.simulations.routing[props.filter.floorplanId || ''] || {}).config || {}).assetId || '',
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRoutingData: (floorplanId: string) => dispatch(fetchRoutingData.request({ floorplanId })),
  onMyAssetChange: (floorplanId: string, assetId: string) => dispatch(onMyAssetChange(floorplanId, assetId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
