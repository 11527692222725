import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  createConfigFile,
  fetchFloorplanSensorGroupStatus,
  fetchSensorGroupsByFloorplan,
  ICreateConfigFile,
  IFetchFloorplanSensors,
  IUpdateConfigFile,
  updateConfigFile,
} from '@actions/index';
import { ConfigFile } from '@models/ConfigFile';
import { IAppState } from '@reducers/index';
import { getFloorplanSensorGroupWithStatus } from '@selectors/sensors';
import ConfigFileFormDialog from './ConfigFileForm';

interface IProps {
  data?: ConfigFile;
}
const mapStateToProps = (state: IAppState, props: IProps) => {
  const { data } = props;

  return {
    activeSensorGroup: getFloorplanSensorGroupWithStatus(
      state,
      (data || {}).floorplanId || ''
    ),
    floorplanId: (data || {}).floorplanId || '',
    floorplans: state.floorplans.floorplans,
    warehouseId: (data || {}).warehouseId || '',
    warehouses: state.warehouses.warehouses,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
  createConfigFile: (properties: ICreateConfigFile) =>
    dispatch(createConfigFile.request(properties)),
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => {
    dispatch(fetchFloorplanSensorGroupStatus.request(properties));
  },
  fetchSensorGroupsByFloorplan: (properties: IFetchFloorplanSensors) =>
    dispatch(fetchSensorGroupsByFloorplan.request(properties)),
  updateConfigFile: (properties: IUpdateConfigFile) =>
    dispatch(updateConfigFile.request(properties)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigFileFormDialog);
