import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IUpdateFloorplan, updateFloorplan } from '@actions/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { getFloorplanById } from '@selectors/floorplans';
import { IAppState } from '@reducers/index';

import Form from './ItemLocationForm';

interface IProps {
  floorplanId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { floorplanId } = props;

  return {
    floorplan:
      getFloorplanById(state, floorplanId || '') || ({} as IncompleteFloorplan),

    loading: state.shallowLoading.loading.updateFloorplan || false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
