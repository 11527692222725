import { max, min } from 'lodash';
import uuid from 'uuid/v4';
import { Color, Path, Point, Matrix, Segment } from 'paper';
import { Component } from 'react';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { IDrawDefinition } from '@models/types';
import { transformMetersToPixels } from '@dashboard_utils/index';
import { transformPixelsToMeters } from '../../../../../../utils';
import { defaultTransformationMatrix } from '../../../consts';
import {
  IPathFeature,
  IToolEvent,
  IHitEvent,
  MapFeature,
  HoverFeature,
} from '../../../types';
import Paper from '../../Paper';
import MapImages from '../../MapImages';
import mapEvents from '../../eventEmitter';

interface IProps {
  id: string;
  featureName: string;
  featureTitle?: string;
  initDraw?: boolean;
  drawType?: string;
  drawColor?: string;
  drawings?: IDrawDefinition[];
  floorplan: IncompleteFloorplan;
  fillColor?: string;
  roundEdges?: boolean;
  editFeature?: MapFeature;
  hoverFeature?: HoverFeature;
  updateHoverFeature: (id: string, feature?: HoverFeature) => void;
  updateEditFeature: (id: string, feature?: MapFeature) => void;
  onChange?: (coordinates: IDrawDefinition[]) => void;
  singleDraw?: boolean;
  paper: Paper;
  mapImages: MapImages;
}

const PATH_EDIT_COLOR = '#1FD8F5';

class Draw extends Component<IProps> {
  public isDrawing = false;

  private draw: IPathFeature | undefined;

  private draws: IPathFeature[] = [];

  private isResizingDrawing = false;

  private isRotating = false;

  private lastRotationAngle?: number;

  constructor(props: IProps) {
    super(props);

    this.onKeySelect = this.onKeySelect.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseDrag = this.handleMouseDrag.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }

  public componentDidMount() {
    document.addEventListener('keydown', this.onKeySelect, { passive: true });

    const { paper } = this.props;
    const { tool } = paper;

    tool.on('mousedown', this.handleMouseDown);
    tool.on('mousedrag', this.handleMouseDrag);
    tool.on('mousemove', this.handleMouseMove);
    tool.on('mouseup', this.handleMouseUp);

    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { editFeature, initDraw, singleDraw } = this.props;

    if (!prevProps.initDraw && initDraw) {
      this.initDraw();
    }

    if (
      singleDraw &&
      ((!prevProps.editFeature && editFeature && !this.draw) ||
        (prevProps.editFeature && !editFeature && this.draw))
    ) {
      this.loadDraw();
    }

    if (
      prevProps.editFeature &&
      editFeature &&
      this.draws.length &&
      JSON.stringify(prevProps.editFeature.featureInfo.props || {}) !==
        JSON.stringify(editFeature.featureInfo.props || {})
    ) {
      this.handleFeaturePropsChange();
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeySelect);

    const { paper } = this.props;
    const { tool } = paper;

    tool.removeListener('mousedown', this.handleMouseDown);
    tool.removeListener('mousedrag', this.handleMouseDrag);
    tool.removeListener('mousemove', this.handleMouseMove);
    tool.removeListener('mouseup', this.handleMouseUp);

    this.removeDraws();
  }

  public handleMouseDown(event: IToolEvent) {
    const { paper } = this.props;

    if (event.event.ctrlKey === true) {
      this.resetDraws();

      const x = event.downPoint!.x || 0;
      const y = event.downPoint!.y || 0;

      this.createDraw(x, y);

      return;
    }

    if (this.draw !== undefined) {
      if (event.event.shiftKey === true) {
        this.isRotating = true;
        mapEvents.emit('lockmap', 'lock');
      }
    }

    // Remove selection on click with no hit
    if (
      event.event.ctrlKey === false &&
      event.event.shiftKey === false &&
      this.draw !== undefined &&
      this.draws.length
    ) {
      let [hit] = paper.scope.project!.hitTestAll(event.point!, {
        fill: false,
        match: (h: IHitEvent) => h.type === 'segment',
        segments: true,
        stroke: false,
        tolerance: 15,
      });
      if (!hit) {
        const [hitEvent] = paper.scope.project!.hitTestAll(event.point!, {
          fill: true,
          match: (h: IHitEvent) => h.type === 'stroke' || h.type === 'fill',
          segments: false,
          stroke: true,
          tolerance: 15,
        });

        hit = hitEvent;
      }

      if (!hit) {
        this.resetDraws();
      }
    }
  }

  public handleMouseDrag(event: IToolEvent) {
    const point = event.point || ({} as paper.Point);
    const downPoint = event.downPoint || ({} as paper.Point);

    if (this.draw !== undefined) {
      if (event.event.shiftKey === true) {
        // Feature draw rotation

        const { segments } = this.draw;
        // find center
        const x1 = min(segments.map((segment) => segment.point.x)) || 0;
        const y1 = min(segments.map((segment) => segment.point.y)) || 0;
        const x2 = max(segments.map((segment) => segment.point.x)) || 1;
        const y2 = max(segments.map((segment) => segment.point.y)) || 1;

        const center = new Point(x1 + (x2 - x1) / 2, y1 + (y2 - y1) / 2);

        const sAngle = Math.atan2(
          (downPoint.y || 0) - (center.y || 0),
          (downPoint.x || 0) - (center.x || 0)
        );
        const pAngle = Math.atan2(
          (point.y || 0) - (center.y || 0),
          (point.x || 0) - (center.x || 0)
        );

        const rotation =
          pAngle - sAngle >= (this.lastRotationAngle || 0) ? 1 : -1;
        this.lastRotationAngle = pAngle - sAngle;

        const newSegments = segments.map((segment) => {
          const newPoint = new Point(
            (center.x || 0) +
              Math.cos(0.0025 * rotation) *
                ((segment.point.x || 0) - (center.x || 0)) -
              Math.sin(0.0025 * rotation) *
                ((segment.point.y || 0) - (center.y || 0)),
            (center.y || 0) +
              Math.sin(0.0025 * rotation) *
                ((segment.point.x || 0) - (center.x || 0)) +
              Math.cos(0.0025 * rotation) *
                ((segment.point.y || 0) - (center.y || 0))
          );

          return new Segment(newPoint);
        });
        this.draw.removeSegments();
        this.draw.addSegments(newSegments);
      } else if (this.isDrawing === true) {
        this.draw.removeSegments();
        this.draw.addSegments([
          new Segment(new Point(downPoint.x || 0, downPoint.y || 0)),
          new Segment(new Point(point.x || 0, downPoint.y || 0)),
          new Segment(new Point(point.x || 0, point.y || 0)),
          new Segment(new Point(downPoint.x || 0, point.y || 0)),
        ]);
      }
    }
  }

  public handleMouseMove(event: IToolEvent) {
    const { editFeature, paper } = this.props;

    if (
      !editFeature ||
      !event.item ||
      !event.item.featureInfo ||
      (editFeature.featureInfo.props || {}).id !==
        (event.item.featureInfo.props || {}).id
    ) {
      return;
    }

    const point = event.point || ({} as paper.Point);
    const hit = paper.scope.project!.hitTestAll(point, {
      fill: false,
      match: (h: paper.HitResult) => h.type === 'segment',
      segments: true,
      stroke: false,
      tolerance: 15,
    })[0];

    if (hit) {
      document.body.style.cursor = 'move';

      return;
    }

    // Add new point or drag feature (alt key)
    if (event.event.altKey === false) {
      document.body.style.cursor = 'copy';
    } else {
      document.body.style.cursor = 'move';
    }
  }

  public handleMouseUp() {
    const {
      floorplan,
      id,
      updateEditFeature,
      editFeature,
      mapImages,
      onChange,
      singleDraw,
    } = this.props;

    if (this.isDrawing || this.isResizingDrawing || this.isRotating) {
      this.isDrawing = false;
      this.isResizingDrawing = false;
      this.isRotating = false;

      mapEvents.emit('lockmap', 'unlock');

      if (this.draw !== undefined) {
        this.draw.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
        const featureInfo = {
          ...this.draw.featureInfo,
          coordinates: this.draw.segments!.map((s) =>
            transformPixelsToMeters(
              [s.point!.x || 0, s.point!.y || 0],
              floorplan.transformationMatrix || defaultTransformationMatrix,
              floorplan.scale || 1
            )
          ),
        };
        this.draw.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

        if (onChange) {
          onChange(
            this.draws.map((draw) => ({
              coordinates: draw.segments!.map((s) => {
                s.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
                const point = transformPixelsToMeters(
                  [s.point!.x || 0, s.point!.y || 0],
                  floorplan.transformationMatrix || defaultTransformationMatrix,
                  floorplan.scale || 1
                );
                s.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

                return point;
              }),
              meta: {
                id: (draw.featureInfo.props || {}).id || uuid(),
                name: (draw.featureInfo.props || {}).name,
                type: (draw.featureInfo.props || {}).type,
              },
            }))
          );
        }

        updateEditFeature(id, {
          ...(singleDraw ? editFeature || this.draw : this.draw),
          featureInfo,
        } as MapFeature);
      }
    } else if (editFeature) {
      updateEditFeature(id, undefined);
    }
  }

  public handleFeaturePropsChange() {
    const { editFeature, fillColor, floorplan, mapImages, onChange } =
      this.props;

    const index = this.draws.findIndex(
      (draw: IPathFeature) =>
        draw.featureInfo.id === editFeature!.featureInfo.id
    );

    if (index !== undefined) {
      this.draws[index].featureInfo = editFeature!.featureInfo;

      if (fillColor) {
        this.draws[index].fillColor = new Color(fillColor);
      }

      this.draws[index].strokeWidth =
        ((editFeature!.featureInfo || {}).props || {}).type !== 'wall' ? 6 : 1;

      if (onChange) {
        onChange(
          this.draws.map((draw) => ({
            coordinates: draw.segments!.map((s) => {
              s.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
              const point = transformPixelsToMeters(
                [s.point!.x || 0, s.point!.y || 0],
                floorplan.transformationMatrix || defaultTransformationMatrix,
                floorplan.scale || 1
              );
              s.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

              return point;
            }),
            meta: {
              id: (draw.featureInfo.props || {}).id || uuid(),
              name: (draw.featureInfo.props || {}).name,
              type: (draw.featureInfo.props || {}).type,
            },
          }))
        );
      }
    }
  }

  public onKeySelect(e: KeyboardEvent) {
    const { floorplan, onChange, mapImages } = this.props;

    if (e.code === 'Delete') {
      const activeDrawIndex = this.draws.findIndex(
        (draw) =>
          draw.strokeColor &&
          draw.strokeColor.toString() === new Color(PATH_EDIT_COLOR).toString()
      );

      if (activeDrawIndex !== -1) {
        this.draws[activeDrawIndex].remove();
        this.draws.splice(activeDrawIndex, 1);
      }

      if (this.draw) {
        this.draw.remove();
        this.draw = undefined;
      }

      if (onChange) {
        onChange(
          this.draws.map((draw) => ({
            coordinates: draw.segments!.map((s) => {
              s.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
              const point = transformPixelsToMeters(
                [s.point!.x || 0, s.point!.y || 0],
                floorplan.transformationMatrix || defaultTransformationMatrix,
                floorplan.scale || 1
              );
              s.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

              return point;
            }),
            meta: {
              id: (draw.featureInfo.props || {}).id || uuid(),
              name: (draw.featureInfo.props || {}).name,
              type: (draw.featureInfo.props || {}).type,
            },
          }))
        );
      }
    }
  }

  public setEditablePathEvents(path: IPathFeature) {
    const { floorplan, id, onChange, paper, mapImages } = this.props;

    let newPoint: any;
    let anchorDragPoint: paper.Point | undefined;
    let segment: paper.Segment | undefined;
    let originalSegments: paper.Segment[] | undefined;
    let didDrag: boolean = false;

    // eslint-disable-next-line no-param-reassign
    path.onMouseDown = (e: IToolEvent) => {
      const { updateEditFeature, editFeature, singleDraw } = this.props;

      const point = e.point || ({} as paper.Point);

      newPoint = undefined;
      anchorDragPoint = undefined;
      segment = undefined;
      anchorDragPoint = undefined;
      didDrag = false;

      let [hit] = paper.scope.project!.hitTestAll(point, {
        fill: false,
        match: (h: paper.HitResult) => h.type === 'segment',
        segments: true,
        stroke: false,
        tolerance: 15,
      });

      if (!hit) {
        const [hitEvent] = paper.scope.project!.hitTestAll(point, {
          fill: true,
          match: (h: paper.HitResult) =>
            h.type === 'stroke' || h.type === 'fill',
          segments: false,
          stroke: true,
          tolerance: 15,
        });

        hit = hitEvent;
      }

      let featureInfo = { ...path.featureInfo };
      if (hit) {
        this.isResizingDrawing = true;
        mapEvents.emit('lockmap', 'lock');
        /**
         * if doesn't has edition color then only select path
         * else if it is dragging feature (alt key) sve original segments and anchor dra point to be used in MouseDrag
         * else adds new point
         */
        if (
          this.draws.length &&
          path.strokeColor &&
          path.strokeColor.toString() !== new Color(PATH_EDIT_COLOR).toString()
        ) {
          this.resetDraws();
          /* eslint-disable no-param-reassign */
          path.strokeColor = new Color(PATH_EDIT_COLOR);
          path.fullySelected = true;
          path.selected = true;
          /* eslint-enable no-param-reassign */
          this.draw = path;
          document.body.style.cursor = 'copy';
        } else if (e.event.altKey === true && this.draw !== undefined) {
          originalSegments = this.draw.segments.map((s) => s);
          anchorDragPoint = e.point;
        } else if (hit.type === 'stroke') {
          // Mouse down hit a line
          newPoint = {
            index: hit.location!.curve.index + 1,
            point: e.point,
          };

          path.insert(hit.location!.curve.index + 1, point);

          if (path.featureInfo !== undefined) {
            featureInfo = {
              ...featureInfo,
              coordinates: path.segments!.map((s) =>
                transformPixelsToMeters(
                  [s.point!.x || 0, s.point!.y || 0],
                  floorplan.transformationMatrix || defaultTransformationMatrix,
                  floorplan.scale || 1
                )
              ),
            };
          }
        } else if (hit.type === 'segment') {
          // Mouse down hit an verctice
          segment = path.segments!.find((s) => s === hit.segment);
        }

        updateEditFeature(id, {
          ...(singleDraw ? editFeature || this.draw : this.draw),
          featureInfo,
        } as MapFeature);
      }
    };

    // eslint-disable-next-line no-param-reassign
    path.onMouseDrag = (event: paper.MouseEvent) => {
      const point = event.point || ({} as paper.Point);

      didDrag = true;

      if (newPoint) {
        path.removeSegment(newPoint.index);
        path.insert(newPoint.index, point);
      } else if (segment !== undefined) {
        segment.point = event.point;
      } else if (
        // if draw exists, drag it
        this.draw !== undefined &&
        anchorDragPoint !== undefined &&
        originalSegments !== undefined
      ) {
        const offsetX = anchorDragPoint.x - point.x;
        const offsetY = anchorDragPoint.y - point.y;
        const newSegments = originalSegments.map(
          (drawSegment) =>
            new Segment(
              new Point(
                drawSegment.point.x - offsetX,
                drawSegment.point.y - offsetY
              )
            )
        );
        this.draw.removeSegments();
        this.draw.addSegments(newSegments);
      }
    };

    // eslint-disable-next-line no-param-reassign
    path.onMouseUp = (event: IToolEvent) => {
      if (
        segment !== undefined &&
        didDrag === false &&
        event.event.button === 2
      ) {
        // Remove vertice on feature when right-click was done on a vertice and didn't drag it
        const index = path.segments!.findIndex((s) => s === segment);
        path.removeSegment(index);
      }

      if (onChange) {
        onChange(
          this.draws.map((draw) => ({
            coordinates: draw.segments!.map((s) => {
              s.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
              const point = transformPixelsToMeters(
                [s.point!.x || 0, s.point!.y || 0],
                floorplan.transformationMatrix || defaultTransformationMatrix,
                floorplan.scale || 1
              );
              s.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

              return point;
            }),
            meta: {
              id: (draw.featureInfo.props || {}).id || uuid(),
              name: (draw.featureInfo.props || {}).name,
              type: (draw.featureInfo.props || {}).type,
            },
          }))
        );
      }
    };

    const mouseHoverEdition = (e: IToolEvent) => {
      const point = e.point || ({} as paper.Point);
      let [hit] = paper.scope.project!.hitTestAll(point, {
        fill: false,
        match: (h: paper.HitResult) => h.type === 'segment',
        segments: true,
        stroke: false,
        tolerance: 15,
      });

      if (!hit) {
        const [hitEvent] = paper.scope.project!.hitTestAll(point, {
          fill: true,
          match: (h: paper.HitResult) =>
            h.type === 'stroke' || h.type === 'fill',
          segments: false,
          stroke: true,
          tolerance: 15,
        });
        hit = hitEvent;
      }
      if (this.isResizingDrawing === false) {
        // Select new feature
        if (
          this.draws.length &&
          path.strokeColor &&
          path.strokeColor.toString() !== new Color(PATH_EDIT_COLOR).toString()
        ) {
          document.body.style.cursor = 'crosshair';

          return null;
        }

        // Add new point or drag feature (alt key)
        if (hit && hit.type === 'stroke') {
          if (e.event.altKey === false) {
            document.body.style.cursor = 'copy';
          } else if (e.event.altKey === true) {
            document.body.style.cursor = 'move';
          }

          return null;
        }

        // Move feature point point
        if (hit && hit.type === 'segment') {
          document.body.style.cursor = 'move';

          return null;
        }
      }

      return null;
    };
    /* eslint-disable no-param-reassign */
    path.onMouseEnter = mouseHoverEdition;
    path.onMouseMove = mouseHoverEdition;
    path.onMouseLeave = () => {
      document.body.style.cursor = 'default';
    };
    /* eslint-enable no-param-reassign */
  }

  public createDraw(
    x: number,
    y: number,
    height = 5,
    width = 5,
    center = false
  ) {
    const { drawType, featureName, featureTitle, roundEdges, singleDraw } =
      this.props;

    const path = new Path() as IPathFeature;
    path.strokeColor = new Color(PATH_EDIT_COLOR);
    path.strokeWidth = 6;
    if (roundEdges) {
      path.strokeCap = 'round';
      path.dashArray = [10, 15];
    }
    if (!center) {
      path.add(new Point(x, y));
      path.add(new Point(x + width, y));
      path.add(new Point(x + width, y + height));
      path.add(new Point(x, y + height));
    } else {
      path.add(new Point(x - width / 2, y - height / 2));
      path.add(new Point(x + width / 2, y - height / 2));
      path.add(new Point(x + width / 2, y + height / 2));
      path.add(new Point(x - width / 2, y + height / 2));
    }
    path.closePath();
    path.featureInfo = {
      id: `${featureName}_${this.draws.length + 1}`,
      props: {
        name:
          featureTitle ||
          `${featureName.charAt(0).toUpperCase() + featureName.slice(1)} #${
            this.draws.length + 1
          }`,
      },
      title: `${featureName.charAt(0).toUpperCase() + featureName.slice(1)} #${
        this.draws.length + 1
      }`,
      type: drawType || 'draw',
    };

    this.isDrawing = true;
    mapEvents.emit('lockmap', 'lock');

    this.setEditablePathEvents(path);
    path.fullySelected = true;
    path.selected = true;
    this.draw = path;

    if (!singleDraw) {
      this.draws.push(path);
    }
  }

  public load() {
    const {
      drawColor,
      drawings,
      drawType,
      featureName,
      fillColor,
      roundEdges,
    } = this.props;
    const { floorplan, mapImages } = this.props;

    this.initDraw();

    if (drawings && drawings.length > 0) {
      drawings.forEach((drawing) => {
        const path = new Path() as IPathFeature;
        path.strokeColor = new Color(`${drawColor || '#000000'}`);
        if (fillColor) {
          path.fillColor = new Color(fillColor);
        }
        path.strokeWidth = drawing.meta.type !== 'wall' ? 6 : 1;
        if (roundEdges) {
          path.strokeCap = 'round';
          path.dashArray = [10, 15];
        }
        drawing.coordinates.map((point) =>
          path.add(
            new Point(
              transformMetersToPixels(
                [point[0], point[1]],
                floorplan.transformationMatrix || defaultTransformationMatrix,
                floorplan.scale || 1
              )
            )
          )
        );
        path.closePath();

        path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
        path.featureInfo = {
          id: `${featureName}_${this.draws.length + 1}`,
          props: {
            id: drawing.meta.id,
            name: drawing.meta.name,
            type: drawing.meta.type,
          },
          title: drawing.meta.name,
          type: drawType || 'draw',
        };

        this.setEditablePathEvents(path);

        this.draws.push(path);
      });
    }
  }

  public initDraw() {
    const { id, paper, initDraw, mapImages, singleDraw, updateEditFeature } =
      this.props;

    if (initDraw) {
      const x = paper.scope.view.center.x || 0;
      const y = paper.scope.view.center.y || 0;

      const featureWidth = mapImages.width / 2;
      const featureHeight = mapImages.height / 2;

      this.createDraw(x, y, featureWidth, featureHeight, true);

      if (singleDraw === true) {
        updateEditFeature(id, this.draw);
      }
    }
  }

  public loadDraw() {
    const { editFeature } = this.props;

    this.removeDraws();

    if (editFeature) {
      const path = new Path() as IPathFeature;
      path.strokeColor = new Color(PATH_EDIT_COLOR);
      path.strokeWidth =
        (editFeature.featureInfo.props || {}).type !== 'wall' ? 6 : 1;
      path.fullySelected = true;
      path.selected = true;
      path.closePath();
      path.addSegments(editFeature.segments || []);
      path.featureInfo = editFeature.featureInfo;

      this.setEditablePathEvents(path);
      path.fullySelected = true;
      path.selected = true;
      this.draw = path;
    }
  }

  public resetDraws() {
    const { drawColor, fillColor } = this.props;

    if (this.draw && !this.draws.length) {
      this.draw.remove();
    }

    this.draw = undefined;

    this.draws.forEach((draw) => {
      /* eslint-disable no-param-reassign */
      draw.fullySelected = false;
      draw.selected = false;
      draw.strokeColor = new Color(drawColor || '#000000');
      if (fillColor) {
        draw.fillColor = new Color(fillColor);
      }
      /* eslint-enable no-param-reassign */
    });
  }

  public removeDraws() {
    if (this.draw) {
      this.draw.remove();
      this.draw = undefined;
    }

    this.draws.forEach((draw) => draw.remove());
    this.draws = [];

    this.isDrawing = false;

    mapEvents.emit('lockmap', 'unlock');
  }

  public render() {
    return null;
  }
}

export default Draw;
