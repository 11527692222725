import { createCanvas } from 'canvas';
import FileSaver from 'file-saver';
import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { convertToFinalUnits, Date, getColor } from '@dashboard_utils/index';
import messages from '../../messages';
import mapEvents from '../eventEmitter';
import Paper from '../Paper';

import './HeatmapScale.css';

export type LayerScale = {
  max: number;
  min: number;
  gradient?: boolean;
  logarithmicScale?: boolean;
  singleColor?: boolean;
  units?: string;
  label?: string;
};

export interface IProps {
  intl: IntlShape;
  measurementUnits: string;
  paper: Paper;
  scale?: LayerScale;
}

class LayerScaleComponent extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.handleDownload = this.handleDownload.bind(this);
  }

  public componentDidMount() {
    mapEvents.on('download', this.handleDownload);
  }

  public componentWillUnmount() {
    mapEvents.removeListener('download', this.handleDownload);
  }

  public handleDownload() {
    const { paper, scale, measurementUnits } = this.props;

    const paperCanvas = paper.scope.view.element;
    if (!paperCanvas) {
      return null;
    }

    const canvas = createCanvas(paperCanvas.width, paperCanvas.height);
    const context = canvas.getContext('2d');
    context.drawImage(paperCanvas as any, 0, 0);

    if (!scale) {
      return FileSaver.saveAs(canvas.toDataURL(), 'assets/map.png');
    }

    const min =
      convertToFinalUnits(
        measurementUnits,
        scale.units || 'percentage',
        scale.min
      ) || 0;
    const max =
      convertToFinalUnits(
        measurementUnits,
        scale.units || 'percentage',
        scale.max
      ) || 0;

    if (min === max) {
      return FileSaver.saveAs(canvas.toDataURL(), 'assets/map.png');
    }

    const unitsLabel = this.getUnitsLabel();
    const units = scale.units || '%';
    const logarithmicScale = scale.logarithmicScale || false;

    const gradientSize = Math.round(350);
    new Array(gradientSize).fill(undefined).forEach((a, index) => {
      context.beginPath();
      context.moveTo(canvas.width - 80, 30 + index);
      context.lineTo(canvas.width - 45, 30 + index);
      context.lineWidth = 1;
      context.strokeStyle = getColor(
        min + ((max - min) / (gradientSize - 1)) * (gradientSize - index - 1),
        min,
        max,
        false,
        true,
        logarithmicScale
      );
      context.stroke();
    });

    context.font = 'bold 12px Arial';
    context.textAlign = 'right';
    context.fillStyle = '#000';

    const maxValue =
      convertToFinalUnits(measurementUnits, units || 'percentage', scale.max) ||
      0;
    const minValue =
      convertToFinalUnits(measurementUnits, units || 'percentage', scale.min) ||
      0;

    context.fillText(unitsLabel, canvas.width - 18, 12);

    context.font = 'normal 10px Arial';
    for (let i = 0; i < 12; i += 1) {
      let value;
      if (units === 'hours') {
        value = new Date(
          'Etc/UTC',
          minValue + ((maxValue - minValue) / 11) * (11 - i) * 60 * 60 * 1000
        ).format('HH:mm');
      } else {
        value = Number(
          minValue + ((maxValue - minValue) / 11) * (11 - i)
        ).toFixed(2);
      }
      if (min !== max || !i || i === 11) {
        context.fillText(
          value,
          canvas.width - 18,
          38 + ((gradientSize - 12) / 11) * i
        );
      }
    }

    return FileSaver.saveAs(canvas.toDataURL(), 'assets/map.png');
  }

  public getUnitsLabel() {
    const { intl, measurementUnits, scale } = this.props;
    const { label, units } = scale || {};

    let unitsLabel = label || '%';
    if (units === 'duration') {
      unitsLabel = intl.formatMessage(messages.hours);
    } else if (units === 'distance') {
      unitsLabel = measurementUnits !== 'si' ? 'ft' : 'm';
    } else if (units === 'speed') {
      unitsLabel = measurementUnits !== 'si' ? 'mph' : 'km/h';
    } else if (units === 'count') {
      // intl.formatMessage(messages.stops);
      unitsLabel = '';
    } else if (units === 'percentage') {
      unitsLabel = '%';
    } else if (units === 'rssi') {
      unitsLabel = 'RSSI';
    }

    return unitsLabel;
  }

  public render() {
    const { measurementUnits, scale } = this.props;

    if (!scale) {
      return null;
    }

    const min =
      convertToFinalUnits(
        measurementUnits,
        scale.units || 'percentage',
        scale.min
      ) || 0;
    const max =
      convertToFinalUnits(
        measurementUnits,
        scale.units || 'percentage',
        scale.max
      ) || 0;

    if (min === 0 && max === 0) {
      return null;
    }

    const unitsLabel = this.getUnitsLabel();
    const units = scale.units || '%';
    const gradient = scale.gradient !== undefined ? scale.gradient : true;
    const logarithmicScale = scale.logarithmicScale || false;
    const singleColor = scale.singleColor || false;

    const formatUtc = (value: number) =>
      `${Math.floor(value)}:${`0${Math.ceil(
        // Escape NaN if rest is 0
        Math.floor(value) ? (value % Math.floor(value)) * 60 : 0
      )}`.slice(-2)}`;

    return (
      <div className="custom-color-scale">
        {new Array(250).fill(null).map((a, index) => {
          let backgroundColor = 'rgb(253, 100, 253)';
          if (min !== max) {
            backgroundColor = getColor(
              min + ((max - min) / 249) * (250 - index - 1),
              min,
              max,
              singleColor,
              gradient,
              logarithmicScale
            );
          }

          return (
            <div
              key={index}
              className="custom-color-scale-square"
              style={{ backgroundColor }}
            />
          );
        })}
        <b className="custom-color-scale">{unitsLabel}</b>
        <i className="custom-color-scale custom-color-scale-0">
          {units === 'hours' ? formatUtc(max) : Number(max).toFixed(2)}
        </i>
        {min !== max ? (
          <>
            <i className="custom-color-scale custom-color-scale-1">
              {units === 'hours'
                ? formatUtc(min + ((max - min) / 11) * 10)
                : Number(min + ((max - min) / 11) * 10).toFixed(2)}
            </i>
            <i className="custom-color-scale custom-color-scale-2">
              {units === 'hours'
                ? formatUtc(min + ((max - min) / 11) * 9)
                : Number(min + ((max - min) / 11) * 9).toFixed(2)}
            </i>
            <i className="custom-color-scale custom-color-scale-3">
              {units === 'hours'
                ? formatUtc(min + ((max - min) / 11) * 8)
                : Number(min + ((max - min) / 11) * 8).toFixed(2)}
            </i>
            <i className="custom-color-scale custom-color-scale-4">
              {units === 'hours'
                ? formatUtc(min + ((max - min) / 11) * 7)
                : Number(min + ((max - min) / 11) * 7).toFixed(2)}
            </i>
            <i className="custom-color-scale custom-color-scale-5">
              {units === 'hours'
                ? formatUtc(min + ((max - min) / 11) * 6)
                : Number(min + ((max - min) / 11) * 6).toFixed(2)}
            </i>
            <i className="custom-color-scale custom-color-scale-6">
              {units === 'hours'
                ? formatUtc(min + ((max - min) / 11) * 5)
                : Number(min + ((max - min) / 11) * 5).toFixed(2)}
            </i>
            <i className="custom-color-scale custom-color-scale-7">
              {units === 'hours'
                ? formatUtc(min + ((max - min) / 11) * 4)
                : Number(min + ((max - min) / 11) * 4).toFixed(2)}
            </i>
            <i className="custom-color-scale custom-color-scale-8">
              {units === 'hours'
                ? formatUtc(min + ((max - min) / 11) * 3)
                : Number(min + ((max - min) / 11) * 3).toFixed(2)}
            </i>
            <i className="custom-color-scale custom-color-scale-9">
              {units === 'hours'
                ? formatUtc(min + ((max - min) / 11) * 2)
                : Number(min + ((max - min) / 11) * 2).toFixed(2)}
            </i>
          </>
        ) : null}
        <i className="custom-color-scale custom-color-scale-10">
          {units === 'hours' ? formatUtc(min) : Number(min).toFixed(2)}
        </i>
      </div>
    );
  }
}

export default injectIntl(LayerScaleComponent);
