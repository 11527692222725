import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchMetric, FetchMetric } from '@actions/index';
import MetricMeta from '@models/MetricMeta';
import { getSelectedLocale } from '@selectors/app';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { IAppState } from '@reducers/index';
import { MetricAggregationWithFeedback } from '@reducers/analytics';
import AggregationForm from './AggregationForm';

interface IProps {
  filterId: string;
  metricId: string;
  id?: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId, metricId, id } = props;

  const metric =
    (state.analytics.metrics[filterId] || {})[metricId || ''] || {};

  const filter = state.filter.filters[filterId] || {};

  return {
    warehouseId: filter.warehouseId || '',
    floorplanId: filter.floorplanId || '',
    zoneIds: filter.zoneIds || [],

    tags: filter.tags || [],
    assetIds: filter.assetIds || [],

    templateIds: filter.templateIds || [],
    ruleIds: filter.ruleIds || [],

    startDate: filter.startDate,
    endDate: filter.endDate,

    locale: getSelectedLocale(state),
    measurementUnits: getAccountMeasurementUnits(state.authentication),

    aggregationState:
      ((metric.data || {}).aggregations || {})[id || ''] ||
      ({} as MetricAggregationWithFeedback),

    metricMeta:
      ((state.analytics.meta[filterId || ''] || {}).metrics || []).find(
        (m) => m.id === metricId
      ) || ({} as MetricMeta),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMetric: (properties: FetchMetric) =>
    dispatch(fetchMetric.request(properties)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AggregationForm);
