import React, { Component } from 'react';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

interface IProps {
  router: any;
  language: string;
}

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  bottom: 20,
  left: 0,
  right: 0,
  margin: '0 auto',
});

class Done extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.scan = this.scan.bind(this);
    this.back = this.back.bind(this);
    this.backExit = this.backExit.bind(this);
  }
  
  public scan() {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/routing/${router.params.floorplanId || ''}/scan/location`);
  }

  public back() {
    const { language, router } = this.props;

    router.navigate(`/${language}/dashboard/routing`);
  }

  public backExit() {
    const { language, router } = this.props;

    router.navigate(`/${language}/dashboard`);
  }

  public render() {
    return (
      <Box className="tab-content-inner">
        <Box p={2}>
          <Button variant="contained" onClick={this.back} fullWidth>
            <Typography variant="h3" style={{ fontWeight: 700 }}>Confirm & Quick Order</Typography>
          </Button>

          <Box pt={2}>
            <Button variant="contained" onClick={this.backExit} fullWidth>
              <Typography variant="h3" style={{ fontWeight: 700 }}>Confirm & End Session</Typography>
            </Button>
          </Box>
        </Box>
        <StyledFab color="primary" onClick={this.scan}>
          <QrCodeScannerIcon fontSize="large" />
        </StyledFab>
      </Box>
    );
  }
}

export default Done;
