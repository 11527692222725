import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import Map from '@app/common/FullMap';
import { withRouter } from '@app/utils/withRouter';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import MUITable, { ITableColumn } from '@app/common/Table';

const messages = defineMessages({
  thReference: {
    defaultMessage: 'Reference',
    id: 'dashboard.items.table.reference',
  },
  floorplan: {
    defaultMessage: 'Floor plan',
    id: 'dashboard.datamap.floorplan',
  },
  racks: {
    defaultMessage: 'Racks',
    id: 'dashboard.livemap.layers.racks',
  },
});

interface IProps {
  filterId: string;
  floorplan: IncompleteFloorplan;
  intl: IntlShape;
  router: any;
  language: string;
  openImportItemsForm: () => void;
  openImportStocksForm: () => void;
}

interface IState {
  anchorEl?: HTMLElement;
  columns: ITableColumn[];
}

class Locations extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      columns: [
        { label: intl.formatMessage(messages.thReference), name: 'reference' },
      ],
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleItemImport = this.handleItemImport.bind(this);
    this.handleStockImport = this.handleStockImport.bind(this);
    this.goToItemsListMapping = this.goToItemsListMapping.bind(this);
  }

  public handleClick(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleClose() {
    this.setState({ anchorEl: undefined });
  }

  public handleItemImport() {
    const { openImportItemsForm } = this.props;

    this.setState({ anchorEl: undefined }, openImportItemsForm);
  }

  public handleStockImport() {
    const { openImportStocksForm } = this.props;

    this.setState({ anchorEl: undefined }, openImportStocksForm);
  }

  public goToItemsListMapping() {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/items`);
  }

  public render() {
    const { anchorEl, columns } = this.state;
    const { filterId, intl, floorplan } = this.props;

    return (
      <Grid container spacing={2} style={{ flex: 1 }}>
        <Grid item sm={4}>
          <Card style={{ flex: 1 }}>
            <CardHeader
              action={
                <>
                  <IconButton
                    aria-label="More"
                    aria-owns={anchorEl ? 'long-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={this.handleItemImport}>
                      <ListItemIcon>
                        <CloudUploadIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <FormattedMessage
                            id="dashboard.items.importitems"
                            defaultMessage="Import Items"
                          />
                        }
                      />
                    </MenuItem>
                    <MenuItem onClick={this.handleStockImport}>
                      <ListItemIcon>
                        <CloudUploadIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <FormattedMessage
                            id="dashboard.items.importitemlocations"
                            defaultMessage="Import Stocks"
                          />
                        }
                      />
                    </MenuItem>
                    <MenuItem onClick={this.goToItemsListMapping}>
                      <ListItemIcon>
                        <FormatListBulletedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <FormattedMessage
                            id="dashboard.items.itemslist"
                            defaultMessage="Items List"
                          />
                        }
                      />
                    </MenuItem>
                  </Menu>
                </>
              }
              title={
                <FormattedMessage
                  id="dashboard.items.title"
                  defaultMessage="Items / Locations"
                />
              }
            />
            <CardContent>
              <MUITable
                columns={columns}
                data={floorplan.itemLocations || []}
                tableId="items"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={8}>
          <Map
            filterId={filterId}
            forceDisabledLayers={['gmaps']}
            layers={{
              floorplan: {
                id: 'floorplan',
                active: true,
                label: intl.formatMessage(messages.floorplan),
                disabled: true,
                primary: true,
                secondary: false,
              },
              racks: {
                id: 'racks',
                active: true,
                label: intl.formatMessage(messages.racks),
                disabled: true,
                primary: false,
                secondary: true,
              },
            }}
            actions={{
              itemLocations: {
                locations: JSON.parse(
                  JSON.stringify(floorplan.itemLocations || [])
                ),
              },
              select: {},
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default injectIntl(withRouter(Locations));
