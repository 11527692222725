import TableCell from '@mui/material/TableCell';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import { styled } from '@mui/material/styles';
import React, { FunctionComponent, RefAttributes } from 'react';

const StyledTableCell = styled(TableCell)(() => ({
  padding: '3px',
}));

const CustomTableCell: FunctionComponent<Omit<TableCellProps, "ref"> & RefAttributes<HTMLDivElement>> = React.forwardRef(
  (properties: any, ref: any) => {
    const { children } = properties;

    return (
      <StyledTableCell ref={ref} {...properties}>
        {children}
      </StyledTableCell>
    );
  }
);

export default CustomTableCell;
