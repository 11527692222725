import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import SearchIcon from '@mui/icons-material/Search';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import React, { Component } from 'react';

import Map from '@app/common/FullMap';
import { IFilter } from '@reducers/filter';
import Loading from '@app/common/Loading';
import { IAddOrderToBasket, IFetchOrders } from '@actions/routing';
import { FullBasket, Order } from '@models/Order';
import { format } from 'date-fns';
import LoadingButton from '@app/common/LoadingButton';
import BottomSheet from '../OrderSheet';
import CurrentBasketSheet from '../CurrentBasketSheet';

interface IProps {
  filter: IFilter;
  filterId: string;
  router: any;
  language: string;
  fetchCurrentBasket: () => void;
  fetchOrders: (properties: IFetchOrders) => void;
  addOrderToCurrentBasket: (properties: IAddOrderToBasket) => void;
  addOrderToNewBasket: (properties: IAddOrderToBasket) => void;
  loading: boolean;
  orders: Order[];
  quickOrder: () => void;
  quickOrderLoading: boolean;
  quickOrderData?: Order;
  currentBasket?: FullBasket;
}
interface IState {
  quickOrderSheet: boolean;
  currentBasketSheet: boolean;
  expanded: boolean;
  search: string;
}

const FloatingGrid = styled(Grid)({
  position: 'absolute',
  zIndex: 1,
  bottom: 60,
  left: 0,
  right: 0,
  margin: '0 auto',
  width: '100%',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class Routing extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      quickOrderSheet: false,
      currentBasketSheet: false,
      expanded: false,
      search: '',
    };

    this.scan = this.scan.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.addOrder = this.addOrder.bind(this);
    this.quickOrder = this.quickOrder.bind(this);
    this.closeQuickOrderSheet = this.closeQuickOrderSheet.bind(this);
    this.autoPick = this.autoPick.bind(this);
    this.closeCurrentBasketSheet = this.closeCurrentBasketSheet.bind(this);
  }

  public componentDidMount() {
    const { fetchOrders, fetchCurrentBasket } = this.props;

    fetchOrders({});
    fetchCurrentBasket();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { fetchCurrentBasket } = this.props;

    if (prevProps.loading && !this.props.loading) {
      fetchCurrentBasket();
    }
  }

  public scan() {
    const { router, language, filter } = this.props;

    const floorplanId = filter.floorplanId || '';

    router.navigate(`/${language}/dashboard/routing/${floorplanId}/scan/location`);
  }

  public handleToggle() {
    const { expanded } = this.state;

    this.setState({ expanded: !expanded });
  }

  public handleSearch(event: any) {
    this.setState({ search: event.target.value, expanded: !!event.target.value });
  }

  public addOrder(id: string) {
    const { addOrderToCurrentBasket, currentBasket, addOrderToNewBasket } = this.props;

    if (currentBasket) {
      return addOrderToCurrentBasket({ orderId: id });
    }

    addOrderToNewBasket({ orderId: id });
  }

  public viewOrder(id: string) {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/routing/${id}`);
  }

  public viewBaskets() {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/routing/basket`);
  }

  public quickOrder() {
    const { quickOrder } = this.props;

    quickOrder();

    this.setState({ quickOrderSheet: true });
  }

  public closeQuickOrderSheet() {
    this.setState({ quickOrderSheet: false });
  }

  public autoPick() {
    this.setState({ currentBasketSheet: true });
  }

  public closeCurrentBasketSheet() {
    this.setState({ currentBasketSheet: false });
  }

  public render() {
    const { filterId, loading, orders, quickOrderLoading, currentBasket } = this.props;
    const { expanded, search, quickOrderSheet, currentBasketSheet } = this.state;

    return (
      <>
        <Box className="tab-content-inner" style={{ position: 'relative' }}>
          <Box style={{ position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 999 }}>
            <Box m={2} style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="search-Order">Search Order</InputLabel>
                <OutlinedInput
                  id="search-Order"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  value={search}
                  onChange={this.handleSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.handleToggle}
                        aria-expanded={expanded}
                        aria-label="show more"
                        sx={{
                          transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                          transition: 'transform 0.3s',
                        }}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search Order"
                  autoComplete="off"
                />
              </FormControl>
              <Box ml={1}>
                <Badge color="secondary" badgeContent={currentBasket?.orders.length}>
                  <IconButton onClick={() => this.viewBaskets()}>
                    <ShoppingBasketIcon />
                  </IconButton>
                </Badge>
              </Box>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Loading loading={loading}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell colSpan={2}>Due Orders</StyledTableCell>
                        <StyledTableCell>Due Date</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders.filter((o) => o.orderNo.toLowerCase().indexOf(search.toLowerCase()) !== -1).slice(0, 6).map((row) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>
                            <StarBorderIcon />
                          </StyledTableCell>
                          <StyledTableCell>{row.orderNo}</StyledTableCell>
                          <StyledTableCell>{format(new Date(row.dueDate), 'yyyy-MM-dd HH:mm')}</StyledTableCell>
                          <StyledTableCell style={{ display: 'flex', flexDirection: 'row' }}>
                            <IconButton onClick={() => this.viewOrder(row.id)}>
                              <ViewCompactIcon />
                            </IconButton>
                            <IconButton onClick={() => this.addOrder(row.id)}>
                              <AddCircleIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Loading>
            </Collapse>
          </Box>

          <Map
            disableOptions={true}
            filterId={filterId}
            forceDisabledLayers={['gmaps']}
            showLiveData
            actions={{ select: {}, hover: {} }}
          />

          <FloatingGrid container spacing={2} direction="row" sx={{
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Grid item>
              <LoadingButton color="primary" variant="contained" fullWidth loading={quickOrderLoading} size="large" onClick={this.quickOrder}>
                Quick Order
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" fullWidth size="large" onClick={this.scan}>
                Smart Batch
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" fullWidth size="large" onClick={this.autoPick}>
                Auto Pick
              </Button>
            </Grid>
          </FloatingGrid>
        </Box>
        <BottomSheet open={quickOrderSheet} onClose={this.closeQuickOrderSheet} showCurrentBasket={this.autoPick} />
        <CurrentBasketSheet open={currentBasketSheet} onClose={this.closeCurrentBasketSheet} />
      </>
    );
  }
}

export default Routing;
