import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  dismissNotifications,
  IFetchNotifications,
  fetchNotifications,
  fetchNotificationsCount,
  INotificationId,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getActiveLanguage, getSelectedLocale } from '@selectors/app';
import {
  getNotifications,
  getNotificationsCount,
  isDismissLoading,
  isNotificationsCountLoading,
} from '@selectors/notifications';

import NotificationsMenu from './NotificationsMenu';

const mapStateToProps = (state: IAppState) => ({
  dismissLoading: isDismissLoading(state),
  language: getActiveLanguage(state),
  loading: isNotificationsCountLoading(state),
  locale: getSelectedLocale(state),
  measurementUnits: getAccountMeasurementUnits(state.authentication),
  notificationCount: getNotificationsCount(state),
  notifications: getNotifications(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dismissNotifications: (
    ids: INotificationId[],
    locale: string,
    units: string
  ) => dispatch(dismissNotifications.request({ ids, locale, units })),
  fetchNotifications: (properties: IFetchNotifications) =>
    dispatch(fetchNotifications.request(properties)),
  fetchNotificationsCount: () => dispatch(fetchNotificationsCount.request()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationsMenu)
);
