import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import FetchError from '@models/FetchError';
import PasswordFields from '@app/forms/AccountForm/PasswordFields';

interface IProps {
  accountSetup: (email: string, password: string, verification: string) => void;
  error?: FetchError;
  language: string;
  loading: boolean;
  router: any;
  setupSuccess: boolean;
}

interface IState {
  email: string;
  password: string;
  repassword: string;
  submitted: boolean;
  error?: FetchError;
  verification: string;
}

class AccountSetupPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const params = new URLSearchParams((props.router.location || {}).search);

    this.state = {
      email: params.get('email') || '',
      password: '',
      repassword: '',
      submitted: false,
      verification: params.get('verificationId') || '',
    };

    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { error } = this.props;

    if (prevProps.error !== error && error) {
      this.showError(error!);
    }
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'email'>);
    };
  }

  public handleSubmit() {
    const { email, password, verification } = this.state;
    const { accountSetup } = this.props;

    if (email.length === 0 || verification.length === 0) {
      this.setState({ submitted: true });

      return;
    }

    accountSetup(email, password, verification);
  }

  public showError(error: FetchError) {
    this.setState({ error });
  }

  public closeSnackbar() {
    this.setState({ error: undefined });
  }

  public render() {
    const { error, email, password, repassword, verification, submitted } = this.state;
    const { language, loading, setupSuccess } = this.props;

    const doPasswordsMatch =
      password.length > 0 && repassword.length > 0
        ? password === repassword
        : true;

    const hasError = !verification.length ||
      !email.length ||
      password.length === 0 ||
      password.length < 8 ||
      repassword.length === 0 ||
      doPasswordsMatch === false;

    return (
      <>
        <div id="banner">
          <video muted autoPlay loop poster="/assets/background.jpg">
            <source src="/assets/banner.mp4" type="video/mp4" />
            <track kind="captions" />
          </video>
        </div>

        <Grid container justifyContent="center" style={{ zIndex: 1 }}>
          <Grid item sm={4}>
            <Box mt={12} ml={2} mr={2} mb={2}>
              <Card className="container">
                <CardHeader title="Account set up" />
                {setupSuccess ? (
                  <CardContent>
                    <Typography variant="body1">
                      <span style={{ marginRight: '5px' }}>Congratulations! Account setup successful.</span>
                      <Link to={`/${language}/`}>Sign In</Link>
                      <span style={{ marginLeft: '5px' }}>to use the application.</span>
                    </Typography>
                  </CardContent>
                ) : (
                  <>
                    <CardContent>
                      <FormControl
                        required
                        fullWidth
                        margin="normal"
                        error={submitted === true && email.length === 0}
                      >
                        <TextField
                          label="Email"
                          name="email"
                          onChange={this.handleChange('email')}
                          value={email}
                          variant="standard"
                          required
                        />
                        {submitted === true && email.length === 0 ? (
                          <FormHelperText error>
                            An email must be specified
                          </FormHelperText>
                        ) : null}
                      </FormControl>

                      <PasswordFields
                        doPasswordsMatch={doPasswordsMatch}
                        handleChange={this.handleChange}
                        password={password}
                        repassword={repassword}
                      />

                      <FormControl
                        required
                        fullWidth
                        margin="normal"
                        error={submitted === true && verification.length === 0}
                      >
                        <InputLabel htmlFor="verification">
                          Verification Id
                        </InputLabel>
                        <Input
                          id="verification"
                          name="verification"
                          onChange={this.handleChange('verification')}
                          value={verification}
                          required
                        />
                        {submitted === true && verification.length === 0 ? (
                          <FormHelperText error>
                            You must input your verification id. Check your
                            email.
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </CardContent>

                    <CardActions>
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={hasError || loading}
                        fullWidth
                      >
                        Set up
                      </Button>
                    </CardActions>
                  </>
                )}
              </Card>
            </Box>
          </Grid>
        </Grid>

        <Snackbar
          open={!!error}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={this.closeSnackbar}
          autoHideDuration={3000}
          message={<span>{(error || {}).errorMsg}</span>}
        />
      </>
    );
  }
}

export default AccountSetupPage;
