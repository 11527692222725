import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  confirmDialog,
  deletePlan,
  fetchPlans,
  IDeletePlan,
  IFetchPlans,
} from '@actions/index';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import List from './List';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const filters = state.filter.filters[props.filterId] || {};

  return {
    floorplanId: filters.floorplanId || '',
    importOrdersFormOpen: state.orders.importOrdersForm.open,
    language: getActiveLanguage(state),
    loading: state.plans.loading,
    plans: Object.values(state.plans.plans),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  deletePlan: (properties: IDeletePlan) =>
    dispatch(deletePlan.request(properties)),
  fetchPlans: (properties: IFetchPlans) =>
    dispatch(fetchPlans.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(List));
