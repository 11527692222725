import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import DeleteIcon from '@mui/icons-material/Delete';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Loading from '@app/common/Loading';
import {
  CheckoutBasket,
  IAbandonBasket,
  RemoveBasketOrders,
} from '@actions/routing';
import { IconButton } from '@mui/material';
import LoadingButton from '@app/common/LoadingButton';
import LoadingIconButton from '@app/common/LoadingButton/LoadingIconButton';
import { FullBasket } from '@models/Order';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface IProps {
  router: any;
  language: string;
  fetchCurrentBasket: () => void;
  abandonBasket: (properties: IAbandonBasket) => void;
  checkoutBasket: (properties: CheckoutBasket) => void;
  removeBasketOrders: (properties: RemoveBasketOrders) => void;
  loading: boolean;
  checkoutLoading: boolean;
  removeLoading: boolean;
  currentBasket?: FullBasket;
  noBasket: boolean;
}

const BasketComponent = (props: IProps) => {
  const {
    abandonBasket,
    checkoutBasket,
    removeBasketOrders,
    language,
    loading,
    checkoutLoading,
    removeLoading,
    fetchCurrentBasket,
    currentBasket,
    noBasket,
    router
  } = props;

  const navigate = useNavigate();
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [orderConfirmationOpen, setOrderConfirmation] = React.useState(false);

  useEffect(() => {
    fetchCurrentBasket();

    setInitialLoad(false);
  }, [fetchCurrentBasket]);

  const home = () => {
    router.navigate(`/${language}/dashboard/routing`);
  }

  const onBack = () => {
    navigate(-1);
  };

  const abandon = () => {
    abandonBasket({ basketId: currentBasket?.id || '' });

    router.navigate(`/${language}/dashboard/routing`);
  };

  const checkout = () => {
    checkoutBasket({ basketId: currentBasket?.id || '' });
  };

  const remove = (orderId: string) => {
    removeBasketOrders({ basketId: currentBasket?.id || '', orderIds: [orderId] });
  };

  const pickConfirm = () => {
    setOrderConfirmation(true);
  }

  const hidePickConfirm = () => {
    setOrderConfirmation(false);
  }

  return (
    <Box className="tab-content-inner">
      <AppBar position="static" color="transparent">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton onClick={home}>
            <HomeIcon />
          </IconButton>
          {window.history.length > 1 && (
            <Button color="inherit" onClick={onBack} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Box p={2} mt={4}>
        <Loading loading={initialLoad || loading}>
          {noBasket ? (
            <Grid container justifyContent="center">
              <Typography variant="overline">No active Basket</Typography>
            </Grid>
          ) : (
            <>
              <Box mb={4}>
                <Button color="primary" fullWidth variant="contained" onClick={pickConfirm}>
                  Pick Orders
                </Button>
              </Box>

              <Typography variant="h4">{router.params.basketId}</Typography>
              <Typography variant="subtitle1">{currentBasket?.isActive ? 'Active' : 'Inactive'}</Typography>
              <Box mt={4} mb={2}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentBasket?.orders.map((row) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{row.id}</StyledTableCell>
                          <StyledTableCell>
                            <LoadingIconButton
                              loading={removeLoading}
                              onClick={() => remove(row.id)}
                              disabled={!currentBasket?.isActive}
                            >
                              <DeleteIcon />
                            </LoadingIconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Button color="error" fullWidth variant="contained" onClick={abandon}>
                Abandon Basket
              </Button>
            </>
          )}
        </Loading>
      </Box>
      <Dialog
        open={orderConfirmationOpen}
        onClose={hidePickConfirm}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event?.preventDefault();

            checkout();

            hidePickConfirm();
          },
        }}
      >
        <DialogTitle>You are about to take orders</DialogTitle>
        <DialogContent>
          {currentBasket?.orders.map((row) => (
            <Typography variant="h6">{row.id}</Typography>
          ))}
          <Box mt={2}>
            <Typography variant="body1">
              Are you certain you wish to proceed?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton variant="contained" color="primary" type="submit" loading={checkoutLoading}>Confirm Orders</LoadingButton>
          <Button variant="contained" color="error" onClick={hidePickConfirm}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BasketComponent;
