import { createCanvas } from 'canvas';

import { convertCanvasToBW } from '@dashboard_utils/index';
import { loadImage } from '../../../utils/mapUtils';

class MapImages {
  public width = 0;

  public height = 0;

  public backgroundImage: string | undefined;

  public backgroundImageBW: string | undefined;

  public backgroundImageBWObj: HTMLImageElement | undefined;

  public backgroundImageObj: HTMLImageElement | undefined;

  public load(image: string | undefined): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!image) {
        return reject(new Error('NoImage'));
      }

      const construct = (err: any, loadedImage?: HTMLImageElement) => {
        if (err) {
          return reject(err);
        }

        if (!loadedImage) {
          return reject(new Error('NoImageLoaded'));
        }

        const { width, height } = loadedImage;
        this.width = width;
        this.height = height;

        const imgCanvas = createCanvas(width, height);
        const ctx = imgCanvas.getContext('2d');
        ctx.fillStyle = '#FFF';
        ctx.fillRect(0, 0, imgCanvas.width, imgCanvas.height);
        ctx.drawImage(loadedImage as any, 0, 0, width, height);

        this.backgroundImage = imgCanvas.toDataURL();

        const imageData = ctx.getImageData(0, 0, width, height);
        ctx.putImageData(convertCanvasToBW(imageData), 0, 0);

        this.backgroundImageBW = imgCanvas.toDataURL();

        const promises = [];
        promises.push(
          new Promise((rs) => {
            const backgroundImageObj = new Image();
            backgroundImageObj.setAttribute('crossOrigin', 'Anonymous');
            backgroundImageObj.onload = () => rs(backgroundImageObj);
            backgroundImageObj.onerror = () => rs(loadedImage);
            backgroundImageObj.src = this.backgroundImage!;
          })
        );
        promises.push(
          new Promise((rs) => {
            const backgroundImageBWObj = new Image();
            backgroundImageBWObj.setAttribute('crossOrigin', 'Anonymous');
            backgroundImageBWObj.onload = () => rs(backgroundImageBWObj);
            backgroundImageBWObj.onerror = () => rs(loadedImage);
            backgroundImageBWObj.src = this.backgroundImageBW!;
          })
        );
        return Promise.all(promises).then((results: any[]) => {
          this.backgroundImageObj = results[0]!;
          this.backgroundImageBWObj = results[1]!;

          return resolve();
        });
      };

      return loadImage(image, construct);
    });
  }
}

export default MapImages;
