import Alert from '@mui/material/Alert';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { MapWarning } from '@actions/index';
import FetchError from '@models/FetchError';
import { MapMetricData } from '../types';

export interface IProps {
  error: FetchError | undefined;
  metricData?: MapMetricData;
  warnings: MapWarning[];
}

interface IMapWarning {
  severity: 'error' | 'info' | 'warning';
  message: string | React.ReactNode;
}
const CustomAlert: FunctionComponent<IMapWarning> = ({
  message,
  severity,
}: IMapWarning) => (
  <Alert
    severity={severity}
    style={{ padding: '0px 10px', borderRadius: '0px' }}
  >
    {message}
  </Alert>
);

const MapWarnings: FunctionComponent<IProps> = ({
  error,
  metricData,
  warnings,
}: IProps) => (
  <div style={{ position: 'absolute', top: 0, width: '100%' }}>
    {error ? (
      <CustomAlert
        severity="error"
        message={
          <FormattedMessage
            id="dashboard.plots.analytics_map_issue"
            defaultMessage="Problem computing analytics for the selected layer"
          />
        }
      />
    ) : null}
    {warnings.map((warning, index) => (
      <CustomAlert
        key={index}
        severity={warning.severity}
        message={warning.message as any}
      />
    ))}
    {(metricData || {}).tab === 'zone_metrics' ? (
      <CustomAlert
        severity="info"
        message={
          <FormattedMessage
            id="dashboard.plots.analytics_zone_metrics"
            defaultMessage="Click on a zone to see it's metrics"
          />
        }
      />
    ) : null}
  </div>
);

export default MapWarnings;
