import { connect } from 'react-redux';

import { Plot } from '@models/Metric';
import { getSelectedLocale } from '@selectors/app';
import { IAppState } from '@reducers/index';
import { MetricAggregationWithFeedback } from '@reducers/analytics';
import PlotComponent from './PlotComponent';

interface IProps {
  plot?: Plot;
  filterId?: string;
  metricId?: string;
  id?: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId, plot, metricId, id } = props;

  const metric =
    (state.analytics.metrics[filterId || ''] || {})[metricId || ''] || {};

  return {
    aggregationState: plot
      ? ({ plot } as MetricAggregationWithFeedback)
      : ((metric.data || {}).aggregations || {})[id || ''] || {},
    locale: getSelectedLocale(state),
  };
};

export default connect(mapStateToProps)(PlotComponent);
