import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MuiLink from '@mui/material/Link';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import CompleteAsset from '@models/CompleteAsset';
import Documents from '../../tabs/Documents';
import Maintenances from '../../tabs/Maintenances';
import PreChecks from '../../tabs/PreChecks';
import Tickets from '../../tabs/Tickets';
import History from '../../tabs/History';

interface IProps {
  asset: CompleteAsset;
  router: any;
  language: string;
}
interface IState {
  tab: number;
}

class Management extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { router } = props;

    this.state = {
      tab: Number(router.params.tab || 0),
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { router } = this.props;

    if (prevProps.router.params.tab !== router.params.tab) {
      this.updateTab();
    }
  }

  public handleTabChange(event: any, tab: number) {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/assets/assetid/${router.params.id}/management/tab/${tab || 0}`);
  }

  public updateTab() {
    const { router } = this.props;

    this.setState({ tab: Number(router.params.tab || 0) });
  }

  public render() {
    const { asset, language } = this.props;
    const { tab } = this.state;

    let activeTab = null;

    switch (tab) {
      case 0:
        activeTab = <Tickets asset={asset} />;
        break;
      case 1:
        activeTab = <Maintenances asset={asset} />;
        break;
      case 2:
        activeTab = <Documents asset={asset} />;
        break;
      case 3:
        activeTab = <PreChecks asset={asset} />;
        break;
      case 4:
        activeTab = <History asset={asset} />;
        break;
      default:
        activeTab = null;
    }

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink
            color="inherit"
            component={Link}
            to={`/${language}/dashboard/assets`}
          >
            <FormattedMessage
              id="dashboard.itab.assets"
              defaultMessage="Assets"
            />
          </MuiLink>
          <Typography color="textPrimary">{asset.name}</Typography>
        </Breadcrumbs>

        <Box mt={2}>
          <Card>
            <AppBar position="static" color="default">
              <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleTabChange}
                variant="fullWidth"
              >
                <Tab
                  label={
                    <FormattedMessage
                      id="dashboard.assets.management.tab_tickets"
                      defaultMessage="Tickets"
                    />
                  }
                />
                <Tab
                  label={
                    <FormattedMessage
                      id="dashboard.assets.management.tab_maintenance"
                      defaultMessage="Maintenance"
                    />
                  }
                />
                <Tab
                  label={
                    <FormattedMessage
                      id="dashboard.assets.management.tab_documents"
                      defaultMessage="Documents"
                    />
                  }
                />
                <Tab
                  label={
                    <FormattedMessage
                      id="dashboard.assets.management.tab_pre_checks"
                      defaultMessage="Pre-Checks"
                    />
                  }
                />
                <Tab
                  label={
                    <FormattedMessage
                      id="dashboard.assets.management.tab_history"
                      defaultMessage="History"
                    />
                  }
                />
              </Tabs>
            </AppBar>
            <CardContent>{activeTab}</CardContent>
          </Card>
        </Box>
      </>
    );
  }
}

export default Management;
