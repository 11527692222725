import Typography from '@mui/material/Typography/Typography';
import React, { FunctionComponent } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Map from '@app/common/FullMap/Map';
import { IDrawAction } from '@app/common/FullMap/types';

const messages = defineMessages({
  floorplan: {
    defaultMessage: 'Floor plan',
    id: 'dashboard.datamap.floorplan',
  },
  newZone: {
    defaultMessage: 'New Zone',
    id: 'dashboard.forms.zoneform.title_create',
  },
  zones: {
    defaultMessage: 'Zones',
    id: 'dashboard.livemap.layers.zones',
  },
});

interface IProps {
  floorplanId: string;
}

const ZonesForm: FunctionComponent<IProps> = ({ floorplanId }: IProps) => {
  const intl = useIntl();

  const help = (
    <>
      <Typography variant="h4">
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.zones.title"
          defaultMessage="Zone Definition"
        />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.zones.optional"
          defaultMessage="This step can be completed at a later time."
        />
      </Typography>
      <Typography variant="caption">
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.zones.tutorial"
          defaultMessage="CTRL + Click and drag on the map, to create new zones."
        />
      </Typography>
    </>
  );

  return (
    <div
      style={{
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Map
          disableColor
          help={help}
          layers={{
            floorplan: {
              id: 'floorplan',
              active: true,
              label: intl.formatMessage(messages.floorplan),
              primary: true,
              secondary: false,
            },
            zones: {
              id: 'zones',
              active: true,
              disabled: true,
              label: intl.formatMessage(messages.zones),
              primary: true,
              secondary: false,
            },
          }}
          actions={{
            select: {},
            draw: {
              featureName: 'Zone',
              featureTitle: intl.formatMessage(messages.newZone),
              drawType: 'zone',
              drawColor: 'red',
              singleDraw: true,
            } as IDrawAction,
          }}
          floorplanId={floorplanId}
        />
      </div>
    </div>
  );
};

export default ZonesForm;
