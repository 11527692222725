import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import {
  IDeleteFloorplan,
  IDeleteWarehouse,
  IUpdateFloorplan,
} from '@actions/index';
import IncompleteFloorplan, {
  IncompleteFloorplans,
} from '@models/IncompleteFloorplan';
import { Warehouses } from '@models/Warehouse';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '../../../dialogs/ConfirmDialog/types';
import FloorplanFormDialog from '../../../forms/FloorplanForm/index';
import FloorplanItem from './FloorplanItem';
import { messages, warehouseMessages } from './messages';

interface IProps {
  confirm: (properties: IConfirmDialog) => void;
  deleteFloorplan: (properties: IDeleteFloorplan) => void;
  deleteWarehouse: (properties: IDeleteWarehouse) => void;
  handleEditWarehouse: () => any;
  router: any;
  intl: IntlShape;
  language: string;
  removeWhSelection: () => void;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
  floorplans: IncompleteFloorplans;
  warehouses: Warehouses;
  warehouseId: string;
}

interface IState {
  anchorEl?: HTMLElement;
  editionFloorplanData?: IncompleteFloorplan;
  openFloorplanForm: boolean;
}

class WarehousesItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: undefined,
      openFloorplanForm: false,
    };

    this.handleWHMenuClick = this.handleWHMenuClick.bind(this);
    this.handleWHMenuClose = this.handleWHMenuClose.bind(this);
    this.handleEditWarehouse = this.handleEditWarehouse.bind(this);
    this.onFloorplanCreation = this.onFloorplanCreation.bind(this);
    this.handleFloorplanFormClose = this.handleFloorplanFormClose.bind(this);
    this.handleEditFloorplan = this.handleEditFloorplan.bind(this);
    this.handleHideFloorplan = this.handleHideFloorplan.bind(this);
    this.handleShowFloorplan = this.handleShowFloorplan.bind(this);
    this.handleConfigureFloorplan = this.handleConfigureFloorplan.bind(this);
    this.handleDeleteFloorplan = this.handleDeleteFloorplan.bind(this);
  }

  public handleFloorplanFormClose() {
    this.setState({
      editionFloorplanData: undefined,
      openFloorplanForm: false,
    });
  }

  public handleEditFloorplan(floorplan: IncompleteFloorplan) {
    this.setState({
      anchorEl: undefined,
      editionFloorplanData: floorplan,
      openFloorplanForm: true,
    });
  }

  public handleWHMenuClose() {
    this.setState({ anchorEl: undefined });
  }

  public handleWHMenuClick(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleEditWarehouse() {
    const { handleEditWarehouse } = this.props;

    this.setState({ anchorEl: undefined }, handleEditWarehouse);
  }

  public handleDeleteWarehouse(id: string) {
    const { confirm, deleteWarehouse, intl, removeWhSelection } = this.props;

    this.setState({ anchorEl: undefined }, () =>
      confirm({
        confirmType: ConfirmDialogActions.DELETE,
        message: intl.formatMessage(warehouseMessages.warehouseDeleteMsg),
        onConfirmation: () => {
          deleteWarehouse({ id, intl });

          removeWhSelection();
        },
      })
    );
  }

  public handleHideFloorplan(floorplan: IncompleteFloorplan) {
    const { confirm, intl, updateFloorplan, warehouseId } = this.props;

    this.setState({ anchorEl: undefined }, () =>
      confirm({
        confirmType: ConfirmDialogActions.HIDE,
        message: intl.formatMessage(warehouseMessages.floorplanHideMsg),
        onConfirmation: () => {
          updateFloorplan({
            floorplanId: floorplan.id,
            intl,
            properties: { hidden: true },
            warehouseId,
          });
        },
      })
    );
  }

  public handleShowFloorplan(floorplan: IncompleteFloorplan) {
    const { confirm, intl, updateFloorplan, warehouseId } = this.props;

    this.setState({ anchorEl: undefined }, () =>
      confirm({
        confirmType: ConfirmDialogActions.SHOW,
        message: intl.formatMessage(warehouseMessages.floorplanShowMsg),
        onConfirmation: () => {
          updateFloorplan({
            floorplanId: floorplan.id,
            intl,
            properties: { hidden: false },
            warehouseId,
          });
        },
      })
    );
  }

  public handleConfigureFloorplan(floorplan: IncompleteFloorplan) {
    const { router, language } = this.props;

    this.setState({ anchorEl: undefined }, () =>
      router.navigate(
        `/${language}/dashboard/settings/warehouses/${floorplan.warehouseId}/${floorplan.id}`
      )
    );
  }

  public handleDeleteFloorplan(floorplan: IncompleteFloorplan) {
    const { confirm, deleteFloorplan, intl, warehouseId } = this.props;

    this.setState({ anchorEl: undefined }, () =>
      confirm({
        confirmType: ConfirmDialogActions.DELETE,
        message: intl.formatMessage(warehouseMessages.floorplanDeleteMsg),
        onConfirmation: () => {
          deleteFloorplan({
            floorplanId: floorplan.id,
            intl,
            warehouseId,
          });
        },
      })
    );
  }

  public onFloorplanCreation() {
    this.setState({
      anchorEl: undefined,
      editionFloorplanData: undefined,
      openFloorplanForm: true,
    });
  }

  public render() {
    const { anchorEl, editionFloorplanData, openFloorplanForm } = this.state;
    const { intl, warehouseId, warehouses, floorplans } = this.props;

    const action = (
      <span>
        <Button onClick={this.onFloorplanCreation}>
          <AddIcon style={{ marginRight: '5px' }} />
          <FormattedMessage
            id="dashboard.settings.warehouse.addfloorplan"
            defaultMessage="Add floorplan"
          />
        </Button>
        <IconButton
          aria-label={intl.formatMessage(messages.more)}
          aria-owns={anchorEl ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleWHMenuClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={this.handleWHMenuClose}
        >
          <MenuItem onClick={this.handleEditWarehouse}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  id="dashboard.settings.warehouse.edit"
                  defaultMessage="Edit Warehouse"
                />
              }
            />
          </MenuItem>
          <MenuItem onClick={() => this.handleDeleteWarehouse(warehouseId)}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  id="dashboard.settings.warehouse.delete"
                  defaultMessage="Delete Warehouse"
                />
              }
            />
          </MenuItem>
        </Menu>
      </span>
    );

    return warehouses[warehouseId] ? (
      <>
        <Box m={2}>
          <CardHeader action={action} title={warehouses[warehouseId].name} />
          {Object.values(floorplans)
            .filter((f) => f.warehouseId === warehouseId)
            .map((f) => (
              <FloorplanItem
                handleConfigureFloorplan={this.handleConfigureFloorplan}
                handleDeleteFloorplan={this.handleDeleteFloorplan}
                handleEditFloorplan={this.handleEditFloorplan}
                handleHideFloorplan={this.handleHideFloorplan}
                handleShowFloorplan={this.handleShowFloorplan}
                floorplan={f}
                key={f.id}
              />
            ))}
        </Box>
        {openFloorplanForm ? (
          <FloorplanFormDialog
            onClose={this.handleFloorplanFormClose}
            data={editionFloorplanData}
            warehouseId={warehouseId}
          />
        ) : null}
      </>
    ) : null;
  }
}

export default injectIntl(WarehousesItem);
