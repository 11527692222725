import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TaskIcon from '@mui/icons-material/Task';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import React, { Component } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Account from '@models/Account';
import { Employee } from '@models/Employee';
import { getFromLocalStorage, saveToLocalStorage } from '@app/utils/localStorageUtils';
import { hasPermission } from '@selectors/accounts';
import Employees from './Employees';
import Teams from './Teams';

const messages = {
  employees: {
    defaultMessage: 'Employees',
    id: 'dashboard.employees.tabs.employees',
  },
  teams: {
    defaultMessage: 'Teams',
    id: 'dashboard.employees.tabs.teams',
  },
};

interface IProps {
  account: Account;
  fetchEmployees: (shallow?: boolean) => void;
  language: string;
  intl: IntlShape;
  data: Employee[];
  router: any;
}

interface IState {
  tab: number;
}

class EmployeeTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const tab = Number(
      getFromLocalStorage('employees_selected_tab') || 0
    );

    this.state = {
      tab,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleManageTasks = this.handleManageTasks.bind(this);
    this.handleManageShifts = this.handleManageShifts.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  private handleTabChange(_: any, tab: number) {
    this.setState({ tab }, () => {
      saveToLocalStorage('employees_selected_tab', String(tab));
    });
  }

  public handleManageShifts() {
    const { language, router } = this.props;

    router.navigate(`/${language}/dashboard/shifts`);
  }

  public handleManageTasks() {
    const { language, router } = this.props;

    router.navigate(`/${language}/dashboard/tasks`);
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const { fetchEmployees } = this.props;

    fetchEmployees(shallow);
  }

  public render() {
    const { account, data, intl } = this.props;
    const { tab } = this.state;

    return (
      <Box className="tab-content-inner" p={2}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <AvatarGroup max={4}>
            {data.map((d) => <Avatar key={d.id} alt={d.name} src={d.image} style={{ backgroundColor: '#efefef' }} />)}
          </AvatarGroup>

          {hasPermission(account, 'add_asset') && (
            <span>
              <Button id="view-tasks" onClick={this.handleManageTasks}>
                <TaskIcon style={{ marginRight: '5px' }} />
                <FormattedMessage
                  id="dashboard.tasks.view"
                  defaultMessage="Manage Tasks"
                />
              </Button>

              <Button id="view-shifts" onClick={this.handleManageShifts}>
                <PunchClockIcon style={{ marginRight: '5px' }} />
                <FormattedMessage
                  id="dashboard.shifts.view"
                  defaultMessage="Manage Shifts"
                />
              </Button>
            </span>
          )}
        </div>

        <Tabs
          centered
          indicatorColor="primary"
          onChange={this.handleTabChange}
          value={tab}
          variant="fullWidth"
          textColor="primary"
        >
          <Tab label={intl.formatMessage(messages.employees)} />
          <Tab label={intl.formatMessage(messages.teams)} />
        </Tabs>
        {tab === 0 ? (
          <Employees />
        ) : (
          <Teams />
        )}
      </Box>
    );
  }
}

export default injectIntl(EmployeeTab);
