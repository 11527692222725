import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import clsx from 'clsx';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { Assets } from '@models/Asset';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import { Rule } from '@models/Rule';
import { RuleTemplate } from '@models/RuleTemplate';
import { Warehouses } from '@models/Warehouse';
import { Zones } from '@models/Zone';

interface IProps {
  columns: boolean[];
  assets: Assets;
  floorplans: IncompleteFloorplans;
  intl: IntlShape;
  onRuleActivation: (id: string) => void;
  onRuleDelete: (id: string) => void;
  onRuleDeactivation: (id: string) => void;
  handleEdit: (id: string) => void;
  rule: Rule;
  theme: string;
  templates: Record<string, RuleTemplate>;
  warehouses: Warehouses;
  zones: Zones;
  rowData: any;
}

interface IAnchorEL {
  menu?: HTMLElement;
  expanded?: boolean;
}

interface IState {
  anchorEl: IAnchorEL;
}

const messages = {
  more: {
    defaultMessage: 'More',
    id: 'dashboard.rules.menu_aria_label',
  },

  booleanFalse: {
    defaultMessage: 'False',
    id: 'boolean.false',
  },
  booleanTrue: {
    defaultMessage: 'True',
    id: 'boolean.true',
  },

  notifyEmail: {
    defaultMessage: 'Email',
    id: 'dashboard.rules.view.notify_email',
  },
  notifyMobile: {
    defaultMessage: 'Mobile',
    id: 'dashboard.rules.view.notify_mobile',
  },
  notifyWhatsapp: {
    defaultMessage: 'Whatsapp',
    id: 'dashboard.rules.view.notify_whatsapp',
  },
};

class Line extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: {},
    };

    this.handleMenu = this.handleMenu.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleActivation = this.handleActivation.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleExpandableRow = this.handleExpandableRow.bind(this);
  }

  public handleMenu() {
    return (event: any) => {
      const { anchorEl } = this.state;

      this.setState({
        anchorEl: {
          ...anchorEl,
          menu: event.currentTarget,
        },
      });
    };
  }

  public handleMenuClose() {
    const { anchorEl } = this.state;

    this.setState({
      anchorEl: {
        ...anchorEl,
        menu: undefined,
      },
    });
  }

  public handleActivation(id: string, activate: boolean) {
    const { onRuleActivation, onRuleDeactivation } = this.props;
    const { anchorEl } = this.state;

    this.setState(
      {
        anchorEl: {
          ...anchorEl,
          menu: undefined,
        },
      },
      () => {
        if (activate === true) {
          onRuleActivation(id);
        } else {
          onRuleDeactivation(id);
        }
      }
    );
  }

  public handleEdit(id: string) {
    const { handleEdit } = this.props;
    const { anchorEl } = this.state;

    this.setState(
      {
        anchorEl: {
          ...anchorEl,
          menu: undefined,
        },
      },
      () => handleEdit(id)
    );
  }

  public handleDelete(id: string) {
    const { onRuleDelete } = this.props;
    const { anchorEl } = this.state;

    this.setState(
      {
        anchorEl: {
          ...anchorEl,
          menu: undefined,
        },
      },
      () => onRuleDelete(id)
    );
  }

  public handleExpandableRow() {
    const { anchorEl } = this.state;

    this.setState(
      {
        anchorEl: {
          ...anchorEl,
          expanded: !anchorEl.expanded,
          menu: undefined,
        },
      },
      () => setTimeout(() => window.dispatchEvent(new Event('resize')), 300)
    );
  }

  public render() {
    const {
      columns,
      assets,
      floorplans,
      intl,
      theme,
      templates,
      rule,
      rowData,
      warehouses,
      zones,
    } = this.props;
    const { anchorEl } = this.state;

    const colSpan = rowData.length + 1;

    const chevronRotation = clsx('chevron', {
      rotate: anchorEl.expanded === true,
    });

    const defaultRule = clsx({
      default: rule.default === true,
      dark: theme === 'dark',
    });

    const activateOptions =
      rule.enabled === false ? (
        <MenuItem onClick={() => this.handleActivation(rule.id, true)}>
          <ListItemIcon>
            <CheckBoxOutlineBlankIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <FormattedMessage
                id="dashboard.rules.activate"
                defaultMessage="Activate Rule"
              />
            }
          />
        </MenuItem>
      ) : (
        <MenuItem onClick={() => this.handleActivation(rule.id, false)}>
          <ListItemIcon>
            <CheckBoxIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <FormattedMessage
                id="dashboard.rules.deactivate"
                defaultMessage="Deactivate Rule"
              />
            }
          />
        </MenuItem>
      );

    return (
      <>
        <TableRow
          className={defaultRule}
          style={{ opacity: rule.enabled ? 1 : 0.5 }}
          hover
        >
          <TableCell>
            <ChevronRightIcon
              className={chevronRotation}
              onClick={() => this.handleExpandableRow()}
              style={{ cursor: 'pointer' }}
            />
          </TableCell>
          {columns[1] && <TableCell>{rowData[1]}</TableCell>}
          {columns[2] && <TableCell>{templates[rowData[2]].name}</TableCell>}
          <TableCell>
            <div style={{ float: 'right' }}>
              <IconButton
                aria-label={intl.formatMessage(messages.more)}
                aria-owns={anchorEl ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleMenu()}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl.menu}
                open={!!anchorEl.menu}
                onClose={this.handleMenuClose}
              >
                <MenuItem onClick={() => this.handleEdit(rule.id)}>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.rules.edit"
                        defaultMessage="Edit Rule"
                      />
                    }
                  />
                </MenuItem>
                {activateOptions}
                <MenuItem onClick={() => this.handleDelete(rule.id)}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.rules.delete"
                        defaultMessage="Delete Rule"
                      />
                    }
                  />
                </MenuItem>
              </Menu>
            </div>
          </TableCell>
        </TableRow>
        <TableRow
          className={defaultRule}
          style={{ opacity: rule.enabled ? 1 : 0.5 }}
        >
          <TableCell
            colSpan={colSpan}
            padding="none"
            style={{ borderBottom: 'none' }}
          >
            <Collapse in={anchorEl.expanded === true}>
              <div
                style={{
                  borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  padding: '14px 40px 14px 16px',
                }}
              >
                <div className="table-container">
                  <Table>
                    <TableBody>
                      <TableRow hover={false}>
                        <TableCell>
                          <FormattedMessage
                            id="dashboard.rules.view.label_name"
                            defaultMessage="Name"
                          />
                        </TableCell>
                        <TableCell>{rule.name}</TableCell>
                      </TableRow>
                      <TableRow hover={false}>
                        <TableCell>
                          <FormattedMessage
                            id="dashboard.rules.view.label_template"
                            defaultMessage="Template"
                          />
                        </TableCell>
                        <TableCell>{templates[rule.templateId].name}</TableCell>
                      </TableRow>
                      <TableRow hover={false}>
                        <TableCell>
                          <FormattedMessage
                            id="dashboard.rules.view.label_valid"
                            defaultMessage="Valid"
                          />
                        </TableCell>
                        <TableCell>
                          {rule.valid === true
                            ? intl.formatMessage(messages.booleanTrue)
                            : intl.formatMessage(messages.booleanFalse)}
                        </TableCell>
                      </TableRow>
                      <TableRow hover={false}>
                        <TableCell>
                          <FormattedMessage
                            id="dashboard.rules.view.label_active"
                            defaultMessage="Active"
                          />
                        </TableCell>
                        <TableCell>
                          {rule.enabled === true
                            ? intl.formatMessage(messages.booleanTrue)
                            : intl.formatMessage(messages.booleanFalse)}
                        </TableCell>
                      </TableRow>
                      <TableRow hover={false}>
                        <TableCell>
                          <FormattedMessage
                            id="dashboard.rules.view.label_applicableto"
                            defaultMessage="Applicable to"
                          />
                        </TableCell>
                        <TableCell>
                          {Object.keys(rule.applicableTo)
                            .map((k: any) => {
                              // @ts-ignore
                              const message = messages[k];

                              const appTo = message
                                ? intl.formatMessage(message)
                                : k;

                              let applicable = '';
                              if (k === 'assets') {
                                applicable = rule.applicableTo[k]
                                  .map(
                                    (id: string) =>
                                      (assets[id] || {}).name || id
                                  )
                                  .join(', ');
                              } else if (k === 'floorplans') {
                                applicable = rule.applicableTo[k]
                                  .map(
                                    (id: string) =>
                                      (floorplans[id] || {}).name || id
                                  )
                                  .join(', ');
                              } else if (k === 'warehouses') {
                                applicable = rule.applicableTo[k]
                                  .map(
                                    (id: string) =>
                                      (warehouses[id] || {}).name || id
                                  )
                                  .join(', ');
                              } else if (k === 'zones') {
                                applicable = rule.applicableTo[k]
                                  .map(
                                    (id: string) => (zones[id] || {}).name || id
                                  )
                                  .join(', ');
                              }

                              return `${appTo}: ${applicable}`;
                            })
                            .join('; ')}
                        </TableCell>
                      </TableRow>
                      <TableRow hover={false}>
                        <TableCell>
                          <FormattedMessage
                            id="dashboard.rules.view.label_options"
                            defaultMessage="Options"
                          />
                        </TableCell>
                        <TableCell>
                          {Object.keys(rule.options)
                            .map((k: string) => {
                              const schema =
                                templates[rule.templateId].schema || {};
                              const option =
                                ((
                                  ((schema.properties || {}).options ||
                                    {}) as any
                                ).properties || {})[k] || {};

                              return `${option.title}: ${rule.options[k]}`;
                            })
                            .join('; ')}
                        </TableCell>
                      </TableRow>
                      <TableRow hover={false}>
                        <TableCell>
                          <FormattedMessage
                            id="dashboard.rules.view.label_notify"
                            defaultMessage="Sent notifications"
                          />
                        </TableCell>
                        <TableCell>
                          {rule.notifyEmail === true
                            ? `${intl.formatMessage(messages.notifyEmail)}; `
                            : ''}
                          {rule.notifyMobile === true
                            ? `${intl.formatMessage(messages.notifyMobile)}; `
                            : ''}
                          {rule.notifyWhatsapp === true
                            ? `${intl.formatMessage(messages.notifyWhatsapp)}; `
                            : ''}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
}

export default injectIntl(Line);
