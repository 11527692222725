import { Canvas, createCanvas } from 'canvas';
import React, { Component } from 'react';

import ThreeDMap, { emitter } from '@app/common/3DMap';
import { PRIMARY_COLOR } from '@app/utils/colors';
import { getRotationFromDirection } from '@app/utils/geometryUtils';
import ws, { ISensorData } from '@api/websocket';
import {
  generateHeatMapCanvas,
  generateZoneHeatMapCanvas,
  getRandomColor,
  transformMeters,
  transformMetersToPixels,
} from '@dashboard_utils/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import HeatMap from '@models/HeatMap';
import SpaghettiMap from '@models/SpaghettiMap';
import Asset from '@models/Asset';
import Sensor from '@models/Sensor';
import { Zones } from '@models/Zone';
import ZoneHeatMap from '@models/ZoneHeatMap';
import ZoneSpaghettiMap from '@models/ZoneSpaghettiMap';
import { getAssetBySensorAssociation } from '@selectors/assets';
import mapEvents from '../../eventEmitter';
import { LayerScale } from '../../LayerScale/LayerScale';
import MapImages from '../../MapImages';
import { defaultTransformationMatrix } from '../../../consts';
import { TransformationMatrix2D } from '../../../../../../utils';

interface IHeatMap {
  type: 'grid' | 'zone';
  map: HeatMap | ZoneHeatMap;
}

interface ISpaghettiMap {
  type: 'grid' | 'zone';
  map: SpaghettiMap | ZoneSpaghettiMap;
}

interface IProps {
  id: string;
  color: boolean;
  floorplan: IncompleteFloorplan;
  assets: Asset[];
  beacons: Sensor[];
  zones: Zones;
  mapImages: MapImages;

  sensors: Sensor[];
  showAssets: boolean;
  showBoundaries: boolean;
  showFloorplan: boolean;
  showSensors: boolean;
  showRacks: boolean;
  showZones: boolean;

  heatmap?: IHeatMap;
  spaghetti?: ISpaghettiMap;
  updateScale: (id: string, scale?: LayerScale) => void;
}

interface IState {
  canvas?: HTMLCanvasElement;
  genKey?: number;
}

class ThreeDMapLayer extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.handleMessage = this.handleMessage.bind(this);
  }

  public componentDidMount() {
    const { id, heatmap, updateScale } = this.props;

    ws.on('live-data', this.handleMessage);
    mapEvents.on('live-data', this.handleMessage);

    if (heatmap) {
      updateScale(id, {
        max: heatmap.map.max_weight || 0,
        min: heatmap.map.min_weight || 0,
        logarithmicScale: heatmap.map.log_scale,
        units: heatmap.map.units,
      });
    } else {
      updateScale(id);
    }

    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { color, heatmap, spaghetti, showZones } = this.props;

    if (
      JSON.stringify(prevProps.heatmap || {}) !==
        JSON.stringify(heatmap || {}) ||
      JSON.stringify(prevProps.spaghetti || {}) !==
        JSON.stringify(spaghetti || {}) ||
      prevProps.showZones !== showZones ||
      prevProps.color !== color
    ) {
      this.load();
    }
  }

  public componentWillUnmount() {
    ws.removeListener('live-data', this.handleMessage);
    mapEvents.removeListener('live-data', this.handleMessage);
  }

  public handleMessage(sensorsData: ISensorData[]) {
    const { assets, beacons, floorplan, showAssets } = this.props;

    if (showAssets) {
      emitter.emit(
        'live-data',
        sensorsData.map((s) => {
          const sensor = beacons.find((b) => b.id === s.id) || ({} as Sensor);

          const asset = sensor
            ? getAssetBySensorAssociation(
                assets,
                sensor.physicalAddress,
                s.ts
              ) || ({} as Asset)
            : ({} as Asset);

          const coordinate = transformMeters(
            [s.x, s.y],
            floorplan.transformationMatrix || defaultTransformationMatrix
          );

          return {
            id: s.id,
            color: asset.color || PRIMARY_COLOR,
            positionX: coordinate[0],
            positionY: coordinate[1],
            positionZ: 1,
            rotation: getRotationFromDirection(
              s.direction || 0,
              floorplan.transformationMatrix as TransformationMatrix2D
            ),
          };
        })
      );
    }
  }

  public drawZones(canvas: Canvas) {
    const { floorplan, showZones, zones } = this.props;

    if (showZones) {
      const ctx = canvas.getContext('2d')!;

      const transformationMatrix =
        floorplan.transformationMatrix || defaultTransformationMatrix;
      const scale = floorplan.scale || 1;

      Object.values(zones).forEach((zone) => {
        ctx.beginPath();
        zone.coordinates.forEach((coordinate, index) => {
          const coordinates = transformMetersToPixels(
            coordinate,
            transformationMatrix,
            scale
          );

          if (index === 0) {
            ctx.moveTo(coordinates[0], coordinates[1]);
          } else {
            ctx.lineTo(coordinates[0], coordinates[1]);
          }
        });
        ctx.closePath();
        ctx.strokeStyle = `10px solid ${zone.color || '#666'}`;
        ctx.fillStyle = zone.color || '#666';
        ctx.fill();
      });
    }
  }

  public load() {
    const {
      assets,

      floorplan,

      heatmap,
      spaghetti,

      id,
      mapImages,
      updateScale,
      zones,
    } = this.props;

    const canvas = createCanvas(mapImages.width, mapImages.height);
    const ctx = canvas.getContext('2d');
    ctx.translate(0, mapImages.height);
    ctx.scale(1, -1);
    ctx.lineWidth = 10;

    const transformationMatrix =
      floorplan.transformationMatrix || defaultTransformationMatrix;
    const scale = floorplan.scale || 1;

    if ((heatmap || {}).type === 'grid') {
      const gridData = (heatmap || {}).map as HeatMap;
      generateHeatMapCanvas(
        mapImages.width,
        mapImages.height,
        gridData,
        transformationMatrix,
        scale
      ).then((heat: any) => {
        ctx.translate(0, mapImages.height);
        ctx.scale(1, -1);
        ctx.drawImage(heat, 0, 0);
        ctx.translate(0, mapImages.height);
        ctx.scale(1, -1);

        updateScale(id, {
          max: gridData.max_weight || 0,
          min: gridData.min_weight || 0,
          logarithmicScale: gridData.log_scale === true,
          units: gridData.units,
        });
      });
    } else if ((heatmap || {}).type === 'zone') {
      const zoneData = (heatmap || {}).map as ZoneHeatMap;
      generateZoneHeatMapCanvas(
        mapImages.width,
        mapImages.height,
        zoneData,
        transformationMatrix,
        scale,
        zones
      ).then((heat: any) => {
        ctx.translate(0, mapImages.height);
        ctx.scale(1, -1);
        ctx.drawImage(heat, 0, 0);
        ctx.translate(0, mapImages.height);
        ctx.scale(1, -1);

        updateScale(id, {
          max: zoneData.max_weight || 0,
          min: zoneData.min_weight || 0,
          logarithmicScale: zoneData.log_scale === true,
          units: zoneData.units,
        });
      });
    }

    if ((spaghetti || {}).type === 'grid') {
      const spaghettiData = (spaghetti || {}).map as SpaghettiMap;

      for (let i = 0; i < (spaghettiData.data || []).length; i += 1) {
        const assetColor = (
          assets.find((a) => a.id === spaghettiData.data![i].asset_id) || {}
        ).color;
        ctx.strokeStyle = assetColor || getRandomColor();

        spaghettiData.data![i].coordinates.forEach((coords) => {
          ctx.beginPath();
          for (let y = 0; y < coords.length; y += 1) {
            const coordinates = transformMetersToPixels(
              coords[y],
              transformationMatrix,
              scale
            );

            if (y === 0) {
              ctx.moveTo(coordinates[0], coordinates[1]);
            } else {
              ctx.lineTo(coordinates[0], coordinates[1]);
            }
          }
          ctx.stroke();
        });
      }
    }

    this.drawZones(canvas);

    this.setState({
      canvas: canvas as any,
      genKey: Date.now(),
    });
  }

  public render() {
    const {
      floorplan,
      sensors,
      color,
      mapImages,
      showBoundaries,
      showFloorplan,
      showSensors,
      showRacks,
    } = this.props;
    const { canvas, genKey } = this.state;

    return (
      <div
        style={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          backgroundColor: '#ffffff',
        }}
      >
        <ThreeDMap
          floorplan={floorplan}
          color={color}
          mapImages={mapImages}
          overlayImage={canvas}
          overlayImageKey={genKey}
          sensors={sensors}
          showBoundaries={showBoundaries}
          showFloorplan={showFloorplan}
          showSensors={showSensors}
          showRacks={showRacks}
        />
      </div>
    );
  }
}

export default ThreeDMapLayer;
