import { Group } from 'paper';
import { Component } from 'react';

import {
  HoverFeature,
  IItem,
  IToolEvent,
  MapFeatureWithAlpha,
} from '../../../types';
import Paper from '../../Paper';
import mapEvents from '../../eventEmitter';

interface IProps {
  id: string;
  feature?: HoverFeature;
  paper: Paper;
  updateHoverFeature: (id: string, feature?: HoverFeature) => void;
}

class Hover extends Component<IProps> {
  public lastHoveredFeature?: MapFeatureWithAlpha;

  constructor(props: IProps) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  public componentDidMount() {
    const { paper } = this.props;
    const { tool } = paper;

    tool.on('mousedown', this.handleMouseDown);
    tool.on('mousemove', this.handleMouseMove);
  }

  public componentWillUnmount() {
    const { paper } = this.props;
    const { tool } = paper;

    tool.removeListener('mousedown', this.handleMouseDown);
    tool.removeListener('mousemove', this.handleMouseMove);
  }

  public handleMouseDown() {
    const { feature, id, updateHoverFeature } = this.props;

    if (feature) {
      updateHoverFeature(id);
    }
  }

  public handleMouseMove(event: IToolEvent) {
    const { feature, id, updateHoverFeature } = this.props;

    // @ts-ignore
    const targetId = (event.event.target || {}).id || '';
    if (targetId.indexOf('_map') === -1 && feature) {
      updateHoverFeature(id);

      return;
    }
    if (targetId.indexOf('_map') === -1) {
      return;
    }

    const item: IItem = event.item || {};
    // Feature hover is the same as item on mouse event
    if (feature && feature.info.id === (item.featureInfo || {}).id) {
      mapEvents.emit('hover-position', {
        x: event.event.layerX,
        y: event.event.layerY,
      });

      return;
    }

    if (this.lastHoveredFeature) {
      if (this.lastHoveredFeature instanceof Group) {
        this.lastHoveredFeature.children.forEach((children: any) => {
          /* eslint-disable no-param-reassign */
          if (children.fillColor) {
            children.fillColor.alpha = children.alpha || 1;
          }
          if (children.strokeColor) {
            children.strokeColor.alpha = children.alpha || 1;
          }
          /* eslint-enable no-param-reassign */
        });
      } else {
        this.lastHoveredFeature.fillColor!.alpha =
          this.lastHoveredFeature.alpha || 1;
      }
    }

    // No feature hover and no item on mouse event
    if (!item.featureInfo && !feature) {
      document.body.style.cursor = 'default';

      return;
    }

    if (!item.featureInfo && feature) {
      document.body.style.cursor = 'default';

      updateHoverFeature(id);

      return;
    }

    // Feature hover is diff from item on mouse event
    if (feature && feature.info.id !== (item.featureInfo || {}).id) {
      document.body.style.cursor = 'default';

      if (!item.featureInfo) {
        updateHoverFeature(id);

        return;
      }
    }

    if (item.featureInfo) {
      const hoveredFeature = event.item as MapFeatureWithAlpha;

      /**
       * If hovered feature is not a group, transparency for fill color will be toggled
       * If hovered feature is a group, transparency for fill color AND stock color will be toggled
       * for each group children. Specially intended for flow map but globally applyed.
       */
      if (hoveredFeature instanceof Group) {
        hoveredFeature.children.forEach((children: any) => {
          /* eslint-disable no-param-reassign */
          if (children.fillColor) {
            if (!children.alpha) {
              children.alpha = children.fillColor.alpha;
            }
            children.fillColor.alpha = 0.5;
          }

          if (children.strokeColor) {
            if (!children.alpha) {
              children.alpha = children.strokeColor.alpha;
            }
            children.strokeColor.alpha = 0.5;
          }
          /* eslint-enable no-param-reassign */
        });
        this.lastHoveredFeature = hoveredFeature;
      } else if (hoveredFeature && hoveredFeature.fillColor) {
        if (!hoveredFeature.alpha) {
          hoveredFeature.alpha = hoveredFeature.fillColor.alpha;
        }

        // Prevent transparency change on zones
        if (
          hoveredFeature.fillColor &&
          hoveredFeature.featureInfo.type !== 'zone'
        ) {
          hoveredFeature.fillColor.alpha = 0.5;

          this.lastHoveredFeature = hoveredFeature;
        }
      }

      document.body.style.cursor = 'pointer';

      updateHoverFeature(id, {
        info: item.featureInfo,
        x: event.event.layerX,
        y: event.event.layerY,
      });
    }
  }

  public render() {
    return null;
  }
}

export default Hover;
