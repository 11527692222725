import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  openTaskForm,
  IUpdateTask,
  updateTask,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import KanbanBoard from './KanbanBoard';
import { Task, TaskStatus } from '@models/Task';
import { Date as LogDate } from '@dashboard_utils/index';

import './KanbanBoard.css';

interface IProps {
  filterId: string;
  statusFilter: TaskStatus[];
  filterStartDate: LogDate;
  filterEndDate: LogDate;
}
const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId, filterStartDate, filterEndDate, statusFilter } = props;

  const filter = state.filter.filters[filterId] || {};

  const tasks = Object.values(state.tasks.tasks).filter((d) =>
    d.warehouseId === filter.warehouseId &&
    d.floorplanId === filter.floorplanId && (
      !(filter.zoneIds || []).length ||
      !!(filter.zoneIds || []).find((zId) => d.zoneId === zId)
    ) && (
      !(filter.employeeIds || []).length ||
      !!d.employees.find((eId) => filter.employeeIds?.find((id) => id === eId))
    ) && (
      !(filter.teamIds || []).length ||
      !!d.teams.find((eId) => (filter.teamIds || []).find((id) => id === eId))
    ) && (
      !filterStartDate || new Date(d.created).getTime() >= filterStartDate.getTime()
    ) && (
      !filterEndDate || new Date(d.created).getTime() <= filterEndDate.getTime()
    ) && (
      !statusFilter.length || statusFilter.indexOf(d.status) !== -1
    )
  );

  return {
    tasks,
    loading: state.tasks.loading,
    employees: state.employees.employees,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateTask: (properties: IUpdateTask) =>
    dispatch(updateTask.request(properties)),
  openForm: (data?: Task) => dispatch(openTaskForm(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KanbanBoard);
