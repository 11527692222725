import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import MapLayoutHelp from './MapLayerHelp';
import { MapMetricData } from '../../types';

interface IProps {
  filterId?: string;
  metricData?: MapMetricData;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId, metricData } = props;
  const { section, tab } = metricData || {};
  const activeMetrics = state.analytics.activeMetrics[filterId || ''] || {};
  const metricId = section ? activeMetrics[section] || '' : '';

  const activeId = (
    ((activeMetrics.plots || {})[metricId] || {})[tab || ''] || {}
  ).id;

  const metricState =
    (state.analytics.metrics[filterId || ''] || {})[activeId || ''] || {};

  return { metricState };
};

export default connect(mapStateToProps)(MapLayoutHelp);
