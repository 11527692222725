import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchRuleAlerts, IFetchRuleAlerts } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getRuleAlerts } from '@selectors/rules';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getSelectedLocale } from '@selectors/app';
import { isFetchDataRageLoading } from '@selectors/plots';
import AlertsTab from './AlertsTab';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;

  const filter = state.filter.filters[filterId] || {};

  return {
    loading: isFetchDataRageLoading(state, filterId),

    ruleAlerts: getRuleAlerts(state, props.filterId),
    locale: getSelectedLocale(state),
    measurementUnits: getAccountMeasurementUnits(state.authentication),

    floorplanId: filter.floorplanId || '',
    ruleIds: filter.ruleIds || [],
    templateIds: filter.templateIds || [],
    warehouseId: filter.warehouseId || '',

    endDate: filter.endDate,
    startDate: filter.startDate,

    filterUpdated: filter.filterUpdated,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRuleAlerts: (properties: IFetchRuleAlerts) =>
    dispatch(fetchRuleAlerts.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsTab);
