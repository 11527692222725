import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { CheckoutBasket, checkoutBasket, fetchCurrentBasket, startPickingSession, StartPickingSession } from '@actions/index';
import { IAppState } from '@reducers/index';
import { withRouter } from '@app/utils/withRouter';
import CurrentBasketSheet from './CurrentBasketSheet';

const mapStateToProps = (state: IAppState) => ({
  currentBasket: state.routing.currentBasket.basket,
  loading: state.routing.currentBasket.loading,
  checkoutLoading: state.routing.checkoutBasket.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCurrentBasket: () => dispatch(fetchCurrentBasket.request()),
  checkoutBasket: (properties: CheckoutBasket) =>
    dispatch(checkoutBasket.request(properties)),
  startPickingSession: (properties: StartPickingSession) =>
    dispatch(startPickingSession.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CurrentBasketSheet));
