import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IFetchSimulations } from '@actions/index';
import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import { timeZone } from '@dashboard_utils/index';
import { ISimulationWithNames, Simulations } from '@models/Simulation';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import { Warehouses } from '@models/Warehouse';
import { findWarehouseByFloorplanId } from '../../../utils/floorplanUtils';
import SimulationTableRow from './SimulationTableRow';

const messages = {
  thCreated: {
    defaultMessage: 'Created',
    id: 'dashboard.simulations.th_created',
  },
  thFloorplan: {
    defaultMessage: 'Floorplan',
    id: 'dashboard.simulations.th_floorplan',
  },
  thLength: {
    defaultMessage: 'Length',
    id: 'dashboard.simulations.th_length',
  },
  thName: {
    defaultMessage: 'Name',
    id: 'dashboard.simulations.th_name',
  },
  thStartTs: {
    defaultMessage: 'Run Start Date',
    id: 'dashboard.simulations.th_startts',
  },
  thStatus: {
    defaultMessage: 'Status',
    id: 'dashboard.simulations.th_status',
  },
  thWarehouse: {
    defaultMessage: 'Warehouse',
    id: 'dashboard.simulations.th_warehouse',
  },
};

interface IProps {
  floorplanId: string;
  fetchSimulations: (properties: IFetchSimulations) => void;
  intl: IntlShape;
  router: any;
  language: string;
  loading: boolean;
  simulations: Simulations;
  floorplans: IncompleteFloorplans;
  warehouses: Warehouses;
}

interface IState {
  columns: ITableColumn[];
}

class SimulationsTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      columns: [
        { label: '', name: '', options: { interaction: false } },
        { label: intl.formatMessage(messages.thName), name: 'name' },
        { label: intl.formatMessage(messages.thCreated), name: 'created' },
        { label: intl.formatMessage(messages.thStartTs), name: 'startTs' },
        { label: intl.formatMessage(messages.thLength), name: 'length' },
        { label: intl.formatMessage(messages.thStatus), name: 'status' },
        {
          label: intl.formatMessage(messages.thWarehouse),
          name: 'warehouseName',
        },
        {
          label: intl.formatMessage(messages.thFloorplan),
          name: 'floorplanName',
        },
        { label: '', name: '', options: { interaction: false } },
      ],
    };

    this.addSimulation = this.addSimulation.bind(this);
    this.customRowRender = this.customRowRender.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();
  }

  public componentDidUpdate(prevProps: any) {
    const { fetchSimulations, floorplanId } = this.props;

    if (prevProps.floorplanId !== floorplanId) {
      if (floorplanId) {
        fetchSimulations({ floorplanId });
      }
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  public getData(): ISimulationWithNames[] {
    const { simulations, floorplans, warehouses } = this.props;

    return Object.values(simulations).map((simulation) => {
      const warehouse = findWarehouseByFloorplanId(
        warehouses,
        floorplans,
        simulation.originalFloorplanId
      );

      return {
        ...simulation,
        floorplanName: (floorplans[simulation.originalFloorplanId] || {}).name,
        warehouseName: (warehouse || {}).name,
        warehouseTimeZone: (warehouse || {}).timezone || timeZone,
      } as ISimulationWithNames;
    });
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const { fetchSimulations, floorplanId } = this.props;

    if (floorplanId) {
      fetchSimulations({ floorplanId, shallow });
    }
  }

  public addSimulation() {
    const { router, language, floorplanId } = this.props;

    router.navigate(`/${language}/dashboard/simulations/${floorplanId}/new`);
  }

  public customRowRender(
    data: ISimulationWithNames[],
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    const { simulations, floorplanId } = this.props;

    const lineData = data[dataIndex];

    if (!lineData) {
      return <TableRow key={rowIndex} />;
    }

    return (
      <SimulationTableRow
        key={rowIndex}
        columns={columns}
        data={lineData}
        simulation={simulations[lineData.id] || {}}
        colSpan={rowData.length + 1}
        rowData={rowData}
        floorplanId={floorplanId}
      />
    );
  }

  public render() {
    const { loading } = this.props;
    const { columns } = this.state;

    const data = this.getData();

    return (
      <>
        <Loading loading={loading}>
          <CardHeader
            action={
              <Button onClick={this.addSimulation}>
                <AddIcon style={{ marginRight: '5px' }} />
                <FormattedMessage
                  id="dashboard.simulations.addsimulation"
                  defaultMessage="Add Simulation"
                />
              </Button>
            }
            title={
              <FormattedMessage
                id="dashboard.simulations.title"
                defaultMessage="Simulations"
              />
            }
          />
          <Card>
            <CardContent>
              <div className="table-container">
                <MUITable
                  columns={columns}
                  data={data}
                  defaultOrderField="created"
                  customRowRender={(cols, rowData, dataIndex, rowIndex) =>
                    this.customRowRender(
                      data,
                      cols,
                      rowData,
                      dataIndex,
                      rowIndex
                    )
                  }
                  tableId="simulations"
                />
              </div>
            </CardContent>
          </Card>
        </Loading>
      </>
    );
  }
}

export default injectIntl(SimulationsTab);
