import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { deleteZone } from '@actions/zones';
import { IAppState } from '@reducers/index';
import { getZonesByFloorplan } from '@selectors/zones';
import StepContainer from './StepContainer';

interface IProps {
  floorplanId: string;
  onNext: () => void;
  onSkip: () => void;
}

const mapStateToProps = (state: IAppState, { floorplanId }: IProps) => ({
  loading: state.floorplans.loading || state.zones.loading,
  floorplanId,
  zones: getZonesByFloorplan(state, floorplanId),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteZone: (id: string) => dispatch(deleteZone.request({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepContainer);
