import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchSimulationData } from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import {
  getSimulation,
  getSimulationData,
  getSimulationDataIntervals,
} from '@selectors/simulations';
import Controls from './Controls';

interface IProps {
  router: any;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  data: getSimulationData(state.simulations, props.router.params.id),
  id: props.router.params.id,
  intervals: getSimulationDataIntervals(
    state.simulations,
    props.router.params.id
  ),
  simulation: getSimulation(state.simulations, props.router.params.id),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSimulationData: (id: string, timestamp: number) =>
    dispatch(fetchSimulationData.request({ id, timestamp })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Controls)
);
