import React from 'react';
import { createAction } from 'typesafe-actions';

import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { HoverFeature, MapFeature } from '@app/common/FullMap/types';
import { LayerScale } from '@app/common/FullMap/Map/LayerScale/LayerScale';
import MapImages from '@app/common/FullMap/Map/MapImages';
import Paper from '@app/common/FullMap/Map/Paper';

export enum AppTypes {
  CHANGELANGUAGE = '@@app/CHANGELANGUAGE',

  CONFIRM_DIALOG = '@@app/CONFIRM_DIALOG',
  CONFIRM_DIALOG_CLOSE = '@@app/CONFIRM_DIALOG_CLOSE',

  TOGGLEMENU = '@@app/TOGGLEMENU',
  TOGGLEWALKTHROUGH = '@@app/TOGGLEWALKTHROUGH',

  MAP_DESTROY = '@@app/MAP_DESTROY',
  MAP_INIT = '@@app/MAP_INIT',
  MAP_UPDATE_SELECT_FEATURE = '@@app/MAP_UPDATE_SELECT_FEATURE',
  MAP_UPDATE_EDIT_FEATURE = '@@app/MAP_UPDATE_EDIT_FEATURE',
  MAP_UPDATE_HOVER_FEATURE = '@@app/MAP_UPDATE_HOVER_FEATURE',
  MAP_TOGGLE_HELP = '@@app/MAP_TOGGLE_HELP',
  MAP_UPDATE_LAYER_SCALE = '@@app/MAP_UPDATE_LAYER_SCALE',

  SKIPZONESETUP = '@@app/SKIPZONESETUP',

  TOGGLE_THEME = '@@app/TOGGLE_THEME',
  TOGGLE_ZONE_METRICS = '@@app/TOGGLE_ZONE_METRICS',
}

export const toggleMenu = createAction(
  AppTypes.TOGGLEMENU,
  (open: boolean) => open
)();
export const toggleWalkthrough = createAction(
  AppTypes.TOGGLEWALKTHROUGH,
  (open: boolean) => open
)();
export const confirmDialog = createAction(
  AppTypes.CONFIRM_DIALOG,
  (props: IConfirmDialog) => props
)();
export const confirmDialogClose = createAction(
  AppTypes.CONFIRM_DIALOG_CLOSE,
)();
export const changeLanguage = createAction(
  AppTypes.CHANGELANGUAGE,
  (language: string) => language
)();

export interface IMap {
  id: string;
  floorplanId: string;
  activeSensorGroupId?: string;
  mapImages: MapImages;
  paper: Paper;
  warnings?: Record<string, MapWarning>;
}

export interface MapWarning {
  id: string;
  severity: 'warning' | 'info' | 'error';
  message: string | React.ReactElement | Element;
}

export const destroyMap = createAction(
  AppTypes.MAP_DESTROY,
  (id: string) => id
)();

export const initMap = createAction(
  AppTypes.MAP_INIT,
  (props: IMap) => props
)();

export const updateSelectedFeature = createAction(
  AppTypes.MAP_UPDATE_SELECT_FEATURE,
  (id: string, feature?: MapFeature) => ({ id, feature })
)();

export const updateEditFeature = createAction(
  AppTypes.MAP_UPDATE_EDIT_FEATURE,
  (id: string, feature?: MapFeature) => ({ id, feature })
)();

export const updateHoverFeature = createAction(
  AppTypes.MAP_UPDATE_HOVER_FEATURE,
  (id: string, feature?: HoverFeature) => ({ id, feature })
)();

export const toggleMapHelp = createAction(
  AppTypes.MAP_TOGGLE_HELP,
  (id: string, help?: React.ReactElement | string) =>
    ({ id, help })
)();

export const updateScale = createAction(
  AppTypes.MAP_UPDATE_LAYER_SCALE,
  (id: string, scale?: LayerScale) => ({ id, scale })
)();

export const skipZoneSetup = createAction(
  AppTypes.SKIPZONESETUP,
  (floorplanId: string) => floorplanId
)();

export const toggleZoneMetrics = createAction(
  AppTypes.TOGGLE_ZONE_METRICS,
  (id?: string, filterId?: string) => ({ id, filterId })
)();

export const toggleTheme = createAction(
  AppTypes.TOGGLE_THEME,
)();
