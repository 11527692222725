import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import MapWarnings from './MapWarnings';
import { MapMetricData } from '../types';

interface IProps {
  id: string;
  filterId?: string;
  metricData?: MapMetricData;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId, id, metricData } = props;
  const { section, tab } = metricData || {};
  const activeMetrics = state.analytics.activeMetrics[filterId || ''] || {};
  const metricId = section ? activeMetrics[section] || '' : '';

  const map = getMapById(state, id);

  const activeId = (
    ((activeMetrics.plots || {})[metricId] || {})[tab || ''] || {}
  ).id;

  const metric =
    (state.analytics.metrics[filterId || ''] || {})[activeId || ''] || {};

  return {
    error: metric.error,
    warnings: Object.values((map || {}).warnings || {}),
  };
};

export default connect(mapStateToProps)(MapWarnings);
