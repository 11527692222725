import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IFetchFloorplanSensors } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { IDrawDefinition } from '@models/types';
import DrawDefinitionForm from '../../forms/FloorplanConfigurationForm/DrawDefinition/DrawDefinitionForm';

interface IProps {
  features: IDrawDefinition[];
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => void;
  floorplan: IncompleteFloorplan;
  loading: boolean;
  minNumberFeatures: number;
  onBack: () => void;
  onNext: (features: IDrawDefinition[], intl: IntlShape) => void;
  onSkip: (intl: IntlShape) => void;
  intl: IntlShape;
  type: string;
}

interface IState {
  drawings: IDrawDefinition[];
  forceMapUpdate: boolean;
}

class StepDefinition extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { features } = props;

    this.state = {
      drawings: features,
      forceMapUpdate: false,
    };

    this.onNext = this.onNext.bind(this);
    this.onSkip = this.onSkip.bind(this);
    this.handleDrawChange = this.handleDrawChange.bind(this);
  }

  public componentDidMount() {
    const { fetchActiveSensorGroup, floorplan } = this.props;

    fetchActiveSensorGroup({ floorplanId: floorplan.id });
  }

  public componentDidUpdate(prevProps: any) {
    const { fetchActiveSensorGroup, floorplan, features, type } = this.props;

    if (JSON.stringify(prevProps.floorplan) !== JSON.stringify(floorplan)) {
      fetchActiveSensorGroup({ floorplanId: floorplan.id });
    }

    if (prevProps.type !== type) {
      this.resetDrawings(features);
    }
  }

  public handleDrawChange(drawings: IDrawDefinition[]) {
    this.setState({ drawings });
  }

  public onNext() {
    const { intl, onNext } = this.props;
    const { drawings } = this.state;

    onNext(drawings, intl);
  }

  public onSkip() {
    const { intl, onSkip } = this.props;

    onSkip(intl);
  }

  public resetDrawings(features: IDrawDefinition[]) {
    this.setState((currentState) => ({
      drawings: features,
      forceMapUpdate: !currentState.forceMapUpdate,
    }));
  }

  public render() {
    const { floorplan, loading, minNumberFeatures, onBack, type } = this.props;
    const { drawings, forceMapUpdate } = this.state;

    return (
      <Card id="draw-setup" className="step-card">
        <CardContent style={{ flex: 1, position: 'relative' }}>
          <DrawDefinitionForm
            drawings={drawings}
            forceMapUpdate={forceMapUpdate}
            handleDrawChange={this.handleDrawChange}
            floorplan={floorplan}
            type={type}
          />
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Box mr={1}>
            <Button onClick={onBack}>
              <FormattedMessage
                id="dashboard.setup.steps.button_back"
                defaultMessage="Back"
              />
            </Button>
          </Box>
          <Box mr={1}>
            <Button onClick={this.onSkip}>
              <FormattedMessage
                id="dashboard.setup.steps.button_skip"
                defaultMessage="Skip"
              />
            </Button>
          </Box>
          <LoadingButton
            loading={loading}
            onClick={this.onNext}
            color="primary"
            variant="contained"
            disabled={drawings.length < minNumberFeatures!}
          >
            <FormattedMessage
              id="dashboard.setup.steps.button_next"
              defaultMessage="Next"
            />
          </LoadingButton>
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(StepDefinition);
