import React, { FunctionComponent } from 'react';
import { GetHandleProps, SliderItem } from 'react-compound-slider';

import { PRIMARY_COLOR } from '@app/utils/colors';

interface IProps {
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
}

const Handle: FunctionComponent<IProps> = (properties: IProps) => {
  const {
    domain: [min, max],
    handle: { id, value, percent },
    getHandleProps,
  } = properties;

  return (
    <div
      className="range-slider-handle"
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        backgroundColor: PRIMARY_COLOR,
        left: `${percent}%`,
      }}
      {...getHandleProps(id)}
    />
  );
};

export default Handle;
