import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import SnackbarContent from '@mui/material/SnackbarContent';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { FormattedMessage } from 'react-intl';

import React, { Component } from 'react';

import { IImport } from '@actions/types';
import LoadingButton from '@app/common/LoadingButton';
import FetchError from '@models/FetchError';

const handleFileUploadClick = (event: any) => {
  event.stopPropagation();

  document.getElementById('form-csv')!.click();
};

export interface IState {
  csv?: string;
}

interface IProps {
  closeImportOrdersForm: () => void;
  error?: FetchError;
  importOrders: (properties: IImport) => void;
  loading: boolean;
}

class ImportOrdersForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      csv: undefined,
    };

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleCancel() {
    const { closeImportOrdersForm } = this.props;

    closeImportOrdersForm();
  }

  public handleSubmit() {
    const { csv } = this.state;

    const { importOrders } = this.props;

    importOrders({ csv: csv || '' });
  }

  public handleFileUpload(event: any) {
    const { files } = event.target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (e.target !== null) {
          this.setState({ csv: e.target.result });
        }
      };

      reader.readAsText(files[0]);

      this.setState({
        csv: files[0],
      });
    }
  }

  public render() {
    const { error, loading } = this.props;
    const { csv } = this.state;

    const buttonDisabled = !csv;

    const successMessage = (
      <span
        style={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <CheckIcon />
        <FormattedMessage
          id="dashboard.forms.importordersform.upload_success"
          defaultMessage="File uploaded successfully"
        />
      </span>
    );

    let errorMessage = <span />;
    if (error && error.errorCode === 'NoData') {
      errorMessage = (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <ErrorIcon />
          <span style={{ paddingLeft: '5px' }}>
            <FormattedMessage
              id="dashboard.forms.importordersform.errors_nodate"
              defaultMessage="No data find in the file"
            />
          </span>
        </div>
      );
    } else if (error && error.errorCode === 'ItemCodeNotFound') {
      errorMessage = (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <ErrorIcon />
          <span style={{ paddingLeft: '5px' }}>
            <FormattedMessage
              id="dashboard.forms.importordersform.errors_itemcodenotfound"
              defaultMessage="Item code {code} not found"
              values={{ code: (error.errorMsg || '').split(' ')[1] }}
            />
          </span>
        </div>
      );
    }

    return (
      <Dialog open>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.forms.importordersform.title"
            defaultMessage="Import Orders"
          />
        </DialogTitle>
        <DialogContent>
          <label htmlFor="form-csv">
            <Button
              style={{ width: '100%', height: '250px' }}
              variant="outlined"
              color="primary"
              onClick={handleFileUploadClick}
            >
              <FormattedMessage
                id="dashboard.forms.importordersform.upload_button"
                defaultMessage="Upload CSV file"
              />
            </Button>
            <input
              id="form-csv"
              accept="csv/*"
              type="file"
              style={{ display: 'none' }}
              onInput={this.handleFileUpload}
            />
          </label>
          <FormHelperText>
            <FormattedMessage
              id="dashboard.forms.importordersform.csv_helper"
              defaultMessage={
                'CSV file, UTF-8 comma limited, containing a list of "Order No","Due Date","Item Code",Quantity entries'
              }
            />
          </FormHelperText>
          {csv ? (
            <SnackbarContent
              style={{
                backgroundColor: green[600],
                marginTop: '15px',
              }}
              message={successMessage}
            />
          ) : null}
          {error ? (
            <SnackbarContent
              message={errorMessage}
              style={{
                backgroundColor: '#d32f2f',
                marginTop: '30px',
              }}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            <FormattedMessage
              id="dashboard.forms.importordersform.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color="primary"
            disabled={buttonDisabled}
            loading={loading}
          >
            <FormattedMessage
              id="dashboard.forms.importordersform.button_submit"
              defaultMessage="Import"
            />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ImportOrdersForm;
