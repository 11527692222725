import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { changeMode, closeModeSelector, logout } from '@actions/index';
import { IAppState } from '@reducers/index';
import ModeSelector from './ModeSelector';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account,
  loading: state.authentication.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModeSelector: () => dispatch(closeModeSelector()),
  selectMode: (event: any) =>
    dispatch(changeMode.request({ organisationId: event.target.value })),
  logout: () => dispatch(logout.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModeSelector);
