import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { updateAssetPreCheck, IUpdateAssetPreCheck } from '@actions/assets';
import {
  fetchPreCheckAnswersByAsset,
  fetchPreCheckList,
  openPreCheckForm,
} from '@actions/prechecks';
import CompleteAsset from '@models/CompleteAsset';
import PreCheck from '@models/PreCheck';
import { IAppState } from '@reducers/index';
import {
  getAssetPreChecks,
  getPreCheckList,
  isAssetPreChecksLoading,
  isPreCheckListsLoading,
} from '@selectors/prechecks';
import PreChecks from './PreChecks';

interface IProps {
  asset: CompleteAsset;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  floorplans: state.floorplans.floorplans,
  assetPreChecks: getAssetPreChecks(state.prechecks, props.asset.id),
  loading:
    isPreCheckListsLoading(state.prechecks) ||
    isAssetPreChecksLoading(state.prechecks, props.asset.id),
  openForm: state.prechecks.form.active,
  preChecks: getPreCheckList(state.prechecks),
  warehouses: state.warehouses.warehouses,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateAssetPreCheck: (asset: IUpdateAssetPreCheck) =>
    dispatch(updateAssetPreCheck.request(asset)),
  fetchPreCheckAnswersByAsset: (assetId: string) =>
    dispatch(fetchPreCheckAnswersByAsset.request({ assetId })),
  fetchPreCheckList: () => dispatch(fetchPreCheckList.request()),
  openPreCheckForm: (data?: PreCheck) => dispatch(openPreCheckForm(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreChecks);
