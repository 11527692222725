import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchOrderItems,
  IFetchOrderItems,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import Order from './Order';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  loading: state.routing.order.loading,
  order: state.routing.order.order,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchOrderItems: (properties: IFetchOrderItems) =>
    dispatch(fetchOrderItems.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));
