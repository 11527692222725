import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import Account from '@models/Account';
import Asset from '@models/Asset';
import CompleteAsset from '@models/CompleteAsset';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import Warehouse, { Warehouses } from '@models/Warehouse';
import { Date, timeZone } from '@dashboard_utils/index';
import { hasPermission } from '@selectors/accounts';
import { getAssetTypeName } from '../../../../forms/AssetForm/utils';
import { findWarehouseByFloorplanId } from '../../../../utils/floorplanUtils';
import { PRIMARY_COLOR } from '../../../../utils/colors';

interface IProps {
  account: Account;
  asset: CompleteAsset;
  extendGrid: boolean;
  floorplans: IncompleteFloorplans;
  intl: IntlShape;
  openAssetForm: (asset?: Asset) => void;
  onAssetDeletion: (assetId: string, intl: IntlShape) => void;
  language: string;
  router: any;
  warehouses: Warehouses;
}

interface IState {
  anchorEl?: HTMLElement;
}

const messages = defineMessages({
  imageAlt: {
    defaultMessage: 'Asset image',
    id: 'dashboard.assets.imagealt',
  },
});

class AssetCard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: undefined,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onAssetDeletion = this.onAssetDeletion.bind(this);
    this.onAssetEdition = this.onAssetEdition.bind(this);
  }

  public handleClick(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleClose() {
    this.setState({ anchorEl: undefined });
  }

  public onAssetEdition() {
    const { asset, openAssetForm } = this.props;

    openAssetForm(JSON.parse(JSON.stringify(asset)));

    this.setState({ anchorEl: undefined });
  }

  public onAssetDeletion() {
    const { asset, onAssetDeletion, intl } = this.props;

    onAssetDeletion(asset.id, intl);

    this.setState({ anchorEl: undefined });
  }

  public goToManagement(id: string) {
    const { router, language } = this.props;

    router.navigate(
      `/${language}/dashboard/assets/assetid/${id}/management/tab/0`
    );
  }

  public render() {
    const { account, asset, extendGrid, floorplans, intl, warehouses } =
      this.props;
    const { anchorEl } = this.state;

    const warehouse =
      findWarehouseByFloorplanId(warehouses, floorplans, asset.floorplanId) ||
      ({} as Warehouse);

    const warehouseTz = warehouse.timezone || timeZone;

    return (
      <Grid
        key={asset.id}
        item
        xs={12}
        sm={extendGrid ? 6 : 12}
        md={extendGrid ? 4 : 6}
        lg={extendGrid ? 3 : 4}
      >
        <Card className="dashboard-card" variant="outlined">
          <CardHeader
            action={
              (hasPermission(account, 'edit_asset') ||
                hasPermission(account, 'del_asset')) && (
                <>
                  <IconButton
                    aria-label="More"
                    aria-owns={anchorEl ? 'long-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={() => this.goToManagement(asset.id)}>
                      <ListItemIcon>
                        <AssessmentIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <FormattedMessage
                            id="dashboard.assets.assetmanagement"
                            defaultMessage="Asset Management"
                          />
                        }
                      />
                    </MenuItem>
                    {hasPermission(account, 'edit_asset') && (
                      <MenuItem onClick={this.onAssetEdition}>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <FormattedMessage
                              id="dashboard.assets.editasset"
                              defaultMessage="Edit"
                            />
                          }
                        />
                      </MenuItem>
                    )}
                    {hasPermission(account, 'del_asset') && (
                      <MenuItem onClick={this.onAssetDeletion}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <FormattedMessage
                              id="dashboard.assets.deleteasset"
                              defaultMessage="Delete"
                            />
                          }
                        />
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )
            }
            title={asset.name}
          />
          <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Avatar
                alt={intl.formatMessage(messages.imageAlt)}
                src={asset.image || '/assets/forkliftPlaceholder.png'}
                style={{
                  width: 200,
                  height: 200,
                  opacity: asset.image ? 1 : 0.1,
                }}
              />
            </div>
            <Table className="fleet-information-table">
              <TableBody>
                <TableRow hover={false}>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.assets.label_type"
                      defaultMessage="Type"
                    />
                  </TableCell>
                  <TableCell>{getAssetTypeName(asset.type)}</TableCell>
                </TableRow>
                <TableRow hover={false}>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.assets.label_color"
                      defaultMessage="Colour"
                    />
                  </TableCell>
                  <TableCell>
                    {asset.color ? (
                      <div
                        style={{
                          backgroundColor: asset.color,
                          height: '20px',
                          width: '50px',
                        }}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
                <TableRow hover={false}>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.assets.label_warehouse"
                      defaultMessage="Warehouse"
                    />
                  </TableCell>
                  <TableCell>{asset.warehouseName}</TableCell>
                </TableRow>
                <TableRow hover={false}>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.assets.label_floorplan"
                      defaultMessage="Floor plan"
                    />
                  </TableCell>
                  <TableCell>{asset.floorplanName}</TableCell>
                </TableRow>
                <TableRow hover={false}>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.assets.label_tags"
                      defaultMessage="Labels"
                    />
                  </TableCell>
                  <TableCell>
                    {asset.tags.map((t, index) => (
                      <Chip
                        key={index}
                        style={{
                          backgroundColor: asset.color || PRIMARY_COLOR,
                          color: 'white',
                        }}
                        size="small"
                        label={t}
                      />
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow hover={false}>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.assets.label_currentsensor"
                      defaultMessage="Current Sensor"
                    />
                  </TableCell>
                  <TableCell>{asset.physicalAddress || ''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
          <Accordion style={{ marginTop: '30px' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ backgroundColor: '#f9f9f9' }}
            >
              <Typography>
                <FormattedMessage
                  id="dashboard.assets.sensorassociations.title"
                  defaultMessage="Sensor Associations"
                />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage
                        id="dashboard.assets.sensorassociations.from"
                        defaultMessage="From"
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage
                        id="dashboard.assets.sensorassociations.to"
                        defaultMessage="To"
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage
                        id="dashboard.assets.sensorassociations.sensor"
                        defaultMessage="Sensor"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {asset.sensorAssociations.map((a, index) => {
                    const start = new Date(warehouseTz, a.from).format();

                    let end = null;
                    if (a.to) {
                      end = new Date(warehouseTz, a.to).format();
                    }

                    return (
                      <TableRow key={index} hover={false}>
                        <TableCell>{start}</TableCell>
                        <TableCell>{end}</TableCell>
                        <TableCell>{a.physicalAddress}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Grid>
    );
  }
}

export default injectIntl(AssetCard);
