import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'react-app-polyfill/ie11';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { fetchAccount } from '@actions/index';
import store from '../store';
import { register } from '../serviceWorker';
import BaseRouter from './App/BaseRouter';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

store.dispatch(fetchAccount.request());

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
});

document.title = `${process.env.REACT_APP_ORGANISATION_NAME} Web Dashboard`;

const container = document.getElementById('app');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <BaseRouter />
  </Provider>
);

if (process.env.NODE_ENV === 'production') {
  register({
    onUpdate: async (registration: any) => {
      // We want to run this code only if we detect a new service worker is
      // waiting to be activated.
      // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
      if (registration && registration.waiting) {
        await registration.unregister();
        // Makes Workbox call skipWaiting()
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        // Once the service worker is unregistered, we can reload the page to let
        // the browser download a fresh copy of our app (invalidating the cache)
        window.location.reload();
      }
    },
  });
}
