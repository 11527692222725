import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  confirmValidateItemLocation,
  confirmValidateItemQuantity,
  IConfirmValidateItemLocation,
  IConfirmValidateItemQuantity,
} from '@actions/routing';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import ScanResult from './ScanResult';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  loading: state.routing.confirmValidateItemLocation.loading,
  confirmed: state.routing.confirmValidateItemLocation.confirmed,
  confirmedQtd: state.routing.confirmValidateItemQuantity.confirmed,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirmValidateItemLocation: (properties: IConfirmValidateItemLocation) =>
    dispatch(confirmValidateItemLocation.request(properties)),
  confirmValidateItemQuantity: (properties: IConfirmValidateItemQuantity) =>
    dispatch(confirmValidateItemQuantity.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScanResult));
