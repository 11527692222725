import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  confirmDialog,
  createSimulation,
  deleteSimulation,
  ICreateSimulation,
  IStopSimulation,
  stopSimulation,
} from '@actions/index';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import SimulationTableRow from './SimulationTableRow';

const mapStateToProps = (state: IAppState) => ({
  assets: state.assets.assets,
  language: getActiveLanguage(state),
  floorplans: state.floorplans.floorplans,
  warehouses: state.warehouses.warehouses,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  onSimulationDelete: (id: string) =>
    dispatch(deleteSimulation.request({ id })),
  onSimulationStop: (properties: IStopSimulation) =>
    dispatch(stopSimulation.request(properties)),
  createSimulation: (properties: ICreateSimulation) =>
    dispatch(createSimulation.request(properties)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SimulationTableRow)
);
