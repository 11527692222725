import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { FunctionComponent } from 'react';

import './Terms.css';

const Terms: FunctionComponent<{}> = () => (
  <div className="full-container">
    <CardHeader title="Terms and Conditions" />
    <Divider style={{ marginBottom: '40px' }} />
    <Paper>
      <div style={{ padding: '20px' }}>
        <Typography variant="h3">1 COMMENCEMENT</Typography>
        <Typography variant="body1" className="terms">
          1.1 The Contract begins on the date that the Order is signed by both
          parties and shall continue for the duration specified in the Schedule
          unless SONODOT extends the Pilot by giving written notice to the
          Customer, or the Pilot is ended in accordance with this Contract.
        </Typography>

        <Typography variant="h3">2 BEFORE THE PILOT</Typography>
        <Typography variant="body1" className="terms">
          2.1 Where the Pilot can only be provided as an addition to a SONODOT
          standard service, a valid contract must be in place with SONODOT for
          the provision of the SONODOT standard service. SONODOT’s standard
          terms and conditions will apply to the provision of the SONODOT
          standard service, details of which are set out in the Schedule. In the
          event of any conflict between SONODOT’s standard terms and conditions
          and this Contract, SONODOT’s standard terms and conditions will
          prevail.
        </Typography>

        <Typography variant="h3">3 DURING THE PILOT</Typography>
        <Typography variant="body1" className="terms">
          3.1 Both Parties agree to make time available to proceed diligently
          with the Pilot, to provide the necessary resource, to provide the
          necessary facilities and take corrective actions as appropriate where
          incidents or faults are being detected during the Pilot. The Customer
          acknowledges that the Pilot service is under development. SONODOT does
          not guarantee that the Pilot service will be free from faults. The
          Customer agrees to report any faults to SONODOT promptly and SONODOT
          will try to repair any faults reported within a reasonable timescale.
        </Typography>

        <Typography variant="h5">Customer Equipment</Typography>
        <Typography variant="body1" className="terms">
          3.2 Any Customer Equipment must be:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) technically compatible with the Pilot service and the SONODOT
          Components and not harm SONODOT’s network or another customers’
          equipment;
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) connected using the applicable SONODOT network termination point
          (if any) unless the Customer has SONODOT’s permission to connect by
          another means, and used in compliance with any relevant instructions,
          standards or laws; and
        </Typography>
        <Typography variant="body2" className="terms inner">
          (c) adequately protected by the Customer against viruses and other
          security breaches.
        </Typography>

        <Typography variant="h5">Proper use</Typography>
        <Typography variant="body1" className="terms">
          3.3 The Pilot service and the SONODOT Components must not be used:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) in any way that is unlawful or in contravention of any licence,
          code of practice, instructions or guidelines issued by a regulatory
          authority, third person’s rights or SONODOT’s Acceptable Use Policy.
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) to send, communicate, knowingly receive, upload, download or use
          any material or make any calls that are offensive, abusive, indecent,
          defamatory, obscene, menacing, cause annoyance, inconvenience,
          needless anxiety or are intended to deceive; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (c) in any way SONODOT considers is or is likely to be detrimental to
          the provision of the Pilot to the Customer or to any of SONODOT’s
          other customers.
        </Typography>

        <Typography variant="body1" className="terms">
          3.4 The Customer will comply with SONODOT’s reasonable instructions
          regarding health, security, safety or the quality of the Pilot.
        </Typography>
        <Typography variant="body1" className="terms">
          3.5 The Customer must not sell, charge, transfer, assign or sub-
          license the Pilot service and the SONODOT Components to anyone else or
          use it as security for a loan.
        </Typography>

        <Typography variant="h5">Security</Typography>
        <Typography variant="body1" className="terms">
          3.6 The Customer is responsible for the proper use of User Security
          Details, if any, and must take all necessary steps to ensure they are
          kept confidential, secure and not made available to unauthorised
          persons.
        </Typography>
        <Typography variant="body1" className="terms">
          3.7 If the Customer believes that any User Security Details are or are
          likely to be used in an unauthorised way, the Customer must inform
          SONODOT immediately. The Customer must not change or attempt to change
          a username without SONODOT’s prior agreement.
        </Typography>
        <Typography variant="body1" className="terms">
          3.8 SONODOT does not guarantee the security of the Pilot against
          unauthorised or unlawful access or use. If SONODOT believes there is
          or is likely to be a breach of security or misuse of either the Pilot
          service or the SONODOT Components SONODOT may:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) change and/or suspend the User Security Details (and notify the
          Customer that it has done this); or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) require the Customer to change the User Security Details.
        </Typography>

        <Typography variant="h5">Internet access</Typography>
        <Typography variant="body1">
          3.9 Where the Pilot allows access to the internet the Customer
          understands and agrees that use of the internet is at the Customer’s
          own risk.
        </Typography>

        <Typography variant="h5">Content</Typography>
        <Typography variant="body1" className="terms">
          3.10 Where SONODOT provides the Customer with Content, the Customer’s
          use of that Content is at the Customer’s own risk. The Customer
          understands and agrees that:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) the Content may change from time to time;
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) the Content can only be used for its own purposes and is protected
          by copyright, trademark and other Intellectual Property Rights. The
          Customer must not copy, store, adapt, modify, transmit, distribute
          externally, play or show in public, broadcast or publish any part of
          the Content;
        </Typography>
        <Typography variant="body2" className="terms inner">
          (c) SONODOT does not guarantee the accuracy or completeness of the
          Content;
        </Typography>
        <Typography variant="body2" className="terms inner">
          (d) some of the Content will have its own terms and conditions. These
          may be displayed online or elsewhere. If the Customer accesses this
          Content the Customer must keep to these terms and conditions; and
        </Typography>
        <Typography variant="body2" className="terms inner">
          (e) access to any Content provided on a subscription basis as part of
          the Pilot will cease when the Pilot ends.
        </Typography>

        <Typography variant="h5">Operational changes</Typography>
        <Typography variant="body1" className="terms">
          3.11 Occasionally, for operational reasons, SONODOT may:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) vary the technical specification of the Pilot service or the
          SONODOT Components;
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) interrupt or suspend the Pilot. If this happens SONODOT will
          restore the Pilot as quickly as possible.
        </Typography>

        <Typography variant="h5">Indemnity</Typography>
        <Typography variant="body1">
          3.12 The Customer will indemnify SONODOT against any claims or legal
          proceedings that are brought or threatened against SONODOT by a third
          party because the Pilot service or the SONODOT Components is or has
          been used in breach of clause 3. SONODOT will notify the Customer of
          any such claims or proceedings and keep the Customer informed as to
          the progress of such claims or proceedings.
        </Typography>

        <Typography variant="h3">4 PILOT DATA COLLECTION</Typography>
        <Typography variant="body1" className="terms">
          4.1 The Customer agrees to:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) complete any questionnaires or other documents SONODOT may send to
          the Customer concerning the Pilot;
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) participate in any interviews and discussion groups, progress
          meetings and reviews; and
        </Typography>
        <Typography variant="body2" className="terms inner">
          (c) provide SONODOT with any information about the Pilot as SONODOT
          may reasonably require.
        </Typography>
        <Typography variant="body2" className="terms inner">
          The Customer accepts that all the information arising from clauses
          4.1(a), (b) and (c) is necessary for SONODOT to evaluate the Pilot.
        </Typography>

        <Typography variant="body1" className="terms">
          4.2 SONODOT reserves the right to make the Pilot results public,
          whether as a combined set of Customer results or on a per Customer
          basis (or both) but if SONODOT wishes to name the Customer, SONODOT
          will ask for the Customer’s written consent, such consent not to be
          unreasonably withheld.
        </Typography>
        <Typography variant="body1" className="terms">
          4.3 The Customer agrees that copyright in any information or designs
          arising from the Pilot will belong to SONODOT.
        </Typography>

        <Typography variant="h3">5 AFTER THE PILOT</Typography>
        <Typography variant="body1" className="terms">
          5.1 SONODOT gives no undertaking to make the Pilot service
          commercially available after the Pilot has ended. Any product or
          service that SONODOT may make available following the Pilot will be
          subject to SONODOT’s standard terms and conditions.
        </Typography>
        <Typography variant="body1" className="terms">
          5.2 At SONODOT’s option, instruction and expense, the Customer will
          either return any SONODOT Components to SONODOT or will make them
          available for SONODOT to collect.
        </Typography>
        <Typography variant="body1" className="terms">
          5.3 SONODOT reserves the right to delete all Customer data from the
          Pilot service.
        </Typography>

        <Typography variant="h3">6 CHARGES AND PAYMENT</Typography>
        <Typography variant="body1" className="terms">
          6.1 Any charges for the Pilot are detailed in the Schedule or Order.
        </Typography>
        <Typography variant="body1" className="terms">
          6.2 SONODOT will send bills to the address notified by the Customer to
          SONODOT.
        </Typography>
        <Typography variant="body1" className="terms">
          6.3 All charges are exclusive of VAT unless otherwise stated.
        </Typography>
        <Typography variant="body1" className="terms">
          6.4 Payment is due on the date on the bill unless otherwise stated.
        </Typography>

        <Typography variant="h3">7 ENDING THE PILOT</Typography>
        <Typography variant="body1" className="terms">
          7.1 The Pilot may be ended by either party on 7 days written notice to
          the other unless otherwise stated in the Schedule.
        </Typography>
        <Typography variant="body1" className="terms">
          7.2 SONODOT may end the Pilot at any time without notice if:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) the Customer breaches the Contract and, if the breach is capable
          of remedy, fails to put right the breach within a reasonable time of
          being asked by SONODOT to do so. In this clause breach includes
          non-payment of any valid invoice by the due date; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) SONODOT reasonably believes that the Pilot service or the SONODOT
          Components are being used in a way forbidden by clauses 3.2 - 3.8 and
          3.10 (b). This applies even if the Customer is unaware that the Pilot
          service or the SONODOT Components are being used in such a way; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (c) bankruptcy or insolvency proceedings are brought against the
          Customer or the Customer does not make any payment under a judgement
          of a Court on time or the Customer makes an arrangement with its
          creditors or a receiver, an administrative receiver or an
          administrator is appointed over any of the Customer’s assets or the
          Customer goes into liquidation.
        </Typography>

        <Typography variant="h3">8 LIMITATION OF LIABILITY</Typography>
        <Typography variant="body1" className="terms">
          8.1 Neither the Customer nor SONODOT excludes or restricts its
          liability for death or personal injury caused by its own negligence or
          the negligence of its employees or agents acting in the course of
          their employment or agency or for fraudulent misrepresentation or to
          any extent not permitted by law.
        </Typography>
        <Typography variant="body1" className="terms">
          8.2 Neither the Customer nor SONODOT shall be liable to the other in
          contract, tort (including negligence), breach of statutory duty or
          otherwise for any direct loss of profit, revenue, time, anticipated
          savings or profit or revenue, opportunity, data, use, business, wasted
          expenditure, or business interruption which may arise in relation to
          the Contract whether or not the Customer or SONODOT was advised in
          advance of the possibility of such loss or damage.
        </Typography>
        <Typography variant="body1" className="terms">
          8.3 Neither the Customer nor SONODOT shall be liable to the other in
          contract, tort (including negligence), breach of statutory duty or
          otherwise for any indirect or consequential loss of profit, revenue,
          time, anticipated savings or profit or revenue, opportunity, data,
          use, business, wasted expenditure, loss of or damage to physical
          property, business interruption or for any other indirect or
          consequential loss or punitive damages which may arise in relation to
          the Contract whether or not the Customer or SONODOT was advised in
          advance of the possibility of such loss or damage.
        </Typography>
        <Typography variant="body1" className="terms">
          8.4 Subject to clauses 8.1, 8.2 and 8.3 the Customer and SONODOT
          accept liability to the other in contract, tort (including
          negligence), breach of statutory duty or otherwise for direct loss
          limited to:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) £ 100,000 for loss of or damage to physical property in any period
          of 12 consecutive months; and
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) for all other loss or damage, the greater of either
        </Typography>
        <Typography variant="body2" className="terms inner">
          (i) £25,000; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (ii) 125% of the amounts paid by the Customer under the Contract.
        </Typography>
        <Typography variant="body1" className="terms">
          8.4 Subject to clauses 8.1, 8.2 and 8.3 the Customer and SONODOT
          accept liability to the other in contract, tort (including
          negligence), breach of statutory duty or otherwise for direct loss
          limited to:
        </Typography>
        <Typography variant="body1" className="terms">
          8.5 Clause 8.4 will not apply to any obligation to pay charges or to
          clauses 9.5 and 9.6.
        </Typography>
        <Typography variant="body1" className="terms">
          8.7 Each part of this clause operates separately. If any part of a
          clause is held by a Court to be unreasonable or inapplicable the rest
          of the clause shall continue to apply.
        </Typography>

        <Typography variant="h3">
          9 INTELLECTUAL PROPERTY AND CONFIDENTIALITY
        </Typography>

        <Typography variant="h5">Intellectual Property</Typography>
        <Typography variant="body1" className="terms">
          9.1 Except as expressly set out in the Contract, the Customer and
          SONODOT do not acquire any rights or licences to the other’s
          Intellectual Property Rights.
        </Typography>
        <Typography variant="body1" className="terms">
          9.2 If software is provided as part of the Pilot, SONODOT grants the
          Customer for the duration of the Pilot a non-exclusive,
          non-transferable licence to use such software for the purpose of the
          Pilot. Unless otherwise agreed in writing, any licence granted by
          SONODOT under this clause 9.2 will end when the Pilot is ended.
        </Typography>
        <Typography variant="body1" className="terms">
          9.3 If the Pilot includes software licensed by third parties who
          require the Customer to accept their terms of use, the Customer must
          keep to those terms.
        </Typography>
        <Typography variant="body1" className="terms">
          9.4 Except as permitted by applicable law or as expressly permitted
          under the Contract the Customer must not, without SONODOT’s prior
          written consent, copy, de-compile or modify any software, copy manuals
          or documentation or permit anyone else to do so.
        </Typography>
        <Typography variant="body1" className="terms">
          9.5 SONODOT will indemnify the Customer against all third party claims
          brought against the Customer, that the provision to the Customer by
          SONODOT of the Pilot service and the SONODOT Components infringe the
          Intellectual Property Rights of any third person.
        </Typography>
        <Typography variant="body1" className="terms">
          9.6 The indemnity set out in clause 9.5 does not apply to
          infringements arising out of:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) use of the Pilot service and the SONODOT Components in conjunction
          with any equipment, software or any other service not supplied by
          SONODOT; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) any modification which was not made or authorised by SONODOT and
          was carried out without SONODOT’s prior written consent; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (c) the use of the Pilot service and the SONODOT Components other than
          in accordance with the terms of the Contract; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (d) breach by the Customer of clause 3.10(d) or 9.3.
        </Typography>
        <Typography variant="body2" className="terms inner">
          The Customer will indemnify SONODOT against all claims and proceedings
          arising from such infringements.
        </Typography>
        <Typography variant="body1" className="terms">
          9.7 As a condition of the indemnities in clause 9.5 and 9.6 the
          indemnified party must:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) notify the indemnifying party promptly in writing of any
          allegation of infringement;
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) make no admission relating to the infringement;
        </Typography>
        <Typography variant="body2" className="terms inner">
          (c) allow the indemnifying party to conduct all negotiations and
          proceedings in respect of any such claims and give it all reasonable
          assistance in doing so (the indemnifying party will pay the other
          party’s reasonable expenses for such assistance); and
        </Typography>
        <Typography variant="body2" className="terms inner">
          (d) where SONODOT is the indemnifying party allow SONODOT to modify
          the Service as set out in clause 9.8.
        </Typography>
        <Typography variant="body1" className="terms">
          9.8 If the Pilot service or the SONODOT Components become, or SONODOT
          believes are likely to become, the subject of a claim of infringement
          of any Intellectual Property Rights SONODOT, at its option and
          expense, may secure for the Customer a right of continued use or
          modify or replace the product or service so that it is no longer
          infringing, provided that the modification or replacement does not
          materially affect the performance of the Pilot. If the indemnity
          offered by SONODOT in clause 9.5 applies and none of the remedies in
          this clause is available to SONODOT on reasonable terms, SONODOT may
          notify the Customer and end the Pilot without liability to the
          Customer.
        </Typography>

        <Typography variant="h5">Confidentiality</Typography>
        <Typography variant="body1" className="terms">
          9.9 Except to the extent any disclosure is required by law SONODOT and
          the Customer will keep in confidence any information, whether written
          or oral, of a confidential nature obtained under or in connection with
          the Contract. The Customer and SONODOT will not, without the written
          consent of the other, disclose such information to any person other
          than:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) their Group Company employees or professional advisers who need
          the information in order for the Customer or SONODOT to fulfil its
          obligations under the Contract; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) in the case of the Customer, its employees to the extent necessary
          to participate in the Pilot; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (c) in the case of SONODOT, the employees or professional advisers of
          its suppliers who need the information in order for SONODOT to fulfil
          its obligations under the Contract.
        </Typography>
        <Typography variant="body1" className="terms">
          9.10 Information will not be treated as confidential if it is:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) in the public domain other than in breach of the Contract; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) lawfully in the possession of the Customer or SONODOT before
          disclosure has taken place; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (c) obtained from a third person who is free to disclose it; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (d) replicated independently by someone without access or knowledge of
          the Information.
        </Typography>

        <Typography variant="body1" className="terms">
          9.11 Where the Freedom of Information Act 2000 applies to the Customer
          and the Customer receives a request under the Act that includes any
          information held by the Customer that was provided by SONODOT in
          connection with the Contract the Customer will:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) notify SONODOT immediately of the request; and
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) give SONODOT at least five Business Days to make representations.
        </Typography>

        <Typography variant="h3">10 GENERAL TERMS</Typography>
        <Typography variant="h5">Matters Beyond Reasonable Control</Typography>
        <Typography variant="body1" className="terms">
          10.1
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) If the Customer or SONODOT is unable to perform, or is delayed in
          performing, any obligation under the Contract because of something
          beyond its reasonable control including act of God, lightning, flood,
          exceptionally severe weather, fire, explosion, war, civil disorder,
          disputes or acts or omissions of local or central government or other
          competent authorities, or beyond the reasonable control of its
          suppliers, it will have no liability to the other for that failure or
          delay in performing.
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) SONODOT will not be liable for failure to or delay in conducting
          the Pilot if:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (i) another supplier delays or refuses the supply of an electronic
          communications service to SONODOT and no alternative service is
          available at reasonable cost; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (ii) legal or regulatory restrictions are imposed that prevent SONODOT
          from conducting the Pilot.
        </Typography>
        <Typography variant="body2" className="terms inner">
          (c) If any of the events detailed in clauses 10.1(a) or 10.1(b)
          continue for more than three months the Customer or SONODOT may end
          the Pilot in whole or part by written notice to the other.
        </Typography>

        <Typography variant="h5">Transfer of Rights and Obligations</Typography>
        <Typography variant="body1" className="terms">
          10.2 The Customer and SONODOT may not transfer any of their rights or
          obligations under the Contract without the written consent of the
          other, except that:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) The Customer may transfer its rights or obligations or both to a
          Group Company with the written consent of SONODOT, such consent not to
          be unreasonably withheld or delayed; and
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) SONODOT may transfer its rights or obligations or both to a Group
          Company without consent provided that it notifies the Customer that it
          has done so.
        </Typography>

        <Typography variant="h5">Severability</Typography>
        <Typography variant="body1" className="terms">
          10.3 If any term of the Contract is held invalid, illegal or
          unenforceable by any court of competent jurisdiction, it will be
          severed and the remaining terms will continue in full force as if the
          Contract had been made without the invalid, illegal or unenforceable
          terms.
        </Typography>

        <Typography variant="h5">Survival</Typography>
        <Typography variant="body1" className="terms">
          10.4 Clauses 9.9 and 9.11 will survive the end of the Pilot for two
          years.
        </Typography>

        <Typography variant="h5">Entire Agreement</Typography>
        <Typography variant="body1" className="terms">
          10.5 The Contract contains the entire agreement between the Customer
          and SONODOT and replaces all previous written or oral agreements
          relating to its content.
        </Typography>

        <Typography variant="h5">Waiver</Typography>
        <Typography variant="body1" className="terms">
          10.6 A failure or delay by the Customer or SONODOT to exercise any
          right or act upon a breach under the Contract will not be a waiver of
          that right or breach. If the Customer or SONODOT waives a right or
          breach of the Contract, that waiver is limited to the particular right
          or breach.
        </Typography>

        <Typography variant="h5">Rights of Third Parties</Typography>
        <Typography variant="body1" className="terms">
          10.7 A person who is not the Customer or SONODOT (including an
          employee, the officer, agent, representative or subcontractor of the
          Customer or SONODOT) has no right under Contracts (Rights of Third
          Parties Act 1999) to enforce any term of the Contract. This does not
          affect any right or remedy that exists or is available apart from that
          Act.
        </Typography>

        <Typography variant="h5">Notices</Typography>
        <Typography variant="body1" className="terms">
          10.8 Notices given under the Contract must be in writing and delivered
          to the following addresses unless otherwise stated in the Schedule:
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) to SONODOT at the address shown on the bill or any address which
          SONODOT provides to the Customer for this purpose; or
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) to the Customer at any one or more of the following: the address
          to which the Customer asks SONODOT to send bills or the address of the
          Site or the Customer’s primary email address or, if the Customer is a
          limited company, its registered office or its email address.
        </Typography>
        <Typography variant="body2" className="terms inner">
          This clause does not apply to notices given under clause 6.2.
        </Typography>
        <Typography variant="body1" className="terms">
          10.9 The Customer must inform SONODOT immediately if there is any
          change to any of the contact information the Customer provided to
          SONODOT.
        </Typography>

        <Typography variant="h5">Law and Jurisdiction</Typography>
        <Typography variant="body1" className="terms">
          10.10 The Contract is governed by the law of England and Wales and is
          subject to the non-exclusive jurisdiction of the English courts.
        </Typography>

        <Typography variant="h5">Data Protection</Typography>
        <Typography variant="body1" className="terms">
          10.11 The Customer and SONODOT will comply with its respective
          obligations under the provisions of Regulation (EU) 2016/679 (the
          “GDPR”) and any data protection, privacy or similar laws that apply to
          any personal data processed in connection with the Contract and
          references in this Condition to “Data Processor”, “Data Controller”
          and “Personal Data” shall have the meanings defined in the GDPR. Each
          party shall comply with its respective obligations under the
          provisions of the Data Protection Legislation and references in this
          Condition to “Data Processor”, “Data Controller and “Personal Data”
          shall have the meanings defined in the Data Protection Legislation.
          This Condition shall only apply to provision of the SONODOT Services.
          The Customer and SONODOT will provide such help and co-operation as is
          reasonably necessary or requested by the other to enable compliance
          with this clause.
        </Typography>
        <Typography variant="body1" className="terms">
          10.12 Subject to any and all applicable data protection legislation
          including, but not limited to, the GDPR (“Data Protection
          Legislation”) and the terms of SONODOT&apos;s privacy policy (as
          amended from time to time (“Privacy Policy”), SONODOT shall be
          entitled to use the Personal Data of the Customer as lawfully required
          by SONODOT to enable SONODOT to complete its obligations under this
          Agreement. Subject to all applicable data protection legislation
          including the Data Protection Legislation, SONODOT shall be entitled
          to use the Personal Data as lawfully required by SONODOT to enable
          SONODOT to successfully complete its obligations under this Agreement.
        </Typography>
        <Typography variant="body1" className="terms">
          10.13 SONODOT and the Customer agree that SONODOT shall be a Data
          Controller when processing Personal Data in accordance with this
          Agreement. SONODOT and the Customer agree that they shall at all times
          during the continuation of this Agreement and as necessary thereafter,
          comply with the provisions of the Data Protection Legislation.
        </Typography>
        <Typography variant="body1" className="terms">
          10.14 SONODOT will collect location data, when authorized, in order to provide the service.
        </Typography>

        <Typography variant="h5">Customer’s Instructions</Typography>
        <Typography variant="body1" className="terms">
          10.15 SONODOT may take instructions from a person whom it thinks, with
          good reason, is acting with the Customer’s permission.
        </Typography>

        <Typography variant="h3">11 DEFINITIONS</Typography>
        <Typography variant="body1" className="terms">
          11.1 In this Contract the following terms have the meanings shown next
          to them:
        </Typography>
        <Typography variant="body1" className="terms">
          “Contract” means the following documents, in order of precedence
        </Typography>
        <Typography variant="body2" className="terms inner">
          (a) the Conditions;
        </Typography>
        <Typography variant="body2" className="terms inner">
          (b) the Schedule;
        </Typography>
        <Typography variant="body2" className="terms inner">
          (c) the Order;
        </Typography>
        <Typography variant="body2" className="terms inner">
          (d) the charges schedule (if any); and
        </Typography>
        <Typography variant="body2" className="terms inner">
          (e) any other documents expressly incorporated by any of these
          documents or by agreement between the Customer and SONODOT
        </Typography>

        <Typography
          variant="body1"
          className="terms"
          style={{ marginTop: '30px' }}
        >
          SONODOT Components means any service or equipment, including any
          software, owned or controlled by SONODOT and placed on the Site for
          the purposes of conducting the Pilot
        </Typography>
        <Typography variant="body1" className="terms">
          “Business Day” means any day which is customarily regarded in the
          country or locality in which the Pilot service is being provided as a
          day when business is undertaken, excluding national, public, or bank
          holidays. If the day on or by which anything is to be performed is not
          a Business Day, it must be done on or by the following Business Day.
        </Typography>
        <Typography variant="body1" className="terms">
          “Content” means applications, data, information, video, graphics,
          sound, music, photographs, software or any other material.
        </Typography>
        <Typography variant="body1" className="terms">
          “Customer Equipment” means any equipment, including any software, for
          use with the Pilot that is not part of SONODOT’s network and which is
          owned or controlled by the Customer.
        </Typography>
        <Typography variant="body1" className="terms">
          “Group Company” means a subsidiary or holding company including a
          holding company, or a subsidiary of any such holding company, all as
          defined by Section 1 of the Companies Act 2006 and as subsequently
          re-enacted.
        </Typography>
        <Typography variant="body1" className="terms">
          “Intellectual Property Rights” means any patent, petty patent,
          registered design, copyright, design right, database right, rights in
          designs, invention, semiconductor topography right, know-how, or any
          similar right exercisable in any part of the world and including any
          applications for the registration of any patents or designs.
        </Typography>
        <Typography variant="body1" className="terms">
          “Order” means a SONODOT order form which has been filled out with all
          necessary information required by SONODOT and signed by both parties.
        </Typography>
        <Typography variant="body1" className="terms">
          “Schedule” means the schedule to these Conditions setting out the
          detail of the Pilot.
        </Typography>
        <Typography variant="body1" className="terms">
          “Site” means the place(s) at which SONODOT provides the Pilot service
          to the Customer.
        </Typography>
        <Typography variant="body1" className="terms">
          “Pilot” means the trialing of the Pilot service by the Customer as set
          out in the Schedule.
        </Typography>
        <Typography variant="body1" className="terms">
          “Pilot service” means the service stated in the Schedule.
        </Typography>
        <Typography variant="body1" className="terms">
          “User” means anyone who is permitted by the Customer to use or access
          the Pilot service.
        </Typography>
        <Typography variant="body1" className="terms">
          “User Security Details” means any IDs, user names, personal
          identification numbers and passwords.
        </Typography>
      </div>
    </Paper>
  </div>
);

export default Terms;
