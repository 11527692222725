import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  changeWSStatus,
  ReconnectingStates,
  fetchAccounts,
  fetchAssets,
  fetchOrganisationInformation,
  fetchWarehouses,
  IFetchAccounts,
  IFetchAssets,
  IFetchOrganisationInformation,
  IFetchWarehouses,
  changeLanguage,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { getAccountLocale } from '@selectors/accounts';
import { getActiveLanguage } from '@selectors/app';
import App from './App';

import './App.css';
import { withRouter } from '@app/utils/withRouter';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account,
  authenticated: state.authentication.authenticated,
  firstAccountCheck: state.authentication.firstAccountCheck,
  error: state.authentication.error,
  language: getActiveLanguage(state),
  locale: getAccountLocale(state.authentication),
  reconnecting: state.shallowLoading.reconnecting,
  theme: state.app.theme,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeLanguage: (language: string) => dispatch(changeLanguage(language)),
  changeWSStatus: (reconnecting: ReconnectingStates) =>
    dispatch(changeWSStatus(reconnecting)),
  fetchAccounts: (properties: IFetchAccounts) =>
    dispatch(fetchAccounts.request(properties)),
  fetchAssets: (properties: IFetchAssets) =>
    dispatch(fetchAssets.request(properties)),
  fetchOrganisationInformation: (properties: IFetchOrganisationInformation) =>
    dispatch(fetchOrganisationInformation.request(properties)),
  fetchWarehouses: (properties: IFetchWarehouses) =>
    dispatch(fetchWarehouses.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
