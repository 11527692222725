import { Color, Group, Path, Point, Matrix } from 'paper';
import { Component } from 'react';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { transformMeters, transformMetersToPixels } from '@dashboard_utils/index';
import { defaultTransformationMatrix } from '../../../consts';
import {
  ICircleFeature,
  IFeatureInfo,
  IGroupFeature,
  IPathFeature,
} from '../../../types';
import { orderLayers } from '../../eventEmitter';
import MapImages from '../../MapImages';
import Paper from '../../Paper';
import { Task } from '@models/Task';
import { geodetic2enu } from '@app/common/FullMap/ENULocations';

interface IData {
  tasks: Task[];
  loading: boolean;
}

interface IProps {
  id: string;
  data: IData;
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  paper: Paper;
}

class TaskMapLayer extends Component<IProps> {
  private elements: paper.Group[] = [];

  public componentDidMount() {
    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { data } = this.props;

    if (prevProps.data.loading !== data.loading) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    this.clear();
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((e) => e.remove());
    this.elements = [];
  }

  public load() {
    const {
      data,
      floorplan,
      mapImages,
      paper,
    } = this.props;

    paper.scope.activate();

    data.tasks
      .forEach((task) => {
        let center;
        if (task.lat && task.long) {
          const { east, north } = geodetic2enu(
            { latitude: task.lat, longitude: task.long },
            {
              latitude: floorplan.geodeticCenterLatitude || 0,
              longitude: floorplan.geodeticCenterLongitude || 0,
            }
          );

          const meterPosition = transformMeters(
            [east, north],
            floorplan.enuTransformationMatrix
          );

          center = new Point(
            transformMetersToPixels(
              [meterPosition[0], meterPosition[1]],
              floorplan.transformationMatrix || defaultTransformationMatrix,
              floorplan.scale || 1
            )
          );
        } else {
          center = new Point(
            transformMetersToPixels(
              [task.positionX || 0, task.positionY || 0],
              floorplan.transformationMatrix || defaultTransformationMatrix,
              floorplan.scale || 1
            )
          );
        }

        const featureInfo: IFeatureInfo = {
          coordinates: [[task.positionX || 0, task.positionY || 0]],
          id: `task_${task.id}`,
          title: task.name,
          props: {},
          type: 'task',
        };

        const path = new Path() as IPathFeature;
        path.strokeColor = new Color('white');
        path.strokeWidth = 5;
        path.strokeScaling = true;
        path.add(new Point([center.x, center.y + 10]));
        path.add(new Point([center.x, center.y - 10]));
        path.closePath();
        path.featureInfo = featureInfo;
        path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

        const circle = new Path.Circle(
          new Point([center.x, center.y]),
          20
        ) as ICircleFeature;
        circle.fillColor = new Color('blue');
        circle.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
        circle.featureInfo = featureInfo;

        const group = new Group([circle, path]) as IGroupFeature;
        group.featureInfo = featureInfo;

        this.elements.push(group);
      });

    orderLayers();
  }

  public render() {
    return null;
  }
}

export default TaskMapLayer;
