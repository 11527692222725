import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  IUpdateAccountSettings,
  updateAccountSettings,
  toggleTheme,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { isAccountSettingsBasicLoading } from '@selectors/accounts';

import OtherSettings from './OtherSettings';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account!,
  changed: state.authentication.settings.basic.changed,
  loading: isAccountSettingsBasicLoading(state.authentication),
  theme: state.app.theme,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateAccountSettings: (properties: IUpdateAccountSettings) =>
    dispatch(updateAccountSettings.request(properties)),
  toggleTheme: () => dispatch(toggleTheme()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherSettings);
