import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { Component } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import { Task, TaskStatus } from '@models/Task';
import { Employees } from '@models/Employee';
import { ConfirmDialogActions, IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import MUITable, { ITableColumn } from '@app/common/Table';
import Loading from '@app/common/Loading';
import { Assets } from '@models/Asset';
import { Date } from '@dashboard_utils/index';

const messages = {
  deleteMsg: {
    defaultMessage: 'Are you sure that you want to DELETE this task?',
    id: 'dashboard.tasks.delete_message',
  },
  thName: {
    defaultMessage: 'Name',
    id: 'dashboard.tasks.table.name',
  },
  thStatus: {
    defaultMessage: 'Status',
    id: 'dashboard.tasks.table.status',
  },
  thEmployees: {
    defaultMessage: 'Employees',
    id: 'dashboard.tasks.table.employees',
  },
  thAssets: {
    defaultMessage: 'Assets',
    id: 'dashboard.tasks.table.assets',
  },
};

export interface IAnchorEL {
  menu: HTMLElement | undefined;
}

interface IProps {
  assets: Assets;
  confirm: (props: IConfirmDialog) => void;
  data: Task[];
  deleteTask: (id: string, intl: IntlShape) => void;
  employees: Employees;
  openForm: (data?: Task) => void;
  intl: IntlShape;
  loading: boolean;
}

interface IState {
  anchorEl: Record<number, IAnchorEL>;
  columns: ITableColumn[];
  page: number;
}

class TasksTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      anchorEl: {},
      columns: [
        { label: intl.formatMessage(messages.thName), name: 'name' },
        { label: intl.formatMessage(messages.thStatus), name: 'status' },
        { label: intl.formatMessage(messages.thEmployees), name: 'employees' },
        { label: intl.formatMessage(messages.thAssets), name: 'assets' },
        {
          label: ' ',
          name: 'menu',
          options: { interaction: false },
        },
      ],
      page: 1,
    };

    this.customRowRender = this.customRowRender.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  public handleMenu(index: number, event: any) {
    const { anchorEl } = this.state;

    this.setState({
      anchorEl: {
        ...anchorEl,
        [index]: {
          ...anchorEl[index],
          menu: event.currentTarget,
        },
      },
    });
  }

  public handleMenuClose(index: number) {
    const { anchorEl } = this.state;
    anchorEl[index].menu = undefined;
    this.setState({ anchorEl });
  }

  public handleEdit(data: any, index: number) {
    const { anchorEl } = this.state;
    const { openForm } = this.props;

    anchorEl[index].menu = undefined;

    this.setState({ anchorEl }, () => openForm(data));
  }

  public handleDelete(id: string, index: number) {
    const { confirm, deleteTask, intl } = this.props;
    const { anchorEl } = this.state;

    anchorEl[index].menu = undefined;

    this.setState({ anchorEl }, () => {
      confirm({
        confirmType: ConfirmDialogActions.DELETE,
        message: intl.formatMessage(messages.deleteMsg),
        onConfirmation: () => deleteTask(id, intl),
      });
    });
  }

  public handlePageChange(event: any, page: number) {
    this.setState({ page });
  }

  public customRowRender(
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    const { assets, data, employees } = this.props;
    const { anchorEl } = this.state;

    return (
      <TableRow key={rowIndex}>
        {columns[0] && <TableCell>{rowData[0]}</TableCell>}
        {columns[1] && <TableCell>{
          rowData[1] === TaskStatus.PENDING
          ? <Chip label="Pending" color="primary" />
          : rowData[1] === TaskStatus.PROGRESS
          ? <Chip label="Progress" color="primary" />
          : <Chip label="Done" color="success" />
        }</TableCell>}
        {columns[2] && <TableCell>
          <AvatarGroup max={9} style={{ flexDirection: 'row' }}>
          {rowData[2].map((employeeId: number) =>
            <Avatar key={employeeId}
              alt={(employees[employeeId] || {}).name}
              src={(employees[employeeId] || {}).image}
              style={{ backgroundColor: '#efefef' }} />)}
          </AvatarGroup>
        </TableCell>}
        {columns[3] && <TableCell>
          {rowData[3].map((assetId: number) => (assets[assetId] || {}).name).join(', ')}
        </TableCell>}
        {columns[4] && (
          <TableCell>
            <div style={{ float: 'right' }}>
              <IconButton
                aria-label="More"
                aria-owns={
                  (anchorEl[dataIndex] || {}).menu ? 'long-menu' : undefined
                }
                aria-haspopup="true"
                onClick={(event: any) => this.handleMenu(dataIndex, event)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={(anchorEl[dataIndex] || {}).menu}
                open={!!(anchorEl[dataIndex] || {}).menu}
                onClose={() => this.handleMenuClose(dataIndex)}
              >
                <MenuItem onClick={() => this.handleEdit(data[dataIndex], dataIndex)}>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.tasks.edittask"
                        defaultMessage="Edit"
                      />
                    }
                  />
                </MenuItem>
                <MenuItem onClick={() => this.handleDelete(data[dataIndex].id, dataIndex)}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.tasks.deletetask"
                        defaultMessage="Delete"
                      />
                    }
                  />
                </MenuItem>
              </Menu>
            </div>
          </TableCell>
        )}
      </TableRow>
    );
  }

  public render() {
    const { data, loading } = this.props;
    const { columns } = this.state;

    return (
      <>
        <Loading loading={loading}>
          <div className="table-container">
            <Card style={{ flex: 1 }}>
              <CardContent>
                <MUITable
                  columns={columns}
                  data={data}
                  customRowRender={this.customRowRender}
                  tableId="tasks"
                />
              </CardContent>
            </Card>
          </div>
        </Loading>
      </>
    );
  }
}

export default injectIntl(TasksTab);
