import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';
import React, { Component } from 'react';

import { IDeletePlan, IFetchPlans } from '@actions/plans';
import Plan from '@models/Plan';
import MUITable, { ITableColumn } from '@app/common/Table';
import Loading from '@app/common/Loading';
import { injectIntl, IntlShape } from 'react-intl';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '@app/dialogs/ConfirmDialog/types';

interface IProps {
  confirm: (properties: IConfirmDialog) => void;
  deletePlan: (properties: IDeletePlan) => void;
  plans: Plan[];
  fetchPlans: (properties: IFetchPlans) => void;
  floorplanId: string;
  intl: IntlShape;
  language: string;
  loading: boolean;
  router: any;
}
interface IState {
  columns: ITableColumn[];
  search?: string;
}

class List extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      columns: [
        { label: 'Name', name: 'name' },
        { label: 'Created', name: 'created' },
        { label: 'Options', name: 'options', options: { interaction: false } },
      ],
    };

    this.customRowRender = this.customRowRender.bind(this);
    this.handleAddPlan = this.handleAddPlan.bind(this);
  }

  public componentDidMount() {
    const { fetchPlans, floorplanId } = this.props;

    fetchPlans({ floorplanId });
  }

  public componentDidUpdate(prevProps: IProps) {
    const { fetchPlans, floorplanId } = this.props;

    if (prevProps.floorplanId !== floorplanId) {
      fetchPlans({ floorplanId });
    }
  }

  public handleAddPlan() {
    const { floorplanId, router, language } = this.props;

    router.navigate(
      `/${language}/dashboard/rtlsplanner/plan/floorplan/${floorplanId}/new`,
      { replace: true }
    );
  }

  public handleEdit(id: string) {
    const { floorplanId, router, language } = this.props;

    router.navigate(
      `/${language}/dashboard/rtlsplanner/plan/floorplan/${floorplanId}/id/${id}/edit`,
      { replace: true }
    );
  }

  public handleDelete(id: string) {
    const { confirm, deletePlan, intl } = this.props;

    confirm({
      confirmType: ConfirmDialogActions.DELETE,
      message: 'Are you sure that you want to delete this plan?',
      onConfirmation: () => {
        deletePlan({ id, intl });
      },
    });
  }

  public customRowRender(
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    const { plans } = this.props;

    const data: Plan = plans[dataIndex];

    if (!data) {
      return <TableRow key={rowIndex} />;
    }

    return (
      <TableRow key={rowIndex}>
        {columns[0] && <TableCell>{rowData[0]}</TableCell>}
        {columns[1] && (
          <TableCell>
            {format(new Date(rowData[1]), 'yyyy-MM-dd HH:mm')}
          </TableCell>
        )}
        {columns[2] && (
          <TableCell style={{ textAlign: 'right' }}>
            <IconButton size="small" onClick={() => this.handleEdit(data.id)}>
              <EditIcon />
            </IconButton>
            <IconButton size="small" onClick={() => this.handleDelete(data.id)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  }

  public render() {
    const { loading, plans } = this.props;
    const { columns } = this.state;

    return (
      <Card>
        <CardHeader
          action={
            <Button id="add-diagram" onClick={this.handleAddPlan}>
              <AddIcon style={{ marginRight: '5px' }} />
              Add plan
            </Button>
          }
          title="RTLS Plans"
        />
        <CardContent>
          <Loading loading={loading}>
            <div className="table-container">
              <MUITable
                columns={columns}
                data={plans}
                customRowRender={this.customRowRender}
                tableId="plans"
              />
            </div>
          </Loading>
        </CardContent>
      </Card>
    );
  }
}

export default injectIntl(List);
