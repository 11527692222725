import React, { FunctionComponent } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from "history";

createBrowserHistory();

import App from './index';

/**
 * BaseRouter initiates React Rotuer with a single route to fetch
 * the language parameter from the url.
 */

const BaseRouter: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:language/*" element={<App />} />
        <Route
          path="/"
          element={<Navigate to={`/en`} replace />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default BaseRouter;
