import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { Component } from 'react';
import { Layout, PlotData } from 'plotly.js';

import { FetchMetric } from '@actions/analytics';
import PlotStripped from '../../tabs/AnalyticsTab/PlotsTab/PlotComponent/PlotComponent';

const PLOT_DEFAULT_LAYOUT = {
  margin: { t: 0, l: 30, r: 20, b: 40 },
  legend: { orientation: 'h' },
  height: 250,
} as Partial<Layout>;

const productivityData = [
  {
    x: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    y: [
      6500, 9000, 5000, 14000, 9500, 7000, 4000, 4900, 5000, 2500, 5000, 4500,
    ],
    mode: 'lines',
    name: '2019',
  },
  {
    x: ['Jan', 'Feb', 'Mar', 'Apr'],
    y: [12000, 11000, 7500, 16000],
    mode: 'lines',
    name: '2020',
  },
];
const productivity2Data = [
  {
    x: [
      '3-Wheel Electric Forklift',
      'Electric Pneumatic Forklift',
      'Stand-Up Rider Forklift',
      'High-Capacity Electric Cushion Forklift',
    ],
    y: [3, 4.3, 4.5, 5],
    type: 'bar',
    name: '2019',
  },
  {
    x: [
      '3-Wheel Electric Forklift',
      'Electric Pneumatic Forklift',
      'Stand-Up Rider Forklift',
      'High-Capacity Electric Cushion Forklift',
    ],
    y: [3.2, 4.4, 4.6, 5],
    type: 'bar',
    name: '2020',
  },
];

const efficiencyData = [
  {
    x: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    y: [11, 5, 15, 25, 2, 21, 18, 11, 21, 13, 9, 21],
    mode: 'lines',
    name: '2019',
  },
  {
    x: ['Jan', 'Feb', 'Mar', 'Apr'],
    y: [4, 6, 19, 22],
    mode: 'lines',
    name: '2020',
  },
];

const efficiency2Data = [
  {
    y: [
      '3-Wheel Electric Forklift',
      'Electric Pneumatic Forklift',
      'Stand-Up Rider Forklift',
      'High-Capacity Electric Cushion Forklift',
    ],
    x: [6, 4, 3, 7],
    type: 'bar',
    name: '2019',
    orientation: 'h',
  },
  {
    y: [
      '3-Wheel Electric Forklift',
      'Electric Pneumatic Forklift',
      'Stand-Up Rider Forklift',
      'High-Capacity Electric Cushion Forklift',
    ],
    x: [4.5, 5.8, 6.7, 6.8],
    type: 'bar',
    name: '2020',
    orientation: 'h',
  },
];

const safetyData = [
  {
    x: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    y: [11, 5, 15, 25, 2, 21, 18, 11, 21, 13, 9, 21],
    type: 'bar',
    name: '2019',
  },
  {
    x: ['Jan', 'Feb', 'Mar', 'Apr'],
    y: [4, 6, 19, 22],
    type: 'bar',
    name: '2020',
  },
];

const safety2Data = [
  {
    labels: ['Speeding', 'Near collisions', 'Near run overs'],
    values: [57, 100, 20],
    type: 'pie',
  },
];

const sustainabilityData = [
  {
    y: ['Warehouse', 'Welding', 'Punching', 'Dock', 'Assembly'],
    x: [0.073938, 0.0258783, 0.1293915, 0.0221814, 0.1293915],
    type: 'bar',
    name: '2019',
    orientation: 'h',
  },
  {
    y: ['Warehouse', 'Welding', 'Punching', 'Dock', 'Assembly'],
    x: [0.13546, 0.047411, 0.040638, 0.223509, 0.237055],
    type: 'bar',
    name: '2020',
    orientation: 'h',
  },
];

const sustainability2Data = [
  {
    y: [
      '3-Wheel Electric Forklift',
      'Electric Pneumatic Forklift',
      'Stand-Up Rider Forklift',
      'High-Capacity Electric Cushion Forklift',
    ],
    x: [3, 4.3, 4.5, 5],
    type: 'bar',
    name: '2019',
    orientation: 'h',
  },
  {
    y: [
      '3-Wheel Electric Forklift',
      'Electric Pneumatic Forklift',
      'Stand-Up Rider Forklift',
      'High-Capacity Electric Cushion Forklift',
    ],
    x: [3.2, 4.4, 4.6, 5],
    type: 'bar',
    name: '2020',
    orientation: 'h',
  },
];

interface IProps {
  extendGrid: boolean;
  locale: string;
  measurementUnits: string;
  theme: string;
}

interface IState {
  plots: Record<string, boolean>;
}

class Home extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      plots: {},
    };

    this.showPlot = this.showPlot.bind(this);
  }

  public showPlot(id: string) {
    this.setState(({ plots }) => ({ plots: { ...plots, [id]: !plots[id] } }));
  }

  public render() {
    const { extendGrid, locale, theme } = this.props;
    const { plots } = this.state;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item lg={extendGrid ? 3 : 6} sm={extendGrid ? 6 : 12} xs={12}>
            <Card className="column-title">
              <CardHeader
                title="Productivity"
                avatar={
                  <img
                    src="/assets/productivity.png"
                    width="48px"
                    height="48px"
                    alt=""
                  />
                }
              />
            </Card>
            <Card className="column-card">
              <CardHeader
                avatar={
                  <FiberManualRecordIcon
                    fontSize="large"
                    style={{ color: 'green' }}
                  />
                }
                title={<span className="title">Number of pallets</span>}
                subheader={
                  <b className="subtitle" style={{ color: 'green' }}>
                    + 9.9%
                  </b>
                }
                className="head"
              />
              <CardContent className="body">
                <b>
                  <span className="fact" style={{ color: 'green' }}>
                    13.546
                  </span>
                  <span>pallets</span>
                </b>
                <div>Compared to 12.323 pallets in April 2019</div>
              </CardContent>
              <CardActions
                className="column-button"
                onClick={() => this.showPlot('numberOfPallets')}
              >
                <span>Monthly evolution</span>
                {!plots.numberOfPallets ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </CardActions>
              {plots.numberOfPallets ? (
                <CardContent className="plot">
                  <PlotStripped
                    filterId=""
                    metricId=""
                    aggregationState={{
                      id: 'productivityData',
                      loading: false,
                      plot: {
                        data: productivityData as Partial<PlotData>[],
                        layout: { ...PLOT_DEFAULT_LAYOUT, a: 1 },
                      },
                      conditions: {} as FetchMetric,
                      level1: null,
                      level1Option: null,
                      level2: null,
                      level2Option: null,
                      level3: null,
                      level3Option: null,
                    }}
                    locale={locale}
                    theme={theme}
                    move={false}
                  />
                </CardContent>
              ) : null}
            </Card>
            <Card className="column-card">
              <CardHeader
                avatar={
                  <FiberManualRecordIcon
                    fontSize="large"
                    style={{ color: 'red' }}
                  />
                }
                title={
                  <span className="title">Average time / Pallet moved</span>
                }
                subheader={
                  <b className="subtitle" style={{ color: 'red' }}>
                    + 2.4%
                  </b>
                }
                className="head"
              />
              <CardContent className="body">
                <b>
                  <span className="fact" style={{ color: 'red' }}>
                    4.3
                  </span>
                  <span>min</span>
                </b>
                <div>Compared to 4.2 min in April 2019</div>
              </CardContent>
              <CardActions
                className="column-button"
                onClick={() => this.showPlot('palletMovedTime')}
              >
                <span>By Asset Category</span>
                {!plots.palletMovedTime ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </CardActions>
              {plots.palletMovedTime ? (
                <CardContent className="plot">
                  <PlotStripped
                    filterId=""
                    metricId=""
                    aggregationState={{
                      id: 'productivity2Data',
                      loading: false,
                      plot: {
                        data: productivity2Data as Partial<PlotData>[],
                        layout: {
                          ...PLOT_DEFAULT_LAYOUT,
                          barmode: 'group',
                        },
                      },
                      conditions: {} as FetchMetric,
                      level1: null,
                      level1Option: null,
                      level2: null,
                      level2Option: null,
                      level3: null,
                      level3Option: null,
                    }}
                    locale={locale}
                    theme={theme}
                    move={false}
                  />
                </CardContent>
              ) : null}
            </Card>
          </Grid>
          <Grid item lg={extendGrid ? 3 : 6} sm={extendGrid ? 6 : 12} xs={12}>
            <Card className="column-title">
              <CardHeader
                title="Efficiency"
                avatar={
                  <img
                    src="/assets/efficiency.png"
                    width="48px"
                    height="48px"
                    alt=""
                  />
                }
              />
            </Card>
            <Card className="column-card">
              <CardHeader
                avatar={
                  <FiberManualRecordIcon
                    fontSize="large"
                    style={{ color: 'red' }}
                  />
                }
                title={<span className="title">Fleet idle time</span>}
                subheader={
                  <b className="subtitle" style={{ color: 'red' }}>
                    + 50.0%
                  </b>
                }
                className="head"
              />
              <CardContent className="body">
                <b>
                  <span className="fact" style={{ color: 'red' }}>
                    15%
                  </span>
                  <span>of time avlb</span>
                </b>
                <div>Compared to 10% in April 2019</div>
              </CardContent>
              <CardActions
                className="column-button"
                onClick={() => this.showPlot('availability')}
              >
                <span>Monthly evolution</span>
                {!plots.availability ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </CardActions>
              {plots.availability ? (
                <CardContent className="plot">
                  <PlotStripped
                    filterId=""
                    metricId=""
                    aggregationState={{
                      id: 'efficiencyData',
                      loading: false,
                      plot: {
                        data: efficiencyData as Partial<PlotData>[],
                        layout: {
                          ...PLOT_DEFAULT_LAYOUT,
                          yaxis: { ticksuffix: '%' },
                        },
                      },
                      conditions: {} as FetchMetric,
                      level1: null,
                      level1Option: null,
                      level2: null,
                      level2Option: null,
                      level3: null,
                      level3Option: null,
                    }}
                    locale={locale}
                    theme={theme}
                    move={false}
                  />
                </CardContent>
              ) : null}
            </Card>
            <Card className="column-card">
              <CardHeader
                avatar={
                  <FiberManualRecordIcon
                    fontSize="large"
                    style={{ color: 'green' }}
                  />
                }
                title={
                  <span className="title">Fleet simultaneous utilization</span>
                }
                subheader={
                  <b className="subtitle" style={{ color: 'green' }}>
                    + 20.0%
                  </b>
                }
                className="head"
              />
              <CardContent className="body">
                <b>
                  <span className="fact" style={{ color: 'green' }}>
                    6%
                  </span>
                  <span>of time avlb</span>
                </b>
                <div>Compared to 5% in April 2019</div>
              </CardContent>
              <CardActions
                className="column-button"
                onClick={() => this.showPlot('availabilityByAssetType')}
              >
                <span>By Asset Category</span>
                {!plots.availabilityByAssetType ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </CardActions>
              {plots.availabilityByAssetType ? (
                <CardContent className="plot">
                  <PlotStripped
                    filterId=""
                    metricId=""
                    aggregationState={{
                      id: 'efficiency2Data',
                      loading: false,
                      plot: {
                        data: efficiency2Data as Partial<PlotData>[],
                        layout: {
                          ...PLOT_DEFAULT_LAYOUT,
                          margin: { ...PLOT_DEFAULT_LAYOUT.margin, l: 100 },
                          barmode: 'group',
                        },
                      },
                      conditions: {} as FetchMetric,
                      level1: null,
                      level1Option: null,
                      level2: null,
                      level2Option: null,
                      level3: null,
                      level3Option: null,
                    }}
                    locale={locale}
                    theme={theme}
                    move={false}
                  />
                </CardContent>
              ) : null}
            </Card>
          </Grid>
          <Grid item lg={extendGrid ? 3 : 6} sm={extendGrid ? 6 : 12} xs={12}>
            <Card className="column-title">
              <CardHeader
                title="Safety"
                avatar={
                  <img
                    src="/assets/safety.png"
                    width="48px"
                    height="48px"
                    alt=""
                  />
                }
              />
            </Card>
            <Card className="column-card">
              <CardHeader
                avatar={
                  <FiberManualRecordIcon
                    fontSize="large"
                    style={{ color: 'green' }}
                  />
                }
                title={<span className="title">Number of accidents</span>}
                subheader={
                  <b className="subtitle" style={{ color: 'green' }}>
                    - 12.0%
                  </b>
                }
                className="head"
              />
              <CardContent className="body">
                <b>
                  <span className="fact" style={{ color: 'green' }}>
                    22
                  </span>
                  <span>accidents</span>
                </b>
                <div>Compared to 25 accidents in April 2019</div>
              </CardContent>
              <CardActions
                className="column-button"
                onClick={() => this.showPlot('accidents')}
              >
                <span>Monthly evolution</span>
                {!plots.accidents ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </CardActions>
              {plots.accidents ? (
                <CardContent className="plot">
                  <PlotStripped
                    filterId=""
                    metricId=""
                    aggregationState={{
                      id: 'safetyData',
                      loading: false,
                      plot: {
                        data: safetyData as Partial<PlotData>[],
                        layout: {
                          ...PLOT_DEFAULT_LAYOUT,
                          barmode: 'group',
                        },
                      },
                      conditions: {} as FetchMetric,
                      level1: null,
                      level1Option: null,
                      level2: null,
                      level2Option: null,
                      level3: null,
                      level3Option: null,
                    }}
                    locale={locale}
                    theme={theme}
                    move={false}
                  />
                </CardContent>
              ) : null}
            </Card>
            <Card className="column-card">
              <CardHeader
                avatar={
                  <FiberManualRecordIcon
                    fontSize="large"
                    style={{ color: 'green' }}
                  />
                }
                title={<span className="title">Incidents events</span>}
                subheader={
                  <b className="subtitle" style={{ color: 'green' }}>
                    - 19.2%
                  </b>
                }
                className="head"
              />
              <CardContent className="body">
                <b>
                  <span className="fact" style={{ color: 'green' }}>
                    143
                  </span>
                  <span>near accidents</span>
                </b>
                <div>Compared to 177 near accidents in April 2019</div>
              </CardContent>
              <CardActions
                className="column-button"
                onClick={() => this.showPlot('nearAccidentsByAssetType')}
              >
                <span>Incident Type</span>
                {!plots.nearAccidentsByAssetType ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </CardActions>
              {plots.nearAccidentsByAssetType ? (
                <CardContent className="plot">
                  <PlotStripped
                    filterId=""
                    metricId=""
                    aggregationState={{
                      id: 'safety2Data',
                      loading: false,
                      plot: {
                        data: safety2Data as Partial<PlotData>[],
                        layout: { ...PLOT_DEFAULT_LAYOUT, a: 1 },
                      },
                      conditions: {} as FetchMetric,
                      level1: null,
                      level1Option: null,
                      level2: null,
                      level2Option: null,
                      level3: null,
                      level3Option: null,
                    }}
                    locale={locale}
                    theme={theme}
                    move={false}
                  />
                </CardContent>
              ) : null}
            </Card>
          </Grid>
          <Grid item lg={extendGrid ? 3 : 6} sm={extendGrid ? 6 : 12} xs={12}>
            <Card className="column-title">
              <CardHeader
                title="Sustainability"
                avatar={
                  <img
                    src="/assets/sustainability.png"
                    width="48px"
                    height="48px"
                    alt=""
                  />
                }
              />
            </Card>
            <Card className="column-card">
              <CardHeader
                avatar={
                  <FiberManualRecordIcon
                    fontSize="large"
                    style={{ color: 'green' }}
                  />
                }
                title={<span className="title">Tons of CO2 saved</span>}
                subheader={
                  <b className="subtitle" style={{ color: 'green' }}>
                    + 83.2%
                  </b>
                }
                className="head"
              />
              <CardContent className="body">
                <b>
                  <span className="fact" style={{ color: 'green' }}>
                    0.37
                  </span>
                  <span>tons</span>
                </b>
                <div>Compared to 0.68 in April 2019</div>
              </CardContent>
              <CardActions
                className="column-button"
                onClick={() => this.showPlot('co2SavingsByArea')}
              >
                <span>Savings by AreaTons</span>
                {!plots.co2SavingsByArea ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </CardActions>
              {plots.co2SavingsByArea ? (
                <CardContent className="plot">
                  <PlotStripped
                    filterId=""
                    metricId=""
                    aggregationState={{
                      id: 'sustainabilityData',
                      loading: false,
                      plot: {
                        data: sustainabilityData as Partial<PlotData>[],
                        layout: {
                          ...PLOT_DEFAULT_LAYOUT,
                          margin: { ...PLOT_DEFAULT_LAYOUT.margin, l: 80 },
                          barmode: 'group',
                        },
                      },
                      conditions: {} as FetchMetric,
                      level1: null,
                      level1Option: null,
                      level2: null,
                      level2Option: null,
                      level3: null,
                      level3Option: null,
                    }}
                    locale={locale}
                    theme={theme}
                    move={false}
                  />
                </CardContent>
              ) : null}
            </Card>
            <Card className="column-card">
              <CardHeader
                avatar={
                  <FiberManualRecordIcon
                    fontSize="large"
                    style={{ color: 'green' }}
                  />
                }
                title={
                  <span className="title">CO2 emission / Pallet moved</span>
                }
                subheader={
                  <b className="subtitle" style={{ color: 'green' }}>
                    - 8.3%
                  </b>
                }
                className="head"
              />
              <CardContent className="body">
                <b>
                  <span className="fact" style={{ color: 'green' }}>
                    0.55
                  </span>
                  <span>Kg</span>
                </b>
                <div>Compared to 0.6 Kg in April 2019</div>
              </CardContent>
              <CardActions
                className="column-button"
                onClick={() => this.showPlot('co2ByAssetType')}
              >
                <span>By Asset Category</span>
                {!plots.co2ByAssetType ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </CardActions>
              {plots.co2ByAssetType ? (
                <CardContent className="plot">
                  <PlotStripped
                    filterId=""
                    metricId=""
                    aggregationState={{
                      id: 'sustainability2Data',
                      loading: false,
                      plot: {
                        data: sustainability2Data as Partial<PlotData>[],
                        layout: {
                          ...PLOT_DEFAULT_LAYOUT,
                          margin: { ...PLOT_DEFAULT_LAYOUT.margin, l: 80 },
                          barmode: 'group',
                        },
                      },
                      conditions: {} as FetchMetric,
                      level1: null,
                      level1Option: null,
                      level2: null,
                      level2Option: null,
                      level3: null,
                      level3Option: null,
                    }}
                    locale={locale}
                    theme={theme}
                    move={false}
                  />
                </CardContent>
              ) : null}
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default Home;
