import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { CreateAssetTicketOperation } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import FetchError from '@models/FetchError';
import AssetTicket from '@models/AssetTicket';

interface IState {
  name: string;
  description: string;
  cost: string;
}

interface IProps {
  close: () => void;
  createAssetTicketOperation: (properties: CreateAssetTicketOperation) => void;
  error?: FetchError;
  loading: boolean;
  ticket: AssetTicket;
}

class TicketForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      name: '',
      description: '',
      cost: '',
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { close, loading, error } = this.props;

    if (!loading && !error && prevProps.loading) {
      close();
    }
  }

  public handleChange(e: any) {
    const { name, value, type, checked } = e.target;

    this.setState({
      [name]: type === 'checkbox' ? checked : value || '',
    } as Pick<IState, 'name'>);
  }

  public handleCancel() {
    const { close } = this.props;

    close();
  }

  public handleSubmit() {
    const { createAssetTicketOperation, ticket } = this.props;
    const { name, description, cost } = this.state;

    createAssetTicketOperation({
      ticketId: ticket.id,
      name,
      description,
      cost: Number(cost),
    });
  }

  public render() {
    const { loading } = this.props;
    const { name, description, cost } = this.state;

    const error =
      name.length === 0 || description.length === 0 || (!cost && cost !== '0');
    const buttonDisabled = error;

    return (
      <Dialog open maxWidth="md" fullWidth>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.assets.management.ticket.operationform.title_create"
            defaultMessage="Register Operation"
          />
        </DialogTitle>
        <DialogContent>
          <FormControl required fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.assets.management.ticket.operationform.label_name"
                  defaultMessage="Name for operation record"
                />
              }
              name="name"
              variant="standard"
              onChange={this.handleChange}
              value={name}
              required
              helperText={
                !name && (
                  <FormattedMessage
                    id="dashboard.assets.management.ticket.operationform.field_name_helper"
                    defaultMessage="The operation must have a name"
                  />
                )
              }
            />
          </FormControl>
          <FormControl required fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.assets.management.ticket.operationform.label_description"
                  defaultMessage="Description of operation"
                />
              }
              name="description"
              variant="standard"
              onChange={this.handleChange}
              value={description}
              multiline
              rows={3}
              required
              helperText={
                !description && (
                  <FormattedMessage
                    id="dashboard.assets.management.ticket.operationform.field_description_helper"
                    defaultMessage="The operation must have a description"
                  />
                )
              }
            />
          </FormControl>
          <FormControl required fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.assets.management.ticket.operationform.label_cost"
                  defaultMessage="Cost"
                />
              }
              name="cost"
              variant="standard"
              onChange={this.handleChange}
              type="number"
              value={cost}
              required
              inputProps={{ step: 0.01 }}
              helperText={
                !description && (
                  <FormattedMessage
                    id="dashboard.assets.management.ticket.operationform.field_cost_helper"
                    defaultMessage="The operation must have a cost"
                  />
                )
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box mr={1}>
            <Button onClick={this.handleCancel}>
              <FormattedMessage
                id="dashboard.assets.management.ticket.operationform.button_cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </Box>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color="secondary"
            disabled={buttonDisabled}
            loading={loading}
          >
            <FormattedMessage
              id="dashboard.assets.management.ticket.operationform.buttom_submit"
              defaultMessage="Create"
            />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default TicketForm;
