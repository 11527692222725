import { PureComponent, ReactNode } from 'react';

interface IProps {
  errorComponent: ReactNode;
  children: ReactNode;
}

interface IState {
  error: boolean;
}

class ErrorCatcher extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      error: false,
    };
  }

  public componentDidCatch(error: Error | ErrorEvent | string, info: object) {
    console.error(error);

    if (info) {
      console.log(info);
    }

    this.setState({
      error: true,
    });
  }

  public render() {
    const { children, errorComponent } = this.props;
    const { error } = this.state;

    return error ? errorComponent : children;
  }
}

export default ErrorCatcher;
