import { range } from 'lodash';
import React, { Component } from 'react';

import Asset from '@models/Asset';
import { AssetsEventPeriods } from '@models/EventActivity';
import { Notification } from '@models/Notification';
import { Date } from '@dashboard_utils/index';
import RuleAlertsSlider from './RuleAlertsSlider';

import './index.css';

export interface IProps {
  assets: Asset[];
  eventsActivity: AssetsEventPeriods;
  min: number;
  max: number;
  onChange: (value: number) => void;
  onClick: (event: any) => void;
  ruleAlerts: Notification[];
  value: number;
  warehouseTz: string;
}

interface IState {
  dragging: boolean;
  startPosition?: number;
  startValue?: number;
  windowWidth?: number;
}

class TimeSlider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      dragging: false,
    };

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  public componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove, { passive: true });
    document.addEventListener('mouseup', this.onMouseUp, { passive: true });
    window.addEventListener('resize', this.updateWindowDimensions, {
      passive: true,
    });

    this.updateWindowDimensions();
  }

  public componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  public onMouseDown(event: any) {
    const { value } = this.props;

    event.stopPropagation();

    this.setState({
      dragging: true,
      startPosition: event.pageX,
      startValue: value,
    });
  }

  public onMouseUp(event: any) {
    event.stopPropagation();

    this.setState({ dragging: false });
  }

  /**
   * @description Moves player indicator into the dragged position
   * @param       {any} event Mouse event
   */
  public onMouseMove(event: any) {
    const { dragging, startPosition, startValue } = this.state;
    const { onChange, min, max } = this.props;

    if (!dragging) {
      return;
    }

    event.stopPropagation();

    // Calculates movement size and gets relative movement to the parentw width
    const moved = event.pageX - (startPosition || 0);
    const parent: any = document.getElementsByClassName(
      'event-timeslider-container'
    )[0];
    const relativeMovement = (moved * 100) / parent.clientWidth;

    // Forces event representation elements to fit determinated viewer line
    // Start value has to be 0
    let newValue =
      (startValue || 0) + Math.ceil(((max - min) * relativeMovement) / 100);
    if (newValue < min) {
      newValue = min;
    }
    // End value has to be eql to max ts of the view
    if (newValue > max) {
      newValue = max;
    }
    onChange(newValue);
  }

  public updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }

  public render() {
    const { onClick, min, max, value, ruleAlerts, warehouseTz } = this.props;
    const { windowWidth } = this.state;

    const intervals = windowWidth
      ? Math.min(Math.floor(windowWidth / 100), 10)
      : 10;

    const unassignedAlerts = ruleAlerts.filter((ra) => !ra.messageInfo.assets);

    return (
      <>
        <div className="events-slider-container">
          {unassignedAlerts.length !== 0 && (
            <RuleAlertsSlider
              min={min}
              max={max}
              onClick={onClick}
              value={value}
              ruleAlerts={unassignedAlerts}
            />
          )}
          <div
            style={{
              left: `${((value - min) * 100) / (max - min)}%`,
            }}
            className="timeslider-pointer"
          />
          <div className="event-timeslider-container">
            <div
              className="event-timeslider"
              role="button"
              aria-label=" "
              tabIndex={0}
              onClick={onClick}
              onKeyDown={onClick}
            />
            <div
              style={{ left: `${((value - min) * 100) / (max - min)}%` }}
              className="event-slider-handle"
              role="button"
              aria-label=" "
              tabIndex={0}
              onMouseDown={this.onMouseDown}
            />

            {range(intervals).map((i) => {
              const intervalTs = min + ((max - min) / intervals) * i;
              const dString = new Date(warehouseTz, intervalTs).format(
                new Date(warehouseTz, intervalTs).format('yyyy-MM-dd') !==
                  new Date(
                    warehouseTz,
                    intervalTs - (max - min) / intervals,
                    'Etc/UTC'
                  ).format('yyyy-MM-dd')
                  ? 'yyyy-MM-dd HH:mm'
                  : 'HH:mm'
              );

              // to center, removes 5px per character, could be improved
              return (
                i !== 0 && (
                  <div
                    key={i}
                    className="scale"
                    style={{
                      left: `calc(${(i * 100) / intervals}% - ${
                        Math.ceil(dString.length / 2) * 6
                      }px)`,
                    }}
                  >
                    {dString.split(' ').length > 1 ? (
                      <span>
                        <b>{dString.split(' ')[0]}</b>
                        <span> </span>
                        <span>{dString.split(' ')[1]}</span>
                      </span>
                    ) : (
                      dString
                    )}
                  </div>
                )
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default TimeSlider;
