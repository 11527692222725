import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import {
  confirmDialog,
  deleteEmployee,
  openEmployeeForm,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { Employee } from '@models/Employee';
import EmployeesTab from './Employees';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { withRouter } from '@app/utils/withRouter';
import { getActiveLanguage } from '@selectors/app';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account!,
  warehouses: state.warehouses.warehouses,
  data: Object.values(state.employees.employees),
  language: getActiveLanguage(state),
  loading: state.employees.loading,
  employeeFormData: state.employees.form.data,
  employeeFormOpen: state.employees.form.active,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  deleteEmployee: (id: string, intl: IntlShape) =>
    dispatch(deleteEmployee.request({ id, intl })),
  openForm: (employee?: Employee) => dispatch(openEmployeeForm(employee)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployeesTab)
);
