import React from 'react';
import { FormattedMessage } from 'react-intl';

export function getZoneTypeName(type: string) {
  switch (type) {
    case 'racks':
      return (
        <FormattedMessage
          id="dashboard.forms.zoneform.field_type_rackszone"
          defaultMessage="Racks Zone"
        />
      );
    case 'ground_pallets':
      return (
        <FormattedMessage
          id="dashboard.forms.zoneform.field_type_groundpallets"
          defaultMessage="Ground Pallets Zone"
        />
      );
    case 'charging':
      return (
        <FormattedMessage
          id="dashboard.forms.zoneform.field_type_charging"
          defaultMessage="Charging Zone"
        />
      );
    case 'loading':
      return (
        <FormattedMessage
          id="dashboard.forms.zoneform.field_type_loading"
          defaultMessage="Loading Bays"
        />
      );
    case 'entry_exit':
      return (
        <FormattedMessage
          id="dashboard.forms.zoneform.field_type_entryexit"
          defaultMessage="Entry/Exit"
        />
      );
    case 'entry':
      return (
        <FormattedMessage
          id="dashboard.forms.zoneform.field_type_entry"
          defaultMessage="Entry Only Zone"
        />
      );
    case 'exit':
      return (
        <FormattedMessage
          id="dashboard.forms.zoneform.field_type_exit"
          defaultMessage="Exit Only Zone"
        />
      );
    case 'mixing':
      return (
        <FormattedMessage
          id="dashboard.forms.zoneform.field_type_mixing"
          defaultMessage="Mixing Zone"
        />
      );
    case 'other':
      return (
        <FormattedMessage
          id="dashboard.forms.zoneform.field_type_other"
          defaultMessage="Any/Other"
        />
      );
    default:
      return <span>NA</span>;
  }
}

export default {};
