import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import React, { FunctionComponent } from 'react';

import { Assets } from '@models/Asset';

export interface IProps {
  assetId?: string;
  assets: Assets;
  closeAssetSelector: () => void;
  selectAsset: (event: any) => void;
}

const AssetSelector: FunctionComponent<IProps> = (properties: IProps) => {
  const { assetId, assets, closeAssetSelector, selectAsset } = properties;

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={closeAssetSelector}>
      <DialogTitle>Select an Asset</DialogTitle>
      <DialogContent>
        {Object.values(assets || {}).length ? (
          <FormControl required fullWidth variant="standard" margin="normal">
            <Select
              label="Select an Asset"
              value={assetId || ''}
              onChange={selectAsset}
            >
              {Object.values(assets || {}).map(
                (asset) => (
                  <MenuItem key={asset.id} value={asset.id}>
                    {asset.name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        ) : (
          <Alert severity="warning">No assets found</Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AssetSelector;
