import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import LoadingButton from '@app/common/LoadingButton';
import { defaultTransformationMatrix } from '@app/common/FullMap/consts';
import IncompleteFloorplan, { IGeoMapping } from '@models/IncompleteFloorplan';
import { TransformationMatrix2D } from '../../../utils';
import GeoAlignmentForm from '../../forms/FloorplanConfigurationForm/GeoAlignment/GeoAlignmentForm';

interface IProps {
  floorplan: IncompleteFloorplan;
  loading: boolean;
  onBack: () => void;
  onNext: (
    geoMapping: IGeoMapping[],
    intl: IntlShape,
    transformationMatrix: TransformationMatrix2D
  ) => void;
  onSkip: (intl: IntlShape) => void;
  intl: IntlShape;
}

interface IState {
  geoMapping: IGeoMapping[];
}

class GeoAlignment extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      geoMapping: [],
    };

    this.mappingChange = this.mappingChange.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onSkip = this.onSkip.bind(this);
  }

  public onSkip() {
    const { intl, onSkip } = this.props;

    onSkip(intl);
  }

  public onNext() {
    const { geoMapping } = this.state;
    const { floorplan, intl, onNext } = this.props;

    onNext(
      geoMapping,
      intl,
      floorplan.transformationMatrix || defaultTransformationMatrix
    );
  }

  public mappingChange(geoMapping: IGeoMapping[]) {
    this.setState({ geoMapping });
  }

  public render() {
    const { floorplan, onBack, loading } = this.props;
    const { geoMapping } = this.state;

    return (
      <Card id="alignment-setup" className="step-card">
        <CardContent style={{ flex: 1, position: 'relative' }}>
          <GeoAlignmentForm
            mappingChange={this.mappingChange}
            floorplanId={floorplan.id}
          />
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Box mr={1}>
            <Button onClick={onBack}>
              <FormattedMessage
                id="dashboard.setup.steps.button_back"
                defaultMessage="Back"
              />
            </Button>
          </Box>
          <Box mr={1}>
            <Button onClick={this.onSkip}>
              <FormattedMessage
                id="dashboard.setup.steps.button_skip"
                defaultMessage="Skip"
              />
            </Button>
          </Box>
          <Box mr={1}>
            <LoadingButton
              variant="contained"
              onClick={this.onNext}
              color="primary"
              loading={loading}
              disabled={geoMapping.length === 2}
            >
              <FormattedMessage
                id="dashboard.setup.steps.button_next"
                defaultMessage="Next"
              />
            </LoadingButton>
          </Box>
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(GeoAlignment);
