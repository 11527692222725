import Grid from '@mui/material/Grid';
import React, { FunctionComponent } from 'react';

import Filters from '@app/common/Filters';
import { IFilter } from '@reducers/filter';
import { cellSize } from '../../../utils';
import SimulationsTab from './SimulationsTabContainer';

interface IProps {
  filters: Record<string, IFilter>;
}

const SimulationsTabGrid: FunctionComponent<IProps> = ({ filters }: IProps) => {
  const filterKeys = Object.keys(filters).filter(
    (k) => k !== 'simulator' && k !== 'popup'
  );

  return (
    <Grid container spacing={2}>
      {filterKeys.map((filterId, index) => (
        <Grid key={filterId} item xs={cellSize(filterKeys, index)}>
          <Filters filterId={filterId}>
            <SimulationsTab filterId={filterId} />
          </Filters>
        </Grid>
      ))}
    </Grid>
  );
};

export default SimulationsTabGrid;
