import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchAssetTickets } from '@actions/assets';
import { withRouter } from '@app/utils/withRouter';
import { getActiveLanguage } from '@selectors/app';
import { IAppState } from '@reducers/index';
import History from './History';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  loading: state.assets.tickets.loading,
  tickets: Object.values(state.assets.tickets.tickets).filter(
    (ticket) => ticket.archived === true
  ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAssetTickets: (assetId: string) =>
    dispatch(fetchAssetTickets.request({ assetId })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(History)
);
