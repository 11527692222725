import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { IFetchRuleAlerts } from '@actions/rules';
import Map from '@app/common/FullMap';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from '@app/utils/localStorageUtils';
import AlertsTable from './AlertsTable';

interface IProps {
  filterId: string;

  floorplanId: string;
  ruleIds: string[];
  templateIds: string[];
  warehouseId: string;

  endDate?: Date;
  startDate?: Date;

  filterUpdated: boolean;

  locale: string;
  measurementUnits: string;

  loading: boolean;
  fetchRuleAlerts: (properties: IFetchRuleAlerts) => void;

  intl: IntlShape;
}

interface IState {
  tab: number;
}

const messages = {
  tabMaps: {
    defaultMessage: 'Maps',
    id: 'dashboard.plots.tab.maps',
  },
  tabTables: {
    defaultMessage: 'Tables',
    id: 'dashboard.plots.tab.tables',
  },
};

class AlertsTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { filterId } = props;

    const tab = Number(
      getFromLocalStorage(`alerts_selected_tab_${filterId}`) || 0
    );

    this.state = {
      tab,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
  }

  public componentDidMount() {
    const { endDate, startDate } = this.props;

    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    if (
      (endDate ? endDate.getTime() : 0) !==
      (startDate ? startDate.getTime() : 0)
    ) {
      this.fetchData();
    }
  }

  public componentDidUpdate(prevProps: any) {
    const {
      fetchRuleAlerts,
      locale,
      measurementUnits,

      floorplanId,
      ruleIds,
      templateIds,
      warehouseId,

      filterId,

      endDate,
      startDate,

      filterUpdated,
    } = this.props;

    // fetches the new data range if (wh, fp) changes
    if (
      filterUpdated &&
      (prevProps.warehouseId !== warehouseId ||
        prevProps.floorplanId !== floorplanId ||
        JSON.stringify(prevProps.ruleIds || []) !==
          JSON.stringify(ruleIds || []) ||
        JSON.stringify(prevProps.templateIds || []) !==
          JSON.stringify(templateIds || []) ||
        (endDate &&
          (prevProps.endDate ? prevProps.endDate.getTime() : 0) !==
            endDate.getTime()) ||
        (startDate &&
          (prevProps.startDate ? prevProps.startDate.getTime() : 0) !==
            startDate.getTime()))
    ) {
      fetchRuleAlerts({
        filterId,
        floorplanId,
        locale,
        units: measurementUnits,
        endTs: endDate ? endDate!.getTime() : undefined,
        startTs: startDate ? startDate!.getTime() : undefined,
        ruleIds,
        templateIds,
      });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  private handleTabChange(_: any, tab: number) {
    const { filterId } = this.props;

    this.setState({ tab }, () => {
      saveToLocalStorage(`alerts_selected_tab_${filterId}`, String(tab));
    });
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const {
      fetchRuleAlerts,
      filterId,
      locale,
      measurementUnits,

      startDate,
      endDate,

      floorplanId,
      ruleIds,
      templateIds,
    } = this.props;

    fetchRuleAlerts({
      filterId,
      floorplanId,
      locale,
      endTs: endDate ? endDate!.getTime() : undefined,
      startTs: startDate ? startDate!.getTime() : undefined,
      ruleIds,
      templateIds,
      units: measurementUnits,
      shallow,
    });
  }

  public render() {
    const { intl, filterId, loading } = this.props;

    const { tab } = this.state;

    return (
      !loading && (
        <>
          <Tabs
            centered
            indicatorColor="primary"
            onChange={this.handleTabChange}
            value={tab}
            variant="fullWidth"
            textColor="primary"
          >
            <Tab label={intl.formatMessage(messages.tabTables)} />
            <Tab label={intl.formatMessage(messages.tabMaps)} />
          </Tabs>
          {tab === 0 ? (
            <AlertsTable filterId={filterId} />
          ) : (
            <Map
              filterId={filterId}
              layers={{
                events: {
                  id: 'events',
                  active: true,
                  label: 'Events',
                },
              }}
              actions={{
                hover: {},
                select: {},
              }}
            />
          )}
        </>
      )
    );
  }
}

export default injectIntl(AlertsTab);
