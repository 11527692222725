import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { IUpdateFloorplan } from '@actions/floorplans';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import LoadingButton from '@app/common/LoadingButton';

export interface IProps {
  floorplan: IncompleteFloorplan;
  loading: boolean;
  intl: IntlShape;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
}
interface IState {
  anchorType?: 'wired' | 'battery';
  tracking?: 'indoor' | 'outdoor';
  connectivity?: 'wifi' | 'cellular';
  targetNumberOfForklifts?: number;
  floorplanArea?: number;
  aisleLength?: number;
  numberOfAisles?: number;
  numberOfOpenAreas?: number;
  openAreasSpace?: number[];
}

class FinancialsSettings extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { floorplan } = props;

    this.state = {
      anchorType: floorplan.anchorType,
      tracking: floorplan.tracking,
      connectivity: floorplan.connectivity,
      targetNumberOfForklifts: floorplan.targetNumberOfForklifts,
      floorplanArea: floorplan.floorplanArea,
      aisleLength: floorplan.aisleLength,
      numberOfAisles: floorplan.numberOfAisles,
      numberOfOpenAreas: (floorplan.openAreasSpace || []).length,
      openAreasSpace: floorplan.openAreasSpace,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value, type } = e.target;
      const { openAreasSpace } = this.state;

      this.setState({
        [key]: type === 'number' && value !== '' ? Number(value) : value,
        openAreasSpace:
          key === 'numberOfOpenAreas'
            ? Array(Number(value)).fill(500)
            : openAreasSpace,
      } as Pick<IState, 'anchorType'>);
    };
  }

  public handleSubmit() {
    const {
      anchorType,
      tracking,
      connectivity,
      targetNumberOfForklifts,
      floorplanArea,
      aisleLength,
      numberOfAisles,
      openAreasSpace,
    } = this.state;
    const { floorplan, intl, updateFloorplan } = this.props;

    updateFloorplan({
      floorplanId: floorplan.id,
      intl,
      properties: {
        anchorType,
        tracking,
        connectivity,
        targetNumberOfForklifts,
        floorplanArea,
        aisleLength,
        numberOfAisles,
        openAreasSpace,
      },
      warehouseId: floorplan.warehouseId,
    });
  }

  public handleOpenAreaChange(index: number) {
    return (e: any) => {
      const { openAreasSpace } = this.state;
      const { value } = e.target;

      (openAreasSpace || [])[index] = value;

      this.setState({ openAreasSpace });
    };
  }

  public render() {
    const {
      anchorType,
      tracking,
      connectivity,
      targetNumberOfForklifts,
      floorplanArea,
      aisleLength,
      numberOfAisles,
      numberOfOpenAreas,
      openAreasSpace,
    } = this.state;
    const { loading } = this.props;

    return (
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Box mt={8}>
              <Typography variant="body2">Installation type</Typography>
              <Divider />
              <FormControl
                required
                fullWidth
                variant="standard"
                margin="normal"
              >
                <InputLabel id="form-anchortype">Anchor Type</InputLabel>
                <Select
                  labelId="form-anchortype"
                  value={anchorType}
                  label="Anchor Type"
                  onChange={this.handleChange('anchorType')}
                >
                  <MenuItem value="wired">Wired</MenuItem>
                  <MenuItem value="battery">Battery</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                required
                fullWidth
                variant="standard"
                margin="normal"
              >
                <InputLabel id="form-tracking">Tracking</InputLabel>
                <Select
                  labelId="form-tracking"
                  value={tracking}
                  label="Tracking"
                  onChange={this.handleChange('tracking')}
                >
                  <MenuItem value="indoor">Indoor</MenuItem>
                  <MenuItem value="outdoor">Outdoor</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                required
                fullWidth
                variant="standard"
                margin="normal"
              >
                <InputLabel id="form-connectivity">Connectivity</InputLabel>
                <Select
                  labelId="form-connectivity"
                  value={connectivity}
                  label="Connectivity"
                  onChange={this.handleChange('connectivity')}
                >
                  <MenuItem value="wifi">Wifi</MenuItem>
                  <MenuItem value="cellular">Cellular</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box mt={8}>
              <Typography variant="body2">Installation details</Typography>
              <Divider />
              <FormControl required fullWidth margin="normal">
                <TextField
                  label="Target number of forklifts"
                  value={targetNumberOfForklifts}
                  variant="standard"
                  type="number"
                  inputProps={{ min: 1 }}
                  onChange={this.handleChange('targetNumberOfForklifts')}
                />
              </FormControl>
              <FormControl required fullWidth margin="normal">
                <TextField
                  label="Total floorplan area"
                  value={floorplanArea}
                  variant="standard"
                  type="number"
                  inputProps={{ min: 0, max: 5000000 }}
                  onChange={this.handleChange('floorplanArea')}
                />
              </FormControl>
              <FormControl required fullWidth margin="normal">
                <TextField
                  label="Length of warehouse aisle"
                  value={aisleLength}
                  variant="standard"
                  type="number"
                  inputProps={{ min: 0, max: 5000 }}
                  onChange={this.handleChange('aisleLength')}
                />
              </FormControl>
              <FormControl required fullWidth margin="normal">
                <TextField
                  label="Number of warehouse aisles"
                  value={numberOfAisles}
                  variant="standard"
                  type="number"
                  inputProps={{ min: 0, max: 5000 }}
                  onChange={this.handleChange('numberOfAisles')}
                />
              </FormControl>
              <FormControl required fullWidth margin="normal">
                <TextField
                  label="Number of open areas (to track)"
                  value={numberOfOpenAreas}
                  variant="standard"
                  type="number"
                  inputProps={{ min: 0, max: 20 }}
                  onChange={this.handleChange('numberOfOpenAreas')}
                />
              </FormControl>
              {(openAreasSpace || []).map((a, index) => (
                <FormControl key={index} required fullWidth margin="normal">
                  <TextField
                    label={`Space of Open Area #${index}`}
                    value={a}
                    variant="standard"
                    type="number"
                    inputProps={{ min: 0 }}
                    onChange={this.handleOpenAreaChange(index)}
                  />
                </FormControl>
              ))}
            </Box>
          </Grid>
        </Grid>
        <CardActions style={{ flexDirection: 'row-reverse' }}>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={this.handleSubmit}
          >
            Submit
          </LoadingButton>
        </CardActions>
      </Container>
    );
  }
}

export default injectIntl(FinancialsSettings);
