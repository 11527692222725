import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';
import React, { Component } from 'react';

import { ICreateAsset, IUpdateAsset } from '@actions/index';
import CustomTableCell from '@app/common/mui-custom/CustomTableCell';
import LoadingButton from '@app/common/LoadingButton';
import ColorPicker, {
  longHexRegex,
  shortHexRegex,
} from '@app/common/MaterialUIColorPicker/ColorPicker';
import { PRIMARY_COLOR } from '@app/utils/colors';
import { Date, getRandomColor, timeZone } from '@dashboard_utils/index';
import Asset, { AssetTypes } from '@models/Asset';
import { IncompleteSensorAssociation } from '@models/IncompleteSensorAssociation';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import Sensor from '@models/Sensor';
import { Warehouses } from '@models/Warehouse';
import {
  areAssetsSensorAssociationsDuplicated,
  isAssetNameUnique,
  isAssetSensorAssociationDuplicated,
} from '@selectors/assets';
import {
  areAssociationsOverlapped,
  isAssociationOverlapped,
} from '@selectors/utils';
import { findWarehouseByFloorplanId } from '../../utils/floorplanUtils';
import { getAssetTypeName } from './utils';

const messages = defineMessages({
  altText: {
    defaultMessage: 'Asset image',
    id: 'dashboard.forms.assetform.img_alt',
  },
  colorErrorMsg: {
    defaultMessage: 'A color must follow the #rrggbb format',
    id: 'dashboard.forms.assetform.field_color_error',
  },
  colorLabel: {
    defaultMessage: 'Color',
    id: 'dashboard.forms.assetform.label_color',
  },
  tagsPlaceholder: {
    defaultMessage: 'Type and hit enter',
    id: 'dashboard.forms.assetform.field_tags',
  },
  typesForklift: {
    defaultMessage: 'Forklift',
    id: 'dashboard.forms.assetform.field_type_forklift',
  },
});

const cleanSensorAssociations = (
  sensorAssociations: IncompleteSensorAssociation[]
) => [
  ...sensorAssociations.map((sa) => ({
    ...sa,
    sensorPhysicalAddress: undefined,
  })),
];

const handleFileUploadClick = (event: any) => {
  event.stopPropagation();

  document.getElementById('form-image')!.click();
};

const mergePhysicalAddresses = (sensors: Sensor[]) => {
  const physicalAddresses: string[] = [];

  sensors.forEach((s) => {
    if (physicalAddresses.indexOf(s.physicalAddress) === -1) {
      physicalAddresses.push(s.physicalAddress);
    }
  });

  return physicalAddresses.sort((a, b) => a.localeCompare(b));
};

export interface IState {
  color: string;
  id?: string;
  image?: string;
  file?: File;
  name: string;
  tag: string;
  tags: string[];
  type: string;
  warehouseId: string;
  floorplanId: string;
  sensorAssociations: IncompleteSensorAssociation[];
}

interface IProps {
  assets: Asset[];
  closeAssetForm: () => void;
  createAsset: (properties: ICreateAsset) => void;
  updateAsset: (properties: IUpdateAsset) => void;
  fetchSensors: (floorplanId: string) => void;
  floorplans: IncompleteFloorplans;
  data?: Asset;
  intl: IntlShape;
  loading: boolean;
  warehouses: Warehouses;
  sensors: Record<string, Sensor[]>;
}

class AssetsForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { data } = props;

    let warehouse;
    if (data !== undefined) {
      warehouse = findWarehouseByFloorplanId(
        props.warehouses,
        props.floorplans,
        data.floorplanId
      );
    }

    const warehouseId = warehouse !== undefined ? warehouse.id : '';

    this.state = {
      color: data && data.color ? data.color : getRandomColor(),
      floorplanId: data !== undefined ? data.floorplanId : '',
      id: data !== undefined ? data.id : undefined,
      image: data !== undefined ? data.image : undefined,
      name: data !== undefined ? data.name : '',
      sensorAssociations:
        data !== undefined
          ? (data.sensorAssociations as IncompleteSensorAssociation[])
          : [],
      tag: '',
      tags: data !== undefined ? data.tags : [],
      type: data !== undefined ? data.type : 'forklift',
      warehouseId,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTag = this.handleTag.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleImageRemoval = this.handleImageRemoval.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleAddSensorAssociation =
      this.handleAddSensorAssociation.bind(this);
    this.handleSensorAssociationChange =
      this.handleSensorAssociationChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    const { floorplanId } = this.state;
    const { fetchSensors } = this.props;

    if (floorplanId) {
      fetchSensors(floorplanId);
    }
  }

  public handleTag(e: any) {
    const { value } = e.target;

    if (e.which === 13 || e.which === 9) {
      const { tags } = this.state;

      tags.push(value);

      return this.setState({ tags, tag: '' });
    }
    return null;
  }

  public handleFileUpload(event: any) {
    const { files } = event.target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (e.target !== null) {
          this.setState({ image: e.target.result });
        }
      };

      reader.readAsDataURL(files[0]);

      this.setState({
        file: files[0],
      });
    }
  }

  public handleImageRemoval() {
    this.setState({ image: undefined });
  }

  public handleColorChange(color: string) {
    this.setState({
      color,
    });
  }

  public handleAddSensorAssociation() {
    const { sensorAssociations } = this.state;

    sensorAssociations.push({ from: Date.now() });

    this.setState({ sensorAssociations });
  }

  public handleRemoveSensorAssociation(index: number) {
    const { sensorAssociations } = this.state;

    sensorAssociations.splice(index, 1);

    this.setState({ sensorAssociations });
  }

  public handleSensorAssociationChange(
    key: keyof IncompleteSensorAssociation,
    index: number
  ) {
    return (e: any) => {
      const { value } = e.target;
      const { sensorAssociations } = this.state;

      // @ts-ignore
      sensorAssociations[index][key] = value;

      this.setState({ sensorAssociations });
    };
  }

  public handleSensorAssociationDateChange(
    key: keyof IncompleteSensorAssociation,
    index: number,
    date: Date
  ) {
    const { warehouses } = this.props;
    const { sensorAssociations, warehouseId } = this.state;

    const warehouseTz = warehouses[warehouseId]
      ? warehouses[warehouseId].timezone
      : timeZone;

    // On keystroke date can be incomplete, update only when valid
    if (date) {
      // KeyboardDatepicker returns a string when edited by keyboard
      // that string has to be read into the target timezone and converted to ts
      // @ts-ignore
      sensorAssociations[index][key] =
        date !== null
          ? new Date(warehouseTz, date, warehouseTz).getTime()
          : undefined;

      this.setState({ sensorAssociations });
    }
  }

  public handleCancel() {
    const { closeAssetForm } = this.props;

    closeAssetForm();
  }

  public handleSubmit() {
    const {
      color,
      floorplanId,
      id,
      name,
      file,
      image,
      sensorAssociations,
      tags,
      type,
    } = this.state;

    const { createAsset, updateAsset, intl } = this.props;

    if (id !== undefined) {
      updateAsset({
        color,
        file,
        floorplanId,
        id,
        image,
        intl,
        name,
        sensorAssociations: cleanSensorAssociations(
          sensorAssociations as IncompleteSensorAssociation[]
        ),
        tags,
        type,
      });
    } else {
      createAsset({
        color,
        file,
        floorplanId,
        intl,
        name,
        sensorAssociations: cleanSensorAssociations(
          sensorAssociations as IncompleteSensorAssociation[]
        ),
        tags,
        type,
      });
    }
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState(
        {
          [key]: value || '',
        } as Pick<IState, 'name'>,
        () => {
          if (key === 'floorplanId') {
            const { fetchSensors } = this.props;
            const { floorplanId } = this.state;

            fetchSensors(floorplanId);
          }
        }
      );
    };
  }

  public handleDelete(index: number) {
    const { tags } = this.state;

    tags.splice(index, 1);

    this.setState({ tags });
  }

  public getFloorplanSensors(floorplanId: string) {
    const { sensors } = this.props;

    return sensors && sensors[floorplanId] ? sensors[floorplanId] : [];
  }

  public render() {
    const { assets, floorplans, loading, intl, warehouses } = this.props;
    const {
      color,
      floorplanId,
      id,
      image,
      name,
      tag,
      tags,
      type,
      warehouseId,
      sensorAssociations,
    } = this.state;

    const warehouseTz = warehouses[warehouseId]
      ? warehouses[warehouseId].timezone
      : timeZone;

    const uniqueName = isAssetNameUnique(assets, name, id);
    const colorError = !(shortHexRegex.test(color) || longHexRegex.test(color));
    const error =
      name.length === 0 ||
      uniqueName === false ||
      type.length === 0 ||
      warehouseId.length === 0 ||
      floorplanId.length === 0 ||
      sensorAssociations.find(
        (sa: any) =>
          sa.from === undefined ||
          sa.from === null ||
          sa.physicalAddress === undefined ||
          sa.physicalAddress === null
      ) !== undefined ||
      areAssociationsOverlapped(sensorAssociations) ||
      areAssetsSensorAssociationsDuplicated(
        assets.filter((a: any) => a.floorplanId === floorplanId),
        id || '',
        sensorAssociations
      ) ||
      colorError;

    const buttonDisabled = error;

    const whs = warehouses ? Object.values(warehouses) : [];
    const fps = Object.values(floorplans).filter(
      (fp: any) => fp.warehouseId === warehouseId
    );

    const physicalAddresses = mergePhysicalAddresses(
      this.getFloorplanSensors(floorplanId)
    );

    return (
      <Dialog open fullWidth maxWidth="md">
        <DialogTitle>
          {id ? (
            <FormattedMessage
              id="dashboard.forms.assetform.title_edit"
              defaultMessage="Edit Asset {name}"
              values={{ name }}
            />
          ) : (
            <FormattedMessage
              id="dashboard.forms.assetform.title_create"
              defaultMessage="Add Asset"
            />
          )}
        </DialogTitle>
        <DialogContent>
          {image ? (
            <div style={{ position: 'relative' }}>
              <ClearIcon
                color="error"
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '160px',
                  top: '10px',
                }}
                onClick={this.handleImageRemoval}
              />
              <Avatar
                alt={intl.formatMessage(messages.altText)}
                src={image}
                style={{ width: 200, height: 200, margin: 'auto' }}
              />
            </div>
          ) : (
            <>
              <div style={{ position: 'relative' }}>
                <label htmlFor="form-image">
                  <Button
                    style={{ width: '100%', height: '250px' }}
                    variant="outlined"
                    color="primary"
                    onClick={handleFileUploadClick}
                  >
                    <FormattedMessage
                      id="dashboard.forms.assetform.upload_button"
                      defaultMessage="Upload image"
                    />
                  </Button>
                  <input
                    id="form-image"
                    accept="image/*"
                    type="file"
                    style={{ display: 'none' }}
                    onInput={this.handleFileUpload}
                  />
                </label>
              </div>
            </>
          )}
          <FormControl required fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.forms.assetform.label_name"
                  defaultMessage="Name"
                />
              }
              variant="standard"
              value={name}
              onChange={this.handleChange('name')}
              helperText={
                uniqueName === false ? (
                  <FormHelperText error>
                    <FormattedMessage
                      id="dashboard.forms.assetform.field_name_unique_error"
                      defaultMessage={
                        "The name has to be unique. Can't have multiple assets with the same name"
                      }
                    />
                  </FormHelperText>
                ) : (
                  <FormHelperText>
                    <FormattedMessage
                      id="dashboard.forms.assetform.field_name_helper"
                      defaultMessage="A name must be specified"
                    />
                  </FormHelperText>
                )
              }
            />
          </FormControl>
          <FormControl required fullWidth variant="standard" margin="normal">
            <InputLabel>
              <FormattedMessage
                id="dashboard.forms.assetform.label_type"
                defaultMessage="Type"
              />
            </InputLabel>
            <Select value={type} onChange={this.handleChange('type')}>
              {Object.keys(AssetTypes).map((key: string) => (
                <MenuItem
                  key={key}
                  value={AssetTypes[key as keyof typeof AssetTypes]}
                >
                  {getAssetTypeName(AssetTypes[key as keyof typeof AssetTypes])}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ColorPicker
            required
            value={color}
            onChange={this.handleColorChange}
            label={intl.formatMessage(messages.colorLabel)}
            error={colorError}
            errorMessage={intl.formatMessage(messages.colorErrorMsg)}
          />
          <FormControl required fullWidth variant="standard" margin="normal">
            <InputLabel>
              <FormattedMessage
                id="dashboard.forms.assetform.label_warehouse"
                defaultMessage="Warehouse"
              />
            </InputLabel>
            <Select
              value={warehouseId}
              onChange={this.handleChange('warehouseId')}
            >
              {warehouses &&
                whs.map((w) => (
                  <MenuItem key={w.id} value={w.id}>
                    {w.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl required fullWidth variant="standard" margin="normal">
            <InputLabel>
              <FormattedMessage
                id="dashboard.forms.assetform.label_floorplan"
                defaultMessage="Floor plan"
              />
            </InputLabel>
            <Select
              value={floorplanId}
              onChange={this.handleChange('floorplanId')}
            >
              {warehouses &&
                fps.map((fp) => (
                  <MenuItem key={fp.id} value={fp.id}>
                    {fp.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.forms.assetform.label_tags"
                  defaultMessage="Labels"
                />
              }
              variant="standard"
              value={tag}
              onChange={this.handleChange('tag')}
              onKeyPress={this.handleTag}
              placeholder={intl.formatMessage(messages.tagsPlaceholder)}
            />
            <div style={{ paddingTop: '15px' }}>
              {tags.map((t, index) => (
                <Chip
                  key={index}
                  style={{
                    backgroundColor: color || PRIMARY_COLOR,
                    color: 'white',
                  }}
                  size="small"
                  label={t}
                  onDelete={() => this.handleDelete(index)}
                />
              ))}
            </div>
          </FormControl>
          <Typography
            variant="subtitle1"
            style={{
              borderBottom: '1px solid #CCC',
              marginBottom: '10px',
              marginTop: '30px',
            }}
          >
            <FormattedMessage
              id="dashboard.forms.assetform.sensorassociations.title"
              defaultMessage="Sensor Associations"
            />
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell>
                  <FormattedMessage
                    id="dashboard.forms.assetform.sensorassociations.from"
                    defaultMessage="From"
                  />
                </CustomTableCell>
                <CustomTableCell>
                  <FormattedMessage
                    id="dashboard.forms.assetform.sensorassociations.to"
                    defaultMessage="To"
                  />
                </CustomTableCell>
                <CustomTableCell>
                  <FormattedMessage
                    id="dashboard.forms.assetform.sensorassociations.sensor"
                    defaultMessage="Sensor"
                  />
                </CustomTableCell>
                <CustomTableCell />
              </TableRow>
            </TableHead>
            {sensorAssociations.map((sa, index) => {
              const isDuplicated = isAssetSensorAssociationDuplicated(
                assets,
                index,
                id || '',
                floorplanId || '',
                sensorAssociations
              );
              const isOverlapped = isAssociationOverlapped(
                index,
                sensorAssociations
              );

              return (
                <TableBody key={index}>
                  <TableRow hover={false}>
                    <CustomTableCell>
                      <FormControl
                        required
                        error={isOverlapped === true || isDuplicated === true}
                      >
                        <DateTimePicker
                          value={new Date(
                            warehouseTz,
                            sa.from || new Date(warehouseTz).getTime()
                          )}
                          maxDate={
                            new Date(
                              warehouseTz,
                              new Date(
                                warehouseTz,
                                sa.to || new Date(warehouseTz)
                              )
                            ) as any
                          }
                          onChange={(date: any) => {
                            this.handleSensorAssociationDateChange(
                              'from',
                              index,
                              date
                            );
                          }}
                          slotProps={{
                            textField: { variant: 'standard' }
                          }}
                          ampm={false}
                        />
                      </FormControl>
                    </CustomTableCell>
                    <CustomTableCell>
                      <FormControl
                        required
                        error={
                          (isOverlapped === true || isDuplicated === true) &&
                          sa.to !== undefined
                        }
                      >
                        <DateTimePicker
                          value={
                            sa.to ? new Date(warehouseTz, sa.to) : null
                          }
                          minDate={
                            new Date(
                              warehouseTz,
                              sa.from || new Date(warehouseTz)
                            ) as any
                          }
                          onChange={(date: any) => {
                            this.handleSensorAssociationDateChange(
                              'to',
                              index,
                              date
                            );
                          }}
                          slotProps={{
                            textField: { variant: 'standard' }
                          }}
                          ampm={false}
                        />
                      </FormControl>
                    </CustomTableCell>
                    <CustomTableCell>
                      <FormControl fullWidth variant="standard" margin="dense">
                        <Select
                          value={sa.physicalAddress || ''}
                          required
                          onChange={this.handleSensorAssociationChange(
                            'physicalAddress',
                            index
                          )}
                          error={isOverlapped === true || isDuplicated === true}
                        >
                          <MenuItem />
                          {physicalAddresses.map((physicalAddress: string) => (
                            <MenuItem
                              key={physicalAddress}
                              value={physicalAddress}
                            >
                              {physicalAddress}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </CustomTableCell>
                    <CustomTableCell
                      style={{ textAlign: 'right', paddingRight: '3px' }}
                    >
                      <RemoveIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          this.handleRemoveSensorAssociation(index)
                        }
                      />
                    </CustomTableCell>
                  </TableRow>
                  {isOverlapped || isDuplicated ? (
                    <TableRow hover={false}>
                      <TableCell colSpan={3}>
                        <FormHelperText error>
                          {isOverlapped ? (
                            <FormattedMessage
                              id="dashboard.forms.assetform.sensorassociations.overlap_error"
                              defaultMessage="This sensor association entry overlaps"
                            />
                          ) : (
                            <FormattedMessage
                              id="dashboard.forms.assetform.sensorassociations.overlapingassociation_error"
                              defaultMessage="There are other assets with an overlapping sensor association"
                            />
                          )}
                        </FormHelperText>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              );
            })}
            <TableBody>
              <TableRow hover={false}>
                <CustomTableCell
                  style={{ textAlign: 'right', paddingRight: '3px' }}
                  colSpan={4}
                >
                  <AddIcon
                    style={{ cursor: 'pointer' }}
                    onClick={this.handleAddSensorAssociation}
                  />
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Box mr={1}>
            <Button onClick={this.handleCancel}>
              <FormattedMessage
                id="dashboard.forms.assetform.button_cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </Box>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color={id ? 'secondary' : 'primary'}
            disabled={buttonDisabled}
            loading={loading}
          >
            {id ? (
              <FormattedMessage
                id="dashboard.forms.assetform.buttom_submit_edit"
                defaultMessage="Edit"
              />
            ) : (
              <FormattedMessage
                id="dashboard.forms.assetform.buttom_submit_create"
                defaultMessage="Create"
              />
            )}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(AssetsForm);
