import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchAccount } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import { isSetupNeeded } from '@selectors/index';
import { getAccounts } from '@selectors/accounts';
import Dashboard from './Dashboard';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account,
  accounts: Object.values(getAccounts(state.accounts)),
  firstWarehouseFetch: state.warehouses.firstFetch,
  error:
    state.warehouses.error || state.assets.error || state.organisation.error,
  language: getActiveLanguage(state),
  loading:
    state.warehouses.loading ||
    state.assets.loading ||
    state.organisation.loading,
  reconnecting: state.shallowLoading.reconnecting,
  setupNeeded: isSetupNeeded(state),
  modeSelector: state.authentication.modeSelector,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAccount: () => dispatch(fetchAccount.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
