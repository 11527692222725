import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';

import SimulationsTabGrid from './SimulationsTabGrid';

const mapStateToProps = (state: IAppState) => ({
  filters: state.filter.filters,
});

export default connect(mapStateToProps)(SimulationsTabGrid);
