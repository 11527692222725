// @ts-nocheck
import React, { Component } from 'react';
import {
  GetHandleProps,
  GetTrackProps,
  Handles,
  Rail,
  Slider,
  SliderItem,
  TrackItem,
  Tracks,
} from 'react-compound-slider';

import Handle from './Handle';
import Track from './Track';

import './index.css';

interface IProps {
  color?: string;
  disabled?: boolean;
  domain?: number[];
  values: number[];
  step?: number;
  onChange?: (values: ReadonlyArray<number>) => void;
  onSlideEnd?: (values: ReadonlyArray<number>) => void;
}

class RangeSlider extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSlideEnd = this.handleSlideEnd.bind(this);
  }

  public handleUpdate(values: ReadonlyArray<number>) {
    const { onChange } = this.props;

    if (onChange) {
      onChange(values);
    }
  }

  public handleSlideEnd(values: ReadonlyArray<number>) {
    const { onSlideEnd } = this.props;

    if (onSlideEnd) {
      onSlideEnd(values);
    }
  }

  public render() {
    const { color, disabled, values, domain, step } = this.props;

    return (
      <div className="range-slider-wrapper">
        {!disabled && (
          <Slider
            mode={3}
            step={step}
            domain={domain}
            className="range-slider"
            onSlideEnd={this.handleSlideEnd}
            onUpdate={this.handleUpdate}
            values={values}
          >
            <Rail>
              {({ getRailProps }: any) =>
                (
                  <div
                    className="range-slider-rail"
                    style={{ backgroundColor: color || '#CCCCCC' }}
                    {...getRailProps()}
                  />
                ) as any
              }
            </Rail>
            <Handles>
              {({
                handles,
                getHandleProps,
              }: {
                handles: SliderItem[];
                getHandleProps: GetHandleProps;
              }) => (
                <div className="slider-handles">
                  {handles.map((handle) => (
                    // @ts-ignore
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={domain!}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks left={false} right={false}>
              {({
                tracks,
                getTrackProps,
              }: {
                tracks: TrackItem[];
                getTrackProps: GetTrackProps;
              }) => (
                <div className="slider-tracks">
                  {tracks.map(({ id, source, target }) => (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                      theme={'primary'}
                    />
                  ))}
                </div>
              )}
            </Tracks>
          </Slider>
        )}
      </div>
    );
  }
}

export default RangeSlider;
