import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { openImportItemsForm, openImportStocksForm } from '@actions/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';

import Locations from './Locations';

const mapStateToProps = (state: IAppState) => {
  const filter =
    state.filter.filters[Object.keys(state.filter.filters)[0] || ''] || {};

  return {
    floorplan:
      getFloorplanById(state, filter.floorplanId || '') ||
      ({} as IncompleteFloorplan),
    filterId: filter.id,
    language: getActiveLanguage(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openImportItemsForm: () => dispatch(openImportItemsForm()),
  openImportStocksForm: () => dispatch(openImportStocksForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
