import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  createZone,
  ICreateZone,
  IUpdateZone,
  updateZone,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { getZoneById } from '@selectors/zones';
import ZoneForm from './ZoneForm';

const mapStateToProps = (state: IAppState, { id }: { id?: string }) => ({
  data: getZoneById(state, id || ''),
  zones: Object.values(state.zones.zones || {}),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  createZone: (properties: ICreateZone) =>
    dispatch(createZone.request(properties)),
  updateZone: (properties: IUpdateZone) =>
    dispatch(updateZone.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ZoneForm);
