import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';

import AssetsTabGrid from './AssetsTabGrid';

const mapStateToProps = (state: IAppState) => ({
  filters: state.filter.filters,
});

export default connect(mapStateToProps)(AssetsTabGrid);
