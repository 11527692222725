import { Notification } from '@models/Notification';

export function formatMessage(notification: Notification, colors: boolean) {
  let { message } = notification;

  if (!message) {
    return message || '';
  }

  const foundAssets = message.matchAll(/{asset#(.*?)}/gi);
  Array.from(foundAssets).forEach((assetMatch) => {
    const asset = (notification.messageInfo.assets || {})[assetMatch[1]] || {};

    const name = asset.name || 'NA';
    message = message.replace(
      assetMatch[0],
      colors === true
        ? `<b style="color: ${asset.color || '#000'}">${name}</b>`
        : asset.name
    );
  });

  const foundFps = message.matchAll(/{floorplan#(.*?)}/gi);
  Array.from(foundFps).forEach((fpMatch) => {
    const floorplan =
      (notification.messageInfo.floorplans || {})[fpMatch[1]] || {};

    const name = floorplan.name || 'NA';
    message = message.replace(fpMatch[0], name);
  });

  const foundWhs = message.matchAll(/{warehouse#(.*?)}/gi);
  Array.from(foundWhs).forEach((whMatch) => {
    const warehouse =
      (notification.messageInfo.warehouses || {})[whMatch[1]] || {};

    const name = warehouse.name || 'NA';
    message = message.replace(whMatch[0], name);
  });

  const foundZns = message.matchAll(/{zone#(.*?)}/gi);
  Array.from(foundZns).forEach((znMatch) => {
    const zone = (notification.messageInfo.zones || {})[znMatch[1]] || {};

    const name = zone.name || 'NA';
    message = message.replace(
      znMatch[0],
      colors === true
        ? `<b style="color: ${zone.color || '#000'}">${name}</b>`
        : zone.name
    );
  });

  return message;
}

export default {};
