import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IUpdateAccountSettings, updateAccountSettings } from '@actions/index';
import { IAppState } from '@reducers/index';
import { isAccountSettingsBasicLoading } from '@selectors/accounts';

import Basic from './Basic';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account!,
  changed: state.authentication.settings.basic.changed,
  loading: isAccountSettingsBasicLoading(state.authentication),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateAccountSettings: (properties: IUpdateAccountSettings) =>
    dispatch(updateAccountSettings.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Basic);
