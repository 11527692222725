import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  CreateTicket,
  UpdateTicket,
  createTicket,
  updateTicket,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';

import TicketForm from './TicketForm';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  loading: state.tickets.loading,
  formLoading: state.tickets.form.loading,
  tickets: state.tickets.tickets,
  redirect: state.tickets.form.redirect,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createTicket: (properties: CreateTicket) =>
    dispatch(createTicket.request(properties)),
  updateTicket: (properties: UpdateTicket) =>
    dispatch(updateTicket.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketForm);
