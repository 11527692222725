import { size } from 'lodash';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Sensor from '@models/Sensor';
import { WarehouseWithFloorplans } from '@models/WarehouseWithFloorplans';

/**
 * Calculate the current step based on the incompleteWarehouse object.
 * Steps content can be seen in {@link ./Setup/Setup Setup.tsx}.
 * @return {number} Number representing the current active step
 */
export const calculateActiveStep = (
  sensors: Sensor[],
  isZoneSetupDone: boolean,
  warehouse?: WarehouseWithFloorplans
) => {
  // If no warehouse has been created
  if (warehouse === undefined) {
    return 0;
  }

  // If a floorplan has not been created
  if (size(warehouse.floorplans) === 0) {
    return 1;
  }

  const floorplan: IncompleteFloorplan = Object.values(warehouse.floorplans)[0];

  // If a floorplan does not have an image
  if (!floorplan.image) {
    return 1;
  }

  // If the default floorplan does not have a scale defined
  if (!floorplan.scale) {
    return 2;
  }

  if (!floorplan.transformationMatrix) {
    // Sensors are only needed to differentiate between steps 3 and 4.
    // If there are more than 2 sensors, then we are ready for step 4.
    // Otherwise, we should be on step 3.
    if (sensors.length >= 2) {
      return 4;
    }

    return 3;
  }

  if (!floorplan.geoMapping) {
    return 5;
  }

  if (!floorplan.exteriorBoundaries) {
    return 6;
  }

  if (!floorplan.boundaries) {
    return 7;
  }

  if (!isZoneSetupDone) {
    return 8;
  }

  if (!floorplan.obstacles) {
    return 9;
  }

  if (!floorplan.racks) {
    return 10;
  }

  return 11;
};

export default {};
