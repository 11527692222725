import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IUpdateFloorplan, IUpdateFloorplanProps } from '@actions/index';
import Loading from '@app/common/Loading';
import LoadingButton from '@app/common/LoadingButton';
import ENULocations from '@app/common/FullMap/ENULocations';
import { defaultTransformationMatrix } from '@app/common/FullMap/consts';
import { IGeoMapping, IncompleteFloorplans } from '@models/IncompleteFloorplan';
import GeoAlignmentForm from './GeoAlignmentForm';

interface IProps {
  floorplanId: string;
  floorplans: IncompleteFloorplans;
  geoMapping?: IGeoMapping[];
  intl: IntlShape;
  loading: boolean;
  updateFloorplan: (properties: IUpdateFloorplan) => any;
  warehouseId: string;
}

interface IState {
  hasChanges: boolean;
  geoMapping: IGeoMapping[];
}

class GeoAlignment extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { geoMapping } = props;

    this.state = {
      geoMapping: geoMapping || [],
      hasChanges: false,
    };

    this.mappingChange = this.mappingChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleSubmit() {
    const { geoMapping } = this.state;
    const { floorplanId, floorplans, intl, warehouseId, updateFloorplan } =
      this.props;

    const floorplan = floorplans[floorplanId];

    this.setState({ hasChanges: false }, () => {
      let properties: IUpdateFloorplanProps = {
        enuTransformationMatrix: undefined,
        geoMapping,
        geodeticCenterLatitude: undefined,
        geodeticCenterLongitude: undefined,
      };

      if (geoMapping && geoMapping.length === 2) {
        const p1 = geoMapping![0];
        // This can come from user input as text, what breaks calcs
        p1.coordinate = {
          longitude: Number(p1.coordinate.longitude),
          latitude: Number(p1.coordinate.latitude),
        };
        const p2 = geoMapping![1];
        p2.coordinate = {
          longitude: Number(p2.coordinate.longitude),
          latitude: Number(p2.coordinate.latitude),
        };

        const enuLocations = new ENULocations(
          [p1, p2],
          floorplan.transformationMatrix || defaultTransformationMatrix
        );

        const geodeticCenter = enuLocations.getCenter();

        properties = {
          ...properties,
          enuTransformationMatrix: enuLocations.getENUTransformationMatrix(),
          geodeticCenterLatitude: (geodeticCenter || {}).latitude,
          geodeticCenterLongitude: (geodeticCenter || {}).longitude,
        };
      }

      updateFloorplan({
        floorplanId,
        intl,
        properties,
        warehouseId,
      });
    });
  }

  public mappingChange(geoMapping: IGeoMapping[]) {
    this.setState({
      geoMapping,
      hasChanges: true,
    });
  }

  public render() {
    const { floorplanId, geoMapping: propsgeoMapping, loading } = this.props;
    const { geoMapping, hasChanges } = this.state;

    return (
      <Card className="step-card">
        <CardContent style={{ flex: 1, position: 'relative' }}>
          <Loading loading={loading}>
            <GeoAlignmentForm
              mappingChange={this.mappingChange}
              geoMapping={geoMapping}
              floorplanId={floorplanId}
            />
          </Loading>
        </CardContent>
        <CardActions
          style={{ borderTop: '1px solid #e1e1e1', justifyContent: 'flex-end' }}
        >
          {!propsgeoMapping && geoMapping.length === 0 && (
            <LoadingButton
              loading={loading}
              onClick={this.handleSubmit}
              color="primary"
              variant="contained"
            >
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.button_skip"
                defaultMessage="Skip"
              />
            </LoadingButton>
          )}
          <LoadingButton
            disabled={!hasChanges || geoMapping.length !== 2}
            loading={loading}
            onClick={this.handleSubmit}
            color="secondary"
            variant="contained"
          >
            <FormattedMessage
              id="dashboard.forms.floorplanconfigurationform.button_save"
              defaultMessage="Save Changes"
            />
          </LoadingButton>
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(GeoAlignment);
