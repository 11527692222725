import { defaultTransformationMatrix } from '@app/common/FullMap/consts';
import { transformMeters } from '@dashboard_utils/index';
import { TransformationMatrix2D, transformPixelsToMeters } from '../../utils';

export function convertPolygonsInPixelsToMeters(
  polygons: number[][][],
  matrix: TransformationMatrix2D,
  scale: number
) {
  return polygons.map((p) =>
    p.map((gc) =>
      transformPixelsToMeters(gc as [number, number], matrix, scale)
    )
  );
}

export function getRotationFromDirection(
  direction: number,
  transformationMatrix: TransformationMatrix2D
) {
  // Get 1,0 vector in image coordinate system
  const transformedVectorPoint = transformMeters(
    [1, 0],
    transformationMatrix || defaultTransformationMatrix
  );
  // Get origin position in image coordinate system
  const transformationOrigin = transformMeters(
    [0, 0],
    transformationMatrix || defaultTransformationMatrix
  );
  // Computate direction vector
  // [Math.sin(direction), Math.cos(direction)] computes the original direction vector
  const directionVector: [number, number] = [
    Math.cos(direction),
    Math.sin(direction),
  ];
  // Get direction vector in image coordinate system
  const transformedDirectionVector = transformMeters(
    directionVector,
    transformationMatrix || defaultTransformationMatrix
  );
  // Final transformed position equals (transformed) vector - origin + vector point - origin
  // Removing origin resets transformation translation
  const transformatedPosition = [
    transformedVectorPoint[0] -
      transformationOrigin[0] -
      (transformedVectorPoint[0] - transformedDirectionVector[0]),
    transformedVectorPoint[1] -
      transformationOrigin[1] -
      (transformedVectorPoint[1] - transformedDirectionVector[1]),
  ];
  // Compute the angle of rotation for the entire transformation (direction + floorplan rotation or reflections)
  const transformationRotation = Math.atan2(
    transformatedPosition[1],
    transformatedPosition[0]
  );

  return transformationRotation;
}
