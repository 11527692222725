import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

const ErrorPage: FunctionComponent<{}> = () => (
  <Grid container justifyContent="center">
    <Grid item sm={4}>
      <Box p={4}>
        <Card>
          <CardHeader
            title={
              <FormattedMessage
                id="dashboard.errorpage.title"
                defaultMessage="Error"
              />
            }
          />
          <CardContent>
            <Typography variant="body1">
              <FormattedMessage
                id="dashboard.errorpage.p1"
                defaultMessage="Unfortunately, an error occurred. It has already been reported and we are trying hard to fix it."
              />
              <br />
              <FormattedMessage
                id="dashboard.errorpage.p2"
                defaultMessage="To try again, click the Refresh button below."
              />
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => window.location.reload()}
            >
              <FormattedMessage
                id="dashboard.errorpage.button"
                defaultMessage="Refresh"
              />
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Grid>
  </Grid>
);

export default ErrorPage;
