import { Component } from 'react';
import { sum, take } from 'lodash';
import { Color, Path, Point, Matrix } from 'paper';

import ws, { ISensorData, IRawSensorData } from '@api/websocket';
import { getRotationFromDirection } from '@app/utils/geometryUtils';
import Asset from '@models/Asset';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Sensor from '@models/Sensor';
import { getAssetBySensorAssociation } from '@selectors/assets';
import {
  Date,
  transformMeters,
  transformMetersToPixels,
} from '@dashboard_utils/index';
import MapImages from '../../MapImages';
import Paper from '../../Paper';
import { defaultTransformationMatrix } from '../../../consts';
import {
  ICircleFeature,
  MapFeature,
  HoverFeature,
  IPathFeature,
} from '../../../types';
import mapEvents from '../../eventEmitter';
import { geodetic2enu } from '../../../ENULocations';
import { TransformationMatrix2D } from '../../../../../../utils';

interface IMobileBeaconData extends ISensorData {
  mapCoordinate: paper.Point;
  transformedMapCoordinate: paper.Point;
}

export enum DataType {
  SIM = 'simulator',
  SENSORS = 'sensors',
}

interface IMobileBeaconPath {
  segment: number;
  path: paper.Path;
}

export interface IProps {
  assetIds: string[];
  tags: string[];
  assets: Asset[];
  beacons: Sensor[];
  floorplan: IncompleteFloorplan;
  measurementUnits: string;
  id: string;
  mapImages: MapImages;
  paper: Paper;
  hoverFeature?: HoverFeature;
  activeFeature?: MapFeature;
  updateHoverFeature: (id: string, feature?: HoverFeature) => void;
  updateSelectedFeature: (id: string, feature?: MapFeature) => void;
  router: any;
  showLiveData: boolean;
  showRawLiveData: boolean;
}

class BeaconMapLayer extends Component<IProps> {
  public elements: Record<string, [ICircleFeature, IMobileBeaconPath[]]> = {};

  private lastRender: number = Date.now();

  constructor(props: IProps) {
    super(props);

    this.clean = this.clean.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleRawMessage = this.handleRawMessage.bind(this);
    this.reload = this.reload.bind(this);
  }

  public componentDidMount() {
    const { showLiveData, showRawLiveData } = this.props;

    if (showLiveData === true) {
      ws.on('live-data', this.handleMessage);
    }
    if (showRawLiveData === true) {
      ws.on('raw-live-data', this.handleRawMessage);
    }

    mapEvents.on('live-data', this.handleMessage);
    mapEvents.on('resized', this.reload);
    mapEvents.on('clean', this.clean);

    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { beacons, assets, tags } = this.props;

    if (
      JSON.stringify(prevProps.assets || []) !== JSON.stringify(assets || []) ||
      JSON.stringify(prevProps.beacons || []) !==
        JSON.stringify(beacons || []) ||
      JSON.stringify(prevProps.tags || []) !== JSON.stringify(tags || [])
    ) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    const { showLiveData } = this.props;

    if (showLiveData === true) {
      ws.removeListener('live-data', this.handleMessage);
    }
    mapEvents.removeListener('live-data', this.handleMessage);
    mapEvents.removeListener('resized', this.reload);
    mapEvents.removeListener('clean', this.clean);

    this.clear();
  }

  private handleLiveData(sensorsData: ISensorData[]) {
    const {
      assets,
      assetIds,
      beacons,
      floorplan,
      id,
      measurementUnits,
      tags,

      hoverFeature,
      activeFeature,

      mapImages,
      paper,
    } = this.props;
    const { height } = mapImages;
    const { view } = paper.scope;

    paper.scope.activate();

    // Adding beacon draw radius (24) to boundaries
    const { bounds } = view;
    const boundX = bounds.x - 24;
    const boundY = bounds.y - 24;
    const boundWidth = bounds.width + 48;
    const boundHeight = bounds.height + 48;

    const transformedSensorData: IMobileBeaconData[] = sensorsData
      .filter((data) => {
        const sensor = beacons.find((b: any) => b.id === data.id);

        const asset = sensor
          ? getAssetBySensorAssociation(assets, sensor.physicalAddress, data.ts)
          : undefined;

        return (
          (assetIds.length === 0 ||
            assetIds.find((bId: string) => asset && bId === asset.id) !==
              undefined) &&
          (tags.length === 0 ||
            tags.find((tag: string) => asset && asset.tags.indexOf(tag) !== -1))
        );
      })
      .map((data) => {
        const coordinate: [number, number] = [data.x, data.y];
        const mapCoordinate = new Point(
          transformMetersToPixels(
            coordinate,
            floorplan.transformationMatrix || defaultTransformationMatrix,
            floorplan.scale || 1
          )
        );

        const transformedMapCoordinate = mapCoordinate.transform(
          new Matrix(1, 0, 0, -1, 0, height)
        );

        return { ...data, mapCoordinate, transformedMapCoordinate };
      });

    const visibleSensorsData = transformedSensorData.filter((data) => {
      // If beacon is inside visible map bounds
      if (
        data.transformedMapCoordinate.x >= boundX &&
        data.transformedMapCoordinate.x <= boundX + boundWidth &&
        data.transformedMapCoordinate.y >= boundY &&
        data.transformedMapCoordinate.y <= boundY + boundHeight
      ) {
        return true;
      }

      const eKey = `${data.id}_${data.dtype || 'live'}`;
      if (this.elements[eKey]) {
        const dataPoints = sum(
          this.elements[eKey][1].map((p) => p.path.segments!.length)
        );
        if (dataPoints > 200) {
          this.elements[eKey][1][0].path.removeSegments(0, 1);

          if (this.elements[eKey][1][0].path.segments!.length === 0) {
            this.elements[eKey][1].splice(0, 1);
          }
        }

        // If beacon path is inside visible map bounds
        const pathInView = this.elements[eKey][1].find((segmentPath) =>
          segmentPath.path.segments.find((segment: any) => {
            if (
              segment.point.x >= boundX &&
              segment.point.x <= boundX + boundWidth &&
              segment.point.y >= boundY &&
              segment.point.y <= boundY + boundHeight
            ) {
              return true;
            }

            return false;
          })
        );

        return pathInView !== undefined;
      }

      return false;
    });

    /**
     * Sensors updated > 10 - Update rate 5Hz
     * Sensors updated > 20 - Update rate 3Hz
     * Sensors updated > 30 - Update rate 1Hz
     */

    if (
      !(
        (visibleSensorsData.length > 30 &&
          this.lastRender + 999 < Date.now()) ||
        (visibleSensorsData.length > 20 &&
          visibleSensorsData.length <= 30 &&
          this.lastRender + 333 < Date.now()) ||
        (visibleSensorsData.length > 10 &&
          visibleSensorsData.length <= 20 &&
          this.lastRender + 199 < Date.now()) ||
        visibleSensorsData.length <= 10
      )
    ) {
      return;
    }

    this.lastRender = Date.now();

    sensorsData.forEach((data) => {
      const eKey = `${data.id}_${data.dtype || 'live'}`;

      if (this.elements[eKey]) {
        this.elements[eKey][0].visible = false;
        this.elements[eKey][1].forEach((forkPath) => {
          // eslint-disable-next-line no-param-reassign
          forkPath.path.visible = false;
        });
      }
    });

    visibleSensorsData.forEach((data) => {
      const eKey = `${data.id}_${data.dtype || 'live'}`;

      if (!this.elements[eKey]) {
        beacons
          .filter((b) => b.id === data.id)
          .forEach((b) => this.drawBeacon(b, data.dtype));
      } else {
        this.elements[eKey][0].visible = true;
        this.elements[eKey][1].forEach((forkPath) => {
          // eslint-disable-next-line no-param-reassign
          forkPath.path.visible = true;
        });
      }
    });

    visibleSensorsData.forEach((data) => {
      const eKey = `${data.id}_${data.dtype || 'live'}`;

      if (this.elements[eKey]) {
        const { featureInfo } = this.elements[eKey][0];

        let symbol;
        if (data.dtype === 'rmc') {
          const offset = 12 / paper.getZoom();
          const pos = data.transformedMapCoordinate;
          symbol = new Path(new Point(pos.x, pos.y)) as IPathFeature;
          symbol.add(new Point(pos.x - offset, pos.y));
          symbol.add(new Point(pos.x + offset, pos.y));
          symbol.add(new Point(pos.x, pos.y));
          symbol.add(new Point(pos.x, pos.y - offset));
          symbol.add(new Point(pos.x, pos.y + offset));
        } else if (data.dtype === 'uwb') {
          const offset = 12 / paper.getZoom();
          const pos = data.transformedMapCoordinate;
          symbol = new Path(new Point(pos.x, pos.y)) as IPathFeature;
          symbol.add(new Point(pos.x - offset, pos.y));
          symbol.add(new Point(pos.x + offset, pos.y));
          symbol.add(new Point(pos.x, pos.y));
          symbol.add(new Point(pos.x, pos.y - offset));
          symbol.add(new Point(pos.x, pos.y + offset));
          symbol.add(new Point(pos.x, pos.y));
          symbol.add(new Point(pos.x - offset, pos.y - offset));
          symbol.add(new Point(pos.x + offset, pos.y + offset));
          symbol.add(new Point(pos.x, pos.y));
          symbol.add(new Point(pos.x - offset, pos.y + offset));
          symbol.add(new Point(pos.x + offset, pos.y - offset));
        }

        // direction is the angle in radians relative to the vector (1,0) in the original frame of reference
        if (data.direction) {
          const segmentPath = this.elements[eKey][1].find(
            (p) => p.segment === data.segment
          );
          if (segmentPath !== undefined) {
            segmentPath.path.add(data.transformedMapCoordinate);
          } else {
            const drawPath = new Path();
            drawPath.strokeColor =
              (this.elements[eKey][0].featureInfo.props || {}).color ||
              new Color('blue');
            drawPath.strokeWidth = 3;
            drawPath.strokeScaling = false;
            drawPath.position = data.mapCoordinate;
            // @ts-ignore
            drawPath.strokeColor.alpha = 0.1;
            drawPath.transform(new Matrix(1, 0, 0, -1, 0, height));
            this.elements[eKey][1].push({
              path: drawPath,
              segment: data.segment || 0,
            });
          }

          if (!symbol) {
            // Drawing vertices, order matter - draw center has to be origin in the image coordinate system
            const featurePoints: [number, number][] = [
              [-12 / (view.zoom || 1), 12 / (view.zoom || 1)],
              [12 / (view.zoom || 1), 0],
              [-12 / (view.zoom || 1), -12 / (view.zoom || 1)],
              [-6 / (view.zoom || 1), 0],
            ];
            this.elements[eKey][0].removeSegments();
            featurePoints.map((p: [number, number]) =>
              this.elements[eKey][0].add(new Point(p))
            );
            this.elements[eKey][0].closePath();
            this.elements[eKey][0].rotate(
              (getRotationFromDirection(
                data.direction || 0,
                floorplan.transformationMatrix as TransformationMatrix2D
              ) *
                180) /
                Math.PI,
              new Point([0, 0])
            );
            this.elements[eKey][0].translate(data.mapCoordinate);
          } else {
            this.elements[eKey][0] = symbol;
          }
        } else {
          this.elements[eKey][0].remove();
          this.elements[eKey][0] = (symbol ||
            new Path.Circle(
              data.mapCoordinate,
              12 / (view.zoom || 1)
            )) as ICircleFeature;
          this.elements[eKey][0].featureInfo = featureInfo;
          this.elements[eKey][0].fillColor = new Color('white');
          this.elements[eKey][0].strokeColor = (
            this.elements[eKey][0].featureInfo.props || {}
          ).color;
          this.elements[eKey][0].strokeWidth = 3;
          this.elements[eKey][0].strokeScaling = false;
        }
        if (!symbol) {
          this.elements[eKey][0].transform(new Matrix(1, 0, 0, -1, 0, height));
        }

        const featureInfoUpdate = {
          ...featureInfo.props,
          direction: `${((data.direction || 0) * (180 / Math.PI)).toFixed(2)}º`,
          // eslint-disable-next-line camelcase
          direction_raw: String(data.direction || 0),
          height: `${(data.z || 0).toFixed(2)}m`,
          horizontalVelocity:
            (
              (data.vel_xy || data.speed || 0) *
              (measurementUnits === 'si' ? 1 : 3.2808399)
            ).toFixed(2) + (measurementUnits === 'si' ? 'm/s' : 'ft/s'),
          moving: data.moving,
          segment: data.segment,
          ts: data.ts,
          dtype: data.dtype,
          verticalVelocity:
            (
              (data.vel_z || 0) * (measurementUnits === 'si' ? 1 : 3.2808399)
            ).toFixed(2) + (measurementUnits === 'si' ? 'm/s' : 'ft/s'),
          x: data.x.toFixed(2),
          y: data.y.toFixed(2),
        };

        this.elements[eKey][0].featureInfo = {
          ...featureInfo,
          props: { ...featureInfo.props, ...featureInfoUpdate },
        };

        this.elements[eKey][0].visible = true;
        this.elements[eKey][0].bringToFront();
        this.elements[eKey][1].forEach((forkPath) => {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          forkPath.path.strokeColor.alpha = 0.4;
        });

        if (hoverFeature && hoverFeature.info.id === eKey) {
          mapEvents.emit('update-hover', {
            mapId: id,
            featureId: eKey,
            props: { ...hoverFeature.info.props, ...featureInfoUpdate },
          });
        }

        if (activeFeature && activeFeature.featureInfo.id === eKey) {
          mapEvents.emit('update-feature', {
            mapId: id,
            featureId: eKey,
            props: { ...activeFeature.featureInfo.props, ...featureInfoUpdate },
          });
        }
      }
    });
  }

  /**
   * @description Updates features with new sensor data
   * @param       {ISensorData[]} data Array of sensor data received by WS
   */
  private handleMessage(sensorsData: ISensorData[]) {
    const { paper } = this.props;

    const { view } = paper.scope;

    if (!view) {
      return false;
    }

    return this.handleLiveData(sensorsData);
  }

  /**
   * @description Treats raw live data
   * @param       {ISensorData[]} data Array of sensor data received by WS
   */
  private handleRawMessage(sensorsData: IRawSensorData[]) {
    const { floorplan } = this.props;

    this.handleLiveData(
      sensorsData
        .filter((point) => point.dtype === 'rmc')
        .map((point) => {
          const { east, north } = geodetic2enu(
            { latitude: point.latitude, longitude: point.longitude },
            {
              latitude: floorplan.geodeticCenterLatitude || 0,
              longitude: floorplan.geodeticCenterLongitude || 0,
            }
          );

          const meterPosition = transformMeters(
            [east, north],
            floorplan.enuTransformationMatrix
          );

          return {
            ...point,
            x: meterPosition[0],
            y: meterPosition[1],
          } as ISensorData;
        })
        .concat(sensorsData.filter((point) => point.dtype === 'uwb') as any[])
    );
  }

  public load() {
    const { beacons, id, updateSelectedFeature, router } = this.props;

    (beacons || []).forEach((s) => {
      const activeBeacons = Object.keys(this.elements).filter(
        (k) => k.indexOf(s.id) === 0
      );

      activeBeacons.forEach((key) => {
        if (this.elements[key]) {
          if (
            (this.elements[key][0].featureInfo.props || {}).ts >
            // has data in the last 2s
            Date.now() - 2000
          ) {
            this.elements[key][0].visible = true;
            this.elements[key][1].forEach((forkPath) => {
              // eslint-disable-next-line no-param-reassign
              forkPath.path.visible = true;
            });
          }
        } else {
          this.drawBeacon(s, key.replace(`${s.id}_`, ''));
        }

        if (router.query.sensorId && key.indexOf(router.query.sensorId) === 0) {
          if (this.elements[key]) {
            updateSelectedFeature(id, this.elements[key][0]);
          } else {
            updateSelectedFeature(id);
          }
        }
      });
    });
  }

  public drawBeacon(sensor: Sensor, dataType = 'live') {
    const { assetIds, tags, assets, floorplan, mapImages, paper } = this.props;

    const asset = getAssetBySensorAssociation(
      assets || [],
      sensor.physicalAddress
    );

    if (
      !asset ||
      ((assetIds.length === 0 ||
        assetIds.find((id: string) => id === asset.id) !== undefined) &&
        (tags.length === 0 ||
          tags.find((tag: string) => asset.tags.indexOf(tag) !== -1)))
    ) {
      let sensorPosition = [0, 0];
      if (sensor.position) {
        sensorPosition = transformMetersToPixels(
          take(sensor.position, 2) as [number, number],
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        );
      }

      const draw = new Path.Circle(
        new Point(sensorPosition),
        12 / paper.getZoom()
      ) as ICircleFeature;

      const color = new Color(
        asset !== undefined ? asset.color || 'blue' : 'blue'
      );
      draw.fillColor = new Color('white');
      draw.strokeColor = color;
      draw.strokeWidth = 3;
      draw.strokeScaling = false;
      draw.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
      draw.visible = false;

      const key = `${sensor.id}_${dataType}`;
      draw.featureInfo = {
        assetId: asset !== undefined ? asset.id : undefined,
        id: key,
        props: {
          color,
          name: asset !== undefined ? asset.name : undefined,
          physicalAddress: sensor.physicalAddress,
          ts: 0,
          type: sensor.type,
          x: (sensor.position || [0, 0, 0])[0].toFixed(2),
          y: (sensor.position || [0, 0, 0])[1].toFixed(2),
          z: (sensor.position || [0, 0, 0])[2].toFixed(2),
        },
        tags: asset !== undefined ? asset.tags : undefined,
        title: asset !== undefined ? asset.name : 'Beacon',
        type: 'beacon',
      };

      this.elements[key] = [draw, []];
    }
  }

  public clean(ts: number) {
    const {
      activeFeature,
      hoverFeature,
      id,
      updateHoverFeature,
      updateSelectedFeature,
      router,
    } = this.props;

    const oldBeacons = Object.keys(this.elements).filter((bId: string) => {
      const props = this.elements[bId][0].featureInfo.props || {};
      return (ts && props.ts < ts) || (!ts && props.ts < Date.now() - 10000);
    });

    oldBeacons.forEach((bId: string) => {
      this.elements[bId][0].visible = false;
      this.elements[bId][1].forEach((forkPath) => {
        // eslint-disable-next-line no-param-reassign
        forkPath.path!.strokeColor!.alpha = 0.1;
      });
    });

    const veryOldForks = Object.keys(this.elements).filter((bId: string) => {
      const props = this.elements[bId][0].featureInfo.props || {};

      return (
        (ts && props.ts < ts - 5000) || (!ts && props.ts < Date.now() - 120000)
      );
    });

    veryOldForks.forEach((bId: string) => {
      this.elements[bId][0].remove();
      this.elements[bId][1].forEach((ePath) => ePath.path!.remove());

      delete this.elements[bId];
    });

    if (
      hoverFeature &&
      oldBeacons.find((bId: string) => bId === hoverFeature.info.id) !==
        undefined
    ) {
      updateHoverFeature(id);
    }

    if (
      activeFeature &&
      oldBeacons.find((bId: string) => bId === activeFeature.featureInfo.id)
    ) {
      if (router.query.sensorId !== activeFeature.featureInfo.id) {
        updateSelectedFeature(id);
      }
    }
  }

  public shallowClear() {
    Object.keys(this.elements).forEach((id: string) => {
      this.elements[id][0].visible = false;
      this.elements[id][1].forEach((fPath) => {
        // eslint-disable-next-line no-param-reassign
        fPath.path.visible = false;
      });
    });
  }

  public clear() {
    Object.keys(this.elements).forEach((id: string) => {
      this.elements[id][0].remove();
      this.elements[id][1].forEach((fPath) => {
        fPath.path.remove();
      });
    });

    this.elements = {};
  }

  public reload() {
    this.shallowClear();

    this.load();
  }

  public render() {
    return null;
  }
}

export default BeaconMapLayer;
