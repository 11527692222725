import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IUpdateFloorplan, updateFloorplan } from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { SensorTypes } from '@models/Sensor';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import { getFloorplanSensorsByType } from '@selectors/sensors';
import Tabs from './Tabs';

interface IProps {
  floorplan: IncompleteFloorplan;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
}

const mapStateToProps = (state: IAppState, { floorplan }: IProps) => ({
  language: getActiveLanguage(state),
  loading: state.floorplans.loading,
  sensors: getFloorplanSensorsByType(
    state,
    SensorTypes.STATIONARY,
    floorplan.id
  )!,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tabs));
