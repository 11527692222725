import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WarningIcon from '@mui/icons-material/Warning';
import Alert from '@mui/material/Alert';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { INotificationId } from '@actions/index';
import { RuleAlerts } from '@actions/rules';
import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import { Date, timeZone } from '@dashboard_utils/index';
import { Rule } from '@models/Rule';
import { RuleTemplate } from '@models/RuleTemplate';
import { Warehouses } from '@models/Warehouse';
import { formatMessage } from '../../../../utils/ruleUtils';

interface IProps {
  dismissLoading: boolean;
  router: any;
  language: string;
  loading: boolean;
  locale: string;
  measurementUnits: string;
  ruleAlerts: RuleAlerts;
  rules: Record<string, Rule>;
  templates: Record<string, RuleTemplate>;

  unreadNotifications: (
    ids: INotificationId[],
    locale: string,
    unit: string
  ) => void;
  dismissNotifications: (
    ids: INotificationId[],
    locale: string,
    unit: string
  ) => void;

  filterId: string;

  warehouseId: string;

  warehouses: Warehouses;

  intl: IntlShape;
}

interface IState {
  columns: ITableColumn[];
}

const messages = {
  dismissAll: {
    defaultMessage: 'Dismiss All',
    id: 'dashboard.rules.alerts.dismissall',
  },
  thMessage: {
    defaultMessage: 'Message',
    id: 'dashboard.rules.alerts.th_message',
  },
  thOptions: {
    defaultMessage: 'Options',
    id: 'dashboard.rules.alerts.th_options',
  },
  thRuleName: {
    defaultMessage: 'Rule',
    id: 'dashboard.rules.alerts.th_rulename',
  },
  thTemplateName: {
    defaultMessage: 'Template',
    id: 'dashboard.rules.alerts.th_templatename',
  },
  thTs: {
    defaultMessage: 'Date/time',
    id: 'dashboard.rules.alerts.th_datetime',
  },
};

class AlertsTable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      columns: [
        { label: intl.formatMessage(messages.thMessage), name: 'plainMessage' },
        {
          label: intl.formatMessage(messages.thTs),
          name: 'date',
          options: { filter: false },
        },
        { label: intl.formatMessage(messages.thRuleName), name: 'ruleName' },
        {
          label: intl.formatMessage(messages.thTemplateName),
          name: 'templateName',
        },
        {
          label: intl.formatMessage(messages.thOptions),
          name: 'options',
          options: { interaction: false },
        },
      ],
    };

    this.handleDismissAllClick = this.handleDismissAllClick.bind(this);
    this.customToolbar = this.customToolbar.bind(this);
    this.customRowRender = this.customRowRender.bind(this);
  }

  public handleDismissClick(id: string) {
    const { dismissNotifications, locale, measurementUnits } = this.props;

    dismissNotifications([{ id, type: 'alerts' }], locale, measurementUnits);
  }

  public handleUnreadClick(id: string) {
    const { locale, measurementUnits, unreadNotifications } = this.props;

    unreadNotifications([{ id, type: 'alerts' }], locale, measurementUnits);
  }

  public handleDismissAllClick() {
    const { dismissNotifications, locale, measurementUnits, ruleAlerts } =
      this.props;

    dismissNotifications(
      ruleAlerts.list
        .filter((a) => a.dismissed === false)
        .map((a) => ({ id: a.id, type: 'alerts' })),
      locale,
      measurementUnits
    );
  }

  public handleClick(ts: number, floorplanId: string) {
    const { router, language } = this.props;

    router.navigate(
      `/${language}/dashboard/player?ts=${ts}&floorplan=${floorplanId}`
    );
  }

  public customRowRender(
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    const { ruleAlerts } = this.props;

    if (!ruleAlerts.list[dataIndex]) {
      return <TableRow key={rowIndex} />;
    }

    let warn = null;
    if (ruleAlerts.list[dataIndex].dismissed === false) {
      if (ruleAlerts.list[dataIndex].level === 'info') {
        warn = <InfoIcon style={{ color: 'blue', marginRight: '7px' }} />;
      } else if (ruleAlerts.list[dataIndex].level === 'warn') {
        warn = <WarningIcon style={{ color: 'orange', marginRight: '7px' }} />;
      } else if (ruleAlerts.list[dataIndex].level === 'critical') {
        warn = <ErrorIcon style={{ color: 'red', marginRight: '7px' }} />;
      }
    }

    return (
      <TableRow key={rowIndex}>
        {columns[0] && (
          <TableCell
            style={{
              fontWeight:
                ruleAlerts.list[dataIndex].dismissed === false
                  ? 'bold'
                  : undefined,
            }}
          >
            <div style={{ display: 'flex', verticalAlign: 'middle' }}>
              {warn}

              <span
                role="button"
                aria-label=" "
                tabIndex={0}
                dangerouslySetInnerHTML={{
                  __html: formatMessage(ruleAlerts.list[dataIndex], true),
                }}
                style={{
                  cursor: 'pointer',
                  lineHeight: '25px',
                }}
                onClick={() =>
                  this.handleClick(
                    ruleAlerts.list[dataIndex].ts,
                    ruleAlerts.list[dataIndex].floorplanId
                  )
                }
                onKeyDown={() =>
                  this.handleClick(
                    ruleAlerts.list[dataIndex].ts,
                    ruleAlerts.list[dataIndex].floorplanId
                  )
                }
              />
            </div>
          </TableCell>
        )}
        {columns[1] && (
          <TableCell
            style={{
              fontWeight:
                ruleAlerts.list[dataIndex].dismissed === false
                  ? 'bold'
                  : undefined,
            }}
          >
            {rowData[1]}
          </TableCell>
        )}
        {columns[2] && (
          <TableCell
            style={{
              fontWeight:
                ruleAlerts.list[dataIndex].dismissed === false
                  ? 'bold'
                  : undefined,
            }}
          >
            {rowData[2]}
          </TableCell>
        )}
        {columns[3] && (
          <TableCell
            style={{
              fontWeight:
                ruleAlerts.list[dataIndex].dismissed === false
                  ? 'bold'
                  : undefined,
            }}
          >
            {rowData[3]}
          </TableCell>
        )}
        {columns[4] && (
          <TableCell
            style={{
              fontWeight:
                ruleAlerts.list[dataIndex].dismissed === false
                  ? 'bold'
                  : undefined,
            }}
          >
            {ruleAlerts.list[dataIndex].dismissed === false ? (
              <VisibilityOffIcon
                className="notification-dismiss"
                onClick={() =>
                  this.handleDismissClick(ruleAlerts.list[dataIndex].id)
                }
              />
            ) : (
              <VisibilityIcon
                className="notification-unread"
                onClick={() =>
                  this.handleUnreadClick(ruleAlerts.list[dataIndex].id)
                }
              />
            )}
          </TableCell>
        )}
      </TableRow>
    );
  }

  public customToolbar(): React.ReactElement {
    const { dismissLoading, intl, ruleAlerts } = this.props;

    return (
      <>
        {ruleAlerts.list.filter((r) => r.dismissed === false).length > 0 && (
          <Button
            aria-label={intl.formatMessage(messages.dismissAll)}
            onClick={this.handleDismissAllClick}
            disabled={dismissLoading}
            style={{ textTransform: 'none', marginLeft: '30px' }}
          >
            {dismissLoading ? (
              <CircularProgress size={24} />
            ) : (
              <VisibilityOffIcon style={{ marginRight: '5px' }} />
            )}
            {intl.formatMessage(messages.dismissAll)}
          </Button>
        )}
      </>
    );
  }

  public render() {
    const {
      loading,
      ruleAlerts,

      rules,
      templates,

      warehouseId,
      warehouses,
    } = this.props;

    const { columns } = this.state;

    const warehouseTz = warehouses[warehouseId]
      ? warehouses[warehouseId].timezone
      : timeZone;

    return (
      <Loading loading={loading}>
        {ruleAlerts.returned < ruleAlerts.total ? (
          <Alert severity="info">
            <FormattedMessage
              id="dashboard.rules.alerts.limitwarning"
              defaultMessage="Displaying only the last {returned} of {total} alerts for the selected period, toggle filters to display more."
              values={{
                returned: ruleAlerts.returned,
                total: ruleAlerts.total,
              }}
            />
          </Alert>
        ) : null}
        <Card style={{ display: 'flex', flex: 1 }}>
          <CardContent style={{ flex: 1 }}>
            <MUITable
              columns={columns}
              customRowRender={this.customRowRender}
              customToolbar={this.customToolbar}
              data={ruleAlerts.list.map((rA) => ({
                ...rA,
                date: new Date(warehouseTz, rA.ts).format(),
                plainMessage: formatMessage(rA, false),
                ruleName: (rules[rA.ruleId] || {}).name,
                templateName: (templates[rA.templateId] || {}).name,
              }))}
              defaultOrderField="ts"
              tableId="rule-alerts"
            />
          </CardContent>
        </Card>
      </Loading>
    );
  }
}

export default injectIntl(AlertsTable);
