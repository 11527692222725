import { Matrix, Path, Point } from 'paper';
import { Component } from 'react';

import { generateScatterMap } from '@dashboard_utils/index';
import Asset from '@models/Asset';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import ScatterMap from '@models/ScatterMap';
import { orderLayers } from '../../eventEmitter';
import Paper from '../../Paper';
import MapImages from '../../MapImages';
import { defaultTransformationMatrix } from '../../../consts';

interface IProps {
  assets: Asset[];
  data: ScatterMap;
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  loading: boolean;
  paper: Paper;
  warehouseTz: string;
}

class ScatterMapLayer extends Component<IProps> {
  private elements: paper.Path[] = [];

  public componentDidMount() {
    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { data } = this.props;

    if (JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    this.clear();
  }

  public load() {
    const { assets, data, floorplan, paper, mapImages, warehouseTz } =
      this.props;

    paper.scope.activate();

    this.elements = generateScatterMap(
      {
        Matrix,
        Path,
        Point,
      },
      warehouseTz,
      assets,
      mapImages.height,
      data,
      floorplan.transformationMatrix || defaultTransformationMatrix,
      floorplan.scale || 1
    );

    orderLayers();
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((e) => e.remove());

    this.elements = [];
  }

  public render() {
    return null;
  }
}

export default ScatterMapLayer;
