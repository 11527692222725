import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';

import { confirmDialog } from '@actions/app';
import {
  archiveTicket,
  ArchiveTicket,
  createTicketComment,
  CreateTicketComment,
  DeleteTicket,
  deleteTicket,
  DeleteTicketComment,
  deleteTicketComment,
} from '@actions/tickets';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { withRouter } from '@app/utils/withRouter';
import { getAccountLocale } from '@selectors/accounts';
import Ticket from './Ticket';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  locale: getAccountLocale(state.authentication),
  loading: state.tickets.loading,
  formLoading: state.tickets.form.loading,
  tickets: state.tickets.tickets,
  redirect: state.tickets.form.redirect,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  archiveTicket: (properties: ArchiveTicket) =>
    dispatch(archiveTicket.request(properties)),
  deleteTicket: (properties: DeleteTicket) =>
    dispatch(deleteTicket.request(properties)),
  createTicketComment: (properties: CreateTicketComment) =>
    dispatch(createTicketComment.request(properties)),
  deleteTicketComment: (properties: DeleteTicketComment) =>
    dispatch(deleteTicketComment.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ticket));
