import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { ICreateFloorplan, IUpdateFloorplan } from '@actions/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { TransformationMatrix2D } from '../../../utils';
import { loadImage, urltoFile } from '../../utils/mapUtils';

export interface IProps {
  createFloorplan: (properties: ICreateFloorplan) => void;
  data?: IncompleteFloorplan;
  intl: IntlShape;
  onClose: () => void;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
  warehouseId: string;
}

export interface IState {
  id?: string;
  name: string;
}

class FloorplanFormDialog extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { data } = props;

    this.state = {
      id: data ? data.id : undefined,
      name: data ? data.name : '',
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'name'>);
    };
  }

  public handleCancel() {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }

    this.reset();
  }

  public handleSubmit() {
    const { id, name } = this.state;
    const { intl, warehouseId } = this.props;

    const { onClose, createFloorplan, updateFloorplan } = this.props;

    if (id !== undefined) {
      updateFloorplan({
        floorplanId: id,
        intl,
        properties: { name },
        warehouseId,
      });
    } else {
      createFloorplan({
        intl,
        properties: { name },
        warehouseId,
      });
    }

    if (onClose) {
      onClose();
    }

    this.reset();
  }

  public handleSkip() {
    const { name } = this.state;
    const { intl, onClose, createFloorplan, warehouseId } = this.props;

    loadImage('/assets/empty_floorplan.png', (err, image) => {
      if (image) {
        urltoFile(image.src, 'default_fp.png', 'image/png').then((file) => {
          const scale = 10;
          const transformationMatrix: TransformationMatrix2D = [
            [1, 0, image.width / 2 / scale],
            [0, 1, image.height / 2 / scale],
            [0, 0, 1],
          ];

          createFloorplan({
            intl,
            properties: {
              boundaries: [],
              exteriorBoundaries: [],
              geoMapping: [],
              image: file,
              name,
              obstacles: [],
              racks: [],
              scale,
              transformationMatrix,
            },
            warehouseId,
          });
        });
      }
    });

    if (onClose) {
      onClose();
    }

    this.reset();
  }

  public reset() {
    this.setState({
      id: undefined,
      name: '',
    });
  }

  public render() {
    const { id, name } = this.state;

    const nameError = name.length === 0;

    const buttonDisabled = nameError;

    return (
      <Dialog fullWidth maxWidth="sm" open>
        <DialogTitle>
          {id ? (
            <FormattedMessage
              id="dashboard.forms.floorplanform.title_edit"
              defaultMessage="Edit Warehouse Floor plan {name}"
              values={{ name }}
            />
          ) : (
            <FormattedMessage
              id="dashboard.forms.floorplanform.title_create"
              defaultMessage="Add Warehouse Floor plan"
            />
          )}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth required>
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.forms.floorplanform.label_name"
                  defaultMessage="Name"
                />
              }
              variant="standard"
              value={name}
              onChange={this.handleChange('name')}
              helperText={
                <FormattedMessage
                  id="dashboard.forms.floorplanform.field_name_helper"
                  defaultMessage="Required"
                />
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            <FormattedMessage
              id="dashboard.forms.floorplanform.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          {!id && (
            <Button
              onClick={this.handleSkip}
              variant="contained"
              color="secondary"
              disabled={buttonDisabled}
            >
              <FormattedMessage
                id="dashboard.forms.floorplanform.button_skip"
                defaultMessage="Create & Skip Configuration"
              />
            </Button>
          )}
          <Button
            onClick={this.handleSubmit}
            variant="contained"
            color={id ? 'secondary' : 'primary'}
            disabled={buttonDisabled}
          >
            {id ? (
              <FormattedMessage
                id="dashboard.forms.floorplanform.button_edit"
                defaultMessage="Edit"
              />
            ) : (
              <FormattedMessage
                id="dashboard.forms.floorplanform.button_create"
                defaultMessage="Create"
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(FloorplanFormDialog);
