import React, { FunctionComponent } from 'react';
import { IntlShape } from 'react-intl';

import CreateOrEditFloorplan from './CreateOrEditFloorplan';

interface IProps {
  createFloorplan: (intl: IntlShape, name: string, image?: File) => void;
  loading: boolean;
  onBack: () => void;
  onSkip: (name: string, imageSrc?: string) => void;
}

const CreateFloorplan: FunctionComponent<IProps> = ({
  loading,
  createFloorplan,
  onBack,
  onSkip,
}: IProps) => (
  <CreateOrEditFloorplan
    onNext={createFloorplan}
    loading={loading}
    onBack={onBack}
    onSkip={onSkip}
  />
);

export default CreateFloorplan;
