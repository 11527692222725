import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { flatten, max, min } from 'lodash';

import {
  fetchTasks,
  fetchEmployees,
  fetchTeams,
  openTaskForm,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import { Task } from '@models/Task';
import { Date as LogDate, timeZone } from '@dashboard_utils/index';
import { getWarehouseById } from '@selectors/warehouses';
import TasksTab from './TasksTab';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;

  const filter = state.filter.filters[filterId] || {};

  const tasks = Object.values(state.tasks.tasks).filter((d) =>
    d.warehouseId === filter.warehouseId &&
    d.floorplanId === filter.floorplanId &&
    (
      !(filter.employeeIds || []).length ||
      !!d.employees.find((eId) => filter.employeeIds?.find((id) => id === eId))
    )
  );
  const warehouseTz = (
      getWarehouseById(
        state,
        filter.warehouseId || '',
      ) || {}
    ).timezone || timeZone;

  return {
    filterId,

    warehouseId: filter.warehouseId || '',
    floorplanId: filter.floorplanId || '',
    language: getActiveLanguage(state),
    formOpen: state.tasks.form.active,

    endDate: new LogDate(warehouseTz, max(flatten(tasks.map((t) =>
      [new Date(t.created).getTime()].concat(t.events.map((e) => new Date(e.created).getTime()))) || 0))),
    startDate: new LogDate(warehouseTz, min(flatten(tasks.map((t) => 
      [new Date(t.created).getTime()].concat(t.events.map((e) => new Date(e.created).getTime()))) || 0))),
    warehouseTz,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openForm: (data?: Task) => dispatch(openTaskForm(data)),
  fetchTasks: (shallow?: boolean) =>
    dispatch(fetchTasks.request({ shallow })),
  fetchEmployees: () =>
    dispatch(fetchEmployees.request({ shallow: true })),
  fetchTeams: () =>
    dispatch(fetchTeams.request({ shallow: true })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TasksTab)
);
