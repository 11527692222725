import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CropFreeIcon from '@mui/icons-material/CropFree';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import CommentIcon from '@mui/icons-material/Comment';
import LaunchIcon from '@mui/icons-material/Launch';
import clsx from 'clsx';
import React, { FunctionComponent, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { PRIMARY_COLOR } from '@app/utils/colors';
import { withRouter } from '@app/utils/withRouter';
import { Date, timeZone } from '@dashboard_utils/index';
import Account from '@models/Account';
import AccountMeta from '@models/AccountMeta';

export enum TabType {
  APPLICATIONS = 'applications',
  MANAGER = 'manager',
  HELP = 'help',
  UTILS = 'utils',
}

export interface ITab {
  id: string;
  shortcutColor?: string;
  icon: ReactElement<any>;
  link: string;
  path: string;
  title: ReactElement<any>;
  type: TabType;
}

interface IProps {
  account?: Account;
  accounts: AccountMeta[];
  open: boolean;
  language: string;
  shortcuts: Record<string, boolean>;
  tabs: ITab[];
  toggleMenu: (open: boolean) => void;
  theme: 'light' | 'dark';
  updateShortcut: (id: string) => void;
  activeChat: boolean;
  toggleChat: () => void;
  createScan: (data: string, x: number, y: number, z: number) => void;

  router: any;
}

const scanner = (callback: (data: string) => void) => {
  // @ts-ignore
  if (window.MessageInvoker) {
    // @ts-ignore
    window.MessageInvoker.postMessage('scan');
  }

  const interval = setInterval(() => {
    // @ts-ignore
    const data = window.nativeData;

    if (data) {
      // @ts-ignore
      window.nativeData = undefined;

      clearInterval(interval);

      callback(data);
    }
  }, 200);

  setTimeout(() => {
    if (interval) {
      clearInterval(interval);
    }
  }, 120000);
};

const Menu: FunctionComponent<IProps> = ({
  account = undefined,
  accounts,
  router,
  language,
  theme,
  open,
  shortcuts,
  toggleMenu,
  tabs,
  updateShortcut,
  activeChat,
  toggleChat,
  createScan
}: IProps) => {
  let monitorURL = 'https://console.logidot.com';
  if (window.location.origin.indexOf('localhost') !== -1) {
    monitorURL = 'http://localhost:3001';
  } else if (window.location.origin.indexOf('dashboard.dev') !== -1) {
    monitorURL = 'https://console.dev.sonodot.com';
  }

  let unread = 0;
  accounts.forEach((a) => {
    unread += a.messagesUnseen;
  });

  const handleShortcutClick = (event: any, id: string) => {
    event.preventDefault();
    event.stopPropagation();

    updateShortcut(id);
  };

  const getTabs = (type: string) =>
    tabs
      .filter((tab) => tab.type === type)
      .map((tab) => (
        <ListItem
          key={tab.id}
          button
          component={Link}
          to={tab.link || tab.path}
          onClick={() => toggleMenu(false)}
        >
          <ListItemIcon className="menu-icon-container">
            <>
              {tab.icon}
              <IconButton
                edge="end"
                aria-label="delete"
                className="menu-shortcut-btn"
                style={{
                  color: shortcuts[tab.id] ? PRIMARY_COLOR : undefined,
                }}
                onClick={(event: any) => handleShortcutClick(event, tab.id)}
              >
                <LaunchIcon style={{ fontSize: '0.75rem' }} />
              </IconButton>
            </>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle1"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {tab.title}
              </Typography>
            }
          />
        </ListItem>
      ));

  const footerClass = clsx('menu-footer', {
    dark: theme === 'dark',
  });

  return (
    <Paper
      onMouseLeave={() => toggleMenu(false)}
      className="menu"
      style={{ display: open ? 'block' : 'none' }}
    >
      <IconButton className="menu-close" onClick={() => toggleMenu(false)}>
        <CloseIcon />
      </IconButton>
      <Grid container>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div">
                <FormattedMessage
                  id="dashboard.menu.manager"
                  defaultMessage="Manager"
                />
              </ListSubheader>
            }
          >
            {getTabs('manager')}
          </List>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div">
                <FormattedMessage
                  id="dashboard.menu.applications"
                  defaultMessage="Applications"
                />
              </ListSubheader>
            }
          >
            {getTabs('applications')}
          </List>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div">
                <FormattedMessage
                  id="dashboard.menu.help"
                  defaultMessage="Help"
                />
              </ListSubheader>
            }
          >
            {getTabs('help')}
          </List>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div">
                <FormattedMessage
                  id="dashboard.menu.utils"
                  defaultMessage="Utils"
                />
              </ListSubheader>
            }
          >
            <ListItem
              button
              component={Link}
              to={`/${language}/dashboard`}
              onClick={() => toggleMenu(false)}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle1"
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <FormattedMessage
                      id="dashboard.itab.overview"
                      defaultMessage="Overview"
                    />
                  </Typography>
                }
              />
            </ListItem>
            {getTabs('utils')}
            {
              // @ts-ignore
              !!window.MessageInvoker && (
                <ListItem
                  button
                  style={{
                    backgroundColor: 'rgba(63, 81, 181, 0.1)',
                    marginBottom: '20px',
                    paddingLeft: '16px',
                  }}
                  onClick={() => {
                    toggleMenu(false);

                    scanner((data: string) => {
                      router.navigate(
                        `/${language}/dashboard/assets/assetid/${data}/management/tab/0`
                      );
                    });
                  }}
                >
                  <ListItemIcon>
                    <CropFreeIcon />
                  </ListItemIcon>
                  <Typography
                    variant="subtitle1"
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <FormattedMessage
                      id="dashboard.itab.scanasset"
                      defaultMessage="Scan Asset"
                    />
                  </Typography>
                </ListItem>
              )
            }
            {
              // @ts-ignore
              !!window.MessageInvoker && (
                <ListItem
                  button
                  style={{
                    backgroundColor: 'rgba(63, 81, 181, 0.1)',
                    marginBottom: '20px',
                    paddingLeft: '16px',
                  }}
                  onClick={() => {
                    toggleMenu(false);
                    
                    scanner((data: string) => {
                      createScan(data, 0, 0, 0);
                    });
                  }}
                >
                  <ListItemIcon>
                    <CropFreeIcon />
                  </ListItemIcon>
                  <Typography
                    variant="subtitle1"
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <FormattedMessage
                      id="dashboard.itab.scanlocation"
                      defaultMessage="Scan"
                    />
                  </Typography>
                </ListItem>
              )
            }
            {(account || ({} as Account)).admin ? (
              <ListItem
                button
                component="a"
                href={monitorURL}
                onClick={() => toggleMenu(false)}
              >
                <ListItemIcon style={{ marginRight: 0 }}>
                  <DesktopWindowsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      Monitor Console
                    </Typography>
                  }
                />
              </ListItem>
            ) : null}
            <ListItem
              button
              onClick={toggleChat}
            >
              <ListItemIcon style={{ marginRight: 0 }}>
                <Badge badgeContent={Number(unread || 0)} max={999} color="secondary">
                  <CommentIcon style={{ opacity: activeChat ? 1 : 0.3 }} />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle1"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    Chats
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <div className={footerClass}>
        <Typography variant="caption">
          <span>Copyright © Sonodot Ltd </span>
          {new Date(timeZone).getFullYear()}
        </Typography>
      </div>
    </Paper>
  );
};

export default withRouter(Menu);
