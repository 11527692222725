import React, { Component } from 'react';

import { EventPeriod, EventPeriods } from '@models/EventActivity';
import { Notification } from '@models/Notification';
import RuleAlert from '../RuleAlertsSlider/RuleAlert';
import EventActivityElemn from './EventActivity';

/**
 * @description Converts hex based colors to rgb
 * @param       {string} hex Color hex as #FFAABB
 */
const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    return {
      b: parseInt(result[3], 16),
      g: parseInt(result[2], 16),
      r: parseInt(result[1], 16),
    };
  }

  return {};
};

interface IEventActivity extends EventPeriod {
  show?: boolean;
  positionX?: number;
}

export interface IProps {
  assetName?: string;
  color?: string;
  eventsActivity: EventPeriods;
  ruleAlerts?: Notification[];
  value: number;
  min: number;
  max: number;
  onClick: (event: any) => void;
}

class AssetSlider extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.getRelativePosition = this.getRelativePosition.bind(this);
    this.getRelativeWidth = this.getRelativeWidth.bind(this);
  }

  public shouldComponentUpdate(nextProps: any) {
    const { eventsActivity, max, min, ruleAlerts } = this.props;

    return (
      JSON.stringify(eventsActivity) !==
        JSON.stringify(nextProps.eventsActivity) ||
      JSON.stringify(ruleAlerts) !== JSON.stringify(nextProps.ruleAlerts) ||
      nextProps.min !== min ||
      nextProps.max !== max
    );
  }

  /**
   * @description Calculates the relative position in the event line for an event starting with the given ts
   * @param       {number} start Event start ts
   */
  private getRelativePosition(start: number) {
    const { min, max } = this.props;

    return ((start - min) * 100) / (max - min);
  }

  /**
   * @description Calculates the relative with of an event element occurring in the given interval
   * @param       {number} start Start ts of the event
   * @param       {number} end   End ts of the event
   */
  private getRelativeWidth(start: number, end: number) {
    const { min, max } = this.props;

    const interval = end - start;

    return (interval * 100) / (max - min);
  }

  public render() {
    const { assetName, color, eventsActivity, onClick, ruleAlerts } =
      this.props;

    const hexColor = color || '#0066ff';
    const rgbColor = hexToRgb(hexColor);

    const activityList = eventsActivity.filter((ea) => {
      let left = this.getRelativePosition(ea.interval_start);
      let width = this.getRelativeWidth(ea.interval_start, ea.interval_end);

      // if chunk is bigger that right limit, slice it to fit
      if (left + width > 100 && left <= 100) {
        width = 100 - left;
      }

      // if chunk is bellow left limit, slice it to right size relative to 0
      if (left < 0 && left + width >= 0) {
        width -= Math.abs(left);
        left = 0;
      }

      return left <= 100 && left >= 0;
    });
    const ruleList = (ruleAlerts || []).filter((ea) => {
      const left = this.getRelativePosition(ea.ts);

      return left <= 100 && left >= 0;
    });

    return (
      (activityList.length > 0 || ruleList.length > 0) && (
        <div className="slider-strip">
          <div
            className="event-slider"
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            aria-label=""
            tabIndex={0}
          >
            {eventsActivity.map((ea: IEventActivity, index: number) => {
              let left = this.getRelativePosition(ea.interval_start);
              let width = this.getRelativeWidth(
                ea.interval_start,
                ea.interval_end
              );

              // if chunk is bigger that right limit, slice it to fit
              if (left + width > 100 && left <= 100) {
                width = 100 - left;
              }

              // if chunk is bellow left limit, slice it to right size relative to 0
              if (left < 0 && left + width >= 0) {
                width -= Math.abs(left);
                left = 0;
              }

              return (
                left <= 100 &&
                left >= 0 && (
                  <EventActivityElemn
                    key={index}
                    assetName={assetName}
                    color={`rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b},${
                      !ea.active ? 0.5 : 1
                    })`}
                    left={left}
                    active={ea.active}
                    width={width}
                  />
                )
              );
            })}
          </div>
          <div
            className="rule-alerts-slider"
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            aria-label=""
            tabIndex={0}
          >
            {(ruleAlerts || []).map((ea, index) => {
              const left = this.getRelativePosition(ea.ts);

              return (
                left <= 100 &&
                left >= 0 && <RuleAlert key={index} alert={ea} left={left} />
              );
            })}
          </div>
        </div>
      )
    );
  }
}

export default AssetSlider;
