import React from 'react';
import { FormattedMessage } from 'react-intl';

export function getAssetTypeName(type: string) {
  switch (type) {
    case 'barcode_scanner':
      return (
        <FormattedMessage
          id="dashboard.forms.assetform.field_type_barcode_scanner"
          defaultMessage="Barcode Scanner"
        />
      );
    case 'fixedobject':
      return (
        <FormattedMessage
          id="dashboard.forms.assetform.field_type_fixedobject"
          defaultMessage="Fixed Object"
        />
      );
    case 'forklift':
      return (
        <FormattedMessage
          id="dashboard.forms.assetform.field_type_forklift"
          defaultMessage="Forklift"
        />
      );
    case 'pallet_truck':
      return (
        <FormattedMessage
          id="dashboard.forms.assetform.field_type_pallet_truck"
          defaultMessage="Pallet Truck"
        />
      );
    case 'picker':
      return (
        <FormattedMessage
          id="dashboard.forms.assetform.field_type_picker"
          defaultMessage="Picker"
        />
      );
    case 'rollcage':
      return (
        <FormattedMessage
          id="dashboard.forms.assetform.field_type_rollcage"
          defaultMessage="Rollcage"
        />
      );
    default:
      return null;
  }
}

export default {};
