import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import clsx from 'clsx';
import React, { Fragment, FunctionComponent } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { confirmDialog, deleteAsset, openAssetForm } from '@actions/index';
import { Date, timeZone } from '@dashboard_utils/index';
import Account from '@models/Account';
import Asset from '@models/Asset';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import { SensorAssociation } from '@models/SensorAssociation';
import Warehouse, { Warehouses } from '@models/Warehouse';
import { IAppState } from '@reducers/index';
import { hasPermission } from '@selectors/accounts';
import { getCompleteAsset } from '@selectors/assets';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import CardListPage, { ICustomRow, IHeaderActions } from '../../CardListPage';
import { getAssetTypeName } from '../../../forms/AssetForm/utils';
import { findWarehouseByFloorplanId } from '../../../utils/floorplanUtils';
import { PRIMARY_COLOR } from '../../../utils/colors';
import AssetCard from './AssetCard';

interface IProps {
  account: Account;
  extendGrid: boolean;
  router: any;
  floorplanId: string;
  floorplans: IncompleteFloorplans;
  intl: IntlShape;
  language: string;
  warehouses: Warehouses;
}

const messages = {
  deleteMsg: {
    defaultMessage: 'Are you sure that you want to DELETE this asset?',
    id: 'dashboard.assets.delete_message',
  },
  thColor: {
    defaultMessage: 'Color',
    id: 'dashboard.assets.table.color',
  },
  thCurrentSensor: {
    defaultMessage: 'Current Sensor',
    id: 'dashboard.assets.table.currentsensor',
  },
  thFloorplanName: {
    defaultMessage: 'Floorplan',
    id: 'dashboard.assets.table.floorplanname',
  },
  thName: {
    defaultMessage: 'Name',
    id: 'dashboard.assets.table.name',
  },
  thTags: {
    defaultMessage: 'Labels',
    id: 'dashboard.assets.table.tags',
  },
  thType: {
    defaultMessage: 'Type',
    id: 'dashboard.assets.table.type',
  },
  thWarehouseName: {
    defaultMessage: 'Warehouse',
    id: 'dashboard.assets.table.warehousename',
  },
};

const mapStateToProps = (state: IAppState, props: IProps) => {
  const {
    account,
    extendGrid,
    floorplanId,
    floorplans,
    router,
    intl,
    language,
    warehouses,
  } = props;

  const Actions: FunctionComponent<IHeaderActions> = (
    properties: IHeaderActions
  ) => {
    const { handleAdd } = properties;

    return (
      <>
        {hasPermission(account, 'add_asset') && (
          <Button id="add-asset" onClick={handleAdd}>
            <AddIcon style={{ marginRight: '5px' }} />
            <FormattedMessage
              id="dashboard.assets.addasset"
              defaultMessage="Add Asset"
            />
          </Button>
        )}
      </>
    );
  };

  const CustomRow: FunctionComponent<ICustomRow> = (properties: ICustomRow) => {
    const {
      anchorEl,
      columns,
      data,
      dataIndex,
      handleDelete,
      handleEdit,
      handleExpandableRow,
      handleMenu,
      handleMenuClose,
      rowData,
      rowIndex,
    } = properties;

    const colSpan = rowData.length + 1;

    const chevronRotation = clsx('chevron', {
      rotate: (anchorEl[dataIndex] || {}).expanded === true,
    });

    const warehouse =
      findWarehouseByFloorplanId(
        warehouses,
        floorplans,
        data[dataIndex].floorplanId
      ) || ({} as Warehouse);
    const warehouseTz = warehouse.timezone || timeZone;

    const handleExpandableRowProp = () => handleExpandableRow(dataIndex);
    const handleMenuProp = (event: any) => handleMenu(dataIndex, event);
    const handleMenuCloseProp = () => handleMenuClose(dataIndex);
    const handleEditProp = () => handleEdit(data[dataIndex], dataIndex);
    const handleDeleteProp = () => handleDelete(data[dataIndex].id, dataIndex);

    const goToManagement = () => {
      router.navigate(
        `/${language}/dashboard/assets/assetid/${data[dataIndex].id}/management/tab/0`
      );
    };

    return (
      <Fragment key={rowIndex}>
        <TableRow key={rowIndex}>
          {columns[0] && (
            <TableCell>
              <ChevronRightIcon
                className={chevronRotation}
                onClick={handleExpandableRowProp}
                style={{ cursor: 'pointer' }}
              />
            </TableCell>
          )}
          {columns[1] && <TableCell>{rowData[1]}</TableCell>}
          {columns[2] && <TableCell>{getAssetTypeName(rowData[2])}</TableCell>}
          {columns[3] && (
            <TableCell>
              {rowData[3] ? (
                <div
                  style={{
                    backgroundColor: rowData[3],
                    height: '20px',
                    width: '50px',
                  }}
                />
              ) : null}
            </TableCell>
          )}
          {columns[4] && <TableCell>{rowData[4]}</TableCell>}
          {columns[5] && <TableCell>{rowData[5]}</TableCell>}
          {columns[6] && (
            <TableCell>
              {rowData[6].map((t: string, index: number) => (
                <Chip
                  key={index}
                  style={{
                    backgroundColor: rowData[3] || PRIMARY_COLOR,
                    color: 'white',
                  }}
                  size="small"
                  label={t}
                />
              ))}
            </TableCell>
          )}
          {columns[7] && <TableCell>{rowData[7]}</TableCell>}
          {columns[8] && (
            <TableCell>
              <div style={{ float: 'right' }}>
                <IconButton
                  aria-label="More"
                  aria-owns={
                    (anchorEl[dataIndex] || {}).menu ? 'long-menu' : undefined
                  }
                  aria-haspopup="true"
                  onClick={handleMenuProp}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={(anchorEl[dataIndex] || {}).menu}
                  open={!!(anchorEl[dataIndex] || {}).menu}
                  onClose={handleMenuCloseProp}
                >
                  <MenuItem onClick={goToManagement}>
                    <ListItemIcon>
                      <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <FormattedMessage
                          id="dashboard.assets.assetmanagement"
                          defaultMessage="Asset Management"
                        />
                      }
                    />
                  </MenuItem>
                  {hasPermission(account, 'edit_asset') && (
                    <MenuItem onClick={handleEditProp}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <FormattedMessage
                            id="dashboard.assets.editasset"
                            defaultMessage="Edit"
                          />
                        }
                      />
                    </MenuItem>
                  )}
                  {hasPermission(account, 'del_asset') && (
                    <MenuItem onClick={handleDeleteProp}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <FormattedMessage
                            id="dashboard.assets.deleteasset"
                            defaultMessage="Delete"
                          />
                        }
                      />
                    </MenuItem>
                  )}
                </Menu>
              </div>
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={colSpan}
            padding="none"
            style={{ borderBottom: 'none' }}
          >
            <Collapse in={(anchorEl[dataIndex] || {}).expanded}>
              <div
                style={{
                  backgroundColor: (anchorEl[dataIndex] || {}).expanded
                    ? '#FEFEFE'
                    : undefined,
                  border: '1px solid #F9F9F9',
                  borderTop: 'none',
                  padding: '14px 40px 14px 16px',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          id="dashboard.assets.sensorassociations.from"
                          defaultMessage="From"
                        />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage
                          id="dashboard.assets.sensorassociations.to"
                          defaultMessage="To"
                        />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage
                          id="dashboard.assets.sensorassociations.sensor"
                          defaultMessage="Sensor"
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data[dataIndex].sensorAssociations.map(
                      (a: SensorAssociation, index: number) => {
                        const start = new Date(warehouseTz, a.from).format();

                        let end = null;
                        if (a.to) {
                          end = new Date(warehouseTz, a.to).format();
                        }

                        return (
                          <TableRow key={index} hover={false}>
                            <TableCell>{start}</TableCell>
                            <TableCell>{end}</TableCell>
                            <TableCell>{a.physicalAddress}</TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return {
    Actions,
    CustomRow,
    Item: AssetCard,
    columns: [
      { label: ' ', name: 'expanded', options: { interaction: false } },
      { label: intl.formatMessage(messages.thName), name: 'name' },
      { label: intl.formatMessage(messages.thType), name: 'type' },
      { label: intl.formatMessage(messages.thColor), name: 'color' },
      {
        label: intl.formatMessage(messages.thWarehouseName),
        name: 'warehouseName',
      },
      {
        label: intl.formatMessage(messages.thFloorplanName),
        name: 'floorplanName',
      },
      {
        label: intl.formatMessage(messages.thTags),
        name: 'tags',
      },
      {
        label: intl.formatMessage(messages.thCurrentSensor),
        name: 'physicalAddress',
      },
      { label: ' ', name: 'menu', options: { interaction: false } },
    ],
    data: Object.values(state.assets.assets)
      .filter((a) => a.floorplanId === floorplanId)
      .map((asset) => getCompleteAsset(warehouses, floorplans, asset)),
    deleteMsg: intl.formatMessage(messages.deleteMsg),
    extendGrid,
    loading: state.assets.loading,
    tableId: 'assets',
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  deleteAction: (id: string, intl: IntlShape) =>
    dispatch(deleteAsset.request({ id, intl })),
  openForm: (asset?: Asset) => dispatch(openAssetForm(asset)),
});

export default injectIntl(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(CardListPage)
);
