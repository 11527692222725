import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { FullOrder } from '@models/Order';
import Loading from '@app/common/Loading';
import LoadingButton from '@app/common/LoadingButton';
import { IAddOrderToBasket, RoutingTypes } from '@actions/routing';
import { takeLatest } from 'redux-saga/effects';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
  showCurrentBasket: () => void;
  loading: boolean;
  basketLoading: boolean;
  order?: FullOrder;
  addOrderToCurrentBasket: (properties: IAddOrderToBasket) => void;
}

const BottomSheet = (props: IProps) => {
  const { open, onClose, loading, order, addOrderToCurrentBasket, basketLoading, showCurrentBasket } =  props;

  const [orderConfirmationOpen, setOrderConfirmation] = React.useState(false);

  const displayPickConfirm = () => {
    setOrderConfirmation(true);
  }

  const pickConfirm = () => {
    setOrderConfirmation(false);

    onClose();
    showCurrentBasket();
  }

  const hidePickConfirm = () => {
    setOrderConfirmation(false);
  }

  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            padding: 2,
            minHeight: '30vh'
          },
        }}
      >
        <Loading loading={loading}>
          <Box display="flex" justifyContent="middle" alignItems="center" mb={2}>
            <Typography variant="h6">{order?.orderNo}</Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button fullWidth color="primary" variant="contained" size="large" onClick={displayPickConfirm}>Pick Order</Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth color="error" variant="contained" size="large">Add to Batch</Button>
            </Grid>
          </Grid>

          <Box mt={4}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Order Items</StyledTableCell>
                    <StyledTableCell>Quantity</StyledTableCell>
                    <StyledTableCell>Location</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(order?.items || []).map((row) => (
                    <StyledTableRow key={row.itemId}>
                      <StyledTableCell>{row.itemId}</StyledTableCell>
                      <StyledTableCell>{row.quantity}</StyledTableCell>
                      <StyledTableCell>{row.location.name}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Loading>
      </Drawer>

      <Dialog
        open={orderConfirmationOpen}
        onClose={hidePickConfirm}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event?.preventDefault();

            addOrderToCurrentBasket({ orderId: order?.id || '' });

            pickConfirm();
          },
        }}
      >
        <DialogTitle>You are about to pick order</DialogTitle>
        <DialogContent>
          <Typography variant="h6">{order?.orderNo}</Typography>
          <Box mt={2}>
            <Typography variant="body1">
              Are you certain you wish to proceed?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton variant="contained" color="primary" type="submit" loading={basketLoading}>Confirm Order</LoadingButton>
          <Button variant="contained" color="error" onClick={hidePickConfirm}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BottomSheet;
