import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ClearIcon from '@mui/icons-material/Clear';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IUpdateFloorplan } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';

const handleFileUploadClick = (event: any) => {
  event.stopPropagation();

  document.getElementById('update-floorplan-file-upload')!.click();
};

interface IProps {
  floorplanId: string;
  image?: string;
  intl: IntlShape;
  loading: boolean;
  updateFloorplan: (properties: IUpdateFloorplan) => any;
  warehouseId: string;
}

interface IState {
  file?: File;
  image?: string;
  hasChanges: boolean;
}

class Image extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { image: props.image, file: undefined, hasChanges: false };

    this.handleImageRemoval = this.handleImageRemoval.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleFileUpload(event: any) {
    const { files } = event.target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (e.target !== null) {
          this.setState({ image: e.target.result });
        }
      };

      reader.readAsDataURL(files[0]);

      this.setState({
        file: files[0],
        hasChanges: true,
      });
    }
  }

  public handleImageRemoval() {
    this.setState({ file: undefined, image: undefined });
  }

  public handleSubmit() {
    const { file } = this.state;
    const { floorplanId, intl, warehouseId, updateFloorplan } = this.props;

    this.setState({ hasChanges: false }, () =>
      updateFloorplan({
        floorplanId,
        intl,
        properties: { image: file },
        warehouseId,
      })
    );
  }

  public render() {
    const { loading } = this.props;
    const { image, hasChanges } = this.state;

    return (
      <Card className="step-card">
        <CardContent style={{ height: 'calc(100% - 52px)' }}>
          {image ? (
            <div
              style={{
                height: '100%',
                position: 'relative',
                textAlign: 'center',
                width: '100%',
              }}
            >
              <ClearIcon
                color="error"
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                }}
                onClick={this.handleImageRemoval}
              />
              <img
                style={{
                  border: '1px solid black',
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
                src={image}
                alt="Floorplan"
              />
            </div>
          ) : (
            <div style={{ height: '100%', width: '100%', textAlign: 'center' }}>
              <label htmlFor="update-floorplan-file-upload">
                <Button
                  style={{ width: '300px', height: '300px' }}
                  variant="outlined"
                  color="primary"
                  onClick={handleFileUploadClick}
                >
                  <FormattedMessage
                    id="dashboard.forms.floorplanform.button_uploadfloorplan"
                    defaultMessage="Upload Floor plan"
                  />
                </Button>
                <input
                  id="update-floorplan-file-upload"
                  accept="image/*"
                  type="file"
                  style={{ display: 'none' }}
                  onInput={this.handleFileUpload}
                />
              </label>
            </div>
          )}
        </CardContent>
        <CardActions
          style={{ borderTop: '1px solid #e1e1e1', justifyContent: 'flex-end' }}
        >
          <LoadingButton
            loading={loading}
            onClick={this.handleSubmit}
            color="secondary"
            variant="contained"
            disabled={hasChanges !== true}
          >
            <FormattedMessage
              id="dashboard.forms.floorplanconfigurationform.button_save"
              defaultMessage="Save Changes"
            />
          </LoadingButton>
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(Image);
