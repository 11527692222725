import { NativeModules, Platform } from 'react-native-web';

import { IAppState } from '@reducers/index';
import { IMapState } from '@reducers/app';
import { getAccountMeta } from './accounts';
import { SupporttedLanguages } from '../types';

const getLocale = () => {
  let locale = 'en-GB';
  if (Platform.OS === 'ios') {
    locale =
      NativeModules.SettingsManager.settings.AppleLocale ||
      NativeModules.SettingsManager.settings.AppleLanguages[0];
  } else if (Platform.OS === 'android') {
    locale = NativeModules.I18nManager.localeIdentifier;
  }

  return locale;
};

export function getActiveLanguage(state: IAppState): SupporttedLanguages {
  const accountMeta = getAccountMeta(state.authentication.account);

  const locale = getLocale();

  return (state.app.language ||
    (accountMeta.locale || '').split('-')[0] ||
    locale.replace('_', '-').split('-')[0] ||
    'en') as SupporttedLanguages;
}

export function getSelectedLocale(state: IAppState): string {
  const accountMeta = getAccountMeta(state.authentication.account);

  const locale = getLocale();

  if (!state.app.language) {
    return accountMeta.locale || locale.replace('_', '-');
  }

  if (state.app.language === 'de') {
    return 'de-DE';
  }

  if (state.app.language === 'en') {
    return 'en-GB';
  }

  if (state.app.language === 'es') {
    return 'es-ES';
  }

  if (state.app.language === 'fr') {
    return 'fr-FR';
  }

  if (state.app.language === 'it') {
    return 'it-IT';
  }

  if (state.app.language === 'pt') {
    return 'pt-PT';
  }

  if (state.app.language === 'ro') {
    return 'ro-RO';
  }

  return 'en-GB';
}

export function getMapById(state: IAppState, id: string): IMapState {
  return state.app.map[id];
}
