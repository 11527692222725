import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { ConfigurationFileTypes, IDeleteConfigFile } from '@actions/index';
import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import { Date, timeZone } from '@dashboard_utils/index';
import { ConfigFile, ConfigFiles } from '@models/ConfigFile';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import { Warehouses } from '@models/Warehouse';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '../../../../dialogs/ConfirmDialog/types';
import ConfigFileFormDialog from '../../../../forms/ConfigFileForm';
import { messages, streamProcessingMessages } from '../messages';

interface IProps {
  confirm: (properties: IConfirmDialog) => void;
  configFilesHardware: ConfigFiles;
  configFilesProcessing: ConfigFiles;
  deleteConfigFile: (properties: IDeleteConfigFile) => void;
  fetchConfigFiles: (type: string) => void;
  intl: IntlShape;
  loadingHardware: boolean;
  loadingProcessing: boolean;
  floorplans: IncompleteFloorplans;
  warehouses: Warehouses;
}

interface IState {
  activeConfigFile?: ConfigFile;
  allowEdition: boolean;
  columns: ITableColumn[];
  itemMenuInfo: Record<string, any>;
  openConfigFileForm: boolean;
  tab: number;
}

const tableMessages = {
  thDate: {
    defaultMessage: 'Date',
    id: 'dashboard.settings.configurationfiles.th_date',
  },
  thFile: {
    defaultMessage: 'File',
    id: 'dashboard.settings.configurationfiles.th_file',
  },
  thFloorplan: {
    defaultMessage: 'Floorplan',
    id: 'dashboard.settings.configurationfiles.th_floorplan',
  },
  thOptions: {
    defaultMessage: 'Options',
    id: 'dashboard.settings.configurationfiles.th_options',
  },
  thSensorGroup: {
    defaultMessage: 'Sensor Group',
    id: 'dashboard.settings.configurationfiles.th_sensorgroup',
  },
  thVersion: {
    defaultMessage: 'Version',
    id: 'dashboard.settings.configurationfiles.th_version',
  },
  thWarehouse: {
    defaultMessage: 'Warehouse',
    id: 'dashboard.settings.configurationfiles.th_warehouse',
  },
};

class ConfigurationFiles extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      allowEdition: false,
      columns: [
        {
          label: intl.formatMessage(tableMessages.thWarehouse),
          name: 'warehouseName',
        },
        {
          label: intl.formatMessage(tableMessages.thFloorplan),
          name: 'floorplanName',
        },
        {
          label: intl.formatMessage(tableMessages.thSensorGroup),
          name: 'sensorGroupId',
        },
        { label: intl.formatMessage(tableMessages.thFile), name: 'filename' },
        { label: intl.formatMessage(tableMessages.thDate), name: 'created' },
        { label: intl.formatMessage(tableMessages.thVersion), name: 'version' },
        {
          label: intl.formatMessage(tableMessages.thOptions),
          name: 'options',
          options: {
            customHeadRender: (columnMeta) => (
              <TableCell key={columnMeta.index} style={{ textAlign: 'right' }}>
                {columnMeta.label}
              </TableCell>
            ),
            interaction: false,
          },
        },
      ],
      itemMenuInfo: {},
      openConfigFileForm: false,
      tab: 0,
    };

    this.customRowRender = this.customRowRender.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleNewFileForm = this.handleNewFileForm.bind(this);
    this.handleFileFormClose = this.handleFileFormClose.bind(this);
  }

  public componentDidMount() {
    const { fetchConfigFiles } = this.props;

    fetchConfigFiles(ConfigurationFileTypes.PROCESSING);
  }

  public handleNewFileForm() {
    this.setState({
      activeConfigFile: undefined,
      allowEdition: true,
      openConfigFileForm: true,
    });
  }

  public handleFileFormClose() {
    this.setState({
      activeConfigFile: undefined,
      allowEdition: false,
      openConfigFileForm: false,
    });
  }

  public handleItemMenuClick(index: number) {
    return (event: any) => {
      const { itemMenuInfo } = this.state;

      itemMenuInfo[index] = event.target;

      this.setState({ itemMenuInfo });
    };
  }

  public handleItemMenuClose(index: number) {
    const { itemMenuInfo } = this.state;

    itemMenuInfo[index] = undefined;

    this.setState({ itemMenuInfo });
  }

  public handleFileView(index: number, configFile: ConfigFile) {
    const { itemMenuInfo } = this.state;

    itemMenuInfo[index] = undefined;

    this.setState({
      activeConfigFile: configFile,
      allowEdition: false,
      itemMenuInfo,
      openConfigFileForm: true,
    });
  }

  public handleFileEdition(index: number, configFile: ConfigFile) {
    const { itemMenuInfo } = this.state;

    itemMenuInfo[index] = undefined;

    this.setState({
      activeConfigFile: configFile,
      allowEdition: true,
      itemMenuInfo,
      openConfigFileForm: true,
    });
  }

  public handleFileDeletion(index: number, configFile: ConfigFile) {
    const { itemMenuInfo } = this.state;
    const { confirm, deleteConfigFile, intl } = this.props;

    itemMenuInfo[index] = undefined;

    this.setState({ itemMenuInfo }, () =>
      confirm({
        confirmType: ConfirmDialogActions.DELETE,
        message: intl.formatMessage(streamProcessingMessages.deleteMsg),
        onConfirmation: () => {
          deleteConfigFile({
            id: configFile.id,
            intl,
            type: ConfigurationFileTypes.PROCESSING,
          });
        },
      })
    );
  }

  public handleTabChange(event: any, tabNumber: number) {
    const { fetchConfigFiles } = this.props;

    fetchConfigFiles(
      tabNumber === 0
        ? ConfigurationFileTypes.PROCESSING
        : ConfigurationFileTypes.HARDWARE
    );

    this.setState({ tab: tabNumber });
  }

  public getTableData() {
    const {
      configFilesHardware,
      configFilesProcessing,
      floorplans,
      warehouses,
    } = this.props;
    const { tab } = this.state;

    const configFiles = tab === 0 ? configFilesProcessing : configFilesHardware;

    return configFiles.map((file) => {
      const warehouse = warehouses[file.warehouseId || ''] || {};
      const floorplan = floorplans[file.floorplanId || ''] || {};

      return {
        ...file,
        floorplanName: floorplan.name,
        warehouseName: warehouse.name,
      };
    });
  }

  public customRowRender(columns: boolean[], rowData: any, dataIndex: number) {
    const { intl } = this.props;
    const { itemMenuInfo, tab } = this.state;

    const data = this.getTableData();

    return (
      <TableRow key={dataIndex}>
        {columns[0] && <TableCell>{rowData[0]}</TableCell>}
        {columns[1] && <TableCell>{rowData[1]}</TableCell>}
        {columns[2] && <TableCell>{rowData[2]}</TableCell>}
        {columns[3] && <TableCell>{rowData[3]}</TableCell>}
        {columns[4] && (
          <TableCell>{new Date(timeZone, rowData[4]).format()}</TableCell>
        )}
        {columns[5] && <TableCell>{rowData[5]}</TableCell>}
        {columns[6] && (
          <TableCell style={{ textAlign: 'right' }}>
            <IconButton
              aria-label={intl.formatMessage(messages.more)}
              aria-owns={itemMenuInfo[dataIndex] ? 'long-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleItemMenuClick(dataIndex)}
            >
              <MoreVertIcon />
            </IconButton>
            {tab === 1 ? (
              <Menu
                id="long-menu"
                anchorEl={itemMenuInfo[dataIndex]}
                open={!!itemMenuInfo[dataIndex]}
                onClose={() => this.handleItemMenuClose(dataIndex)}
              >
                <MenuItem
                  onClick={() =>
                    this.handleFileView(dataIndex, data[dataIndex])
                  }
                >
                  <ListItemIcon>
                    <VisibilityIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.settings.configurationfiles.view"
                        defaultMessage="View file"
                      />
                    }
                  />
                </MenuItem>
              </Menu>
            ) : (
              <Menu
                id="long-menu"
                anchorEl={itemMenuInfo[dataIndex]}
                open={!!itemMenuInfo[dataIndex]}
                onClose={() => this.handleItemMenuClose(dataIndex)}
              >
                <MenuItem
                  onClick={() =>
                    this.handleFileEdition(dataIndex, data[dataIndex])
                  }
                >
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.settings.configurationfiles.edit"
                        defaultMessage="Edit file"
                      />
                    }
                  />
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    this.handleFileDeletion(dataIndex, data[dataIndex])
                  }
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.settings.configurationfiles.delete"
                        defaultMessage="Delete file"
                      />
                    }
                  />
                </MenuItem>
              </Menu>
            )}
          </TableCell>
        )}
      </TableRow>
    );
  }

  public render() {
    const { intl, loadingHardware, loadingProcessing } = this.props;
    const { activeConfigFile, allowEdition, columns, openConfigFileForm, tab } =
      this.state;

    const loading = tab === 0 ? loadingProcessing : loadingHardware;

    const data = this.getTableData();

    const title = (
      <FormattedMessage
        id="dashboard.settings.configurationfiles.title"
        defaultMessage="Configuration Files"
      />
    );

    const action =
      tab === 0 ? (
        <Button onClick={this.handleNewFileForm}>
          <AddIcon style={{ marginRight: '5px' }} />
          <FormattedMessage
            id="dashboard.settings.configurationfiles.add"
            defaultMessage="Add new file"
          />
        </Button>
      ) : null;

    return (
      <>
        <Card>
          <CardHeader action={action} title={title} />
          <CardContent>
            <Tabs
              indicatorColor="primary"
              onChange={this.handleTabChange}
              textColor="primary"
              variant="fullWidth"
              value={tab}
            >
              <Tab
                label={intl.formatMessage(
                  streamProcessingMessages.fileHardwareType
                )}
              />
              <Tab
                label={intl.formatMessage(
                  streamProcessingMessages.fileProcessingType
                )}
              />
            </Tabs>
            <Divider />
            <Loading loading={loading}>
              <div className="table-container">
                <MUITable
                  columns={columns}
                  customRowRender={this.customRowRender}
                  data={data}
                  tableId="configFiles"
                />
              </div>
            </Loading>
          </CardContent>
        </Card>
        {openConfigFileForm ? (
          <ConfigFileFormDialog
            allowEdition={allowEdition}
            onClose={this.handleFileFormClose}
            data={activeConfigFile}
          />
        ) : null}
      </>
    );
  }
}

export default injectIntl(ConfigurationFiles);
