import { connect } from 'react-redux';

import { SensorTypes } from '@models/Sensor';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import { getFloorplanSensorsByType } from '@selectors/sensors';
import { IAppState } from '@reducers/index';
import CoverageMap from './CoverageMap';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    mapImages: map.mapImages,
    paper: map.paper,

    floorplan: getFloorplanById(state, map.floorplanId)!,

    sensors: getFloorplanSensorsByType(
      state,
      SensorTypes.STATIONARY,
      map.floorplanId
    ).filter(
      (s) =>
        s.position !== undefined &&
        s.position !== null &&
        (!map.activeSensorGroupId ||
          s.sensorGroupId === map.activeSensorGroupId)
    ),
  };
};

export default connect(mapStateToProps)(CoverageMap);
