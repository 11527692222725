import { connect } from 'react-redux';

import { withRouter } from '@app/utils/withRouter';
import Asset from '@models/Asset';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import { getAsset, getCompleteAsset } from '@selectors/assets';
import Management from './Management';

interface IProps {
  router: any;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  asset: getCompleteAsset(
    state.warehouses.warehouses,
    state.floorplans.floorplans,
    getAsset(state, props.router.params.id) || ({} as Asset)
  ),
  language: getActiveLanguage(state),
});

export default withRouter(connect(mapStateToProps)(Management));
