import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';

import ItemsTab from './ItemsTab';

const mapStateToProps = (state: IAppState) => ({
  importItemsFormOpen: state.items.importItemsForm.open,
  importStocksFormOpen: state.items.importStocksForm.open,
  language: getActiveLanguage(state),
});

export default connect(mapStateToProps)(ItemsTab);
