import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DropzoneArea } from 'react-mui-dropzone';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { CreateAssetDocument } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import { AssetDocumentTypes } from '@models/AssetDocument';
import CompleteAsset from '@models/CompleteAsset';
import FetchError from '@models/FetchError';
import { dropZoneMessages } from '../../../../../../utils/dropZoneMessages';

interface IState {
  name: string;
  type?: AssetDocumentTypes;
  file?: File;
}

interface IProps {
  asset: CompleteAsset;
  close: () => void;
  createAssetDocument: (properties: CreateAssetDocument) => void;
  intl: IntlShape;
  error?: FetchError;
  loading: boolean;
}

class TicketForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      name: '',
    };

    this.handleFiles = this.handleFiles.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { close, loading, error } = this.props;

    if (!loading && !error && prevProps.loading) {
      close();
    }
  }

  public handleChange(e: any) {
    const { name, value, type, checked } = e.target;

    this.setState({
      [name]: type === 'checkbox' ? checked : value || '',
    } as Pick<IState, 'type'>);
  }

  public handleFiles(files: any[]) {
    this.setState({ file: files[0] });
  }

  public handleCancel() {
    const { close } = this.props;

    close();
  }

  public handleSubmit() {
    const { asset, createAssetDocument } = this.props;
    const { type, name, file } = this.state;

    createAssetDocument({
      assetId: asset.id,
      type: type || AssetDocumentTypes.MANUAL,
      name,
      file: file!,
    });
  }

  public render() {
    const { intl, loading } = this.props;
    const { name, file, type } = this.state;

    const error = name.length === 0 || !file || !type;
    const buttonDisabled = error;

    return (
      <Dialog open maxWidth="md" fullWidth>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.assets.management.documentform.title_create"
            defaultMessage="Create Document"
          />
        </DialogTitle>
        <DialogContent>
          <FormControl required fullWidth margin="normal">
            <InputLabel htmlFor="ticket-form-type">
              <FormattedMessage
                id="dashboard.assets.management.documentform.label_type"
                defaultMessage="Type"
              />
            </InputLabel>
            <Select
              id="ticket-form-type"
              variant="standard"
              value={type || ''}
              onChange={this.handleChange}
              name="type"
            >
              <MenuItem value="" disabled />
              <MenuItem value="manual">
                <FormattedMessage
                  id="dashboard.assets.management.documentform.label_type_manual"
                  defaultMessage="Manual"
                />
              </MenuItem>
              <MenuItem value="service_manual">
                <FormattedMessage
                  id="dashboard.assets.management.documentform.label_type_service_manual"
                  defaultMessage="Service Manual"
                />
              </MenuItem>
              <MenuItem value="service_inspection">
                <FormattedMessage
                  id="dashboard.assets.management.documentform.label_type_service_inspection"
                  defaultMessage="Service Inspection"
                />
              </MenuItem>
              <MenuItem value="repair_note">
                <FormattedMessage
                  id="dashboard.assets.management.documentform.label_type_repair_note"
                  defaultMessage="Repair Note"
                />
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl required fullWidth margin="normal">
            <TextField
              name="name"
              variant="standard"
              onChange={this.handleChange}
              value={name}
              required
              label={
                <FormattedMessage
                  id="dashboard.assets.management.documentform.label_name"
                  defaultMessage="Name for document presentation"
                />
              }
            />
            {!name && (
              <FormHelperText>
                <FormattedMessage
                  id="dashboard.assets.management.documentform.field_name_helper"
                  defaultMessage="The document must have a name"
                />
              </FormHelperText>
            )}
          </FormControl>
          <DropzoneArea
            dropzoneText={intl.formatMessage(
              dropZoneMessages.dropzoneTextSingleFile
            )}
            initialFiles={file ? [file] : []}
            filesLimit={1}
            getFileLimitExceedMessage={(filesLimit: number) =>
              intl.formatMessage(dropZoneMessages.exceededMessage, {
                limit: filesLimit,
              })
            }
            getFileAddedMessage={(fileName: string) =>
              intl.formatMessage(dropZoneMessages.addedMessage, { fileName })
            }
            getFileRemovedMessage={(fileName: string) =>
              intl.formatMessage(dropZoneMessages.removedMessage, { fileName })
            }
            getDropRejectMessage={(
              rejectedFile: any,
              acceptedFiles: any[],
              maxFileSize: number
            ) => {
              if (!acceptedFiles.includes(rejectedFile.type)) {
                return intl.formatMessage(dropZoneMessages.unsupportedFile, {
                  fileName: rejectedFile.name,
                });
              }

              if (rejectedFile.size > maxFileSize) {
                let size = '';

                if (maxFileSize >= 1048576) {
                  size = `${maxFileSize / 1048576} MB`;
                } else if (maxFileSize >= 1024) {
                  size = `${maxFileSize / 1024} KB`;
                } else {
                  size = `${maxFileSize} B`;
                }

                return intl.formatMessage(dropZoneMessages.fileTooBig, {
                  fileName: rejectedFile.name,
                  size,
                });
              }

              return '';
            }}
            onChange={this.handleFiles}
          />
        </DialogContent>
        <DialogActions>
          <Box mr={1}>
            <Button onClick={this.handleCancel}>
              <FormattedMessage
                id="dashboard.assets.management.documentform.button_cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </Box>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color="secondary"
            disabled={buttonDisabled}
            loading={loading}
          >
            <FormattedMessage
              id="dashboard.assets.management.documentform.buttom_submit"
              defaultMessage="Create"
            />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(TicketForm);
