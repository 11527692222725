import paper from 'paper';
import { Component } from 'react';
import { EventEmitter } from 'events';

import MapImages from './MapImages';
import { IToolEvent } from '../types';
import mapEvents from './eventEmitter';

const { Point } = paper;

export const ZOOM_FACTOR = 1.05;

interface ISize {
  width: number;
  height: number;
}

interface IProps {
  id: string;
  mapImages: MapImages;
}

class Paper extends Component<IProps> {
  public zoomRatio = 1;

  public paper: any = paper;

  public scope: paper.PaperScope;

  public tool: EventEmitter;

  public ptool: paper.Tool;

  constructor(props: IProps) {
    super(props);

    this.scope = new paper.PaperScope();
    this.ptool = new paper.Tool();

    this.tool = new EventEmitter();

    const receiveToolEvent = (eventType: string, event: IToolEvent) => {
      this.tool.emit(eventType, event);
    };

    this.ptool.onMouseDown = (event: IToolEvent) =>
      receiveToolEvent('mousedown', event);
    this.ptool.onMouseDrag = (event: IToolEvent) =>
      receiveToolEvent('mousedrag', event);
    this.ptool.onMouseMove = (event: IToolEvent) =>
      receiveToolEvent('mousemove', event);
    this.ptool.onMouseUp = (event: IToolEvent) =>
      receiveToolEvent('mouseup', event);

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  public handleWindowResize() {
    const { id } = this.props;

    const canvas = document.getElementById(`${id}_map`);

    if (canvas) {
      this.scope.view.viewSize = new paper.Size(
        canvas.parentElement!.clientWidth,
        canvas.parentElement!.clientHeight
      );
    }
  }

  public setup(canvas: HTMLCanvasElement) {
    this.scope.setup(canvas);
    this.scope.view.onResize = () => {
      const { mapImages } = this.props;
      if (!this.scope.view.viewSize) {
        return;
      }

      this.zoomRatio = Math.min(
        mapImages.width / (this.scope.view.viewSize.width || 1),
        mapImages.height / (this.scope.view.viewSize.height || 1)
      );

      mapEvents.emit('resized');
    };
  }

  public getZoom() {
    return (this.scope.view || {}).zoom || 1;
  }

  public setZoom(newZoom: number) {
    this.scope.view.zoom = newZoom;
  }

  public setCenter(x: number, y: number) {
    const center = new Point(x, y);
    this.scope.view.center = center;
  }

  public isActive() {
    return !!this.scope.view;
  }

  public centerView(width: number, height: number) {
    if (!this.scope.view) {
      return;
    }

    this.zoomRatio = width / (this.scope.view.size.width || 1);

    if (this.zoomRatio < height / (this.scope.view.size.height || 1)) {
      this.zoomRatio = height / (this.scope.view.size.height || 1);
    }

    if (this.zoomRatio > 1) {
      this.scope.view.zoom = 1 / this.zoomRatio;
    }

    this.scope.view.center = this.scope.view.viewToProject(
      this.scope.view.projectToView(new Point(width / 2, height / 2))
    );

    mapEvents.emit('resized');
  }

  /**
   * @description Fits current map to view - centering and zooming to the initial zoom value
   */
  public fitToView(x: number, y: number, size?: ISize) {
    if (this.scope.view) {
      if (size) {
        this.scope.view.zoom = Math.min(
          1 / (size.width / (this.scope.view.viewSize!.width || 1)),
          1 / (size.height / (this.scope.view.viewSize!.height || 1))
        );
      } else {
        const { mapImages } = this.props;

        this.scope.view.zoom = Math.min(
          1 / (mapImages.width / (this.scope.view.viewSize!.width || 1)),
          1 / (mapImages.height / (this.scope.view.viewSize!.height || 1))
        );
      }

      this.setCenter(x, y);
    }
  }

  /**
   * @description Adds 1 factor level to the map current zoom
   */
  public zoomIn() {
    const newZoom = (this.scope.view.zoom || 1) * (ZOOM_FACTOR + 0.5);

    this.scope.view.zoom = newZoom;
  }

  /**
   * @description Subtracts 1 factor level to the map current zoom
   */
  public zoomOut() {
    const newZoom = ((this.scope.view.zoom || 1) * 1) / (ZOOM_FACTOR + 0.5);

    this.scope.view.zoom = newZoom;
  }
}

export default Paper;
