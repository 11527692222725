import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { sum } from 'lodash';
import { Color, Path, Point, Matrix } from 'paper';
import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { getColor, transformMetersToPixels } from '@dashboard_utils/index';
import IteractiveMap from '@models/IteractiveMap';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Zone from '@models/Zone';
import { orderLayers } from '../../eventEmitter';
import MapImages from '../../MapImages';
import Paper from '../../Paper';
import { LayerScale } from '../../LayerScale/LayerScale';
import { IPathFeature } from '../../../types';
import { defaultTransformationMatrix } from '../../../consts';

interface IProps {
  id: string;
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  data: IteractiveMap;
  intl: IntlShape;
  paper: Paper;
  updateScale: (id: string, scale?: LayerScale) => void;
  zones: Zone[];
}

interface IZoneData {
  endZoneId: string;
  meanDuration: number;
  totalCount: number;
}

interface IState {
  metric: string;
  zoneId?: string;
}

const messages = defineMessages({
  total: {
    defaultMessage: 'Total',
    id: 'dashboard.map.layers.zoneflow_units_total',
  },
});

class FlowMapLayer extends Component<IProps, IState> {
  private elements: IPathFeature[] = [];

  constructor(props: IProps) {
    super(props);

    this.state = {
      metric: 'total',
    };

    this.handleMetricChange = this.handleMetricChange.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
  }

  public componentDidMount() {
    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { data } = this.props;

    if (prevProps.data.loading !== data.loading) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    this.clear();
  }

  public handleZoneChange(event: any) {
    this.setState({ zoneId: event.target.value }, this.reload);
  }

  public handleMetricChange(event: any) {
    this.setState({ metric: event.target.value }, this.reload);
  }

  public clear() {
    const { id, updateScale } = this.props;

    updateScale(id);

    this.elements.forEach((e) => e.remove());

    this.elements = [];
  }

  public reload() {
    this.clear();

    this.load();
  }

  public load() {
    const { data, floorplan, id, intl, mapImages, paper, updateScale, zones } =
      this.props;
    const { metric, zoneId } = this.state;

    paper.scope.activate();

    let zoneIndexes = [];
    let zoneValues: IZoneData[] = [];
    let totalValue = 0;
    if (zoneId) {
      zoneIndexes = ((data.data || {}).start_zone_id || [])
        .map((zId, index) => ({ isZone: zId === zoneId, index }))
        .filter((d) => d.isZone);

      zoneValues = zoneIndexes.map((d) => ({
        endZoneId: ((data.data || {}).end_zone_id || [])[d.index],
        meanDuration: ((data.data || {}).mean_duration || [])[d.index],
        totalCount: ((data.data || {}).total_count || [])[d.index],
      }));
      totalValue = sum(
        zoneValues.map(
          (v) => (metric === 'total' ? v.totalCount : v.meanDuration) || 0
        )
      );
    }

    (zones || [])
      .filter(
        (zone) =>
          !zoneId ||
          zone.id === zoneId ||
          zoneValues.find((value) => value.endZoneId === zone.id)
      )
      .forEach((zone, index) => {
        const path = new Path() as IPathFeature;

        const zoneValue = zoneValues.find(
          (value) => value.endZoneId === zone.id
        );

        if (!zoneId || zone.id === zoneId) {
          path.strokeColor = new Color('#666666');
          path.fillColor = new Color('#66666620');
        } else {
          const color = getColor(
            (metric === 'total'
              ? (zoneValue || {}).totalCount
              : (zoneValue || {}).meanDuration) || 0,
            0,
            totalValue,
            true,
            true
          );
          path.strokeColor = new Color(color);
          path.fillColor = new Color(`${color}60`);
        }

        path.strokeWidth = 3;
        zone.coordinates.forEach((c) =>
          path.add(
            new Point(
              transformMetersToPixels(
                c as [number, number],
                floorplan.transformationMatrix || defaultTransformationMatrix,
                floorplan.scale || 1
              )
            )
          )
        );
        path.closePath();
        path.strokeScaling = false;
        path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
        this.elements.push(path);

        path.featureInfo = {
          coordinates: zone.coordinates,
          id: `zone_${index}`,
          props: {
            color: new Color(zone.color),
            created: zone.created,
            id: zone.id,
            zoneName: zone.name,
            zoneType: zone.type,
            totalCount: (zoneValue || {}).totalCount,
          },
          title: zone.name,
          type: 'flowmap',
        };
      });

    updateScale(id, {
      max: totalValue,
      min: 0,
      singleColor: true,
      units: 'unit',
      label: intl.formatMessage(messages.total),
    });

    orderLayers();
  }

  public render() {
    const { metric, zoneId } = this.state;
    const { zones } = this.props;

    return (
      <div
        style={{
          position: 'absolute',
          top: '55px',
          left: '52px',
          minWidth: '250px',
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="zone-selector">
            <FormattedMessage
              id="dashboard.map.layers.zoneflow_zone_label"
              defaultMessage="Select a zone of origin"
            />
          </InputLabel>
          <Select
            id="zone-selector"
            value={zoneId || ''}
            onChange={this.handleZoneChange}
          >
            {zones.map((zone) => (
              <MenuItem key={zone.id} value={zone.id}>
                {zone.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginTop: '5px' }}>
          <InputLabel id="type-selector">
            <FormattedMessage
              id="dashboard.map.layers.zoneflow_type_label"
              defaultMessage="Select a metric"
            />
          </InputLabel>
          <Select
            id="type-selector"
            value={zoneId ? metric : ''}
            disabled={!zoneId}
            onChange={this.handleMetricChange}
          >
            <MenuItem value="total">
              <FormattedMessage
                id="dashboard.map.layers.zoneflow_info_totalcount"
                defaultMessage="Number of movements into this zone"
              />
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default injectIntl(FlowMapLayer);
