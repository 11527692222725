import Typography from '@mui/material/Typography/Typography';
import React, { FunctionComponent } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Map from '@app/common/FullMap/Map';
import { ILayer, IDrawAction } from '@app/common/FullMap/types';
import { PRIMARY_COLOR } from '@app/utils/colors';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { IDrawDefinition } from '@models/types';

interface IProps {
  drawings: IDrawDefinition[];
  floorplan: IncompleteFloorplan;
  forceMapUpdate?: boolean;
  handleDrawChange?: (coordinates: IDrawDefinition[]) => void;
  type: string;
}

const messages = defineMessages({
  floorplan: {
    defaultMessage: 'Floor plan',
    id: 'dashboard.datamap.floorplan',
  },
  satellite: {
    defaultMessage: 'Satellite',
    id: 'dashboard.livemap.layers.satellite',
  },
  sensors: {
    defaultMessage: 'Sensors',
    id: 'dashboard.livemap.layers.sensors',
  },
});

const BoundariesDefinitionForm: FunctionComponent<IProps> = ({
  floorplan,
  forceMapUpdate = false,
  handleDrawChange = undefined,
  drawings,
  type,
}: IProps) => {
  const intl = useIntl();

  const layers: Record<string, ILayer> = {
    floorplan: {
      id: 'floorplan',
      active: true,
      label: intl.formatMessage(messages.floorplan),
      primary: true,
      secondary: false,
    },
    sensors: {
      id: 'sensors',
      active: true,
      label: intl.formatMessage(messages.sensors),
      primary: true,
      secondary: false,
    },
  };

  if (type === 'exteriorBoundaries' && floorplan.satelliteBWImage) {
    layers.satellite = {
      id: 'satellite',
      active: true,
      label: intl.formatMessage(messages.satellite),
      primary: true,
      secondary: false,
    };
  }

  let help = (
    <>
      <Typography variant="h4">
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.racksdefinition.title"
          defaultMessage="Racks Definition"
        />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.racksdefinition.description.p1"
          defaultMessage={
            "Here you will be able to draw the floor's racks in the map."
          }
        />
        <br />
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.drawdefinitions.description.p2"
          defaultMessage="This step can be completed at a later time."
        />
      </Typography>
    </>
  );

  if (type === 'exteriorBoundaries') {
    help = (
      <>
        <Typography variant="h4">
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.exteriorboundariesdefinition.title"
            defaultMessage="Exterior Boundaries Definition"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.exteriorboundariesdefinition.description.p1"
            defaultMessage={
              "Here you will be able to draw the floor's exterior boundaries in the map."
            }
          />
          <br />
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.drawdefinitions.description.p2"
            defaultMessage="This step can be completed at a later time."
          />
        </Typography>
      </>
    );
  } else if (type === 'boundaries') {
    help = (
      <>
        <Typography variant="h4">
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.boundariesdefinition.title"
            defaultMessage="Boundaries Definition"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.boundariesdefinition.description.p1"
            defaultMessage={
              "Here you will be able to draw the floor's boundaries in the map."
            }
          />
          <br />
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.drawdefinitions.description.p2"
            defaultMessage="This step can be completed at a later time."
          />
        </Typography>
      </>
    );
  } else if (type === 'obstacles') {
    help = (
      <>
        <Typography variant="h4">
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.obstaclesdefinition.title"
            defaultMessage="Obstacles Definition"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.obstaclesdefinition.description.p1"
            defaultMessage={
              "Here you will be able to draw the floor's obstacles in the map."
            }
          />
          <br />
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.drawdefinitions.description.p2"
            defaultMessage="This step can be completed at a later time."
          />
        </Typography>
      </>
    );
  }

  const mainHelp = (
    <>
      {help}
      <Typography variant="caption">
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.drawdefinitions.tutorial.p1"
          defaultMessage="Ctrl + Click and Drag (Cmd + Drag on Mac) will create a box."
        />
        &nbsp;
        {type !== 'racks' ? (
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.drawdefinitions.tutorial.p2"
            defaultMessage="Boxes are editable by clicking and dragging their vertices."
          />
        ) : null}
        <br />
        <FormattedMessage
          id="dashboard.forms.floorplanconfigurationform.drawdefinitions.tutorial.p3"
          defaultMessage="To delete a box, click it and press Delete on your keyboard."
        />
        <>
          <br />
          {type !== 'racks' ? (
            <FormattedMessage
              id="dashboard.forms.floorplanconfigurationform.drawdefinitions.tutorial.p4"
              defaultMessage="Right Click on a vertex will delete it."
            />
          ) : null}
          &nbsp;
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.drawdefinitions.tutorial.p5"
            defaultMessage="ALT + Click and Drag on a segment will drag the draw. With the draw selected, SHIFT + Click and drag will rotate it."
          />
        </>
      </Typography>
    </>
  );

  let drawColor = '#008000';
  if (type === 'exteriorBoundaries') {
    drawColor = '#AD006B';
  } else if (type === 'boundaries') {
    drawColor = PRIMARY_COLOR;
  } else if (type === 'obstacles') {
    drawColor = PRIMARY_COLOR;
  } else if (type === 'landmarks') {
    drawColor = '#ffc300';
  }

  let fillColor;
  if (type === 'obstacles') {
    fillColor = `${PRIMARY_COLOR}40`;
  } else if (type === 'landmarks') {
    fillColor = '#ffc30040';
  }

  let featureName = '';
  if (type === 'exteriorBoundaries') {
    featureName = 'Exterior Boundaries';
  } else if (type === 'boundaries') {
    featureName = 'Boundary';
  } else if (type === 'obstacles') {
    featureName = 'Obstacle';
  } else if (type === 'racks') {
    featureName = 'Rack';
  } else if (type === 'landmarks') {
    featureName = 'Landmark';
  }

  let drawType = '';
  if (type === 'boundaries') {
    drawType = 'boundary';
  } else if (type === 'obstacles') {
    drawType = 'obstacle';
  } else if (type === 'racks') {
    drawType = 'rack';
  } else if (type === 'landmarks') {
    drawType = 'landmark';
  }

  return (
    <div
      style={{
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Map
          disableColor
          floorplanId={floorplan.id}
          forceMapUpdate={forceMapUpdate}
          help={mainHelp}
          actions={{
            draw: {
              featureName,
              onChange: handleDrawChange,
              drawings,
              drawColor,
              drawType,
              fillColor,
              roundEdges: type === 'racks' || type === 'landmarks',
            } as IDrawAction,
          }}
          layers={layers}
        />
      </div>
    </div>
  );
};

export default BoundariesDefinitionForm;
