import { Component } from 'react';

import ws from '@api/websocket';

const USER_ACTIVITY_TIMEOUT = 10 * 60 * 1000;

export interface IProps {
  activeSensorGroupId?: string;
}

class LiveDataPing extends Component<IProps> {
  private livePing?: any;

  private userActivityTimeout?: any;

  constructor(props: IProps) {
    super(props);

    this.userActivity = this.userActivity.bind(this);
    this.startActivity = this.startActivity.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('mousemove', this.userActivity, { passive: true });
    window.addEventListener('scroll', this.userActivity, { passive: true });
    window.addEventListener('keydown', this.userActivity, { passive: true });
    window.addEventListener('resize', this.userActivity, { passive: true });
    window.addEventListener('MapActivityResumed', this.startActivity, {
      passive: true,
    });

    this.startActivity();
  }

  public componentWillUnmount() {
    window.removeEventListener('mousemove', this.userActivity);
    window.removeEventListener('scroll', this.userActivity);
    window.removeEventListener('keydown', this.userActivity);
    window.removeEventListener('resize', this.userActivity);
    window.removeEventListener('MapActivityResumed', this.startActivity);

    if (this.userActivityTimeout) {
      clearTimeout(this.userActivityTimeout);
    }

    if (this.livePing) {
      clearInterval(this.livePing);
    }
  }

  public userActivity() {
    if (this.userActivityTimeout) {
      clearTimeout(this.userActivityTimeout);
    }

    this.userActivityTimeout = setTimeout(() => {
      window.dispatchEvent(new Event('NoMapActivityDetected'));

      clearInterval(this.livePing);
      this.livePing = undefined;

      this.userActivityTimeout = undefined;
    }, USER_ACTIVITY_TIMEOUT);
  }

  public startActivity() {
    const { activeSensorGroupId } = this.props;

    if (!this.livePing && activeSensorGroupId) {
      ws.ping(activeSensorGroupId);
      this.livePing = setInterval(() => {
        ws.ping(activeSensorGroupId);
      }, 1000);
    }

    this.userActivity();
  }

  public render() {
    return null;
  }
}

export default LiveDataPing;
