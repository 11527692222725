import React, { Component } from 'react';

import { IFetchFloorplanSensors } from '@actions/index';
import Loading from '../../Loading';

export interface IProps {
  fetchSensors: (floorplanId: string) => void;
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => void;
  loading: boolean;
  floorplanId: string;
  children: React.ReactNode;
}

class MapLoading extends Component<IProps> {
  public componentDidMount() {
    const { fetchActiveSensorGroup, fetchSensors, floorplanId } = this.props;

    fetchSensors(floorplanId);

    fetchActiveSensorGroup({ floorplanId });
  }

  public componentDidUpdate(prevProps: any) {
    const { fetchActiveSensorGroup, fetchSensors, floorplanId } = this.props;

    if (prevProps.floorplanId !== floorplanId) {
      fetchSensors(floorplanId);
      fetchActiveSensorGroup({ floorplanId });
    }
  }

  public render() {
    const { children, loading } = this.props;

    return <Loading loading={loading}>{children}</Loading>;
  }
}
export default MapLoading;
