import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import {
  confirmDialog,
  deleteTask,
  openTaskForm,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { withRouter } from '@app/utils/withRouter';
import { Task, TaskStatus } from '@models/Task';
import TasksTable from './TasksTable';
import { Date as LogDate } from '@dashboard_utils/index';

interface IProps {
  filterId: string;
  statusFilter: TaskStatus[];
  filterEndDate?: LogDate;
  filterStartDate?: LogDate;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const {
    filterId,
    statusFilter,
    filterStartDate,
    filterEndDate,
  } = props;

  const filter = state.filter.filters[filterId] || {};

  const tasks = Object.values(state.tasks.tasks).filter((d) =>
    d.warehouseId === filter.warehouseId &&
    d.floorplanId === filter.floorplanId && (
      !(filter.zoneIds || []).length ||
      !!(filter.zoneIds || []).find((zId) => d.zoneId === zId)
    ) && (
      !(filter.employeeIds || []).length ||
      !!d.employees.find((eId) => (filter.employeeIds || []).find((id) => id === eId))
    ) && (
      !(filter.teamIds || []).length ||
      !!d.teams.find((eId) => (filter.teamIds || []).find((id) => id === eId))
    ) && (
      !filterStartDate || new Date(d.created).getTime() >= filterStartDate.getTime()
    ) && (
      !filterEndDate || new Date(d.created).getTime() <= filterEndDate.getTime()
    ) && (
      !statusFilter.length || statusFilter.indexOf(d.status) !== -1
    )
  );

  return {
    assets: state.assets.assets,
    data: tasks,
    loading: state.tasks.loading,
    employees: state.employees.employees,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  deleteTask: (id: string, intl: IntlShape) =>
    dispatch(deleteTask.request({ id, intl })),
  openForm: (data?: Task) => dispatch(openTaskForm(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TasksTable)
);
