import React from 'react';
import {
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";

export const withRouter = (Component: any) => {
  function ComponentWithRouterProp(props: any) {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    return (
      <Component
        {...props}
        router={{  navigate, params, query: new URLSearchParams(location.search), location }}
      />
    );
  }

  return ComponentWithRouterProp;
}