import React from 'react';
import TimeAgo from 'react-timeago';
// @ts-ignore
import frStrings from 'react-timeago/lib/language-strings/fr';
// @ts-ignore
import ptStrings from 'react-timeago/lib/language-strings/pt';
// @ts-ignore
import esStrings from 'react-timeago/lib/language-strings/es';
// @ts-ignore
import roStrings from 'react-timeago/lib/language-strings/ro';
// @ts-ignore
import enStrings from 'react-timeago/lib/language-strings/en';
// @ts-ignore
import deStrings from 'react-timeago/lib/language-strings/de';
// @ts-ignore
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const timeAgo = (time: string, locale: string) => {
  let localeTools = enStrings;
  if (locale.indexOf('de') === 0) {
    localeTools = deStrings;
  } else if (locale.indexOf('es') === 0) {
    localeTools = esStrings;
  } else if (locale.indexOf('fr') === 0) {
    localeTools = frStrings;
  } else if (locale.indexOf('pt') === 0) {
    localeTools = ptStrings;
  } else if (locale.indexOf('ro') === 0) {
    localeTools = roStrings;
  }

  const formatter = buildFormatter(localeTools);

  return <TimeAgo date={time} formatter={formatter} />;
};

export default timeAgo;
