import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchMaintenancesByAsset } from '@actions/maintenances';
import CompleteAsset from '@models/CompleteAsset';
import { IAppState } from '@reducers/index';
import {
  getAssetMaintenanceList,
  isAssetMaintenanceListLoading,
} from '@selectors/maintenances';
import Maintenances from './Maintenances';

interface IProps {
  asset: CompleteAsset;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  floorplans: state.floorplans.floorplans,
  loading: isAssetMaintenanceListLoading(state.maintenances, props.asset.id),
  maintenances: getAssetMaintenanceList(state.maintenances, props.asset.id),
  warehouses: state.warehouses.warehouses,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMaintenancesByAsset: (assetId: string) =>
    dispatch(fetchMaintenancesByAsset.request({ assetId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Maintenances);
