import React, { FunctionComponent } from 'react';
import { IntlShape } from 'react-intl';

import { ForkliftTypes } from '@app/dashboard/tabs/FinancialsTab/Calc';
import CreateOrEditWarehouse from './CreateOrEditWarehouse';

interface IProps {
  createWarehouse: (
    intl: IntlShape,
    name: string,
    site: string,
    timezone: string,
    country: string,
    metricsTotalPalletsMoved?: number,
    metricsFleetUsedCapacity?: number,
    numberOfShifts?: number,
    shiftHours?: number,
    workingDays?: number,
    targetForkliftType?: keyof typeof ForkliftTypes
  ) => void;
  loading: boolean;
}

const CreateWarehouse: FunctionComponent<IProps> = ({
  createWarehouse,
  loading,
}: IProps) => (
  <CreateOrEditWarehouse
    onNext={createWarehouse}
    name=""
    site=""
    timezone=""
    country=""
    loading={loading}
  />
);

export default CreateWarehouse;
