import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Popover from '@mui/material/Popover';
import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';
import uuid from 'uuid/v4';

import { FetchMetric } from '@actions/index';
import { Date } from '@dashboard_utils/index';
import MetricMeta from '@models/MetricMeta';
import { MetricAggregationWithFeedback } from '@reducers/analytics';

const messages = defineMessages({
  aggregation: {
    defaultMessage: 'Aggregation',
    id: 'dashboard.plots.aggregationform.aggregation',
  },
  filterType: {
    defaultMessage: 'Filter Type',
    id: 'dashboard.plots.aggregationform.filtertype',
  },

  none: {
    defaultMessage: 'None',
    id: 'dashboard.plots.aggregationform.none',
  },
  timeline: {
    defaultMessage: 'Timeline',
    id: 'dashboard.plots.aggregationform.timeline',
  },
  periods: {
    defaultMessage: 'Periods',
    id: 'dashboard.plots.aggregationform.periods',
  },
  assets: {
    defaultMessage: 'Assets',
    id: 'dashboard.plots.aggregationform.assets',
  },
  zones: {
    defaultMessage: 'Zones',
    id: 'dashboard.plots.aggregationform.zones',
  },

  id: {
    defaultMessage: 'Id',
    id: 'dashboard.plots.aggregationform.id',
  },
  type: {
    defaultMessage: 'Type',
    id: 'dashboard.plots.aggregationform.type',
  },
  tag: {
    defaultMessage: 'Tag',
    id: 'dashboard.plots.aggregationform.tag',
  },

  day: {
    defaultMessage: 'Day',
    id: 'dashboard.plots.aggregationform.day',
  },
  hour: {
    defaultMessage: 'Hour',
    id: 'dashboard.plots.aggregationform.hour',
  },
  weekday: {
    defaultMessage: 'Weekday',
    id: 'dashboard.plots.aggregationform.weekday',
  },
  weekofyear: {
    defaultMessage: 'Week Of Year',
    id: 'dashboard.plots.aggregationform.weekofyear',
  },
  month: {
    defaultMessage: 'Month',
    id: 'dashboard.plots.aggregationform.month',
  },
});

interface LevelValue {
  aggregation: string | null;
  option: string | null;
}

interface IProps {
  filterId: string;
  metricId: string;

  warehouseId: string;
  floorplanId: string;
  zoneIds: string[];

  tags: string[];
  assetIds: string[];

  templateIds: string[];
  ruleIds: string[];

  startDate?: Date;
  endDate?: Date;

  locale: string;
  measurementUnits: string;

  intl: IntlShape;

  anchorEl?: Element;
  aggregationState: MetricAggregationWithFeedback;
  metricMeta: MetricMeta;

  fetchMetric: (properties: FetchMetric) => void;

  handleClose: () => void;
}

interface IState {
  dirty: boolean;
  levels: LevelValue[];
}

/**
 * Aggregation form. Allows aggregation edition/addition.
 */
class PlotComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { aggregationState } = this.props;

    this.state = {
      dirty: false,
      levels: [
        {
          aggregation: aggregationState.level1 || null,
          option: aggregationState.level1Option || null,
        },
        {
          aggregation: aggregationState.level2 || null,
          option: aggregationState.level2Option || null,
        },
        {
          aggregation: aggregationState.level3 || null,
          option: aggregationState.level3Option || null,
        },
      ],
    };

    this.handleLevelChange = this.handleLevelChange.bind(this);
    this.changeAggregation = this.changeAggregation.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { aggregationState } = this.props;

    if (
      JSON.stringify(prevProps.aggregationState) !==
      JSON.stringify(aggregationState)
    ) {
      this.updateLevel();
    }
  }

  public handleLevelChange(index: number, key: keyof LevelValue, value: any) {
    const { levels } = this.state;

    const nLevels = [...levels];
    const nLevel = nLevels[index];

    nLevels[index] = {
      ...nLevel,
      option: null,
      [key]: value,
    };
    if (key === 'aggregation') {
      let i = index + 1;
      while (nLevels[i]) {
        nLevels[i].aggregation = null;
        nLevels[i].option = null;
        i += 1;
      }
    }

    this.setState({ levels: nLevels, dirty: true });
  }

  /**
   * @description Test if form is filled.
   * @returns     {boolean} If form in completly filled.
   */
  public isFormComplete() {
    const { levels } = this.state;

    return !levels.find((l) => l.aggregation && !l.option);
  }

  /**
   * @description Filters possible aggregations for the levels based on the metric meta.
   * @returns     {object[]} Possible aggregations.
   */
  public filterAggregations(level: number) {
    const { metricMeta } = this.props;
    const { levels } = this.state;

    const availableOptions = (metricMeta.aggregations || [])
      .filter(
        (aggregation) =>
          (level < 1 ||
            aggregation[0] === ((levels[0] || {}).aggregation || null)) &&
          (level < 2 ||
            aggregation[1] === ((levels[1] || {}).aggregation || null)) &&
          (level < 3 ||
            aggregation[2] === ((levels[2] || {}).aggregation || null))
      )
      .map((aggregation) => aggregation[level])
      .filter((aggregation) => !!aggregation);

    return availableOptions;
  }

  /**
   * @description Fetchs new metrics for edited metric aggregation.
   */
  public changeAggregation() {
    const {
      fetchMetric,

      handleClose,

      filterId,
      metricId,
      aggregationState,

      warehouseId,
      floorplanId,
      zoneIds,

      tags,
      assetIds,

      templateIds,
      ruleIds,

      startDate,
      endDate,

      locale,
      measurementUnits,
    } = this.props;
    const { levels } = this.state;

    const agg: any = { id: aggregationState.id || uuid() };

    levels.forEach((level, index) => {
      agg[`level${index + 1}`] = level.aggregation;
      agg[`level${index + 1}Option`] = level.option;
    });

    // Fetch only if conditions from the last fetch changed
    const conditions = aggregationState.conditions || {};
    if (
      conditions.filterId !== filterId ||
      conditions.metricId !== metricId ||
      conditions.from !== (startDate || new Date()).getTime() ||
      conditions.to !== (endDate || new Date()).getTime() ||
      conditions.warehouseId !== warehouseId ||
      conditions.floorplanId !== floorplanId ||
      JSON.stringify(conditions.zoneIds) !== JSON.stringify(zoneIds) ||
      JSON.stringify(conditions.tags) !== JSON.stringify(tags) ||
      JSON.stringify(conditions.assetIds) !== JSON.stringify(assetIds) ||
      JSON.stringify(conditions.templateIds) !== JSON.stringify(templateIds) ||
      JSON.stringify(conditions.ruleIds) !== JSON.stringify(ruleIds) ||
      JSON.stringify(conditions.aggregations) !== JSON.stringify([agg]) ||
      conditions.locale !== locale ||
      conditions.units !== measurementUnits ||
      JSON.stringify(conditions.returns) !==
        JSON.stringify(['aggregation_plots', 'aggregation_tables'])
    ) {
      fetchMetric({
        filterId,
        metricId,

        from: (startDate || new Date()).getTime(),
        to: (endDate || new Date()).getTime(),

        warehouseId,
        floorplanId,
        zoneIds,

        tags,
        assetIds,

        templateIds,
        ruleIds,

        aggregations: [agg],
        locale,
        units: measurementUnits,
        returns: ['aggregation_plots', 'aggregation_tables'],
      });
    }

    handleClose();
  }

  /**
   * @description Transformes MetricAggregation into Form Aggregation levels
   */
  public updateLevel() {
    const { aggregationState } = this.props;

    this.setState({
      levels: [
        {
          aggregation: aggregationState.level1 || null,
          option: aggregationState.level1Option || null,
        },
        {
          aggregation: aggregationState.level2 || null,
          option: aggregationState.level2Option || null,
        },
        {
          aggregation: aggregationState.level3 || null,
          option: aggregationState.level3Option || null,
        },
      ],
    });
  }

  public render() {
    const { anchorEl, handleClose, intl, metricMeta } = this.props;
    const { dirty, levels } = this.state;

    return (
      <Backdrop open={!!anchorEl} style={{ zIndex: 1 }}>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              minHeight: '200px',
              maxHeight: '75%',
            },
          }}
        >
          <CardContent>
            {levels
              .filter(
                (a, index) =>
                  a.aggregation !== null ||
                  !index ||
                  !!(levels[index - 1] || {}).option
              )
              .map(({ aggregation, option }, index) => {
                const possibleAggs = this.filterAggregations(index);
                let options: any[] = [];

                if (aggregation === 'timeline') {
                  options = metricMeta.aggregationsOptions.timeline;
                }
                if (aggregation === 'periods') {
                  options = metricMeta.aggregationsOptions.periods;
                }
                if (aggregation === 'assets') {
                  options = metricMeta.aggregationsOptions.assets;
                }
                if (aggregation === 'zones') {
                  options = metricMeta.aggregationsOptions.zones;
                }

                const mainButton = (aggr: string) => {
                  const color = aggr === 'none' ? 'secondary' : 'primary';
                  const value = aggr === 'none' ? null : aggr;

                  return (
                    <Button
                      color={aggregation === value ? color : undefined}
                      variant={aggregation === value ? 'contained' : 'outlined'}
                      onClick={() =>
                        this.handleLevelChange(index, 'aggregation', value)
                      }
                      disabled={!!value && possibleAggs.indexOf(value) === -1}
                      style={{
                        borderBottom: options.length > 0 ? 'none' : undefined,
                        borderBottomRightRadius:
                          options.length > 0 ? 0 : undefined,
                        borderBottomLeftRadius:
                          options.length > 0 ? 0 : undefined,
                      }}
                    >
                      {intl.formatMessage(messages[aggr as 'zones'])}
                    </Button>
                  );
                };

                return (
                  <Box mt={2} key={index}>
                    <FormControl fullWidth margin="dense">
                      <ButtonGroup
                        aria-label={intl.formatMessage(messages.aggregation)}
                        fullWidth
                        size="small"
                      >
                        {mainButton('none')}
                        {mainButton('timeline')}
                        {mainButton('periods')}
                        {mainButton('assets')}
                        {mainButton('zones')}
                      </ButtonGroup>
                      {options.length > 0 ? (
                        <ButtonGroup
                          key="periods"
                          color="primary"
                          aria-label={intl.formatMessage(messages.filterType)}
                          fullWidth
                          size="small"
                        >
                          {options.map((o: any, i: number) => {
                            const message = messages[o as 'none'];

                            return (
                              <Button
                                key={o}
                                variant={
                                  option === o ? 'contained' : 'outlined'
                                }
                                color={option === o ? 'primary' : undefined}
                                onClick={() =>
                                  this.handleLevelChange(index, 'option', o)
                                }
                                style={{
                                  borderTopLeftRadius: i === 0 ? 0 : undefined,
                                  borderTopRightRadius:
                                    i === options.length - 1 ? 0 : undefined,
                                }}
                              >
                                {message ? intl.formatMessage(message) : o}
                              </Button>
                            );
                          })}
                        </ButtonGroup>
                      ) : null}
                    </FormControl>
                  </Box>
                );
              })}
          </CardContent>
          {dirty ? (
            <CardActions style={{ justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="secondary"
                disabled={!this.isFormComplete()}
                onClick={this.changeAggregation}
              >
                <FormattedMessage
                  id="dashboard.plots.aggregationform.apply"
                  defaultMessage="Apply"
                />
              </Button>
            </CardActions>
          ) : null}
        </Popover>
      </Backdrop>
    );
  }
}

export default injectIntl(PlotComponent);
