import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import AddIcon from '@mui/icons-material/Add';
import React, { Component } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import Map from '@app/common/FullMap';
import DateTimeRangeSlider from '@app/common/DateTimeRangeSlider/DateTimeRangeSlider';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from '@app/utils/localStorageUtils';
import { Task, TaskStatus } from '@models/Task';
import TasksTable from './TasksTable';
import TaskForm from './TaskForm';
import { Date } from '@dashboard_utils/index';

interface IProps {
  filterId: string;
  statusFilter: TaskStatus[];
  warehouseId: string;
  floorplanId: string;

  loading: boolean;
  fetchTasks: (shallow?: boolean) => void;
  fetchEmployees: () => void;
  fetchTeams: () => void;
  openForm: (data?: Task) => void;

  formOpen: boolean;
  language: string;
  router: any;

  startDate: Date;
  endDate: Date;
  warehouseTz: string;

  intl: IntlShape;
}

interface IState {
  tab: number;
  filterStartDate?: Date;
  filterEndDate?: Date;
}

const messages = {
  tabMaps: {
    defaultMessage: 'Maps',
    id: 'dashboard.plots.tab.maps',
  },
  tabTables: {
    defaultMessage: 'Tables',
    id: 'dashboard.plots.tab.tables',
  },
};

class TasksTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { filterId } = props;

    const tab = Number(
      getFromLocalStorage(`tasks_selected_tab_${filterId}`) || 0
    );

    this.state = {
      tab,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleTaskBoard = this.handleTaskBoard.bind(this);
    this.changePeriod = this.changePeriod.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  private handleTabChange(_: any, tab: number) {
    const { filterId } = this.props;

    this.setState({ tab }, () => {
      saveToLocalStorage(`tasks_selected_tab_${filterId}`, String(tab));
    });
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const { fetchTasks, fetchEmployees, fetchTeams } = this.props;

    fetchTasks(shallow);
    fetchEmployees();
    fetchTeams();
  }

  public handleTaskBoard() {
    const { language, router } = this.props;

    router.navigate(`/${language}/dashboard/tasks-board`);
  }

  public handleAdd() {
    const { openForm, warehouseId, floorplanId } = this.props;

    openForm({ warehouseId, floorplanId } as Task);
  }

  public onClick(position: [number, number]) {
    const { openForm, warehouseId, floorplanId } = this.props;

    openForm({
      warehouseId,
      floorplanId,
      positionX: position[0],
      positionY: position[1],
      positionZ: 0,
    } as Task);
  }

  public changePeriod(leftDate: Date, rightDate: Date) {
    this.setState({
      filterStartDate: leftDate,
      filterEndDate: rightDate,
    });
  }

  public render() {
    const { formOpen, intl, filterId, loading, endDate, startDate, statusFilter, warehouseTz } = this.props;

    const { tab, filterStartDate, filterEndDate } = this.state;

    return (
      <>
        <div className="dtrs-with-hot-dates">
          <DateTimeRangeSlider
            disabled={loading}
            leftDate={filterStartDate || startDate}
            rightDate={filterEndDate || endDate}
            maxDate={endDate}
            minDate={startDate}
            hotMaxDate={endDate}
            hotMinDate={startDate}
            onChange={this.changePeriod}
            warehouseTz={warehouseTz}
          />
        </div>

        <Box pt={1} pb={1}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button id="view-tasks" onClick={this.handleTaskBoard}>
              <ViewWeekIcon style={{ marginRight: '5px' }} />
              <FormattedMessage
                id="dashboard.tasks.board"
                defaultMessage="Task Board"
              />
            </Button>

            <Button id="add-task" onClick={this.handleAdd}>
              <AddIcon style={{ marginRight: '5px' }} />
              <FormattedMessage
                id="dashboard.tasks.addtask"
                defaultMessage="Add Task"
              />
            </Button>
          </div>
        </Box>
        {!loading && (
          <>
            <Tabs
              centered
              indicatorColor="primary"
              onChange={this.handleTabChange}
              value={tab}
              variant="fullWidth"
              textColor="primary"
            >
              <Tab label={intl.formatMessage(messages.tabTables)} />
              <Tab label={intl.formatMessage(messages.tabMaps)} />
            </Tabs>
            {tab === 0 ? (
              <TasksTable
                filterId={filterId}
                filterStartDate={filterStartDate || startDate}
                filterEndDate={filterEndDate || endDate}
                statusFilter={statusFilter}
              />
            ) : (
              <Map
                filterId={filterId}
                layers={{
                  tasks: {
                    id: 'tasks',
                    active: true,
                    label: 'Tasks',
                  },
                }}
                actions={{
                  hover: {},
                  click: {
                    onClick: this.onClick,
                  },
                }}
              />
            )}
          </>
        )}
        {formOpen ? <TaskForm /> : null}
      </>
    );
  }
}

export default injectIntl(TasksTab);
