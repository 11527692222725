import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IUpdateFloorplan, updateFloorplan } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import DefineScale from './DefineScale';

const mapStateToProps = (state: IAppState) => ({
  measurementUnits: getAccountMeasurementUnits(state.authentication),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefineScale);
