import TableCell from '@mui/material/TableCell';
import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import AssetTicket from '@models/AssetTicket';
import CompleteAsset from '@models/CompleteAsset';
import { customRowRender, messages } from '../Tickets/Tickets';

interface IProps {
  asset: CompleteAsset;
  fetchAssetTickets: (assetId: string) => void;
  intl: IntlShape;
  tickets: AssetTicket[];
  loading: boolean;
  language: string;
  router: any;
}
interface IState {
  columns: ITableColumn[];
  itemMenuInfo: Record<string, any>;
}

class History extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      columns: [
        { label: intl.formatMessage(messages.thType), name: 'type' },
        {
          label: intl.formatMessage(messages.thRequester),
          name: 'accountName',
        },
        { label: intl.formatMessage(messages.thSubject), name: 'subject' },
        { label: intl.formatMessage(messages.thRequestDate), name: 'created' },
        {
          label: intl.formatMessage(messages.thAssignee),
          name: 'assigneeAccountName',
        },
        { label: intl.formatMessage(messages.thStatus), name: 'status' },
        {
          label: intl.formatMessage(messages.thOptions),
          name: 'id',
          options: {
            customHeadRender: (columnMeta) => (
              <TableCell key={columnMeta.index} style={{ textAlign: 'right' }}>
                {columnMeta.label}
              </TableCell>
            ),
            interaction: false,
          },
        },
      ],
      itemMenuInfo: {},
    };

    this.handleItemMenuClick = this.handleItemMenuClick.bind(this);
    this.handleItemMenuClose = this.handleItemMenuClose.bind(this);
    this.goToTicket = this.goToTicket.bind(this);
  }

  public componentDidMount() {
    const { asset, fetchAssetTickets } = this.props;

    fetchAssetTickets(asset.id);
  }

  public handleItemMenuClick(index: number) {
    return (event: any) => {
      const { itemMenuInfo } = this.state;

      itemMenuInfo[index] = event.target;

      this.setState({ itemMenuInfo });
    };
  }

  public handleItemMenuClose(index: number) {
    const { itemMenuInfo } = this.state;

    itemMenuInfo[index] = undefined;

    this.setState({ itemMenuInfo });
  }

  public goToTicket(index: number, ticketId: string) {
    const { asset, router, language } = this.props;
    const { itemMenuInfo } = this.state;

    itemMenuInfo[index] = undefined;

    this.setState({ itemMenuInfo }, () => {
      router.navigate(
        `/${language}/dashboard/assets/assetid/${asset.id}/management/tab/4/ticketid/${ticketId}`
      );
    });
  }

  public render() {
    const { loading, intl, tickets } = this.props;
    const { columns, itemMenuInfo } = this.state;

    return (
      <>
        <Loading loading={loading}>
          <MUITable
            columns={columns}
            customRowRender={(c: boolean[], r: any, d: number, rw: number) =>
              customRowRender(
                true,
                c,
                r,
                d,
                rw,
                intl,
                tickets,
                itemMenuInfo,
                this.handleItemMenuClick,
                this.handleItemMenuClose,
                this.goToTicket
              )
            }
            data={tickets}
            tableId="asset-tickets-history"
          />
        </Loading>
      </>
    );
  }
}

export default injectIntl(History);
