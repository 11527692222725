import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { IFetchOrdersEvents } from '@actions/orders';
import Loading from '@app/common/Loading';
import Map from '@app/common/FullMap';
import { ISimulatorConfig, ISimulationWithConfig } from '@models/Simulation';
import { IFilterEntry, IFilterChange, LAST, IFilter } from '@reducers/filter';
import Controls from './Controls';
import { IOrdersEvents } from '@api/websocket';

interface IProps {
  onChange: (properties: IFilterChange) => void;
  addFilter: (properties: IFilterEntry) => void;
  fetchFloorplan: (id: string) => void;
  fetchSimulation: (id: string) => void;
  fetchOrdersEvents: (properties: IFetchOrdersEvents) => void;
  filters: Record<string, IFilter>;
  id: string;
  language: string;
  loading: boolean;
  simulation: ISimulationWithConfig;
  ordersEvents?: IOrdersEvents[];
}

class PlayerContainer extends Component<IProps> {
  public componentDidMount() {
    const {
      addFilter,
      fetchFloorplan,
      fetchSimulation,
      fetchOrdersEvents,
      filters,
      id,
      simulation,
    } = this.props;

    if (simulation.id !== id) {
      fetchSimulation(id);
    }

    if (simulation.floorplanId && !filters.simulator) {
      fetchFloorplan(simulation.floorplanId);
      fetchOrdersEvents({ floorplanId: simulation.floorplanId });

      addFilter({
        id: 'simulator',
        floorplanId: simulation.floorplanId,
        warehouseId: simulation.warehouseId,
        autoRefresh: false,
        filterUpdated: false,
        last: LAST.WEEK,
      });
    }
  }

  public componentDidUpdate(prevProps: any) {
    const { addFilter, fetchFloorplan, fetchOrdersEvents, filters, onChange, simulation } =
      this.props;

    if (prevProps.simulation.floorplanId !== simulation.floorplanId) {
      fetchFloorplan(simulation.floorplanId);
      fetchOrdersEvents({ floorplanId: simulation.floorplanId });

      if (!filters.simulator) {
        addFilter({
          id: 'simulator',
          floorplanId: simulation.floorplanId,
          warehouseId: simulation.warehouseId,
          autoRefresh: false,
          filterUpdated: false,
          last: LAST.WEEK,
        });
      } else {
        onChange({
          id: 'simulator',
          floorplanId: simulation.floorplanId,
          warehouseId: simulation.warehouseId,
        });
      }
    }
  }

  public render() {
    const { language, loading, simulation, ordersEvents } = this.props;

    const LinkRouter = (props: any) => (
      <Link {...props} component={RouterLink} />
    );

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '10px' }}>
          <LinkRouter
            color="inherit"
            to={`/${language}/dashboard/simulations/`}
          >
            <FormattedMessage
              id="dashboard.simulations.breadcrumbs.home"
              defaultMessage="Simulations"
            />
          </LinkRouter>
          <Typography color="textPrimary">
            <FormattedMessage
              id="dashboard.simulations.breadcrumbs.simulationrun"
              defaultMessage="Simulation"
            />
          </Typography>
          <Typography color="textPrimary">
            <FormattedMessage
              id="dashboard.simulations.breadcrumbs.player"
              defaultMessage="Player"
            />
          </Typography>
        </Breadcrumbs>
        <Loading loading={loading}>
          <Map
            showLiveData
            filterId="simulator"
            forceDisabledLayers={['gmaps']}
            layers={{
              simulator: {
                id: 'simulator',
                label: 'Sim',
                disabled: true,
                active: true,
                data: (simulation.config || {}) as ISimulatorConfig,
              },
            }}
            actions={{
              select: {},
              hover: {},
            }}
          />
          <Controls ordersEvents={ordersEvents} />
        </Loading>
      </>
    );
  }
}

export default PlayerContainer;
