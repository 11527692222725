import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import * as actions from '@actions/index';
import { IAppState } from '@reducers/index';
import { getFloorplanById } from '@selectors/floorplans';
import EditFloorplan from './EditFloorplan';

interface IProps {
  floorplanId: string;
  onNext: () => void;
  warehouseId: string;
}

const mapStateToProps = (state: IAppState, { floorplanId }: IProps) => {
  const { name, image } = getFloorplanById(state, floorplanId)!;

  return {
    image: image!,
    loading: state.floorplans.loading,
    name: name!,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { floorplanId, onNext, warehouseId }: IProps
) => ({
  updateFloorplan: (intl: IntlShape, name: string, image?: File) => {
    dispatch(
      actions.updateFloorplan.request({
        floorplanId,
        intl,
        properties: { name, image },
        warehouseId,
      })
    );
    onNext();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFloorplan);
