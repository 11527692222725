import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { toggleZoneMetrics } from '@actions/index';
import { IAppState } from '@reducers/index';
import ZoneMetricsProvider from './ZoneMetricsProvider';

import './index.css';

const mapStateToProps = (state: IAppState) => ({
  meta: state.analytics.meta,
  activeMetrics: state.analytics.activeMetrics,

  zoneMetrics: state.app.zoneMetrics,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleZoneMetrics: (id?: string) => dispatch(toggleZoneMetrics(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoneMetricsProvider);
