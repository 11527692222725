import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';

import Router from './Router';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
});

export default connect(mapStateToProps)(Router);
