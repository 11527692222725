import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { dismissNotifications, INotificationId } from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getActiveLanguage, getSelectedLocale } from '@selectors/app';
import { isNotificationsLoading } from '@selectors/notifications';
import NotificationsList from './NotificationsList';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  loading: isNotificationsLoading(state),
  locale: getSelectedLocale(state),
  measurementUnits: getAccountMeasurementUnits(state.authentication),
  floorplans: state.floorplans.floorplans,
  warehouses: state.warehouses.warehouses,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dismissNotifications: (
    ids: INotificationId[],
    locale: string,
    units: string
  ) => dispatch(dismissNotifications.request({ ids, locale, units })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationsList)
);
