import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import uuid from 'uuid/v4';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import React, { Component } from 'react';

import { IUpdateFloorplan } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import IncompleteFloorplan, { ItemLocation } from '@models/IncompleteFloorplan';

export interface IState {
  id: string;
  reference: string;
}

interface IProps {
  location: ItemLocation;
  floorplan: IncompleteFloorplan;
  onClose: () => void;
  loading: boolean;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
  intl: IntlShape;
}

class ItemLocationForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      id: props.location.id || uuid(),
      reference: props.location.reference || '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'reference'>);
    };
  }

  public handleCancel() {
    const { onClose } = this.props;

    onClose();
  }

  public handleSubmit() {
    const { floorplan, intl, location, updateFloorplan } = this.props;
    const { id, reference } = this.state;

    const itemLocations = floorplan.itemLocations || [];
    const existingIndex = itemLocations.findIndex((i) => i.id === location.id);
    if (existingIndex !== -1) {
      itemLocations[existingIndex] = {
        id,
        reference,
        position: location.position,
      };
    } else {
      itemLocations.push({
        id,
        reference,
        position: location.position,
      });
    }

    updateFloorplan({
      floorplanId: floorplan.id,
      properties: { itemLocations },
      warehouseId: floorplan.warehouseId,
      intl,
      shallow: true,
    });
  }

  public render() {
    const { location, loading } = this.props;
    const { reference } = this.state;

    const buttonDisabled = !reference;

    return (
      <>
        <CardContent>
          <FormControl fullWidth required>
            <InputLabel required htmlFor="itemlocationform-reference">
              <FormattedMessage
                id="dashboard.forms.itemlocationform.label_reference"
                defaultMessage="Position identifier"
              />
            </InputLabel>
            <Input
              id="itemlocationform-reference"
              value={reference}
              onChange={this.handleChange('reference')}
            />
            <FormHelperText>
              <FormattedMessage
                id="dashboard.forms.itemlocationform.field_reference_helper"
                defaultMessage="Required"
              />
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="itemlocationform-coordinates">
              <FormattedMessage
                id="dashboard.forms.itemlocationform.label_coordinates"
                defaultMessage="Coordinates"
              />
            </InputLabel>
            <Input
              id="itemlocationform-coordinates"
              value={(location.position || []).map((p) => p.toFixed(2))}
              disabled
            />
          </FormControl>
        </CardContent>
        <CardActions>
          <Button onClick={this.handleCancel}>
            <FormattedMessage
              id="dashboard.forms.itemlocationform.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color="primary"
            disabled={buttonDisabled}
            loading={loading}
          >
            <FormattedMessage
              id="dashboard.forms.itemlocationform.button_submit"
              defaultMessage="Save"
            />
          </LoadingButton>
        </CardActions>
      </>
    );
  }
}

export default injectIntl(ItemLocationForm);
