import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { closeImportItemsForm, importItems } from '@actions/index';
import { IImport } from '@actions/types';
import { IAppState } from '@reducers/index';
import { isImportFormLoading } from '@selectors/items';
import ImportItemsForm from './ImportItemsForm';

const mapStateToProps = (state: IAppState) => ({
  error: state.items.importItemsForm.error,
  loading: isImportFormLoading(state.items),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeImportItemsForm: () => dispatch(closeImportItemsForm()),
  importItems: (properties: IImport) =>
    dispatch(importItems.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportItemsForm);
