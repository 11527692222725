import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import { deleteAsset, openAssetForm } from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import Asset from '@models/Asset';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import { getCompleteAsset } from '@selectors/assets';
import AssetCard from './AssetCard';

interface IProps {
  data: Asset;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  account: state.authentication.account!,
  asset: getCompleteAsset(
    state.warehouses.warehouses,
    state.floorplans.floorplans,
    props.data
  ),
  language: getActiveLanguage(state),
  floorplans: state.floorplans.floorplans,
  warehouses: state.warehouses.warehouses,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAssetDeletion: (id: string, intl: IntlShape) =>
    dispatch(deleteAsset.request({ id, intl })),
  openAssetForm: (asset?: Asset) => dispatch(openAssetForm(asset)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssetCard)
);
