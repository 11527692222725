import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { fetchDiagrams } from '@actions/diagrams';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import Router from './Router';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchDiagrams: () => dispatch(fetchDiagrams.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
