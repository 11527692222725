import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  createSimulation,
  ICreateSimulation,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { withRouter } from '@app/utils/withRouter';
import { getActiveLanguage } from '@selectors/app';
import SimulationForm from './SimulationForm';

import './index.css';

interface IProps {
  router: any;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  assets: state.assets.assets,
  error: state.simulations.form.error,
  loading: state.simulations.form.loading,
  floorplans: state.floorplans.floorplans,
  warehouses: state.warehouses.warehouses,
  floorplanId: props.router.params.floorplanId,
  language: getActiveLanguage(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createSimulation: (properties: ICreateSimulation) =>
    dispatch(createSimulation.request(properties)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SimulationForm)
);
