import { Color, Path, Point, Matrix } from 'paper';
import { Component } from 'react';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Zone from '@models/Zone';
import { transformMetersToPixels } from '@dashboard_utils/index';
import mapEvents, { orderLayers } from '../../eventEmitter';
import Paper from '../../Paper';
import MapImages from '../../MapImages';
import { MapFeature, IPathFeature } from '../../../types';
import { defaultTransformationMatrix } from '../../../consts';
import { PRIMARY_COLOR } from '../../../../../utils/colors';

interface IProps {
  id: string;
  floorplan: IncompleteFloorplan;
  editFeature?: MapFeature;
  mapImages: MapImages;
  paper: Paper;
  zones: Zone[];
  zoneIds: string[];
}

class ZoneMapLayer extends Component<IProps> {
  private elements: IPathFeature[] = [];

  constructor(props: IProps) {
    super(props);

    this.sendToBack = this.sendToBack.bind(this);
  }

  public componentDidMount() {
    mapEvents.on('order_layers', this.sendToBack);

    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { editFeature, zoneIds, zones } = this.props;

    if (
      JSON.stringify(prevProps.zones || []) !== JSON.stringify(zones || []) ||
      JSON.stringify(prevProps.zoneIds || []) !==
        JSON.stringify(zoneIds || []) ||
      prevProps.editFeature !== editFeature
    ) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    mapEvents.removeListener('order_layers', this.sendToBack);

    this.clear();
  }

  public load() {
    const { floorplan, editFeature, paper, mapImages, zoneIds, zones } =
      this.props;

    paper.scope.activate();

    (zones || [])
      .filter(
        (z) =>
          (!editFeature || z.id !== (editFeature.featureInfo.props || {}).id) &&
          (!zoneIds.length || zoneIds.indexOf(z.id) !== -1)
      )
      .forEach((zone, index) => {
        const path = new Path() as IPathFeature;
        path.strokeColor = new Color(zone.color || PRIMARY_COLOR);
        path.fillColor = new Color((zone.color || PRIMARY_COLOR) + 20);
        path.strokeWidth = 3;
        zone.coordinates.forEach((c) =>
          path.add(
            new Point(
              transformMetersToPixels(
                c as [number, number],
                floorplan.transformationMatrix || defaultTransformationMatrix,
                floorplan.scale || 1
              )
            )
          )
        );
        path.closePath();
        path.strokeScaling = false;
        path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
        this.elements.push(path);

        path.featureInfo = {
          coordinates: zone.coordinates,
          id: `zone_${index}`,
          props: {
            color: new Color(zone.color),
            created: zone.created,
            id: zone.id,
            zoneName: zone.name,
            zoneType: zone.type,
          },
          title: zone.name,
          type: 'zone',
        };
      });

    orderLayers();
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((e) => e.remove());

    this.elements = [];
  }

  public sendToBack(layerType: string) {
    if (layerType === 'other_layers' && this.elements.length) {
      this.elements.forEach((e) => e.sendToBack());
    }
  }

  public render() {
    return null;
  }
}

export default ZoneMapLayer;
