import {
  getObjectFromLocalStorage,
  saveObjectToLocalStorage,
} from '@app/utils/localStorageUtils';

const shortCuts: Record<string, boolean> = (getObjectFromLocalStorage(
  'shortcuts'
) as Record<string, boolean>) || {
  live: true,
  rawlive: false,
  analytics: true,
  diagnostics: false,
  diagrams: false,
  alerts: false,
  player: true,
  rules: false,
  assets: true,
  employees: false,
  items: false,
  orders: false,
  sensors: false,
  simulations: false,
  reports: false,
  settings: false,
  tickets: true,
  rtlsplanner: false,
};

export const updateShortCuts = (data: Record<string, boolean>) => {
  saveObjectToLocalStorage('shortcuts', data);
};

export default shortCuts;
