import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import {
  IActivateAccount,
  IChangeAccountPassword,
  IUpdateAccountPermissions,
} from '@actions/index';
import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import { Date, timeZone } from '@dashboard_utils/index';
import AccountMeta from '@models/AccountMeta';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '../../../../dialogs/ConfirmDialog/types';
import AccountForm from '../../../../forms/AccountForm';
import AccountPermissionsForm from '../../../../forms/AccountPermissionsForm';
import InviteForm from './InviteForm';
import ResetPasswordForm from '../../../../forms/ResetPasswordForm';
import { accountsMessages, messages } from '../messages';

interface IProps {
  accounts: AccountMeta[];
  activateAccount: (properties: IActivateAccount) => void;
  changeAccountPassword: (properties: IChangeAccountPassword) => void;
  confirm: (properties: IConfirmDialog) => void;
  fetchAccounts: () => void;
  intl: IntlShape;
  isAccountFormOpen: boolean;
  isinviteFormOpen: boolean;
  loading: boolean;
  openAccountForm: () => void;
  openInviteForm: () => void;
  updateAccountPermissions: (properties: IUpdateAccountPermissions) => void;
}

interface IState {
  columns: ITableColumn[];
  itemMenuInfo: Record<string, any>;
  permissionsForm?: {
    account: AccountMeta;
    onSubmit: (permissions: string[]) => void;
    onCancel: () => void;
  };
  resetPwdForm?: {
    onSubmit: (password: string) => void;
    onCancel: () => void;
  };
}

const tableMessages = {
  thActive: {
    defaultMessage: 'Active',
    id: 'dashboard.settings.accounts.list.active',
  },
  thCreated: {
    defaultMessage: 'Created',
    id: 'dashboard.settings.accounts.list.created',
  },
  thEmail: {
    defaultMessage: 'Email',
    id: 'dashboard.settings.accounts.list.email',
  },
  thLastSeen: {
    defaultMessage: 'Last Seen',
    id: 'dashboard.settings.accounts.list.lastseen',
  },
  thName: {
    defaultMessage: 'Name',
    id: 'dashboard.settings.accounts.list.name',
  },
  thOptions: {
    defaultMessage: 'Options',
    id: 'dashboard.settings.accounts.list.options',
  },
  thPermissions: {
    defaultMessage: 'Permissions',
    id: 'dashboard.settings.accounts.list.permissions',
  },
};

class AccountsSettings extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      columns: [
        {
          label: ' ',
          name: 'authImage',
          options: { interaction: false },
        },
        { label: intl.formatMessage(tableMessages.thName), name: 'authName' },
        { label: intl.formatMessage(tableMessages.thEmail), name: 'authEmail' },
        { label: "Operator", name: 'operator' },
        { label: intl.formatMessage(tableMessages.thActive), name: 'active' },
        {
          label: intl.formatMessage(tableMessages.thPermissions),
          name: 'permissions',
        },
        { label: intl.formatMessage(tableMessages.thCreated), name: 'created' },
        {
          label: intl.formatMessage(tableMessages.thLastSeen),
          name: 'lastSession',
        },
        {
          label: intl.formatMessage(tableMessages.thOptions),
          name: 'options',
          options: {
            customHeadRender: (columnMeta) => (
              <TableCell key={columnMeta.index} style={{ textAlign: 'right' }}>
                {columnMeta.label}
              </TableCell>
            ),
            interaction: false,
          },
        },
      ],
      itemMenuInfo: {},
    };

    this.customRowRender = this.customRowRender.bind(this);
    this.handleNewAccountForm = this.handleNewAccountForm.bind(this);
    this.handleInviteForm = this.handleInviteForm.bind(this);
  }

  public componentDidMount() {
    const { fetchAccounts } = this.props;

    fetchAccounts();
  }

  public handleNewAccountForm() {
    const { openAccountForm } = this.props;

    openAccountForm();
  }

  public handleInviteForm() {
    const { openInviteForm } = this.props;

    openInviteForm();
  }

  public handleItemMenuClick(index: number) {
    return (event: any) => {
      const { itemMenuInfo } = this.state;

      itemMenuInfo[index] = event.target;

      this.setState({ itemMenuInfo });
    };
  }

  public handleItemMenuClose(index: number) {
    const { itemMenuInfo } = this.state;

    itemMenuInfo[index] = undefined;

    this.setState({ itemMenuInfo });
  }

  public handlePasswordReset(index: number, account: AccountMeta) {
    const { itemMenuInfo } = this.state;
    const { changeAccountPassword, intl } = this.props;

    itemMenuInfo[index] = undefined;

    this.setState({
      itemMenuInfo,
      resetPwdForm: {
        onCancel: () => this.setState({ resetPwdForm: undefined }),
        onSubmit: (password: string) => {
          changeAccountPassword({ id: account.id, intl, password });

          this.setState({ resetPwdForm: undefined });
        },
      },
    });
  }

  public handleAccountPermissionsEdition(index: number, account: AccountMeta) {
    const { itemMenuInfo } = this.state;
    const { intl, updateAccountPermissions } = this.props;

    itemMenuInfo[index] = undefined;

    this.setState({
      itemMenuInfo,
      permissionsForm: {
        account,
        onCancel: () => this.setState({ permissionsForm: undefined }),
        onSubmit: (permissions: string[]) => {
          updateAccountPermissions({ id: account.id, intl, permissions });

          this.setState({ permissionsForm: undefined });
        },
      },
    });
  }

  public handleAccountActivation(
    index: number,
    account: AccountMeta,
    active: boolean
  ) {
    const { itemMenuInfo } = this.state;
    const { confirm, activateAccount, intl } = this.props;

    itemMenuInfo[index] = undefined;

    this.setState({ itemMenuInfo }, () =>
      confirm({
        confirmType: ConfirmDialogActions.ACTION,
        message:
          active === false
            ? intl.formatMessage(accountsMessages.deactivate)
            : intl.formatMessage(accountsMessages.activate),
        onConfirmation: () => {
          activateAccount({
            active,
            id: account.id,
            intl,
          });
        },
      })
    );
  }

  public customRowRender(columns: boolean[], rowData: any, dataIndex: number) {
    const { accounts, intl } = this.props;
    const { itemMenuInfo } = this.state;

    const data = accounts;

    const menu = (
      <TableCell style={{ textAlign: 'right' }}>
        <IconButton
          aria-label={intl.formatMessage(messages.more)}
          aria-owns={itemMenuInfo[dataIndex] ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleItemMenuClick(dataIndex)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={itemMenuInfo[dataIndex]}
          open={!!itemMenuInfo[dataIndex]}
          onClose={() => this.handleItemMenuClose(dataIndex)}
        >
          <MenuItem
            onClick={() => this.handlePasswordReset(dataIndex, data[dataIndex])}
          >
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  id="dashboard.settings.accounts.reset_pwd"
                  defaultMessage="Reset password"
                />
              }
            />
          </MenuItem>
          <MenuItem
            onClick={() =>
              this.handleAccountPermissionsEdition(dataIndex, data[dataIndex])
            }
          >
            <ListItemIcon>
              <LockOpenIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  id="dashboard.settings.accounts.edit_permissions"
                  defaultMessage="Edit permissions"
                />
              }
            />
          </MenuItem>
          {data[dataIndex].active ? (
            <MenuItem
              onClick={() =>
                this.handleAccountActivation(dataIndex, data[dataIndex], false)
              }
            >
              <ListItemIcon>
                <CloseIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="dashboard.settings.accounts.deactivate"
                    defaultMessage="Deactivate account"
                  />
                }
              />
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() =>
                this.handleAccountActivation(dataIndex, data[dataIndex], true)
              }
            >
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="dashboard.settings.accounts.activate"
                    defaultMessage="Activate account"
                  />
                }
              />
            </MenuItem>
          )}
        </Menu>
      </TableCell>
    );

    return (
      <TableRow key={dataIndex}>
        {columns[0] && (
          <TableCell>
            {rowData[0] ? (
              <Avatar
                alt={rowData[1]}
                src={rowData[0] || '/assets/employeePlaceholder.png'}
                style={{ backgroundColor: '#efefef' }}
              />
            ) : null}
          </TableCell>
        )}
        {columns[1] && <TableCell>{rowData[1]}</TableCell>}
        {columns[2] && <TableCell>{rowData[2]}</TableCell>}
        {columns[3] && (
          <TableCell>
            {rowData[3] === true ? (
              <CheckIcon style={{ color: 'green' }} />
            ) : (
              <CloseIcon style={{ color: 'red' }} />
            )}
          </TableCell>
        )}
        {columns[4] && (
          <TableCell>
            {rowData[4] === true ? (
              <CheckIcon style={{ color: 'green' }} />
            ) : (
              <CloseIcon style={{ color: 'red' }} />
            )}
          </TableCell>
        )}
        {columns[5] && <TableCell>{(rowData[5] || []).join('; ')}</TableCell>}
        {columns[6] && (
          <TableCell>{new Date(timeZone, rowData[6]).format()}</TableCell>
        )}
        {columns[7] && (
          <TableCell>
            {rowData[7] ? new Date(timeZone, rowData[7]).format() : null}
          </TableCell>
        )}
        {columns[8] && menu}
      </TableRow>
    );
  }

  public render() {
    const { accounts, isAccountFormOpen, isinviteFormOpen, loading } = this.props;
    const { columns, permissionsForm, resetPwdForm } = this.state;

    const data = accounts;

    const action = (
      <div style={{ display: 'flex' }}>
        <Box mr={2}>
          <Button onClick={this.handleInviteForm}>
            <AddIcon style={{ marginRight: '5px' }} />

            Invite
          </Button>
        </Box>
        <Button onClick={this.handleNewAccountForm}>
          <AddIcon style={{ marginRight: '5px' }} />

          <FormattedMessage
            id="dashboard.settings.accounts.add"
            defaultMessage="Add new account"
          />
        </Button>
      </div>
    );
    const title = (
      <FormattedMessage
        id="dashboard.settings.accounts.title"
        defaultMessage="Accounts"
      />
    );

    return (
      <>
        <Card>
          <CardHeader action={action} title={title} />
          <CardContent style={{ overflow: 'auto' }}>
            <Loading loading={loading}>
              <div className="table-container">
                <MUITable
                  columns={columns}
                  customRowRender={this.customRowRender}
                  data={data}
                  tableId="settings-accounts"
                />
              </div>
            </Loading>
          </CardContent>
        </Card>
        {resetPwdForm ? (
          <ResetPasswordForm
            onOk={resetPwdForm.onSubmit}
            onCancel={resetPwdForm.onCancel}
          />
        ) : null}
        {permissionsForm ? (
          <AccountPermissionsForm
            account={permissionsForm.account}
            onOk={permissionsForm.onSubmit}
            onCancel={permissionsForm.onCancel}
          />
        ) : null}
        {isAccountFormOpen ? <AccountForm /> : null}
        {isinviteFormOpen ? <InviteForm /> : null}
      </>
    );
  }
}

export default injectIntl(AccountsSettings);
