import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { updateScale } from '@actions/index';
import { SensorTypes } from '@models/Sensor';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import { getFloorplanSensorsByType } from '@selectors/sensors';
import { IAppState } from '@reducers/index';
import { LayerScale } from '../../LayerScale/LayerScale';
import ThreeDMap from './3DMap';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    assets: Object.values(state.assets.assets).filter(
      (a) => a.floorplanId === map.floorplanId
    ),
    beacons: getFloorplanSensorsByType(
      state,
      SensorTypes.BEACON,
      map.floorplanId
    ).filter((s) => s.position !== undefined && s.position !== null),
    mapImages: map.mapImages,
    floorplan: getFloorplanById(state, map.floorplanId)!,
    sensors: getFloorplanSensorsByType(
      state,
      SensorTypes.STATIONARY,
      map.floorplanId
    ).filter((s) => s.position !== undefined && s.position !== null),
    zones: state.zones.zones,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateScale: (id: string, scale?: LayerScale) =>
    dispatch(updateScale(id, scale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThreeDMap);
