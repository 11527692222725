import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  createDiagram,
  ICreateDiagram,
  IUpdateDiagram,
  updateDiagram,
} from '@actions/diagrams';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import Composer from './Composer';

const mapStateToProps = (state: IAppState) => ({
  assets: Object.values(state.assets.assets),
  diagrams: state.diagrams.diagrams,
  redirectId: state.diagrams.redirectId,
  employees: Object.values(state.employees.employees),
  language: getActiveLanguage(state),
  warehouses: Object.values(state.warehouses.warehouses),
  floorplans: Object.values(state.floorplans.floorplans),
  zones: Object.values(state.zones.zones),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createDiagram: (properties: ICreateDiagram) =>
    dispatch(createDiagram.request(properties)),
  updateDiagram: (properties: IUpdateDiagram) =>
    dispatch(updateDiagram.request(properties)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Composer)
);
