import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IAppState } from '@reducers/index';
import { toggleWalkthrough } from '@actions/index';
import Walkthrough from './Walkthrough';

const mapStateToProps = (state: IAppState) => ({
  walkthrough: state.app.walkthrough,
  theme: state.app.theme,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleWalkthough: (open: boolean) => dispatch(toggleWalkthrough(open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Walkthrough);
