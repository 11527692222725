import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import { IUpdateFloorplan, updateFloorplan } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getFloorplanById } from '@selectors/floorplans';
import StepContainer from './StepContainer';

export interface IProps {
  floorplanId: string;
  onNext: () => void;
  warehouseId: string;
}

const mapStateToProps = (state: IAppState, { floorplanId }: IProps) => {
  const floorplan = getFloorplanById(state, floorplanId)!;

  return {
    floorplan,
    loading: state.floorplans.loading,
    measurementUnits: getAccountMeasurementUnits(state.authentication),
    showSkip: !!floorplan.scale,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { floorplanId, onNext, warehouseId }: IProps
) => ({
  onNext: (scale: number, intl: IntlShape) => {
    dispatch(
      updateFloorplan.request({
        floorplanId,
        intl,
        properties: { scale },
        warehouseId,
      })
    );
    onNext();
  },
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepContainer);
