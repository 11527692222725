import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import {
  fetchFloorplanSensorGroupStatus,
  IFetchFloorplanSensors,
  updateFloorplan,
} from '@actions/index';
import { IDrawDefinition } from '@models/types';
import { IAppState } from '@reducers/index';
import { getFloorplanById } from '@selectors/floorplans';
import StepContainer from './StepContainer';

interface IProps {
  floorplanId: string;
  onNext: () => void;
  onSkip: () => void;
  warehouseId: string;
  type: string;
}

const mapStateToProps = (state: IAppState, { floorplanId, type }: IProps) => {
  const floorplan = getFloorplanById(state, floorplanId)!;

  return {
    floorplan,
    loading: state.floorplans.loading,
    scale: floorplan.scale!,
    type,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { warehouseId, onNext, onSkip, floorplanId, type }: IProps
) => ({
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => {
    dispatch(fetchFloorplanSensorGroupStatus.request(properties));
  },
  onNext: (features: IDrawDefinition[], intl: IntlShape) => {
    dispatch(
      updateFloorplan.request({
        floorplanId,
        intl,
        properties: { [type]: features },
        warehouseId,
      })
    );
    onNext();
  },
  onSkip: (intl: IntlShape) => {
    dispatch(
      updateFloorplan.request({
        floorplanId,
        intl,
        properties: { [type]: [] },
        warehouseId,
      })
    );
    onSkip();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StepContainer);
