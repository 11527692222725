import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { size } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { IUpdateFloorplan } from '@actions/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Sensor from '@models/Sensor';
import DefineScale from '../DefineScale/index';
import DrawDefinition from '../DrawDefinition/index';
import GeoAlignment from '../GeoAlignment/index';
import Image from '../Image/index';
import SensorAlignment from '../SensorAlignment/index';
import SensorInstallation from '../SensorInstallation/index';
import Zones from '../Zones/index';
import FinancialsSettings from '../FinancialsSettings';
import ItemLocations from '../ItemLocations';

interface IProps {
  floorplan: IncompleteFloorplan;
  language: string;
  loading: boolean;
  router: any;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
  sensors: Sensor[];
}

interface IState {
  value: number;
  installationComplete: boolean;
}

const TABNAMES: Record<number, string> = {
  0: 'image',
  1: 'scale',
  2: 'sensorinstallation',
  3: 'sensoralignment',
  4: 'geoalignment',
  5: 'exteriorboundaries',
  6: 'boundaries',
  7: 'zones',
  8: 'obstacles',
  9: 'racks',
  10: 'landmarks',
  11: 'financialsettings',
};

class TabsComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { router } = props;
    const { tab } = router.params;

    this.state = {
      installationComplete: size(props.sensors) > 1,
      value: tab
        ? Object.values(TABNAMES).findIndex((name) => name === tab)
        : 0,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { router, sensors } = this.props;

    if (JSON.stringify(prevProps.sensors) !== JSON.stringify(sensors)) {
      this.setInstallStatus();
    }

    if (router.params.tab !== prevProps.router.params.tab) {
      this.setValue(router.params.tab);
    }
  }

  public handleChange(event: any, value: number) {
    const { floorplan, router, language } = this.props;

    router.navigate(
      `/${language}/dashboard/settings/warehouses/${router.params.warehouseId}/${floorplan.id}/${TABNAMES[value]}`
    );
  }

  public setInstallStatus() {
    const { sensors } = this.props;

    this.setState({
      installationComplete: size(sensors) > 1,
    });
  }

  public setValue(tab: string) {
    this.setState({
      value: tab
        ? Object.values(TABNAMES).findIndex((name) => name === tab)
        : 0,
    });
  }

  public render() {
    const { floorplan, loading, updateFloorplan, sensors } = this.props;
    const { value, installationComplete } = this.state;

    return (
      <>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
          >
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.image"
                    defaultMessage="Image"
                  />
                </span>
              }
              icon={
                floorplan.image ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.scale_definition"
                    defaultMessage="Scale Definition"
                  />
                </span>
              }
              icon={
                floorplan.scale ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.sensor_installation"
                    defaultMessage="Sensor Installation"
                  />
                </span>
              }
              icon={
                installationComplete ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.sensor_alignment"
                    defaultMessage="Sensor Alignment"
                  />
                </span>
              }
              icon={
                floorplan.transformationMatrix ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.geo_mapping"
                    defaultMessage="Geo Mapping"
                  />
                </span>
              }
              icon={
                floorplan.geoMapping ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.exteriorboundaries_definition"
                    defaultMessage="Exterior Boundaries Definition"
                  />
                </span>
              }
              icon={
                floorplan.exteriorBoundaries ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.boundaries_definition"
                    defaultMessage="Boundaries Definition"
                  />
                </span>
              }
              icon={
                floorplan.boundaries ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.zones"
                    defaultMessage="Zones"
                  />
                </span>
              }
              icon={
                floorplan.transformationMatrix ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.obstacles_definition"
                    defaultMessage="Obstacles Definition"
                  />
                </span>
              }
              icon={
                floorplan.obstacles ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.racks_definition"
                    defaultMessage="Racks Definition"
                  />
                </span>
              }
              icon={
                floorplan.racks ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.item_locations_definition"
                    defaultMessage="Item Locations Definition"
                  />
                </span>
              }
              icon={
                floorplan.racks ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.landmarks_definition"
                    defaultMessage="Landmarks Definition"
                  />
                </span>
              }
              icon={
                floorplan.landmarks ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
            <Tab
              label={
                <span style={{ paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.forms.floorplanconfigurationform.tabs.financial_settings"
                    defaultMessage="Financial Settings"
                  />
                </span>
              }
              icon={
                floorplan.landmarks ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CloseIcon style={{ color: 'red' }} />
                )
              }
            />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <Image
            floorplan={floorplan}
            loading={loading}
            updateFloorplan={updateFloorplan}
          />
        )}
        {value === 1 && <DefineScale floorplan={floorplan} loading={loading} />}
        {value === 2 && (
          <SensorInstallation
            installationComplete={installationComplete}
            floorplan={floorplan}
            loading={loading}
            updateFloorplan={updateFloorplan}
          />
        )}
        {value === 3 && (
          <SensorAlignment
            floorplan={floorplan}
            loading={loading}
            updateFloorplan={updateFloorplan}
            sensors={sensors}
          />
        )}
        {value === 4 && (
          <GeoAlignment
            floorplan={floorplan}
            loading={loading}
            updateFloorplan={updateFloorplan}
          />
        )}
        {value === 5 && (
          <DrawDefinition
            floorplan={floorplan}
            loading={loading}
            updateFloorplan={updateFloorplan}
            sensors={sensors}
            type="exteriorBoundaries"
          />
        )}
        {value === 6 && (
          <DrawDefinition
            floorplan={floorplan}
            loading={loading}
            updateFloorplan={updateFloorplan}
            sensors={sensors}
            type="boundaries"
          />
        )}
        {value === 7 && <Zones floorplan={floorplan} loading={loading} />}
        {value === 8 && (
          <DrawDefinition
            floorplan={floorplan}
            loading={loading}
            updateFloorplan={updateFloorplan}
            sensors={sensors}
            type="obstacles"
          />
        )}
        {value === 9 && (
          <DrawDefinition
            floorplan={floorplan}
            loading={loading}
            updateFloorplan={updateFloorplan}
            sensors={sensors}
            type="racks"
          />
        )}
        {value === 10 && (
          <ItemLocations
            floorplan={floorplan}
            loading={loading}
            updateFloorplan={updateFloorplan}
          />
        )}
        {value === 11 && (
          <DrawDefinition
            floorplan={floorplan}
            loading={loading}
            updateFloorplan={updateFloorplan}
            sensors={sensors}
            type="landmarks"
          />
        )}
        {value === 12 && <FinancialsSettings floorplan={floorplan} />}
      </>
    );
  }
}

export default TabsComponent;
