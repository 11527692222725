import React, { FunctionComponent } from 'react';

import CreateWarehouse from './CreateWarehouseContainer';
import EditWarehouse from './EditWarehouseContainer';

interface IProps {
  onNext: () => void;
  warehouseId?: string;
}

const WarehouseStep: FunctionComponent<IProps> = ({
  onNext,
  warehouseId = undefined,
}: IProps) =>
  warehouseId === undefined ? (
    <CreateWarehouse onNext={onNext} />
  ) : (
    <EditWarehouse onNext={onNext} warehouseId={warehouseId} />
  );

export default WarehouseStep;
