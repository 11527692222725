import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { signUp } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import SignUpPage from './SignUpPage';

const mapStateToProps = (state: IAppState) => ({
  error: state.authentication.signup.error,
  language: getActiveLanguage(state),
  loading: state.authentication.signup.loading,
  signUpSuccess: state.authentication.signup.success,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    signUp: (name: string, company: string, email: string, password: string) =>
      dispatch(signUp.request({ name, company, email, password })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
