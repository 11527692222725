import { Point, Raster, Matrix } from 'paper';
import { Component } from 'react';
import uuid from 'uuid/v4';

import { transformMetersToPixels } from '@dashboard_utils/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import mapEvents, { orderLayers } from '../../eventEmitter';
import Paper from '../../Paper';
import { defaultTransformationMatrix } from '../../../consts';
import { loadImage } from '../../../../../utils/mapUtils';
import ENULocations from '../../../ENULocations';
import MapImages from '../../MapImages';

interface IRaster {
  id: string;
  raster: paper.Raster;
}

interface IProps {
  color: boolean;
  floorplan: IncompleteFloorplan;
  isSatellite: boolean;
  mapImages: MapImages;
  paper: Paper;
}

class BackgroundLayer extends Component<IProps> {
  private map: IRaster | undefined;

  private mounted = false;

  constructor(props: IProps) {
    super(props);

    this.sendToBack = this.sendToBack.bind(this);
  }

  public componentDidMount() {
    this.mounted = true;

    mapEvents.on('order_layers', this.sendToBack);

    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { color } = this.props;

    if (prevProps.color !== color) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    this.mounted = false;

    mapEvents.removeListener('order_layers', this.sendToBack);

    this.clear();
  }

  public load() {
    const { color, floorplan, isSatellite, mapImages, paper } = this.props;

    if (!floorplan.geoMapping || floorplan.geoMapping.length !== 2) {
      return;
    }

    const id = isSatellite
      ? `${floorplan.satelliteImage}_${color}`
      : `${floorplan.roadmapImage}_${color}`;

    if (!this.map || this.map.id !== id) {
      let imgUrl: string | undefined;
      if (isSatellite) {
        imgUrl =
          color === true
            ? floorplan.satelliteImage
            : floorplan.satelliteBWImage;
      } else {
        imgUrl =
          color === true ? floorplan.roadmapImage : floorplan.roadmapBWImage;
      }

      const loadingId = uuid();
      mapEvents.emit('loading', { loadingId, type: 'start' });
      loadImage(imgUrl || '', (err, image) => {
        mapEvents.emit('loading', { loadingId, type: 'end' });

        if (err || !image || !this.mounted) {
          return;
        }

        const enuLocations = new ENULocations(
          [floorplan.geoMapping![0], floorplan.geoMapping![1]],
          floorplan.transformationMatrix || defaultTransformationMatrix,
        );

        const enuCenter = enuLocations.getENUCenter();
        const enuCenterPx = transformMetersToPixels(
          enuCenter,
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        );

        paper.scope.activate();

        const zoomLevel = 20;
        const metersPerPixel = (
          Math.cos(floorplan.geoMapping![0].coordinate.latitude * Math.PI / 180) * 2 * Math.PI
        ) * 6378137 / (256 * Math.pow(2, zoomLevel));

        const raster = new Raster(image);
        raster.position = new Point(enuCenterPx[0], enuCenterPx[1]).transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

        raster.scale((floorplan.scale || 1) / (1 / metersPerPixel));

        const locationsDelta = enuLocations.getDeltas();
        raster.rotate(
          locationsDelta.transformationAngle - locationsDelta.angleDiff
        );
        raster.addTo(paper.scope.project);

        this.map = { id, raster };
        orderLayers();
      });

      return;
    }

    paper.scope.activate();

    this.map.raster.addTo(paper.scope.project);

    orderLayers();
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    if (this.map) {
      this.map.raster.remove();
    }

    this.map = undefined;
  }

  public sendToBack(layerType: string) {
    if (layerType === 'background' && this.map) {
      this.map.raster.sendToBack();
    }
  }

  public render() {
    return null;
  }
}

export default BackgroundLayer;
