import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Alert from '@mui/material/Alert';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import React, { Component } from 'react';
import Papa from 'papaparse';
import { Link as RouterLink } from 'react-router-dom';

import { IAgentInfo, ICreateSimulation } from '@actions/index';
import CustomTableCell from '@app/common/mui-custom/CustomTableCell';
import LoadingButton from '@app/common/LoadingButton';
import { Date, timeZone } from '@dashboard_utils/index';
import Asset, { Assets, AssetTypes } from '@models/Asset';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import { Warehouses } from '@models/Warehouse';
import { getWarehouseIdByFloorplanId } from '../../../../utils/floorplanUtils';
import { getAssetTypeName } from '../../../../forms/AssetForm/utils';
import { Box } from '@mui/system';
import { Breadcrumbs, TableCell } from '@mui/material';
import Loading from '@app/common/Loading';
import FetchError from '@models/FetchError';

export const simulationLengths = [
  {
    label: (
      <>
        <span style={{ marginRight: '5px' }}>30</span>
        <FormattedMessage id="dashboard.minutes" defaultMessage="Minutes" />
      </>
    ),
    value: 30 * 60 * 1000,
  },
  {
    label: (
      <>
        <span style={{ marginRight: '5px' }}>1</span>
        <FormattedMessage id="dashboard.hour" defaultMessage="Hour" />
      </>
    ),
    value: 60 * 60 * 1000,
  },
  {
    label: (
      <>
        <span style={{ marginRight: '5px' }}>8</span>
        <FormattedMessage id="dashboard.hours" defaultMessage="Hours" />
      </>
    ),
    value: 8 * 60 * 60 * 1000,
  },
  {
    label: (
      <>
        <span style={{ marginRight: '5px' }}>1</span>
        <FormattedMessage id="dashboard.day" defaultMessage="Day" />
      </>
    ),
    value: 24 * 60 * 60 * 1000,
  },
  {
    label: (
      <>
        <span style={{ marginRight: '5px' }}>1</span>
        <FormattedMessage id="dashboard.week" defaultMessage="Week" />
      </>
    ),
    value: 7 * 24 * 60 * 60 * 1000,
  },
  {
    label: (
      <>
        <span style={{ marginRight: '5px' }}>1</span>
        <FormattedMessage id="dashboard.month" defaultMessage="Month" />
      </>
    ),
    value: 30 * 24 * 60 * 60 * 1000,
  },
  {
    label: (
      <>
        <span style={{ marginRight: '5px' }}>3</span>
        <FormattedMessage id="dashboard.months" defaultMessage="Months" />
      </>
    ),
    value: 90 * 24 * 60 * 60 * 1000,
  },
  {
    label: (
      <>
        <span style={{ marginRight: '5px' }}>1</span>
        <FormattedMessage id="dashboard.year" defaultMessage="Year" />
      </>
    ),
    value: 365 * 24 * 60 * 60 * 1000,
  },
];

const intervals = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

const handleFileInputCsvUploadClick = (event: any) => {
  event.stopPropagation();

  document.getElementById('input-file-csv')!.click();
};

export const routers = ['TSP', 'bath', 'bath2', 'bath3'];
const colors = ['#3876BF', '#E1AA74', '#F3F0CA'];

interface Shift {
  activePeriods: Record<number, boolean>;
  tmpPeriods: Record<number, boolean>;
}

interface IProps {
  assets: Assets;
  createSimulation: (properties: ICreateSimulation) => void;
  floorplanId?: string;
  floorplans: IncompleteFloorplans;
  intl: IntlShape;
  language: string;
  loading: boolean;
  error?: FetchError;
  warehouses: Warehouses;
  router: any;
}

export interface IState {
  agents: IAgentInfo[];
  floorplanId: string;
  modelFloorplanId: string;
  length: number;
  name: string;
  orderVolume: number;
  router: string;
  routerDynamic: boolean;
  inputCsv?: string;
  startTs: Date;
  warehouseId: string;
  shifts: Shift[];
  selectedShift?: number;
  startClickIndex?: number;
  submitted: boolean;
}

class SimulationForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { assets, floorplans, floorplanId, warehouses } = this.props;
    const warehouse =
      warehouses[
        getWarehouseIdByFloorplanId(floorplans, floorplanId || '') || ''
      ];
    const warehouseId = warehouse ? warehouse.id : '';
    const warehouseTz = (warehouse || {}).timezone || timeZone;

    this.state = {
      agents: Object.values(assets)
        .filter((a) => a.floorplanId === (floorplanId || ''))
        .map((a) => ({ assetId: a.id, shift: -1 })),
      floorplanId: floorplanId || '',
      modelFloorplanId: floorplanId || '',
      length: simulationLengths[0].value,
      name: '',
      orderVolume: 100,
      router: '',
      routerDynamic: false,
      startTs: new Date(warehouseTz),
      warehouseId,
      shifts: [],
      submitted: false,
    };

    this.handleFileInputFileCsvUpload = this.handleFileInputFileCsvUpload.bind(this);
    this.handleAddAgent = this.handleAddAgent.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.addShift = this.addShift.bind(this);
    this.selectShift = this.selectShift.bind(this);
    this.setDragDown = this.setDragDown.bind(this);
    this.setDragUp = this.setDragUp.bind(this);
    this.setDragMove = this.setDragMove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { submitted } = this.state;
    const { language, router, loading, error } = this.props;

    if (prevProps.loading == true && loading === false && submitted === true && !error) {
      router.navigate(`/${language}/dashboard/simulations`);
    }
  }

  public handleAddAgent() {
    const { agents } = this.state;

    agents.push({ shift: -1 });

    this.setState({ agents });
  }

  public handleRemoveAgent(index: number) {
    const { agents } = this.state;

    agents.splice(index, 1);

    this.setState({ agents });
  }

  public handleAgentChange(key: keyof IAgentInfo, index: number) {
    return (e: any) => {
      const { agents } = this.state;

      const { value } = e.target;
      // @ts-ignore
      agents[index][key] = value;

      this.setState({ agents });
    };
  }

  public handleChange(e: any) {
    const { name } = e.target;
    const value: string | boolean =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    const { assets, floorplanId } = this.props;
    const { warehouseId } = this.state;

    const newState: any = {};
    newState[name] = value || '';
    if (name === 'warehouseId' && value !== warehouseId) {
      newState.floorplanId = '';
      newState.modelFloorplanId = '';
    }
    if (name === 'floorplanId') {
      newState.modelFloorplanId = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      newState.agents = Object.values(assets).filter(
        (a) => a.floorplanId === (floorplanId || '')
      ).length;
    }

    this.setState(newState as Pick<IState, 'floorplanId'>);
  }

  public handleSliderChange(event: any, value: any) {
    this.setState({ orderVolume: value });
  }

  public handleDateTimeChange(warehouseTz: string, date?: Date) {
    // On keystroke date can be incomplete, update only when valid
    if (date) {
      // KeyboardDatepicker returns a string when edited by keyboard
      // that string has to be read into the target timezone and converted to ts
      this.setState({ startTs: new Date(warehouseTz, date, warehouseTz) });
    }
  }

  public handleFileInputFileCsvUpload(event: any) {
    const { warehouses, floorplans } = this.props;
    const { files } = event.target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (e.target !== null) {
          const csv = Papa.parse(e.target.result);
          const formData = {} as IState;
          const agents: IAgentInfo[] = [];

          let isType = false;
          csv.data.forEach((r: any) => {
            if (r[0] === 'Warehouse') {
              formData.warehouseId = (Object.values(warehouses).find((w) => w.name.toLowerCase() === (r[1] || '').toLowerCase()) || {}).id || '';
              isType = false;
              return;
            }
            if (r[0] === 'Floorplan') {
              formData.floorplanId = (Object.values(floorplans).find((f) => f.name.toLowerCase() === (r[1] || '').toLowerCase()) || {}).id || '';
              isType = false;
              return;
            }
            if (r[0] === 'Floorplan For Models') {
              formData.modelFloorplanId = (Object.values(floorplans).find((f) => f.name.toLowerCase() === (r[1] || '').toLowerCase()) || {}).id || '';
              isType = false;
              return;
            }
            if (r[0] === 'Simulation Name') {
              formData.name = r[1];
              isType = false;
              return;
            }
            if (r[0] === 'Simulation Name') {
              formData.name = r[1];
              isType = false;
              return;
            }
            if (r[0] === 'Simulation Length (in milliseconds)') {
              formData.length = Number(r[1]);
              isType = false;
              return;
            }
            if (r[0] === 'Simulation Start') {
              formData.startTs = new Date(r[1]);
              isType = false;
              return;
            }
            if (r[0] === 'Router') {
              formData.router = r[1];
              isType = false;
              return;
            }
            if (r[0] === 'Order Volume (%)') {
              formData.orderVolume = Number(r[1]);
              isType = false;
              return;
            }
            if (r[0] === 'Asset Type') {
              isType = true;
              return;
            }
            if (isType) {
              agents.push({
                type: r[0] as AssetTypes,
                capacity: Number(r[1]),
                shift: -1,
              });
              return;
            }
          });

          this.setState({ ...formData, agents, inputCsv: e.target.result });
        }
      };

      reader.readAsText(files[0]);
    }
  }

  public handleCancel() {
    const { language, router } = this.props;

    router.navigate(`/${language}/dashboard/simulations`);
  }

  public addShift() {
    const { shifts } = this.state;

    if (shifts.length > 2) {
      return;
    }

    shifts.push({
      activePeriods: {},
      tmpPeriods: {},
    });

    this.setState({ shifts });
  }

  public selectShift(i: number) {
    this.setState({ selectedShift: i });
  }

  public setDragDown(e: any, i: number) {
    e.stopPropagation();

    this.setState({ startClickIndex: i });
  }

  public setDragUp(e: any, i: number) {
    e.stopPropagation();

    const { selectedShift, startClickIndex, shifts } = this.state;

    if (selectedShift === undefined || startClickIndex === undefined) {
      return;
    }

    const shiftsCp = [...shifts];
    for (let y = startClickIndex || 0; y <= i; y += 1) {
      shiftsCp[selectedShift].activePeriods[y] = !shiftsCp[selectedShift].activePeriods[y];
      shiftsCp[selectedShift].tmpPeriods = {};
    }
    this.setState({ shifts: shiftsCp, startClickIndex: undefined });
  }

  public setDragMove(e: any, i: number) {
    e.stopPropagation();

    const { selectedShift, startClickIndex, shifts } = this.state;

    if (selectedShift === undefined || startClickIndex === undefined) {
      return;
    }

    const shiftsCp = [...shifts];
    shiftsCp[selectedShift].tmpPeriods = {};
    for (let y = startClickIndex || 0; y <= i; y += 1) {
      shiftsCp[selectedShift].tmpPeriods[y] = !shiftsCp[selectedShift].tmpPeriods[y];
    }
    this.setState({ shifts: shiftsCp });
  }

  public handleSubmit() {
    const {
      agents,
      floorplanId,
      modelFloorplanId,
      name,
      length,
      orderVolume,
      router,
      routerDynamic,
      inputCsv,
      startTs,
      shifts,
    } = this.state;
    const { createSimulation } = this.props;

    this.setState({ submitted: true }, () => {
      createSimulation({
        agents: agents.map((a) => ({ 
          ...a,
          assetId: a.assetId || undefined,
          capacity: a.capacity || a.capacity == 0 ? Number(a.capacity) : undefined,
          shift: a.shift || a.shift == 0 ? Number(a.shift) : -1
        })),
        floorplanId,
        modelFloorplanId,
        length,
        name,
        orderVolume,
        router: router.length ? router : undefined,
        routerDynamic: router.length ? routerDynamic == true : undefined,
        inputCsv,
        // Get ts in warehouse timezone
        startTs: startTs.getTime(),
        shifts: shifts.filter((s: Shift) => Object.values(s.activePeriods).find((p) => !!p)).map((s: Shift) => s.activePeriods),
      });
    });
  }

  public render() {
    const { assets, floorplans, warehouses, language, loading } = this.props;
    const {
      agents,
      floorplanId,
      modelFloorplanId,
      name,
      length,
      router,
      routerDynamic,
      inputCsv,
      orderVolume,
      shifts,
      selectedShift,
      startTs,
      warehouseId,
    } = this.state;

    const warehouseTz = (warehouses[warehouseId] || {}).timezone || timeZone;

    const error =
      name.length === 0 ||
      agents.filter((a) => !a.assetId && !a.type).length > 0 ||
      !startTs ||
      !length ||
      warehouseId.length === 0 ||
      floorplanId.length === 0;

    const whs = Object.values(warehouses);
    const fps = Object.values(floorplans).filter(
      (fp) => fp.warehouseId === warehouseId
    );

    const buttonDisabled = error;

    const LinkRouter = (props: any) => (
      <Link {...props} component={RouterLink} />
    );

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '10px' }}>
          <LinkRouter
            color="inherit"
            to={`/${language}/dashboard/simulations/`}
          >
            <FormattedMessage
              id="dashboard.simulations.breadcrumbs.home"
              defaultMessage="Simulations"
            />
          </LinkRouter>
          <Typography color="textPrimary">
            New Simulation
          </Typography>
        </Breadcrumbs>
        <Loading loading={loading}>
          <Card style={{ overflow: 'visible' }}>
            <CardContent>
              <Grid container>
                <Grid item xs={6} md={4} lg={4}>
                  <FormControl required fullWidth margin="normal">
                    <TextField
                      name="name"
                      variant="standard"
                      onChange={this.handleChange}
                      value={name}
                      label={
                        <FormattedMessage
                          id="dashboard.forms.simulationform.label_name"
                          defaultMessage="Simulation name"
                        />
                      }
                    />
                    <FormHelperText>
                      <FormattedMessage
                        id="dashboard.forms.simulationform.field_name_helper"
                        defaultMessage="A simulation name must be specified"
                      />
                    </FormHelperText>
                  </FormControl>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <CustomTableCell rowSpan={2}>
                          <FormattedMessage
                            id="dashboard.forms.simulationform.agents.number"
                            defaultMessage="Agent"
                          />
                        </CustomTableCell>
                        <CustomTableCell colSpan={3}>
                          <FormattedMessage
                            id="dashboard.forms.simulationform.agents.link"
                            defaultMessage="Link"
                          />
                        </CustomTableCell>
                      </TableRow>
                      <TableRow>
                        <CustomTableCell>
                          <FormattedMessage
                            id="dashboard.forms.simulationform.agents.asset"
                            defaultMessage="Existing Asset"
                          />
                        </CustomTableCell>
                        <CustomTableCell>
                          <FormattedMessage
                            id="dashboard.forms.simulationform.agents.assettype"
                            defaultMessage="Asset Type"
                          />
                        </CustomTableCell>
                        <CustomTableCell>
                          <FormattedMessage
                            id="dashboard.forms.simulationform.agents.assetcapacity"
                            defaultMessage="Asset Capacity"
                          />
                        </CustomTableCell>
                        <CustomTableCell>Shift</CustomTableCell>
                      </TableRow>
                    </TableHead>
                    {agents.map((a, index) => (
                      <TableBody key={index}>
                        <TableRow hover={false}>
                          <CustomTableCell>{index + 1}</CustomTableCell>
                          <CustomTableCell>
                            <Box mt={2}>
                              <FormControl fullWidth margin="dense">
                                <Select
                                  disabled={!!a.type}
                                  value={a.assetId || ''}
                                  onChange={this.handleAgentChange('assetId', index)}
                                  input={<Input name="assetId" />}
                                >
                                  <MenuItem value="">
                                    <FormattedMessage
                                      id="dashboard.forms.simulationform.agents.none"
                                      defaultMessage="None"
                                    />
                                  </MenuItem>
                                  {Object.values(assets)
                                    .filter(
                                      (asset: Asset) =>
                                        asset.floorplanId === (floorplanId || '')
                                    )
                                    .map((asset: Asset) => (
                                      <MenuItem key={asset.id} value={asset.id}>
                                        {asset.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </CustomTableCell>
                          <CustomTableCell>
                            <Box mt={2}>
                              <FormControl fullWidth margin="dense">
                                <Select
                                  disabled={!!a.assetId}
                                  value={a.type || ''}
                                  onChange={this.handleAgentChange('type', index)}
                                  input={<Input name="type" />}
                                >
                                  <MenuItem value="">
                                    <FormattedMessage
                                      id="dashboard.forms.simulationform.agents.none"
                                      defaultMessage="None"
                                    />
                                  </MenuItem>
                                  {Object.keys(AssetTypes)
                                    // Display only asset types that exist
                                    .filter(
                                      (key: string) =>
                                        !!Object.values(assets).find(
                                          (asset: Asset) =>
                                            asset.type ===
                                            AssetTypes[key as keyof typeof AssetTypes]
                                        )
                                    )
                                    .map((key: string) => (
                                      <MenuItem
                                        key={key}
                                        value={AssetTypes[key as keyof typeof AssetTypes]}
                                      >
                                        {getAssetTypeName(
                                          AssetTypes[key as keyof typeof AssetTypes]
                                        )}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </CustomTableCell>
                          <CustomTableCell>
                            <FormControl fullWidth margin="dense">
                              <TextField
                                name="capacity"
                                variant="standard"
                                onChange={this.handleAgentChange('capacity', index)}
                                value={a.capacity || 1}
                                type="number"
                                inputProps={{ min: 1 }}
                                label={
                                  <FormattedMessage
                                    id="dashboard.forms.simulationform.agents.assetcapacity"
                                    defaultMessage="Asset Capacity"
                                  />
                                }
                              />
                            </FormControl>
                          </CustomTableCell>
                          <CustomTableCell>
                            <Box mt={2}>
                              <FormControl fullWidth margin="dense">
                                <Select
                                  value={a.shift || -1}
                                  onChange={this.handleAgentChange('shift', index)}
                                  input={<Input name="shift" />}
                                >
                                  <MenuItem value="-1">All</MenuItem>
                                  <MenuItem value="0">1</MenuItem>
                                  <MenuItem value="1">2</MenuItem>
                                  <MenuItem value="2">3</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </CustomTableCell>
                          <CustomTableCell
                            style={{ textAlign: 'right', paddingRight: '3px' }}
                          >
                            <RemoveIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.handleRemoveAgent(index)}
                            />
                          </CustomTableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                    <TableBody>
                      <TableRow hover={false}>
                        <CustomTableCell
                          style={{ textAlign: 'right', paddingRight: '3px' }}
                          colSpan={5}
                        >
                          <AddIcon
                            style={{ cursor: 'pointer' }}
                            onClick={this.handleAddAgent}
                          />
                        </CustomTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <FormControl required fullWidth margin="normal">
                    <span style={{ float: 'left' }}>
                      <FormattedMessage
                        id="dashboard.forms.simulationform.label_ordervolume"
                        defaultMessage="Order Volume"
                      />
                      <span>: </span>
                      <b style={{ paddingLeft: '5px' }}>
                        {orderVolume}
                        <span>%</span>
                      </b>
                    </span>
                    <Slider
                      aria-labelledby="discrete-slider-small-steps"
                      onChange={this.handleSliderChange}
                      step={25}
                      marks
                      min={25}
                      max={500}
                      value={orderVolume}
                      valueLabelDisplay="auto"
                    />
                  </FormControl>
                  <FormControl required fullWidth margin="normal">
                    <DateTimePicker
                      value={startTs}
                      onChange={(date: any) =>
                        this.handleDateTimeChange(warehouseTz, date)
                      }
                      label={
                        <FormattedMessage
                          id="dashboard.forms.simulationform.label_startts"
                          defaultMessage="Starting Date"
                        />
                      }
                      slotProps={{
                        textField: { variant: 'standard' }
                      }}
                      ampm={false}
                    />
                    <FormHelperText>
                      <FormattedMessage
                        id="dashboard.forms.simulationform.field_startts_helper"
                        defaultMessage="The starting date must be specified"
                      />
                    </FormHelperText>
                  </FormControl>
                  <FormControl required fullWidth margin="normal">
                    <InputLabel htmlFor="runsimulation-length">
                      <FormattedMessage
                        id="dashboard.forms.simulationform.label_length"
                        defaultMessage="Simulation length"
                      />
                    </InputLabel>
                    <Select
                      id="simulation-form-length"
                      value={length}
                      onChange={this.handleChange}
                      name="length"
                      input={<Input name="length" />}
                    >
                      {simulationLengths.map((simulationLength) => (
                        <MenuItem
                          key={simulationLength.value}
                          value={simulationLength.value}
                        >
                          {simulationLength.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      <FormattedMessage
                        id="dashboard.forms.simulationform.field_length_helper"
                        defaultMessage="A simulation length must be specified"
                      />
                    </FormHelperText>
                  </FormControl>
                  <FormControl required={false} fullWidth margin="normal">
                    <InputLabel htmlFor="runsimulation-router">
                      <FormattedMessage
                        id="dashboard.forms.simulationform.label_router"
                        defaultMessage="Router"
                      />
                    </InputLabel>
                    <Select
                      id="simulation-form-router"
                      value={router}
                      onChange={this.handleChange}
                      name="router"
                      input={<Input name="router" />}
                    >
                      <MenuItem value="">
                        <FormattedMessage
                          id="dashboard.forms.simulationform.router.none"
                          defaultMessage="None"
                        />
                      </MenuItem>
                      {routers.map((r) => (
                        <MenuItem key={r} value={r}>
                          {r}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {router ? (
                    <FormControl required fullWidth margin="normal">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={routerDynamic}
                            onChange={this.handleChange}
                            name="routerDynamic"
                          />
                        }
                        label={
                          <FormattedMessage
                            id="dashboard.forms.simulationform.label_dynamicrouting"
                            defaultMessage="Dynamic Routing"
                          />
                        }
                      />
                    </FormControl>
                  ) : null}
                  <FormControl required fullWidth margin="normal">
                    <InputLabel htmlFor="simulation-form-warehouseid">
                      <FormattedMessage
                        id="dashboard.forms.simulationform.label_warehouse"
                        defaultMessage="Warehouse"
                      />
                    </InputLabel>
                    <Select
                      id="form-warehouseid"
                      value={warehouseId}
                      onChange={this.handleChange}
                      name="warehouseId"
                      input={
                        <Input name="warehouseid" id="simulation-form-warehouseid" />
                      }
                    >
                      {warehouses &&
                        whs.map((w) => (
                          <MenuItem key={w.id} value={w.id}>
                            {w.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl required fullWidth margin="normal">
                    <InputLabel htmlFor="simulation-form-floorplanid">
                      <FormattedMessage
                        id="dashboard.forms.simulationform.label_floorplan"
                        defaultMessage="Floor plan"
                      />
                    </InputLabel>
                    <Select
                      id="form-floorplanid"
                      value={floorplanId}
                      onChange={this.handleChange}
                      name="floorplanId"
                      input={
                        <Input name="floorplanid" id="simulation-form-floorplanid" />
                      }
                    >
                      {warehouses &&
                        fps.map((fp) => (
                          <MenuItem key={fp.id} value={fp.id}>
                            {fp.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl required fullWidth margin="normal">
                    <InputLabel htmlFor="simulation-form-modelfloorplanid">
                      <FormattedMessage
                        id="dashboard.forms.simulationform.label_modelfloorplan"
                        defaultMessage="Floor plan for models"
                      />
                    </InputLabel>
                    <Select
                      id="form-modelfloorplanid"
                      value={modelFloorplanId}
                      onChange={this.handleChange}
                      name="modelFloorplanId"
                      input={
                        <Input name="modelfloorplanid" id="simulation-form-modelfloorplanid" />
                      }
                    >
                      {warehouses &&
                        fps.map((fp) => (
                          <MenuItem key={fp.id} value={fp.id}>
                            {fp.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>
                      {inputCsv ? inputCsv.slice(0, 100) : (
                        <FormattedMessage
                          id="dashboard.forms.simulationform.label_input_file_csv"
                          defaultMessage="Input File (CSV)"
                        />
                      )}
                    </InputLabel>
                    <Button
                      style={{ width: '100%', height: '75px' }}
                      variant="outlined"
                      color="primary"
                      onClick={handleFileInputCsvUploadClick}
                    >
                      <FormattedMessage
                        id="dashboard.forms.simulationform.upload_input_file_csv"
                        defaultMessage="Upload Input File CSV file"
                      />
                    </Button>
                    <input
                      id="input-file-csv"
                      accept="csv/*"
                      type="file"
                      style={{ display: 'none' }}
                      onInput={this.handleFileInputFileCsvUpload}
                    />
                    <Box mt={1}>
                      <small><a href="/assets/input-file.csv">Input File Sample CSV</a></small>
                    </Box>
                  </FormControl>
                  <FormHelperText>
                    <FormattedMessage
                      id="dashboard.forms.simulationform.csv_helper"
                      defaultMessage="CSV file, UTF-8 comma limited, containing a list of Code,Description entries"
                    />
                  </FormHelperText>
                  {agents.length === 0 ? (
                    <Alert severity="error">
                      <FormattedMessage
                        id="dashboard.forms.simulationform.noagents"
                        defaultMessage="You have no assets on this floorplan - must have 1 or more assets to run simulations"
                      />
                    </Alert>
                  ) : null}
                </Grid>
                <Grid item xs={6} md={8} lg={8}>
                  <Box p={2}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button onClick={this.addShift}>
                        <AddIcon style={{ marginRight: '5px' }} />
                        Add shift
                      </Button>
                    </div>

                    <Box p={2}>
                      {shifts.map((_, i) => (
                        <div
                          key={i}
                          className="shift-box"
                          style={{ opacity: selectedShift == i ? 1 : 0.4, backgroundColor: colors[i] }}
                          onClick={() => this.selectShift(i)}
                        >Shift #{i + 1}</div>
                      ))}
                    </Box>

                    <Table id="schedule">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Monday</TableCell>
                          <TableCell>Tuesday</TableCell>
                          <TableCell>Wednesday</TableCell>
                          <TableCell>Thursday</TableCell>
                          <TableCell>Friday</TableCell>
                          <TableCell>Saturday</TableCell>
                          <TableCell>Sunday</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {intervals.map((i, idx) => (
                          <TableRow key={i}>
                            <TableCell>{i}</TableCell>
                            <TableCell
                              onMouseDown={(e) => this.setDragDown(e, idx)}
                              onMouseUp={(e) => this.setDragUp(e, idx)}
                              onMouseEnter={(e) => this.setDragMove(e, idx)}
                            >
                              {shifts.map((s, index) => {
                                return s.activePeriods[idx] ? (
                                  <div className="period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                              {shifts.map((s, index) => {
                                return s.tmpPeriods[idx] ? (
                                  <div className="tmp-period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                            </TableCell>
                            <TableCell
                              onMouseDown={(e) => this.setDragDown(e, intervals.length + idx)}
                              onMouseUp={(e) => this.setDragUp(e, intervals.length + idx)}
                              onMouseEnter={(e) => this.setDragMove(e, intervals.length + idx)}
                            >
                              {shifts.map((s, index) => {
                                return s.activePeriods[intervals.length + idx] ? (
                                  <div className="period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                              {shifts.map((s, index) => {
                                return s.tmpPeriods[intervals.length + idx] ? (
                                  <div className="tmp-period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                            </TableCell>
                            <TableCell
                              onMouseDown={(e) => this.setDragDown(e, intervals.length * 2 + idx)}
                              onMouseUp={(e) => this.setDragUp(e, intervals.length * 2 + idx)}
                              onMouseEnter={(e) => this.setDragMove(e, intervals.length * 2 + idx)}
                            >
                              {shifts.map((s, index) => {
                                return s.activePeriods[intervals.length * 2 + idx] ? (
                                  <div className="period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                              {shifts.map((s, index) => {
                                return s.tmpPeriods[intervals.length * 2 + idx] ? (
                                  <div className="tmp-period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                            </TableCell>
                            <TableCell
                              onMouseDown={(e) => this.setDragDown(e, intervals.length * 3 + idx)}
                              onMouseUp={(e) => this.setDragUp(e, intervals.length * 3 + idx)}
                              onMouseEnter={(e) => this.setDragMove(e, intervals.length * 3 + idx)}
                            >
                              {shifts.map((s, index) => {
                                return s.activePeriods[intervals.length * 3 + idx] ? (
                                  <div className="period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                              {shifts.map((s, index) => {
                                return s.tmpPeriods[intervals.length * 3 + idx] ? (
                                  <div className="tmp-period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                            </TableCell>
                            <TableCell
                              onMouseDown={(e) => this.setDragDown(e, intervals.length * 4 + idx)}
                              onMouseUp={(e) => this.setDragUp(e, intervals.length * 4 + idx)}
                              onMouseEnter={(e) => this.setDragMove(e, intervals.length * 4 + idx)}
                            >
                              {shifts.map((s, index) => {
                                return s.activePeriods[intervals.length * 4 + idx] ? (
                                  <div className="period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                              {shifts.map((s, index) => {
                                return s.tmpPeriods[intervals.length * 4 + idx] ? (
                                  <div className="tmp-period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                            </TableCell>
                            <TableCell
                              onMouseDown={(e) => this.setDragDown(e, intervals.length * 5 + idx)}
                              onMouseUp={(e) => this.setDragUp(e, intervals.length * 5 + idx)}
                              onMouseEnter={(e) => this.setDragMove(e, intervals.length * 5 + idx)}
                            >
                              {shifts.map((s, index) => {
                                return s.activePeriods[intervals.length * 5 + idx] ? (
                                  <div className="period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                              {shifts.map((s, index) => {
                                return s.tmpPeriods[intervals.length * 5 + idx] ? (
                                  <div className="tmp-period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                            </TableCell>
                            <TableCell
                              onMouseDown={(e) => this.setDragDown(e, intervals.length * 6 + idx)}
                              onMouseUp={(e) => this.setDragUp(e, intervals.length * 6 + idx)}
                              onMouseEnter={(e) => this.setDragMove(e, intervals.length * 6 + idx)}
                            >
                              {shifts.map((s, index) => {
                                return s.activePeriods[intervals.length * 6 + idx] ? (
                                  <div className="period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                              {shifts.map((s, index) => {
                                return s.tmpPeriods[intervals.length * 6 + idx] ? (
                                  <div className="tmp-period" key={colors[index]} style={{ backgroundColor: colors[index] }}></div>
                                ) : null;
                              })}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions style={{ justifyContent: 'flex-end' }}>
              <Box pr={1}>
                <Button onClick={this.handleCancel}>
                  <FormattedMessage
                    id="dashboard.forms.simulationform.button_cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </Box>
              <LoadingButton
                onClick={this.handleSubmit}
                variant="contained"
                color="secondary"
                disabled={buttonDisabled}
                loading={loading}
              >
                <FormattedMessage
                  id="dashboard.forms.simulationform.button_submit"
                  defaultMessage="Create Simulation"
                />
              </LoadingButton>
            </CardActions>
          </Card>
        </Loading>
      </>
    );
  }
}

export default injectIntl(SimulationForm);
