import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import { MapMetricData } from '../../types';
import Layers from './Layers';

interface IPropsState {
  filterId?: string;
  id: string;
  metricData?: MapMetricData;
}
const mapStateToProps = (state: IAppState, props: IPropsState) => {
  const { filterId, metricData } = props;
  const { section, tab } = metricData || {};

  const filter = state.filter.filters[filterId || ''] || {};
  const dataRange = state.analytics.dataRanges[filterId || ''] || {};
  const activeMetrics = state.analytics.activeMetrics[filterId || ''] || {};
  const metricId = section ? activeMetrics[section] || '' : '';

  const { id, result } =
    ((activeMetrics.plots || {})[metricId || ''] || {})[tab || ''] || {};

  const metricState =
    (state.analytics.metrics[filterId || ''] || {})[id || ''] || {};

  return {
    mapType: result,
    metricState,
    dataRange,
    filter,
  };
};

export default connect(mapStateToProps)(Layers);
