import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TuneIcon from '@mui/icons-material/Tune';
import LiveHelp from '@mui/icons-material/LiveHelp';
import TableChartIcon from '@mui/icons-material/TableChart';
import hasHover from 'has-hover';
// @ts-ignore
import Plotly, { Config, Layout, PlotData } from 'plotly.js/lib/index-basic';
// @ts-ignore
import PlotlyPie from 'plotly.js/lib/pie';
// @ts-ignore
import PlotlyBar from 'plotly.js/lib/bar';
// @ts-ignore
import PlotlyIndicator from 'plotly.js/lib/indicator';
// @ts-ignore
import PlotlySankey from 'plotly.js/lib/sankey';
// @ts-ignore
import PlotlyTable from 'plotly.js/lib/table';
// @ts-ignore
import * as de from 'plotly.js/lib/locales/de';
// @ts-ignore
import * as es from 'plotly.js/lib/locales/es';
// @ts-ignore
import * as fr from 'plotly.js/lib/locales/fr';
// @ts-ignore
import * as pt from 'plotly.js/lib/locales/pt-br';
// @ts-ignore
import * as it from 'plotly.js/lib/locales/it';
// @ts-ignore
import * as ro from 'plotly.js/lib/locales/ro';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import uuid from 'uuid/v4';

import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import FetchError from '@models/FetchError';
import { MetricAggregationWithFeedback } from '@reducers/analytics';
import AggregationForm from '../AggregationForm';

// @ts-ignore
Plotly.register([
  PlotlyPie,
  PlotlyBar,
  PlotlyIndicator,
  PlotlySankey,
  PlotlyTable,
  de,
  es,
  fr,
  pt,
  it,
  ro,
]);

interface IProps {
  filterId?: string;
  metricId?: string;
  aggregationState: MetricAggregationWithFeedback;
  loading?: boolean;
  error?: FetchError;
  locale: string;
  move: boolean;
  theme: string;
  forceRender?: boolean;
}

interface IState {
  anchorEl?: Element;
  open: boolean;
  table: boolean;
}

const PLOT_DEFAULT_CONFIG = {
  displaylogo: false,
  modeBarButtonsToRemove: ['sendDataToCloud'],
  responsive: true,
} as Partial<Config>;

class PlotComponent extends Component<IProps, IState> {
  private id: string = uuid();

  private wrapperRef!: HTMLDivElement;

  constructor(props: IProps) {
    super(props);

    this.state = {
      open: false,
      table: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.initPlotly = this.initPlotly.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, {
      passive: true,
    });

    this.initPlotly();
  }

  public shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    const { forceRender, aggregationState } = this.props;
    const { anchorEl, open, table } = this.state;

    if (nextProps.forceRender !== forceRender) {
      this.initPlotly();
    }

    if (
      nextProps.aggregationState &&
      aggregationState &&
      nextProps.aggregationState.loading === true &&
      aggregationState.loading === false &&
      document.getElementById(`plot_${aggregationState.id || this.id}`)
    ) {
      Plotly.purge(`plot_${aggregationState.id || this.id}`);
    }

    return (
      JSON.stringify(nextProps.aggregationState) !==
        JSON.stringify(aggregationState) ||
      nextState.anchorEl !== anchorEl ||
      JSON.stringify(nextState.open) !== JSON.stringify(open) ||
      JSON.stringify(nextState.table) !== JSON.stringify(table)
    );
  }

  public componentDidUpdate() {
    const { aggregationState } = this.props;

    if (aggregationState && !aggregationState.loading) {
      this.initPlotly();
    }
  }

  /**
   * Toggles between plot & table view
   */
  public handleToggle() {
    const { table } = this.state;

    const tmp = !table;

    this.setState({ table: tmp }, () => {
      if (!tmp) {
        this.initPlotly();
      }
    });
  }

  public handleOpen(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleClose() {
    this.setState({ anchorEl: undefined });
  }

  public handleClickOutside(event: any) {
    const { open } = this.state;

    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      open === true
    ) {
      this.setState({ open: false });
    }
  }

  public setWrapperRef(node: HTMLDivElement) {
    this.wrapperRef = node;

    window.dispatchEvent(new Event('resize'));
  }

  /**
   * Toogles help tooltip
   */
  public toggleTooltip() {
    this.setState((state: IState) => ({
      open: !state.open,
    }));
  }

  /**
   * Inits plotly.js
   */
  public initPlotly() {
    const { aggregationState, loading, locale, theme } = this.props;

    const p = aggregationState.plot || {};
    const l = aggregationState.loading || loading;

    if (p.data && !l) {
      if (document.getElementById(`plot_${aggregationState.id || this.id}`)) {
        Plotly.newPlot(
          `plot_${aggregationState.id || this.id}`,
          p.data as Partial<PlotData>[],
          {
            ...p.layout,
            dragmode: hasHover,
            font: {
              ...(p.layout || {}).font,
              family: 'Roboto, Helvetica, Arial, sans-serif',
              color: theme === 'dark' ? '#ffffff' : '#000000',
            },
            margin: { ...p.layout.margin, t: 90 },
            yaxis: {
              ...(p.layout || {}).yaxis,
              color: theme === 'dark' ? '#ffffff' : '#000000',
            },
            xaxis: {
              ...(p.layout || {}).xaxis,
              color: theme === 'dark' ? '#ffffff' : '#000000',
            },
            // eslint-disable-next-line camelcase
            paper_bgcolor: theme === 'dark' ? '#424242' : '#ffffff',
            // eslint-disable-next-line camelcase
            plot_bgcolor: theme === 'dark' ? '#424242' : '#ffffff',
            useResizeHandler: true,
          } as Partial<Layout>,
          { ...PLOT_DEFAULT_CONFIG, locale }
        ).then(() => {
          if (p.frames) {
            Plotly.addFrames(
              `plot_${aggregationState.id || this.id}`,
              p.frames
            );
          }
        });
      }
    }
  }

  public render() {
    const { error, filterId, metricId, aggregationState, loading, move } =
      this.props;
    const { anchorEl, open, table } = this.state;

    const p = aggregationState.plot || {};
    const l = aggregationState.loading || loading;
    const e = aggregationState.error || error;
    const id = aggregationState.id || this.id;

    let plotComponent = <span />;

    // If plot is not loading, there is no data or there is an error
    if (!l && (!p.data || e)) {
      plotComponent = (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <ErrorOutlineIcon style={{ color: '#666' }} />
          <Typography style={{ color: '#666', paddingLeft: '5px' }}>
            {e !== undefined ? (
              <FormattedMessage
                id="dashboard.plots.analytics_issue"
                defaultMessage="Problem computing analytics"
              />
            ) : (
              <FormattedMessage
                id="dashboard.plots.nodata"
                defaultMessage="No data for the current selection"
              />
            )}
          </Typography>
        </div>
      );
      // If plot is not loading
    } else if (!l) {
      if (table && aggregationState.table) {
        const columns: ITableColumn[] = Object.keys(
          (aggregationState!.table || {}).columns || {}
        ).map((column) => ({ label: column, name: column }));

        const dataObj: any[] = [];
        (
          ((aggregationState!.table || {}).columns || {})[
            (columns[0] || {}).name
          ] || []
        ).forEach((d: any, index: number) => {
          const obj: any = {};
          columns.forEach((c) => {
            obj[c.name] = (((aggregationState!.table || {}).columns || {})[
              c.name
            ] || [])[index];
          });
          dataObj.push(obj);
        });

        plotComponent = (
          <CardContent
            style={{ overflow: 'auto', maxHeight: 'calc(100% - 42px)' }}
          >
            <MUITable
              columns={columns}
              data={dataObj}
              tableId="analytics-table"
            />
          </CardContent>
        );
      } else {
        plotComponent = (
          <div
            id={`plot_${id}`}
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          />
        );
      }
    }

    return (
      <>
        {filterId && metricId && id ? (
          <>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              <IconButton
                disabled={l}
                size="small"
                onClick={this.handleToggle}
                style={{ float: 'right' }}
              >
                {table ? <BarChartIcon /> : <TableChartIcon />}
              </IconButton>
              <IconButton
                disabled={l}
                size="small"
                onClick={this.handleOpen}
                style={{ float: 'right' }}
              >
                <TuneIcon />
              </IconButton>
            </div>
            <hr style={{ display: 'block', clear: 'both' }} />

            <AggregationForm
              filterId={filterId}
              metricId={metricId}
              id={id}
              anchorEl={anchorEl}
              handleClose={this.handleClose}
            />
          </>
        ) : null}

        <Loading loading={l || false}>
          {move && (
            <div
              style={{
                height: '100%',
                position: 'absolute',
                width: '100%',
                zIndex: 999,
              }}
            />
          )}
          {p && p.help && (
            <div
              ref={this.setWrapperRef}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box px={2}>
                <ClickAwayListener onClickAway={this.toggleTooltip}>
                  <Tooltip
                    onClick={this.toggleTooltip}
                    open={open}
                    title={
                      <Typography
                        style={{ color: '#FFF', padding: '7px' }}
                        variant="body2"
                      >
                        {p.help}
                      </Typography>
                    }
                  >
                    <IconButton aria-label={p.help}>
                      <LiveHelp />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              </Box>
            </div>
          )}
          {plotComponent}
        </Loading>
      </>
    );
  }
}

export default PlotComponent;
