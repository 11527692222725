import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import NotificationsIcon from '@mui/icons-material/Notifications';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { Component } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import { IFetchNotifications } from '@actions/index';
import { INotificationId } from '@actions/notifications';
import Loading from '@app/common/Loading';
import { Notification } from '@models/Notification';
import NotificationsList from './NotificationsList';

import './NotificationsMenu.css';

const messages = {
  dismissAll: {
    defaultMessage: 'Dismiss All',
    id: 'dashboard.notifications.dismissall',
  },
};

interface IProps {
  dismissLoading: boolean;
  dismissNotifications: (
    ids: INotificationId[],
    locale: string,
    units: string
  ) => void;
  fetchNotifications: (properties: IFetchNotifications) => void;
  fetchNotificationsCount: () => void;
  router: any;
  intl: IntlShape;
  language: string;
  loading: boolean;
  locale: string;
  measurementUnits: string;
  notificationCount: number;
  notifications: Record<string, Notification[]>;
}

interface IState {
  anchorElement?: HTMLElement;
  open: boolean;
}

class NotificationsMenu extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorElement: undefined,
      open: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleDismissAllClick = this.handleDismissAllClick.bind(this);
    this.handleViewAllClick = this.handleViewAllClick.bind(this);
  }

  public componentDidMount() {
    const {
      fetchNotifications,
      fetchNotificationsCount,
      locale,
      measurementUnits,
    } = this.props;

    fetchNotifications({ locale, units: measurementUnits });
    fetchNotificationsCount();
  }

  public handleDismissAllClick() {
    const { dismissNotifications, locale, notifications, measurementUnits } =
      this.props;

    dismissNotifications(
      notifications.alerts
        .filter((a) => a.dismissed === false)
        .map((a) => ({ id: a.id, type: 'alerts' }))
        .concat(
          notifications.notifications
            .filter((a) => a.dismissed === false)
            .map((a) => ({ id: a.id, type: 'notifications' }))
        ),
      locale,
      measurementUnits
    );
  }

  public handleViewAllClick() {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/rules/alerts`);
  }

  public toggleMenu(e: any) {
    const { open } = this.state;

    this.setState({
      anchorElement: !open ? e.currentTarget : undefined,
      open: !open,
    });
  }

  public render() {
    const { dismissLoading, intl, notificationCount, notifications, loading } =
      this.props;
    const { open, anchorElement } = this.state;

    return (
      <>
        {loading === false && (
          <IconButton
            color="inherit"
            aria-label="Notifications"
            onClick={this.toggleMenu}
          >
            <Badge badgeContent={notificationCount} max={999} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        )}
        <Popover
          className="notifications-menu"
          open={open}
          anchorEl={anchorElement}
          onClose={this.toggleMenu}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <Box p={2} pt={1} pb={1} className="notifications-menu-title">
            <Typography variant="body1">
              <FormattedMessage
                id="dashboard.notifications.title"
                defaultMessage="Notifications"
              />
            </Typography>

            {((notifications || {}).alerts || []).concat(
              (notifications || {}).notifications || []
            ).length > 1 && (
              <Button
                aria-label={intl.formatMessage(messages.dismissAll)}
                onClick={this.handleDismissAllClick}
                disabled={dismissLoading}
                style={{ textTransform: 'none' }}
              >
                {dismissLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <VisibilityOffIcon style={{ marginRight: '5px' }} />
                )}
                {intl.formatMessage(messages.dismissAll)}
              </Button>
            )}
          </Box>

          <Divider />

          <Loading loading={loading}>
            <NotificationsList notifications={notifications} />
          </Loading>
        </Popover>
      </>
    );
  }
}

export default injectIntl(NotificationsMenu);
