import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TaskIcon from '@mui/icons-material/Task';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import {
  getFromLocalStorage,
  saveToLocalStorage,
} from '@app/utils/localStorageUtils';
import TaskForm from '../TasksTab/TaskForm';
import KanbanBoard from './KanbanBoard';
import TimeLine from './TimeLine';
import { Date } from '@dashboard_utils/index';
import DateTimeRangeSlider from '@app/common/DateTimeRangeSlider/DateTimeRangeSlider';
import { TaskStatus } from '@models/Task';

interface IProps {
  filterId: string;
  statusFilter: TaskStatus[];

  loading: boolean;
  fetchTasks: (shallow?: boolean) => void;
  fetchEmployees: () => void;
  fetchTeams: () => void;

  startDate: Date;
  endDate: Date;
  warehouseTz: string;

  intl: IntlShape;

  formOpen: boolean;
  language: string;
  router: any;
}

interface IState {
  tab: number;
  filterStartDate?: Date;
  filterEndDate?: Date;
}

const messages = {
  kanbanBoard: {
    defaultMessage: 'Kanban Board',
    id: 'dashboard.taskboard.tab.kanban',
  },
  timeline: {
    defaultMessage: 'Timeline',
    id: 'dashboard.taskboard.tab.timeline',
  },
};

class TasksBoardTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { filterId } = props;

    const tab = Number(
      getFromLocalStorage(`task_board_selected_tab_${filterId}`) || 0
    );

    this.state = {
      tab,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
    this.handleManageTasks = this.handleManageTasks.bind(this);
    this.changePeriod = this.changePeriod.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  private handleTabChange(_: any, tab: number) {
    const { filterId } = this.props;

    this.setState({ tab }, () => {
      saveToLocalStorage(`task_board_selected_tab_${filterId}`, String(tab));
    });
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const { fetchTasks, fetchEmployees, fetchTeams } = this.props;

    fetchTasks(shallow);
    fetchEmployees();
    fetchTeams();
  }

  public handleManageTasks() {
    const { language, router } = this.props;

    router.navigate(`/${language}/dashboard/tasks`);
  }

  public changePeriod(leftDate: Date, rightDate: Date) {
    this.setState({
      filterStartDate: leftDate,
      filterEndDate: rightDate,
    });
  }

  public render() {
    const { formOpen, intl, filterId, loading, endDate, startDate, statusFilter, warehouseTz } = this.props;

    const { tab, filterStartDate, filterEndDate } = this.state;

    return (
      <>
        <div className="dtrs-with-hot-dates">
          <DateTimeRangeSlider
            disabled={loading}
            leftDate={filterStartDate || startDate}
            rightDate={filterEndDate || endDate}
            maxDate={endDate}
            minDate={startDate}
            hotMaxDate={endDate}
            hotMinDate={startDate}
            onChange={this.changePeriod}
            warehouseTz={warehouseTz}
          />
        </div>

        <Box pt={1} pb={1}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button id="view-tasks" onClick={this.handleManageTasks}>
              <TaskIcon style={{ marginRight: '5px' }} />
              <FormattedMessage
                id="dashboard.tasks.view"
                defaultMessage="Manage Tasks"
              />
            </Button>
          </div>
        </Box>
        {!loading && (
          <>
            <Tabs
              centered
              indicatorColor="primary"
              onChange={this.handleTabChange}
              value={tab}
              variant="fullWidth"
              textColor="primary"
            >
              <Tab label={intl.formatMessage(messages.kanbanBoard)} />
              <Tab label={intl.formatMessage(messages.timeline)} />
            </Tabs>
            {tab === 0 ? (
              <KanbanBoard
                filterId={filterId}
                filterStartDate={filterStartDate || startDate}
                filterEndDate={filterEndDate || endDate}
                statusFilter={statusFilter} />
            ) : (
              <TimeLine
                filterId={filterId}
                filterStartDate={filterStartDate || startDate}
                filterEndDate={filterEndDate || endDate}
                statusFilter={statusFilter} />
            )}
          </>
        )}
        {formOpen ? <TaskForm /> : null}
      </>
    );
  }
}

export default injectIntl(TasksBoardTab);
