import React, { FunctionComponent } from 'react';

import AlignSensorsAction from './AlignSensors';
import DrawAction from './Draw';
import GeoMappingAction from './GeoMapping';
import ItemLocationsAction from './ItemLocations';
import HoverAction from './Hover';
import ScalingAction from './Scaling';
import SelectAction from './Select';
import ClickAction from './Click';
import {
  IDrawAction,
  IGeoMappingAction,
  IItemLocationsAction,
  IClickAction,
  IScaleAction,
  IAligningAction,
  MapActions,
  MapMetricData,
} from '../../types';

interface IProps {
  actions?: MapActions;
  color: boolean;
  id: string;
  filterId?: string;
  metricData?: MapMetricData;
}

/**
 * Component responsible for injecting active actions into the map
 * @param properties
 * @returns React DOM
 */
const Actions: FunctionComponent<IProps> = ({
  actions = undefined,
  color,
  id,
  filterId = undefined,
  metricData = undefined,
}: IProps) => (
  <>
    {Object.keys(actions || {}).map((type) => {
      if (type === 'aligning') {
        const actionData = (actions || {})[type] as IAligningAction;

        return (
          <AlignSensorsAction
            key={type}
            id={id}
            onChange={actionData.onChange}
          />
        );
      }

      if (type === 'draw') {
        const actionData = (actions || {})[type] as IDrawAction;

        return (
          <DrawAction
            key={type}
            id={id}
            drawColor={actionData.drawColor}
            drawType={actionData.drawType}
            initDraw={actionData.initDraw}
            drawings={actionData.drawings}
            featureName={actionData.featureName}
            featureTitle={actionData.featureTitle}
            fillColor={actionData.fillColor}
            onChange={actionData.onChange}
            roundEdges={actionData.roundEdges}
            singleDraw={actionData.singleDraw}
          />
        );
      }

      if (type === 'geoMapping') {
        const actionData = (actions || {})[type] as IGeoMappingAction;

        return (
          <GeoMappingAction
            key={type}
            id={id}
            onClick={actionData.onClick}
            geoMapping={actionData.geoMapping}
          />
        );
      }

      if (type === 'hover') {
        return <HoverAction key={type} id={id} />;
      }

      if (type === 'select') {
        return (
          <SelectAction
            key={type}
            id={id}
            filterId={filterId}
            metricData={metricData}
          />
        );
      }

      if (type === 'scaling') {
        const actionData = (actions || {})[type] as IScaleAction;

        return (
          <ScalingAction
            key={type}
            id={id}
            color={color}
            onChange={actionData.onChange}
          />
        );
      }

      if (type === 'itemLocations') {
        const actionData = (actions || {})[type] as IItemLocationsAction;

        return (
          <ItemLocationsAction
            key={type}
            id={id}
            locations={actionData.locations}
            onClick={actionData.onLocationClick}
          />
        );
      }

      if (type === 'click') {
        const actionData = (actions || {})[type] as IClickAction;

        return (
          <ClickAction
            key={type}
            id={id}
            onClick={actionData.onClick}
          />
        );
      }

      return null;
    })}
  </>
);

export default Actions;
