import React, { Component } from 'react';

interface IProps {}

interface IState {}

class Details extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public render() {
    return <div>Details</div>;
  }
}

export default Details;
