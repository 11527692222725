import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { closeRuleForm, createRule, updateRule } from '@actions/index';
import { ICreateRule, IUpdateRule } from '@actions/rules';
import { IAppState } from '@reducers/index';
import { getAccountLocale } from '@selectors/accounts';
import { getCompleteFloorplans } from '@selectors/index';
import { getCompleteWarehouses } from '@selectors/warehouses';
import RuleForm from './RuleForm';

const mapStateToProps = (state: IAppState) => ({
  assets: state.assets.assets,
  error: state.rules.form.error,
  floorplans: getCompleteFloorplans(state),
  loading: state.rules.templatesLoading || state.rules.form.loading,
  locale: getAccountLocale(state.authentication),
  templates: state.rules.templates,
  warehouses: getCompleteWarehouses(state),
  zones: state.zones.zones,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createRule: (properties: ICreateRule) =>
    dispatch(createRule.request(properties)),
  onClose: () => dispatch(closeRuleForm()),
  updateRule: (properties: IUpdateRule) =>
    dispatch(updateRule.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RuleForm);
