import { Color, Path, Point, Matrix } from 'paper';
import { Component } from 'react';

import IncompleteFloorplan, { IGeoMapping } from '@models/IncompleteFloorplan';
import { transformMetersToPixels } from '@dashboard_utils/index';
import { transformPixelsToMeters } from '../../../../../../utils';
import { ICircleFeature, IToolEvent, IHitEvent } from '../../../types';
import { defaultTransformationMatrix } from '../../../consts';
import MapImages from '../../MapImages';
import Paper from '../../Paper';

interface IProps {
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  paper: Paper;
  geoMapping?: IGeoMapping[];
  onClick: (point: [number, number]) => void;
}

class GeoMapping extends Component<IProps> {
  private isLoaded = false;

  private elements: ICircleFeature[] = [];

  private dryClick = true;

  constructor(props: IProps) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseDrag = this.handleMouseDrag.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }

  public componentDidMount() {
    const { paper } = this.props;
    const { tool } = paper;

    this.load();

    tool.on('mousedown', this.handleMouseDown);
    tool.on('mousedrag', this.handleMouseDrag);
    tool.on('mousemove', this.handleMouseMove);
    tool.on('mouseup', this.handleMouseUp);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { geoMapping } = this.props;

    if (
      this.isLoaded &&
      JSON.stringify(prevProps.geoMapping || []) !==
        JSON.stringify(geoMapping || [])
    ) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    const { paper } = this.props;
    const { tool } = paper;

    this.clear();

    tool.removeListener('mousedown', this.handleMouseDown);
    tool.removeListener('mousedrag', this.handleMouseDrag);
    tool.removeListener('mousemove', this.handleMouseMove);
    tool.removeListener('mouseup', this.handleMouseUp);
  }

  public handleMouseDown(event: IToolEvent) {
    const { paper } = this.props;

    const [hit]: IHitEvent[] = paper.scope.project!.hitTestAll(event.point!, {
      fill: false,
      match: (h: IHitEvent) => h.type === 'segment',
      segments: true,
      stroke: false,
      tolerance: 50,
    });

    this.dryClick =
      !hit ||
      (!!hit &&
        !!hit.item &&
        !!hit.item.featureInfo &&
        hit.item.featureInfo.type !== 'geomapping');
  }

  public handleMouseDrag() {
    this.dryClick = false;
  }

  public handleMouseMove() {
    this.dryClick = false;
  }

  public handleMouseUp(event: IToolEvent) {
    const { onClick } = this.props;
    const { floorplan, mapImages } = this.props;

    // If click is dry (no drag or move) and is not targeting any feature
    // Used to add/remove geo locations
    if (this.dryClick === true) {
      const point = new Matrix(1, 0, 0, -1, 0, mapImages.height).transform(
        new Point([event.point!.x || 0, event.point!.y || 0])
      );

      onClick(
        transformPixelsToMeters(
          [point.x, point.y],
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        )
      );
    }
  }

  public load() {
    const { floorplan, geoMapping, mapImages, onClick } = this.props;

    this.isLoaded = true;
    (geoMapping || []).forEach((mapping, index) => {
      const draw = new Path.Circle(
        new Point(
          transformMetersToPixels(
            mapping.position,
            floorplan.transformationMatrix,
            floorplan.scale || 1
          )
        ),
        16
      ) as ICircleFeature;

      draw.fillColor = new Color('blue');
      draw.featureInfo = {
        id: String(index),
        props: {
          latitude: mapping.coordinate.latitude,
          longitude: mapping.coordinate.longitude,
        },
        title: 'Geo Position',
        type: 'geomapping',
      };
      draw.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
      draw.onClick = () => onClick(mapping.position);

      this.elements.push(draw);
    });
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((element) => element.remove());

    this.elements = [];
  }

  public render() {
    return null;
  }
}

export default GeoMapping;
