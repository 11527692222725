import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { fetchMetric, FetchMetric } from '@actions/index';
import { IAppState } from '@reducers/index';
import PlotsTab from './PlotsTab';

interface IProps {
  filterId: string;
  section: 'user' | 'diagnostics';
}
const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId, section } = props;

  const metricId = (state.analytics.activeMetrics[filterId] || {})[section];

  return {
    metricId: metricId || '',
    metric: (state.analytics.metrics[filterId] || {})[metricId || ''] || {},
    theme: state.app.theme,
    zoneName: (
      state.zones.zones[
        ((state.filter.filters[filterId] || {}).zoneIds || [])[0]
      ] || {}
    ).name,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMetric: (properties: FetchMetric) =>
    dispatch(fetchMetric.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlotsTab);
