import { Component } from 'react';
import { Color, Group, Path, Point, Matrix } from 'paper';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { ISimulatorConfig, ISimulationData } from '@models/Simulation';
import { transformMetersToPixels } from '@dashboard_utils/index';
import MapImages from '../../MapImages';
import { defaultTransformationMatrix } from '../../../consts';
import { ICircleFeature } from '../../../types';
import Paper from '../../Paper';
import mapEvents from '../../eventEmitter';

interface ISimulatorElements {
  agent: paper.Path;
  path: paper.Path[];
  currentPath: paper.Path[];
}

interface IProps {
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  paper: Paper;
  simulatorLayout: ISimulatorConfig;
}

class SimulatorElements extends Component<IProps> {
  public simulatorElements: Record<string, ISimulatorElements> = {};

  private simulatorLayoutElements?: paper.Group;

  constructor(props: IProps) {
    super(props);

    this.handleSimData = this.handleSimData.bind(this);
  }

  public componentDidMount() {
    mapEvents.on('simulator-data', this.handleSimData);

    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { simulatorLayout } = this.props;

    if (prevProps.simulatorLayout !== simulatorLayout) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    mapEvents.removeListener('live-data', this.handleSimData);

    this.clear();
  }

  private handleSimData(sensorsData: ISimulationData[]) {
    const { floorplan, mapImages, paper } = this.props;
    const { height } = mapImages;

    paper.scope.activate();

    (sensorsData || []).forEach((data) => {
      const agentPosition = new Point(
        transformMetersToPixels(
          [data.x, data.y],
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        )
      );

      if (!this.simulatorElements[data.sensorId]) {
        const agent = new Path.Circle(
          agentPosition,
          12 / paper.getZoom()
        ) as ICircleFeature;
        agent.fillColor = new Color('White');
        agent.strokeColor = new Color('DarkCyan');
        agent.strokeWidth = 3;
        agent.strokeScaling = false;
        agent.transform(new Matrix(1, 0, 0, -1, 0, height));
        this.simulatorElements[data.sensorId] = {
          agent,
          currentPath: [],
          path: [],
        };
      } else {
        this.simulatorElements[data.sensorId].agent.position = agentPosition;
        this.simulatorElements[data.sensorId].agent.transform(
          new Matrix(1, 0, 0, -1, 0, height)
        );
      }

      let lastPathNode: [number, number];
      ((data.simulation || {}).previous_path || []).forEach((node) => {
        if (!lastPathNode) {
          lastPathNode = node;

          return null;
        }

        const from = transformMetersToPixels(
          lastPathNode,
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        );
        const to = transformMetersToPixels(
          node,
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        );
        const path = new Path.Line(new Point(from), new Point(to));
        path.strokeColor = new Color('DimGrey');
        path.strokeWidth = 3;
        path.strokeScaling = false;
        path.transform(new Matrix(1, 0, 0, -1, 0, height));

        this.addPath(data.sensorId, path);

        lastPathNode = node;

        return null;
      });

      let lastPathNode2: [number, number];
      ((data.simulation || {}).current_path || []).forEach((node) => {
        if (!lastPathNode2) {
          lastPathNode2 = node;

          return null;
        }

        const from = transformMetersToPixels(
          lastPathNode2,
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        );
        const to = transformMetersToPixels(
          node,
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        );
        const path = new Path.Line(new Point(from), new Point(to));
        path.strokeColor = new Color('DarkOrange');
        path.strokeWidth = 3;
        path.strokeScaling = false;
        path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

        this.addCurrentPath(data.sensorId, path);

        lastPathNode2 = node;

        return null;
      });
    });
  }

  public addPath(key: string, path: paper.Path) {
    this.simulatorElements[key].path.push(path);
  }

  public addCurrentPath(key: string, path: paper.Path) {
    this.simulatorElements[key].currentPath.push(path);
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    if (this.simulatorLayoutElements) {
      this.simulatorLayoutElements.remove();
    }

    Object.keys(this.simulatorElements).forEach((key: string) => {
      this.simulatorElements[key].agent.remove();
      this.simulatorElements[key].path.forEach((p) => p.remove());
      this.simulatorElements[key].currentPath.forEach((p) => p.remove());
    });

    this.simulatorLayoutElements = undefined;
    this.simulatorElements = {};
  }

  public load() {
    const {
      floorplan,
      mapImages,
      paper: paperMod,
      simulatorLayout,
    } = this.props;
    console.log(simulatorLayout)

    paperMod.scope.activate();

    const edges: paper.Path[] = [];
    (simulatorLayout.edges || []).forEach((edge) => {
      const from = transformMetersToPixels(
        edge[0],
        floorplan.transformationMatrix || defaultTransformationMatrix,
        floorplan.scale || 1
      );
      const to = transformMetersToPixels(
        edge[1],
        floorplan.transformationMatrix || defaultTransformationMatrix,
        floorplan.scale || 1
      );
      const path = new Path.Line(new Point(from), new Point(to));
      path.strokeColor = new Color('WhiteSmoke');
      edges.push(path);
    });

    const nodes: paper.Path[] = [];
    (simulatorLayout.nodes || []).forEach((node) => {
      const position = transformMetersToPixels(
        node,
        floorplan.transformationMatrix || defaultTransformationMatrix,
        floorplan.scale || 1
      );

      const path = new Path.Circle(new Point(position), 2) as ICircleFeature;
      path.fillColor = new Color('DarkGrey');
      nodes.push(path);
    });

    const items: paper.Path[] = [];
    (simulatorLayout.items || []).forEach((item) => {
      const coordinates = (item.coordinates || []).map((coord) =>
        transformMetersToPixels(
          coord,
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        )
      );

      const path = new Path();
      path.strokeColor = new Color('DarkOliveGreen');
      path.strokeWidth = 3;
      coordinates.forEach((coord) => path.add(new Point(coord)));
      items.push(path);
    });
    const itemGroup = new Group(edges.concat(nodes).concat(items));
    itemGroup.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
    this.simulatorLayoutElements = itemGroup;
  }

  public render() {
    return null;
  }
}

export default SimulatorElements;
