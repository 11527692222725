import CheckBox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { IFetchDataRange } from '@actions/index';
import { Date } from '@dashboard_utils/index';
import { DataRange } from '@models/DataRange';
import FetchError from '@models/FetchError';
import { IFilterChange, LAST, getDateFromEnum } from '@reducers/filter';
import DateTimeRangeSlider from '../DateTimeRangeSlider';

import '../DateTimeRangeSlider.css';

interface IProps {
  filterId: string;
  warehouseId?: string;
  floorplanId?: string;
  zoneIds?: string[];
  assetIds?: string[];
  tags?: string[];
  ruleIds?: string[];
  templateIds?: string[];
  autoRefresh?: boolean;
  startDate: Date;
  endDate: Date;
  dataRange: DataRange;
  dataRangeError?: FetchError;
  last: LAST;
  loading: boolean;
  onAutoRefreshChange: (id: string, autoRefresh: boolean) => void;
  onChange: (properties: IFilterChange) => void;
  fetchDataRange: (properties: IFetchDataRange) => void;
  warehouseTz: string;
}

class WithHotDates extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
    this.handleAutoRefresh = this.handleAutoRefresh.bind(this);
    this.handleHotDateChange = this.handleHotDateChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  public componentDidMount() {
    setTimeout(this.refresh, 120000);

    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      dataRange,
      endDate,
      filterId,
      onChange,
      startDate,
      assetIds,
      tags,
      warehouseId,
      floorplanId,
      zoneIds,
      ruleIds,
      templateIds,
      warehouseTz,
    } = this.props;

    if (
      dataRange.minDate &&
      dataRange.maxDate &&
      (endDate === undefined || startDate === undefined)
    ) {
      onChange({
        id: filterId,
        startDate: dataRange.minDate || new Date(warehouseTz),
        endDate: dataRange.maxDate || new Date(warehouseTz),
        filterUpdated: true,
      });
    }

    // fetches the new data range if (wh, fp, assets or tags) changes
    if (
      prevProps.warehouseId !== warehouseId ||
      prevProps.floorplanId !== floorplanId ||
      JSON.stringify(prevProps.zoneIds) !== JSON.stringify(zoneIds) ||
      JSON.stringify(prevProps.assetIds) !== JSON.stringify(assetIds) ||
      JSON.stringify(prevProps.tags) !== JSON.stringify(tags) ||
      JSON.stringify(prevProps.ruleIds) !== JSON.stringify(ruleIds) ||
      JSON.stringify(prevProps.templateIds) !== JSON.stringify(templateIds)
    ) {
      this.fetchData();
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  public handleAutoRefresh(event: any) {
    const { filterId, onAutoRefreshChange } = this.props;

    onAutoRefreshChange(filterId, event.target.checked);
  }

  public handleHotDateChange(event: any) {
    const { filterId, onChange } = this.props;

    onChange({ id: filterId, last: event.target.value, filterUpdated: true });

    this.fetchData();
  }

  public onChange(startDate: Date, endDate: Date) {
    const { dataRange, filterId, last, onChange, warehouseTz } = this.props;

    const maxDate = dataRange.maxDate || new Date(warehouseTz);

    let newLast = last;
    if (
      last !== LAST.CUSTOMIZED &&
      getDateFromEnum(last, warehouseTz, maxDate).getTime() >
        startDate.getTime()
    ) {
      newLast = LAST.CUSTOMIZED;
    }

    onChange({
      id: filterId,
      startDate,
      endDate,
      last: newLast,
      filterUpdated: true,
    });
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const {
      filterId,
      assetIds,
      dataRange,
      floorplanId,
      warehouseTz,
      tags,
      warehouseId,
      fetchDataRange,
    } = this.props;

    if (warehouseId && floorplanId) {
      fetchDataRange({
        filterId,
        assetIds,
        dataRange,
        floorplanId,
        tags,
        warehouseId,
        warehouseTz,
        shallow,
      });
    }
  }

  public refresh() {
    const { autoRefresh, dataRange, endDate } = this.props;

    if (
      autoRefresh === true &&
      endDate !== undefined &&
      dataRange.maxDate !== undefined &&
      endDate.getTime() === dataRange.maxDate.getTime()
    ) {
      this.fetchData();
    }

    setTimeout(this.refresh, 120000);
  }

  public render() {
    const {
      autoRefresh,
      startDate,
      dataRange,
      dataRangeError,
      endDate,
      last,
      loading,
      warehouseTz,
    } = this.props;

    const maxDate = dataRange.maxDate || new Date(warehouseTz);
    const minDateDR = dataRange.minDate || new Date(warehouseTz);
    let minDate = minDateDR;
    if (last !== LAST.CUSTOMIZED) {
      minDate = getDateFromEnum(last, warehouseTz, maxDate);
    }
    if (minDate.getTime() < minDateDR.getTime()) {
      minDate = minDateDR;
    }

    if (loading) {
      return <LinearProgress />;
    }

    if (
      loading ||
      (dataRange !== undefined &&
        dataRange.minDate !== undefined &&
        dataRange.maxDate !== undefined)
    ) {
      return (
        <div className="dtrs-with-hot-dates">
          <FormControlLabel
            style={{ position: 'absolute', top: '-30px', right: '10px' }}
            label={
              <FormattedMessage
                id="dashboard.plots.autorefresh"
                defaultMessage="Auto refresh (2 min.)"
              />
            }
            control={
              <CheckBox
                color="secondary"
                checked={autoRefresh}
                onChange={this.handleAutoRefresh}
                size="small"
              />
            }
          />
          <DateTimeRangeSlider
            disabled={loading}
            leftDate={startDate}
            rightDate={endDate}
            maxDate={dataRange.maxDate}
            minDate={dataRange.minDate}
            hotMaxDate={maxDate}
            hotMinDate={minDate}
            onChange={this.onChange}
            warehouseTz={warehouseTz}
          />
          <FormControl
            className="dtrs-with-hot-dates-secondary"
            variant="standard"
          >
            <Select
              disableUnderline
              className="dtrs-with-hot-dates-select"
              value={last}
              onChange={this.handleHotDateChange}
            >
              <MenuItem value={LAST.CUSTOMIZED}>
                <FormattedMessage
                  id="dashboard.datetimerangeslider.hotdates.customized"
                  defaultMessage="Customised"
                />
              </MenuItem>
              <MenuItem value={LAST.MIN5}>
                <FormattedMessage
                  id="dashboard.datetimerangeslider.hotdates.mins"
                  defaultMessage="Last 5 Minutes"
                />
              </MenuItem>
              <MenuItem value={LAST.HOUR}>
                <FormattedMessage
                  id="dashboard.datetimerangeslider.hotdates.hour"
                  defaultMessage="Last Hour"
                />
              </MenuItem>
              <MenuItem value={LAST.DAY}>
                <FormattedMessage
                  id="dashboard.datetimerangeslider.hotdates.day"
                  defaultMessage="Last Day"
                />
              </MenuItem>
              <MenuItem value={LAST.WEEK}>
                <FormattedMessage
                  id="dashboard.datetimerangeslider.hotdates.week"
                  defaultMessage="Last Week"
                />
              </MenuItem>
              <MenuItem value={LAST.MONTH}>
                <FormattedMessage
                  id="dashboard.datetimerangeslider.hotdates.month"
                  defaultMessage="Last Month"
                />
              </MenuItem>
              <MenuItem value={LAST.YEAR}>
                <FormattedMessage
                  id="dashboard.datetimerangeslider.hotdates.year"
                  defaultMessage="Last Year"
                />
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      );
    }

    if (dataRangeError) {
      return (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            padding: '5px',
          }}
        >
          <Alert severity="error">
            <FormattedMessage
              id="dashboard.plots.nodatarange"
              defaultMessage="Sorry, we were unable to load data range."
            />
          </Alert>
        </div>
      );
    }

    return null;
  }
}

export default WithHotDates;
