import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import { detect } from 'detect-browser';
import FileSaver from 'file-saver';
import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { IFetchReport, IFetchReportData } from '@actions/index';
import { Date } from '@dashboard_utils/index';
import PDF from './PDF';
import ReportForm from './ReportForm';

interface IProps {
  fetchReport: (properties: IFetchReport) => any;
  filterId: string;

  intl: IntlShape;

  loading: boolean;
  report: any;

  locale: string;
  measurementUnits: string;
}

class ReportTabContent extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.generateReport = this.generateReport.bind(this);
  }

  public shouldComponentUpdate(nextProps: IProps) {
    const { loading, report } = this.props;

    if (nextProps.loading !== loading || nextProps.report !== report) {
      return true;
    }

    return false;
  }

  public generateReport(
    filterId: string,
    assetIds: string[],
    floorplanId: string,
    warehouseId: string,
    leftDate: Date,
    rightDate: Date,
    floorplanSensorPlacement: boolean,
    executiveSummary: string,
    data: IFetchReportData
  ) {
    const { fetchReport, intl, locale, measurementUnits } = this.props;

    fetchReport({
      filterId,

      assetIds,
      floorplanId,
      warehouseId,

      endDate: rightDate.getTime(),
      startDate: leftDate.getTime(),

      executiveSummary,
      sensorPlacement: floorplanSensorPlacement,

      data,

      locale,
      measurementUnits,

      intl,
    });
  }

  public render() {
    const { filterId, loading, report } = this.props;

    const browser = detect();
    let url;

    if (report !== undefined) {
      const blob = new Blob([report], { type: 'application/pdf' });

      if (
        browser &&
        (browser.name === 'chrome' ||
          browser.name === 'firefox' ||
          browser.name === 'edge' ||
          browser.name === 'safari') &&
        browser.os !== 'Android OS' &&
        browser.os !== 'BlackBerry OS' &&
        browser.os !== 'Windows Mobile' &&
        browser.os !== 'iOS'
      ) {
        url = window.URL.createObjectURL(blob);
      } else {
        FileSaver.saveAs(blob, 'report.pdf');
      }
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CardHeader title="Reports" />
          <Card style={{ overflow: 'auto' }}>
            <CardContent>
              <ReportForm
                filterId={filterId}
                loading={loading}
                generateReport={this.generateReport}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid id="report-place" item xs={12} md={6}>
          {(loading || report) && <PDF loading={loading} url={url} />}
        </Grid>
      </Grid>
    );
  }
}

export default injectIntl(ReportTabContent);
