import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import clsx from 'clsx';
import React, { Component, Fragment } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { IUpdateAssetPreCheck } from '@actions/assets';
import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import PreCheckForm from '@app/forms/PreCheckForm';
import { findWarehouseByFloorplanId } from '@app/utils/floorplanUtils';
import { PRIMARY_COLOR } from '@app/utils/colors';
import { Date, timeZone } from '@dashboard_utils/index';
import CompleteAsset from '@models/CompleteAsset';
import PreCheck, { IAssetPreCheck } from '@models/PreCheck';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import { Warehouses } from '@models/Warehouse';
import { WarehouseWithFloorplans } from '@models/WarehouseWithFloorplans';

const messages = defineMessages({
  thPreCheckAccountName: {
    defaultMessage: 'Operator',
    id: 'dashboard.assets.management.precheck.table.accountname',
  },
  thPreCheckCreated: {
    defaultMessage: 'Created',
    id: 'dashboard.assets.management.precheck.table.created',
  },
  thPreCheckPreCheckName: {
    defaultMessage: 'Pre-Check',
    id: 'dashboard.assets.management.precheck.table.precheckname',
  },
});

interface IProps {
  asset: CompleteAsset;
  assetPreChecks: IAssetPreCheck[];
  loading: boolean;
  updateAssetPreCheck: (asset: IUpdateAssetPreCheck) => void;
  fetchPreCheckList: () => void;
  fetchPreCheckAnswersByAsset: (assetId: string) => void;
  intl: IntlShape;
  openForm: boolean;
  openPreCheckForm: (data?: PreCheck) => void;
  preChecks: PreCheck[];
  floorplans: IncompleteFloorplans;
  warehouses: Warehouses;
}
interface IPreCheckRow {
  expanded: boolean;
}
interface IState {
  preCheckList: string;
  columns: ITableColumn[];
  preCheckRows: Record<string, IPreCheckRow>;
}

class PreChecks extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { asset, intl } = props;

    this.state = {
      columns: [
        { label: ' ', name: 'expanded', options: { interaction: false } },
        {
          label: intl.formatMessage(messages.thPreCheckAccountName),
          name: 'accountName',
        },
        {
          label: intl.formatMessage(messages.thPreCheckPreCheckName),
          name: 'preCheckName',
        },
        {
          label: intl.formatMessage(messages.thPreCheckCreated),
          name: 'created',
        },
      ],
      preCheckList: asset.preCheckId || '',
      preCheckRows: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.customPreCheckRowRender = this.customPreCheckRowRender.bind(this);
  }

  public componentDidMount() {
    const { asset, fetchPreCheckAnswersByAsset, fetchPreCheckList } =
      this.props;

    fetchPreCheckList();
    fetchPreCheckAnswersByAsset(asset.id);
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { asset, updateAssetPreCheck, intl, openPreCheckForm } = this.props;

      const { value } = e.target;

      if (key === 'preCheckList' && value === 'new') {
        return openPreCheckForm();
      }

      return this.setState(
        {
          [key]: value || '',
        } as Pick<IState, 'preCheckList'>,
        () =>
          updateAssetPreCheck({ id: asset.id, intl, preCheckId: value || null })
      );
    };
  }

  public handleExpandableRow(id: string) {
    const { preCheckRows } = this.state;

    this.setState(
      {
        preCheckRows: {
          ...preCheckRows,
          [id]: {
            expanded: !(preCheckRows[id] || {}).expanded,
          },
        },
      },
      () => setTimeout(() => window.dispatchEvent(new Event('resize')), 300)
    );
  }

  public customPreCheckRowRender(
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    const { asset, assetPreChecks, floorplans, warehouses } = this.props;

    const warehouse =
      findWarehouseByFloorplanId(warehouses, floorplans, asset.floorplanId) ||
      ({} as WarehouseWithFloorplans);
    const warehouseTz = warehouse.timezone || timeZone;

    const colSpan = rowData.length + 1;

    const chevronRotation = clsx('chevron', {
      rotate: rowData[0] === true,
    });

    return (
      <Fragment key={rowIndex}>
        <TableRow>
          {columns[0] && (
            <TableCell>
              {rowData[0] !== true ? (
                <ChevronRightIcon
                  className={chevronRotation}
                  onClick={() =>
                    this.handleExpandableRow(
                      (assetPreChecks[dataIndex] || {}).id
                    )
                  }
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <ChevronDownIcon
                  className={chevronRotation}
                  onClick={() =>
                    this.handleExpandableRow(
                      (assetPreChecks[dataIndex] || {}).id
                    )
                  }
                  style={{ cursor: 'pointer' }}
                />
              )}
            </TableCell>
          )}
          {columns[1] && <TableCell>{rowData[1]}</TableCell>}
          {columns[2] && <TableCell>{rowData[2]}</TableCell>}
          {columns[3] && (
            <TableCell>{new Date(warehouseTz, rowData[3]).format()}</TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={colSpan}
            padding="none"
            style={{ borderBottom: 'none' }}
          >
            <Collapse in={rowData[0] === true}>
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F9F9F9' }}>
                      <TableCell style={{ padding: '3px' }}>
                        <b style={{ color: '#666' }}>
                          <FormattedMessage
                            id="dashboard.assets.management.precheck.table.details.question_description"
                            defaultMessage="Question"
                          />
                        </b>
                      </TableCell>
                      <TableCell style={{ padding: '3px' }}>
                        <b style={{ color: '#666' }}>
                          <FormattedMessage
                            id="dashboard.assets.management.precheck.table.details.question_answer"
                            defaultMessage="Answer"
                          />
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assetPreChecks[dataIndex].answers
                      .sort((a, b) => (a.index > b.index ? 1 : -1))
                      .map((answer) => {
                        let text = (
                          <span>{(answer.answerNumber || 0) + 1}</span>
                        );

                        if (answer.answerBoolean !== null) {
                          text = answer.answerBoolean ? (
                            <FormattedMessage id="yes" defaultMessage="Yes" />
                          ) : (
                            <FormattedMessage id="no" defaultMessage="No" />
                          );
                        }

                        return (
                          <TableRow key={answer.index} hover={false}>
                            <TableCell>{answer.description}</TableCell>
                            <TableCell>{text}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }

  public render() {
    const { assetPreChecks, loading, openForm, preChecks } = this.props;
    const { columns, preCheckList, preCheckRows } = this.state;

    return (
      <>
        <Loading loading={loading}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="assets-form-type">
              <FormattedMessage
                id="dashboard.assets.management.label_prechecklist"
                defaultMessage="Pre-Check List"
              />
            </InputLabel>
            <Select
              value={preCheckList}
              onChange={this.handleChange('preCheckList')}
              input={<Input name="type" id="prechecklist" />}
            >
              <MenuItem key="none" value="" style={{ color: '#CCC' }}>
                <FormattedMessage
                  id="dashboard.assets.management.prechecklist_none"
                  defaultMessage="None"
                />
              </MenuItem>
              {preChecks.map((pc) => (
                <MenuItem key={pc.id} value={pc.id}>
                  {pc.name}
                </MenuItem>
              ))}
              <MenuItem key="new" value="new">
                <Typography style={{ color: PRIMARY_COLOR }}>
                  <FormattedMessage
                    id="dashboard.assets.management.prechecklist_new"
                    defaultMessage="Create New"
                  />
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <MUITable
            columns={columns}
            customRowRender={this.customPreCheckRowRender}
            data={assetPreChecks.map((preCheck) => ({
              ...preCheck,
              expanded: (preCheckRows[preCheck.id] || {}).expanded || false,
            }))}
            defaultOrderField="created"
            tableId="asset-prechecks"
          />
        </Loading>
        {openForm === true ? <PreCheckForm /> : null}
      </>
    );
  }
}

export default injectIntl(PreChecks);
