import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { format } from 'date-fns';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IDeletePreCheck } from '@actions/prechecks';
import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import PreCheck from '@models/PreCheck';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '@app/dialogs/ConfirmDialog/types';
import PreCheckForm from '@app/forms/PreCheckForm';
import { messages, preCheckMessages } from '../messages';

interface IProps {
  confirm: (properties: IConfirmDialog) => void;
  deletePreCheck: (preCheck: IDeletePreCheck) => void;
  fetchPreCheckList: () => void;
  intl: IntlShape;
  loading: boolean;
  openForm: boolean;
  openPreCheckForm: (data?: PreCheck) => void;
  preChecks: PreCheck[];
}

interface IState {
  columns: ITableColumn[];
  listMenu: Record<string, any>;
}

const tableMessages = {
  thDate: {
    defaultMessage: 'Date',
    id: 'dashboard.settings.prechecklists.th_date',
  },
  thName: {
    defaultMessage: 'Name',
    id: 'dashboard.settings.prechecklists.th_name',
  },
  thNumberOfQuestions: {
    defaultMessage: 'Number Of Questions',
    id: 'dashboard.settings.prechecklists.th_numberofquestions',
  },
  thOptions: {
    defaultMessage: 'Options',
    id: 'dashboard.settings.prechecklists.th_options',
  },
};

class PreCheckLists extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      columns: [
        { label: intl.formatMessage(tableMessages.thName), name: 'name' },
        { label: intl.formatMessage(tableMessages.thDate), name: 'created' },
        {
          label: intl.formatMessage(tableMessages.thNumberOfQuestions),
          name: 'numberOfQuestions',
        },
        {
          label: intl.formatMessage(tableMessages.thOptions),
          name: 'options',
          options: {
            customHeadRender: (columnMeta) => (
              <TableCell key={columnMeta.index} style={{ textAlign: 'right' }}>
                {columnMeta.label}
              </TableCell>
            ),
            interaction: false,
          },
        },
      ],
      listMenu: {},
    };

    this.customRowRender = this.customRowRender.bind(this);
    this.handleNewPreCheck = this.handleNewPreCheck.bind(this);
  }

  public componentDidMount() {
    const { fetchPreCheckList } = this.props;

    fetchPreCheckList();
  }

  public handleNewPreCheck() {
    const { openPreCheckForm } = this.props;

    openPreCheckForm();
  }

  public handleItemMenuClick(index: number) {
    return (event: any) => {
      const { listMenu } = this.state;

      listMenu[index] = event.target;

      this.setState({ listMenu });
    };
  }

  public handleItemMenuClose(index: number) {
    const { listMenu } = this.state;

    listMenu[index] = undefined;

    this.setState({ listMenu });
  }

  public handleEdition(index: number, preCheck: PreCheck) {
    const { listMenu } = this.state;
    const { openPreCheckForm } = this.props;

    listMenu[index] = undefined;

    this.setState({ listMenu }, () => openPreCheckForm(preCheck));
  }

  public handleDeletion(index: number, preCheck: PreCheck) {
    const { listMenu } = this.state;
    const { confirm, deletePreCheck, intl } = this.props;

    listMenu[index] = undefined;

    this.setState({ listMenu }, () =>
      confirm({
        confirmType: ConfirmDialogActions.DELETE,
        message: intl.formatMessage(preCheckMessages.deleteMsg),
        onConfirmation: () => {
          deletePreCheck({ id: preCheck.id });
        },
      })
    );
  }

  public customRowRender(columns: boolean[], rowData: any, dataIndex: number) {
    const { intl, preChecks } = this.props;
    const { listMenu } = this.state;

    const data = preChecks.map((p) => ({
      ...p,
      numberOfQuestions: p.questions.length,
    }));

    return (
      <TableRow key={dataIndex}>
        {columns[0] && <TableCell>{rowData[0]}</TableCell>}
        {columns[1] && (
          <TableCell>
            {format(new Date(rowData[1]), 'yyyy-MM-dd HH:mm')}
          </TableCell>
        )}
        {columns[2] && <TableCell>{rowData[2]}</TableCell>}
        {columns[3] && (
          <TableCell style={{ textAlign: 'right' }}>
            <IconButton
              aria-label={intl.formatMessage(messages.more)}
              aria-owns={listMenu[dataIndex] ? 'long-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleItemMenuClick(dataIndex)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={listMenu[dataIndex]}
              open={!!listMenu[dataIndex]}
              onClose={() => this.handleItemMenuClose(dataIndex)}
            >
              <MenuItem
                onClick={() => this.handleEdition(dataIndex, data[dataIndex])}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="dashboard.settings.prechecklists.edit"
                      defaultMessage="Edit Pre-Check"
                    />
                  }
                />
              </MenuItem>
              <MenuItem
                onClick={() => this.handleDeletion(dataIndex, data[dataIndex])}
              >
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="dashboard.settings.prechecklists.delete"
                      defaultMessage="Delete Pre-Check"
                    />
                  }
                />
              </MenuItem>
            </Menu>
          </TableCell>
        )}
      </TableRow>
    );
  }

  public render() {
    const { loading, openForm, preChecks } = this.props;
    const { columns } = this.state;

    const data = preChecks.map((p) => ({
      ...p,
      numberOfQuestions: p.questions.length,
    }));

    return (
      <>
        <Card>
          <CardHeader
            action={
              <Button onClick={this.handleNewPreCheck}>
                <AddIcon style={{ marginRight: '5px' }} />
                <FormattedMessage
                  id="dashboard.settings.prechecklists.add"
                  defaultMessage="Add new Pre-Check"
                />
              </Button>
            }
            title={
              <FormattedMessage
                id="dashboard.settings.prechecklists.title"
                defaultMessage="Pre-Check Lists"
              />
            }
          />
          <CardActions>
            <Loading loading={loading}>
              <div className="table-container">
                <MUITable
                  columns={columns}
                  customRowRender={this.customRowRender}
                  data={data}
                  tableId="preChecks"
                />
              </div>
            </Loading>
          </CardActions>
        </Card>
        {openForm === true ? <PreCheckForm /> : null}
      </>
    );
  }
}

export default injectIntl(PreCheckLists);
