import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchFloorplanSensorGroupStatus,
  fetchFloorplanSensors,
  IFetchFloorplanSensors,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import {
  isFloorplanSensorGroupWithStatusLoading,
  isSensorByFloorplanLoading,
} from '@selectors/sensors';
import MapLoading from './MapLoading';

interface IProps {
  floorplanId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  floorplanId: props.floorplanId,
  loading:
    isSensorByFloorplanLoading(state, props.floorplanId) ||
    isFloorplanSensorGroupWithStatusLoading(state, props.floorplanId),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => {
    dispatch(fetchFloorplanSensorGroupStatus.request(properties));
  },
  fetchSensors: (floorplanId: string) => {
    dispatch(
      fetchFloorplanSensors.request({
        floorplanId,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MapLoading);
