import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IUpdateAccountSettings } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import Account from '@models/Account';
import { Warehouses } from '@models/Warehouse';
import { getAccountMeta } from '@selectors/accounts';

interface IProps {
  account: Account;
  theme: 'light' | 'dark';
  toggleTheme: () => void;
  changed: boolean;
  intl: IntlShape;
  loading: boolean;
  updateAccountSettings: (properties: IUpdateAccountSettings) => void;
  resetWarehouses: (warehouses: Warehouses, intl: IntlShape) => any;
  warehouses: Warehouses;
}

interface IState {
  showHidden: boolean;
  changed: boolean;
}

class OtherSettingsItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { changed, account } = this.props;

    this.state = {
      changed,
      showHidden: getAccountMeta(account).showHiddenFloorplans,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleThemeChange = this.handleThemeChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { changed } = this.props;

    if (prevProps.changed !== changed) {
      this.setChange();
    }
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const value: string | boolean =
        e.target.type === 'checkbox' ? e.target.checked : e.target.value;

      this.setState(
        (prevState) =>
          ({
            [key]: value,
            changed: value !== prevState[key],
          } as Pick<IState, 'changed'>)
      );
    };
  }

  public handleSubmit() {
    const { intl, updateAccountSettings } = this.props;
    const { showHidden } = this.state;

    updateAccountSettings({
      intl,
      properties: {
        showHiddenFloorplans: showHidden,
      },
    });
  }

  public handleThemeChange() {
    const { toggleTheme } = this.props;

    toggleTheme();
  }

  public handleReset() {
    const { account } = this.props;

    this.setState({
      changed: false,
      showHidden: getAccountMeta(account).showHiddenFloorplans,
    });
  }

  public setChange() {
    const { changed } = this.props;

    this.setState({ changed });
  }

  public render() {
    const { intl, loading, theme, resetWarehouses, warehouses } = this.props;
    const { changed, showHidden } = this.state;

    return (
      <>
        <Card>
          <CardHeader
            title={
              <FormattedMessage
                id="dashboard.settings.others.accountsettings"
                defaultMessage="Quick Settings"
              />
            }
          />
          <CardContent>
            <FormControl required fullWidth margin="normal">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showHidden || false}
                    onChange={this.handleChange('showHidden')}
                    value
                  />
                }
                label={
                  <FormattedMessage
                    id="dashboard.settings.others.accountsettings.label_showhiddenfloorplans"
                    defaultMessage="Show hidden floor plans"
                  />
                }
              />
            </FormControl>
          </CardContent>
          <CardActions style={{ justifyContent: 'flex-end' }}>
            {changed ? (
              <>
                <Button
                  onClick={this.handleReset}
                  style={{ marginLeft: '10px' }}
                >
                  <FormattedMessage
                    id="dashboard.settings.others.accountsettings.button_cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <LoadingButton
                  onClick={this.handleSubmit}
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  loading={loading}
                >
                  <FormattedMessage
                    id="dashboard.settings.others.accountsettings.button_submit"
                    defaultMessage="Update"
                  />
                </LoadingButton>
              </>
            ) : null}
          </CardActions>
        </Card>
        <Box mt={2}>
          <Card>
            <CardHeader
              title={
                <FormattedMessage
                  id="dashboard.settings.others.appearance"
                  defaultMessage="Appearance"
                />
              }
            />
            <CardContent>
              <FormControl required fullWidth margin="normal">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={theme === 'dark'}
                      onChange={this.handleThemeChange}
                      value
                    />
                  }
                  label={
                    <FormattedMessage
                      id="dashboard.settings.others.appearance.label_theme"
                      defaultMessage="Use dark mode"
                    />
                  }
                />
              </FormControl>
            </CardContent>
          </Card>
        </Box>
        <Box mt={2}>
          <Card>
            <CardHeader
              title={
                <FormattedMessage
                  id="dashboard.settings.others.resetwarehouses"
                  defaultMessage="Reset Warehouses"
                />
              }
            />
            <CardContent>
              <Button
                variant="contained"
                color="primary"
                onClick={resetWarehouses(warehouses, intl)}
              >
                <FormattedMessage
                  id="dashboard.settings.others.button_reset"
                  defaultMessage="Reset"
                />
              </Button>
            </CardContent>
          </Card>
        </Box>
      </>
    );
  }
}

export default injectIntl(OtherSettingsItem);
