export const getFromLocalStorage = (key: string): string | undefined => {
  if (window.localStorage) {
    return window.localStorage.getItem(key) || undefined;
  }

  return undefined;
};

export const getObjectFromLocalStorage = (key: string): object | undefined => {
  try {
    const item = getFromLocalStorage(key);

    if (item) {
      return JSON.parse(item);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return undefined;
};

export const saveToLocalStorage = (key: string, value: string) => {
  if (window.localStorage) {
    window.localStorage.setItem(key, value);
  }
};

export const saveObjectToLocalStorage = (key: string, value: object) => {
  saveToLocalStorage(key, JSON.stringify(value));
};
