import Box from '@mui/material/Box';
import React, { FunctionComponent } from 'react';

const ErrorPlaceholder: FunctionComponent = () => (
  <Box
    p={2}
    style={{
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img
      alt="Broken logo"
      style={{ height: '350px', opacity: 0.1 }}
      src="/assets/low.svg"
    />
  </Box>
);

export default ErrorPlaceholder;
