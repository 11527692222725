import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import clsx from 'clsx';
import React, { Component, Fragment, lazy } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import { Date, timeZone } from '@dashboard_utils/index';
import Account from '@models/Account';
import { AssetAssociation } from '@models/AssetAssociations';
import { Employee } from '@models/Employee';
import { Warehouses } from '@models/Warehouse';
import { hasPermission } from '@selectors/accounts';
import { ConfirmDialogActions, IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import MUITable, { ITableColumn } from '@app/common/Table';
import Loading from '@app/common/Loading';
const EmployeeForm = lazy(() => import('../../../../forms/EmployeeForm'));

const messages = {
  deleteMsg: {
    defaultMessage: 'Are you sure that you want to DELETE this employee?',
    id: 'dashboard.employees.delete_message',
  },
  thName: {
    defaultMessage: 'Name',
    id: 'dashboard.employees.table.name',
  },
};

export interface IAnchorEL {
  menu: HTMLElement | undefined;
  expanded: boolean;
}

interface IProps {
  account: Account;
  confirm: (props: IConfirmDialog) => void;
  data: Employee[];
  deleteEmployee: (id: string, intl: IntlShape) => void;
  fetchEmployees: (shallow?: boolean) => void;
  intl: IntlShape;
  openForm: (data?: any) => void;
  language: string;
  loading: boolean;
  warehouses: Warehouses;
  employeeFormOpen: boolean;
  employeeFormData?: Employee;
  router: any;
}

interface IState {
  anchorEl: Record<number, IAnchorEL>;
  columns: ITableColumn[];
  page: number;
}

class EmployeeTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      anchorEl: {},
      columns: [
        { label: ' ', name: 'expanded', options: { interaction: false } },
        { label: ' ', name: 'image' },
        { label: intl.formatMessage(messages.thName), name: 'name' },
        { label: 'Email', name: 'email' },
        { label: 'Account Type', name: 'operator' },
        { label: 'Scale', name: 'scale' },
        {
          label: ' ',
          name: 'menu',
          options: { interaction: false },
        },
      ],
      page: 1,
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.customRowRender = this.customRowRender.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleExpandableRow = this.handleExpandableRow.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  public handleAdd() {
    const { openForm } = this.props;

    openForm();
  }

  public handleMenu(index: number, event: any) {
    const { anchorEl } = this.state;

    this.setState({
      anchorEl: {
        ...anchorEl,
        [index]: {
          ...anchorEl[index],
          menu: event.currentTarget,
        },
      },
    });
  }

  public handleMenuClose(index: number) {
    const { anchorEl } = this.state;
    anchorEl[index].menu = undefined;
    this.setState({ anchorEl });
  }

  public handleEdit(data: any, index: number) {
    const { openForm } = this.props;
    const { anchorEl } = this.state;

    openForm(data);
    anchorEl[index].menu = undefined;

    this.setState({ anchorEl });
  }

  public handleDelete(id: string, index: number) {
    const { confirm, deleteEmployee, intl } = this.props;
    const { anchorEl } = this.state;

    anchorEl[index].menu = undefined;

    this.setState({ anchorEl }, () => {
      confirm({
        confirmType: ConfirmDialogActions.DELETE,
        message: intl.formatMessage(messages.deleteMsg),
        onConfirmation: () => deleteEmployee(id, intl),
      });
    });
  }

  public handleExpandableRow(index: number) {
    const { anchorEl } = this.state;

    this.setState(
      {
        anchorEl: {
          ...anchorEl,
          [index]: {
            ...anchorEl[index],
            expanded: !(anchorEl[index] || {}).expanded,
            menu: undefined,
          },
        },
      },
      () => setTimeout(() => window.dispatchEvent(new Event('resize')), 300)
    );
  }

  public handlePageChange(event: any, page: number) {
    this.setState({ page });
  }

  public customRowRender(
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    const { account, data, warehouses } = this.props;
    const { anchorEl } = this.state;

    const colSpan = rowData.length + 1;

    const chevronRotation = clsx('chevron', {
      rotate: (anchorEl[dataIndex] || {}).expanded === true,
    });

    return (
      <Fragment key={rowIndex}>
        <TableRow key={rowIndex}>
          {columns[0] && (
            <TableCell>
              <ChevronRightIcon
                className={chevronRotation}
                onClick={() => this.handleExpandableRow(dataIndex)}
                style={{ cursor: 'pointer' }}
              />
            </TableCell>
          )}
          {columns[1] && (
            <TableCell>
              <Avatar
                alt={rowData[2]}
                src={rowData[1] || '/assets/employeePlaceholder.png'}
                style={{ backgroundColor: '#efefef' }}
              />
            </TableCell>
          )}
          {columns[2] && <TableCell>{rowData[2]}</TableCell>}
          {columns[3] && <TableCell>{rowData[3]}</TableCell>}
          {columns[4] && <TableCell>{rowData[4]}</TableCell>}
          {columns[5] && <TableCell>{rowData[5]}</TableCell>}
          {columns[6] && (
            <TableCell>
              {(hasPermission(account, 'edit_asset') ||
                hasPermission(account, 'del_asset')) && (
                <div style={{ float: 'right' }}>
                  <IconButton
                    aria-label="More"
                    aria-owns={
                      (anchorEl[dataIndex] || {}).menu ? 'long-menu' : undefined
                    }
                    aria-haspopup="true"
                    onClick={(event: any) => this.handleMenu(dataIndex, event)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={(anchorEl[dataIndex] || {}).menu}
                    open={!!(anchorEl[dataIndex] || {}).menu}
                    onClose={() => this.handleMenuClose(dataIndex)}
                  >
                    {hasPermission(account, 'edit_asset') && (
                      <MenuItem onClick={() => this.handleEdit(data[dataIndex], dataIndex)}>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <FormattedMessage
                              id="dashboard.employees.editemployee"
                              defaultMessage="Edit"
                            />
                          }
                        />
                      </MenuItem>
                    )}
                    {hasPermission(account, 'del_asset') && (
                      <MenuItem onClick={() => this.handleDelete(data[dataIndex].id, dataIndex)}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <FormattedMessage
                              id="dashboard.employees.deleteemployee"
                              defaultMessage="Delete"
                            />
                          }
                        />
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              )}
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={colSpan}
            padding="none"
            style={{ borderBottom: 'none' }}
          >
            <Collapse in={(anchorEl[dataIndex] || {}).expanded}>
              <div
                style={{
                  backgroundColor: (anchorEl[dataIndex] || {}).expanded
                    ? '#FEFEFE'
                    : undefined,
                  border: '1px solid #F9F9F9',
                  borderTop: 'none',
                  padding: '14px 40px 14px 16px',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          id="dashboard.employees.assetassociations.from"
                          defaultMessage="From"
                        />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage
                          id="dashboard.employees.assetassociations.to"
                          defaultMessage="To"
                        />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage
                          id="dashboard.employees.assetassociations.asset"
                          defaultMessage="Asset"
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data[dataIndex].assetAssociations.map(
                      (a: AssetAssociation, index: number) => {
                        const warehouseTz =
                          (warehouses[a.warehouseId] || {}).timezone ||
                          timeZone;

                        const start = new Date(warehouseTz, a.from).format();
                        let end = null;
                        if (a.to) {
                          end = new Date(warehouseTz, a.to).format();
                        }

                        return (
                          <TableRow key={index} hover={false}>
                            <TableCell>{start}</TableCell>
                            <TableCell>{end}</TableCell>
                            <TableCell>{a.assetName}</TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }

  public render() {
    const { account, data, employeeFormOpen, employeeFormData, loading } = this.props;
    const { columns } = this.state;

    return (
      <>
        <Loading loading={loading}>
          {hasPermission(account, 'add_asset') && (
            <Box mt={2}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button id="add-employee" onClick={this.handleAdd}>
                  <AddIcon style={{ marginRight: '5px' }} />
                  <FormattedMessage
                    id="dashboard.employees.addemployee"
                    defaultMessage="Add Employee"
                  />
                </Button>
              </div>
            </Box>
          )}

          <div className="table-container">
            <Card style={{ flex: 1 }}>
              <CardContent>
                <MUITable
                  columns={columns}
                  data={data}
                  customRowRender={this.customRowRender}
                  tableId="employees"
                />
              </CardContent>
            </Card>
          </div>
        </Loading>
        {employeeFormOpen ? <EmployeeForm data={employeeFormData} /> : null}
      </>
    );
  }
}

export default injectIntl(EmployeeTab);
