import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Alert from '@mui/material/Alert';
import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { IFetchReportData } from '@actions/reports';
import ws, { IReportProgressData, ReportProgress } from '@api/websocket';
import DateTimeRangeSlider from '@app/common/DateTimeRangeSlider/WithHotDates';
import LoadingButton from '@app/common/LoadingButton';
import { Date, timeZone } from '@dashboard_utils/index';
import Account from '@models/Account';
import { Assets } from '@models/Asset';
import IncompleteFloorplan, {
  IncompleteFloorplans,
} from '@models/IncompleteFloorplan';
import FetchError from '@models/FetchError';
import MetricMeta from '@models/MetricMeta';
import { Warehouses } from '@models/Warehouse';
import { IFilterChange } from '@reducers/filter';
import { getAccountMeta, hasPermission } from '@selectors/accounts';
import {
  getObjectFromLocalStorage,
  saveObjectToLocalStorage,
} from '../../../../utils/localStorageUtils';
import ReportCompositionForm from './ReportCompositionForm';

const LOCAL_STORAGE_KEY = 'REPORTTAB_CACHE';

interface ICache {
  executiveSummary: string;
}

interface IProps {
  account: Account;

  filterId: string;

  dataRangeError?: FetchError;

  loading: boolean;

  assets: Assets;
  floorplans: IncompleteFloorplans;
  warehouses: Warehouses;

  warehouseId?: string;
  floorplanId?: string;
  assetIds: string[];

  startDate?: Date;
  endDate?: Date;

  metrics: MetricMeta[];

  onChange: (properties: IFilterChange) => void;

  generateReport: (
    filterId: string,
    assetIds: string[],
    floorplanId: string,
    warehouseId: string,
    startDate: Date,
    endDate: Date,
    floorplanSensorPlacement: boolean,
    executiveSummary: string,
    data: IFetchReportData
  ) => void;

  intl: IntlShape;

  theme: string;
}

interface IState {
  step?: ReportProgress;

  showHidden: boolean;

  executiveSummary?: string;

  assetIds: string[];
  floorplanSensorPlacement: boolean;

  plots: Record<string, Record<string, Record<string, boolean>>>;
}

const messages = defineMessages({
  collectingWhInfo: {
    defaultMessage: 'Collecting floorplan information',
    id: 'dashboard.report.generationsteps.collectingwhinfo',
  },
  compilingReport: {
    defaultMessage: 'Compiling report',
    id: 'dashboard.report.generationsteps.compilingreport',
  },
  downloading: {
    defaultMessage: 'Downloading report',
    id: 'dashboard.report.generationsteps.downloading',
  },
  fetchingAnalytics: {
    defaultMessage: 'Fetching analytics',
    id: 'dashboard.report.generationsteps.fetchinganalytics',
  },
  generatingAnalytics: {
    defaultMessage: 'Generating analytics',
    id: 'dashboard.report.generationsteps.generatinganalytics',
  },
  showWalkThrough: {
    defaultMessage: 'Show walk-through help.',
    id: 'dashboard.walkthrough',
  },
});

const styles = {
  pendingAvatar: {
    backgroundColor: 'rgb(255, 171, 0)',
  },
  pendingIcon: {
    color: 'white',
  },
  pendingItem: {
    backgroundColor: 'rgb(255, 171, 0)',
  },
  pendingText: {
    color: 'white',
    fontWeight: 700,
  },
  successAvatar: {
    backgroundColor: 'rgb(0, 135, 90)',
  },
  successIcon: {
    color: 'white',
  },
  successText: {
    color: 'rgb(0, 135, 90)',
    fontWeight: 700,
  },
  waitingItem: {
    opacity: 0.3,
  },
  waitingText: {
    fontWeight: 700,
  },
};

const getProgressStepText = (listStep: ReportProgress, intl: IntlShape): string => {
  if (listStep === ReportProgress.CollectingWhInfo) {
    return intl.formatMessage(messages.collectingWhInfo);
  }
  if (listStep === ReportProgress.GeneratingAnalytics) {
    return intl.formatMessage(messages.generatingAnalytics);
  }
  if (listStep === ReportProgress.FetchingAnalytics) {
    return intl.formatMessage(messages.fetchingAnalytics);
  }
  if (listStep === ReportProgress.CompilingReport) {
    return intl.formatMessage(messages.compilingReport);
  }
  if (listStep === ReportProgress.Downloading) {
    return intl.formatMessage(messages.downloading);
  }

  return 'NA';
};

const renderActiveAssets = (assets: Assets, selected: any[]) => (
  <div>
    {selected.map((id: any, index: number) => {
      const asset = assets[id];

      return asset !== undefined ? (
        <div
          key={id}
          style={{
            float: 'left',
            margin: '0px 5px',
          }}
        >
          <div
            style={{
              color:
                asset.color !== undefined && asset.color !== null
                  ? asset.color
                  : undefined,
              float: 'left',
            }}
          >
            {asset.color !== undefined && asset.color !== null ? (
              <div
                style={{
                  backgroundColor: asset.color,
                  borderRadius: '15px',
                  float: 'left',
                  height: '15px',
                  marginRight: '5px',
                  width: '15px',
                }}
              />
            ) : null}
            {asset.name}
          </div>
          {index !== selected.length - 1 ? (
            <div style={{ float: 'left' }}>, </div>
          ) : null}
        </div>
      ) : null;
    })}
  </div>
);

class ReportForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { account, assets, assetIds, filterId, floorplanId } = this.props;

    const showHidden =
      hasPermission(account, 'settings') &&
      getAccountMeta(account).showHiddenFloorplans;

    const lsInfo = getObjectFromLocalStorage(LOCAL_STORAGE_KEY) as ICache;

    const a = Object.keys(assets).filter(
      (id) => assets[id].floorplanId === floorplanId
    );

    this.state = {
      showHidden,
      assetIds: assetIds.length === 0 ? a : assetIds,

      executiveSummary: lsInfo ? lsInfo.executiveSummary : undefined,
      floorplanSensorPlacement: true,
      plots: (getObjectFromLocalStorage(`reports_form_plots_${filterId}`) ||
        {}) as Record<string, Record<string, Record<string, boolean>>>,
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterOpen = this.handleFilterOpen.bind(this);
    this.handleFilterClose = this.handleFilterClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSectionChange = this.handleSectionChange.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleProgressMessage = this.handleProgressMessage.bind(this);
    this.submit = this.submit.bind(this);
  }

  public componentDidMount() {
    ws.on('report-progress', this.handleProgressMessage);
  }

  public componentDidUpdate(prevProps: any) {
    const { floorplanId, warehouseId } = this.props;

    if (
      prevProps.warehouseId !== warehouseId ||
      prevProps.floorplanId !== floorplanId
    ) {
      this.updateAssetSelection();
    }
  }

  public componentWillUnmount() {
    ws.removeListener('report-progress', this.handleProgressMessage);
  }

  public handleProgressMessage(data: IReportProgressData) {
    const { filterId } = this.props;

    if ((data.requestData || {}).filterId === filterId) {
      let step = ReportProgress.Downloading;
      if (data.step === 'CollectingWhInfo') {
        step = ReportProgress.CollectingWhInfo;
      } else if (data.step === 'FetchingAnalytics') {
        step = ReportProgress.FetchingAnalytics;
      } else if (data.step === 'GeneratingAnalytics') {
        step = ReportProgress.GeneratingAnalytics;
      } else if (data.step === 'CompilingReport') {
        step = ReportProgress.CompilingReport;
      }

      this.setState({ step });
    }
  }

  public handleChange(e: any) {
    const { name } = e.target;

    this.setState({
      [name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    } as Pick<IState, 'executiveSummary'>);
  }

  public handleFilterChange(e: any) {
    const { filterId, floorplans, onChange } = this.props;
    const { showHidden } = this.state;
    const { name } = e.target;

    if (name === 'warehouseId') {
      const floorplanId = (
        Object.values(floorplans)
          .filter((fp) => fp.warehouseId === e.target.value)
          .filter((fp) => !fp.hidden || showHidden)[0] || {}
      ).id;

      onChange({
        id: filterId,
        warehouseId: e.target.value,
        floorplanId,
        assetIds: [],
      });
    } else if (name === 'floorplanId') {
      onChange({ id: filterId, floorplanId: e.target.value, assetIds: [] });
    } else {
      this.setState({ assetIds: e.target.value });
    }
  }

  public handleFilterOpen() {
    const { assets, assetIds, floorplanId } = this.props;

    const a = Object.keys(assets).filter(
      (id) => assets[id].floorplanId === floorplanId
    );

    this.setState({
      assetIds: assetIds.length === 0 ? a : assetIds,
    });
  }

  public handleFilterClose() {
    const { assetIds: propsAssetIds, filterId, onChange } = this.props;
    const { assetIds } = this.state;

    if (assetIds.join('|') !== propsAssetIds.join('|')) {
      onChange({
        id: filterId,
        assetIds,
      });
    }
  }

  public handleSelectionChange(metridId: string, type: string, id: string) {
    return (e: any) => {
      const { filterId } = this.props;
      const { plots } = this.state;

      const active =
        e.target.type === 'checkbox' ? e.target.checked : e.target.value;

      if (!plots[metridId]) {
        plots[metridId] = {};
      }
      if (!plots[metridId][type]) {
        plots[metridId][type] = {};
      }

      plots[metridId][type][id] = active;

      this.setState({ plots } as Pick<IState, 'executiveSummary'>, () =>
        saveObjectToLocalStorage(`reports_form_plots_${filterId}`, plots)
      );
    };
  }

  public handleSectionChange(
    e: any,
    analytics: { type: string; id: string }[]
  ) {
    const { filterId } = this.props;
    const { plots } = this.state;
    const { name, checked } = e.target;

    if (!plots[name]) {
      plots[name] = {};
    }
    analytics.forEach(({ type, id }) => {
      if (!plots[name][type]) {
        plots[name][type] = {};
      }

      plots[name][type][id] = checked;
    });

    this.setState({ plots } as Pick<IState, 'executiveSummary'>, () =>
      saveObjectToLocalStorage(`reports_form_plots_${filterId}`, plots)
    );
  }

  public getProgressStep(
    listStep: ReportProgress
  ): React.ReactElement | undefined {
    const { step } = this.state;
    const { intl } = this.props;

    if (step === undefined) {
      return undefined;
    }

    let progressInfo = null;
    if (step === listStep) {
      progressInfo = (
        <ListItem style={styles.pendingItem}>
          <ListItemAvatar>
            <Avatar style={styles.pendingAvatar}>
              <CircularProgress style={styles.pendingIcon} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            style={styles.pendingText}
            disableTypography
            primary={getProgressStepText(listStep, intl)}
          />
        </ListItem>
      );
    } else if (step > listStep) {
      return (
        <ListItem>
          <ListItemAvatar>
            <Avatar style={styles.successAvatar}>
              <CheckIcon style={styles.successIcon} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            style={styles.successText}
            disableTypography
            primary={getProgressStepText(listStep, intl)}
          />
        </ListItem>
      );
    } else {
      progressInfo = (
        <ListItem style={styles.waitingItem}>
          <ListItemAvatar>
            <Avatar>
              <ClearIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            style={styles.waitingText}
            disableTypography
            primary={getProgressStepText(listStep, intl)}
          />
        </ListItem>
      );
    }

    return progressInfo;
  }

  public updateAssetSelection() {
    const { assets, assetIds, floorplanId } = this.props;

    const a = Object.keys(assets).filter(
      (id) => assets[id].floorplanId === floorplanId
    );

    this.setState({
      assetIds: assetIds.length === 0 ? a : assetIds,
    });
  }

  public submit() {
    const {
      filterId,
      assetIds,
      floorplanId,
      generateReport,
      metrics,
      startDate,
      endDate,
      warehouseId,
      warehouses,
    } = this.props;

    const { floorplanSensorPlacement, plots, executiveSummary } = this.state;

    this.setState({ step: undefined });

    if (executiveSummary !== undefined && executiveSummary !== '') {
      saveObjectToLocalStorage(LOCAL_STORAGE_KEY, { executiveSummary });
    }

    const warehouseTz = warehouses[warehouseId || '']
      ? warehouses[warehouseId || ''].timezone
      : timeZone;

    const data: IFetchReportData = {};
    Object.keys(plots).forEach((k) => {
      data[k] = {
        others: {
          // eslint-disable-next-line camelcase
          fixed_plots: (plots[k].fixed_plots || {}).default || false,
        },
        maps: Object.keys(plots[k].maps || {})
          .filter((m) => !!plots[k].maps[m])
          .map((m) => ({
            id: m.split('|')[0],
            type: m.split('|')[1],
          })),
        aggregations: Object.keys(plots[k].aggregation_plots || {})
          .filter((m) => !!plots[k].aggregation_plots[m])
          .map((index) => {
            const metric = metrics.find((m) => m.id === k);

            return ((metric || {}).defaultAggregations || [])[Number(index)];
          }),
      };
    });

    generateReport(
      filterId,

      assetIds,
      floorplanId || '',
      warehouseId || '',

      startDate || new Date(warehouseTz),
      endDate || new Date(warehouseTz),

      floorplanSensorPlacement,
      executiveSummary || '',

      data
    );
  }

  public render() {
    const {
      assets,
      dataRangeError,

      filterId,

      floorplans,
      loading,
      warehouses,

      floorplanId,
      warehouseId,

      startDate,
      endDate,

      metrics,

      theme,
    } = this.props;

    const {
      floorplanSensorPlacement,
      executiveSummary,

      plots,

      assetIds,
      showHidden,
    } = this.state;

    const drError = (
      <Alert severity="error">
        <FormattedMessage
          id="dashboard.plots.nodatarange"
          defaultMessage="Sorry, we were unable to load data range."
        />
      </Alert>
    );

    return (
      <>
        <div id="filters">
          <Typography variant="h6">
            <FormattedMessage
              id="dashboard.report.title"
              defaultMessage="Warehouse & Assets"
            />
          </Typography>
          <Divider />
          <FormControl required fullWidth margin="normal">
            <InputLabel htmlFor="form-warehouseid">
              <FormattedMessage
                id="dashboard.report.label_warehouse"
                defaultMessage="Warehouse"
              />
            </InputLabel>
            <Select
              name="warehouseId"
              value={warehouseId}
              onChange={this.handleFilterChange}
              input={<Input name="warehouseId" id="form-warehouseid" />}
              disabled={loading}
            >
              {warehouses &&
                Object.values(warehouses).map((w) => (
                  <MenuItem key={w.id} value={w.id}>
                    {w.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl required fullWidth margin="normal">
            <InputLabel htmlFor="form-floorplanid">
              <FormattedMessage
                id="dashboard.report.label_floorplan"
                defaultMessage="Floor plan"
              />
            </InputLabel>
            <Select
              name="floorplanId"
              value={floorplanId}
              onChange={this.handleFilterChange}
              input={<Input name="floorplanId" id="form-floorplanid" />}
              disabled={loading}
            >
              {warehouses &&
                Object.values(floorplans)
                  .filter((fp) => fp.warehouseId === warehouseId)
                  .filter((fp) => !fp.hidden || showHidden)
                  .map((fp: IncompleteFloorplan) => (
                    <MenuItem key={fp.id} value={fp.id}>
                      {fp.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormControl required={false} fullWidth margin="normal">
            <InputLabel htmlFor="form-assetIds">
              <FormattedMessage
                id="dashboard.report.label_assets"
                defaultMessage="Assets"
              />
            </InputLabel>
            <Select
              multiple
              name="assetIds"
              value={assetIds || []}
              onChange={this.handleFilterChange}
              onOpen={this.handleFilterOpen}
              onClose={this.handleFilterClose}
              renderValue={(selected: any) =>
                renderActiveAssets(assets, selected)
              }
              disabled={loading}
              variant="standard"
            >
              {Object.keys(assets)
                .filter(
                  (assetId) => assets[assetId].floorplanId === floorplanId
                )
                .map((assetId) => {
                  const { id, color, name } = assets[assetId];

                  return (
                    <MenuItem
                      key={id}
                      value={id}
                      style={{
                        color:
                          color !== undefined && color !== null
                            ? color
                            : undefined,
                      }}
                    >
                      {color !== undefined && color !== null ? (
                        <div
                          style={{
                            backgroundColor: color,
                            borderRadius: '15px',
                            height: '15px',
                            marginRight: '5px',
                            width: '15px',
                          }}
                        />
                      ) : null}
                      {name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
        {dataRangeError ? (
          drError
        ) : (
          <>
            <div id="summary">
              <Typography variant="h6" style={{ marginTop: '30px' }}>
                <FormattedMessage
                  id="dashboard.report.label_executivesummary"
                  defaultMessage="Executive Summary"
                />
              </Typography>
              <Divider />
              <div style={{ marginTop: '20px' }}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    multiline
                    name="executiveSummary"
                    label={
                      <FormattedMessage
                        id="dashboard.report.label_executivesummary"
                        defaultMessage="Executive Summary"
                      />
                    }
                    onChange={this.handleChange}
                    value={executiveSummary}
                    rows={4}
                    disabled={loading}
                    variant="standard"
                  />
                </FormControl>
              </div>
            </div>
            <div id="dataperiod">
              <Typography variant="h6" style={{ marginTop: '30px' }}>
                <FormattedMessage
                  id="dashboard.report.label_dataperiod"
                  defaultMessage="Data Period"
                />
              </Typography>
              <Divider />
              <div style={{ marginTop: '20px' }}>
                <DateTimeRangeSlider filterId={filterId} />
              </div>
            </div>
            <ReportCompositionForm
              filterId={filterId}
              loading={loading}
              floorplanSensorPlacement={floorplanSensorPlacement}
              plots={plots}
              handleChange={this.handleChange}
              handleSectionChange={this.handleSectionChange}
              handleSelectionChange={this.handleSelectionChange}
              metrics={metrics}
            />
            {loading && (
              <Box
                style={{
                  backgroundColor: theme === 'dark' ? '#525252' : '#F9F9F9',
                  border: '1px solid #CCC',
                  marginBottom: '15px',
                  padding: '15px',
                }}
              >
                <List>
                  {this.getProgressStep(ReportProgress.CollectingWhInfo)}
                  {this.getProgressStep(ReportProgress.FetchingAnalytics)}
                  {this.getProgressStep(ReportProgress.GeneratingAnalytics)}
                  {this.getProgressStep(ReportProgress.CompilingReport)}
                  {this.getProgressStep(ReportProgress.Downloading)}
                </List>
              </Box>
            )}
            <Box mt={2}>
              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <LoadingButton
                  id="generate"
                  onClick={this.submit}
                  variant="contained"
                  color="primary"
                  disabled={
                    loading ||
                    !warehouseId ||
                    !floorplanId ||
                    !startDate ||
                    !endDate
                  }
                  loading={loading}
                >
                  <FormattedMessage
                    id="dashboard.report.button_submit"
                    defaultMessage="Generate Report"
                  />
                </LoadingButton>
              </div>
            </Box>
          </>
        )}
      </>
    );
  }
}

export default injectIntl(ReportForm);
