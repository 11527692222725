import { max, min, take } from 'lodash';
import { Color, Path, Point, PointText, Matrix } from 'paper';

import { Component } from 'react';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Sensor from '@models/Sensor';
import { transformMetersToPixels } from '@dashboard_utils/index';
import { transformPixelsToMeters } from '../../../../../../utils';
import mapEvents from '../../eventEmitter';
import Paper from '../../Paper';
import MapImages from '../../MapImages';
import { IRegularFeature, IToolEvent, IHitEvent } from '../../../types';
import { defaultTransformationMatrix } from '../../../consts';

interface ISensor {
  id: string;
  sensorPath: IRegularFeature;
  sensorText?: paper.PointText;
}

interface IProps {
  floorplan: IncompleteFloorplan;
  paper: Paper;
  mapImages: MapImages;
  sensors: Sensor[];
  onChange: (points: number[][]) => void;
}

class AlignSensors extends Component<IProps> {
  private isAligning = false;

  private lastRotationAngle?: number;

  private aligningStartPosition?: paper.Point;

  private aligningDiffPosition?: paper.Point;

  private aligningStartPositions?: paper.Point[];

  private aligningEndPositions?: Record<string, paper.Point>;

  private elements: ISensor[] = [];

  constructor(props: IProps) {
    super(props);

    this.reload = this.reload.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseDrag = this.handleMouseDrag.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }

  public componentDidMount() {
    const { paper } = this.props;
    const { tool } = paper;

    mapEvents.on('resized', this.reload);

    tool.on('mousedown', this.handleMouseDown);
    tool.on('mousedrag', this.handleMouseDrag);
    tool.on('mousemove', this.handleMouseMove);
    tool.on('mouseup', this.handleMouseUp);

    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { sensors } = this.props;

    if (JSON.stringify(prevProps.sensors) !== JSON.stringify(sensors)) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    const { paper } = this.props;
    const { tool } = paper;

    mapEvents.removeListener('resized', this.reload);

    tool.removeListener('mousedown', this.handleMouseDown);
    tool.removeListener('mousedrag', this.handleMouseDrag);
    tool.removeListener('mousemove', this.handleMouseMove);
    tool.removeListener('mouseup', this.handleMouseUp);

    this.clear();
  }

  public handleMouseDown(event: IToolEvent) {
    const { paper } = this.props;

    if (event.event.ctrlKey === true) {
      this.isAligning = true;
      mapEvents.emit('lockmap', 'lock');

      this.aligningStartPosition = event.point!;
      this.aligningStartPositions = this.elements.map(
        (s) => s.sensorPath.position as paper.Point
      );
    } else {
      let [hit]: IHitEvent[] = paper.scope.project!.hitTestAll(event.point!, {
        fill: false,
        match: (h: IHitEvent) => h.type === 'segment',
        segments: true,
        stroke: false,
        tolerance: 50,
      });
      if (!hit) {
        const [hitEvent] = paper.scope.project!.hitTestAll(event.point!, {
          fill: true,
          match: (h: IHitEvent) => h.type === 'stroke' || h.type === 'fill',
          segments: false,
          stroke: true,
          tolerance: 15,
        });

        hit = hitEvent;
      }

      if (
        hit &&
        hit.item &&
        hit.item.featureInfo &&
        hit.item.featureInfo.type === 'sensors'
      ) {
        this.isAligning = true;
        mapEvents.emit('lockmap', 'lock');

        this.aligningStartPosition = event.point!;
        this.aligningStartPositions = this.elements.map(
          (s) => s.sensorPath.position as paper.Point
        );
      }
    }
  }

  public handleMouseDrag(event: IToolEvent) {
    const { paper } = this.props;

    if (!this.isAligning) {
      return;
    }

    const point = event.point || ({} as paper.Point);
    const downPoint = event.downPoint || ({} as paper.Point);

    if (event.event.shiftKey === true) {
      // Sensor alignment rotation

      this.aligningStartPosition = point;

      // find center
      const x1 =
        min(this.aligningStartPositions!.map((position) => position.x)) || 0;
      const y1 =
        min(this.aligningStartPositions!.map((position) => position.y)) || 0;
      const x2 =
        max(this.aligningStartPositions!.map((position) => position.x)) || 1;
      const y2 =
        max(this.aligningStartPositions!.map((position) => position.y)) || 1;
      const center = new Point(x1 + (x2 - x1) / 2, y1 + (y2 - y1) / 2);

      const sAngle = Math.atan2(
        (downPoint.y || 0) - (center.y || 0),
        (downPoint.x || 0) - (center.x || 0)
      );
      const pAngle = Math.atan2(
        (point.y || 0) - (center.y || 0),
        (point.x || 0) - (center.x || 0)
      );

      const rotation =
        pAngle - sAngle >= (this.lastRotationAngle || 0) ? 1 : -1;
      this.lastRotationAngle = pAngle - sAngle;

      this.elements.forEach((s) => {
        const newPosition = new Point(
          (center.x || 0) +
            Math.cos(0.0025 * rotation) *
              ((s.sensorPath.position!.x || 0) - (center.x || 0)) -
            Math.sin(0.0025 * rotation) *
              ((s.sensorPath.position!.y || 0) - (center.y || 0)),
          (center.y || 0) +
            Math.sin(0.0025 * rotation) *
              ((s.sensorPath.position!.x || 0) - (center.x || 0)) +
            Math.cos(0.0025 * rotation) *
              ((s.sensorPath.position!.y || 0) - (center.y || 0))
        );

        // eslint-disable-next-line no-param-reassign
        s.sensorPath.position = newPosition;
        if (s.sensorText) {
          // eslint-disable-next-line no-param-reassign
          s.sensorText.position = new Point(
            (newPosition.x || 0) + 12 / paper.getZoom() + 3,
            (newPosition.y || 0) - 12 / paper.getZoom() - 3
          );
        }
      });
    } else {
      this.aligningDiffPosition = new Point(
        (this.aligningStartPosition!.x || 0) - (point.x || 0),
        (this.aligningStartPosition!.y || 0) - (point.y || 0)
      );

      this.aligningEndPositions = {};

      this.elements.forEach((s, index) => {
        const newPosition = new Point(
          (this.aligningStartPositions![index].x || 0) -
            (this.aligningDiffPosition!.x || 0),
          (this.aligningStartPositions![index].y || 0) -
            (this.aligningDiffPosition!.y || 0)
        );

        this.aligningEndPositions![s.id] = newPosition;

        // eslint-disable-next-line no-param-reassign
        s.sensorPath.position = newPosition;
        if (s.sensorText) {
          // eslint-disable-next-line no-param-reassign
          s.sensorText.position = new Point(
            (newPosition.x || 0) + 12 / paper.getZoom() + 3,
            (newPosition.y || 0) - 12 / paper.getZoom() - 3
          );
        }
      });
    }
  }

  public handleMouseMove(event: IToolEvent) {
    const { paper } = this.props;

    if (event.event.shiftKey === false) {
      const point = event.point || ({} as paper.Point);
      let [hit] = paper.scope.project!.hitTestAll(point, {
        fill: false,
        match: (h: IHitEvent) => h.type === 'segment',
        segments: true,
        stroke: false,
        tolerance: 50,
      });

      if (!hit) {
        const [hitEvent] = paper.scope.project!.hitTestAll(point, {
          fill: true,
          match: (h: IHitEvent) => h.type === 'stroke' || h.type === 'fill',
          segments: false,
          stroke: true,
          tolerance: 15,
        });
        hit = hitEvent;
      }

      if (hit !== undefined) {
        document.body.style.cursor = 'move';
      } else {
        document.body.style.cursor = 'default';
      }
    }
  }

  public handleMouseUp() {
    const { floorplan, mapImages, onChange } = this.props;

    if (this.isAligning === true) {
      this.isAligning = false;
      mapEvents.emit('lockmap', 'unlock');

      if (this.aligningStartPositions) {
        onChange(
          this.elements.map((s) => {
            s.sensorPath.transform(
              new Matrix(1, 0, 0, -1, 0, mapImages.height)
            );
            const point = transformPixelsToMeters(
              [s.sensorPath.position!.x || 0, s.sensorPath.position!.y || 0],
              defaultTransformationMatrix,
              floorplan.scale || 1
            );
            s.sensorPath.transform(
              new Matrix(1, 0, 0, -1, 0, mapImages.height)
            );

            return point;
          })
        );
      }
    }
  }

  public load() {
    const { sensors, floorplan, paper, mapImages } = this.props;

    sensors.forEach((s) => {
      let sensorPosition = [0, 0];
      if (s.position) {
        sensorPosition = transformMetersToPixels(
          take(s.position, 2) as [number, number],
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        );
      }

      let point = new Point(sensorPosition);
      if (this.aligningEndPositions) {
        point = this.aligningEndPositions[s.id] || point;
      }

      const sensorPath = new Path.RegularPolygon(
        point,
        4,
        12 / paper.getZoom()
      ) as IRegularFeature;
      sensorPath.featureInfo = {
        id: s.id,
        props: {
          physicalAddress: s.physicalAddress,
          type: s.type,
          x: (s.position || [0, 0, 0])[0].toFixed(2),
          y: (s.position || [0, 0, 0])[1].toFixed(2),
          z: (s.position || [0, 0, 0])[2].toFixed(2),
        },
        title: 'Stationary',
        type: 'sensors',
      };
      sensorPath.fillColor = new Color('#000');
      sensorPath.strokeColor = new Color('red');
      sensorPath.strokeWidth = 3;
      sensorPath.strokeScaling = false;
      sensorPath.rotate(45, point);
      if (!this.aligningEndPositions || !this.aligningEndPositions[s.id]) {
        sensorPath.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
      }

      const sensorText = new PointText(point);
      sensorText.content = s.physicalAddress;
      sensorText.fillColor = new Color('black');
      sensorText.fontSize = '24px';
      sensorText.fontWeight = 'bold';
      sensorText.position = new Point(
        (sensorPath.position!.x || 0) + 12 / paper.getZoom() + 3,
        (sensorPath.position!.y || 0) - 12 / paper.getZoom() - 3
      );

      this.elements.push({ id: s.id, sensorPath, sensorText });
    });
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((s) => {
      s.sensorPath.remove();
      if (s.sensorText) {
        s.sensorText.remove();
      }
    });
    this.elements = [];
  }

  public render() {
    return null;
  }
}

export default AlignSensors;
