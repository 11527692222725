import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  batteryLow: {
    defaultMessage: 'Low',
    id: 'dashboard.sensors.battery.low',
  },
  batteryMessage: {
    defaultMessage: '{minutes} minutes left',
    id: 'dashboard.sensors.battery.message',
  },
  batteryOk: {
    defaultMessage: 'Ok',
    id: 'dashboard.sensors.battery.ok',
  },
  batteryVeryLow: {
    defaultMessage: 'Very Low',
    id: 'dashboard.sensors.battery.verylow',
  },
  groupMore: {
    defaultMessage: 'More',
    id: 'dashboard.groups.actions.menu_aria_label',
  },
  groupTurnOff: {
    defaultMessage: 'Deactivate all',
    id: 'dashboard.groups.actions.turnoff',
  },
  groupTurnOn: {
    defaultMessage: 'Activate all',
    id: 'dashboard.groups.actions.turnon',
  },
  rowAsset: {
    defaultMessage: 'Asset',
    id: 'dashboard.sensors.table.header.asset',
  },
  rowBattery: {
    defaultMessage: 'Battery',
    id: 'dashboard.sensors.table.header.battery',
  },
  rowControls: {
    defaultMessage: 'Controls',
    id: 'dashboard.sensors.table.header.control',
  },
  rowCreated: {
    defaultMessage: 'Created',
    id: 'dashboard.sensors.table.header.created',
  },
  rowId: {
    defaultMessage: 'Id',
    id: 'dashboard.sensors.table.header.id',
  },
  rowPhysicalAddress: {
    defaultMessage: 'Address',
    id: 'dashboard.sensors.table.header.address',
  },
  rowRSSI: {
    defaultMessage: 'RSSI',
    id: 'dashboard.sensors.table.header.rssi',
  },
  rowStatus: {
    defaultMessage: 'Status',
    id: 'dashboard.sensors.table.header.status',
  },
  rowType: {
    defaultMessage: 'Type',
    id: 'dashboard.sensors.table.header.type',
  },
  rowVCC: {
    defaultMessage: 'VCC',
    id: 'dashboard.sensors.table.header.vcc',
  },
  rowWorkingTime: {
    defaultMessage: 'Working Time',
    id: 'dashboard.sensors.table.header.workingtime',
  },
  tableNextPage: {
    defaultMessage: 'Next Page',
    id: 'dashboard.table.next_page',
  },
  tablePreviousPage: {
    defaultMessage: 'Previous Page',
    id: 'dashboard.table.previous_page',
  },
  turnOff: {
    defaultMessage: 'Turn Off',
    id: 'dashboard.sensors.actions.turnoff',
  },
  turnOn: {
    defaultMessage: 'Turn On',
    id: 'dashboard.sensors.actions.turnon',
  },
  typeBeacon: {
    defaultMessage: 'Mobile',
    id: 'dashboard.sensors.label_type_moving',
  },
  typeStationaryBeacon: {
    defaultMessage: 'Stationary',
    id: 'dashboard.sensors.label_type_stationary',
  },
});

export default {};
