import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import { EventEmitter } from 'stream';
import React, { Component, LegacyRef } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { ICreatePlan, IFetchPlans, IUpdatePlan } from '@actions/index';
import { IFetchFloorplanSensors } from '@actions/sensors';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '@app/dialogs/ConfirmDialog/types';
import ThreeDMap, { emitter } from '@app/common/3DMap';
import { withRouter } from '@app/utils/withRouter';
import { transformMeters } from '@dashboard_utils/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Plan, { IPlan, SensorSettings } from '@models/Plan';
import Sensor from '@models/Sensor';
import SensorSettingsComponent from './SensorSettings';

interface IProps {
  intl: IntlShape;

  floorplan?: IncompleteFloorplan;
  sensors: Sensor[];
  language: string;

  router: any;

  confirm: (properties: IConfirmDialog) => void;
  createPlan: (properties: ICreatePlan) => void;
  updatePlan: (properties: IUpdatePlan) => void;
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => void;
  fetchSensors: (floorplanId: string) => void;
  fetchPlans: (properties: IFetchPlans) => void;

  redirectId?: string;
  statePlan?: Plan;
}
interface IState {
  activeSensor?: SensorSettings;
  anchorEl?: Element;
  anchorElInfo?: Element;
  changed: boolean;
  name: string;
  plan: IPlan;
  showSensorPop: boolean;

  coverage: number;
  space: number;
  quality: number;
}

class RTLSPlanner extends Component<IProps, IState> {
  public emitter: EventEmitter = emitter;

  public map?: LegacyRef<ThreeDMap>;

  constructor(props: IProps) {
    super(props);

    const { statePlan } = props;

    this.state = {
      changed: false,
      name: (statePlan || {}).name || 'No name',
      plan: {
        // 60 m line-of-sight range typical (decaWave DWM1001 Datasheet)
        coverageBySensor: 30,
        pathLoss: 'simple',
        simpleBestCoverage: 5,
        simplePoorestCoverage: 25,
        onerpowBestCoverage: 0.01,
        onerpowPoorestCoverage: 0.05,
        rackSignalAttenuation: 0.5,
        wallSignalAttenuation: 1,
        delutionOfPrecision: 0,
        reflectionFactor: 0,
        ...(statePlan || {}).plan,
      },
      showSensorPop: false,

      coverage: 0,
      space: 0,
      quality: 0,
    };

    this.showName = this.showName.bind(this);
    this.hideName = this.hideName.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.addSensor = this.addSensor.bind(this);
    this.addExistingSensors = this.addExistingSensors.bind(this);
    this.clearPlan = this.clearPlan.bind(this);
    this.savePlan = this.savePlan.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.closeSensor = this.closeSensor.bind(this);
    this.dealWithSensor = this.dealWithSensor.bind(this);
    this.showInfo = this.showInfo.bind(this);
    this.hideInfo = this.hideInfo.bind(this);
    this.handlePropChange = this.handlePropChange.bind(this);
    this.updateStats = this.updateStats.bind(this);
  }

  public componentDidMount() {
    const { fetchActiveSensorGroup, fetchPlans, fetchSensors, floorplan } =
      this.props;

    if (floorplan) {
      fetchPlans({ floorplanId: floorplan.id });
      fetchActiveSensorGroup({ floorplanId: floorplan.id });
      fetchSensors(floorplan.id);
    }

    this.emitter.on('sensors-changed', (sensorPositions: SensorSettings[]) => {
      const { plan } = this.state;

      this.setState({ changed: true, plan: { ...plan, sensorPositions } });
    });
    this.emitter.on('sensor-focus', (sensor: SensorSettings) => {
      this.setState({
        activeSensor: sensor,
        showSensorPop: true,
      });
    });
    this.emitter.on('sensor-unfocus', () => {
      this.setState({
        changed: true,
        showSensorPop: false,
      });
    });
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      fetchPlans,
      fetchActiveSensorGroup,
      fetchSensors,
      floorplan,
      router,
      language,
      redirectId,
      statePlan,
    } = this.props;

    if (!prevProps.redirectId && !!redirectId) {
      router.navigate(
        `/${language}/dashboard/rtlsplanner/plan/floorplan/${
          (floorplan || {}).id
        }/id/${redirectId}/edit`
      );
    }

    if (
      floorplan &&
      JSON.stringify(floorplan) !== JSON.stringify(prevProps.floorplan)
    ) {
      fetchPlans({ floorplanId: floorplan.id });
      fetchActiveSensorGroup({ floorplanId: floorplan.id });
      fetchSensors(floorplan.id);
    }

    if (
      statePlan &&
      JSON.stringify(statePlan) !== JSON.stringify(prevProps.statePlan)
    ) {
      this.updateState();
    }
  }

  public handleNameChange(event: any) {
    this.setState({ changed: true, name: event.target.value });
  }

  public handleDelete(id: string) {
    const { confirm } = this.props;

    this.setState({ showSensorPop: false }, () => {
      confirm({
        confirmType: ConfirmDialogActions.DELETE,
        message: 'Are you sure that you want to delete this sensor?',
        onConfirmation: () => {
          this.emitter.emit('sensor-deleted', { id });
        },
      });
    });
  }

  public handlePropChange(event: any) {
    const { plan } = this.state;

    const name = event.target.name as
      | 'coverageBySensor'
      | 'pathLoss'
      | 'simpleBestCoverage'
      | 'simplePoorestCoverage'
      | 'onerpowBestCoverage'
      | 'onerpowPoorestCoverage'
      | 'rackSignalAttenuation'
      | 'wallSignalAttenuation'
      | 'reflectionFactor';
    this.setState({
      plan: {
        ...plan,
        [name]:
          event.target.name !== 'pathLoss'
            ? Number(event.target.value)
            : event.target.value,
      },
    });
  }

  public updateState() {
    const { statePlan } = this.props;

    this.setState({
      changed: false,
      name: (statePlan || {}).name || '',
      plan: {
        coverageBySensor: 30,
        pathLoss: 'simple',
        simpleBestCoverage: 5,
        simplePoorestCoverage: 25,
        onerpowBestCoverage: 0.01,
        onerpowPoorestCoverage: 0.05,
        rackSignalAttenuation: 0.5,
        wallSignalAttenuation: 1,
        delutionOfPrecision: 0,
        reflectionFactor: 0,
        ...(statePlan || {}).plan,
      },
    });
  }

  public showInfo(event: any) {
    this.setState({ anchorElInfo: event.target });
  }

  public hideInfo() {
    this.setState({ anchorElInfo: undefined });
  }

  public updateStats(coverage: number, space: number, quality: number) {
    this.setState({ coverage, space, quality });
  }

  public showName(event: any) {
    this.setState({ anchorEl: event.target });
  }

  public hideName() {
    this.setState({ anchorEl: undefined });
  }

  public addExistingSensors() {
    const { floorplan, sensors } = this.props;
    const { plan } = this.state;

    this.setState({
      changed: true,
      plan: {
        ...plan,
        sensorPositions: (plan.sensorPositions || []).concat(
          sensors
            .map((s) => ({
              ...s,
              position: transformMeters(
                [(s.position || [])[0] || 0, (s.position || [])[1] || 0],
                floorplan!.transformationMatrix
              ),
            }))
            .map((s) => ({
              identifier: s.physicalAddress,
              positionX: (s.position || [])[0] || 0,
              positionY: (s.position || [])[1] || 0,
              positionZ: 3,
            }))
        ),
      },
    });
  }

  public addSensor() {
    this.setState({ activeSensor: undefined, showSensorPop: true });
  }

  public clearPlan() {
    this.setState({
      changed: true,
      plan: {
        sensorPositions: [],
        coverageBySensor: 30,
        pathLoss: 'simple',
        simpleBestCoverage: 5,
        simplePoorestCoverage: 25,
        onerpowBestCoverage: 0.01,
        onerpowPoorestCoverage: 0.05,
        rackSignalAttenuation: 0.5,
        wallSignalAttenuation: 1,
        delutionOfPrecision: 0,
        reflectionFactor: 0,
      },
    });
  }

  public savePlan() {
    const { createPlan, updatePlan, intl, floorplan, statePlan } = this.props;
    const { name, plan } = this.state;

    if (statePlan && statePlan.id) {
      updatePlan({
        intl,
        id: statePlan.id,
        floorplanId: floorplan!.id,
        name,
        plan,
      });
    } else {
      createPlan({ intl, floorplanId: floorplan!.id, name, plan });
    }
  }

  public dealWithSensor(sensor: SensorSettings) {
    this.emitter.emit('sensor-changed', sensor);
  }

  public closeSensor() {
    this.emitter.emit('sensor-unfocus');

    this.setState({ showSensorPop: false });
  }

  public render() {
    const { floorplan, language } = this.props;
    const {
      activeSensor,
      anchorEl,
      anchorElInfo,
      changed,
      plan,
      name,
      showSensorPop,

      coverage,
      space,
      quality,
    } = this.state;

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '10px' }}>
          <Link
            color="inherit"
            to={`/${language}/dashboard/rtlsplanner/`}
            component={RouterLink}
          >
            RTLS Planner
          </Link>
          <Typography color="textPrimary">{name || 'New RTLS Plan'}</Typography>
        </Breadcrumbs>
        <Card className="editor-card">
          <CardContent className="editor-container">
            {showSensorPop && (
              <SensorSettingsComponent
                close={this.closeSensor}
                handleDelete={this.handleDelete}
                dealWithSensor={this.dealWithSensor}
                data={activeSensor}
              />
            )}
            <div className="editor-content">
              {floorplan && (
                <ThreeDMap
                  ref={this.map}
                  editor
                  floorplan={floorplan}
                  plan={plan}
                  updateStats={this.updateStats}
                  showBoundaries
                  showFloorplan
                  showSensors={false}
                  showRacks
                />
              )}
            </div>
            {floorplan && (
              <div className="action-bar">
                <Tooltip title="Information">
                  <IconButton onClick={this.showInfo}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Show Name">
                  <IconButton onClick={this.showName}>
                    <TextRotationNoneIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add existing sensors to map">
                  <IconButton onClick={this.addExistingSensors}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add existing sensors to map">
                  <IconButton onClick={this.clearPlan}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add new sensor">
                  <IconButton onClick={this.addSensor}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
                {!changed ? (
                  <IconButton disabled onClick={this.savePlan}>
                    <SaveIcon />
                  </IconButton>
                ) : (
                  <Tooltip title="Save plan">
                    <IconButton onClick={this.savePlan}>
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )}
          </CardContent>
        </Card>
        <Popover
          open={!!anchorElInfo}
          anchorEl={anchorElInfo}
          onClose={this.hideInfo}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Card style={{ width: '320px' }}>
            <CardHeader title="Setup Info" />
            <CardContent>
              <Grid container>
                <Grid item sm={6}>
                  <Typography variant="subtitle2">
                    Number of sensors:
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    {(plan.sensorPositions || []).length}
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="subtitle2">Total Area:</Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{space} m2</Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="subtitle2">Covered Area:</Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{coverage} m2</Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="subtitle2">Coverage Quality:</Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    {(quality / coverage).toFixed(2)} [0-1]
                  </Typography>
                </Grid>
              </Grid>
              <FormControl fullWidth variant="standard" margin="normal">
                <TextField
                  label="Line-of-Sight inter sensor distance (m)"
                  value={plan.coverageBySensor}
                  variant="standard"
                  name="coverageBySensor"
                  type="number"
                  inputProps={{ step: 0.01 }}
                  onChange={this.handlePropChange}
                />
              </FormControl>
              <FormControl fullWidth variant="standard" margin="normal">
                <InputLabel htmlFor="pathLoss">Path Loss Model</InputLabel>
                <Select
                  id="pathLoss"
                  value={plan.pathLoss}
                  name="pathLoss"
                  onChange={this.handlePropChange}
                >
                  <MenuItem value="simple">Simple distance (AVG)</MenuItem>
                  <MenuItem value="onerpow">1/R^2 (AVG 3/4 Closest)</MenuItem>
                </Select>
              </FormControl>
              {plan.pathLoss === 'simple' ? (
                <>
                  <FormControl fullWidth variant="standard" margin="normal">
                    <TextField
                      label="Best Coverage Target Value (m)"
                      value={plan.simpleBestCoverage}
                      variant="standard"
                      name="simpleBestCoverage"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      onChange={this.handlePropChange}
                    />
                  </FormControl>
                  <FormControl fullWidth variant="standard" margin="normal">
                    <TextField
                      label="Poorest Coverage Target Value (m)"
                      value={plan.simplePoorestCoverage}
                      variant="standard"
                      name="simplePoorestCoverage"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      onChange={this.handlePropChange}
                    />
                  </FormControl>
                </>
              ) : (
                <>
                  <FormControl fullWidth variant="standard" margin="normal">
                    <TextField
                      label="Best Coverage Target Value (value)"
                      value={plan.onerpowBestCoverage}
                      variant="standard"
                      name="simpleBestCoverage"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      onChange={this.handlePropChange}
                    />
                  </FormControl>
                  <FormControl fullWidth variant="standard" margin="normal">
                    <TextField
                      label="Poorest Coverage Target Value (value)"
                      value={plan.onerpowPoorestCoverage}
                      variant="standard"
                      name="onerpowPoorestCoverage"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      onChange={this.handlePropChange}
                    />
                  </FormControl>
                </>
              )}
              <FormControl fullWidth variant="standard" margin="normal">
                <InputLabel htmlFor="rackSignalAttenuation">
                  Signal Attenuation (on racks) (%)
                </InputLabel>
                <Select
                  id="rackSignalAttenuation"
                  value={plan.rackSignalAttenuation}
                  name="rackSignalAttenuation"
                  onChange={this.handlePropChange}
                >
                  <MenuItem value="0">None</MenuItem>
                  <MenuItem value="0.1">10%</MenuItem>
                  <MenuItem value="0.2">20%</MenuItem>
                  <MenuItem value="0.3">30%</MenuItem>
                  <MenuItem value="0.4">40%</MenuItem>
                  <MenuItem value="0.5">50%</MenuItem>
                  <MenuItem value="0.6">60%</MenuItem>
                  <MenuItem value="0.7">70%</MenuItem>
                  <MenuItem value="0.8">80%</MenuItem>
                  <MenuItem value="0.9">90%</MenuItem>
                  <MenuItem value="1">100%</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth variant="standard" margin="normal">
                <InputLabel htmlFor="wallSignalAttenuation">
                  Signal Attenuation (on walls) (%)
                </InputLabel>
                <Select
                  id="wallSignalAttenuation"
                  value={plan.wallSignalAttenuation}
                  name="wallSignalAttenuation"
                  onChange={this.handlePropChange}
                >
                  <MenuItem value="0">None</MenuItem>
                  <MenuItem value="0.1">10%</MenuItem>
                  <MenuItem value="0.2">20%</MenuItem>
                  <MenuItem value="0.3">30%</MenuItem>
                  <MenuItem value="0.4">40%</MenuItem>
                  <MenuItem value="0.5">50%</MenuItem>
                  <MenuItem value="0.6">60%</MenuItem>
                  <MenuItem value="0.7">70%</MenuItem>
                  <MenuItem value="0.8">80%</MenuItem>
                  <MenuItem value="0.9">90%</MenuItem>
                  <MenuItem value="1">100%</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth variant="standard" margin="normal">
                <InputLabel htmlFor="delutionOfPrecision">
                  Dilution of precision
                </InputLabel>
                <Select
                  id="delutionOfPrecision"
                  value={plan.delutionOfPrecision}
                  name="delutionOfPrecision"
                  onChange={this.handlePropChange}
                >
                  <MenuItem value="0">None</MenuItem>
                  <MenuItem value="30">30 º</MenuItem>
                  <MenuItem value="45">45 º</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth variant="standard" margin="normal">
                <InputLabel htmlFor="reflectionFactor">
                  Refection factor
                </InputLabel>
                <Select
                  id="reflectionFactor"
                  value={plan.reflectionFactor}
                  name="reflectionFactor"
                  onChange={this.handlePropChange}
                >
                  <MenuItem value="0">None</MenuItem>
                  <MenuItem value="1">1</MenuItem>
                </Select>
              </FormControl>
            </CardContent>
          </Card>
        </Popover>

        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={this.hideName}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Card style={{ width: '320px' }}>
            <CardHeader title="Plan Name" />
            <CardContent>
              <FormControl required fullWidth margin="normal">
                <TextField
                  label="Name"
                  variant="standard"
                  value={name}
                  onChange={this.handleNameChange}
                />
              </FormControl>
            </CardContent>
          </Card>
        </Popover>
      </>
    );
  }
}

export default withRouter(injectIntl(RTLSPlanner));
