import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closeTaskForm,
  createTask,
  ICreateTask,
  IUpdateTask,
  updateTask,
  fetchEmployees,
  fetchTeams,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import TaskForm from './TaskForm';

const mapStateToProps = (state: IAppState) => ({
  error: state.tasks.form.error,
  loading: state.tasks.form.loading || state.employees.loading || state.teams.loading,
  data: state.tasks.form.data,
  employeeMap: state.employees.employees,
  teamMap: state.teams.teams,
  assetMap: state.assets.assets,
  floorplans: state.floorplans.floorplans,
  warehouses: state.warehouses.warehouses,
  zones: state.zones.zones,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createTask: (properties: ICreateTask) =>
    dispatch(createTask.request(properties)),
  updateTask: (properties: IUpdateTask) =>
    dispatch(updateTask.request(properties)),
  closeForm: () => dispatch(closeTaskForm()),
  fetchEmployees: (shallow?: boolean) =>
    dispatch(fetchEmployees.request({ shallow })),
  fetchTeams: (shallow?: boolean) =>
    dispatch(fetchTeams.request({ shallow }))
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
