import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import AutoRenewIcon from '@mui/icons-material/Autorenew';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery90Icon from '@mui/icons-material/Battery90';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PauseCircleIcon from '@mui/icons-material/PauseCircleFilled';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RouterIcon from '@mui/icons-material/Router';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IFetchFloorplanSensors } from '@actions/index';
import ws, { IGatewayPingData, ISensorBatteryData } from '@api/websocket';
import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import { PRIMARY_COLOR } from '@app/utils/colors';
import { Date, timeZone } from '@dashboard_utils/index';
import Account from '@models/Account';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import SensorGroupWithStatus from '@models/SensorGroupWithStatus';
import SensorWithStatus, { SensorsWithStatus } from '@models/SensorWithStatus';
import { Warehouses } from '@models/Warehouse';
import { hasPermission } from '@selectors/accounts';
import MobileSensor from './MobileSensor';
import { messages } from './messages';

interface IProps {
  loading: boolean;

  account: Account;
  floorplans: IncompleteFloorplans;
  floorplanId: string;
  fetchGroupsStatus: (properties: IFetchFloorplanSensors) => void;
  fetchSensorsStatus: (properties: IFetchFloorplanSensors) => void;
  router: any;
  group: SensorGroupWithStatus | undefined;
  groupLoading: boolean;
  intl: IntlShape;
  language: string;
  sensors: SensorsWithStatus;
  warehouseId: string;
  warehouses: Warehouses;
}

interface IExtMUIDataTableColumn extends ITableColumn {
  customerPage: boolean;
}

interface IState {
  anchorEl?: any;
  columns: IExtMUIDataTableColumn[];
  customerPage: boolean;
  systemStatus: any;
  showMobileSensorPopup: boolean;
}

class SensorsTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { account, intl } = props;

    this.state = {
      columns: [
        {
          customerPage: true,
          label: intl.formatMessage(messages.rowPhysicalAddress),
          name: 'physicalAddress',
        },
        {
          customerPage: false,
          label: intl.formatMessage(messages.rowId),
          name: 'id',
          options: { filter: true },
        },
        {
          customerPage: true,
          label: intl.formatMessage(messages.rowType),
          name: 'type',
          options: { filter: true },
        },
        {
          customerPage: true,
          label: intl.formatMessage(messages.rowAsset),
          name: 'assetName',
          options: { filter: true },
        },
        {
          customerPage: false,
          label: intl.formatMessage(messages.rowCreated),
          name: 'created',
          options: { filter: false },
        },
        /*
        {
          customerPage: true,
          label: intl.formatMessage(messages.rowStatus),
          name: 'status',
          options: { filter: true },
        },
        {
          customerPage: false,
          label: intl.formatMessage(messages.rowVCC),
          name: 'vcc',
          options: { filter: false },
        },
        {
          customerPage: false,
          label: intl.formatMessage(messages.rowRSSI),
          name: 'rssi',
          options: { filter: false },
        },
        {
          customerPage: false,
          label: intl.formatMessage(messages.rowWorkingTime),
          name: 'workingTime',
          options: { filter: false },
        },
        {
          customerPage: true,
          label: intl.formatMessage(messages.rowControls),
          name: 'controls',
          options: { interaction: false },
        },
        */
      ],
      customerPage: !hasPermission(account, 'settings'),
      systemStatus: {},
      showMobileSensorPopup: false,
    };

    this.customRowRender = this.customRowRender.bind(this);
    this.handleBatteryMessage = this.handleBatteryMessage.bind(this);
    this.handleCommand = this.handleCommand.bind(this);
    this.isCommandLoading = this.isCommandLoading.bind(this);
    this.isGroupActive = this.isGroupActive.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.handleGatewayPingMessage = this.handleGatewayPingMessage.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.toggleCustomerPage = this.toggleCustomerPage.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
    this.openMobileSensor = this.openMobileSensor.bind(this);
    this.closeMobileSensor = this.closeMobileSensor.bind(this);
  }

  public componentDidMount() {
    ws.on('battery', this.handleBatteryMessage);
    ws.on('gateway-ping', this.handleGatewayPingMessage);
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();

    setInterval(() => {
      const { systemStatus } = this.state;
      const now = Date.now();
      let update = false;

      Object.keys(systemStatus).forEach((k) => {
        if (
          systemStatus[k].updated !== undefined &&
          systemStatus[k].updated.getTime() < now - 60000
        ) {
          systemStatus[k] = {};

          update = true;
        }
      });

      if (update !== false) {
        this.setState({ systemStatus });
      }
    }, 20000);
  }

  public componentDidUpdate(prevProps: any) {
    const { fetchGroupsStatus, fetchSensorsStatus, floorplanId, warehouseId } =
      this.props;

    if (
      prevProps.warehouseId !== warehouseId ||
      prevProps.floorplanId !== floorplanId
    ) {
      if (floorplanId) {
        fetchGroupsStatus({ floorplanId });
        fetchSensorsStatus({ floorplanId });
      }
    }
  }

  public componentWillUnmount() {
    ws.removeListener('battery', this.handleBatteryMessage);
    ws.removeListener('gateway-ping', this.handleGatewayPingMessage);

    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  public handleMenuClick(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleMenuClose() {
    this.setState({ anchorEl: undefined });
  }

  /**
   * @description Populates systemStatus with ws data, reseting command status
   * @param       {ISensorBatteryData} data
   */
  public handleBatteryMessage(data: ISensorBatteryData) {
    const { systemStatus } = this.state;

    const updatedStatus = { ...systemStatus };
    updatedStatus[data.sensorId] = {
      ...updatedStatus[data.sensorId],
      battery: data.batteryFlag,
      loading: false,
      rssi: data.rssi,
      status: data.status,
      ts: data.ts,
      vcc: data.vcc,
      workingTime: data.workingTime,
      'sensor-wake': {
        loading: false,
      },
      'sensor-sleep': {
        loading: false,
      },
      'sensor-status': {
        loading: false,
      },
    };

    this.setState({ systemStatus: updatedStatus });
  }

  /**
   * @description Populates systemStatus with ws data, reseting command status
   * @param       {IGatewayBatteryData} data
   */
  public handleGatewayPingMessage(data: IGatewayPingData) {
    const { systemStatus } = this.state;
    const { warehouseId, warehouses } = this.props;

    const warehouseTz = warehouses[warehouseId]
      ? warehouses[warehouseId].timezone
      : timeZone;

    const messageInfo = {
      loading: false,
      updated: new Date(warehouseTz),
    };

    const updatedStatus = { ...systemStatus };
    if (data.event === 'ping') {
      updatedStatus[data.sensorGroupId] = {
        device: data.info.device,
        ip: data.info.ip,
        ...messageInfo,
      };
    } else if (data.event === 'boot') {
      updatedStatus[data.sensorGroupId] = {
        device: '...',
        ip: '...',
        ...messageInfo,
      };
    } else if (data.event === 'modem-connected') {
      updatedStatus[data.sensorGroupId] = {
        device: data.info.device,
        ip: (updatedStatus[data.sensorGroupId] || {}).ip,
        ...messageInfo,
      };
    } else if (data.event === 'modem-disconnect') {
      updatedStatus[data.sensorGroupId] = {
        device: undefined,
        ip: (updatedStatus[data.sensorGroupId] || {}).ip,
        ...messageInfo,
      };
    } else if (data.event === 'shutdown') {
      updatedStatus[data.sensorGroupId] = {
        ...messageInfo,
      };
    }

    this.setState({ systemStatus: updatedStatus });
  }

  /**
   * @param {string} id      Sensor id or sensor group id
   * @param {string} command command, (see websockets)
   * @param {string} type    group or sensor
   */
  public handleCommand(id: string, command: string, type: string) {
    const { systemStatus } = this.state;
    const { sensors } = this.props;

    const updatedStatus = { ...systemStatus };

    const updateSimpleStatus = () => {
      if (updatedStatus[id] === undefined) {
        updatedStatus[id] = {};
      }

      if (updatedStatus[id][command] === undefined) {
        updatedStatus[id][command] = {};
      }

      updatedStatus[id][command].loading = true;
      updatedStatus[id].loading = true;
    };

    if (type === 'group') {
      if (
        command === 'sensor-wake' ||
        command === 'sensor-sleep' ||
        command === 'sensor-status'
      ) {
        for (let i = 0; i < sensors.length; i += 1) {
          if (sensors[i].id !== id) {
            if (updatedStatus[sensors[i].id] === undefined) {
              updatedStatus[sensors[i].id] = {};
            }

            if (updatedStatus[sensors[i].id][command] === undefined) {
              updatedStatus[sensors[i].id][command] = {};
            }

            updatedStatus[sensors[i].id][command].loading = true;
            updatedStatus[sensors[i].id].loading = true;
          }
        }
      } else {
        updateSimpleStatus();
      }
    } else {
      updateSimpleStatus();
    }

    this.setState({ systemStatus: updatedStatus, anchorEl: undefined });

    if (type === 'group') {
      ws.sendGroupCommand(id, command);
    } else {
      ws.sendCommand(id, command);
    }
  }

  public handleSensorLink(sensorId: string) {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/live?sensorId=${sensorId}`);
  }

  /**
   * @description Merges systemStatus data (received via ws) with sensor data
   *              prioritizing systemStatus data if it exists (updating sensor object)
   * @param       {SensorWithStatus} sensors
   * @returns     {SensorWithStatus}
   */
  public getSensorData(sensor: SensorWithStatus): SensorWithStatus {
    const { systemStatus } = this.state;

    const sensorData = { ...sensor };

    if (systemStatus[sensor.id] !== undefined) {
      sensorData.status = systemStatus[sensor.id].status || sensorData.status;
      sensorData.battery =
        systemStatus[sensor.id].battery || sensorData.battery;
      sensorData.rssi =
        systemStatus[sensor.id].rssi &&
        systemStatus[sensor.id].rssi < sensorData.rssi
          ? systemStatus[sensor.id].rssi
          : sensorData.rssi;
      sensorData.vcc =
        systemStatus[sensor.id].vcc &&
        systemStatus[sensor.id].vcc < sensorData.vcc
          ? systemStatus[sensor.id].vcc
          : sensorData.vcc;
      sensorData.workingTime =
        systemStatus[sensor.id].workingTime || sensorData.workingTime;
      sensorData.ts = systemStatus[sensor.id].ts || sensorData.ts;
    }

    return sensorData;
  }

  /**
   * @description Merges systemStatus data (received via ws) with sensor group data
   *              prioritizing systemStatus data if it exists (updating sensor group object)
   * @param       {SensorGroupWithStatus} group
   * @returns     {SensorGroupWithStatus}
   */
  public getSensorGroupData(
    group: SensorGroupWithStatus | undefined
  ): SensorGroupWithStatus {
    const { systemStatus } = this.state;

    const groupData = { ...(group || {}) } as SensorGroupWithStatus;
    if (systemStatus[groupData.id] !== undefined) {
      groupData.ip = systemStatus[groupData.id].ip || groupData.ip;
      groupData.device = systemStatus[groupData.id].device || groupData.device;
    }

    return groupData;
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const { fetchGroupsStatus, fetchSensorsStatus, floorplanId } = this.props;

    if (floorplanId) {
      fetchGroupsStatus({ floorplanId, shallow });
      fetchSensorsStatus({ floorplanId, shallow });
    }
  }

  /**
   * @param   {string} id      Sensor id or sensor group id
   * @param   {string} command command
   * @returns {boolean} Is loading or not
   */
  public isCommandLoading(id: string, command: string) {
    const { systemStatus } = this.state;

    return (
      systemStatus[id] !== undefined &&
      systemStatus[id][command] !== undefined &&
      systemStatus[id][command].loading === true
    );
  }

  /**
   * @description Checks if the lastest group is active
   * @returns {boolean} Is group active or not
   */
  public isGroupActive() {
    const { group } = this.props;
    const { systemStatus } = this.state;

    return (
      (systemStatus[(group || ({} as SensorGroupWithStatus)).id] || group || {})
        .ip !== undefined
    );
  }

  /**
   * @param   {string}  id Sensor id or sensor group id
   * @returns {boolean} Is loading or not
   */
  public isLoading(id: string) {
    const { systemStatus } = this.state;

    return systemStatus[id] !== undefined && systemStatus[id].loading === true;
  }

  public toggleCustomerPage() {
    const { customerPage } = this.state;

    this.setState({ customerPage: !customerPage });
  }

  public openMobileSensor() {
    this.setState({ showMobileSensorPopup: true });
  }

  public closeMobileSensor() {
    this.setState({ showMobileSensorPopup: false });
  }

  public customRowRender(
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    const { intl, sensors, warehouseId, warehouses } = this.props;
    const { customerPage } = this.state;

    const data = sensors.map((s) => this.getSensorData(s));

    if (!data[dataIndex]) {
      return <TableRow key={rowIndex} />;
    }

    const warehouseTz = warehouses[warehouseId]
      ? warehouses[warehouseId].timezone
      : timeZone;

    const sensorStatus = () => {
      if (
        this.isLoading(data[dataIndex].id) === true ||
        this.isLoading(data[dataIndex].sensorGroupId) === true
      ) {
        return (
          <span className="label-loading">
            <AutoRenewIcon fontSize="small" />
            <FormattedMessage
              id="dashboard.sensors.status.wait"
              defaultMessage="Updating"
            />
          </span>
        );
      }

      if (this.isGroupActive() === true) {
        if (data[dataIndex].status === 'sleeping') {
          return (
            <Tooltip title={new Date(warehouseTz, data[dataIndex].ts).format()}>
              <span className="label-off">
                <PauseCircleIcon fontSize="small" />
                <FormattedMessage
                  id="dashboard.sensors.status.off"
                  defaultMessage="Inactive"
                />
              </span>
            </Tooltip>
          );
        }
        if (
          data[dataIndex].status === 'active' ||
          data[dataIndex].status === 'alive'
        ) {
          return (
            <Tooltip title={new Date(warehouseTz, data[dataIndex].ts).format()}>
              <span className="label-on">
                <CheckCircleIcon fontSize="small" />
                <FormattedMessage
                  id="dashboard.sensors.status.on"
                  defaultMessage="Active"
                />
              </span>
            </Tooltip>
          );
        }
        if (data[dataIndex].status === 'missing') {
          return (
            <Tooltip title={new Date(warehouseTz, data[dataIndex].ts).format()}>
              <span className="label-missing">
                <ErrorIcon fontSize="small" />
                <FormattedMessage
                  id="dashboard.sensors.status.missing"
                  defaultMessage="Missing"
                />
              </span>
            </Tooltip>
          );
        }
      }

      return null;
    };

    const options = () =>
      customerPage === false || data[dataIndex].type === 'beacon' ? (
        <>
          <IconButton
            aria-label={intl.formatMessage(messages.turnOn)}
            onClick={() => {
              this.handleCommand(data[dataIndex].id, 'sensor-wake', 'sensor');
            }}
            disabled={
              this.isGroupActive() === false ||
              this.isCommandLoading(data[dataIndex].id, 'sensor-wake') ||
              this.isLoading(data[dataIndex].id) === true ||
              this.isLoading(data[dataIndex].sensorGroupId) === true
            }
          >
            {this.isCommandLoading(data[dataIndex].id, 'sensor-wake') ? (
              <CircularProgress size={24} />
            ) : (
              <PlayCircleOutlineIcon />
            )}
          </IconButton>
          <IconButton
            aria-label={intl.formatMessage(messages.turnOff)}
            onClick={() => {
              this.handleCommand(data[dataIndex].id, 'sensor-sleep', 'sensor');
            }}
            disabled={
              this.isGroupActive() === false ||
              this.isCommandLoading(data[dataIndex].id, 'sensor-sleep') ||
              this.isLoading(data[dataIndex].id) === true ||
              this.isLoading(data[dataIndex].sensorGroupId) === true
            }
          >
            {this.isCommandLoading(data[dataIndex].id, 'sensor-sleep') ? (
              <CircularProgress size={24} />
            ) : (
              <PauseCircleOutlineIcon />
            )}
          </IconButton>
        </>
      ) : null;

    if (customerPage === false) {
      const batteryLevel = ((rowData[6] - 3400) * 100) / (4000 - 3400);

      let sensorType = null;
      if (rowData[2] === 'beacon') {
        sensorType = intl.formatMessage(messages.typeBeacon);
      } else if (rowData[2] === 'stationary_beacon') {
        sensorType = intl.formatMessage(messages.typeStationaryBeacon);
      }

      let batteryColor = 'green';
      if (batteryLevel <= 10) {
        batteryColor = 'red';
      } else if (batteryLevel <= 50) {
        batteryColor = 'orange';
      }

      let batteryMaxLevel = Number(batteryLevel).toFixed(3);
      if (rowData[6] > 4000) {
        batteryMaxLevel = String(100);
      } else if (rowData[6] < 3400) {
        batteryMaxLevel = String(0);
      }

      return (
        <TableRow key={rowIndex}>
          {columns[0] && <TableCell>{rowData[0]}</TableCell>}
          {columns[1] && (
            <TableCell>
              <Button
                onClick={() => this.handleSensorLink(rowData[1])}
                style={{
                  color: PRIMARY_COLOR,
                  cursor: 'pointer',
                  fontWeight: 700,
                }}
              >
                {rowData[1]}
              </Button>
            </TableCell>
          )}
          {columns[2] && <TableCell>{sensorType}</TableCell>}
          {columns[3] && <TableCell>{rowData[3]}</TableCell>}
          {columns[4] && (
            <TableCell>{new Date(warehouseTz, rowData[4]).format()}</TableCell>
          )}
          {columns[5] && <TableCell>{sensorStatus()}</TableCell>}
          {columns[6] && (
            <TableCell>
              {rowData[6] && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>
                    {Number(rowData[6] / 1000).toFixed(3)}
                    <span>V</span>
                  </span>
                  <div
                    style={{
                      alignItems: 'center',
                      color: batteryColor,
                      display: 'flex',
                      fontWeight: 700,
                      padding: '5px',
                    }}
                  >
                    {batteryLevel <= 30 && <Battery20Icon />}
                    {batteryLevel <= 60 && batteryLevel > 30 && (
                      <Battery30Icon />
                    )}
                    {batteryLevel <= 90 && batteryLevel > 60 && (
                      <Battery90Icon />
                    )}
                    {batteryLevel > 90 && <BatteryFullIcon />}
                    {batteryMaxLevel}
                    <span>%</span>
                  </div>
                  {rowData[6] <= 4000 && rowData[6] >= 3400 && (
                    <i>
                      {intl.formatMessage(messages.batteryMessage, {
                        minutes: Number(
                          (rowData[6] - 3400) / data[dataIndex].dischargeRate
                        ).toFixed(3),
                      })}
                    </i>
                  )}
                </div>
              )}
            </TableCell>
          )}
          {columns[7] && <TableCell>{rowData[7]}</TableCell>}
          {columns[8] && (
            <TableCell>
              {rowData[8]
                ? new Date('Etc/UTC', rowData[8] * 1000).format('HH:mm [h.]')
                : null}
            </TableCell>
          )}
          {columns[9] && <TableCell>{options()}</TableCell>}
        </TableRow>
      );
    }

    let sensorType2 = null;
    if (rowData[1] === 'beacon') {
      sensorType2 = intl.formatMessage(messages.typeBeacon);
    } else if (rowData[1] === 'stationary_beacon') {
      sensorType2 = intl.formatMessage(messages.typeStationaryBeacon);
    }

    return (
      <TableRow key={rowIndex}>
        {columns[0] && <TableCell>{rowData[0]}</TableCell>}
        {columns[1] && <TableCell>{sensorType2}</TableCell>}
        {columns[2] && <TableCell>{rowData[2]}</TableCell>}
        {columns[3] && <TableCell>{sensorStatus()}</TableCell>}
        {columns[4] && <TableCell>{options()}</TableCell>}
      </TableRow>
    );
  }

  public render() {
    const {
      account,
      floorplanId,
      group,
      groupLoading,
      intl,
      loading,
      sensors,
    } = this.props;
    const { anchorEl, columns, customerPage, showMobileSensorPopup } = this.state;

    const selectedColumns = columns.filter(
      (c) => !customerPage || c.customerPage === customerPage
    );
    const data = sensors.map((s) => this.getSensorData(s));

    const groupWithUpdatedData = this.getSensorGroupData(group);

    if (anchorEl) {
      // command features are not suported yet in the new decawave setup
      // eslint-disable-next-line no-console
      console.log(intl, anchorEl);
    }

    let nav = null;
    if (groupLoading) {
      nav = (
        <IconButton disabled>
          <CircularProgress size={24} />
        </IconButton>
      );
    } else if (group !== undefined) {
      nav = (
        <div
          key={group.id}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span className="hardware-status">
            <div className="global-controls">
              <Badge
                color="primary"
                variant="dot"
                className={
                  groupWithUpdatedData.ip !== undefined
                    ? 'badge-on'
                    : 'badge-off'
                }
                style={{ float: 'left' }}
              >
                <RouterIcon fontSize="large" />
              </Badge>
              <div className="gateway-info">
                <i className="gateway">{groupWithUpdatedData.id}</i>
                <i className="gateway">{groupWithUpdatedData.ip}</i>
                <i className="gateway device">{groupWithUpdatedData.device}</i>
              </div>
            </div>
          </span>
          {/* 
            <span className="hardware-controls">
              <Button
                aria-label={intl.formatMessage(messages.groupTurnOn)}
                onClick={() =>
                  this.handleCommand(
                    groupWithUpdatedData.id,
                    'sensor-wake',
                    'group'
                  )
                }
                disabled={
                  groupWithUpdatedData.ip === undefined ||
                  this.isCommandLoading(
                    groupWithUpdatedData.id,
                    'sensor-wake'
                  ) === true ||
                  this.isLoading(groupWithUpdatedData.id) === true
                }
                style={{ textTransform: 'none' }}
              >
                {this.isCommandLoading(
                  groupWithUpdatedData.id,
                  'sensor-wake'
                ) ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <PlayCircleOutlineIcon style={{ marginRight: '5px' }} />
                    {intl.formatMessage(messages.groupTurnOn)}
                  </>
                )}
              </Button>
              <Button
                aria-label={intl.formatMessage(messages.groupTurnOff)}
                onClick={() =>
                  this.handleCommand(
                    groupWithUpdatedData.id,
                    'sensor-sleep',
                    'group'
                  )
                }
                disabled={
                  groupWithUpdatedData.ip === undefined ||
                  this.isCommandLoading(
                    groupWithUpdatedData.id,
                    'sensor-sleep'
                  ) === true ||
                  this.isLoading(groupWithUpdatedData.id) === true
                }
                style={{ textTransform: 'none' }}
              >
              {this.isCommandLoading(groupWithUpdatedData.id, 'sensor-sleep')
                ? (<CircularProgress size={24} />)
                : (
                  <>
                    <PauseCircleOutlineIcon style={{ marginRight: '5px' }} />
                    {intl.formatMessage(messages.groupTurnOff)}
                  </>
                )}
              </Button>
              {hasPermission(account, 'settings') && customerPage === false ? (
                <>
                  <IconButton
                    aria-label={intl.formatMessage(messages.groupMore)}
                    aria-owns={anchorEl ? 'long-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenuClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={this.handleMenuClose}
                  >
                    <MenuItem
                      onClick={() =>
                        this.handleCommand(
                          groupWithUpdatedData.id,
                          'modem-disconnect',
                          'group'
                        )
                      }
                      disabled={
                        groupWithUpdatedData.ip === undefined ||
                        this.isCommandLoading(
                          groupWithUpdatedData.id,
                          'modem-disconnect'
                        ) === true ||
                        this.isLoading(groupWithUpdatedData.id) === true
                      }
                    >
                      <FormattedMessage
                        id="dashboard.groups.actions.modemdisconnect"
                        defaultMessage="Modem Disconnect"
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.handleCommand(
                          groupWithUpdatedData.id,
                          'modem-connect',
                          'group'
                        )
                      }
                      disabled={
                        groupWithUpdatedData.ip === undefined ||
                        this.isCommandLoading(
                          groupWithUpdatedData.id,
                          'modem-connect'
                        ) === true ||
                        this.isLoading(groupWithUpdatedData.id) === true
                      }
                    >
                      <FormattedMessage
                        id="dashboard.groups.actions.modemconnect"
                        defaultMessage="Modem Connect"
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.handleCommand(
                          groupWithUpdatedData.id,
                          'modem-restart',
                          'group'
                        )
                      }
                      disabled={
                        groupWithUpdatedData.ip === undefined ||
                        this.isCommandLoading(
                          group.id,
                          'groupWithUpdatedData-restart'
                        ) === true ||
                        this.isLoading(groupWithUpdatedData.id) === true
                      }
                    >
                      <FormattedMessage
                        id="dashboard.groups.actions.modemrestart"
                        defaultMessage="Modem Restart"
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.handleCommand(
                          groupWithUpdatedData.id,
                          'gateway-restart',
                          'group'
                        )
                      }
                      disabled={
                        groupWithUpdatedData.ip === undefined ||
                        this.isCommandLoading(
                          groupWithUpdatedData.id,
                          'gateway-restart'
                        ) === true ||
                        this.isLoading(groupWithUpdatedData.id) === true
                      }
                    >
                      <FormattedMessage
                        id="dashboard.groups.actions.gatewayrestart"
                        defaultMessage="Gateway Restart"
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.handleCommand(
                          groupWithUpdatedData.id,
                          'system-reboot',
                          'group'
                        )
                      }
                      disabled={
                        groupWithUpdatedData.ip === undefined ||
                        this.isCommandLoading(
                          groupWithUpdatedData.id,
                          'system-reboot'
                        ) === true ||
                        this.isLoading(groupWithUpdatedData.id) === true
                      }
                    >
                      <FormattedMessage
                        id="dashboard.groups.actions.systemreboot"
                        defaultMessage="System Reboot"
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.handleCommand(
                          groupWithUpdatedData.id,
                          'sensor-status',
                          'group'
                        )
                      }
                      disabled={
                        groupWithUpdatedData.ip === undefined ||
                        this.isCommandLoading(
                          groupWithUpdatedData.id,
                          'sensor-status'
                        ) === true ||
                        this.isLoading(groupWithUpdatedData.id) === true
                      }
                    >
                      <FormattedMessage
                        id="dashboard.groups.actions.sensorstatus"
                        defaultMessage="Sensor Status"
                      />
                    </MenuItem>
                  </Menu>
                </>
              ) : null}
            </span>
          */}
        </div>
      );
    }

    return (
      <>
        {hasPermission(account, 'settings') ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 20px',
            }}
            mb={2}
          >
            <FormControlLabel
              label={
                <FormattedMessage
                  id="dashboard.sensors.customerversion"
                  defaultMessage="See customer sensor page"
                />
              }
              control={
                <Checkbox
                  color="secondary"
                  checked={customerPage}
                  onChange={this.toggleCustomerPage}
                />
              }
            />
            <Button id="add-asset" onClick={this.openMobileSensor}>
              <AddIcon style={{ marginRight: '5px' }} />
              <FormattedMessage
                id="dashboard.sensors.addmobilesensor"
                defaultMessage="Add Mobile Sensor"
              />
            </Button>
          </Box>
        ) : null}
        {floorplanId && (
          <Loading loading={loading}>
            {nav}

            <Card>
              <CardContent>
                <div className="table-container">
                  {customerPage && (
                    <MUITable
                      columns={selectedColumns}
                      customRowRender={this.customRowRender}
                      data={data}
                      tableId="sensors"
                    />
                  )}
                  {!customerPage && (
                    <MUITable
                      columns={selectedColumns}
                      customRowRender={this.customRowRender}
                      data={data}
                      tableId="sensors"
                    />
                  )}
                </div>
              </CardContent>
            </Card>
          </Loading>
        )}
        {showMobileSensorPopup && (<MobileSensor floorplanId={floorplanId} account={account!} closeForm={this.closeMobileSensor} />)}
      </>
    );
  }
}

export default injectIntl(SensorsTab);
