import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';

import { FetchTickets, fetchTickets } from '@actions/tickets';
import TicketsTab from './TicketsTab';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  loading: state.tickets.loading,
  tickets: Object.values(state.tickets.tickets),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTickets: (properties: FetchTickets) =>
    dispatch(fetchTickets.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsTab);
