import { size } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchFloorplanSensors } from '@actions/index';
import axios from '@api/axios';
import { fakeSensors } from '@datasets/sensors';
import { SensorTypes } from '@models/Sensor';
import { IAppState } from '@reducers/index';
import {
  getFloorplanSensorsByType,
  isSensorByFloorplanLoading,
} from '@selectors/sensors';
import StepContainer from './StepContainer';

interface IProps {
  floorplanId: string;
  onBack: () => void;
  onNext: () => void;
  warehouseId: string;
}

const mapStateToProps = (state: IAppState, { floorplanId }: IProps) => ({
  loading: isSensorByFloorplanLoading(state, floorplanId),
  nextDisabled:
    size(
      getFloorplanSensorsByType(state, SensorTypes.STATIONARY, floorplanId)
    ) < 2,
});

const mapDispatchToProps = (dispatch: Dispatch, { floorplanId }: IProps) => ({
  // FIXME: Debug only
  createFakeSensors: async () => {
    const {
      data: { id: sensorGroupId },
    } = await axios.post('/sensors/groups', {
      floorplanId,
    });

    const promises = fakeSensors.map((s) =>
      axios.post('/sensors', {
        floorplanId,
        physicalAddress: s.physicalAddress,
        position: s.position,
        sensorGroupId,
        type: s.type,
      })
    );

    Promise.all(promises).then(() => {
      dispatch(
        fetchFloorplanSensors.request({
          floorplanId,
        })
      );
    });
  },
  onRefresh: () =>
    dispatch(
      fetchFloorplanSensors.request({
        floorplanId,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepContainer);
