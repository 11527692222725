import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReconnectingStates } from '@actions/shallow-loading';

interface IProps {
  loading: boolean;
  reconnecting?: ReconnectingStates;
}

const ShallowLoading: FunctionComponent<IProps> = (properties: IProps) => {
  const { loading, reconnecting } = properties;

  let content;
  if (loading === true) {
    content = (
      <Box mb={2}>
        <Alert severity="info">
          <FormattedMessage
            id="dashboard.ws.loading"
            defaultMessage="Updating your data..."
          />
        </Alert>
      </Box>
    );
  }
  if (reconnecting === ReconnectingStates.RECONNECTING) {
    content = (
      <Box mb={2}>
        <Alert severity="warning">
          <FormattedMessage
            id="dashboard.ws.reconnecting"
            defaultMessage="Reconnecting..."
          />
        </Alert>
      </Box>
    );
  }
  if (reconnecting === ReconnectingStates.DISCONNECTED) {
    content = (
      <Box mb={2}>
        <Alert severity="warning">
          <FormattedMessage
            id="dashboard.ws.disconnected"
            defaultMessage="Your connection to Logidot cloud is offline, the data shown may not be the most up to date"
          />
        </Alert>
      </Box>
    );
  }

  return content || null;
};

export default ShallowLoading;
