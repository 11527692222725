import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  confirmDialog,
  ArchiveAssetTicket,
  archiveAssetTicket,
  changeAssetTicketStatus,
  ChangeAssetTicketStatus,
} from '@actions/index';
import { fetchAssetTickets } from '@actions/assets';
import { withRouter } from '@app/utils/withRouter';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { getActiveLanguage } from '@selectors/app';
import { IAppState } from '@reducers/index';
import Tickets from './Tickets';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  loading: state.assets.tickets.loading,
  tickets: Object.values(state.assets.tickets.tickets).filter(
    (ticket) => ticket.archived === false
  ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  archiveAssetTicket: (properties: ArchiveAssetTicket) =>
    dispatch(archiveAssetTicket.request(properties)),
  changeAssetTicketStatus: (properties: ChangeAssetTicketStatus) =>
    dispatch(changeAssetTicketStatus.request(properties)),
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  fetchAssetTickets: (assetId: string) =>
    dispatch(fetchAssetTickets.request({ assetId })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Tickets)
);
