import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  handleNotification,
  logout,
  openModeSelector,
  toggleMenu,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { Notification } from '@models/Notification';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import Navbar from './Navbar';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account,
  authenticated: state.authentication.authenticated,
  language: getActiveLanguage(state),
  loading: state.authentication.loading,
  open: state.app.menu,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleNotification: (notification: Notification, type: string) =>
    dispatch(handleNotification(notification, type)),
  logout: () => dispatch(logout.request()),
  openModeSelector: () => dispatch(openModeSelector()),
  toggleMenu: (open: boolean) => dispatch(toggleMenu(open)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
