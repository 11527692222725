import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchFloorplan,
  fetchFloorplanSensors,
  IUpdateFloorplan,
  updateFloorplan,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import FloorplanConfigurationFormDialog from './FloorplanConfigurationForm';

import '../../setup/Setup.css';
import './index.css';

interface IProps {
  router: any;
}

const mapStateToProps = (state: IAppState, { router }: IProps) => {
  const { floorplanId } = router.params;
  const floorplan = getFloorplanById(state, floorplanId);

  return {
    floorplan,
    language: getActiveLanguage(state),
    loading: state.floorplans.loading,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFloorplan: (id: string) => dispatch(fetchFloorplan.request({ id })),
  fetchStationarySensors: (floorplanId: string) =>
    dispatch(fetchFloorplanSensors.request({ floorplanId })),
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FloorplanConfigurationFormDialog)
);
