import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { accountSetup } from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import AccountSetupPage from './AccountSetupPage';

import './index.css';

const mapStateToProps = (state: IAppState) => ({
  error: state.authentication.invite.error,
  language: getActiveLanguage(state),
  loading: state.authentication.invite.loading,
  setupSuccess: state.authentication.invite.success,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    accountSetup: (email: string, password: string, verification: string) =>
      dispatch(accountSetup.request({ email, password, verification })),
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSetupPage));
