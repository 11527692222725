import React, { Component } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Map from '@app/common/FullMap';
import { IFilter } from '@reducers/filter';
import { ISimulatorConfig } from '@models/Simulation';
import Loading from '@app/common/Loading';
import Asset from '@models/Asset';

interface IProps {
  assets: Asset[];
  filter: IFilter;
  filterId: string;
  loading: boolean;
  data: ISimulatorConfig;
  myAssetId?: string;
  fetchRoutingData: (floorplanId: string) => void;
  onMyAssetChange: (floorplanId: string, assetId: string) => void;
}

class Routing extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  public componentDidMount() {
    const { fetchRoutingData, filter } = this.props;

    fetchRoutingData(filter.floorplanId || '');
  }

  public handleChange(event: any) {
    const { onMyAssetChange, filter } = this.props;

    onMyAssetChange(filter.floorplanId || '', event.target.value);
  }

  public render() {
    const { assets, filterId, data, loading, myAssetId } = this.props;

    return (
      <Loading loading={loading}>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="assets-form-type">My Asset:</InputLabel>
          <Select variant="standard" value={myAssetId} onChange={this.handleChange}>
            {assets.map((a) => (
              <MenuItem key={a.id} value={a.id}>
                {a.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Map
          filterId={filterId}
          forceDisabledLayers={['gmaps']}
          showLiveData
          layers={{
            simulatorRouting: {
              id: 'simulatorRouting',
              label: 'Sim',
              disabled: true,
              active: true,
              data: { ...data, myAssetId },
            },
          }}
          actions={{ select: {}, hover: {} }}
        />
      </Loading>
    );
  }
}

export default Routing;
