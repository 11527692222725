import { Component } from 'react';
import { Color, Path, Point, Matrix } from 'paper';
import { take } from 'lodash';

import Sensor from '@models/Sensor';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { transformMetersToPixels } from '@dashboard_utils/index';
import Paper from '../../Paper';
import MapImages from '../../MapImages';
import mapEvents, { orderLayers } from '../../eventEmitter';
import { IRegularFeature, MapFeature } from '../../../types';
import { defaultTransformationMatrix } from '../../../consts';

interface IProps {
  floorplan: IncompleteFloorplan;
  id: string;
  mapImages: MapImages;
  paper: Paper;
  sensors: Sensor[];
  updateSelectedFeature: (id: string, feature: MapFeature) => void;
  router: any;
}

class SensorMapLayer extends Component<IProps> {
  private elements: IRegularFeature[] = [];

  constructor(props: IProps) {
    super(props);

    this.reload = this.reload.bind(this);
    this.sendToBack = this.sendToBack.bind(this);
  }

  public componentDidMount() {
    mapEvents.on('resized', this.reload);
    mapEvents.on('order_layers', this.sendToBack);

    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { sensors } = this.props;

    if (JSON.stringify(prevProps.sensors) !== JSON.stringify(sensors)) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    mapEvents.removeListener('resized', this.reload);
    mapEvents.removeListener('order_layers', this.sendToBack);

    this.clear();
  }

  public load() {
    const {
      floorplan,
      id,
      paper,
      mapImages,
      router,
      sensors,
      updateSelectedFeature,
    } = this.props;

    paper.scope.activate();

    sensors.forEach((s) => {
      let sensorPosition = [0, 0];
      if (s.position) {
        sensorPosition = transformMetersToPixels(
          take(s.position, 2) as [number, number],
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        );
      }

      const point = new Point(sensorPosition);
      const sensorPath = new Path.RegularPolygon(
        point,
        4,
        12 / paper.getZoom()
      ) as IRegularFeature;
      sensorPath.featureInfo = {
        id: s.id,
        props: {
          physicalAddress: s.physicalAddress,
          type: s.type,
          x: (s.position || [0, 0, 0])[0].toFixed(2),
          y: (s.position || [0, 0, 0])[1].toFixed(2),
          z: (s.position || [0, 0, 0])[2].toFixed(2),
        },
        title: 'Stationary',
        type: 'sensors',
      };
      sensorPath.fillColor = new Color('#000');
      sensorPath.strokeColor = new Color('red');
      sensorPath.strokeWidth = 3;
      sensorPath.strokeScaling = false;
      sensorPath.rotate(45, point);
      sensorPath.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

      this.elements.push(sensorPath);

      if (router.query.sensorId && router.query.sensorId === s.id) {
        updateSelectedFeature(id, sensorPath);
      }
    });

    orderLayers();
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((s) => s.remove());
    this.elements = [];
  }

  public sendToBack(layerType: string) {
    if (layerType === 'other_layers' && this.elements.length) {
      this.elements.forEach((e) => e.sendToBack());
    }
  }

  public render() {
    return null;
  }
}

export default SensorMapLayer;
