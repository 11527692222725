import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchRules,
  fetchTemplates,
  IFetchRules,
  IFetchTemplates,
  openRuleForm,
} from '@actions/index';
import { Rule } from '@models/Rule';
import { IAppState } from '@reducers/index';
import {
  getAccountLocale,
  getAccountMeasurementUnits,
} from '@selectors/accounts';
import { getValidRules } from '@selectors/rules';
import List from './List';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;

  const filter = state.filter.filters[filterId] || {};

  return {
    editionData: state.rules.form.data,
    floorplanId: filter.floorplanId || '',
    formActive: state.rules.form.active,
    loading:
      state.rules.loading ||
      state.rules.templatesLoading ||
      state.rules.form.loading,
    locale: getAccountLocale(state.authentication),
    measurementUnits: getAccountMeasurementUnits(state.authentication),
    rules: getValidRules(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRules: (properties: IFetchRules) =>
    dispatch(fetchRules.request(properties)),
  fetchTemplates: (properties: IFetchTemplates) =>
    dispatch(fetchTemplates.request(properties)),
  openRuleForm: (rule?: Rule) => dispatch(openRuleForm(rule)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
