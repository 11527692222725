import Alert from '@mui/material/Alert';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { FetchMetrics } from '@actions/index';
import FetchError from '@models/FetchError';
import MetricMeta from '@models/MetricMeta';
import Loading from '@app/common/Loading';
import { IActiveMetrics } from '@reducers/analytics';
import ErrorPlaceholder from '../../../../utils/ErrorPlaceholder';

interface IProps {
  activeMetrics: IActiveMetrics;

  section: 'user' | 'diagnostics';

  metrics: MetricMeta[];

  filterId: string;

  warehouseId: string;
  floorplanId: string;
  zoneIds: string[];

  tags: string[];
  assetIds: string[];

  templateIds: string[];
  ruleIds: string[];

  loading: boolean;
  error?: FetchError;

  conditions: FetchMetrics;

  fetchMetrics: (properties: FetchMetrics) => void;
  toggleMetric: (
    filterId: string,
    section: 'user' | 'diagnostics',
    metricId: string
  ) => void;

  locale: string;
  measurementUnits: string;

  children?: React.ReactNode;
}

/**
 * Analytics Metrics Provider. Component responsible for fetching metric meta information.
 * Reacts to active filter change.
 */
class MetricsProvider extends Component<IProps> {
  public componentDidMount() {
    const {
      fetchMetrics,

      section,

      filterId,

      floorplanId,
      warehouseId,
      zoneIds,

      tags,
      assetIds,

      templateIds,
      ruleIds,

      locale,
      measurementUnits,

      conditions,
    } = this.props;

    /* Fetchs only if conditions change from last fetch */
    if (
      conditions.filterId !== filterId ||
      conditions.section !== section ||
      conditions.floorplanId !== floorplanId ||
      conditions.warehouseId !== warehouseId ||
      JSON.stringify(conditions.zoneIds) !== JSON.stringify(zoneIds) ||
      JSON.stringify(conditions.tags) !== JSON.stringify(tags) ||
      JSON.stringify(conditions.assetIds) !== JSON.stringify(assetIds) ||
      JSON.stringify(conditions.templateIds) !== JSON.stringify(templateIds) ||
      JSON.stringify(conditions.ruleIds) !== JSON.stringify(ruleIds) ||
      conditions.locale !== locale ||
      conditions.units !== measurementUnits
    ) {
      fetchMetrics({
        filterId,
        section,

        floorplanId,
        warehouseId,
        zoneIds,

        tags,
        assetIds,

        templateIds,
        ruleIds,

        locale,
        units: measurementUnits,
      });
    }
  }

  public componentDidUpdate(prevProps: any) {
    const { activeMetrics, filterId, section, loading, metrics, toggleMetric } =
      this.props;

    if (
      prevProps.loading === true &&
      loading === false &&
      filterId &&
      !activeMetrics[section]
    ) {
      if (metrics.length) {
        toggleMetric(filterId, section, metrics[0].id);
      }
    }
  }

  public render() {
    const { children, error, loading } = this.props;

    return (
      <Loading loading={loading}>
        {error ? (
          <>
            <Alert severity="error">
              <FormattedMessage
                id="dashboard.plots.nometrics"
                defaultMessage="Sorry, we were unable to load metrics."
              />
            </Alert>
            <ErrorPlaceholder />
          </>
        ) : (
          children
        )}
      </Loading>
    );
  }
}

export default MetricsProvider;
