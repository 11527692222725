import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import { DropzoneArea, FileObject } from 'react-mui-dropzone';
import { getType } from 'mime';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import axios from '@api/axios';

import { withRouter } from '@app/utils/withRouter';
import { CreateTicket, UpdateTicket, ExtendedFile } from '@actions/tickets';
import LoadingButton from '@app/common/LoadingButton';
import { Tickets, APIFile } from '@models/Ticket';
import { isImage } from '@app/utils';

async function createFile(file: APIFile): Promise<ExtendedFile> {
  const response = await axios.get(file.path, { responseType: 'blob' });
  const metadata = {
    type: getType(file.originalname) || 'image/png',
  };

  const f = new File(
    [response.data],
    file.originalname,
    metadata
  ) as ExtendedFile;
  f.id = file.id;
  return f;
}

interface IProps {
  language: string;
  createTicket: (properties: CreateTicket) => void;
  updateTicket: (properties: UpdateTicket) => void;
  intl: IntlShape;
  router: any;
  formLoading: boolean;
  loading: boolean;
  redirect: boolean;
  tickets: Tickets;
}
interface IState {
  id?: string;
  subject: string;
  description: string;
  files: File[];
  filesLoading: boolean;
}

class TicketForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { tickets, router } = props;

    const ticket = (tickets || {})[router.params.id || ''] || {};

    this.state = {
      id: ticket.id,
      subject: ticket.subject || '',
      description: ticket.description || '',
      files: [],
      filesLoading: (ticket.files || []).length > 0,
    };

    if (ticket.files) {
      this.loadFiles(ticket.files);
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { router, language, tickets, redirect } = this.props;

    if (
      JSON.stringify(tickets) !== JSON.stringify(prevProps.tickets) &&
      router.params.id
    ) {
      this.updateFormData();
    }

    if (prevProps.redirect !== redirect && redirect) {
      router.navigate(
        !router.params.id
          ? `/${language}/dashboard/tickets`
          : `/${language}/dashboard/tickets/id/${router.params.id}`
      );
    }
  }

  public handleChange(field: keyof IState, event: any) {
    this.setState({ [field]: event.target.value } as Pick<IState, 'subject'>);
  }

  public handleFiles(files: File[]) {
    this.setState({ files });
  }

  public handleCancel() {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/tickets`);
  }

  public handleSubmit() {
    const { createTicket, updateTicket, intl } = this.props;
    const { id, subject, description, files } = this.state;

    if (id) {
      return updateTicket({ id, subject, description, files, intl });
    }

    return createTicket({ subject, description, files, intl });
  }

  public hasError() {
    const { subject, description } = this.state;

    return !subject.length || !description.length;
  }

  public updateFormData() {
    const { tickets, router } = this.props;

    const ticket = (tickets || {})[router.params.id || ''] || {};

    this.setState(
      {
        id: ticket.id,
        subject: ticket.subject || '',
        description: ticket.description || '',
        files: [],
        filesLoading: (ticket.files || []).length > 0,
      },
      () => {
        if (ticket.files) {
          this.loadFiles(ticket.files);
        }
      }
    );
  }

  public loadFiles(fs: APIFile[]) {
    Promise.all(fs.map((file) => createFile(file))).then((files) => {
      this.setState({ files, filesLoading: false });
    });
  }

  public render() {
    const { id, subject, description, files, filesLoading } = this.state;
    const { formLoading, language, loading, router } = this.props;

    const buttonDisabled = !subject.length || !description.length;

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink
            color="inherit"
            component={Link}
            to={`/${language}/dashboard/tickets`}
          >
            Tickets
          </MuiLink>
          <Typography color="textPrimary">
            {router.params.id ? 'Edit ticket' : 'New Ticket'}
          </Typography>
        </Breadcrumbs>

        <Box mt={2} mr={2} pb={1}>
          {loading ? (
            <LinearProgress />
          ) : (
            <Card>
              <CardContent>
                <FormControl required fullWidth margin="normal">
                  <TextField
                    label="Subject of the ticket"
                    variant="standard"
                    value={subject}
                    onChange={(event: any) =>
                      this.handleChange('subject', event)
                    }
                  />
                </FormControl>
                <FormControl required fullWidth margin="normal">
                  <TextField
                    label="Description of the ticket subject"
                    variant="standard"
                    value={description}
                    multiline
                    rows={8}
                    onChange={(event: any) =>
                      this.handleChange('description', event)
                    }
                  />
                </FormControl>
                {filesLoading ? (
                  <LinearProgress />
                ) : (
                  <DropzoneArea
                    dropzoneText="Drag and drop files here"
                    initialFiles={files}
                    filesLimit={999}
                    maxFileSize={2147483648}
                    previewGridProps={{ container: { spacing: 0 } }}
                    getPreviewIcon={(file: FileObject) =>
                      isImage(file.file.name) ? (
                        <div>
                          <img
                            src={URL.createObjectURL(file.file)}
                            style={{ maxHeight: '160px', maxWidth: '100%' }}
                            alt={file.file.name}
                          />
                        </div>
                      ) : (
                        <Typography variant="body1">
                          {file.file.name}
                        </Typography>
                      )
                    }
                    onChange={this.handleFiles}
                  />
                )}
              </CardContent>
              <CardActions style={{ justifyContent: 'flex-end' }}>
                <Box mr={1}>
                  <Button onClick={this.handleCancel}>Cancel</Button>
                </Box>
                <LoadingButton
                  onClick={this.handleSubmit}
                  variant="contained"
                  color={id ? 'secondary' : 'primary'}
                  disabled={buttonDisabled}
                  loading={formLoading}
                >
                  {id ? 'Edit' : 'Create'}
                </LoadingButton>
              </CardActions>
            </Card>
          )}
        </Box>
      </>
    );
  }
}

export default withRouter(injectIntl(TicketForm));
