import Button from '@mui/material/Button';
import React, { Component } from 'react';
import { Color, Group, Path, Point, PointText, Matrix } from 'paper';
import { FormattedMessage } from 'react-intl';

import ws, { ISensorData } from '@api/websocket';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { ISimulatorConfig } from '@models/Simulation';
import { transformMetersToPixels } from '@dashboard_utils/index';
import MapImages from '../../MapImages';
import { defaultTransformationMatrix } from '../../../consts';
import { ICircleFeature, IToolEvent } from '../../../types';
import Paper from '../../Paper';
import { transformPixelsToMeters } from 'src/utils';
import LoadingButton from '@app/common/LoadingButton';
import { getAssetBySensorAssociation } from '@selectors/assets';
import Sensor from '@models/Sensor';
import Asset from '@models/Asset';

interface IProps {
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  paper: Paper;
  simulatorLayout: ISimulatorConfig;
  assets: Asset[];
  beacons: Sensor[];

  data: { path: [number, number][], orders: [number, number][] };
  loading: boolean;
  createRoutingPaths: (floorplanId: string, position: [number, number], point: [number, number][]) => void;
}
interface State {
  orders: OrderPosition[];
}

export interface OrderPosition {
  x: number;
  y: number;
}

class SimulatorRouting extends Component<IProps, State> {
  private simulatorLayoutElements?: paper.Group;
  private elements: paper.Path.Circle[] = [];
  private paths: paper.Path[] = [];
  private texts: paper.PointText[] = [];
  private position?: paper.Path.Circle;
  private livePosition?: OrderPosition; // Resed model
  private requestedPosition?: OrderPosition; // Resed model
  private mapOrders: paper.Path.Circle[] = [];

  constructor(props: IProps) {
    super(props);

    this.state = {
      orders: [],
    };

    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleRequest = this.handleRequest.bind(this);
    this.cleanUp = this.cleanUp.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }

  public componentDidMount() {
    const { paper } = this.props;
    const { tool } = paper;

    ws.on('live-data', this.handleMessage);

    this.load();

    tool.on('mouseup', this.handleMouseUp);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { simulatorLayout, data } = this.props;

    if (JSON.stringify(prevProps.simulatorLayout) !== JSON.stringify(simulatorLayout)) {
      this.reload();
    }
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      this.drawPaths();
    }
  }

  public componentWillUnmount() {
    const { paper } = this.props;
    const { tool } = paper;

    ws.removeListener('live-data', this.handleMessage);

    this.clear();

    tool.removeListener('mouseup', this.handleMouseUp);
  }

  public reload() {
    this.clear();

    this.load();
  }

  public drawPaths() {
    const { orders } = this.state;
    const { data, floorplan, mapImages } = this.props;

    this.paths.forEach((p) => p.remove());
    this.texts.forEach((t) => t.remove());
    this.mapOrders.forEach((o) => o.remove());
    this.paths = [];
    this.texts = [];
    this.mapOrders = [];

    this.elements.forEach((e) => e.remove());
    this.elements = [];

    this.mapOrders = [];
    ((data || {}).orders || []).forEach((d, index) => {
      const point = new Point(
        transformMetersToPixels(
          d,
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        )
      );
      const orderText = new PointText(point);
      orderText.content = `Pick ${index + 1}`;
      orderText.fillColor = new Color('black');
      orderText.fontSize = '14px';
      orderText.fontWeight = 'bold';
      orderText.position = point;
      orderText.scale(1, -1);
      orderText.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
      this.texts.push(orderText);

      const path = new Path.Circle(
        point,
        (floorplan.scale || 20) / 4
      ) as ICircleFeature;
      path.fillColor = new Color('#F1C40F');
      path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
      this.mapOrders.push(path);
    });
    let lastPoint = this.requestedPosition || orders[0] || {};
    let hitNextPick = false;
    ((data || {}).path || []).forEach((d) => {
      const path = new Path();
      path.strokeColor = new Color('Blue');
      path.strokeWidth = 5;
      path.opacity = hitNextPick ? 0.3 : 1;
      path.add(new Point(
        transformMetersToPixels(
          [
            lastPoint.x || 0,
            lastPoint.y || 0,
          ],
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        )
      ));
      path.add(new Point(
        transformMetersToPixels(
          d,
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        )
      ));
      path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

      if (!hitNextPick) {
        hitNextPick = d[0] == (((data || {}).orders || [])[0] || [])[0] &&
          d[1] == (((data || {}).orders || [])[0] || [])[1];
      }

      this.paths.push(path);

      lastPoint = { x: d[0], y: d[1] };
    });
    this.texts.forEach((t) => {
      t.bringToFront();
    });
  }

  public clear() {
    if (this.simulatorLayoutElements) {
      this.simulatorLayoutElements.remove();
    }

    this.simulatorLayoutElements = undefined;
  }

  public load() {
    const {
      floorplan,
      mapImages,
      paper: paperMod,
      simulatorLayout,
    } = this.props;

    paperMod.scope.activate();

    const edges: paper.Path[] = [];
    (simulatorLayout.edges || []).forEach((edge) => {
      const from = transformMetersToPixels(
        edge[0],
        floorplan.transformationMatrix || defaultTransformationMatrix,
        floorplan.scale || 1
      );
      const to = transformMetersToPixels(
        edge[1],
        floorplan.transformationMatrix || defaultTransformationMatrix,
        floorplan.scale || 1
      );
      const path = new Path.Line(new Point(from), new Point(to));
      path.strokeColor = new Color('WhiteSmoke');
      edges.push(path);
    });

    const nodes: paper.Path[] = [];
    (simulatorLayout.nodes || []).forEach((node) => {
      const position = transformMetersToPixels(
        node,
        floorplan.transformationMatrix || defaultTransformationMatrix,
        floorplan.scale || 1
      );

      const path = new Path.Circle(new Point(position), 2) as ICircleFeature;
      path.fillColor = new Color('DarkGrey');
      nodes.push(path);
    });

    const items: paper.Path[] = [];
    (simulatorLayout.items || []).forEach((item) => {
      const coordinates = (item.coordinates || []).map((coord) =>
        transformMetersToPixels(
          coord,
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        )
      );

      const path = new Path();
      path.strokeColor = new Color('DarkOliveGreen');
      path.strokeWidth = 3;
      coordinates.forEach((coord) => path.add(new Point(coord)));
      items.push(path);
    });
    const itemGroup = new Group(edges.concat(nodes).concat(items));
    itemGroup.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
    this.simulatorLayoutElements = itemGroup;
  }

  public handleMouseUp(event: IToolEvent) {
    const { mapImages, floorplan } = this.props;
    const { orders } = this.state;

    const point = new Matrix(1, 0, 0, -1, 0, mapImages.height).transform(
      new Point([event.point!.x || 0, event.point!.y || 0])
    );

    this.setState({
      orders: 
      [
        ...orders,
        new Point(transformPixelsToMeters(
          [point.x, point.y],
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        )),
      ]
    }, this.reloadOrders);
  }

  public reloadOrders() {
    const { floorplan, mapImages } = this.props;
    const { orders } = this.state;

    this.elements.forEach((e) => e.remove());
    this.elements = [];

    orders.forEach((o) => {
      const point = new Point(
        transformMetersToPixels(
          [o.x, o.y],
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        )
      );
  
      const path = new Path.Circle(
        point,
        (floorplan.scale || 20) / 4
      ) as ICircleFeature;
      path.fillColor = new Color('#F1C40F');
      path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

      this.elements.push(path);
    });

    this.paths.forEach((p) => p.remove());
    this.texts.forEach((t) => t.remove());
    this.mapOrders.forEach((o) => o.remove());
    this.paths = [];
    this.texts = [];
    this.mapOrders = [];

    window.dispatchEvent(new CustomEvent('routing_orders', { detail: orders }));
  }

  public handleRequest() {
    const { createRoutingPaths, floorplan } = this.props;
    const { orders } = this.state;

    const p = this.livePosition || orders[0];
    this.requestedPosition = this.livePosition || undefined;

    createRoutingPaths(floorplan.id, [p.x, p.y], orders.slice(this.livePosition ? 0 : 1).map((p) => [p.x, p.y]));
  }

  public cleanUp() {
    this.setState({ orders: [] }, this.reloadOrders);
  }

  private handleMessage(sensorsData: ISensorData[]) {
    const { assets, beacons, floorplan, createRoutingPaths, paper, simulatorLayout, mapImages } = this.props;
    const { myAssetId } = simulatorLayout;

    const { view } = paper.scope;

    if (!view) {
      return false;
    }

    paper.scope.activate();

    sensorsData
      .filter((data) => {
        const sensor = beacons.find((b: any) => b.id === data.id);
        const asset = sensor
          ? getAssetBySensorAssociation(assets, sensor.physicalAddress, data.ts)
          : undefined;

        return (asset || {}).id === myAssetId;
      })
      .map((data) => {
        if (this.position) {
          this.position.remove();
        }
        if (this.livePosition) {
          this.livePosition = undefined;
        }

        const coordinate: [number, number] = [data.x, data.y];
        const mapCoordinate = new Point(
          transformMetersToPixels(
            coordinate,
            floorplan.transformationMatrix || defaultTransformationMatrix,
            floorplan.scale || 1
          )
        );

        const path = new Path.Circle(
          mapCoordinate,
          (floorplan.scale || 20)
        ) as ICircleFeature;
        path.fillColor = new Color('#FF0000');
        path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
        this.livePosition = { x: coordinate[0], y: coordinate[1] };
        this.position = path;

        if (this.livePosition) {
          const filteredOrders = this.mapOrders;
          this.mapOrders = [];
  
          filteredOrders.forEach((o) => {
            o.remove();

            if (mapCoordinate.getDistance(path.getNearestPoint(o.position)) > (floorplan.scale || 0) * 2) {
              const path = new Path.Circle(
                o.position,
                (floorplan.scale || 20) / 4
              ) as ICircleFeature;
              path.fillColor = new Color('#F1C40F');
              this.mapOrders.push(path);
            }
          });

          if (filteredOrders.length !== this.mapOrders.length) {
            createRoutingPaths(
              floorplan.id,
              [this.livePosition.x, this.livePosition.y],
              this.mapOrders.map((p) => {
                const point = new Matrix(1, 0, 0, -1, 0, mapImages.height).transform(
                  new Point([p.position.x || 0, p.position.y || 0])
                );

                return transformPixelsToMeters(
                  [point.x, point.y],
                  floorplan.transformationMatrix || defaultTransformationMatrix,
                  floorplan.scale || 1
                );
              })
            );
          }
        }
      });
  }

  public render() {
    const { loading } = this.props;
    const { orders } = this.state;

    return orders.length > 1 ? (
      <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
        <LoadingButton
          onClick={this.handleRequest}
          variant="contained"
          color="primary"
          loading={loading}
        >
          <FormattedMessage
            id="dashboard.forms.routing.request_btn"
            defaultMessage="Request Route"
          />
        </LoadingButton>
        <Button
          onClick={this.cleanUp}
          variant="contained"
          color="primary"
          style={{ marginLeft: '8px' }}
        >
          <FormattedMessage
            id="dashboard.forms.routing.clean_btn"
            defaultMessage="Clean Orders"
          />
        </Button>
      </div>
    ) : null;
  }
}

export default SimulatorRouting;
