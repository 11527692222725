import React, { Component } from 'react';
import {
  defineMessages,
  injectIntl,
  IntlShape,
} from 'react-intl';

import Map from '@app/common/FullMap/Map';
import { ItemLocation } from '@models/IncompleteFloorplan';

const messages = defineMessages({
  floorplan: {
    defaultMessage: 'Floor plan',
    id: 'dashboard.datamap.floorplan',
  },
  sensors: {
    defaultMessage: 'Sensors',
    id: 'dashboard.livemap.layers.sensors',
  },
  racks: {
    defaultMessage: 'Racks',
    id: 'dashboard.livemap.layers.racks',
  },
});

interface IProps {
  mappingChange?: (itemLocations: ItemLocation[]) => void;
  floorplanId: string;
  itemLocations?: ItemLocation[];
  intl: IntlShape;
}

interface IState {
  currentPosition: [number, number] | null;
  itemLocations: ItemLocation[];
}

class ItemLocationsForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { itemLocations } = props;

    this.state = {
      currentPosition: null,
      itemLocations: itemLocations || [],
    };

    this.handleMappingClose = this.handleMappingClose.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  public handleMappingClose() {
    this.setState({ currentPosition: null });
  }

  public onClick(position: [number, number], action: 'add' | 'remove') {
    const { itemLocations } = this.state;

    if (action === 'add') {
      itemLocations.push({
        id: (itemLocations.length + 1).toString(),
        reference: (itemLocations.length + 1).toString(),
        position,
      });
    } else if (action === 'remove') {
      const index = itemLocations.findIndex((i) => i.position[0] === position[0] && i.position[1] === position[1]);
      itemLocations.splice(index, 1);
    }

    this.setState({ itemLocations }, () => {
      const { mappingChange } = this.props;
      if (mappingChange) {
        mappingChange(itemLocations);
      }
    });
  }

  public render() {
    const { floorplanId, intl } = this.props;
    const { itemLocations } = this.state;

    return (
      <div
        style={{
          bottom: 0,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Map
            floorplanId={floorplanId}
            disableColor
            layers={{
              floorplan: {
                id: 'floorplan',
                active: true,
                label: intl.formatMessage(messages.floorplan),
                disabled: true,
                primary: true,
                secondary: false,
              },
              sensors: {
                id: 'sensors',
                active: true,
                label: intl.formatMessage(messages.sensors),
                disabled: true,
                primary: true,
                secondary: false,
              },
              racks: {
                id: 'racks',
                active: true,
                label: intl.formatMessage(messages.racks),
                disabled: true,
                primary: false,
                secondary: true,
              },
            }}
            actions={{
              itemLocations: {
                locations: JSON.parse(
                  JSON.stringify(itemLocations || [])
                ),
                onLocationClick: this.onClick,
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(ItemLocationsForm);
