import { Component } from 'react';
import { Color, Path, Point, PointText, Matrix } from 'paper';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { transformMetersToPixels } from '@dashboard_utils/index';
import Paper from '../../Paper';
import MapImages from '../../MapImages';
import mapEvents, { orderLayers } from '../../eventEmitter';
import { ICircleFeature, MapFeature } from '../../../types';
import { defaultTransformationMatrix } from '../../../consts';

interface IElement {
  path: ICircleFeature;
  sensorText: paper.PointText;
}

interface IProps {
  floorplan: IncompleteFloorplan;
  id: string;
  mapImages: MapImages;
  paper: Paper;
  updateSelectedFeature: (id: string, feature: MapFeature) => void;
}

class ItemLocationMapLayer extends Component<IProps> {
  private elements: IElement[] = [];

  constructor(props: IProps) {
    super(props);

    this.reload = this.reload.bind(this);
    this.sendToBack = this.sendToBack.bind(this);
  }

  public componentDidMount() {
    mapEvents.on('resized', this.reload);
    mapEvents.on('order_layers', this.sendToBack);

    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { floorplan } = this.props;

    if (JSON.stringify(prevProps.floorplan) !== JSON.stringify(floorplan)) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    mapEvents.removeListener('resized', this.reload);
    mapEvents.removeListener('order_layers', this.sendToBack);

    this.clear();
  }

  public load() {
    const { floorplan, mapImages } = this.props;

    (floorplan.itemLocations || []).forEach((location, index) => {
      const point = new Point(
        transformMetersToPixels(
          location.position,
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        )
      );

      const path = new Path.Circle(
        point,
        (floorplan.scale || 20) / 4
      ) as ICircleFeature;
      path.fillColor = new Color('#00A6EB');
      path.featureInfo = {
        id: `item_${String(index)}`,
        props: {
          id: location.id,
          reference: location.reference,
          position: location.position,
        },
        title: `Item Location #${location.reference}`,
        type: 'itemLocation',
      };
      path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

      const sensorText = new PointText(
        new Point([point.x - 10, point.y + 10]).transform(
          new Matrix(1, 0, 0, -1, 0, mapImages.height)
        )
      );

      sensorText.content = location.reference;
      sensorText.fillColor = new Color('#00A6EB');
      sensorText.fontSize = '16px';
      sensorText.fontWeight = 'bold';

      this.elements.push({ path, sensorText });
    });

    orderLayers();
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((element) => {
      element.path.remove();
      element.sensorText.remove();
    });

    this.elements = [];
  }

  public sendToBack(layerType: string) {
    if (layerType === 'other_layers' && this.elements.length) {
      this.elements.forEach((e) => {
        e.path.sendToBack();
        e.sensorText.sendToBack();
      });
    }
  }

  public render() {
    return null;
  }
}

export default ItemLocationMapLayer;
