import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ClearIcon from '@mui/icons-material/Clear';
import React, { PureComponent } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { IUpdateAccountSettings } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import AvatarComponent from '@app/utils/Avatar';
import Account from '@models/Account';
import { getAccountMeta } from '@selectors/accounts';
import {
  getMeasurementUnitsFromLocale,
  getNavigatorLanguage,
} from '../../../../utils/accountUtils';

const messages = defineMessages({
  altText: {
    defaultMessage: 'User image',
    id: 'dashboard.account.settings.accountbasics.img_alt',
  },
  measurementUnitsImperial: {
    defaultMessage: 'Imperial Units',
    id: 'dashboard.account.settings.accountbasics.field_imperial',
  },
  measurementUnitsSI: {
    defaultMessage: 'International System',
    id: 'dashboard.account.settings.accountbasics.field_si',
  },
});

interface IProps {
  account: Account;
  changed: boolean;
  intl: IntlShape;
  loading: boolean;
  updateAccountSettings: (properties: IUpdateAccountSettings) => void;
}

interface IState {
  changed: boolean;
  enableNotifications: boolean;
  measurementUnits: string;
  locale: string;
  image?: string;
  file?: File;
}

class Basic extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { account, changed } = props;

    const accountMeta = getAccountMeta(account);

    this.state = {
      changed,
      enableNotifications: accountMeta.enableNotifications,
      locale: accountMeta.locale || getNavigatorLanguage(),
      measurementUnits:
        accountMeta.measurementUnits || getMeasurementUnitsFromLocale(),
      image: account.image,
    };

    this.handleImageRemoval = this.handleImageRemoval.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { account, changed } = this.props;

    if (prevProps.changed !== changed) {
      this.setChanges();
    }
    if (prevProps.account.image !== account.image) {
      this.setImageChange();
    }
  }

  public handleImageRemoval() {
    this.setState({ image: undefined, changed: true });
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const value: string | boolean =
        e.target.type === 'checkbox' ? e.target.checked : e.target.value;

      this.setState(
        (prevState) =>
          ({
            [key]: value,
            changed: value !== prevState[key],
          } as Pick<IState, 'changed'>)
      );
    };
  }

  public handleFileUpload(event: any) {
    const { files } = event.target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (e.target !== null) {
          this.setState({ image: e.target.result, changed: true });
        }
      };

      reader.readAsDataURL(files[0]);

      this.setState({ file: files[0] });
    }
  }

  public handleSubmit() {
    const { enableNotifications, file, image, locale, measurementUnits } =
      this.state;
    const { intl, updateAccountSettings } = this.props;

    updateAccountSettings({
      intl,
      properties: {
        file,
        image,
        enableNotifications,
        locale,
        measurementUnits,
      },
    });
  }

  public handleReset() {
    const { account } = this.props;

    const accountMeta = getAccountMeta(account);

    this.setState({
      changed: false,
      locale: accountMeta.locale || getNavigatorLanguage(),
      measurementUnits:
        accountMeta.measurementUnits || getMeasurementUnitsFromLocale(),
      image: account.image,
      file: undefined,
    });
  }

  public setChanges() {
    const { changed } = this.props;

    this.setState({ changed });
  }

  public setImageChange() {
    const { account } = this.props;

    this.setState({ image: account.image });
  }

  public render() {
    const { account, intl, loading } = this.props;
    const { changed, enableNotifications, image, locale, measurementUnits } =
      this.state;

    const handleFileUploadClick = (event: any) => {
      event.stopPropagation();

      document.getElementById('form-image')!.click();
    };

    return (
      <Card>
        <CardHeader
          avatar={<AvatarComponent account={account} />}
          title={account.name}
          subheader={
            <FormattedMessage
              id="dashboard.account.settings.menu.account_basics"
              defaultMessage="Account Basics"
            />
          }
        />
        <CardContent>
          <Grid container>
            <Grid item sm={3}>
              <div style={{ position: 'relative' }}>
                {image ? (
                  <ClearIcon
                    color="error"
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '50px',
                      top: '10px',
                    }}
                    onClick={this.handleImageRemoval}
                  />
                ) : null}
                <div
                  style={{
                    position: 'absolute',
                    zIndex: 1,
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <label htmlFor="form-image">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleFileUploadClick}
                    >
                      <FormattedMessage
                        id="dashboard.account.settings.accountbasics.upload_button"
                        defaultMessage="Upload image"
                      />
                    </Button>
                    <input
                      id="form-image"
                      accept="image/*"
                      type="file"
                      style={{ display: 'none' }}
                      onInput={this.handleFileUpload}
                    />
                  </label>
                </div>

                <Avatar
                  alt={intl.formatMessage(messages.altText)}
                  src={image}
                  style={{ width: 200, height: 200, margin: 'auto' }}
                />
              </div>
            </Grid>
            <Grid item sm={9}>
              <FormControl
                required
                fullWidth
                variant="standard"
                margin="normal"
              >
                <InputLabel htmlFor="form-locale">
                  <FormattedMessage
                    id="dashboard.account.settings.accountbasics.label_locale"
                    defaultMessage="Locale"
                  />
                </InputLabel>
                <Select
                  id="form-locale"
                  value={locale}
                  onChange={this.handleChange('locale')}
                  input={<Input name="locale" id="form-locale" />}
                >
                  <MenuItem value="de-DE">Deutsche (DE)</MenuItem>
                  <MenuItem value="en-GB">English (GB)</MenuItem>
                  <MenuItem value="es-ES">Español (ES)</MenuItem>
                  <MenuItem value="fr-FR">Français (FR)</MenuItem>
                  <MenuItem value="it-IT">Italiano (IT)</MenuItem>
                  <MenuItem value="pt-PT">Português (PT)</MenuItem>
                  <MenuItem value="ro-RO">Română (RO)</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                required
                fullWidth
                variant="standard"
                margin="normal"
              >
                <InputLabel htmlFor="form-measurement_units">
                  <FormattedMessage
                    id="dashboard.account.settings.accountbasics.label_measurementunits"
                    defaultMessage="Measurement unit system"
                  />
                </InputLabel>
                <Select
                  id="form-measurement_units"
                  value={measurementUnits}
                  onChange={this.handleChange('measurementUnits')}
                  input={
                    <Input
                      name="measurement_units"
                      id="form-measurement_units"
                    />
                  }
                >
                  <MenuItem value="si">
                    {intl.formatMessage(messages.measurementUnitsSI)}
                  </MenuItem>
                  <MenuItem value="imperial">
                    {intl.formatMessage(messages.measurementUnitsImperial)}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl required fullWidth margin="normal">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enableNotifications}
                      name="enableNotifications"
                      onChange={this.handleChange('enableNotifications')}
                      value
                    />
                  }
                  label={
                    <FormattedMessage
                      id="dashboard.account.settings.accountbasics.label_enablenotifications"
                      defaultMessage="Allow notifications"
                    />
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          {changed ? (
            <>
              <Box mr={2}>
                <Button onClick={this.handleReset}>
                  <FormattedMessage
                    id="dashboard.account.settings.accountbasics.button_cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </Box>
              <LoadingButton
                onClick={this.handleSubmit}
                variant="contained"
                color="secondary"
                disabled={loading}
                loading={loading}
              >
                <FormattedMessage
                  id="dashboard.account.settings.accountbasics.button_submit"
                  defaultMessage="Update"
                />
              </LoadingButton>
            </>
          ) : null}
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(Basic);
