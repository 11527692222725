import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import PasswordStrength from '@app/common/PasswordStrength';

interface IState {
  password: string;
  repassword: string;
}

interface IProps {
  doPasswordsMatch: boolean;
  password: string;
  repassword: string;
  handleChange: (key: keyof IState) => any;
}

const PasswordFields: FunctionComponent<IProps> = ({
  doPasswordsMatch,
  password,
  repassword,
  handleChange,
}: IProps) => (
  <>
    <FormControl required fullWidth margin="normal">
      <TextField
        variant="standard"
        label={
          <FormattedMessage
            id="dashboard.forms.accountform.label_password"
            defaultMessage="Password"
          />
        }
        value={password}
        type="password"
        autoComplete="current-password"
        onChange={handleChange('password')}
        helperText={
          password.length > 0 && password.length < 8 ? (
            <FormHelperText error>
              <FormattedMessage
                id="dashboard.forms.accountform.field_password_length_error"
                defaultMessage="Password must have at least 8 characteres"
              />
            </FormHelperText>
          ) : (
            <FormattedMessage
              id="dashboard.forms.accountform.field_password_helper"
              defaultMessage="A password must be specified"
            />
          )
        }
      />
      <PasswordStrength password={password} />
    </FormControl>
    <FormControl required fullWidth margin="normal">
      <TextField
        variant="standard"
        label={
          <FormattedMessage
            id="dashboard.forms.accountform.label_repassword"
            defaultMessage="Confirm password"
          />
        }
        value={repassword}
        type="password"
        onChange={handleChange('repassword')}
        error={doPasswordsMatch === false}
        helperText={
          doPasswordsMatch === false ? (
            <FormHelperText error>
              <FormattedMessage
                id="dashboard.forms.accountform.field_repassword_eql_error"
                defaultMessage={"Passwords don't match"}
              />
            </FormHelperText>
          ) : (
            <FormattedMessage
              id="dashboard.forms.accountform.field_repassword_helper"
              defaultMessage="Must confirm the password"
            />
          )
        }
      />
    </FormControl>
  </>
);

export default PasswordFields;
