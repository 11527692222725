import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import Warehouse, { Warehouses } from '@models/Warehouse';

export function getWarehouseIdByFloorplanId(
  floorplans: IncompleteFloorplans,
  floorplanId: string
): string | undefined {
  return (floorplans[floorplanId] || {}).warehouseId;
}

export function findWarehouseByFloorplanId(
  warehouses: Warehouses,
  floorplans: IncompleteFloorplans,
  floorplanId: string
): Warehouse | undefined {
  return warehouses[getWarehouseIdByFloorplanId(floorplans, floorplanId) || ''];
}
