import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ArchiveIcon from '@mui/icons-material/Archive';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import { format } from 'date-fns';
import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { ArchiveAssetTicket, ChangeAssetTicketStatus } from '@actions/index';
import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '@app/dialogs/ConfirmDialog/types';
import AssetTicket, { AssetTicketStatus } from '@models/AssetTicket';
import CompleteAsset from '@models/CompleteAsset';
import AssignmentForm from '../../forms/AssignmentForm';

export const messages = defineMessages({
  more: {
    defaultMessage: 'More',
    id: 'dashboard.assets.management.tickets.menu_aria_label',
  },
  thType: {
    defaultMessage: 'Type',
    id: 'dashboard.assets.management.tickets.table.type',
  },
  thRequester: {
    defaultMessage: 'Requester',
    id: 'dashboard.assets.management.tickets.table.requester',
  },
  thSubject: {
    defaultMessage: 'Subject',
    id: 'dashboard.assets.management.tickets.table.subject',
  },
  thRequestDate: {
    defaultMessage: 'Request Date',
    id: 'dashboard.assets.management.tickets.table.requestdate',
  },
  thAssignee: {
    defaultMessage: 'Assignee',
    id: 'dashboard.assets.management.tickets.table.assignee',
  },
  thStatus: {
    defaultMessage: 'Status',
    id: 'dashboard.assets.management.tickets.table.status',
  },
  thOptions: {
    defaultMessage: 'Options',
    id: 'dashboard.assets.management.tickets.table.options',
  },
  archiveConfirmation: {
    defaultMessage: 'Are you sure that you want to archive this ticket?',
    id: 'dashboard.assets.management.tickets.archive_confirm_message',
  },
});

export const customRowRender = (
  archive: boolean,
  columns: boolean[],
  rowData: any,
  dataIndex: number,
  rowIndex: number,
  intl: IntlShape,
  tickets: AssetTicket[],
  itemMenuInfo: Record<string, any>,
  handleItemMenuClick: (index: number) => (event: any) => void,
  handleItemMenuClose: (index: number) => void,
  goToTicket: (index: number, ticketId: string) => void,
  assignIssue?: (index: number, ticketId: string) => void,
  doneIssue?: (index: number, ticketId: string) => void,
  archiveIssue?: (index: number, ticketId: string) => void
) => {
  let type;
  switch (rowData[0]) {
    case 'breakdown':
      type = (
        <FormattedMessage
          id="dashboard.assets.management.ticketform.label_type_breakdown"
          defaultMessage="Breakdown"
        />
      );
      break;
    case 'maintenance_needed':
      type = (
        <FormattedMessage
          id="dashboard.assets.management.ticketform.label_type_maintenance_needed"
          defaultMessage="Maintenance Needed"
        />
      );
      break;
    default:
      type = '';
      break;
  }

  let status;
  switch (rowData[5]) {
    case AssetTicketStatus.PENDING:
      status = (
        <Chip
          color="secondary"
          label={
            <FormattedMessage
              id="dashboard.assets.management.tickets.status_pending"
              defaultMessage="Pending"
            />
          }
        />
      );
      break;

    case AssetTicketStatus.ASSIGNED:
      status = (
        <Chip
          color="primary"
          label={
            <FormattedMessage
              id="dashboard.assets.management.tickets.status_assigned"
              defaultMessage="Assigned"
            />
          }
        />
      );
      break;

    case AssetTicketStatus.DONE:
      status = (
        <Chip
          style={{ backgroundColor: '#008000', color: '#ffffff' }}
          label={
            <FormattedMessage
              id="dashboard.assets.management.tickets.status_done"
              defaultMessage="Resolved"
            />
          }
        />
      );
      break;

    default:
      status = '';
      break;
  }

  return (
    <TableRow key={rowIndex}>
      {columns[0] && <TableCell>{type}</TableCell>}
      {columns[1] && (
        <TableCell>
          <Avatar
            alt={rowData[1]}
            src={(tickets[dataIndex] || {}).accountImage}
            style={{ float: 'left' }}
          />
          <b style={{ float: 'left', marginLeft: '5px' }}>{rowData[1]}</b>
        </TableCell>
      )}
      {columns[2] && <TableCell>{rowData[2]}</TableCell>}
      {columns[3] && (
        <TableCell>{format(new Date(rowData[3]), 'yyyy-MM-dd')}</TableCell>
      )}
      {columns[4] && (
        <TableCell>
          {rowData[4] && (
            <>
              <Avatar
                alt={rowData[4]}
                src={(tickets[dataIndex] || {}).assigneeAccountImage}
                style={{ float: 'left' }}
              />
              <b style={{ float: 'left', marginLeft: '5px' }}>{rowData[4]}</b>
            </>
          )}
        </TableCell>
      )}
      {columns[5] && <TableCell>{status}</TableCell>}
      {columns[6] && (
        <TableCell style={{ textAlign: 'right' }}>
          <IconButton
            aria-label={intl.formatMessage(messages.more)}
            aria-owns={itemMenuInfo[dataIndex] ? 'long-menu' : undefined}
            aria-haspopup="true"
            onClick={handleItemMenuClick(dataIndex)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={itemMenuInfo[dataIndex]}
            open={!!itemMenuInfo[dataIndex]}
            onClose={() => handleItemMenuClose(dataIndex)}
          >
            <MenuItem onClick={() => goToTicket(dataIndex, rowData[6])}>
              <ListItemIcon>
                <ViewQuiltIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="dashboard.assets.management.tickets.view_ticket"
                    defaultMessage="View Ticket Details"
                  />
                }
              />
            </MenuItem>
            {!archive && rowData[5] !== AssetTicketStatus.DONE ? (
              <MenuItem
                onClick={() =>
                  assignIssue ? assignIssue(dataIndex, rowData[6]) : null
                }
              >
                <ListItemIcon>
                  <AssignmentIndIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="dashboard.assets.management.tickets.assign_issue"
                      defaultMessage="Assign Issue"
                    />
                  }
                />
              </MenuItem>
            ) : null}
            {!archive && rowData[5] !== AssetTicketStatus.DONE ? (
              <MenuItem
                onClick={() =>
                  doneIssue ? doneIssue(dataIndex, rowData[6]) : null
                }
              >
                <ListItemIcon>
                  <CheckBoxIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="dashboard.assets.management.tickets.done_issue"
                      defaultMessage="Mark as Resolved"
                    />
                  }
                />
              </MenuItem>
            ) : null}
            {!archive && rowData[5] === AssetTicketStatus.DONE ? (
              <MenuItem
                onClick={() =>
                  archiveIssue ? archiveIssue(dataIndex, rowData[6]) : null
                }
              >
                <ListItemIcon>
                  <ArchiveIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="dashboard.assets.management.tickets.archive_issue"
                      defaultMessage="Archive"
                    />
                  }
                />
              </MenuItem>
            ) : null}
          </Menu>
        </TableCell>
      )}
    </TableRow>
  );
};

interface IProps {
  asset: CompleteAsset;
  fetchAssetTickets: (assetId: string) => void;
  changeAssetTicketStatus: (properties: ChangeAssetTicketStatus) => void;
  archiveAssetTicket: (properties: ArchiveAssetTicket) => void;
  confirm: (props: IConfirmDialog) => void;
  intl: IntlShape;
  tickets: AssetTicket[];
  loading: boolean;
  language: string;
  router: any;
}
interface IState {
  assignForm?: string;
  columns: ITableColumn[];
  itemMenuInfo: Record<string, any>;
}

class Tickets extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      columns: [
        { label: intl.formatMessage(messages.thType), name: 'type' },
        {
          label: intl.formatMessage(messages.thRequester),
          name: 'accountName',
        },
        { label: intl.formatMessage(messages.thSubject), name: 'subject' },
        { label: intl.formatMessage(messages.thRequestDate), name: 'created' },
        {
          label: intl.formatMessage(messages.thAssignee),
          name: 'assigneeAccountName',
        },
        { label: intl.formatMessage(messages.thStatus), name: 'status' },
        {
          label: intl.formatMessage(messages.thOptions),
          name: 'id',
          options: {
            customHeadRender: (columnMeta) => (
              <TableCell key={columnMeta.index} style={{ textAlign: 'right' }}>
                {columnMeta.label}
              </TableCell>
            ),
            interaction: false,
          },
        },
      ],
      itemMenuInfo: {},
    };

    this.handleAssignmentFormClose = this.handleAssignmentFormClose.bind(this);
    this.handleItemMenuClick = this.handleItemMenuClick.bind(this);
    this.handleItemMenuClose = this.handleItemMenuClose.bind(this);
    this.goToTicket = this.goToTicket.bind(this);
    this.assignIssue = this.assignIssue.bind(this);
    this.doneIssue = this.doneIssue.bind(this);
    this.archiveIssue = this.archiveIssue.bind(this);
  }

  public componentDidMount() {
    const { asset, fetchAssetTickets } = this.props;

    fetchAssetTickets(asset.id);
  }

  public handleItemMenuClick(index: number) {
    return (event: any) => {
      const { itemMenuInfo } = this.state;

      itemMenuInfo[index] = event.target;

      this.setState({ itemMenuInfo });
    };
  }

  public handleItemMenuClose(index: number) {
    const { itemMenuInfo } = this.state;

    itemMenuInfo[index] = undefined;

    this.setState({ itemMenuInfo });
  }

  public handleAssignmentFormClose() {
    this.setState({ assignForm: undefined });
  }

  public assignIssue(index: number, ticketId: string) {
    const { itemMenuInfo } = this.state;

    itemMenuInfo[index] = undefined;

    this.setState({ assignForm: ticketId, itemMenuInfo });
  }

  public doneIssue(index: number, ticketId: string) {
    const { itemMenuInfo } = this.state;
    const { changeAssetTicketStatus } = this.props;

    itemMenuInfo[index] = undefined;

    this.setState({ itemMenuInfo }, () => {
      changeAssetTicketStatus({
        ticketId,
        status: AssetTicketStatus.DONE,
      });
    });
  }

  public archiveIssue(index: number, ticketId: string) {
    const { itemMenuInfo } = this.state;
    const { archiveAssetTicket, confirm, intl } = this.props;

    itemMenuInfo[index] = undefined;

    this.setState({ itemMenuInfo }, () => {
      confirm({
        confirmType: ConfirmDialogActions.ACTION,
        message: intl.formatMessage(messages.archiveConfirmation),
        onConfirmation: () => archiveAssetTicket({ ticketId }),
      });
    });
  }

  public goToTicket(index: number, ticketId: string) {
    const { asset, router, language } = this.props;
    const { itemMenuInfo } = this.state;

    itemMenuInfo[index] = undefined;

    this.setState({ assignForm: ticketId, itemMenuInfo }, () => {
      router.navigate(
        `/${language}/dashboard/assets/assetid/${asset.id}/management/tab/0/ticketid/${ticketId}`
      );
    });
  }

  public render() {
    const { loading, intl, tickets } = this.props;
    const { assignForm, columns, itemMenuInfo } = this.state;

    return (
      <>
        <Loading loading={loading}>
          <MUITable
            columns={columns}
            customRowRender={(c: boolean[], r: any, d: number, rw: number) =>
              customRowRender(
                false,
                c,
                r,
                d,
                rw,
                intl,
                tickets,
                itemMenuInfo,
                this.handleItemMenuClick,
                this.handleItemMenuClose,
                this.goToTicket,
                this.assignIssue,
                this.doneIssue,
                this.archiveIssue
              )
            }
            data={tickets}
            tableId="asset-tickets"
          />
        </Loading>
        {assignForm ? (
          <AssignmentForm
            ticketId={assignForm}
            close={this.handleAssignmentFormClose}
          />
        ) : null}
      </>
    );
  }
}

export default injectIntl(Tickets);
