import { EventEmitter } from 'events';

export interface ILoadingEvent {
  loadingId: string;
  type: 'start' | 'end';
}

const mapEvents = new EventEmitter();
mapEvents.setMaxListeners(99999);

export const orderLayers = () => {
  mapEvents.emit('order_layers', 'other_layers');
  mapEvents.emit('order_layers', 'obstacles');
  mapEvents.emit('order_layers', 'boundaries');
  mapEvents.emit('order_layers', 'exteriorboundaries');
  mapEvents.emit('order_layers', 'landmarks');
  mapEvents.emit('order_layers', 'heatmap');
  mapEvents.emit('order_layers', 'floorplan');
  mapEvents.emit('order_layers', 'background');
};

export default mapEvents;
