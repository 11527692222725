import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import Pagination from '@mui/material/Pagination';
import React, { Component } from 'react';
import { IntlShape } from 'react-intl';

import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '@app/dialogs/ConfirmDialog/types';
import { PRIMARY_COLOR } from '../utils/colors';

export interface IAnchorEL {
  menu: HTMLElement | undefined;
  expanded: boolean;
}

interface IItemProps {
  data: any;
  extendGrid: boolean;
}
export interface IHeaderActions {
  handleAdd: () => void;
}
export interface ICustomRow {
  anchorEl: Record<number, IAnchorEL>;
  columns: boolean[];
  data: any[];
  handleDelete: (id: string, index: number) => void;
  handleEdit: (data: any, index: number) => void;
  handleExpandableRow: (index: number) => void;
  handleMenu: (index: number, event: any) => void;
  handleMenuClose: (index: number) => void;
  rowData: any;
  dataIndex: number;
  rowIndex: number;
}

interface IProps {
  Actions: React.FunctionComponent<IHeaderActions>;
  confirm: (props: IConfirmDialog) => void;
  columns: ITableColumn[];
  CustomRow: React.FunctionComponent<ICustomRow>;
  data: any[];
  deleteAction: (id: string, intl: IntlShape) => void;
  deleteMsg: string;
  extendGrid: boolean;
  fetchData?: (shallow?: boolean) => void;
  intl: IntlShape;
  Item: React.ComponentClass<IItemProps>;
  openForm: (data?: any) => void;
  tableId: string;
  loading: boolean;
}

interface IState {
  anchorEl: Record<number, IAnchorEL>;
  listType: string;
  numberOfCards: number;
  page: number;
}

class CardListPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: {},
      listType: 'card',
      numberOfCards: 12,
      page: 1,
    };

    this.customRowRender = this.customRowRender.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleExpandableRow = this.handleExpandableRow.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCardNumberChange = this.handleCardNumberChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  /**
   * Open item form by redux action
   */
  public handleAdd() {
    const { openForm } = this.props;

    openForm();
  }

  public handleMenu(index: number, event: any) {
    const { anchorEl } = this.state;

    this.setState({
      anchorEl: {
        ...anchorEl,
        [index]: {
          ...anchorEl[index],
          menu: event.currentTarget,
        },
      },
    });
  }

  public handleMenuClose(index: number) {
    const { anchorEl } = this.state;
    anchorEl[index].menu = undefined;
    this.setState({ anchorEl });
  }

  public handleEdit(data: any, index: number) {
    const { openForm } = this.props;
    const { anchorEl } = this.state;

    openForm(data);
    anchorEl[index].menu = undefined;

    this.setState({ anchorEl });
  }

  public handleDelete(id: string, index: number) {
    const { confirm, deleteAction, deleteMsg, intl } = this.props;
    const { anchorEl } = this.state;

    anchorEl[index].menu = undefined;

    this.setState({ anchorEl }, () => {
      confirm({
        confirmType: ConfirmDialogActions.DELETE,
        message: deleteMsg,
        onConfirmation: () => {
          deleteAction(id, intl);
        },
      });
    });
  }

  /**
   * @description Expands clicked table row
   * @param       {number} index Row index
   * @public
   */
  public handleExpandableRow(index: number) {
    const { anchorEl } = this.state;

    this.setState(
      {
        anchorEl: {
          ...anchorEl,
          [index]: {
            ...anchorEl[index],
            expanded: !(anchorEl[index] || {}).expanded,
            menu: undefined,
          },
        },
      },
      () => setTimeout(() => window.dispatchEvent(new Event('resize')), 300)
    );
  }

  public handleCardNumberChange(event: any) {
    this.setState({ numberOfCards: event.target.value, page: 1 });
  }

  public handlePageChange(event: any, page: number) {
    this.setState({ page });
  }

  public changeListType(listType: string) {
    this.setState({ listType });
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const { fetchData } = this.props;

    if (fetchData) {
      fetchData(shallow);
    }
  }

  public customRowRender(
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    const { CustomRow, data } = this.props;
    const { anchorEl } = this.state;

    return (
      <CustomRow
        key={rowIndex}
        anchorEl={anchorEl}
        columns={columns}
        data={data}
        handleDelete={this.handleDelete}
        handleExpandableRow={this.handleExpandableRow}
        handleMenu={this.handleMenu}
        handleMenuClose={this.handleMenuClose}
        handleEdit={this.handleEdit}
        rowData={rowData}
        dataIndex={dataIndex}
        rowIndex={rowIndex}
      />
    );
  }

  public render() {
    const { columns, Actions, data, extendGrid, Item, loading, tableId } =
      this.props;
    const { listType, page, numberOfCards } = this.state;

    return (
      <Loading loading={loading}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Actions handleAdd={this.handleAdd} />
          {listType !== 'list' ? (
            <IconButton
              id="listtype-toggle-list"
              onClick={() => this.changeListType('list')}
              style={{ color: PRIMARY_COLOR }}
            >
              <ViewListIcon />
            </IconButton>
          ) : (
            <IconButton
              id="listtype-toggle-card"
              onClick={() => this.changeListType('card')}
              style={{ color: PRIMARY_COLOR }}
            >
              <ViewModuleIcon />
            </IconButton>
          )}
        </div>

        <div className="table-container">
          {listType === 'card' ? (
            <Grid
              container
              spacing={2}
              style={{ overflow: 'hidden', width: '100%', marginBottom: '8px' }}
            >
              {data
                .slice(
                  page * numberOfCards - numberOfCards,
                  page * numberOfCards
                )
                .map((a) => (
                  <Item key={a.id} data={a} extendGrid={extendGrid} />
                ))}
              <Grid item xs={12}>
                <CardActions
                  style={{
                    paddingTop: '30px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Pagination
                    count={Math.ceil(data.length / numberOfCards)}
                    hidePrevButton={page === 0}
                    hideNextButton={page * numberOfCards >= data.length}
                    onChange={this.handlePageChange}
                    page={page}
                    shape="circular"
                  />
                  <Select
                    value={numberOfCards}
                    onChange={this.handleCardNumberChange}
                    variant="standard"
                  >
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={18}>18</MenuItem>
                    <MenuItem value={24}>24</MenuItem>
                    <MenuItem value={32}>32</MenuItem>
                  </Select>
                </CardActions>
              </Grid>
            </Grid>
          ) : (
            <Card style={{ flex: 1 }}>
              <CardContent>
                <MUITable
                  columns={columns}
                  data={data}
                  customRowRender={this.customRowRender}
                  tableId={tableId}
                />
              </CardContent>
            </Card>
          )}
        </div>
      </Loading>
    );
  }
}

export default CardListPage;
