import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import React, { Component } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';

import FetchError from '@models/FetchError';

interface IProps {
  intl: IntlShape;
  language: string;
  loading: boolean;
  passwordRecovery: (email: string) => void;
  error?: FetchError;
}

interface IState {
  email: string;
  submitted: boolean;
  success: boolean;
  error?: FetchError;
}

class PasswordRecoveryPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      email: '',
      submitted: false,
      success: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { error, loading } = this.props;

    if (prevProps.error !== error) {
      this.showError();
    }

    if (prevProps.loading !== loading && !loading && !error) {
      this.showSuccess();
    }
  }

  private handleKeyPress(e: any) {
    if (e.which === 13) {
      this.handleSubmit();
    }
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'email'>);
    };
  }

  public handleSubmit() {
    const { email } = this.state;
    const { passwordRecovery } = this.props;

    if (email.length === 0) {
      this.setState({ submitted: true });

      return;
    }

    passwordRecovery(email);
  }

  public showError() {
    const { error } = this.props;

    this.setState({ error });
  }

  public showSuccess() {
    this.setState({ success: true });
  }

  public closeSnackbar() {
    this.setState({ error: undefined });
  }

  public render() {
    const { email, error, submitted, success } = this.state;
    const { loading } = this.props;

    let errorMessage = (
      <FormattedMessage
        id="external.passwordrecovery.error"
        defaultMessage="Something went wrong, please try again or contact our support."
      />
    );

    if (error && error.errorCode === 'UnknownAccountEmail') {
      errorMessage = (
        <FormattedMessage
          id="external.passwordrecovery.notfound"
          defaultMessage="Account not found."
        />
      );
    } else if (error && error.errorCode === 'AccountInactive') {
      errorMessage = (
        <FormattedMessage
          id="external.passwordrecovery.inactive"
          defaultMessage="This account is inactive."
        />
      );
    }

    return (
      <>
        <div id="banner">
          <video muted autoPlay loop poster="/assets/background.jpg">
            <source src="/assets/banner.mp4" type="video/mp4" />
            <track kind="captions" />
          </video>
        </div>

        <Grid container justifyContent="center" style={{ zIndex: 1 }}>
          <Grid item sm={4}>
            <Box mt={12} ml={2} mr={2} mb={2}>
              <Card className="container">
                <CardHeader
                  title={
                    <FormattedMessage
                      id="external.passwordrecovery.title"
                      defaultMessage="Password Recovery"
                    />
                  }
                />
                <CardContent>
                  <FormControl
                    required
                    fullWidth
                    margin="normal"
                    error={submitted === true && email.length === 0}
                  >
                    <TextField
                      label={
                        <FormattedMessage
                          id="external.passwordrecovery.label_email"
                          defaultMessage="Email"
                        />
                      }
                      name="email"
                      onChange={this.handleChange('email')}
                      onKeyPress={this.handleKeyPress}
                      value={email}
                      variant="standard"
                      disabled={success || loading}
                      required
                    />
                    {submitted === true && email.length === 0 ? (
                      <FormHelperText error>
                        <FormattedMessage
                          id="external.passwordrecovery.field_email_help"
                          defaultMessage="An email must be specified"
                        />
                      </FormHelperText>
                    ) : null}
                  </FormControl>

                  <Button
                    onClick={this.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={success || !email.length || loading}
                    fullWidth
                  >
                    <FormattedMessage
                      id="external.passwordrecovery.button_submit"
                      defaultMessage="Recover password"
                    />
                  </Button>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>

        <Snackbar
          open={!!error && !loading}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={this.closeSnackbar}
          autoHideDuration={3000}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <Snackbar
          open={success && !loading}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="success">
            <FormattedMessage
              id="external.passwordrecovery.success"
              defaultMessage="Password recovery requested with success. Please check your inbox for instructions on how to proceeds."
            />
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default PasswordRecoveryPage;
