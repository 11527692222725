import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import { MetricState } from '@reducers/analytics';
import { Assets } from '@models/Asset';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import { Table } from '@models/Metric';
import { Warehouses } from '@models/Warehouse';
import { Zones } from '@models/Zone';
import { formatMessage } from '../../../../utils/ruleUtils';

interface IProps {
  assets: Assets;
  floorplans: IncompleteFloorplans;
  warehouses: Warehouses;
  zones: Zones;
  type: 'zone_heatmap' | 'zone_trajectories' | 'fixed_tables';
  metric: MetricState;
}

/**
 * @description Table type tab view
 * @param       properties
 * @returns     React Elements
 */
const TablesTab: FunctionComponent<IProps> = ({
  assets,
  floorplans,
  warehouses,
  zones,
  type,
  metric,
}: IProps) => {
  let plot: Table | Table[] = [];
  if (type === 'fixed_tables') {
    plot = (metric.data || {}).fixed_tables || [];
  } else if (type === 'zone_heatmap') {
    const columns = {
      zoneId: (((metric.data || {}).zone_heatmap || {}).data || []).map(
        (d) => d.zone_id
      ),
      weight: (((metric.data || {}).zone_heatmap || {}).data || []).map(
        (d) => d.weight
      ),
    };
    plot = [{ columns, title: '' }];
  } else if (type === 'zone_trajectories') {
    const assetId: string[] = [];
    const zoneId: string[] = [];
    const timestamp: number[] = [];
    const event: string[] = [];

    // Transforms hierarquical data into tabular data, moving asset info 1 level down
    (((metric.data || {}).zone_trajectories || {}).data || []).forEach((d) => {
      (d.trajectory || []).forEach((t) => {
        assetId.push(d.asset_id);
        zoneId.push(t.zone_id);
        timestamp.push(t.timestamp);
        event.push(t.event);
      });
    });

    const columns = { assetId, zoneId, timestamp, event };
    plot = [{ columns, title: '' }];
  }

  const tables = Array.isArray(plot) ? plot : [plot];

  const customRowRender = (cols: boolean[], rowData: any, rowIndex: number) => (
    <TableRow key={rowIndex}>
      {cols.map((c, index) =>
        c ? (
          <TableCell key={index}>
            <span
              dangerouslySetInnerHTML={{
                __html: rowData[index] || '',
              }}
            />
          </TableCell>
        ) : null
      )}
    </TableRow>
  );

  return (
    <Loading loading={metric.loading}>
      {metric.error ? (
        <Alert
          severity="error"
          style={{ padding: '0px 10px', borderRadius: '0px' }}
        >
          <FormattedMessage
            id="dashboard.plots.analytics_issue"
            defaultMessage="Problem computing analytics"
          />
        </Alert>
      ) : (
        <>
          {!tables.length ? (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ErrorOutlineIcon style={{ color: '#666' }} />
              <Typography style={{ color: '#666', paddingLeft: '5px' }}>
                <FormattedMessage
                  id="dashboard.plots.nodata"
                  defaultMessage="No data for the current selection"
                />
              </Typography>
            </div>
          ) : (
            tables.map((table, i) => {
              const columns: ITableColumn[] = Object.keys(
                table.columns || {}
              ).map((column) => ({ label: column, name: column }));

              const data: any[] = [];
              ((table.columns || {})[(columns[0] || {}).name] || []).forEach(
                (d: any, index: number) => {
                  const obj: any = {};
                  columns.forEach((c) => {
                    const value = ((table.columns || {})[c.name] || [])[index];
                    const msg = {
                      message: value,
                      messageInfo: { assets, floorplans, warehouses, zones },
                    };

                    let column = msg.message;
                    if (c.name === 'Message') {
                      column = formatMessage(msg as any, true);
                    } else if (c.name === 'zoneId') {
                      column = (zones[value] || {}).name || value;
                    } else if (c.name === 'assetId') {
                      column = (assets[value] || {}).name || value;
                    }

                    obj[c.name] = column;
                  });
                  data.push(obj);
                }
              );

              return (
                <Box mb={2} key={i}>
                  <Card>
                    {!!table.title && (
                      <AppBar
                        position="static"
                        color="default"
                        style={{
                          zIndex: 0,
                          boxShadow: 'unset',
                          borderBottom: '1px solid #e3e3e3',
                        }}
                      >
                        <Toolbar variant="dense">
                          <Typography variant="h6">{table.title}</Typography>
                        </Toolbar>
                      </AppBar>
                    )}
                    <CardContent>
                      <MUITable
                        columns={columns}
                        customRowRender={customRowRender}
                        data={data}
                        tableId={`analytics-table_${i}`}
                      />
                    </CardContent>
                  </Card>
                </Box>
              );
            })
          )}
        </>
      )}
    </Loading>
  );
};

export default TablesTab;
