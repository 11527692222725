import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closeInviteForm,
  createAccountInvite,
  fetchAvailablePermissions,
  ICreateAccountInvite,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import {
  getAvailablePermissions,
  isAvailablePermissionsLoading,
} from '@selectors/accounts';
import InviteForm from './InviteForm';

const mapStateToProps = (state: IAppState) => ({
  availablePermissions: getAvailablePermissions(state.accounts),
  availablePermissionsLoading: isAvailablePermissionsLoading(state.accounts),
  error: state.accounts.form.error,
  loading: state.accounts.form.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeInviteForm: () => dispatch(closeInviteForm()),
  createAccountInvite: (properties: ICreateAccountInvite) =>
    dispatch(createAccountInvite.request(properties)),
  fetchAvailablePermissions: () =>
    dispatch(fetchAvailablePermissions.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteForm);
