import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import React, { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import List from './List';
import Planner from './Planner';

interface IProps {
  language: string;
}

const BaseRouter: FunctionComponent<IProps> = ({ language }: IProps) => {
  return (
    <Box className="tab-content-inner" p={2}>
      <Routes>
        <Route
          path="/plan/floorplan/:floorplanId/id/:id/edit"
          element={<Planner />}
        />
        <Route
          path="/plan/floorplan/:floorplanId/new"
          element={<Planner />}
        />
        <Route path="/" element={<List />} />
        <Route
          path="*"
          element={<Navigate to={`/${language}/dashboard/rtlsplanner`} replace />}
        />
      </Routes>
    </Box>
  );
}

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
});

export default connect(mapStateToProps)(BaseRouter);
