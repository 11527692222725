import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IAppState } from '@reducers/index';
import {
  createMessage,
  ICreateMessage,
} from '@actions/index';
import Chat from './Messages';

interface IProps {
  accountId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  messages: (state.chats.chats[props.accountId] || {}).messages || [],
  loading: (state.chats.chats[props.accountId] || {}).loading || false,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createMessage: (props: ICreateMessage) => dispatch(createMessage.request(props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
