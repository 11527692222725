import React, { FunctionComponent } from 'react';
import { useDrop } from 'react-dnd'

import { Employees } from '@models/Employee';
import { Task, TaskStatus } from '@models/Task';
import TaskView from './TaskView';

interface IProps {
  employees: Employees;
  tasks: Task[];
  onTaskDrop: (item: Task, newStatus: TaskStatus) => void;
  openModal: (task: Task) => void;
  status: TaskStatus;
}

const TaskContainer: FunctionComponent <IProps> = (properties) => {
  const { employees, tasks, onTaskDrop, openModal, status } = properties;

  const [, drop] = useDrop({
    accept: 'task',
    drop: (item) => onTaskDrop((item as any).task, status),
  });

  return (
    <div className="task-container-droparea" ref={drop}>
      {tasks.filter((t) => t.status === status).map((t, index) =>
        <TaskView
          key={index}
          index={index}
          openModal={openModal}
          task={t}
          employees={employees}
        />
      )}
    </div>
  );
}

export default TaskContainer;
