import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormControl from '@mui/material/FormControl/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import LockIcon from '@mui/icons-material/Lock';
import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IUpdateAccountPassword } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import Account from '@models/Account';
import FetchError from '@models/FetchError';
import PasswordFields from '../../../../forms/AccountForm/PasswordFields';

interface IProps {
  account: Account;
  changed: boolean;
  error?: FetchError;
  intl: IntlShape;
  loading: boolean;
  updateAccountPassword: (properties: IUpdateAccountPassword) => void;
}

interface IState {
  changed: boolean;
  changes: Record<string, boolean>;
  currentPassword: string;
  password: string;
  repassword: string;
}

class Password extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      changed: props.changed,
      changes: {},
      currentPassword: '',
      password: '',
      repassword: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { changed } = this.props;

    if (prevProps.changed !== changed) {
      this.setChanges();
    }
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const value: string | boolean =
        e.target.type === 'checkbox' ? e.target.checked : e.target.value;

      this.setState(
        (prevState) =>
          ({
            [key]: value,
            changed: value !== prevState[key],
          } as Pick<IState, 'changed'>)
      );
    };
  }

  public handleSubmit() {
    const { currentPassword, password } = this.state;
    const { intl, updateAccountPassword } = this.props;

    this.setState({ changes: {} });
    updateAccountPassword({
      currentPassword,
      intl,
      password,
    });
  }

  public handleReset() {
    this.setState({
      changed: false,
      changes: {},
      currentPassword: '',
      password: '',
      repassword: '',
    });
  }

  public setChanges() {
    const { changed } = this.props;

    if (changed === false) {
      return this.setState({
        changed: false,
        changes: {},
        currentPassword: '',
        password: '',
        repassword: '',
      });
    }

    return this.setState({ changed });
  }

  public render() {
    const { account, error, loading } = this.props;
    const { changed, changes, currentPassword, password, repassword } =
      this.state;

    const isCurrentPasswordWrong =
      loading === false &&
      changes.currentPassword !== true &&
      error !== undefined &&
      error.errorCode === 'WrongPassword';

    const doPasswordsMatch =
      password.length > 0 && repassword.length > 0
        ? password === repassword
        : true;

    const hasError =
      currentPassword.length === 0 ||
      password.length === 0 ||
      password.length < 8 ||
      repassword.length === 0 ||
      doPasswordsMatch === false;

    const buttonDisabled = hasError;

    return (
      <Card>
        <CardHeader
          avatar={<LockIcon />}
          title={account.name}
          subheader={
            <FormattedMessage
              id="dashboard.account.settings.menu.account_password"
              defaultMessage="Change Password"
            />
          }
        />
        <CardContent>
          <FormControl required fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.forms.changepassword.label_currentpassword"
                  defaultMessage="Current Password"
                />
              }
              variant="standard"
              value={currentPassword}
              type="password"
              autoComplete="current-password"
              error={isCurrentPasswordWrong}
              onChange={this.handleChange('currentPassword')}
              helperText={
                isCurrentPasswordWrong ? (
                  <FormHelperText error>
                    <FormattedMessage
                      id="dashboard.forms.changepassword.field_currentpassword_wrongpassword_error"
                      defaultMessage="Incorrect password"
                    />
                  </FormHelperText>
                ) : (
                  <FormattedMessage
                    id="dashboard.forms.changepassword.field_currentpassword_helper"
                    defaultMessage="Must indicate current password"
                  />
                )
              }
            />
          </FormControl>
          <PasswordFields
            doPasswordsMatch={doPasswordsMatch}
            handleChange={this.handleChange}
            password={password}
            repassword={repassword}
          />
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          {changed ? (
            <>
              <Box mr={2}>
                <Button onClick={this.handleReset}>
                  <FormattedMessage
                    id="dashboard.account.settings.accountbasics.button_cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </Box>
              <LoadingButton
                onClick={this.handleSubmit}
                variant="contained"
                color="secondary"
                disabled={buttonDisabled}
                loading={loading}
              >
                <FormattedMessage
                  id="dashboard.account.settings.accountbasics.button_submit"
                  defaultMessage="Update"
                />
              </LoadingButton>
            </>
          ) : null}
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(Password);
