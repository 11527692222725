import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IUpdateFloorplan, updateFloorplan } from '@actions/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { IAppState } from '@reducers/index';
import GeoAlignment from './GeoAlignment';

interface IProps {
  floorplan: IncompleteFloorplan;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
}

const mapStateToProps = (state: IAppState, { floorplan }: IProps) => ({
  floorplanId: floorplan.id,
  floorplans: state.floorplans.floorplans,
  geoMapping: floorplan.geoMapping,
  warehouseId: floorplan.warehouseId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeoAlignment);
