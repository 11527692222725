import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  createFloorplan,
  ICreateFloorplan,
  IUpdateFloorplan,
  updateFloorplan,
} from '@actions/index';
import FloorplanFormDialog from './FloorplanForm';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  createFloorplan: (properties: ICreateFloorplan) =>
    dispatch(createFloorplan.request(properties)),
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FloorplanFormDialog);
