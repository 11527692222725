import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  more: {
    defaultMessage: 'More',
    id: 'dashboard.settings.menu_aria_label',
  },
});

export const warehouseMessages = defineMessages({
  floorplanDeleteMsg: {
    defaultMessage: 'Are you sure that you want to DELETE this floor plan?',
    id: 'dashboard.settings.warehouse.msg_floorplan_delete',
  },
  floorplanHideMsg: {
    defaultMessage: 'Are you sure that you want to HIDE this floor plan?',
    id: 'dashboard.settings.warehouse.msg_floorplan_hide',
  },
  floorplanShowMsg: {
    defaultMessage: 'Are you sure that you want to SHOW this floor plan?',
    id: 'dashboard.settings.warehouse.msg_floorplan_show',
  },
  warehouseDeleteMsg: {
    defaultMessage:
      'Are you sure that you want to DELETE this entire warehouse?',
    id: 'dashboard.settings.warehouse.msg_delete',
  },
});

export const streamProcessingMessages = defineMessages({
  deleteMsg: {
    defaultMessage: 'Are you sure that you want to DELETE this file?',
    id: 'dashboard.settings.configurationfiles.msg_file_delete',
  },
  fileHardwareType: {
    defaultMessage: 'Processing',
    id: 'dashboard.settings.configurationfiles.tab_processing',
  },
  fileProcessingType: {
    defaultMessage: 'Hardware',
    id: 'dashboard.settings.configurationfiles.tab_hardware',
  },
});

export const accountsMessages = defineMessages({
  activate: {
    defaultMessage: 'Are you sure that you want to activate this account?',
    id: 'dashboard.settings.accounts.msg_account_activate',
  },
  deactivate: {
    defaultMessage: 'Are you sure that you want to deactivate this account?',
    id: 'dashboard.settings.accounts.msg_account_deactivate',
  },
});

export const preCheckMessages = defineMessages({
  deleteMsg: {
    defaultMessage: 'Are you sure that you want to DELETE this Pre-Check?',
    id: 'dashboard.settings.prechecklists.msg_precheck_delete',
  },
});
