import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closeAssetForm,
  createAsset,
  updateAsset,
  fetchFloorplanSensors,
  ICreateAsset,
  IUpdateAsset,
} from '@actions/index';
import { SensorTypes } from '@models/Sensor';
import { IAppState } from '@reducers/index';
import { getSensorsByType } from '@selectors/sensors';
import AssetsForm from './AssetForm';

const mapStateToProps = (state: IAppState) => ({
  assets: Object.values(state.assets.assets),
  loading: state.assets.form.loading,
  sensors: getSensorsByType(state, SensorTypes.BEACON),
  floorplans: state.floorplans.floorplans,
  warehouses: state.warehouses.warehouses,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeAssetForm: () => dispatch(closeAssetForm()),
  createAsset: (properties: ICreateAsset) =>
    dispatch(createAsset.request(properties)),
  updateAsset: (properties: IUpdateAsset) =>
    dispatch(updateAsset.request(properties)),
  fetchSensors: (floorplanId: string) =>
    dispatch(fetchFloorplanSensors.request({ floorplanId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetsForm);
