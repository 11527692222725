import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { updateHoverFeature, updateSelectedFeature } from '@actions/index';
import { SensorTypes } from '@models/Sensor';
import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import { getFloorplanSensorsByType } from '@selectors/sensors';
import { MapFeature, HoverFeature } from '../../../types';
import BeaconMap from './BeaconMap';

interface IProps {
  id: string;
  filterId?: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { id, filterId } = props;
  const map = getMapById(state, id);
  const filter = state.filter.filters[filterId || ''] || {};

  return {
    hoverFeature: map.hoverFeature,
    mapImages: map.mapImages,
    paper: map.paper,

    assets: Object.values(state.assets.assets).filter(
      (a) => a.floorplanId === map.floorplanId
    ),
    floorplan: getFloorplanById(state, map.floorplanId)!,

    measurementUnits: getAccountMeasurementUnits(state.authentication),

    activeFeature: map.feature,
    beacons: getFloorplanSensorsByType(
      state,
      SensorTypes.BEACON,
      map.floorplanId
    ).filter((s) => s.position !== undefined && s.position !== null).concat(
      getFloorplanSensorsByType(
        state,
        SensorTypes.MOBILEAPP,
        map.floorplanId
      )
    ),

    assetIds: filter.assetIds || [],
    tags: filter.tags || [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateHoverFeature: (id: string, feature?: HoverFeature) =>
    dispatch(updateHoverFeature(id, feature)),
  updateSelectedFeature: (id: string, feature?: MapFeature) =>
    dispatch(updateSelectedFeature(id, feature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeaconMap);
