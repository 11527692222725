import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import {
  createFloorplan,
  fetchFloorplanSensors,
  updateFloorplan,
  skipZoneSetup,
} from '@actions/index';
import { getFirstIncompleteFloorplan } from '@api/warehousesUtils';
import { IGeoMapping } from '@models/IncompleteFloorplan';
import { SensorTypes } from '@models/Sensor';
import { IAppState } from '@reducers/index';
import {
  getFloorplanSensorsByType,
  isSensorByFloorplanLoading,
} from '@selectors/sensors';
import { getFirstIncompleteWarehouse } from '@selectors/warehouses';
import { getZonesByFloorplan } from '@selectors/zones';
import { TransformationMatrix2D } from '../../utils';
import SetupLoader from './SetupLoader';
import { calculateActiveStep } from './setupUtils';

const mapStateToProps = (state: IAppState) => {
  const firstWarehouse = getFirstIncompleteWarehouse(state);
  const firstFloorplan = getFirstIncompleteFloorplan(firstWarehouse);

  const { error, firstFetch } = state.warehouses;

  const floorplanId = firstFloorplan ? firstFloorplan.id : undefined;
  const stationarySensors = getFloorplanSensorsByType(
    state,
    SensorTypes.STATIONARY,
    floorplanId
  );
  const zones = getZonesByFloorplan(state, floorplanId || '');

  return {
    activeStep: calculateActiveStep(
      stationarySensors,
      !!zones.length || state.app.setup[floorplanId || ''],
      firstWarehouse
    ),
    error,
    firstFetch,
    loading: state.floorplans.loading || state.zones.loading,
    floorplan: firstFloorplan,
    sensorsLoading: firstFloorplan
      ? isSensorByFloorplanLoading(state, firstFloorplan.id)
      : false,
    warehouse: firstWarehouse,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchStationarySensors: (floorplanId: string) =>
    dispatch(
      fetchFloorplanSensors.request({
        floorplanId,
      })
    ),
  onSkipInstallation: (
    image: File | undefined,
    intl: IntlShape,
    name: string,
    scale: number,
    transformationMatrix: TransformationMatrix2D,
    geoMapping: IGeoMapping[],
    warehouseId: string,
    floorplanId?: string
  ) => {
    const data = {
      intl,
      properties: {
        boundaries: [],
        geoMapping,
        image,
        name,
        obstacles: [],
        racks: [],
        scale,
        transformationMatrix,
      },
      warehouseId,
    };

    return dispatch(
      floorplanId === undefined
        ? createFloorplan.request(data)
        : updateFloorplan.request({ ...data, floorplanId })
    );
  },
  skipZoneSetup: (floorplanId: string) => dispatch(skipZoneSetup(floorplanId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupLoader);
