import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DropzoneArea } from 'react-mui-dropzone';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { CreateAssetTicket } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import CompleteAsset from '@models/CompleteAsset';
import { dropZoneMessages } from '../../../../../../utils/dropZoneMessages';

interface IState {
  subject: string;
  description: string;
  type: string;
  workImpairingIssue: boolean;
  eventDate?: Date;
  files: File[];
}

interface IProps {
  asset: CompleteAsset;
  closeTicketForm: () => void;
  createAssetTicket: (properties: CreateAssetTicket) => void;
  intl: IntlShape;
  loading: boolean;
}

class TicketForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      subject: '',
      description: '',
      type: '',
      workImpairingIssue: false,
      files: [],
    };

    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleDateTimeChange(date: any) {
    this.setState({ eventDate: date });
  }

  public handleChange(e: any) {
    const { name, value, type, checked } = e.target;

    this.setState({
      [name]: type === 'checkbox' ? checked : value || '',
    } as Pick<IState, 'type'>);
  }

  public handleFiles(files: any[]) {
    this.setState({ files });
  }

  public handleCancel() {
    const { closeTicketForm } = this.props;

    closeTicketForm();
  }

  public handleSubmit() {
    const { asset, createAssetTicket } = this.props;
    const { type, eventDate, subject, description, workImpairingIssue, files } =
      this.state;

    createAssetTicket({
      assetId: asset.id,
      type,
      eventDate: eventDate || undefined,
      subject,
      description,
      workImpairingIssue,
      files,
    });
  }

  public render() {
    const { intl, loading } = this.props;
    const { subject, description, eventDate, files, workImpairingIssue, type } =
      this.state;

    const error =
      subject.length === 0 || description.length === 0 || type.length === 0;

    const buttonDisabled = error;

    return (
      <Dialog open maxWidth="md" fullWidth>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.assets.management.ticketform.title_create"
            defaultMessage="Create Ticket"
          />
        </DialogTitle>
        <DialogContent>
          <FormControl required fullWidth variant="standard" margin="normal">
            <InputLabel htmlFor="ticket-form-type">
              <FormattedMessage
                id="dashboard.assets.management.ticketform.label_type"
                defaultMessage="Type"
              />
            </InputLabel>
            <Select
              id="ticket-form-type"
              value={type}
              onChange={this.handleChange}
              name="type"
            >
              <MenuItem value="" disabled />
              <MenuItem value="breakdown">
                <FormattedMessage
                  id="dashboard.assets.management.ticketform.label_type_breakdown"
                  defaultMessage="Breakdown"
                />
              </MenuItem>
              <MenuItem value="maintenance_needed">
                <FormattedMessage
                  id="dashboard.assets.management.ticketform.label_type_maintenance_needed"
                  defaultMessage="Maintenance Needed"
                />
              </MenuItem>
            </Select>
          </FormControl>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <FormControl required fullWidth margin="normal">
                <DatePicker
                  value={eventDate || null}
                  onChange={this.handleDateTimeChange}
                  label={
                    <FormattedMessage
                      id="dashboard.assets.management.ticketform.label_eventdate"
                      defaultMessage="Event Date"
                    />
                  }
                  slotProps={{
                    textField: { variant: 'standard' }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl required fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.assets.management.ticketform.label_subject"
                  defaultMessage="Subject of the issue"
                />
              }
              name="subject"
              variant="standard"
              onChange={this.handleChange}
              value={subject}
              required
            />
            {!subject && (
              <FormHelperText>
                <FormattedMessage
                  id="dashboard.assets.management.ticketform.field_subject_helper"
                  defaultMessage="The ticket must have an issue subject"
                />
              </FormHelperText>
            )}
          </FormControl>
          <FormControl required fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.assets.management.ticketform.label_description"
                  defaultMessage="Description of the issue"
                />
              }
              variant="standard"
              name="description"
              onChange={this.handleChange}
              multiline
              rows={5}
              value={description}
              required
            />
            {!description && (
              <FormHelperText>
                <FormattedMessage
                  id="dashboard.assets.management.ticketform.field_description_helper"
                  defaultMessage="The ticket must have an issue description"
                />
              </FormHelperText>
            )}
          </FormControl>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <FormControl required fullWidth margin="normal">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={workImpairingIssue}
                      onChange={this.handleChange}
                      name="workImpairingIssue"
                    />
                  }
                  label={
                    <FormattedMessage
                      id="dashboard.assets.management.ticketform.label_workimpairingissue"
                      defaultMessage="Work impairing issue"
                    />
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <DropzoneArea
            dropzoneText={intl.formatMessage(dropZoneMessages.dropzoneText)}
            acceptedFiles={['image/*']}
            initialFiles={files}
            filesLimit={6}
            getFileLimitExceedMessage={(filesLimit: number) =>
              intl.formatMessage(dropZoneMessages.exceededMessage, {
                limit: filesLimit,
              })
            }
            getFileAddedMessage={(fileName: string) =>
              intl.formatMessage(dropZoneMessages.addedMessage, { fileName })
            }
            getFileRemovedMessage={(fileName: string) =>
              intl.formatMessage(dropZoneMessages.removedMessage, { fileName })
            }
            getDropRejectMessage={(
              rejectedFile: any,
              acceptedFiles: any[],
              maxFileSize: number
            ) => {
              if (!acceptedFiles.includes(rejectedFile.type)) {
                return intl.formatMessage(dropZoneMessages.unsupportedFile, {
                  fileName: rejectedFile.name,
                });
              }

              if (rejectedFile.size > maxFileSize) {
                let size = '';

                if (maxFileSize >= 1048576) {
                  size = `${maxFileSize / 1048576} MB`;
                } else if (maxFileSize >= 1024) {
                  size = `${maxFileSize / 1024} KB`;
                } else {
                  size = `${maxFileSize} B`;
                }

                return intl.formatMessage(dropZoneMessages.fileTooBig, {
                  fileName: rejectedFile.name,
                  size,
                });
              }

              return '';
            }}
            onChange={this.handleFiles}
          />
        </DialogContent>
        <DialogActions>
          <Box mr={1}>
            <Button onClick={this.handleCancel}>
              <FormattedMessage
                id="dashboard.assets.management.ticketform.button_cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </Box>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color="secondary"
            disabled={buttonDisabled}
            loading={loading}
          >
            <FormattedMessage
              id="dashboard.assets.management.ticketform.buttom_submit"
              defaultMessage="Create"
            />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(TicketForm);
