import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IUpdateAccountPassword, updateAccountPassword } from '@actions/index';
import { IAppState } from '@reducers/index';
import { isAccountSettingsBasicLoading } from '@selectors/accounts';

import Password from './Password';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account!,
  changed: state.authentication.settings.password.changed,
  error: state.authentication.settings.password.error,
  loading: isAccountSettingsBasicLoading(state.authentication),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateAccountPassword: (properties: IUpdateAccountPassword) =>
    dispatch(updateAccountPassword.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Password);
