import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchSimulations,
  IFetchSimulations,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { getSimulations, isSimulationsLoading } from '@selectors/simulations';
import SimulationsTab from './SimulationsTab';
import { getActiveLanguage } from '@selectors/app';
import { withRouter } from '@app/utils/withRouter';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;

  const filter = state.filter.filters[filterId] || {};

  return {
    floorplanId: filter.floorplanId || '',
    loading: isSimulationsLoading(state.simulations) || state.simulations.form.loading,
    simulations: getSimulations(state.simulations, filter.floorplanId || ''),
    floorplans: state.floorplans.floorplans,
    warehouses: state.warehouses.warehouses,
    language: getActiveLanguage(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSimulations: (properties: IFetchSimulations) =>
    dispatch(fetchSimulations.request(properties)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SimulationsTab)
);
