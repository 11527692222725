import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { toggleMetric } from '@actions/index';
import { IAppState } from '@reducers/index';
import AnalyticsTab from './AnalyticsTab';

import './index.css';

const mapStateToProps = (state: IAppState) => ({
  filters: state.filter.filters,
  meta: state.analytics.meta,
  activeMetrics: state.analytics.activeMetrics,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleMetric: (
    filterId: string,
    section: 'user' | 'diagnostics',
    metricId: string
  ) => dispatch(toggleMetric(filterId, section, metricId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsTab);
