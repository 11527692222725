import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  ConfigurationFileTypes,
  confirmDialog,
  deleteConfigFile,
  fetchConfigFiles,
  IDeleteConfigFile,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import {
  getConfigFiles,
  isConfigFilesLoading,
} from '@selectors/confirgurations';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import ConfigurationFiles from './ConfigurationFiles';

const mapStateToProps = (state: IAppState) => ({
  configFilesHardware:
    getConfigFiles(state.configurations, ConfigurationFileTypes.HARDWARE) || {},
  configFilesProcessing:
    getConfigFiles(state.configurations, ConfigurationFileTypes.PROCESSING) ||
    {},
  loadingHardware: isConfigFilesLoading(
    state.configurations,
    ConfigurationFileTypes.HARDWARE
  ),
  loadingProcessing: isConfigFilesLoading(
    state.configurations,
    ConfigurationFileTypes.PROCESSING
  ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  deleteConfigFile: (properties: IDeleteConfigFile) =>
    dispatch(deleteConfigFile.request(properties)),
  fetchConfigFiles: (type: string) =>
    dispatch(fetchConfigFiles.request({ type })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationFiles);
