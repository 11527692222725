import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { IUpdateFloorplan } from '@actions/index';
import Loading from '@app/common/Loading';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Tabs from './Tabs';

interface IProps {
  fetchFloorplan: (id: string) => void;
  fetchStationarySensors: (floorplanId: string) => void;
  floorplan?: IncompleteFloorplan;
  router: any;
  language: string;
  loading: boolean;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
}

class FloorplanConfigurationFormDialog extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.goBack = this.goBack.bind(this);
  }

  public componentDidMount() {
    const { fetchFloorplan, fetchStationarySensors, router } = this.props;
    const { floorplanId } = router.params;

    fetchFloorplan(floorplanId);
    fetchStationarySensors(floorplanId);
  }

  public goBack() {
    const { router, language } = this.props;

    router.navigate(
      `/${language}/dashboard/settings/warehouses/${router.params.warehouseId}`
    );
  }

  public render() {
    const { floorplan, loading, updateFloorplan } = this.props;

    return (
      <Paper
        elevation={0}
        style={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
        }}
      >
        <CardHeader
          avatar={
            <Button onClick={this.goBack}>
              <ArrowBackIosIcon />
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.button_back"
                defaultMessage="Back"
              />
            </Button>
          }
          className="form-floorplanconfiguration-title"
          title={
            <Typography
              variant="h6"
              component="h6"
              style={{ color: '#ffffff' }}
            >
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.title"
                defaultMessage="Configure Floor plan {name}"
                values={{ name: (floorplan || {}).name }}
              />
            </Typography>
          }
        />
        <Loading loading={loading}>
          {floorplan && (
            <Tabs floorplan={floorplan} updateFloorplan={updateFloorplan} />
          )}
        </Loading>
      </Paper>
    );
  }
}

export default FloorplanConfigurationFormDialog;
