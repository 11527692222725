import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

import './index.css';

interface IProps {
  password: string;
}
/*
^                 The password string will start this way
(?=.*[a-z])       The string must contain at least 1 lowercase alphabetical character
(?=.*[A-Z])       The string must contain at least 1 uppercase alphabetical character
(?=.*[0-9])       The string must contain at least 1 numeric character
(?=.[!@#\$%\^&])  The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
(?=.{12,})        The string must be twelve characters or longer
*/

const strongRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})'
);

const mediumRegex = new RegExp(
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})'
);

const okRegex = new RegExp('^(?=.{8,})');

const Index: FunctionComponent<IProps> = ({ password }: IProps) => {
  const isStong = strongRegex.test(password);
  const isMedium = mediumRegex.test(password) && isStong === false;
  const isWeek = okRegex.test(password) && isMedium === false;

  const linkClass = clsx('bar', {
    medium: isMedium === true,
    strong: isStong === true,
    weak: isWeek === true,
  });

  return <div className={linkClass} />;
};

export default Index;
