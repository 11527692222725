import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import React, { Component } from 'react';

interface IProps {
  id: string;
  help: React.ReactElement | string | undefined;
  toggleMapHelp: (id: string, help?: React.ReactElement | string) => void;
}

class MapHelp extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.closeHelp = this.closeHelp.bind(this);
  }

  public closeHelp() {
    const { id, toggleMapHelp } = this.props;

    toggleMapHelp(id);
  }

  public render() {
    const { help } = this.props;

    const action = (
      <IconButton onClick={this.closeHelp}>
        <CloseIcon style={{ color: 'white' }} />
      </IconButton>
    );

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!help}
        onClose={this.closeHelp}
        message={help}
        action={action}
      />
    );
  }
}

export default MapHelp;
