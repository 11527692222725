import { connect } from 'react-redux';

import { getWarehouseIdByFloorplanId } from '@app/utils/floorplanUtils';
import { timeZone } from '@dashboard_utils/index';
import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { getWarehouseById } from '@selectors/warehouses';
import Hover from './Hover';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    feature: map.hoverFeature,
    warehouseTz:
      (
        getWarehouseById(
          state,
          getWarehouseIdByFloorplanId(
            state.floorplans.floorplans,
            map.floorplanId
          ) || ''
        ) || {}
      ).timezone || timeZone,
  };
};

export default connect(mapStateToProps)(Hover);
