import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { updateSelectedFeature, updateHoverFeature } from '@actions/index';
import { getMapById } from '@selectors/app';
import { IAppState } from '@reducers/index';
import { HoverFeature, MapFeature } from '../../../types';
import Hover from './Hover';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    mapImages: map.mapImages,
    paper: map.paper,

    feature: map.hoverFeature,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelectedFeature: (id: string, feature?: MapFeature) =>
    dispatch(updateSelectedFeature(id, feature)),
  updateHoverFeature: (id: string, feature?: HoverFeature) =>
    dispatch(updateHoverFeature(id, feature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Hover);
