import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import FloatLoading from './FloatLoading';

const mapStateToProps = (state: IAppState) => ({
  theme: state.app.theme,
});

export default connect(mapStateToProps)(FloatLoading);
