import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IAppState } from '@reducers/index';
import {
  ICreateMessage,
  IFetchAccounts,
  IFetchMessages,
  fetchAccounts,
  fetchMessages,
  createMessage,
} from '@actions/index';
import { getAccounts, isAccountsLoading } from '@selectors/accounts';
import { getSelectedLocale } from '@selectors/app';
import Chat from './Chat';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account,
  accounts: Object.values(getAccounts(state.accounts)),
  loading: isAccountsLoading(state.accounts),
  messageLoading: (state.chats.chats[0] || {}).loading || false,
  locale: getSelectedLocale(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAccounts: (props: IFetchAccounts) => dispatch(fetchAccounts.request(props)),
  fetchMessages: (props: IFetchMessages) => dispatch(fetchMessages.request(props)),
  createMessage: (props: ICreateMessage) => dispatch(createMessage.request(props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
