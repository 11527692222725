import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { IntlShape, injectIntl } from 'react-intl';

import {
  DeleteTicket,
  CreateTicketComment,
  DeleteTicketComment,
  ArchiveTicket,
} from '@actions/tickets';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '@app/dialogs/ConfirmDialog/types';
import { PRIMARY_COLOR } from '@app/utils/colors';
import Account from '@models/Account';
import { TicketComment, Tickets } from '@models/Ticket';
import { isImage } from '@app/utils';
import AvatarComponent from '@app/utils/Avatar';
import timeAgo from '@app/utils/timeAgo';

interface IProps {
  confirm: (properties: IConfirmDialog) => void;
  archiveTicket: (properties: ArchiveTicket) => void;
  deleteTicket: (properties: DeleteTicket) => void;
  createTicketComment: (properties: CreateTicketComment) => void;
  deleteTicketComment: (properties: DeleteTicketComment) => void;
  intl: IntlShape;
  language: string;
  locale: string;
  formLoading: boolean;
  loading: boolean;
  router: any;
  tickets: Tickets;
  redirect: boolean;
}
interface IState {
  anchorEl?: Element;
  comment: string;
}

class Ticket extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      comment: '',
    };

    this.toggleActions = this.toggleActions.bind(this);
    this.handleArchive = this.handleArchive.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.submitComment = this.submitComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { router, language, loading, redirect, tickets } = this.props;

    if (prevProps.redirect !== redirect && redirect) {
      router.navigate(`/${language}/dashboard/tickets`);
    }

    if (
      router['*'].indexOf('/id/') !== -1 &&
      router.params.id &&
      !loading &&
      !tickets[router.params.id]
    ) {
      router.navigate(`/${language}/dashboard/tickets`);
    }
  }

  public handleChange(field: keyof IState, event: any) {
    this.setState({ [field]: event.target.value } as Pick<IState, 'comment'>);
  }

  public handleArchive() {
    const { confirm, intl, router, archiveTicket } = this.props;

    this.setState({ anchorEl: undefined }, () => {
      confirm({
        confirmType: ConfirmDialogActions.ACTION,
        message: 'Are you sure that you want to archive this ticket?',
        onConfirmation: () => archiveTicket({ id: router.params.id, intl }),
      });
    });
  }

  public handleDelete() {
    const { confirm, intl, router, deleteTicket } = this.props;

    this.setState({ anchorEl: undefined }, () => {
      confirm({
        confirmType: ConfirmDialogActions.DELETE,
        message: 'Are you sure that you want to delete this ticket?',
        onConfirmation: () => deleteTicket({ id: router.params.id, intl }),
      });
    });
  }

  public toggleActions(event?: any) {
    this.setState({ anchorEl: event ? event.target : undefined });
  }

  public submitComment() {
    const { createTicketComment, intl, router } = this.props;
    const { comment } = this.state;

    createTicketComment({ ticketId: router.params.id, comment, intl });

    this.setState({ comment: '' });
  }

  public deleteComment(c: TicketComment) {
    const { confirm, deleteTicketComment, intl, router } = this.props;

    confirm({
      confirmType: ConfirmDialogActions.DELETE,
      message: 'Are you sure that you want to delete this comment?',
      onConfirmation: () =>
        deleteTicketComment({
          ticketId: router.params.id,
          id: c.id,
          intl,
        }),
    });
  }

  public render() {
    const { formLoading, language, locale, loading, router, tickets } =
      this.props;
    const { anchorEl, comment } = this.state;

    const { id, subject, description, archived, comments, files } =
      tickets[router.params.id] || {};

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink
            color="inherit"
            component={Link}
            to={`/${language}/dashboard/tickets`}
          >
            Tickets
          </MuiLink>
          <Typography color="textPrimary">{subject}</Typography>
        </Breadcrumbs>

        {loading ? (
          <LinearProgress />
        ) : (
          <Box mt={2} mr={2}>
            <Card>
              <CardHeader
                title={subject}
                action={
                  archived === false && (
                    <>
                      {formLoading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <IconButton
                            aria-label="More"
                            aria-owns={anchorEl ? 'long-menu' : undefined}
                            aria-haspopup="true"
                            onClick={this.toggleActions}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            open={!!anchorEl}
                            onClose={() => this.toggleActions()}
                          >
                            <MenuItem
                              onClick={() =>
                                router.navigate(
                                  `/${language}/dashboard/tickets/id/${id}/edit`
                                )
                              }
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit ticket" />
                            </MenuItem>
                            <MenuItem onClick={this.handleArchive}>
                              <ListItemIcon>
                                <ArchiveIcon />
                              </ListItemIcon>
                              <ListItemText primary="Arquive ticket" />
                            </MenuItem>
                            <MenuItem onClick={this.handleDelete}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete ticket" />
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </>
                  )
                }
              />
              <CardContent>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  component="span"
                >
                  Subject of the ticket
                </Typography>
                <Typography gutterBottom variant="body1" component="p">
                  {subject}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  component="span"
                >
                  Description of the ticket subject
                </Typography>
                <Typography gutterBottom variant="body1" component="p">
                  {description}
                </Typography>
                {(files || []).length > 0 && (
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    component="span"
                  >
                    Files
                  </Typography>
                )}
              </CardContent>
              <ImageList cols={5}>
                {(files || []).map((f) => (
                  <ImageListItem key={f.path}>
                    {isImage(f.path) ? (
                      <img src={f.path} alt={f.originalname} />
                    ) : (
                      <AttachFileIcon />
                    )}
                    <ImageListItemBar
                      title={
                        <MuiLink component="a" href={f.path} target="_blank">
                          {f.originalname}
                        </MuiLink>
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Card>
            {Object.values(comments || {}).length > 0 && (
              <Box mt={2} mb={2}>
                {Object.values(comments || {}).map((c) => (
                  <Box mt={1} key={c.id}>
                    <Card
                      key={c.id}
                      style={{
                        borderLeft: c.admin
                          ? `8px solid ${PRIMARY_COLOR}`
                          : 'unset',
                      }}
                    >
                      <CardHeader
                        avatar={
                          c.accountImage ? (
                            <AvatarComponent
                              account={
                                {
                                  id: c.accountId,
                                  name: c.accountName,
                                  image: c.accountImage,
                                } as Account
                              }
                              size={24}
                            />
                          ) : (
                            <AccountCircleIcon />
                          )
                        }
                        title={c.comment}
                        subheader={
                          <Typography variant="caption">
                            {c.accountName}, {timeAgo(c.created, locale)}
                          </Typography>
                        }
                        action={
                          archived === false && (
                            <>
                              {formLoading ? (
                                <CircularProgress />
                              ) : (
                                <IconButton
                                  onClick={() => this.deleteComment(c)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </>
                          )
                        }
                      />
                    </Card>
                  </Box>
                ))}
              </Box>
            )}
            {archived === false && (
              <Box mt={2}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControl required fullWidth margin="normal">
                    <TextField
                      label="Comment"
                      variant="standard"
                      value={comment}
                      multiline
                      rows={4}
                      onChange={(event: any) =>
                        this.handleChange('comment', event)
                      }
                    />
                  </FormControl>
                  {comment && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <IconButton onClick={this.submitComment}>
                        <SendIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
              </Box>
            )}
          </Box>
        )}
      </>
    );
  }
}

export default injectIntl(Ticket);
