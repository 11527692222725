import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IUpdateAccountReport, updateAccountReport } from '@actions/index';
import { IAppState } from '@reducers/index';
import Reports from './Reports';

const mapStateToProps = (state: IAppState) => ({
  loading: state.authentication.settings.report.loading,
  account: state.authentication.account!,
  changed: state.authentication.settings.report.changed,

  metrics: (state.analytics.meta.reportGenerator || {}).metrics || [],

  floorplans: Object.values(state.floorplans.floorplans),
  warehouses: Object.values(state.warehouses.warehouses),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateAccountReport: (properties: IUpdateAccountReport) =>
    dispatch(updateAccountReport.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
