import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getMapById } from '@selectors/app';
import LayerScaleComponent from './LayerScale';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    scale: map.scale,
    paper: map.paper,

    measurementUnits: getAccountMeasurementUnits(state.authentication),
  };
};

export default connect(mapStateToProps)(LayerScaleComponent);
