import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchFloorplanSensorGroupStatus,
  IFetchFloorplanSensors,
  IUpdateFloorplan,
  updateFloorplan,
} from '@actions/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Sensor from '@models/Sensor';
import { IAppState } from '@reducers/index';
import DrawDefinition from './DrawDefinition';

interface IProps {
  floorplan: IncompleteFloorplan;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
  sensors: Sensor[];
  type: string;
}

const mapStateToProps = (state: IAppState, { floorplan, type }: IProps) => {
  let draws = floorplan.racks;
  if (type === 'exteriorBoundaries') {
    draws = floorplan.exteriorBoundaries;
  } else if (type === 'boundaries') {
    draws = floorplan.boundaries;
  } else if (type === 'obstacles') {
    draws = floorplan.obstacles;
  } else if (type === 'landmarks') {
    draws = floorplan.landmarks;
  }

  return {
    draws,
    floorplan,
    type,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => {
    dispatch(fetchFloorplanSensorGroupStatus.request(properties));
  },
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawDefinition);
