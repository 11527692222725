import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchDataRange,
  IFetchDataRange,
  onChange,
  onAutoRefreshChange,
} from '@actions/index';

import { Date, timeZone } from '@dashboard_utils/index';
import { IAppState } from '@reducers/index';
import { IFilterChange } from '@reducers/filter';
import {
  getDataRange,
  getDataRangeError,
  isFetchDataRageLoading,
} from '@selectors/plots';

import WithHotDates from './WithHotDates';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;
  const filter = state.filter.filters[filterId] || {};

  const dataRange = getDataRange(state, filterId);

  const warehouseTz =
    (state.warehouses.warehouses[filter.warehouseId || ''] || {}).timezone ||
    timeZone;

  return {
    filterId,
    autoRefresh: filter.autoRefresh,
    dataRange,
    warehouseId: filter.warehouseId,
    floorplanId: filter.floorplanId,
    zoneIds: filter.zoneIds,
    assetIds: filter.assetIds,
    tags: filter.tags,
    ruleIds: filter.ruleIds,
    templateIds: filter.templateIds,
    startDate: filter.startDate || new Date(warehouseTz),
    endDate: filter.endDate || new Date(warehouseTz),
    loading: isFetchDataRageLoading(state, filterId),
    dataRangeError: getDataRangeError(state, filterId),
    warehouseTz,
    last: filter.last,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchDataRange: (properties: IFetchDataRange) =>
    dispatch(fetchDataRange.request(properties)),
  onChange: (properties: IFilterChange) => dispatch(onChange(properties)),
  onAutoRefreshChange: (id: string, autoRefresh: boolean) =>
    dispatch(onAutoRefreshChange(id, autoRefresh)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithHotDates);
