import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import PasswordFields from './AccountForm/PasswordFields';

interface IState {
  password: string;
  repassword: string;
}

interface IProps {
  onCancel: () => void;
  onOk: (password: string) => void;
}

class ResetPasswordForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      password: '',
      repassword: '',
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'password'>);
    };
  }

  public handleCancel() {
    const { onCancel } = this.props;

    onCancel();
  }

  public handleSubmit() {
    const { password } = this.state;
    const { onOk } = this.props;

    onOk(password);
  }

  public render() {
    const { password, repassword } = this.state;

    const doPasswordsMatch =
      password.length > 0 && repassword.length > 0
        ? password === repassword
        : true;

    const hasError =
      password.length === 0 ||
      password.length < 8 ||
      repassword.length === 0 ||
      doPasswordsMatch === false;

    const buttonDisabled = hasError;

    return (
      <Dialog open>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.forms.resetpassword.title"
            defaultMessage="Reset account password"
          />
        </DialogTitle>
        <DialogContent>
          <PasswordFields
            doPasswordsMatch={doPasswordsMatch}
            handleChange={this.handleChange}
            password={password}
            repassword={repassword}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            <FormattedMessage
              id="dashboard.forms.accountform.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            onClick={this.handleSubmit}
            variant="contained"
            color="secondary"
            disabled={buttonDisabled}
          >
            <FormattedMessage
              id="dashboard.forms.resetpassword.button_submit"
              defaultMessage="Reset"
            />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ResetPasswordForm;
