import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IAppState } from '@reducers/index';
import { withRouter } from '@app/utils/withRouter';
import { getActiveLanguage } from '@selectors/app';
import EmployeesTab from './EmployeesTab';
import { fetchEmployees } from '@actions/employees';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account!,
  data: Object.values(state.employees.employees),
  language: getActiveLanguage(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchEmployees: (shallow?: boolean) =>
    dispatch(fetchEmployees.request({ shallow })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployeesTab)
);
