import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import {
  defineMessages,
  injectIntl,
  IntlShape,
  FormattedMessage,
} from 'react-intl';
import React, { Component } from 'react';

import Map from '@app/common/FullMap';
import { IDrawAction } from '@app/common/FullMap/types';
import mapEvents from '@app/common/FullMap/Map/eventEmitter';
import List from './List';

const messages = defineMessages({
  newZone: {
    defaultMessage: 'New Zone',
    id: 'dashboard.forms.zoneform.title_create',
  },
});

interface IProps {
  filterId: string;
  intl: IntlShape;
}

interface IState {
  drawDefaultZone: boolean;
}

class RulesTab extends Component<IProps, IState> {
  private reactivateForm: () => void;

  constructor(props: IProps) {
    super(props);

    this.state = {
      drawDefaultZone: false,
    };

    this.createNewZone = this.createNewZone.bind(this);
    this.closeDraw = this.closeDraw.bind(this);
    this.reactivateFormRef = this.reactivateFormRef.bind(this);
    this.reactivateForm = () => {
      /**/
    };
  }

  public componentDidMount() {
    mapEvents.on('featureinfo-close', this.closeDraw);
  }

  public componentWillUnmount() {
    mapEvents.removeListener('featureinfo-close', this.closeDraw);
  }

  public createNewZone() {
    this.setState({ drawDefaultZone: true });
  }

  public closeDraw() {
    this.setState(
      { drawDefaultZone: false },
      this.reactivateForm ? this.reactivateForm : undefined
    );
  }

  public reactivateFormRef(reactivateForm: () => void) {
    this.reactivateForm = reactivateForm;
  }

  public render() {
    const { drawDefaultZone } = this.state;
    const { filterId, intl } = this.props;

    return (
      <Grid container flex={1}>
        <Grid id="rules-list" item xs={12} md={6} lg={4} flex={1}>
          <Box mr={2}>
            <Card>
              <List
                filterId={filterId}
                createNewZone={this.createNewZone}
                reactivateFormRef={this.reactivateFormRef}
              />
            </Card>
          </Box>
        </Grid>
        <Grid
          id="rules-map"
          item
          xs={12}
          md={6}
          lg={8}
          flex={1}
          style={{ display: 'flex' }}
        >
          <Map
            filterId={filterId}
            warnings={{
              // eslint-disable-next-line camelcase
              zone_warning: {
                id: 'zone_warning',
                severity: 'info',
                message: (
                  <FormattedMessage
                    id="dashboard.map.info.draw_zone"
                    defaultMessage="To create a new zone CTRL + Click and drag on the map."
                  />
                ),
              },
            }}
            forceActiveLayers={['zones']}
            actions={{
              hover: {},
              select: {},
              draw: {
                featureName: 'Zone',
                featureTitle: intl.formatMessage(messages.newZone),
                initDraw: drawDefaultZone,
                drawType: 'zone',
                drawColor: 'red',
                singleDraw: true,
              } as IDrawAction,
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default injectIntl(RulesTab);
