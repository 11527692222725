import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { onChange } from '@actions/index';
import { IAppState } from '@reducers/index';
import { IFilterChange } from '@reducers/filter';
import { getReportableAssets } from '@selectors/assets';
import { getDataRangeError } from '@selectors/plots';
import ReportForm from './ReportForm';

interface IProps {
  filterId: string;
  loading: boolean;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;

  const filter = state.filter.filters[filterId] || {};

  return {
    account: state.authentication.account!,

    dataRangeError: getDataRangeError(state, filterId),
    loading: props.loading,

    warehouseId: filter.warehouseId || '',
    floorplanId: filter.floorplanId || '',
    assetIds: filter.assetIds || [],

    endDate: filter.endDate,
    startDate: filter.startDate,

    warehouses: state.warehouses.warehouses,
    floorplans: state.floorplans.floorplans,

    assets: getReportableAssets(state),

    metrics: (state.analytics.meta[filterId] || {}).metrics || [],

    theme: state.app.theme,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChange: (properties: IFilterChange) => dispatch(onChange(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportForm);
