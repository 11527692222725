import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TableChartIcon from '@mui/icons-material/TableChart';
import LiveHelp from '@mui/icons-material/LiveHelp';
import RoomIcon from '@mui/icons-material/Room';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import DateTimeRangeSlider from '@app/common/DateTimeRangeSlider/WithHotDates';
import { messages } from '@definitions/plots';
import Filters from '@app/common/Filters';
import Map from '@app/common/FullMap';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from '@app/utils/localStorageUtils';
import MetricMeta from '@models/MetricMeta';
import { IActiveMetrics, MetricsMetaState } from '@reducers/analytics';
import LayerProvider from './LayerProvider';
import PlotsTab from './PlotsTab';
import TablesTab from './TablesTab';

interface IProps {
  /* Object with active tab information */
  activeMetrics: IActiveMetrics;
  /* Filter id for current component */
  filterId: string;
  /* Intl shape */
  intl: IntlShape;
  /* Metrics meta state (reducer) */
  meta: MetricsMetaState;
  /* Active analytics section */
  section: 'user' | 'diagnostics';
  /* Metric toggler, changes active metric (activeMetrics) for current filter id */
  toggleMetric: (
    filterId: string,
    section: 'user' | 'diagnostics',
    metricId: string
  ) => void;
}

interface IState {
  anchorEl?: HTMLElement;
  open: boolean;
  tab: number;
  table: boolean;
}

/**
 * Analytics Tabs Provider. Component responsible for extracting information from analytics meta
 * and active metric and translate it into needed tabs. Keeps state of the active tab.
 */
class TabsProvider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { filterId, activeMetrics, section } = props;

    const tabs = this.generateTabs(activeMetrics[section]);
    // Tries to get active tab from local storage
    const tab = Number(
      getFromLocalStorage(`analytics_selected_tab_${filterId}`) || 0
    );
    this.state = {
      open: false,
      tab: tabs[tab] ? tab : 0,
      table: false,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleMetricChange = this.handleMetricChange.bind(this);
    this.handleMapToggler = this.handleMapToggler.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  private handleTabChange(_: any, tab: number) {
    const { filterId } = this.props;

    this.setState({ tab }, () =>
      // Stores active tab in local storage
      saveToLocalStorage(`analytics_selected_tab_${filterId}`, String(tab))
    );
  }

  public handleMapToggler() {
    this.setState((currentState) => ({
      table: !currentState.table,
    }));
  }

  private handleMetricChange(event: any, id: string) {
    const { section, toggleMetric } = this.props;

    this.setState({ tab: 0 }, () => {
      toggleMetric(id, section, event.target.value);
    });
  }

  /**
   * @description Constructs array with tab ids for the needed tabs for the active metric.
   * @param       {string} metricId
   */
  public generateTabs(metricId?: string) {
    const { meta } = this.props;

    const tabs: string[] = [];

    if (
      (meta.metrics || []).find(
        (m) =>
          m.id === metricId && m.returns.indexOf('aggregation_plots') !== -1
      )
    ) {
      tabs.push('aggregation_plots');
    }
    if (
      (meta.metrics || []).find(
        (m) => m.id === metricId && m.returns.indexOf('fixed_plots') !== -1
      )
    ) {
      tabs.push('fixed_plots');
    }
    if (
      (meta.metrics || []).find(
        (m) =>
          m.id === metricId &&
          (m.returns.indexOf('grid_heatmap') !== -1 ||
            m.returns.indexOf('zone_heatmap') !== -1 ||
            m.returns.indexOf('trajectories') !== -1 ||
            m.returns.indexOf('zone_trajectories') !== -1 ||
            m.returns.indexOf('scatter') !== -1 ||
            m.returns.indexOf('other') !== -1)
      )
    ) {
      tabs.push('maps');
    }
    if (
      (meta.metrics || []).find(
        (m) => m.id === metricId && m.returns.indexOf('fixed_tables') !== -1
      )
    ) {
      tabs.push('fixed_tables');
    }
    if (
      tabs.indexOf('fixed_plots') !== -1 ||
      tabs.indexOf('aggregation_plots') !== -1
    ) {
      tabs.push('zone_metrics');
    }

    return tabs;
  }

  public toggleTooltip() {
    this.setState((currentState) => ({ open: !currentState.open }));
  }

  public render() {
    const { open, tab, table } = this.state;
    const { activeMetrics, filterId, intl, meta, section } = this.props;

    const activeFilters = ['assets', 'assetTags', 'zones'];
    const metricId = activeMetrics[section];
    const tabs = this.generateTabs(metricId);

    if (
      ((meta.metrics || []).find((m) => m.id === metricId) || {}).rule_filters
    ) {
      activeFilters.push('rules');
      activeFilters.push('ruleTemplates');
    }

    const activeMapIfAny = (
      ((activeMetrics.plots || {})[metricId] || {}).maps || {}
    ).result;
    const showMapTable =
      (activeMapIfAny === 'zone_heatmap' && table) ||
      activeMapIfAny === 'zone_trajectories';

    let tabContent = null;
    switch (tabs[tab]) {
      case 'zone_metrics':
      case 'maps': {
        tabContent = (
          <>
            {activeMapIfAny === 'zone_heatmap' && tabs[tab] === 'maps' && (
              <AppBar
                position="static"
                color="default"
                style={{
                  zIndex: 0,
                  boxShadow: 'unset',
                  flexDirection: 'row-reverse',
                  borderBottom: '1px solid #e3e3e3',
                }}
              >
                <Toolbar variant="dense">
                  <IconButton onClick={this.handleMapToggler}>
                    {table ? <RoomIcon /> : <TableChartIcon />}
                  </IconButton>
                </Toolbar>
              </AppBar>
            )}

            {showMapTable ? (
              <TablesTab
                section={section}
                type={activeMapIfAny as 'zone_heatmap' | 'zone_trajectories'}
                filterId={filterId}
              />
            ) : (
              <Map
                filterId={filterId}
                forceActiveLayers={
                  tabs[tab] === 'zone_metrics' ? ['zones'] : []
                }
                metricData={{
                  tab: tabs[tab] as 'maps' | 'zone_metrics',
                  section,
                }}
                actions={{
                  select: {},
                  hover: {},
                }}
              />
            )}
          </>
        );
        break;
      }

      case 'fixed_plots':
      case 'aggregation_plots':
        tabContent = (
          <PlotsTab section={section} type={tabs[tab]} filterId={filterId} />
        );
        break;

      case 'fixed_tables':
        tabContent = (
          <TablesTab
            section={section}
            type="fixed_tables"
            filterId={filterId}
          />
        );
        break;

      default:
        tabContent = null;
        break;
    }

    const metric =
      (meta.metrics || []).find((m) => m.id === metricId) || ({} as MetricMeta);

    return (
      <Box
        mt={1}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <Filters activeFilters={activeFilters} filterId={filterId} />
        <DateTimeRangeSlider filterId={filterId} />
        <Box mb={1} mt={1}>
          <Box mb={1}>
            <Card className="metric-card">
              {metric.help ? (
                <ClickAwayListener
                  onClickAway={open ? this.toggleTooltip : () => null}
                >
                  <Tooltip
                    onClick={this.toggleTooltip}
                    open={open}
                    placement="bottom-start"
                    title={
                      <Typography
                        style={{ color: '#FFF', padding: '7px' }}
                        variant="body2"
                      >
                        {metric.help}
                      </Typography>
                    }
                  >
                    <IconButton aria-label={metric.help}>
                      <LiveHelp />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              ) : null}

              <CardContent className="metric-card-content">
                <FormControl fullWidth variant="standard">
                  <Select
                    disableUnderline
                    displayEmpty
                    value={activeMetrics[section] || ''}
                    onChange={(event: any) =>
                      this.handleMetricChange(event, filterId)
                    }
                  >
                    <MenuItem value="" selected disabled>
                      <FormattedMessage
                        id="dashboard.plots.metrics_placeholder"
                        defaultMessage="Select a metric"
                      />
                    </MenuItem>
                    {(meta.metrics || []).map((m) => (
                      <MenuItem key={m.id} value={m.id}>
                        {m.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
              {activeMetrics[section] ? (
                <LayerProvider
                  activeMetrics={activeMetrics}
                  filterId={filterId}
                  section={section}
                  tab={tabs[tab]}
                />
              ) : null}
            </Card>
          </Box>
          {activeMetrics[section] ? (
            <AppBar position="static" color="default" style={{ zIndex: 0 }}>
              <Tabs
                centered
                indicatorColor="primary"
                onChange={this.handleTabChange}
                value={tab}
                variant="fullWidth"
                textColor="primary"
              >
                {tabs.map((k) => (
                  <Tab
                    id={`tab-${k}`}
                    label={intl.formatMessage(messages[`tab_${k}`])}
                    key={k}
                  />
                ))}
              </Tabs>
            </AppBar>
          ) : null}
        </Box>
        <Divider />
        {activeMetrics[section] && tabContent}
      </Box>
    );
  }
}

export default injectIntl(TabsProvider);
