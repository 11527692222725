import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import SpaghettiMap from './SpaghettiMap';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    mapImages: map.mapImages,
    paper: map.paper,

    floorplan: getFloorplanById(state, map.floorplanId)!,

    assets: state.assets.assets,
    zones: state.zones.zones,
  };
};

export default connect(mapStateToProps)(SpaghettiMap);
