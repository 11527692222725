import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  confirmDialog,
  createPlan,
  fetchFloorplanSensorGroupStatus,
  fetchFloorplanSensors,
  fetchPlans,
  ICreatePlan,
  IFetchFloorplanSensors,
  IFetchPlans,
  IUpdatePlan,
  updatePlan,
} from '@actions/index';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { withRouter } from '@app/utils/withRouter';
import { SensorTypes } from '@models/Sensor';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import {
  getFloorplanSensorGroupWithStatus,
  getFloorplanSensorsByType,
} from '@selectors/sensors';
import RTLSPlanner from './RTLSPlanner';

import './index.css';

interface IProps {
  router: any;
}

const mapStateToProps = (state: IAppState, { router }: IProps) => {
  const { floorplanId, id } = router.params;

  const activeSensorGroup = getFloorplanSensorGroupWithStatus(
    state,
    floorplanId
  );

  return {
    language: getActiveLanguage(state),
    floorplan: getFloorplanById(state, floorplanId),
    sensors: getFloorplanSensorsByType(
      state,
      SensorTypes.STATIONARY,
      floorplanId
    ).filter(
      (s) =>
        s.position !== undefined &&
        s.position !== null &&
        (!activeSensorGroup || s.sensorGroupId === activeSensorGroup.id)
    ),
    redirectId: state.plans.redirectId,
    statePlan: state.plans.plans[id || ''],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => {
    dispatch(fetchFloorplanSensorGroupStatus.request(properties));
  },
  fetchPlans: (properties: IFetchPlans) =>
    dispatch(fetchPlans.request(properties)),
  fetchSensors: (floorplanId: string) => {
    dispatch(
      fetchFloorplanSensors.request({
        floorplanId,
      })
    );
  },
  createPlan: (properties: ICreatePlan) =>
    dispatch(createPlan.request(properties)),
  updatePlan: (properties: IUpdatePlan) =>
    dispatch(updatePlan.request(properties)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RTLSPlanner)
);
