import Button from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogContentText from '@mui/material/DialogContentText/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

interface IProps {
  error?: object;
  children: React.ReactNode;
}

interface IState {
  open: boolean;
  error: boolean;
}

class ErrorDialogHOC extends Component<IProps, IState> {
  public static getDerivedStateFromProps({ error }: IProps, state: IState) {
    return {
      error: !!error,
      open: state.error === !!error ? state.open : !!error,
    };
  }

  constructor(props: IProps) {
    super(props);

    const { error } = props;

    this.state = {
      error: !!error,
      open: !!error,
    };

    this.handleClose = this.handleClose.bind(this);
  }

  public handleClose() {
    this.setState({
      open: false,
    });
  }

  public render() {
    const { children } = this.props;
    const { open, error } = this.state;

    return (
      <>
        <Dialog open={open && error} onClose={this.handleClose}>
          <DialogTitle>
            <FormattedMessage
              id="dashboard.setup.error.title"
              defaultMessage="Error"
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage
                id="dashboard.setup.error.description"
                defaultMessage="There was an error proceeding to the next step. Please try again."
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              <FormattedMessage
                id="dashboard.setup.error.button"
                defaultMessage="Close"
              />
            </Button>
          </DialogActions>
        </Dialog>
        {children}
      </>
    );
  }
}

export default ErrorDialogHOC;
