import { connect } from 'react-redux';

import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import AssetsTab from './AssetsTab';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;
  const filter = state.filter.filters[filterId] || {};

  return {
    account: state.authentication.account!,
    floorplanId: filter.floorplanId || '',
    floorplans: state.floorplans.floorplans,
    language: getActiveLanguage(state),
    warehouses: state.warehouses.warehouses,
  };
};

export default connect(mapStateToProps)(withRouter(AssetsTab));
