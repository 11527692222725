import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import React, { Component } from 'react';

import { ICreateTeam, IEditTeam } from '@actions/index';
import LoadingButton from '@app/common/LoadingButton';
import { Team } from '@models/Team';
import { Chip, InputLabel } from '@mui/material';
import { Employees } from '@models/Employee';

export interface IState {
  employees: string[];
  id?: string;
  name: string;
}

interface IProps {
  employeeMap: Employees;
  closeTeamForm: () => void;
  createTeam: (properties: ICreateTeam) => void;
  updateTeam: (properties: IEditTeam) => void;
  teams: Team[];
  data?: Team;
  intl: IntlShape;
  loading: boolean;
}

class TeamForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { data } = props;

    this.state = {
      id: (data || {}).id,
      name: (data || {}).name || '',
      employees: (data || {}).employees || [],
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(e: any) {
    const { name } = e.target;

    const value: string | boolean =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    const newState: any = {};
    newState[name] = value || '';

    this.setState(newState as Pick<IState, 'name'>);
  }

  public handleCancel() {
    const { closeTeamForm } = this.props;

    closeTeamForm();
  }

  public handleSubmit() {
    const { employees, id, name } = this.state;
    const { createTeam, intl, updateTeam } = this.props;

    if (id !== undefined) {
      updateTeam({
        employees,
        id,
        intl,
        name,
      });
    } else {
      createTeam({
        employees,
        intl,
        name,
      });
    }
  }

  public render() {
    const { employeeMap, loading } = this.props;
    const { employees, id, name } = this.state;

    const error = name.length === 0;

    const buttonDisabled = error;

    return (
      <Dialog open fullWidth maxWidth="sm">
        <DialogTitle>
          {id ? (
            <FormattedMessage
              id="dashboard.forms.teamform.title_edit"
              defaultMessage="Edit Team {name}"
              values={{ name }}
            />
          ) : (
            <FormattedMessage
              id="dashboard.forms.teamform.title_create"
              defaultMessage="Add Team"
            />
          )}
        </DialogTitle>
        <DialogContent>
          <FormControl required fullWidth margin="normal">
            <TextField
              label={
                <FormattedMessage
                  id="dashboard.forms.teamform.label_name"
                  defaultMessage="Name"
                />
              }
              variant="standard"
              value={name}
              name="name"
              onChange={this.handleChange}
              helperText={
                <FormattedMessage
                  id="dashboard.forms.teamform.field_name_helper"
                  defaultMessage="A name must be specified"
                />
              }
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="employees" variant="standard">
              <FormattedMessage
                id="dashboard.forms.taskform.label_employees"
                defaultMessage="Employees"
              />
            </InputLabel>
            <Select
              labelId="employees"
              multiple
              onChange={this.handleChange}
              name="employees"
              variant="standard"
              value={employees}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={(employeeMap[value] || {}).name || ''} />
                  ))}
                </Box>
              )}
            >
              {Object.values(employeeMap).map((e) => (
                <MenuItem
                  key={e.id}
                  value={e.id}
                >
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box mr={1}>
            <Button onClick={this.handleCancel}>
              <FormattedMessage
                id="dashboard.forms.assetform.button_cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </Box>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color={id ? 'secondary' : 'primary'}
            disabled={buttonDisabled}
            loading={loading}
          >
            {id ? (
              <FormattedMessage
                id="dashboard.forms.teamform.button_submit_edit"
                defaultMessage="Edit"
              />
            ) : (
              <FormattedMessage
                id="dashboard.forms.teamform.button_submit_create"
                defaultMessage="Create"
              />
            )}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(TeamForm);
