import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import {
  confirmDialog,
  deleteAllWarehouses,
  deleteFloorplan,
  deleteWarehouse,
  IDeleteFloorplan,
  IDeleteWarehouse,
  IUpdateFloorplan,
  updateFloorplan,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { Warehouses } from '@models/Warehouse';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import SettingsTab from './SettingsTab';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  loading: state.floorplans.loading || state.warehouses.loading,
  warehouses: state.warehouses.warehouses,
  floorplans: state.floorplans.floorplans,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  deleteFloorplan: (properties: IDeleteFloorplan) =>
    dispatch(deleteFloorplan.request(properties)),
  deleteWarehouse: (properties: IDeleteWarehouse) =>
    dispatch(deleteWarehouse.request(properties)),
  resetWarehouses: (warehouses: Warehouses, intl: IntlShape) => () =>
    dispatch(
      deleteAllWarehouses.request({ ids: Object.keys(warehouses), intl })
    ),
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettingsTab)
);
