import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { Component } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import uuid from 'uuid/v4';

import { FetchMetric, ToggleMetricPlot } from '@actions/analytics';
import { matchAggregation } from '@app/utils';
import { Date, timeZone } from '@dashboard_utils/index';
import { messages } from '@definitions/plots';
import { MetricAggregation } from '@models/Metric';
import MetricMeta from '@models/MetricMeta';
import { Warehouses } from '@models/Warehouse';
import {
  IActiveMetrics,
  MetricsMetaState,
  MetricState,
} from '@reducers/analytics';

interface IProps {
  filterId: string;

  intl: IntlShape;

  activeMetrics: IActiveMetrics;
  section: 'user' | 'diagnostics';

  warehouseId: string;
  floorplanId: string;
  zoneIds: string[];

  tags: string[];
  assetIds: string[];

  templateIds: string[];
  ruleIds: string[];

  startDate?: Date;
  endDate?: Date;
  filterUpdated: boolean;

  /* Active tab id */
  tab: string;
  meta: MetricsMetaState;

  metricState: MetricState;
  toggleMetricPlot: (properties: ToggleMetricPlot) => void;
  fetchMetric: (properties: FetchMetric) => void;

  locale: string;
  measurementUnits: string;

  warehouses: Warehouses;
}

interface IState {
  anchorEl?: HTMLElement;
}

/**
 * Analytics Layer Provider. Component responsible for fetching information from analytics.
 * Also provides layer toggle for analytics tabs.
 */
class LayerProvider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.toggleMetricPlot = this.toggleMetricPlot.bind(this);
    this.handleClickSub = this.handleClickSub.bind(this);
    this.handleCloseSub = this.handleCloseSub.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
    this.filterTabReturns = this.filterTabReturns.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    if (!this.toggledNeeded()) {
      this.fetchData();
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    if (!this.toggledNeeded()) {
      const {
        activeMetrics,
        filterId,

        warehouseId,
        floorplanId,
        zoneIds,

        tags,
        assetIds,

        templateIds,
        ruleIds,

        startDate,
        endDate,
        filterUpdated,

        warehouses,

        tab,
      } = this.props;

      const warehouseTz = warehouses[warehouseId]
        ? warehouses[warehouseId].timezone
        : timeZone;

      const start = startDate || new Date(warehouseTz);
      const end = endDate || new Date(warehouseTz);

      // fetches new layer data if selected interval changes
      if (
        start.getTime() !== end.getTime() &&
        filterUpdated &&
        ((prevProps.startDate || new Date(warehouseTz)).getTime() !==
          start.getTime() ||
          (prevProps.endDate || new Date(warehouseTz)).getTime() !==
            end.getTime() ||
          prevProps.warehouseId !== warehouseId ||
          prevProps.floorplanId !== floorplanId ||
          JSON.stringify(prevProps.zoneIds) !== JSON.stringify(zoneIds) ||
          JSON.stringify(prevProps.tags) !== JSON.stringify(tags) ||
          JSON.stringify(prevProps.assetIds) !== JSON.stringify(assetIds) ||
          JSON.stringify(prevProps.templateIds) !==
            JSON.stringify(templateIds) ||
          JSON.stringify(prevProps.ruleIds) !== JSON.stringify(ruleIds) ||
          JSON.stringify(prevProps.filterId) !== JSON.stringify(filterId) ||
          JSON.stringify(prevProps.activeMetrics) !==
            JSON.stringify(activeMetrics) ||
          prevProps.filterUpdated !== filterUpdated ||
          prevProps.tab !== tab)
      ) {
        return this.fetchData();
      }
    }

    return undefined;
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  public handleCloseSub() {
    this.setState({ anchorEl: undefined });
  }

  public handleClickSub(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public getActiveLayers() {
    const { activeMetrics, meta, section, tab } = this.props;

    const metricId = activeMetrics[section];

    const { id, result } =
      ((activeMetrics.plots || {})[metricId] || {})[tab] || {};

    // Get active metric meta
    const metricMeta =
      ((meta || {}).metrics || []).find((m) => m.id === metricId) ||
      ({} as MetricMeta);

    // Get layers for active tab
    const mLayers: MetricMeta[] = [];

    if (
      metricMeta &&
      metricMeta.returns &&
      ((tab === 'maps' &&
        (metricMeta.returns.indexOf('grid_heatmap') !== -1 ||
          metricMeta.returns.indexOf('zone_heatmap') !== -1 ||
          metricMeta.returns.indexOf('trajectories') !== -1 ||
          metricMeta.returns.indexOf('zone_trajectories') !== -1 ||
          metricMeta.returns.indexOf('scatter') !== -1 ||
          metricMeta.returns.indexOf('other') !== -1)) ||
        (tab === 'fixed_tables' &&
          metricMeta.returns.indexOf('fixed_tables') !== -1))
    ) {
      mLayers.push(metricMeta);
    }

    const activeLayer = mLayers.find((l) => l.id === id);

    return { mLayers, activeLayer, id, result };
  }

  private filterTabReturns(r: string) {
    const { tab } = this.props;

    return (
      (tab === 'maps' &&
        (r === 'grid_heatmap' ||
          r === 'zone_heatmap' ||
          r === 'zone_trajectories' ||
          r === 'other' ||
          r === 'scatter' ||
          r === 'trajectories' ||
          r === 'other')) ||
      (tab === 'fixed_tables' && r === 'fixed_tables')
    );
  }

  private fetchDataShallow() {
    this.fetchData(true);
  }

  private fetchData(shallow?: boolean) {
    const {
      activeMetrics,
      fetchMetric,

      filterId,
      warehouseId,
      floorplanId,
      zoneIds,

      tags,
      assetIds,

      templateIds,
      ruleIds,

      tab,
      meta,
      metricState,

      startDate,
      endDate,

      locale,
      measurementUnits,

      section,
    } = this.props;
    const metricId = activeMetrics[section];
    const { id, result } =
      ((activeMetrics.plots || {})[metricId] || {})[tab] || {};

    let metricData = (meta.metrics || []).find((m) => m.id === metricId);

    if (tab !== 'aggregation_plots' && tab !== 'fixed_plots') {
      metricData = (meta.metrics || []).find((m) => m.id === id);
    }

    if (
      metricData &&
      startDate &&
      endDate &&
      startDate.getTime() !== endDate.getTime() &&
      (tab !== 'maps' || result)
    ) {
      let aggregations: MetricAggregation[] = [];
      if (tab === 'aggregation_plots') {
        aggregations = Object.values(
          (metricState.data || {}).aggregations || {}
        )
          .filter((a) => !!a.id)
          .map((a) => ({
            id: a.id,
            level1: a.level1,
            level1Option: a.level1Option,
            level2: a.level2,
            level2Option: a.level2Option,
            level3: a.level3,
            level3Option: a.level3Option,
          }));
      }

      if (
        !aggregations.length &&
        metricData.defaultAggregations.length &&
        tab === 'aggregation_plots'
      ) {
        aggregations = metricData.defaultAggregations.map((a) => ({
          ...a,
          id:
            Object.keys((metricState.data || {}).aggregations || {}).find(
              (aId) =>
                matchAggregation((metricState.data || {}).aggregations[aId], a)
            ) || uuid(),
        }));
      }

      const returns = [tab !== 'maps' ? tab : result];
      if (tab === 'aggregation_plots') {
        returns.push('aggregation_tables');
      }

      /* Fetchs metric if conditions actually changed from last fetch */
      const conditions = metricState.conditions || {};
      if (
        conditions.filterId !== filterId ||
        conditions.metricId !== metricData.id ||
        conditions.from !== startDate.getTime() ||
        conditions.to !== endDate.getTime() ||
        conditions.warehouseId !== warehouseId ||
        conditions.floorplanId !== floorplanId ||
        JSON.stringify(conditions.zoneIds) !== JSON.stringify(zoneIds) ||
        JSON.stringify(conditions.tags) !== JSON.stringify(tags) ||
        JSON.stringify(conditions.assetIds) !== JSON.stringify(assetIds) ||
        JSON.stringify(conditions.templateIds) !==
          JSON.stringify(templateIds) ||
        JSON.stringify(conditions.ruleIds) !== JSON.stringify(ruleIds) ||
        JSON.stringify(conditions.aggregations) !==
          JSON.stringify(aggregations) ||
        conditions.locale !== locale ||
        conditions.units !== measurementUnits ||
        JSON.stringify(conditions.returns) !== JSON.stringify(returns) ||
        conditions.shallow !== shallow
      ) {
        fetchMetric({
          filterId,
          metricId: metricData.id,

          from: startDate.getTime(),
          to: endDate.getTime(),

          warehouseId,
          floorplanId,
          zoneIds,

          tags,
          assetIds,

          templateIds,
          ruleIds,

          aggregations,
          locale,
          units: measurementUnits,
          returns,
          shallow,
        });
      }
    }
  }

  public toggleMetricPlot(id: string, result: string) {
    const { activeMetrics, filterId, tab, section, toggleMetricPlot } =
      this.props;

    this.setState({ anchorEl: undefined }, () => {
      const metricId = activeMetrics[section];

      toggleMetricPlot({
        filterId,
        metricId,
        plotType: tab,
        id,
        result,
      });
    });
  }

  public toggledNeeded() {
    const { tab } = this.props;

    /**
     * If we are on a map or a fixed tables tab, we want to pre-fill the metric
     * if after loading metrics meta we have layers and no active layer.
     */
    if (tab === 'maps' || tab === 'fixed_tables') {
      const { mLayers, activeLayer } = this.getActiveLayers();

      if (!activeLayer && mLayers.length) {
        this.toggleMetricPlot(
          mLayers[0].id,
          // We need to get the fist return after filtering out the return types of this tab.
          mLayers[0].returns
            .filter(this.filterTabReturns)
            .sort((a, b) => (a > b ? 1 : -1))[0]
        );

        return true;
      }
    }

    return false;
  }

  public render() {
    const { anchorEl } = this.state;
    const { activeMetrics, filterId, intl, section, tab } = this.props;

    const { mLayers, activeLayer, id, result } = this.getActiveLayers();

    const layerLabel =
      activeLayer && result ? (
        <>
          <Typography variant="body1">{(activeLayer || {}).title}</Typography>
          <span style={{ marginLeft: '5px' }} />
          <Typography variant="caption">
            {intl.formatMessage(messages[`returnType_${result}`])}
          </Typography>
        </>
      ) : null;

    let layerLabelString;
    if (activeLayer) {
      layerLabelString = `${(activeLayer || {}).title} ${intl.formatMessage(
        messages[`returnType_${result}`]
      )}`;
    }

    if (tab === 'maps' || tab === 'fixed_tables') {
      return (
        <>
          <Button
            aria-label={layerLabelString || ''}
            color={activeMetrics[section] ? 'primary' : 'secondary'}
            id="show-layers"
            variant="contained"
            style={{
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            }}
            onClick={this.handleClickSub}
          >
            {layerLabel ||
              (tab === 'maps' ? (
                <FormattedMessage
                  id="dashboard.map.layers.select_map_layer"
                  defaultMessage="Select Map Layer(s)"
                />
              ) : (
                <FormattedMessage
                  id="dashboard.plots.tab.tables.button"
                  defaultMessage="Select Table Metric(s)"
                />
              ))}
            <ArrowDropDownIcon />
          </Button>
          <Menu
            id={`long-menu_${filterId}`}
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={this.handleCloseSub}
          >
            {mLayers.map((layer: MetricMeta) =>
              layer.returns
                .filter(this.filterTabReturns)
                .sort((a, b) => (a > b ? 1 : -1))
                .map((t) => (
                  <MenuItem key={`${layer.id}_${t}`}>
                    <FormControlLabel
                      label={
                        t && (
                          <>
                            <Typography variant="body1">
                              {layer.title}
                            </Typography>
                            <Typography variant="caption">
                              {intl.formatMessage(messages[`returnType_${t}`])}
                            </Typography>
                          </>
                        )
                      }
                      control={
                        <Checkbox
                          color="default"
                          checked={layer.id === id && t === result}
                          onChange={() => this.toggleMetricPlot(layer.id, t)}
                        />
                      }
                    />
                  </MenuItem>
                ))
            )}
          </Menu>
        </>
      );
    }

    return null;
  }
}

export default injectIntl(LayerProvider);
