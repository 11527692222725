import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IFetchRules, IFetchTemplates } from '@actions/index';
import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import { Rule } from '@models/Rule';
import RuleForm from '../../../../forms/RuleForm';
import { IState as IRuleFormState } from '../../../../forms/RuleForm/RuleForm';
import Line from './Line';

interface IProps {
  createNewZone: () => void;
  editionData?: Rule;
  fetchRules: (properties: IFetchRules) => void;
  fetchTemplates: (properties: IFetchTemplates) => void;
  floorplanId: string;
  formActive: boolean;
  intl: IntlShape;
  loading: boolean;
  locale: string;
  openRuleForm: (data?: Rule) => void;
  measurementUnits: string;
  rules: Record<string, Rule>;
  reactivateFormRef?: (reactivateForm: () => void) => void;
}

interface IState {
  columns: ITableColumn[];
  formState?: IRuleFormState;
  search?: string;
  showForm: boolean;
}

const messages = {
  thOptions: {
    defaultMessage: 'Options',
    id: 'dashboard.rules.table.options',
  },
  thTemplate: {
    defaultMessage: 'Template',
    id: 'dashboard.rules.table.template',
  },
  thName: {
    defaultMessage: 'Name',
    id: 'dashboard.rules.table.name',
  },
};

class List extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      columns: [
        { label: ' ', name: 'expanded', options: { interaction: false } },
        { label: intl.formatMessage(messages.thName), name: 'name' },
        { label: intl.formatMessage(messages.thTemplate), name: 'templateId' },
        {
          label: intl.formatMessage(messages.thOptions),
          name: 'options',
          options: { interaction: false },
        },
      ],
      showForm: props.formActive === true,
    };

    this.customRowRender = this.customRowRender.bind(this);
    this.handleAddRule = this.handleAddRule.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.createNewZone = this.createNewZone.bind(this);
    this.reactivateForm = this.reactivateForm.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);

    if (props.reactivateFormRef !== undefined) {
      props.reactivateFormRef(this.reactivateForm);
    }
  }

  public componentDidMount() {
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();
  }

  public componentDidUpdate(prevProps: any) {
    const { formActive } = this.props;

    if (prevProps.formActive !== formActive) {
      this.setFormActive();
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  public handleAddRule() {
    const { openRuleForm } = this.props;

    this.setState({ formState: undefined }, openRuleForm);
  }

  public handleEdit(id: string) {
    const { rules, openRuleForm } = this.props;

    openRuleForm(rules[id]);
  }

  public setFormActive() {
    const { formActive } = this.props;

    this.setState({ showForm: !!formActive });
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const { fetchRules, fetchTemplates, locale, measurementUnits } = this.props;

    fetchRules({ shallow });
    fetchTemplates({ locale, units: measurementUnits, shallow });
  }

  public createNewZone(state: IRuleFormState) {
    const { createNewZone } = this.props;

    this.setState({ showForm: false, formState: state }, createNewZone);
  }

  public reactivateForm() {
    this.setState({ showForm: true });
  }

  public customRowRender(
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    const { rules } = this.props;

    const data: Rule = Object.values(rules)[dataIndex];

    if (!data) {
      return <TableRow key={rowIndex} />;
    }

    return (
      <Line
        key={rowIndex}
        columns={columns}
        rule={data}
        rowData={rowData}
        handleEdit={this.handleEdit}
      />
    );
  }

  public render() {
    const { floorplanId, formActive, editionData, loading, rules } = this.props;
    const { columns, formState, showForm } = this.state;

    const data: Rule[] = Object.values(rules);

    const action = (
      <Button id="add-rule" onClick={this.handleAddRule}>
        <AddIcon style={{ marginRight: '5px' }} />
        <FormattedMessage id="dashboard.rules.add" defaultMessage="Add Rule" />
      </Button>
    );
    const title = (
      <FormattedMessage id="dashboard.rules.title" defaultMessage="Rules" />
    );

    return (
      <>
        <CardHeader action={action} title={title} />
        <Loading loading={loading}>
          <div className="table-container">
            <MUITable
              columns={columns}
              data={data}
              customRowRender={this.customRowRender}
              tableId="rules"
            />
          </div>
        </Loading>

        {formActive && showForm && (
          <RuleForm
            createNewZone={this.createNewZone}
            floorplanId={floorplanId}
            data={editionData}
            stateData={formState}
          />
        )}
      </>
    );
  }
}

export default injectIntl(List);
