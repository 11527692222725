import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { FunctionComponent } from 'react';

import Loading from '@app/common/Loading';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import ZonesForm from './ZonesForm';

interface IProps {
  floorplan: IncompleteFloorplan;
  loading: boolean;
}

const Zones: FunctionComponent<IProps> = ({ floorplan, loading }: IProps) => (
  <Card className="step-card">
    <CardContent style={{ flex: 1, position: 'relative' }}>
      <Loading loading={loading}>
        <ZonesForm floorplanId={floorplan.id} />
      </Loading>
    </CardContent>
  </Card>
);

export default Zones;
