import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';

import LandingGrid from './LandingGrid';

const mapStateToProps = (state: IAppState) => ({
  filters: state.filter.filters,
  warehouses: state.warehouses.warehouses,
});

export default connect(mapStateToProps)(LandingGrid);
