import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { updateScale } from '@actions/index';
import { SensorTypes } from '@models/Sensor';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import { getFloorplanSensorsByType } from '@selectors/sensors';
import { IAppState } from '@reducers/index';
import { LayerScale } from '../../LayerScale/LayerScale';

import './GMaps.css';

import GMaps from './GMaps';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    assets: Object.values(state.assets.assets).filter(
      (a) => a.floorplanId === map.floorplanId
    ),
    beacons: getFloorplanSensorsByType(
      state,
      SensorTypes.BEACON,
      map.floorplanId
    ).filter(
      (s) =>
        s.position !== undefined &&
        s.position !== null &&
        (s.sensorGroupId === map.activeSensorGroupId ||
          !map.activeSensorGroupId)
    ),
    floorplan: getFloorplanById(state, map.floorplanId)!,
    zones: state.zones.zones,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateScale: (id: string, scale?: LayerScale) =>
    dispatch(updateScale(id, scale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GMaps);
