import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import {
  getFromLocalStorage,
  saveToLocalStorage,
} from '../../utils/localStorageUtils';

const messages = defineMessages({
  all: {
    defaultMessage: 'All',
    id: 'dashboard.table.all',
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'dashboard.table.delete',
  },
  deleteAria: {
    defaultMessage: 'Delete Selected Rows',
    id: 'dashboard.table.deletearia',
  },
  displayRows: {
    defaultMessage: 'of',
    id: 'dashboard.table.displayRows',
  },
  downloadCsv: {
    defaultMessage: 'Download CSV',
    id: 'dashboard.table.download',
  },
  filterTable: {
    defaultMessage: 'Filter Table',
    id: 'dashboard.table.filtertable',
  },
  noData: {
    defaultMessage: 'No data to show',
    id: 'dashboard.table.nodata',
  },
  print: {
    defaultMessage: 'Print',
    id: 'dashboard.table.print',
  },
  reset: {
    defaultMessage: 'Reset',
    id: 'dashboard.table.reset',
  },
  rowsPerPage: {
    defaultMessage: 'Rows per page',
    id: 'dashboard.table.rowsperpage',
  },
  search: {
    defaultMessage: 'Search',
    id: 'dashboard.table.search',
  },
  sort: {
    defaultMessage: 'Sort',
    id: 'dashboard.table.sort',
  },
  tableNextPage: {
    defaultMessage: 'Next Page',
    id: 'dashboard.table.next_page',
  },
  tablePreviousPage: {
    defaultMessage: 'Previous Page',
    id: 'dashboard.table.previous_page',
  },
  text: {
    defaultMessage: 'row(s) selected',
    id: 'dashboard.table.text',
  },
  title: {
    defaultMessage: 'Filters',
    id: 'dashboard.table.title',
  },
  viewColumns: {
    defaultMessage: 'View Columns',
    id: 'dashboard.table.viewcolumns',
  },
  viewColumnsTitle: {
    defaultMessage: 'Show Columns',
    id: 'dashboard.table.viewcolumnstitle',
  },
  viewColumnsTitleAria: {
    defaultMessage: 'Show/Hide Table Columns',
    id: 'dashboard.table.viewcolumnstitlearia',
  },
});

const version = process.env.REACT_APP_VERSION;

export enum ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

interface ITableOptions {
  display?: boolean;
  filter?: boolean;
  interaction?: boolean;
  customHeadRender?: (columnMeta: any) => React.ReactNode;
}

export interface ITableColumn {
  label: string;
  name: string;
  options?: ITableOptions;
}

export interface IProps {
  columns: ITableColumn[];
  customToolbar?: () => React.ReactElement;
  data: any[];
  defaultOrderField?: string;
  customRowRender?: (
    columns: boolean[],
    data: any,
    dataIndex: number,
    rowIndex: number
  ) => React.ReactElement;
  expandableRow?: (rowData: any, rowMeta: any) => React.ReactElement;
  intl: IntlShape;
  options?: any;
  tableId: string;
}

interface IMUIOptions {
  display: 'true' | 'false' | 'excluded' | undefined;
  filter: boolean;
  filterList?: string[];
  searchable: boolean;
  sort: boolean;
  viewColumns: boolean;
  customHeadRender?: (columnMeta: any) => React.ReactNode;
}
interface IColumnOptions {
  index: number;
  options: IMUIOptions;
}

interface IState {
  columnOptions: Record<string, IColumnOptions>;
  field: string;
  order: ORDER;
  page: number;
  rowsPerPage: number;
  search?: string;
}

class Table extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { columns, defaultOrderField, tableId } = this.props;

    const field =
      getFromLocalStorage(`${tableId}_${version}_field`) ||
      defaultOrderField ||
      (columns[0] || {}).name;
    const order =
      (getFromLocalStorage(`${tableId}_${version}_order`) as ORDER) ||
      ORDER.DESC;

    const columnOptions: Record<string, IColumnOptions> = {};
    columns.forEach((column, index) => {
      columnOptions[column.name] = {
        index,
        options: {
          customHeadRender: (column.options || {}).customHeadRender,
          display: String((column.options || {}).display !== false) as
            | 'true'
            | 'false',
          filter:
            (column.options || {}).interaction !== false ||
            (column.options || {}).filter === true,
          searchable: (column.options || {}).interaction !== false,
          sort: (column.options || {}).interaction !== false,
          viewColumns: (column.options || {}).interaction !== false,
        },
      };
    });

    this.state = {
      columnOptions,
      field,
      order,
      page: 0,
      rowsPerPage: 10,
    };

    this.customRowRender = this.customRowRender.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    this.onColumnSortChange = this.onColumnSortChange.bind(this);
    this.onColumnViewChange = this.onColumnViewChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  public shouldComponentUpdate(nextProps: any, nextState: any) {
    const { search } = this.state;

    return search === nextState.search;
  }

  public onColumnSortChange(field: string, direction: string) {
    const { tableId } = this.props;

    const order = direction === 'ascending' ? ORDER.ASC : ORDER.DESC;

    saveToLocalStorage(`${tableId}_${version}_field`, field);
    saveToLocalStorage(
      `${tableId}_${version}_order`,
      direction === 'ascending' ? ORDER.ASC : ORDER.DESC
    );

    this.setState({ field, order });
  }

  public onFilterChange(field: string, filterList: any) {
    const { columnOptions } = this.state;
    const column = columnOptions[field];

    columnOptions[field].options.filterList = filterList[column.index];

    this.setState({ columnOptions });
  }

  public onSearchChange(search: string) {
    this.setState({ search });
  }

  public onColumnViewChange(field: string, action: string) {
    const { columnOptions } = this.state;

    columnOptions[field].options.display = String(action !== 'remove') as
      | 'true'
      | 'false';

    this.setState({ columnOptions });
  }

  public onChangePage(page: number) {
    this.setState({ page });
  }

  public onChangeRowsPerPage(rowsPerPage: number) {
    this.setState({ rowsPerPage });
  }

  public customRowRender(rowData: any, dataIndex: number, rowIndex: number) {
    const { customRowRender } = this.props;
    const { columnOptions } = this.state;

    if (customRowRender !== undefined) {
      return customRowRender(
        Object.keys(columnOptions).map(
          (column: string) => columnOptions[column].options.display !== 'false'
        ),
        rowData,
        dataIndex,
        rowIndex
      );
    }

    return null;
  }

  public render() {
    const {
      columns,
      customToolbar,
      customRowRender,
      data,
      expandableRow,
      intl,
      options,
    } = this.props;
    const { columnOptions, field, order, page, rowsPerPage, search } =
      this.state;

    return (
      <MUIDataTable
        columns={columns.map((column) => {
          let opts: any = {
            ...(columnOptions[column.name] || {}).options,
            sortOrder: order as 'desc' | 'asc',
          };

          if (!field || field !== column.name) {
            opts = (columnOptions[column.name] || {}).options;
          }

          return { ...column, options: opts };
        })}
        data={data}
        options={{
          customRowRender: customRowRender ? this.customRowRender : undefined,
          customToolbar,
          download: true,
          expandableRows: !!expandableRow,
          filter: true,
          onChangePage: this.onChangePage,
          onChangeRowsPerPage: this.onChangeRowsPerPage,
          onColumnSortChange: this.onColumnSortChange,
          onColumnViewChange: this.onColumnViewChange,
          onRowClick: () => window.dispatchEvent(new Event('resize')),
          onSearchChange: this.onSearchChange,
          page,
          print: false,
          renderExpandableRow: expandableRow,
          responsive: 'simple',
          rowsPerPage,
          rowsPerPageOptions: [10, 25, 50, 100, 200, 500],
          searchText: search,
          selectableRows: 'none',
          sort: true,
          elevation: 0,
          textLabels: {
            body: {
              noMatch: intl.formatMessage(messages.noData),
              toolTip: intl.formatMessage(messages.sort),
            },
            filter: {
              all: intl.formatMessage(messages.all),
              reset: intl.formatMessage(messages.reset),
              title: intl.formatMessage(messages.title),
            },
            pagination: {
              displayRows: intl.formatMessage(messages.displayRows),
              next: intl.formatMessage(messages.tableNextPage),
              previous: intl.formatMessage(messages.tablePreviousPage),
              rowsPerPage: intl.formatMessage(messages.rowsPerPage),
            },
            selectedRows: {
              delete: intl.formatMessage(messages.delete),
              deleteAria: intl.formatMessage(messages.deleteAria),
              text: intl.formatMessage(messages.text),
            },
            toolbar: {
              downloadCsv: intl.formatMessage(messages.downloadCsv),
              filterTable: intl.formatMessage(messages.filterTable),
              print: intl.formatMessage(messages.print),
              search: intl.formatMessage(messages.search),
              viewColumns: intl.formatMessage(messages.viewColumns),
            },
            viewColumns: {
              title: intl.formatMessage(messages.viewColumnsTitle),
              titleAria: intl.formatMessage(messages.viewColumnsTitleAria),
            },
          },
          ...(options || {}),
        }}
        title=""
      />
    );
  }
}

export default injectIntl(Table);
