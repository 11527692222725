import { MetricAggregation } from '@models/Metric';
import { DefaultMetricAggregation } from '@models/MetricMeta';

export const cellSize = (keys: string[], index: number) => {
  let size: 6 | 12 = keys.length > 1 ? 6 : 12;
  if (size === 6 && index === keys.length - 1 && (index + 1) % 2 !== 0) {
    size = 12;
  }

  return size;
};

export const matchAggregation = (
  aggregation1: MetricAggregation,
  aggregation2: DefaultMetricAggregation
) =>
  (aggregation1.level1 || '') === (aggregation2.level1 || '') &&
  (aggregation1.level1Option || '') === (aggregation2.level1Option || '') &&
  (aggregation1.level2 || '') === (aggregation2.level2 || '') &&
  (aggregation1.level2Option || '') === (aggregation2.level2Option || '') &&
  (aggregation1.level3 || '') === (aggregation2.level3 || '') &&
  (aggregation1.level3Option || '') === (aggregation2.level3Option || '');

export const isImage = (name: string) => name.match(/.(jpg|jpeg|png|gif)$/i);
