// @ts-nocheck
import {
  create,
  invDependencies,
  multiplyDependencies,
} from 'mathjs';
import { flatten, take } from 'lodash';
import React, { Component } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  Polygon,
} from 'react-google-maps';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';

import ws, { ISensorData } from '@api/websocket';
import { enu2geodetic } from '@app/common/FullMap/ENULocations';
import { generateHeatMap, scaleColors } from '@dashboard_utils/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import HeatMap from '@models/HeatMap';
import SpaghettiMap from '@models/SpaghettiMap';
import Asset from '@models/Asset';
import Sensor from '@models/Sensor';
import { Zones } from '@models/Zone';
import { getAssetBySensorAssociation } from '@selectors/assets';
import ZoneHeatMap from '@models/ZoneHeatMap';
import ZoneSpaghettiMap from '@models/ZoneSpaghettiMap';
import { LayerScale } from '../../LayerScale/LayerScale';

const { inv, multiply } = create({
  invDependencies,
  multiplyDependencies,
});

interface IMapProps {
  lat: number;
  lng: number;
  zoom: number;
  children: React.ReactNode;
}

const defaultMapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  mapTypeControl: true,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: true,
  mapTypeId: 'satellite',
};

// @ts-ignore-start
const MapComponent: any = withScriptjs(
  withGoogleMap(({ lat, lng, children, zoom }: IMapProps) => (
    <GoogleMap
      defaultZoom={zoom}
      defaultCenter={{ lat, lng }}
      defaultOptions={defaultMapOptions}
    >
      {children}
    </GoogleMap>
  ))
);
// @ts-ignore-end

interface IMarker {
  id: string;
  lat: number;
  lng: number;
  name?: string;
  color: string;
  open: boolean;
  lastTs: number;
}

interface IHeatMap {
  type: 'grid' | 'zone';
  map: HeatMap | ZoneHeatMap;
}

interface ISpaghettiMap {
  type: 'grid' | 'zone';
  map: SpaghettiMap | ZoneSpaghettiMap;
}

interface HeatmapPoint {
  coordinates: [number, number][];
  alpha: number;
}

interface IProps {
  id: string;
  floorplan: IncompleteFloorplan;
  heatmap?: IHeatMap;
  spaghetti?: ISpaghettiMap;
  assets: Asset[];
  beacons: Sensor[];
  zones: Zones;
  updateScale: (id: string, scale?: LayerScale) => void;
}
interface IState {
  markers: Record<string, IMarker>;
  zoom: number;
  heatmapPoints?: HeatmapPoint[];
}

class GMaps extends Component<IProps, IState> {
  private markersCache: Record<string, IMarker> = {};

  private interval: any;

  private clearMap = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      markers: {},
      zoom: 18,
    };

    this.handleRawMessage = this.handleRawMessage.bind(this);
  }

  public componentDidMount() {
    const { id, heatmap, updateScale } = this.props;

    ws.on('live-data', this.handleRawMessage);

    this.interval = setInterval(() => {
      Object.keys(this.markersCache).forEach((key) => {
        if (this.markersCache[key].lastTs < Date.now() - 2000) {
          delete this.markersCache[key];
        }
      });

      this.setState({ markers: this.markersCache });
    }, 1000);

    if (heatmap) {
      generateHeatMap(heatmap.map).then((heatmapPoints) => {
        updateScale(id, {
          max: heatmap.map.max_weight || 0,
          min: heatmap.map.min_weight || 0,
          logarithmicScale: heatmap.map.log_scale,
          units: heatmap.map.units,
        });

        this.updateHeatmap(heatmapPoints);
      });
    } else {
      updateScale(id);
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { id, heatmap, spaghetti, updateScale } = this.props;

    if (
      JSON.stringify(prevProps.heatmap || {}) !==
        JSON.stringify(heatmap || {}) ||
      JSON.stringify(prevProps.spaghetti || {}) !==
        JSON.stringify(spaghetti || {})
    ) {
      this.clearMap = true;
      this.forceUpdate();
      updateScale(id);

      setTimeout(() => {
        if (heatmap) {
          generateHeatMap(heatmap.map).then((heatmapPoints) => {
            updateScale(id, {
              max: heatmap.map.max_weight || 0,
              min: heatmap.map.min_weight || 0,
              logarithmicScale: heatmap.map.log_scale,
              units: heatmap.map.units,
            });
            this.updateHeatmap(heatmapPoints);
          });
        }

        this.clearMap = false;
        this.forceUpdate();
      }, 0);
    }
  }

  public componentWillUnmount() {
    ws.removeListener('live-data', this.handleRawMessage);

    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private handleRawMessage(sensorsData: ISensorData[]) {
    const { assets, beacons, floorplan } = this.props;

    sensorsData.forEach((point) => {
      const { physicalAddress } =
        beacons.find((s: Sensor) => s.id === point.id) || ({} as Sensor);

      const transformedCoodinates = take(
        flatten(
          multiply(
            inv(floorplan.enuTransformationMatrix || []),
            [point.x, point.y, 1].map((v) => [v])
          ) as number[][]
        ),
        3
      );
      const geoCoordinates = enu2geodetic(
        transformedCoodinates[0],
        transformedCoodinates[1],
        transformedCoodinates[2],
        {
          latitude: floorplan.geodeticCenterLatitude || 0,
          longitude: floorplan.geodeticCenterLongitude || 0,
        }
      );

      const asset = getAssetBySensorAssociation(
        assets || [],
        physicalAddress,
        point.ts
      );

      if (!this.markersCache[point.id]) {
        this.markersCache[point.id] = {
          id: point.id,
          lat: geoCoordinates.lat,
          lng: geoCoordinates.long,
          name: (asset || {}).name,
          color: (asset || {}).color || 'blue',
          open: false,
          lastTs: Date.now(),
        };
      } else {
        this.markersCache[point.id].lat = geoCoordinates.lat;
        this.markersCache[point.id].lng = geoCoordinates.long;
        this.markersCache[point.id].lastTs = Date.now();
      }
    });
  }

  public onToggleOpen(id: string) {
    const { markers } = this.state;

    markers[id].open = !markers[id].open;

    this.setState({ markers });
  }

  public updateHeatmap(heatmapPoints: HeatmapPoint[]) {
    this.setState({ heatmapPoints });
  }

  public render() {
    const { assets, floorplan, heatmap, spaghetti, zones } = this.props;
    const { heatmapPoints, markers, zoom } = this.state;

    const heatmapTransformedPoints: any[] = [];
    const zoneHeatmapPoints: any[] = [];
    if ((heatmap || {}).type === 'grid') {
      (heatmapPoints || []).forEach((d) => {
        const color =
          scaleColors[Math.round((scaleColors.length - 1) * d.alpha)];

        heatmapTransformedPoints.push({
          color,
          coordinates: d.coordinates.map((c) => {
            const transformedCoordinate = take(
              flatten(
                multiply(
                  inv(floorplan.enuTransformationMatrix || []),
                  [...c, 1].map((v) => [v])
                ) as number[][]
              ),
              3
            );

            return enu2geodetic(
              transformedCoordinate[0],
              transformedCoordinate[1],
              transformedCoordinate[2],
              {
                latitude: floorplan.geodeticCenterLatitude || 0,
                longitude: floorplan.geodeticCenterLongitude || 0,
              }
            );
          }),
        });
      });
    } else if ((heatmap || {}).type === 'zone') {
      const map = (heatmap || {}).map as ZoneHeatMap | undefined;

      const transformedZones = ((map || {}).data || []).map((zone) => ({
        ...zone,
        coordinates: ((zones[zone.zone_id] || {}).coordinates || []).map(
          (coordinate) => {
            const transformedCoodinates = take(
              flatten(
                multiply(
                  inv(floorplan.enuTransformationMatrix || []),
                  [coordinate[0], coordinate[1], 1].map((v) => [v])
                ) as number[][]
              ),
              3
            );

            return enu2geodetic(
              transformedCoodinates[0],
              transformedCoodinates[1],
              transformedCoodinates[2],
              {
                latitude: floorplan.geodeticCenterLatitude || 0,
                longitude: floorplan.geodeticCenterLongitude || 0,
              }
            );
          }
        ),
      }));

      const geometries = transformedZones.filter(
        (gp) => gp.coordinates.length > 0
      );

      if (map) {
        geometries.forEach((geometry) => {
          const minWeight = map.min_weight || 0;
          const maxWeight = map.max_weight || 0;
          const alpha = map.log_scale
            ? Math.log10(
                1 +
                  (9 * (geometry.weight - minWeight)) / (maxWeight - minWeight)
              ) + 0.1
            : (geometry.weight - minWeight) / (maxWeight - minWeight) + 0.1;
          const globalAlpha = alpha > 1 ? 1 : alpha;
          zoneHeatmapPoints.push({
            color:
              scaleColors[Math.round((scaleColors.length - 1) * globalAlpha)],
            coordinates: geometry.coordinates,
          });
        });
      }
    }

    const spaghettiMap = (spaghetti || {}).map as SpaghettiMap | undefined;

    return (
      <MapComponent
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=visualization`}
        containerElement={<div />}
        mapElement={<div id="google-maps" />}
        lat={floorplan.geodeticCenterLatitude || 0}
        lng={floorplan.geodeticCenterLongitude || 0}
        zoom={zoom}
        loadingElement={<div />}
      >
        {Object.values(markers).map((marker) => {
          const { color, id, lat, lng, open, name } = marker;

          // @ts-ignore-start
          return (
            <Marker
              onClick={() => this.onToggleOpen(id)}
              key={id}
              position={{ lat, lng }}
            >
              {open ? (
                <InfoBox
                  onCloseClick={() => this.onToggleOpen(id)}
                  options={{ closeBoxURL: '', enableEventPropagation: true }}
                >
                  <div className="map-infobox" style={{ color }}>
                    {name || 'NA'}
                  </div>
                </InfoBox>
              ) : null}
            </Marker>
          );
          // @ts-ignore-end
        })}
        {!this.clearMap &&
          (spaghetti || {}).type === 'grid' &&
          ((spaghettiMap || {}).data || []).map((d) => {
            const asset = assets.find((a) => a.id === d.asset_id);

            return d.coordinates.map((sets, index) => {
              if (!window.google) {
                return null;
              }

              const coordinates = sets.map((c) => {
                const transformedCoodinates = take(
                  flatten(
                    multiply(
                      inv(floorplan.enuTransformationMatrix || []),
                      [c[0], c[1], 1].map((v) => [v])
                    ) as number[][]
                  ),
                  3
                );
                const geoCoordinates = enu2geodetic(
                  transformedCoodinates[0],
                  transformedCoodinates[1],
                  transformedCoodinates[2],
                  {
                    latitude: floorplan.geodeticCenterLatitude || 0,
                    longitude: floorplan.geodeticCenterLongitude || 0,
                  }
                );

                return new window.google.maps.LatLng(
                  geoCoordinates.lat,
                  geoCoordinates.long
                );
              });

              return (
                <Polyline
                  key={`${d.asset_id}_${index}`}
                  path={coordinates}
                  options={{ strokeColor: (asset || {}).color || '#FF0000' }}
                />
              );
            });
          })}
        {!this.clearMap &&
          heatmapTransformedPoints.length &&
          window.google &&
          heatmapTransformedPoints.map((p, index) => (
            <Polygon
              key={`pol_${index}`}
              path={p.coordinates.map(
                (point: any) =>
                  new window.google.maps.LatLng(point.lat, point.long)
              )}
              options={{
                fillColor: p.color,
                fillOpacity: 0.4,
                strokeWeight: 0,
              }}
            />
          ))}
        {!this.clearMap &&
          zoneHeatmapPoints.length &&
          window.google &&
          zoneHeatmapPoints.map((p, index) => (
            <Polygon
              key={`pol_${index}`}
              path={p.coordinates.map(
                (point: any) =>
                  new window.google.maps.LatLng(point.lat, point.long)
              )}
              options={{
                fillColor: p.color,
                fillOpacity: 0.4,
                strokeWeight: 0,
              }}
            />
          ))}
      </MapComponent>
    );
  }
}

export default GMaps;
