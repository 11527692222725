import { createCanvas } from 'canvas';
import EventEmitter from 'events';
import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';

import { loadImage } from '@app/utils/mapUtils';
import { PRIMARY_COLOR } from '@app/utils/colors';
import { transformMeters } from '@dashboard_utils/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { IPlan, SensorSettings } from '@models/Plan';
import Sensor from '@models/Sensor';
import { IDrawDefinition } from '@models/types';
import MapImages from '../FullMap/Map/MapImages';
import Heatmap from './heatmap';

import './index.css';

// Jest (jsdom) does not support webgl, this prevents tests to fail
let renderer: THREE.WebGLRenderer | undefined;
try {
  renderer = new THREE.WebGLRenderer({
    antialias: true,
  });
} catch (err) {
  renderer = undefined;
}

THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1);

interface ThreeDData {
  color: string;
  id: string;
  positionX: number;
  positionY: number;
  positionZ: number;
  rotation: number;
}

interface LiveFeature {
  ts: number;
  feature: THREE.Mesh;
  lastRotation: number;
  removed: boolean;
}
interface ExtendedLineSegments extends THREE.LineSegments {
  color: string;
  data: SensorSettings;
}

interface IProps {
  floorplan: IncompleteFloorplan;
  color?: boolean;
  overlayImage?: HTMLCanvasElement;
  overlayImageKey?: number;
  plan?: IPlan;
  editor?: boolean;
  mapImages?: MapImages;
  sensors?: Sensor[];
  showBoundaries: boolean;
  showFloorplan: boolean;
  showSensors: boolean;
  showRacks: boolean;
  updateStats?: (coverage: number, area: number, quality: number) => void;
}

export const emitter = new EventEmitter();

class RTLSPlannerMap extends Component<IProps> {
  public camera: THREE.PerspectiveCamera = new THREE.PerspectiveCamera(
    70,
    window.innerWidth / window.innerHeight,
    1,
    10000
  );

  public scene: THREE.Scene = new THREE.Scene();

  public transformControl?: TransformControls;

  public controls?: OrbitControls;

  public helper?: THREE.GridHelper;

  public raycaster: THREE.Raycaster = new THREE.Raycaster();

  public pointer: THREE.Vector2 = new THREE.Vector2();

  public onUpPosition: THREE.Vector2 = new THREE.Vector2();

  public onDownPosition: THREE.Vector2 = new THREE.Vector2();

  public interactables: any[] = [];

  public isDragging = false;

  public image?: HTMLImageElement;

  public heatmap?: Heatmap;

  public liveAction: Record<string, LiveFeature> = {};

  public lastPlan?: THREE.Mesh;

  public timer?: any;

  public updateTimer?: any;

  public isDirty = false;

  constructor(props: IProps) {
    super(props);

    this.animate = this.animate.bind(this);
    this.update = this.update.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  public componentDidMount() {
    this.buildEditor();

    window.addEventListener('resize', this.handleResize);

    emitter.on('sensor-unfocus', () => {
      this.interactables
        .filter((i) => i.children && i.children[0] && i.children[0].data)
        .forEach((i) => {
          // eslint-disable-next-line no-param-reassign
          i.children[0].material = new THREE.LineBasicMaterial({
            color: i.children[0].color,
          });

          this.animate();
        });
    });

    emitter.on('live-data', (data: ThreeDData[]) => {
      data.forEach((d: ThreeDData) => {
        const rotation = d.rotation - Math.PI / 2;

        if (!this.liveAction[d.id]) {
          const shape = new THREE.Shape();
          shape.moveTo(0, 0);
          shape.lineTo(-1, -1);
          shape.lineTo(0, 2);
          shape.lineTo(1, -1);
          shape.lineTo(0, 0);

          const extrudeSettings = {
            depth: 0.5,
            bevelEnabled: false,
            steps: 1,
            bevelSize: 1,
            bevelThickness: 1,
          };

          const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

          const material = new THREE.MeshBasicMaterial({
            color: new THREE.Color(d.color),
            opacity: 1,
          });
          const arrow = new THREE.Mesh(geometry, material);
          arrow.position.set(0, 0, 0);
          arrow.translateX(d.positionX);
          arrow.translateY(d.positionY);
          arrow.translateZ(d.positionZ);
          arrow.rotateZ(rotation);
          this.scene.add(arrow);

          this.liveAction[d.id] = {
            ts: Date.now(),
            feature: arrow,
            lastRotation: rotation,
            removed: false,
          };
        } else {
          this.liveAction[d.id].feature.position.set(0, 0, 0);
          this.liveAction[d.id].feature.rotateZ(
            -this.liveAction[d.id].lastRotation
          );
          this.liveAction[d.id].feature.translateX(d.positionX);
          this.liveAction[d.id].feature.translateY(d.positionY);
          this.liveAction[d.id].feature.translateZ(d.positionZ);
          this.liveAction[d.id].feature.rotateZ(rotation);
          this.liveAction[d.id].ts = Date.now();
          this.liveAction[d.id].lastRotation = rotation;
          if (this.liveAction[d.id].removed) {
            this.scene.add(this.liveAction[d.id].feature);
          }
        }
        this.isDirty = true;
      });
    });

    emitter.on('sensor-changed', (plan: SensorSettings) => {
      this.transformControl!.detach();
      const plans = this.interactables
        .filter((i) => i.children && i.children[0])
        .map((i) => ({
          ...i.children[0].data,
          id: i.children[0].uuid,
          positionX: i.position.x,
          positionY: i.position.y,
          positionZ: i.position.z,
        }));

      this.interactables.forEach((i) => this.scene.remove(i));
      this.interactables = [];

      if (plan.id) {
        const index = plans.findIndex((p) => p.id === plan.id);
        plans[index] = plan;
      } else {
        plans.push(plan);
      }

      plans.forEach((p) => this.drawSensor(p));

      this.drawBackground();
      this.animate();

      emitter.emit('sensors-changed', plans);
      emitter.emit('sensor-unfocus');
    });

    emitter.on('sensor-deleted', ({ id }) => {
      const index = this.interactables.findIndex(
        (i) => i.children && i.children[0] && i.children[0].uuid === id
      );
      this.transformControl!.detach();
      this.scene.remove(this.interactables[index]);
      this.interactables.splice(index, 1);
      this.drawBackground();
      this.animate();
    });

    this.timer = setInterval(() => {
      Object.keys(this.liveAction).forEach((k) => {
        if (this.liveAction[k].ts < Date.now() - 10 * 1000) {
          this.scene.remove(this.liveAction[k].feature);
          this.liveAction[k].removed = true;
          this.isDirty = true;
        }
      });
    }, 1000);
    this.updateTimer = setInterval(() => {
      if (this.isDirty) {
        this.isDirty = false;
        this.animate();
      }
    }, 50);
  }

  public shouldComponentUpdate(prevProps: IProps) {
    return JSON.stringify(prevProps) !== JSON.stringify(this.props);
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      color,
      floorplan,
      plan,
      overlayImageKey,
      showBoundaries,
      showFloorplan,
      showSensors,
      showRacks,
    } = this.props;

    if (
      prevProps.floorplan.id !== floorplan.id ||
      prevProps.showBoundaries !== showBoundaries ||
      prevProps.showFloorplan !== showFloorplan ||
      prevProps.showSensors !== showSensors ||
      prevProps.showRacks !== showRacks ||
      prevProps.color !== color
    ) {
      this.buildEditor();

      return null;
    }

    // Do not listen to JSON diff as it will get updated on first load (sensor changing) creating a loop
    if (
      ((prevProps.plan || {}).sensorPositions || []).length !==
      ((plan || {}).sensorPositions || []).length
    ) {
      this.transformControl!.detach();
      this.interactables.forEach((i) => {
        this.scene.remove(i);
      });
      this.interactables = [];

      ((plan || {}).sensorPositions || []).forEach((p: any) =>
        this.drawSensor(p)
      );

      this.drawBackground();
      this.animate();

      const plans = this.interactables
        .filter((i) => i.children && i.children[0])
        .map((i) => ({
          ...i.children[0].data,
          id: i.children[0].uuid,
          positionX: i.position.x,
          positionY: i.position.y,
          positionZ: i.position.z,
        }));

      emitter.emit('sensors-changed', plans);

      return null;
    }

    if (
      prevProps.overlayImageKey !== overlayImageKey ||
      JSON.stringify({ ...(prevProps.plan || {}), sensorPositions: null }) !==
        JSON.stringify({ ...(plan || {}), sensorPositions: null })
    ) {
      this.drawBackground();
      this.animate();
    }

    return null;
  }

  public componentWillUnmount() {
    const container = document.getElementById('editor');

    container!.removeEventListener('pointerdown', this.onPointerDown);
    container!.removeEventListener('pointerup', this.onPointerUp);
    container!.removeEventListener('pointermove', this.onPointerMove);
    window.removeEventListener('resize', this.handleResize);

    this.scene.clear();
    if (renderer) {
      this.interactables = [];
      renderer.clear();
    }

    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
  }

  public handleResize() {
    const container = document.getElementById('editor');

    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    if (renderer) {
      renderer.setSize(container!.clientWidth, container!.clientHeight);
      renderer.shadowMap.enabled = true;
    }
  }

  public onPointerDown(event: any) {
    this.isDragging = true;

    this.onDownPosition.x = event.clientX;
    this.onDownPosition.y = event.clientY;
  }

  public onPointerUp(event: any) {
    this.isDragging = false;

    if (this.transformControl) {
      this.transformControl.enabled = true;
    }

    this.onUpPosition.x = event.clientX;
    this.onUpPosition.y = event.clientY;

    if (
      this.transformControl &&
      this.onDownPosition.distanceTo(this.onUpPosition) === 0
    ) {
      this.transformControl.detach();
    }
  }

  public onPointerMove(event: any) {
    if (this.isDragging) {
      return;
    }

    this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(this.pointer, this.camera);

    const intersects = this.raycaster.intersectObjects(
      this.interactables,
      true
    );

    if (intersects.length > 0) {
      const [obj] = intersects;

      if (
        !this.transformControl!.object ||
        obj.object.parent !== this.transformControl!.object!.parent
      ) {
        this.transformControl!.attach(obj.object.parent!);
      }
    }
  }

  public onClick(event: any) {
    this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(this.pointer, this.camera);

    const intersects = this.raycaster.intersectObjects(
      this.interactables,
      true
    );

    emitter.emit('sensor-unfocus');

    if (intersects.length > 0) {
      const [obj] = intersects;
      const mesh = (obj.object || {}) as ExtendedLineSegments;

      if (mesh && mesh.data) {
        mesh.material = new THREE.LineBasicMaterial({
          color: 0xffcd00,
        });

        emitter.emit('sensor-focus', {
          ...mesh.data,
          positionX: mesh.parent!.position.x,
          positionY: mesh.parent!.position.y,
          positionZ: mesh.parent!.position.z,
          id: mesh.uuid,
        });
      }
    }

    this.animate();
  }

  public buildEditor() {
    const {
      color,
      editor,
      floorplan,
      mapImages,
      sensors,
      showBoundaries,
      showSensors,
      showRacks,
    } = this.props;

    const container = document.getElementById('editor');

    this.scene.clear();
    if (renderer) {
      this.interactables = [];
      renderer.clear();
    }

    this.scene.add(new THREE.AmbientLight(0xf0f0f0));
    const light = new THREE.SpotLight(0xffffff, 1.5);
    light.position.set(0, 1500, 200);
    light.angle = Math.PI * 0.2;
    light.castShadow = true;
    light.shadow.camera.near = 1;
    light.shadow.camera.far = 200;
    light.shadow.bias = -0.000222;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    this.scene.add(light);

    if (editor) {
      this.helper = new THREE.GridHelper(200, 100);
      this.helper.position.z = -0.5;
      this.helper.rotateX(Math.PI / 2);
      this.scene.add(this.helper);
    }

    if (renderer && !this.transformControl) {
      container!.addEventListener('pointerdown', this.onPointerDown);
      container!.addEventListener('pointerup', this.onPointerUp);
      container!.addEventListener('pointermove', this.onPointerMove);

      this.scene.background = new THREE.Color(0xffffff);
      this.camera.position.set(0, 150, 100);

      renderer.setPixelRatio(window.devicePixelRatio);

      renderer.setSize(container!.clientWidth, container!.clientHeight);
      renderer.shadowMap.enabled = true;
      renderer.domElement.addEventListener('click', this.onClick);

      container!.appendChild(renderer.domElement);

      this.controls = new OrbitControls(this.camera, renderer.domElement);
      this.controls.addEventListener('change', this.animate);

      this.transformControl = new TransformControls(
        this.camera,
        renderer.domElement
      );

      this.transformControl.addEventListener('change', this.animate);
      this.transformControl.addEventListener(
        'dragging-changed',
        (event: any) => {
          this.controls!.enabled = !event.value;

          if (this.controls!.enabled) {
            this.update();
          } else {
            this.animate();
          }

          const plans = this.interactables
            .filter((i) => i.children && i.children[0])
            .map((i) => ({
              ...i.children[0].data,
              id: i.children[0].uuid,
              positionX: i.position.x,
              positionY: i.position.y,
              positionZ: i.position.z,
            }));

          emitter.emit('sensors-changed', plans);
        }
      );
    }
    this.scene.add(this.camera);
    this.scene.add(this.transformControl!);

    loadImage(floorplan.image || '', (err, image) => {
      const { plan } = this.props;

      this.image = image;

      if (!color && mapImages) {
        this.image = mapImages.backgroundImageBWObj;
      }

      const scale = floorplan.scale || 1;

      if (editor) {
        // Merge obstacles
        const heatObstacles = (floorplan.obstacles || []).concat(
          floorplan.boundaries || []
        );
        // Merge attenuations
        const heatAttenuations = floorplan.racks || [];
        this.heatmap = new Heatmap(
          this.image!.width,
          this.image!.height,
          scale,
          heatObstacles.map((o: any) => ({
            ...o,
            coordinates: o.coordinates.map((c: any) =>
              transformMeters([c[0], c[1]], floorplan.transformationMatrix)
            ),
          })),
          heatAttenuations.map((o: any) => ({
            ...o,
            coordinates: o.coordinates.map((c: any) =>
              transformMeters([c[0], c[1]], floorplan.transformationMatrix)
            ),
          }))
        );
      }

      if (showBoundaries) {
        this.addCloseFeature(
          (floorplan.obstacles || []).filter(
            (o: any) => o.meta.type === 'wall'
          ),
          `${PRIMARY_COLOR}`,
          5
        );
        this.addCloseFeature(floorplan.boundaries || [], '#444', 1, false);
        this.addCloseFeature(
          (floorplan.obstacles || []).filter(
            (o: any) => o.meta.type !== 'wall'
          ),
          PRIMARY_COLOR,
          2
        );
        this.addCloseFeature(
          floorplan.exteriorBoundaries || [],
          '#666',
          0.3,
          false
        );
        this.addCloseFeature(floorplan.landmarks || [], '#ffc300', 0.5);
      }
      if (showRacks) {
        this.addCloseFeature(floorplan.racks || [], '#008000', 3);
      }
      if (showSensors) {
        (sensors || [])
          .map((s: any) => ({
            ...s,
            position: transformMeters(
              [(s.position || [])[0] || 0, (s.position || [])[1] || 0],
              floorplan!.transformationMatrix
            ),
          }))
          .map((s: any) => ({
            id: s.id,
            identifier: s.physicalAddress,
            positionX: (s.position || [])[0] || 0,
            positionY: (s.position || [])[1] || 0,
            positionZ: 3,
          }))
          .forEach((s: any) => this.drawSensor(s, false));
      }

      this.interactables.forEach((i) => {
        this.scene.remove(i);
      });
      this.interactables = [];

      ((plan || {}).sensorPositions || []).forEach((p: any) =>
        this.drawSensor(p)
      );

      this.drawBackground();
      this.animate();
    });
  }

  public drawBackground() {
    const { color, floorplan, plan, overlayImage, showFloorplan, updateStats } =
      this.props;

    const scale = floorplan.scale || 1;

    if (this.image && overlayImage) {
      const canvasBg = createCanvas(this.image!.width, this.image!.height);
      const ctxBg = canvasBg.getContext('2d') as any;
      ctxBg.fillStyle = '#ffffff';
      ctxBg.fillRect(0, 0, this.image!.width, this.image!.height);
      if (showFloorplan) {
        ctxBg.drawImage(
          this.image! as any,
          0,
          0,
          this.image!.width,
          this.image!.height
        );
      }

      ctxBg.drawImage(
        overlayImage as any,
        0,
        0,
        this.image!.width,
        this.image!.height
      );
      const texture = new THREE.CanvasTexture(ctxBg.canvas as any);
      texture.needsUpdate = true;
      const img = new THREE.MeshBasicMaterial({ map: texture });
      const plane = new THREE.Mesh(
        new THREE.PlaneGeometry(
          this.image!.width / scale,
          this.image!.height / scale
        ),
        img
      );
      const x = this.image!.width / scale / 2;
      const y = this.image!.height / scale / 2;
      plane.position.x = x;
      plane.position.y = y;
      if (this.helper) {
        this.helper.position.x = x;
        this.helper.position.y = y;
      }
      this.controls!.center = new THREE.Vector3(x, y, 0);

      if (this.lastPlan) {
        this.scene.remove(this.lastPlan);
      }
      this.scene.add(plane);
      this.lastPlan = plane;
    }

    if (this.image && this.heatmap) {
      const positions = this.interactables
        .filter(
          (mesh) => mesh.children && mesh.children[0] && mesh.children[0].data
        )
        .map((mesh) => [mesh.position.x, mesh.position.y] as [number, number]);

      const canvasBg = createCanvas(this.image!.width, this.image!.height);
      const ctxBg = canvasBg.getContext('2d') as any;
      ctxBg.fillStyle = '#ffffff';
      ctxBg.fillRect(0, 0, this.image!.width, this.image!.height);
      if (color) {
        ctxBg.drawImage(
          this.image! as any,
          0,
          0,
          this.image!.width,
          this.image!.height
        );
      }

      this.heatmap.draw(positions, plan || ({} as IPlan)).then((result) => {
        ctxBg.drawImage(result as any, 0, 0, this.image!.width, this.image!.height);

        const texture = new THREE.CanvasTexture(ctxBg.canvas as any);
        texture.needsUpdate = true;
        const img = new THREE.MeshBasicMaterial({ map: texture });
        const plane = new THREE.Mesh(
          new THREE.PlaneGeometry(
            this.image!.width / scale,
            this.image!.height / scale
          ),
          img
        );
        const x = this.image!.width / scale / 2;
        const y = this.image!.height / scale / 2;
        plane.position.x = x;
        plane.position.y = y;
        if (this.helper) {
          this.helper.position.x = x;
          this.helper.position.y = y;
        }
        this.controls!.center = new THREE.Vector3(x, y, 0);

        if (this.lastPlan) {
          this.scene.remove(this.lastPlan);
        }
        this.scene.add(plane);
        this.lastPlan = plane;

        if (updateStats) {
          updateStats(
            this.heatmap!.coverage,
            this.heatmap!.space,
            this.heatmap!.quality
          );
        }
      });
    }
  }

  public update() {
    this.drawBackground();
    this.animate();
  }

  public animate() {
    requestAnimationFrame(() => {
      if (renderer) {
        renderer.render(this.scene, this.camera);
      }
    });
  }

  public addCloseFeature(
    features: IDrawDefinition[],
    color: string,
    height = 5,
    addTopPlane = true
  ) {
    const { floorplan } = this.props;

    features
      .map((b) => ({
        ...b,
        coordinates: b.coordinates.map((c) =>
          transformMeters([c[0], c[1]], floorplan.transformationMatrix)
        ),
      }))
      .forEach((feature) => {
        let firstPoint: any;
        let lastPoint: any;

        const t = new THREE.Shape();
        const group = new THREE.Group();
        feature.coordinates.forEach((f) => {
          if (!firstPoint) {
            firstPoint = f;
          }
          const b = new THREE.Shape();
          t.moveTo(f[0], f[1]);
          b.moveTo(f[0], f[1]);
          if (lastPoint) {
            t.lineTo(f[0], f[1]);
            b.lineTo(lastPoint[0], lastPoint[1]);
            const bg = new THREE.ExtrudeBufferGeometry([b], {
              steps: 1,
              depth: height,
              bevelEnabled: false,
              curveSegments: 32,
            });
            const mesh = new THREE.Mesh(
              bg,
              new THREE.MeshStandardMaterial({
                color,
                opacity: 0.4,
                transparent: true,
              })
            );
            const geometry = new THREE.EdgesGeometry(mesh.geometry);
            const material = new THREE.LineBasicMaterial({ color });
            const wireframe = new THREE.LineSegments(geometry, material);

            if (addTopPlane === false) {
              group.add(mesh);
            }
            group.add(wireframe);
          }
          lastPoint = f;
        });

        const b = new THREE.Shape();
        b.moveTo(lastPoint[0], lastPoint[1]);
        t.lineTo(firstPoint[0], firstPoint[1]);
        b.lineTo(firstPoint[0], firstPoint[1]);
        const bg = new THREE.ExtrudeBufferGeometry([b], {
          steps: 1,
          depth: height,
          bevelEnabled: false,
          curveSegments: 32,
        });
        const mesh = new THREE.Mesh(
          bg,
          new THREE.MeshStandardMaterial({
            color,
            opacity: 0.4,
            transparent: true,
          })
        );
        const geometry = new THREE.EdgesGeometry(mesh.geometry);
        const material = new THREE.LineBasicMaterial({ color });
        const wireframe = new THREE.LineSegments(geometry, material);

        if (addTopPlane === false) {
          group.add(mesh);
        }
        group.add(wireframe);

        if (addTopPlane) {
          const tbg = new THREE.ExtrudeBufferGeometry([t], {
            steps: 1,
            depth: height,
            bevelEnabled: false,
            curveSegments: 32,
          });

          const tmesh = new THREE.Mesh(
            tbg,
            new THREE.MeshStandardMaterial({
              color,
              opacity: 0.6,
              transparent: true,
            })
          );

          group.add(tmesh);
        }
        this.scene.add(group);
      });
  }

  public drawSensor(sensor: SensorSettings, interactable = true) {
    const geometry = new THREE.OctahedronGeometry(1, 0);
    const material = new THREE.MeshBasicMaterial({
      color: 0x000000,
      opacity: 0.6,
      transparent: true,
    });
    const sphere = new THREE.Mesh(geometry, material);
    const wireframe: ExtendedLineSegments = new THREE.LineSegments(
      geometry,
      new THREE.MeshBasicMaterial({ color: '#ff0f00' })
    ) as any;

    wireframe.color = '#ff0f00';
    wireframe.data = sensor;
    sphere.position.set(0, 0, 0);
    sphere.translateX(sensor.positionX);
    sphere.translateY(sensor.positionY);
    sphere.translateZ(sensor.positionZ);
    sphere.add(wireframe);
    this.scene.add(sphere);
    if (interactable) {
      this.interactables.push(sphere);
    }
  }

  public render() {
    return <div id="editor" className="editor" />;
  }
}

export default RTLSPlannerMap;
