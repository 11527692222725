import { Color, Path, Point, PointText, Matrix } from 'paper';
import { Component } from 'react';

import IncompleteFloorplan, { ItemLocation } from '@models/IncompleteFloorplan';
import { transformMetersToPixels } from '@dashboard_utils/index';
import {
  ICircleFeature,
  IToolEvent,
  IHitEvent,
  MapFeature,
  IFeatureInfo,
} from '../../../types';
import { defaultTransformationMatrix } from '../../../consts';
import MapImages from '../../MapImages';
import Paper from '../../Paper';
import { transformPixelsToMeters } from '../../../../../../utils';

interface IElement {
  path: ICircleFeature;
  sensorText: paper.PointText;
}

interface IProps {
  id: string;
  floorplan: IncompleteFloorplan;
  editFeature?: MapFeature;
  mapImages: MapImages;
  paper: Paper;
  locations?: ItemLocation[];
  onClick?: ([x, y]: [number, number], action: 'add' | 'remove') => void;
  updateEditFeature: (id: string, feature?: MapFeature) => void;
}

class ItemLocations extends Component<IProps> {
  private elements: IElement[] = [];

  private dryClick = true;
  private postitionClick?: IFeatureInfo;

  constructor(props: IProps) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseDrag = this.handleMouseDrag.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }

  public componentDidMount() {
    const { paper } = this.props;
    const { tool } = paper;

    this.load();

    tool.on('mousedown', this.handleMouseDown);
    tool.on('mousedrag', this.handleMouseDrag);
    tool.on('mousemove', this.handleMouseMove);
    tool.on('mouseup', this.handleMouseUp);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { editFeature, locations } = this.props;

    if (
      JSON.stringify(prevProps.locations || []) !==
        JSON.stringify(locations || []) ||
      prevProps.editFeature !== editFeature
    ) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    const { paper } = this.props;
    const { tool } = paper;

    this.clear();

    tool.removeListener('mousedown', this.handleMouseDown);
    tool.removeListener('mousedrag', this.handleMouseDrag);
    tool.removeListener('mousemove', this.handleMouseMove);
    tool.removeListener('mouseup', this.handleMouseUp);
  }

  public handleMouseDown(event: IToolEvent) {
    const { paper } = this.props;

    const [hit]: IHitEvent[] = paper.scope.project!.hitTestAll(event.point!, {
      fill: false,
      match: (h: IHitEvent) => h.type === 'segment',
      segments: true,
      stroke: false,
      tolerance: 5,
    });

    this.dryClick =
      !hit ||
      (!!hit &&
        !!hit.item &&
        !!hit.item.featureInfo &&
        hit.item.featureInfo.type !== 'itemLocation');
    this.postitionClick = !!hit && !!hit.item && !!hit.item.featureInfo && hit.item.featureInfo.type === 'itemLocation'
     ? hit.item.featureInfo : undefined;
  }

  public handleMouseDrag() {
    this.dryClick = false;
  }

  public handleMouseMove() {
    this.dryClick = false;
  }

  public handleMouseUp(event: IToolEvent) {
    const { editFeature, floorplan, id, mapImages, onClick, updateEditFeature } =
      this.props;

    const point = new Matrix(1, 0, 0, -1, 0, mapImages.height).transform(
      new Point([event.point!.x || 0, event.point!.y || 0])
    );

    if (!!this.postitionClick && onClick) {
      return onClick(this.postitionClick!.props!.position, 'remove');
    }

    // If click is dry (no drag or move) and is not targeting any feature
    // Used to add/remove geo locations
    if (this.dryClick === true) {
      if (onClick) {
        return onClick(
          transformPixelsToMeters(
            [point.x, point.y],
            floorplan.transformationMatrix || defaultTransformationMatrix,
            floorplan.scale || 1
          ),
          'add'
        );
      }

      const path = new Path.Circle(
        new Point(
          transformMetersToPixels(
            [point.x, point.y],
            floorplan.transformationMatrix || defaultTransformationMatrix,
            floorplan.scale || 1
          )
        ),
        (floorplan.scale || 20) / 4
      ) as ICircleFeature;
      path.fillColor = new Color('#00A6EB');

      if (
        !editFeature ||
        !editFeature.featureInfo ||
        editFeature.featureInfo.type !== 'itemLocation'
      ) {
        path.featureInfo = {
          id: 'item_new',
          props: {
            position: transformPixelsToMeters(
              [point.x, point.y],
              floorplan.transformationMatrix || defaultTransformationMatrix,
              floorplan.scale || 1
            ),
          },
          title: 'New Item Location',
          type: 'itemLocation',
        };
      } else {
        path.featureInfo = {
          ...editFeature.featureInfo,
          props: {
            ...editFeature.featureInfo.props,
            position: transformPixelsToMeters(
              [point.x, point.y],
              floorplan.transformationMatrix || defaultTransformationMatrix,
              floorplan.scale || 1
            ),
          },
        };
      }

      path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

      updateEditFeature(id, path as MapFeature);
    }
  }

  public load() {
    const { floorplan, locations, mapImages } = this.props;

    (locations || []).forEach((location, index) => {
      const point = new Point(
        transformMetersToPixels(
          location.position,
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        )
      );

      const path = new Path.Circle(
        point,
        (floorplan.scale || 20) / 4
      ) as ICircleFeature;
      path.fillColor = new Color('#00A6EB');
      path.featureInfo = {
        id: `item_${String(index)}`,
        props: {
          id: location.id,
          reference: location.reference,
          position: location.position,
        },
        title: `Item Location #${location.reference}`,
        type: 'itemLocation',
      };
      path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

      const sensorText = new PointText(
        new Point([point.x - 10, point.y + 10]).transform(
          new Matrix(1, 0, 0, -1, 0, mapImages.height)
        )
      );
      sensorText.content = location.reference;
      sensorText.fillColor = new Color('#00A6EB');
      sensorText.fontSize = '16px';
      sensorText.fontWeight = 'bold';

      this.elements.push({ path, sensorText });
    });
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((element) => {
      element.path.remove();
      element.sensorText.remove();
    });

    this.elements = [];
  }

  public render() {
    return null;
  }
}

export default ItemLocations;
