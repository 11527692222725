import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import CameraIcon from '@mui/icons-material/Camera';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import React, { Component } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import LoadingButton from '@app/common/LoadingButton';
import { Box } from '@mui/system';
import Loading from '@app/common/Loading';
import FetchError from '@models/FetchError';
import { ICreateTask, IUpdateTask } from '@actions/index';
import { Task, TaskPriority, TaskStatus, TaskType } from '@models/Task';
import { Assets } from '@models/Asset';
import { Employees } from '@models/Employee';
import { Warehouses } from '@models/Warehouse';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import { Date, timeZone } from '@dashboard_utils/index';
import { Teams } from '@models/Team';
import { Zones } from '@models/Zone';

interface IProps {
  createTask: (properties: ICreateTask) => void;
  updateTask: (properties: IUpdateTask) => void;
  fetchEmployees: (shallow?: boolean) => void;
  fetchTeams: (shallow?: boolean) => void;
  closeForm: () => void;
  intl: IntlShape;
  loading: boolean;
  error?: FetchError;
  data?: Task;
  employeeMap: Employees;
  teamMap: Teams;
  assetMap: Assets;
  warehouses: Warehouses;
  floorplans: IncompleteFloorplans;
  zones: Zones;
}

export interface IState {
  id?: string;
  warehouseId: string;
  floorplanId: string;
  zoneId?: string;
  name: string;
  notes: string;
  employees: string[];
  teams: string[];
  assets: string[];
  subtasks: { active: boolean, name: string }[];
  subtask: string;
  positionX?: number;
  positionY?: number;
  positionZ?: number;
  priority: TaskPriority;
  status: TaskStatus;
  type: TaskType;
  dueDate: string;
  pictures: string[];
  submitted: boolean;
}

class TaskForm extends Component<IProps, IState> {
  private interval?: NodeJS.Timer;

  constructor(props: IProps) {
    super(props);

    const { data, warehouses } = this.props;

    const warehouseId = (data || {}).warehouseId || '';
    const warehouseTz = warehouses[warehouseId]
      ? warehouses[warehouseId].timezone
      : timeZone;

    this.state = {
      id: (data || {}).id,
      warehouseId,
      floorplanId: (data || {}).floorplanId || '',
      zoneId: (data || {}).zoneId,
      name: (data || {}).name || '',
      notes: (data || {}).notes || '',
      employees: (data || {}).employees || [],
      teams: (data || {}).teams || [],
      assets: (data || {}).assets || [],
      subtasks: (data || {}).subtasks || [],
      subtask: '',
      positionX: ((data || {}).positionX == 0 ? 0 : (data || {}).positionX) || undefined,
      positionY: ((data || {}).positionY == 0 ? 0 : (data || {}).positionY) || undefined,
      positionZ: ((data || {}).positionZ == 0 ? 0 : (data || {}).positionZ) || undefined,
      priority: (data || {}).priority || TaskPriority.NORMAL,
      status: (data || {}).status || TaskStatus.PENDING,
      type: (data || {}).type || TaskType.PICKING,
      dueDate: (data || {}).dueDate || new Date(warehouseTz).toISOString(),
      pictures: (data || {}).pictures || [],
      submitted: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDueDateChange = this.handleDueDateChange.bind(this);
    this.handleSubtaskChange = this.handleSubtaskChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
    this.openCamera = this.openCamera.bind(this);
    this.deletePicture = this.deletePicture.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();
  }

  public componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  public handleChange(e: any) {
    const { name } = e.target;

    const value: string | boolean =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    const newState: any = {};
    newState[name] = value || '';

    this.setState(newState as Pick<IState, 'name'>);
  }

  public handleDueDateChange(dueDate: Date | null | undefined) {
    if (dueDate) {
      this.setState({ dueDate: dueDate.toUTCString() });
    }
  }

  public handleSubtaskChange(e: any) {
    const { subtasks } = this.state;

    if (e.keyCode === 13) {
      this.setState({ subtask: '', subtasks: [...subtasks, { name: e.target.value, active: false }] });

      return;
    }

    this.setState({ subtask: e.target.value });
  }

  public handleSubtaskDoneChange(index: number) {
    const { subtasks } = this.state;

    const st = [...subtasks];
    st[index].active = !st[index].active;

    this.setState({ subtasks: st });
  }

  public handleCancel() {
    const { closeForm } = this.props;

    closeForm();
  }

  public handleSubmit() {
    const {
      id,
      warehouseId,
      floorplanId,
      zoneId,
      name,
      notes,
      employees,
      teams,
      assets,
      subtasks,
      positionX,
      positionY,
      positionZ,
      priority,
      status,
      type,
      dueDate,
      pictures,
    } = this.state;
    const { createTask, updateTask, intl } = this.props;

    this.setState({ submitted: true }, () => {
      if (id) {
        return updateTask({
          id,
          warehouseId,
          floorplanId,
          zoneId,
          name,
          notes: notes || undefined,
          priority,
          status,
          type,
          employees,
          teams,
          assets,
          subtasks,
          positionX,
          positionY,
          positionZ,
          dueDate,
          pictures,
          intl,
        });
      }

      createTask({
        warehouseId,
        floorplanId,
        zoneId,
        name,
        notes: notes || undefined,
        priority,
        status,
        type,
        employees,
        teams,
        assets,
        subtasks,
        positionX,
        positionY,
        positionZ,
        dueDate,
        pictures,
        intl,
      });
    });
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const { fetchEmployees, fetchTeams } = this.props;

    fetchEmployees(shallow);
    fetchTeams(shallow);
  }

  public openCamera() {
    const { pictures } = this.state;

    if (this.interval) {
      clearInterval(this.interval);
    }

    // @ts-ignore
    if (window.MessageInvoker) {
      // @ts-ignore
      window.MessageInvoker.postMessage('picture');
    }

    this.interval = setInterval(() => {
      // @ts-ignore
      const data = window.nativeData;
  
      if (data) {
        // @ts-ignore
        window.nativeData = undefined;
  
        clearInterval(this.interval);
  
        this.setState({
          pictures: pictures.concat(data),
        });
      }
    }, 500);
  }

  public deletePicture(i: number) {
    const { pictures } = this.state;

    pictures.splice(i, 1);

    this.setState({ pictures });
  }

  public render() {
    const { loading, data, employeeMap, assetMap, teamMap, floorplans, warehouses, zones } = this.props;
    const {
      id,
      warehouseId,
      floorplanId,
      zoneId,
      name,
      notes,
      employees,
      teams,
      assets,
      subtasks,
      subtask,
      priority,
      status,
      type,
      dueDate,
      pictures,
    } = this.state;

    const error = name.length === 0 ||
      warehouseId.length === 0 ||
      floorplanId.length === 0;

    const whs = Object.values(warehouses);
    const fps = Object.values(floorplans).filter(
      (fp) => fp.warehouseId === warehouseId
    );
    const zns = Object.values(zones).filter(
      (z) => z.floorplanId === floorplanId
    );

    const buttonDisabled = error;

    const warehouseTz = warehouses[warehouseId]
      ? warehouses[warehouseId].timezone
      : timeZone;

    return (
      <Dialog open fullWidth maxWidth="sm">
        <DialogTitle>
          {id ? (
            <FormattedMessage
              id="dashboard.forms.taskform.title_edit"
              defaultMessage="Edit Task {name}"
              values={{ name: (data || {}).name }}
            />
          ) : (
            <FormattedMessage
              id="dashboard.forms.taskform.title_create"
              defaultMessage="Add Task"
            />
          )}
        </DialogTitle>
        <DialogContent>
          <Loading loading={loading}>
            <FormControl required fullWidth margin="normal">
              <InputLabel htmlFor="warehouseId">
                <FormattedMessage
                  id="dashboard.forms.taskform.label_warehouse"
                  defaultMessage="Warehouse"
                />
              </InputLabel>
              <Select
                id="warehouseId"
                variant="standard"
                name="warehouseId"
                value={warehouseId}
                onChange={this.handleChange}
              >
                {warehouses &&
                  whs.map((w) => (
                    <MenuItem key={w.id} value={w.id}>
                      {w.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl required fullWidth margin="normal">
              <InputLabel htmlFor="floorplanId">
                <FormattedMessage
                  id="dashboard.forms.taskform.label_floorplan"
                  defaultMessage="Floor plan"
                />
              </InputLabel>
              <Select
                id="floorplanId"
                variant="standard"
                name="floorplanId"
                value={floorplanId}
                onChange={this.handleChange}
              >
                {warehouses &&
                  fps.map((fp) => (
                    <MenuItem key={fp.id} value={fp.id}>
                      {fp.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="zoneId">
                <FormattedMessage
                  id="dashboard.forms.taskform.label_zone"
                  defaultMessage="Zone"
                />
              </InputLabel>
              <Select
                id="zoneId"
                variant="standard"
                name="zoneId"
                value={zoneId}
                onChange={this.handleChange}
              >
                {zns.map((zone) => (
                  <MenuItem key={zone.id} value={zone.id}>
                    {zone.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl required fullWidth margin="normal">
              <TextField
                name="name"
                variant="standard"
                onChange={this.handleChange}
                value={name}
                label={
                  <FormattedMessage
                    id="dashboard.forms.taskform.label_name"
                    defaultMessage="Task name"
                  />
                }
              />
              <FormHelperText>
                <FormattedMessage
                  id="dashboard.forms.taskform.field_name_helper"
                  defaultMessage="A task name must be specified"
                />
              </FormHelperText>
            </FormControl>
            <FormControl required fullWidth margin="normal">
              <TextField
                name="notes"
                variant="standard"
                onChange={this.handleChange}
                value={notes}
                rows={3}
                multiline
                label={
                  <FormattedMessage
                    id="dashboard.forms.taskform.label_notes"
                    defaultMessage="Notes"
                  />
                }
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="employees" variant="standard">
                <FormattedMessage
                  id="dashboard.forms.taskform.label_employees"
                  defaultMessage="Employees"
                />
              </InputLabel>
              <Select
                labelId="employees"
                multiple
                onChange={this.handleChange}
                name="employees"
                variant="standard"
                value={employees}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={(employeeMap[value] || {}).name || ''} />
                    ))}
                  </Box>
                )}
              >
                {Object.values(employeeMap).map((e) => (
                  <MenuItem
                    key={e.id}
                    value={e.id}
                  >
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="teams" variant="standard">
                <FormattedMessage
                  id="dashboard.forms.taskform.label_teams"
                  defaultMessage="Teams"
                />
              </InputLabel>
              <Select
                labelId="teams"
                multiple
                onChange={this.handleChange}
                name="teams"
                variant="standard"
                value={teams}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={(teamMap[value] || {}).name || ''} />
                    ))}
                  </Box>
                )}
              >
                {Object.values(teamMap).map((t) => (
                  <MenuItem
                    key={t.id}
                    value={t.id}
                  >
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="assets" variant="standard">
                <FormattedMessage
                  id="dashboard.forms.taskform.label_assets"
                  defaultMessage="Assets"
                />
              </InputLabel>
              <Select
                labelId="assets"
                multiple
                onChange={this.handleChange}
                name="assets"
                variant="standard"
                value={assets}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={(assetMap[value] || {}).name || ''} />
                    ))}
                  </Box>
                )}
              >
                {Object.values(assetMap).map((a) => (
                  <MenuItem
                    key={a.id}
                    value={a.id}
                  >
                    {a.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.forms.taskform.subtasks"
                      defaultMessage="Sub tasks"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subtasks
                  .map((subtask, index) => (
                    <TableRow key={index} hover={false}>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel control={
                            <Checkbox
                              checked={subtask.active || false}
                              onChange={() => this.handleSubtaskDoneChange(index)}
                            />
                          } label={subtask.name} />
                        </FormGroup>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <FormControl required fullWidth margin="normal">
                      <TextField
                        name="subtask"
                        variant="standard"
                        onChange={this.handleSubtaskChange}
                        onKeyUp={this.handleSubtaskChange}
                        value={subtask}
                        label={
                          <FormattedMessage
                            id="dashboard.forms.taskform.label_subtask_name"
                            defaultMessage="Sub task name"
                          />
                        }
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
            <FormControl fullWidth margin="normal">
              <InputLabel id="priority" variant="standard">
                <FormattedMessage
                  id="dashboard.forms.taskform.label_priority"
                  defaultMessage="Priority"
                />
              </InputLabel>
              <Select
                labelId="priority"
                onChange={this.handleChange}
                name="priority"
                variant="standard"
                value={priority}
              >
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="normal">Normal</MenuItem>
                <MenuItem value="high">High</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="status" variant="standard">
                <FormattedMessage
                  id="dashboard.forms.taskform.label_status"
                  defaultMessage="Status"
                />
              </InputLabel>
              <Select
                labelId="status"
                onChange={this.handleChange}
                name="status"
                variant="standard"
                value={status}
              >
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="progress">Progress</MenuItem>
                <MenuItem value="done">Done</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="type" variant="standard">
                <FormattedMessage
                  id="dashboard.forms.taskform.label_type"
                  defaultMessage="Type"
                />
              </InputLabel>
              <Select
                labelId="type"
                onChange={this.handleChange}
                name="type"
                variant="standard"
                value={type}
              >
                <MenuItem value="picking">Picking</MenuItem>
                <MenuItem value="maintenance">Maintenance</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <DateTimePicker
                label={
                  <FormattedMessage
                    id="dashboard.forms.taskform.label_duedate"
                    defaultMessage="Due Date"
                  />
                }
                value={new Date(
                  warehouseTz,
                  dueDate || new Date(warehouseTz).getTime()
                )}
                maxDate={
                  new Date(
                    warehouseTz,
                    new Date(
                      warehouseTz,
                      dueDate || new Date(warehouseTz)
                    )
                  ) as any
                }
                onChange={this.handleDueDateChange}
                slotProps={{
                  textField: { variant: 'standard' }
                }}
                ampm={false}
              />
            </FormControl>
            <Box>
              {pictures.length ? (
                <Box p={2} style={{
                  overflow: 'hidden',
                  border: '1px solid #C1C1C1',
                  backgroundColor: '#FCFCFC',
                }}>
                  {pictures.map((item, index) => (
                    <Box m={1} key={index} style={{
                      position: 'relative',
                      float: 'left',
                      backgroundColor: '#FFF',
                      border: '1px solid #E1E1E1',
                    }}>
                      <IconButton
                        aria-label="close"
                        onClick={() => this.deletePicture(index)}
                        style={{ position: 'absolute', top: '-8px', right: '-8px' }}
                      >
                        <CloseIcon color="error" />
                      </IconButton>
                      <img src={item} alt="Image" style={{ maxWidth: '100%', maxHeight: '125px' }} />
                    </Box>
                  ))}
                </Box>
              ) : null}
              {
                // @ts-ignore
                !!window.MessageInvoker && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <IconButton onClick={this.openCamera}>
                      <CameraIcon />
                    </IconButton>
                  </div>
                )
              }
            </Box>
          </Loading>
        </DialogContent>
        <DialogActions>
          <Box pr={1}>
            <Button onClick={this.handleCancel}>
              <FormattedMessage
                id="dashboard.forms.taskform.button_cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </Box>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color="secondary"
            disabled={buttonDisabled}
            loading={loading}
          >
            {!id ? (
              <FormattedMessage
                id="dashboard.forms.taskform.button_create"
                defaultMessage="Create Task"
              />
            ) : (
              <FormattedMessage
                id="dashboard.forms.taskform.button_update"
                defaultMessage="Update Task"
              />
            )}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(TaskForm);
