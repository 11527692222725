import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';

import { ILayer } from './types';

export const defaultTransformationMatrix = [
  [1, 0, 0],
  [0, 1, 0],
  [0, 0, 1],
] as [
  [number, number, number],
  [number, number, number],
  [number, number, number]
];

/**
 * @description Renders checkbox for layer activation/deactivation
 * @param       {string} key Layer key
 */
export const renderLayerToggle = (
  layer: ILayer,
  handleChange: (id: string) => void
) => (
  <FormControlLabel
    key={layer.id}
    label={
      <div className="layer-label">
        <div className="layer-style-wrapper">
          <div className={`layer-style ${layer.styleClass || ''}`} />
        </div>
        <span>{layer.label}</span>
      </div>
    }
    control={
      <Checkbox
        color={
          layer.primary === true || layer.secondary !== true
            ? 'primary'
            : 'secondary'
        }
        checked={layer.active || false}
        disabled={layer.disabled || false}
        onChange={() => handleChange(layer.id)}
      />
    }
  />
);
