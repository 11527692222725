import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  confirmDialog,
  createAssetTicketComment,
  CreateAssetTicketComment,
  fetchAssetTicket,
  DeleteAssetTicketOperation,
  deleteAssetTicketOperation,
} from '@actions/index';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { withRouter } from '@app/utils/withRouter';
import Asset from '@models/Asset';
import AssetTicket from '@models/AssetTicket';
import { IAppState } from '@reducers/index';
import { getActiveLanguage, getSelectedLocale } from '@selectors/app';
import { getAsset, getCompleteAsset } from '@selectors/assets';
import TicketDashboard from './TicketDashboard';

interface IProps {
  router: any;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  asset: getCompleteAsset(
    state.warehouses.warehouses,
    state.floorplans.floorplans,
    getAsset(state, props.router.params.id) || ({} as Asset)
  ),
  language: getActiveLanguage(state),
  locale: getSelectedLocale(state),
  ticket: state.assets.tickets.ticket || ({} as AssetTicket),
  loading: state.assets.tickets.loadingTicket,
  commentLoading: state.assets.tickets.ticketCommentForm.loading,
  commentError: state.assets.tickets.ticketCommentForm.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  fetchAssetTicket: (ticketId: string) =>
    dispatch(fetchAssetTicket.request({ ticketId })),
  createAssetTicketComment: (properties: CreateAssetTicketComment) =>
    dispatch(createAssetTicketComment.request(properties)),
  deleteAssetTicketOperation: (properties: DeleteAssetTicketOperation) =>
    dispatch(deleteAssetTicketOperation.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TicketDashboard));
