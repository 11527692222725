import { connect } from 'react-redux';
import React, { FunctionComponent, lazy } from 'react';

import Asset from '@models/Asset';
import { IAppState } from '@reducers/index';

interface IProps {
  assetFormOpen: boolean;
  assetFormData?: Asset;
}

const AssetForm = lazy(() => import('../../../forms/AssetForm'));

const FormInjector: FunctionComponent<IProps> = ({
  assetFormOpen,
  assetFormData = undefined,
}: IProps) => (assetFormOpen ? <AssetForm data={assetFormData} /> : null);

const mapStateToProps = (state: IAppState) => ({
  assetFormData: state.assets.form.data,
  assetFormOpen: state.assets.form.active,
});

export default connect(mapStateToProps)(FormInjector);
