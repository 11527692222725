import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  activateAccount,
  changeAccountPassword,
  confirmDialog,
  fetchAccounts,
  IActivateAccount,
  IChangeAccountPassword,
  IUpdateAccountPermissions,
  openAccountForm,
  openInviteForm,
  updateAccountPermissions,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { getAccounts, isAccountsLoading } from '@selectors/accounts';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import Accounts from './Accounts';

const mapStateToProps = (state: IAppState) => ({
  accounts: Object.values(getAccounts(state.accounts)),
  isAccountFormOpen: state.accounts.form.open,
  isinviteFormOpen: state.accounts.inviteForm.open,
  loading: isAccountsLoading(state.accounts) || state.accounts.form.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  activateAccount: (properties: IActivateAccount) =>
    dispatch(activateAccount.request(properties)),
  changeAccountPassword: (properties: IChangeAccountPassword) =>
    dispatch(changeAccountPassword.request(properties)),
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  fetchAccounts: () => dispatch(fetchAccounts.request({})),
  openAccountForm: () => dispatch(openAccountForm()),
  openInviteForm: () => dispatch(openInviteForm()),
  updateAccountPermissions: (properties: IUpdateAccountPermissions) =>
    dispatch(updateAccountPermissions.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
