import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';

import RulesTabGrid from './RulesTabGrid';

const mapStateToProps = (state: IAppState) => ({
  filters: state.filter.filters,
});

export default connect(mapStateToProps)(RulesTabGrid);
