import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  IEventsFetch,
  IFetchRuleAlerts,
  MapWarning,
  RuleAlerts,
} from '@actions/index';
import { ISensorData } from '@api/websocket';
import DateTimeRangeSlider from '@app/common/DateTimeRangeSlider/WithHotDates';
import Map from '@app/common/FullMap';
import PlayerControls from '@app/common/PlayerControls';
import { Date, timeZone } from '@dashboard_utils/index';
import Asset from '@models/Asset';
import { DataRange } from '@models/DataRange';
import { AssetsEventPeriods } from '@models/EventActivity';
import { WarehouseWithCompleteFloorplans } from '@models/WarehouseWithCompleteFloorplans';
import { IIntervalState } from '@reducers/sensors';

import { getOffsetLeft } from '../../../utils/mapUtils';
import mapEvents from '../../../common/FullMap/Map/eventEmitter';
import TimeSlider from './EventsSlider/TimeSlider';
import AssetSlider from './EventsSlider/AssetSlider';

const STEP = 1000;
const LOADING_INTERVAL = 30 * 60 * 1000;

interface IProps {
  assets: Asset[];
  data: Record<string, ISensorData[]>;
  dataRange: DataRange;
  error?: object;
  eventIds: string[];
  eventsActivity: AssetsEventPeriods | undefined;
  intervals: Record<string, IIntervalState>;

  fetchEventsActivity: (properties: IEventsFetch) => void;
  fetchRuleAlerts: (properties: IFetchRuleAlerts) => void;
  fetchSensorsData: (
    floorplanId: string,
    assetIds: string[],
    timestamp: number
  ) => void;

  filterId: string;

  router: any;
  loading: boolean;
  locale: string;
  measurementUnits: string;
  ruleAlerts: RuleAlerts;
  warehouses: Record<string, WarehouseWithCompleteFloorplans>;

  floorplanId: string;
  warehouseId: string;
  zoneIds: string[];

  assetIds: string[];
  tags: string[];

  ruleIds: string[];
  templateIds: string[];

  endDate?: Date;
  startDate?: Date;

  theme: string;
}

interface IState {
  direction: number;
  playing: boolean;
  speed: number;
  timestamp?: number;
  showAssetActivity: boolean;
  streamHolded: boolean;
}

const handleNewMessage = (data: any) => {
  mapEvents.emit('live-data', data);
};

let playerTimestamp = 0;
let isHolded = false;
let isHoldedByError = false;
class EventsTabContent extends Component<IProps, IState> {
  private interval: any;

  private updateInterval: any;

  constructor(props: IProps) {
    super(props);

    const { dataRange, router } = this.props;

    playerTimestamp = 0;
    if (router.query.ts !== undefined) {
      playerTimestamp = router.query.ts;
    } else if (dataRange.minDate !== undefined) {
      playerTimestamp = dataRange.minDate.getTime();
    }

    this.state = {
      direction: 1,

      playing: false,
      speed: 0,
      streamHolded: false,
      timestamp: playerTimestamp - (playerTimestamp % STEP),

      showAssetActivity: false,
    };

    this.createLoop = this.createLoop.bind(this);
    this.start = this.start.bind(this);
    this.pause = this.pause.bind(this);
    this.backward = this.backward.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.forward = this.forward.bind(this);
    this.fastRewind = this.fastRewind.bind(this);
    this.fastForward = this.fastForward.bind(this);
    this.backwards = this.backwards.bind(this);
    this.getInterval = this.getInterval.bind(this);
    this.getIntervalInfo = this.getIntervalInfo.bind(this);
    this.loadIntervalIfNeeded = this.loadIntervalIfNeeded.bind(this);
    this.onShowClick = this.onShowClick.bind(this);
    this.onClick = this.onClick.bind(this);
    this.updateState = this.updateState.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  public componentDidMount() {
    this.interval = this.createLoop();
    // Every 1s updates player time (prevents frequent rendering)
    this.updateInterval = setInterval(this.updateState, STEP / 2);

    this.fetchData();
  }

  public componentDidUpdate(prevProps: any) {
    const {
      dataRange,
      router,
      floorplanId,
      warehouseId,
      endDate,
      startDate,
      warehouses,
    } = this.props;

    const warehouseTz = warehouses[warehouseId]
      ? warehouses[warehouseId].timezone
      : timeZone;

    // fetches the new data range if (wh, fp) changes
    // or ts > current interval end (for on events player page "notifications seek" with live data)
    if (
      prevProps.warehouseId !== warehouseId ||
      prevProps.floorplanId !== floorplanId ||
      (endDate &&
        (prevProps.endDate ? prevProps.endDate.getTime() : 0) !==
          endDate.getTime()) ||
      (startDate &&
        (prevProps.startDate ? prevProps.startDate.getTime() : 0) !==
          startDate.getTime()) ||
      (prevProps.router.query.ts >
        (dataRange.maxDate || new Date(warehouseTz)).getTime() &&
        (dataRange.lastUpdate || Date.now()) < Date.now() - 10 * 1000)
    ) {
      this.fetchData();
    }

    if (prevProps.router.query.ts !== router.query.ts) {
      const queryTs = router.query.ts || 0;
      const timestamp = queryTs - (queryTs % STEP);

      playerTimestamp = timestamp || 0;

      this.setTimestamp(timestamp);
    }
  }

  public componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.updateInterval);
  }

  private handleSliderChange(value: number) {
    clearInterval(this.interval);

    playerTimestamp = value;

    this.setState({ timestamp: value }, () => {
      this.interval = this.createLoop();
    });
  }

  /**
   * @description Toggles activity asset bar
   */
  public onShowClick() {
    this.setState((currentState: IState) => ({
      showAssetActivity: !currentState.showAssetActivity,
    }));
  }

  /**
   * @description Moves player indicator into the clicked position
   * @param       {any} event Mouse event
   */
  public onClick(event: any) {
    const { startDate, endDate } = this.props;

    const min = (startDate || new Date()).getTime();
    const max = (endDate || new Date()).getTime();

    const clickX = event.pageX;
    const element: any = document.getElementsByClassName(
      'event-timeslider-container'
    )[0];

    // Relative movement to the parent width and click position
    const relativeMovement =
      ((clickX - getOffsetLeft(element)) * 100) / element.clientWidth;

    const value = min + Math.ceil(((max - min) * relativeMovement) / 100);

    clearInterval(this.interval);

    playerTimestamp = value;

    this.setState({ timestamp: value }, () => {
      this.interval = this.createLoop();
    });
  }

  /**
   * @description Gets ts interval
   * @param       {number} ts
   * @returns     {string} Interval key
   */
  private getInterval(ts: number) {
    const { intervals } = this.props;

    const keys = Object.keys(intervals);
    for (let i = 0; i < keys.length; i += 1) {
      if (ts >= Number(keys[i]) && ts <= Number(keys[i]) + LOADING_INTERVAL) {
        return keys[i];
      }
    }

    return undefined;
  }

  /**
   * @description Get's ts interval info
   * @param       {number} ts
   * @returns     {IIntervalState} Is loading or not
   */
  private getIntervalInfo(ts: number) {
    const { intervals } = this.props;

    const interval = this.getInterval(ts);

    if (interval !== undefined) {
      return intervals[interval];
    }

    return { loading: true };
  }

  public setTimestamp(timestamp: number) {
    this.setState({ timestamp });
  }

  public fetchData() {
    const {
      fetchEventsActivity,
      fetchRuleAlerts,
      filterId,

      locale,
      measurementUnits,

      floorplanId,
      warehouseId,
      zoneIds,

      assetIds,
      tags,

      ruleIds,
      templateIds,

      warehouses,

      endDate,
      startDate,
    } = this.props;

    const warehouseTz = warehouses[warehouseId]
      ? warehouses[warehouseId].timezone
      : timeZone;

    fetchEventsActivity({
      filterId,

      floorplanId,
      warehouseId,
      zoneIds,

      from: (startDate || new Date(warehouseTz)).getTime(),
      to: (endDate || new Date(warehouseTz)).getTime(),

      assetIds,
      tags,

      ruleIds,
      templateIds,

      locale,
      units: measurementUnits,
    });

    fetchRuleAlerts({
      filterId,
      floorplanId,
      locale,
      ruleIds,
      templateIds,
      units: measurementUnits,
    });
  }

  /**
   * @description Checks if ts interval is loading
   * @param       {number} ts
   */
  private loadIntervalIfNeeded(ts: number) {
    const {
      assetIds,
      assets,
      fetchSensorsData,
      floorplanId,
      intervals,
      tags,
      warehouseId,
      warehouses,
    } = this.props;
    const { speed, direction } = this.state;

    const interval = this.getInterval(ts);

    let requestTs = ts + LOADING_INTERVAL * direction;
    if (interval !== undefined) {
      requestTs = Number(interval) + LOADING_INTERVAL * direction;
    } else if (direction === 1) {
      requestTs = ts;
    }

    // Will fetch data for all the assets not in the data fetched for the interval
    const assetIdsToFetch = [
      ...assetIds.filter(
        (assetId) =>
          !intervals[requestTs] ||
          (intervals[requestTs].assetIds || []).indexOf(assetId) === -1
      ),
    ];

    tags.forEach((tag) => {
      assets.forEach((asset) => {
        const assetIndex = assetIds.indexOf(asset.id);
        const tagIndex = asset.tags.indexOf(tag);

        if (
          assetIndex === -1 &&
          tagIndex !== -1 &&
          (!intervals[requestTs] ||
            (intervals[requestTs].assetIds || []).indexOf(asset.id) === -1)
        ) {
          assetIdsToFetch.push(asset.id);
        }
      });
    });

    // If the current player ts is bigger than loading margin (10s)
    // and there is no subsequent interval or asset is not in the loaded list, then loading is needed
    if (
      interval === undefined ||
      assetIdsToFetch.length > 0 ||
      (ts + 10 * (speed || 1) * 60 * 1000 >= Number(interval) &&
        (intervals[requestTs] === undefined ||
          (!intervals[requestTs].loading &&
            intervals[requestTs].erroredAttempts &&
            ((intervals[requestTs].erroredAttempts || 0) < 3 ||
              (intervals[requestTs].lastErrorTs || 0) < Date.now() - STEP))))
    ) {
      isHoldedByError = false;

      const warehouseTz = warehouses[warehouseId]
        ? warehouses[warehouseId].timezone
        : timeZone;

      // eslint-disable-next-line no-console
      console.log(
        `Requesting ${new Date(warehouseTz, requestTs).format(
          'HH:mm:ss'
        )} to ${new Date(warehouseTz, requestTs + LOADING_INTERVAL).format(
          'HH:mm:ss'
        )}`
      );

      fetchSensorsData(
        floorplanId,
        assetIdsToFetch.length === 0
          ? assets.map((a) => a.id)
          : assetIdsToFetch,
        requestTs
      );
    } else if (
      !(intervals[requestTs] || {}).loading &&
      ((intervals[requestTs] || {}).erroredAttempts || 0) > 2
    ) {
      isHoldedByError = true;
    }
  }

  public createLoop() {
    // Every 10ms retrieves data from sensor data array and sends it to the player
    return setInterval(() => {
      const { direction, playing, speed, timestamp } = this.state;
      const { endDate, startDate } = this.props;

      const playerSpeed = speed || 1;

      if (timestamp && playerTimestamp) {
        const { data } = this.props;

        if (playing === true) {
          let ts = playerTimestamp;

          if (
            ts < (startDate || new Date(timeZone, 0)).getTime() ||
            ts > (endDate || new Date(timeZone, 0)).getTime()
          ) {
            playerTimestamp = (startDate || new Date(timeZone, 0)).getTime();

            return this.setState({
              playing: false,
              timestamp: playerTimestamp,
            });
          }

          for (
            ts;
            (direction === 1 && ts <= playerTimestamp + 10 * playerSpeed) ||
            (direction === -1 && ts >= playerTimestamp - 10 * playerSpeed);
            ts += 1 * direction
          ) {
            const intervalData = data[ts];

            const intervalInfo = this.getIntervalInfo(ts);

            if (!intervalInfo || intervalInfo.loading) {
              isHolded = true;

              break;
            } else {
              isHolded = false;

              if (intervalData) {
                /**
                 * We can remove sorting for performance, that way only the first data point
                 * for the 10ms interval is used (10Hz)
                 */
                const groupedData: Record<string, ISensorData[]> = {};
                const sortedData = intervalData.sort((a: any, b: any) => {
                  if (direction === -1 && a.ts < b.ts) {
                    return 1;
                  }
                  if (direction === 1 && a.ts > b.ts) {
                    return 1;
                  }
                  if (direction === -1 && a.ts > b.ts) {
                    return -1;
                  }
                  if (direction === 1 && a.ts < b.ts) {
                    return -1;
                  }
                  return 0;
                });

                for (let i = 0; i < sortedData.length; i += 1) {
                  if (!groupedData[sortedData[i].group || '']) {
                    groupedData[sortedData[i].group || ''] = [sortedData[i]];
                  } else {
                    groupedData[sortedData[i].group || ''].push(sortedData[i]);
                  }
                }

                Object.keys(groupedData).forEach((group) =>
                  handleNewMessage(groupedData[group])
                );
              }
            }
          }

          playerTimestamp = ts;

          return this.loadIntervalIfNeeded(playerTimestamp);
        }

        return null;
      }

      return null;
    }, 10);
  }

  public updateState() {
    const { playing } = this.state;
    const { startDate } = this.props;

    const streamHolded = isHolded;

    if (playing === true && startDate !== undefined) {
      // Make sure that setted timestamp fits the selected step of the slider
      this.setState({
        playing: isHoldedByError ? false : playing,
        streamHolded,
        timestamp:
          playerTimestamp - ((playerTimestamp - startDate.getTime()) % STEP),
      });
    }
  }

  /**
   * @description Starts playing selected interval stream
   * fetchs sensor data for the fist time
   */
  public start() {
    const { startDate } = this.props;
    const { timestamp } = this.state;

    isHoldedByError = false;
    playerTimestamp =
      timestamp || (startDate || new Date(timeZone, 0)).getTime();

    this.setState({ playing: true });
  }

  /**
   * @description Pauses player
   */
  public pause() {
    this.setState({ playing: false });
  }

  /**
   * @description Moves player ts backward 60 seconds
   */
  public backward() {
    const { timestamp } = this.state;
    const { startDate } = this.props;

    if (timestamp) {
      clearInterval(this.interval);

      const ts =
        (timestamp || (startDate || new Date(timeZone, 0)).getTime()) -
          60 * 1000 <
        (startDate || new Date(timeZone, 0)).getTime()
          ? (startDate || new Date(timeZone, 0)).getTime()
          : (timestamp || (startDate || new Date(timeZone, 0)).getTime()) -
            60 * 1000;

      playerTimestamp = ts;

      this.setState({ timestamp: ts }, () => {
        this.interval = this.createLoop();
      });
    }
  }

  /**
   * @description Moves player ts forward 60 seconds
   */
  public forward() {
    const { timestamp } = this.state;
    const { endDate, startDate } = this.props;

    if (timestamp) {
      clearInterval(this.interval);

      const ts =
        (timestamp || (startDate || new Date(timeZone, 0)).getTime()) +
          60 * 1000 >
        (endDate || new Date(timeZone, 0)).getTime()
          ? (endDate || new Date(timeZone, 0)).getTime()
          : (timestamp || (startDate || new Date(timeZone, 0)).getTime()) +
            60 * 1000;

      playerTimestamp = ts;

      this.setState({ timestamp: ts }, () => {
        this.interval = this.createLoop();
      });
    }
  }

  /**
   * @description Adjusts player speed by 10x
   */
  public fastRewind() {
    const { speed } = this.state;

    if (speed > 0.25) {
      this.setState({ speed: (speed || 1) / 2 });
    }
  }

  public fastForward() {
    const { speed } = this.state;

    if (speed < 256) {
      this.setState({ speed: (speed || 1) * 2 });
    }
  }

  public backwards() {
    const { direction } = this.state;

    clearInterval(this.interval);

    this.setState({ direction: direction * -1 }, () => {
      this.interval = this.createLoop();
    });
  }

  public render() {
    const {
      assetIds,
      assets,
      error,
      eventIds,
      eventsActivity,
      filterId,
      loading,
      ruleAlerts,
      tags,
      warehouseId,
      warehouses,

      endDate,
      startDate,

      theme,
    } = this.props;

    const {
      direction,
      playing,
      speed,
      showAssetActivity,
      streamHolded,
      timestamp,
    } = this.state;

    const min = (startDate || new Date()).getTime();
    const max = (endDate || new Date()).getTime();

    const warehouseTz = warehouses[warehouseId]
      ? warehouses[warehouseId].timezone
      : timeZone;

    let warnings;
    if (error) {
      warnings = {
        ...(warnings || {}),
        // eslint-disable-next-line camelcase
        fetch_error: {
          id: 'fetch_error',
          severity: 'error',
          message: (
            <FormattedMessage
              id="dashboard.plots.analytics_issue"
              defaultMessage="Problem computing analytics"
            />
          ),
        } as MapWarning,
      };
    }
    if (ruleAlerts.returned < ruleAlerts.total) {
      warnings = {
        ...(warnings || {}),
        // eslint-disable-next-line camelcase
        player_limit_warning: {
          id: 'player_limit_warning',
          severity: 'info',
          message: (
            <FormattedMessage
              id="dashboard.rules.alerts.limitwarning"
              defaultMessage="Displaying only the last {returned} of {total} alerts for the selected period, toggle filters to display more."
              values={{
                returned: ruleAlerts.returned,
                total: ruleAlerts.total,
              }}
            />
          ),
        } as MapWarning,
      };
    }

    const ruleList = (ruleAlerts.list || []).filter(
      (ra) => eventIds.length === 0 || eventIds.indexOf(ra.templateId) !== -1
    );
    let assetsSlider;
    if (assets.length > 0) {
      assetsSlider = assets.map((a) => (
        <div key={a.id}>
          <AssetSlider
            assetName={a.name}
            color={a.color}
            eventsActivity={
              ((eventsActivity || []).find((ea) => ea.asset_id === a.id) || {})
                .periods || []
            }
            ruleAlerts={ruleList.filter(
              (ra) =>
                ra.messageInfo.assets &&
                Object.keys(ra.messageInfo.assets).indexOf(a.id) !== -1
            )}
            value={timestamp || min}
            min={min}
            max={max}
            onClick={this.onClick}
          />
        </div>
      ));
    }

    return (
      <>
        <DateTimeRangeSlider filterId={filterId} />
        {startDate !== undefined && endDate !== undefined ? (
          <>
            <Box mb={1} className="activity">
              <Map
                actions={{
                  hover: {},
                  select: {},
                }}
                filterId={filterId}
                forceDisabledLayers={['gmaps']}
                ts={timestamp}
                warnings={warnings}
              />
              {loading || (streamHolded && playing) ? <LinearProgress /> : null}
              <Card
                className="activity-container"
                style={{ maxHeight: !showAssetActivity ? '24px' : undefined }}
              >
                <div
                  className="activity-toggler"
                  role="button"
                  aria-label=" "
                  tabIndex={0}
                  onClick={this.onShowClick}
                  onKeyDown={() => null}
                >
                  {showAssetActivity ? (
                    <FormattedMessage
                      id="dashboard.events.timeline.hide_asset_activity"
                      defaultMessage="Hide Asset Activity"
                    />
                  ) : (
                    <FormattedMessage
                      id="dashboard.events.timeline.show_asset_activity"
                      defaultMessage="Show Asset Activity"
                    />
                  )}
                  {showAssetActivity ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </div>
                {showAssetActivity && (
                  <div className="activity-toggler-scroller">
                    <div
                      style={{
                        left: `${
                          (((timestamp || min) - min) * 100) / (max - min)
                        }%`,
                      }}
                      className="timeslider-pointer"
                    />
                    {assetsSlider}
                  </div>
                )}
              </Card>
            </Box>
            <Card
              style={{
                backgroundColor: theme === 'dark' ? '#525252' : '#F9F9F9',
              }}
            >
              <CardContent className="timeslider-content">
                <TimeSlider
                  assets={assets.filter(
                    (a) =>
                      (assetIds.length === 0 ||
                        assetIds.indexOf(a.id) !== -1) &&
                      (tags.length === 0 || tags.indexOf(a.id) !== -1)
                  )}
                  eventsActivity={eventsActivity || []}
                  ruleAlerts={ruleList}
                  value={timestamp || min}
                  min={min}
                  max={max}
                  onClick={this.onClick}
                  onChange={this.handleSliderChange}
                  warehouseTz={warehouseTz}
                />
              </CardContent>
              <PlayerControls
                direction={direction}
                playing={playing}
                speed={speed}
                time={new Date(warehouseTz, timestamp || min).format()}
                fastRewind={this.fastRewind}
                backward={this.backward}
                pause={this.pause}
                start={this.start}
                forward={this.forward}
                fastForward={this.fastForward}
                backwards={this.backwards}
              />
            </Card>
          </>
        ) : null}
      </>
    );
  }
}

export default EventsTabContent;
