import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';

import {
  confirmDialog,
  deleteAssetDocument,
  fetchAssetDocuments,
} from '@actions/index';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { IAppState } from '@reducers/index';
import Documents from './Documents';

const mapStateToProps = (state: IAppState) => ({
  loading: state.assets.documents.loading,
  documents: Object.values(state.assets.documents.documents),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (props: IConfirmDialog) => dispatch(confirmDialog(props)),
  deleteAssetDocument: (id: string, intl: IntlShape) =>
    dispatch(deleteAssetDocument.request({ id, intl })),
  fetchAssetDocuments: (assetId: string) =>
    dispatch(fetchAssetDocuments.request({ assetId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
