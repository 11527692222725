import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Zoom from '@mui/material/Zoom';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PanToolIcon from '@mui/icons-material/PanTool';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FormattedMessage } from 'react-intl';
import React, { Component, FunctionComponent } from 'react';
import { Layouts, Responsive, WidthProvider } from 'react-grid-layout';

import Loading from '@app/common/Loading';
import { generatePlotGrid } from '@definitions/plots';
import { MetricState } from '@reducers/analytics';
import {
  getObjectFromLocalStorage,
  saveObjectToLocalStorage,
} from '../../../../utils/localStorageUtils';
import PlotComponent from './PlotComponent';
import AggregationForm from './AggregationForm';

const ResponsiveGridLayout = WidthProvider(Responsive) as any;

const version = process.env.REACT_APP_VERSION;

interface IPropsScroll {
  children: any;
}

const ScrollTop: FunctionComponent<IPropsScroll> = (props: IPropsScroll) => {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: any) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <div style={{ position: 'absolute', bottom: '16px', right: '16px' }}>
      <Zoom in={trigger}>
        <div onClick={handleClick} role="presentation">
          {children}
        </div>
      </Zoom>
    </div>
  );
};

const LOCAL_STORAGE_KEY = `_plots_grid_${version}`;

interface IProps {
  filterId: string;
  metricId: string;
  type: string;
  zoneName?: string;

  metric: MetricState;
  theme: string;
}

interface IState {
  anchorEl?: Element;
  move: boolean;
  screen: [number, number];
  render: boolean;
}

class PlotlyTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      move: false,
      // eslint-disable-next-line no-restricted-globals
      screen: [screen.width, screen.height],
      render: false,
    };

    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddAggregation = this.handleAddAggregation.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('resize', this.updateDimensions, { passive: true });
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public handleClick() {
    const { move } = this.state;

    this.setState({ move: !move });
  }

  public handleAddAggregation() {
    const { move } = this.state;

    this.setState({ move: !move });
  }

  public handleOpen(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleClose() {
    this.setState({ anchorEl: undefined });
  }

  public onLayoutChange(layout: object, layouts: Layouts): void {
    const { filterId } = this.props;
    const { render } = this.state;

    saveObjectToLocalStorage(filterId + LOCAL_STORAGE_KEY, layouts);

    this.setState({ render: !render });
  }

  public updateDimensions() {
    this.setState({
      // eslint-disable-next-line no-restricted-globals
      screen: [screen.width, screen.height],
    });
  }

  public render() {
    const { anchorEl, move, screen, render } = this.state;
    const { filterId, metricId, metric, type, theme, zoneName } = this.props;

    let plotInfo;
    if (type === 'aggregation_plots') {
      plotInfo = Object.keys((metric.data || {}).aggregations || {}).map(
        (id) => ({ id })
      );
    } else {
      plotInfo = ((metric.data || {}).fixed_plots || []).map((plot, index) => ({
        id: String(index),
      }));
    }

    const layout = generatePlotGrid(
      plotInfo,
      getObjectFromLocalStorage(
        `${filterId}-${metricId}-${LOCAL_STORAGE_KEY}`
      ) as any
    );

    let plots;
    if (type === 'aggregation_plots') {
      plots = Object.keys((metric.data || {}).aggregations || {}).map((id) => (
        <div key={id}>
          <PlotComponent
            filterId={filterId}
            metricId={metricId}
            id={id}
            move={move}
            theme={theme}
            forceRender={render}
          />
        </div>
      ));
    } else {
      plots = ((metric.data || {}).fixed_plots || []).map((plot, index) => (
        <div key={index}>
          <PlotComponent
            plot={plot}
            loading={metric.loading}
            error={metric.error}
            move={move}
            theme={theme}
            forceRender={render}
          />
        </div>
      ));
    }

    return (
      <Card>
        <AppBar
          position="static"
          color="default"
          style={{
            zIndex: 0,
            boxShadow: 'unset',
            flexDirection: 'row',
            borderBottom: '1px solid #e3e3e3',
          }}
        >
          <Toolbar
            variant="dense"
            style={{ flex: 1, justifyContent: 'space-between' }}
          >
            <Typography variant="h6">{zoneName}</Typography>
            <span>
              <IconButton
                style={{ opacity: move ? 1 : 0.3 }}
                onClick={this.handleClick}
              >
                <PanToolIcon />
              </IconButton>
              <IconButton onClick={this.handleOpen}>
                <AddBoxIcon />
              </IconButton>
            </span>
          </Toolbar>
        </AppBar>
        <div className="grid-wrapper">
          <Loading
            loading={type === 'aggregation_plots' ? false : metric.loading}
          >
            {!plots.length ? (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <ErrorOutlineIcon style={{ color: '#666' }} />
                <Typography style={{ color: '#666', paddingLeft: '5px' }}>
                  <FormattedMessage
                    id="dashboard.plots.nodata"
                    defaultMessage="No data for the current selection"
                  />
                </Typography>
              </div>
            ) : (
              <>
                <ResponsiveGridLayout
                  cols={{ lg: 4, md: 2, sm: 2, xs: 2, xxs: 1 }}
                  containerPadding={screen[0] <= 500 ? [15, 15] : [25, 25]}
                  breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                  isDraggable={move}
                  isResizable={!move}
                  measureBeforeMount
                  layouts={{
                    lg: layout,
                    xs: layout,
                    xxs: layout,
                  }}
                  style={{ textAlign: 'center' }}
                  onLayoutChange={this.onLayoutChange}
                >
                  {plots}
                </ResponsiveGridLayout>
                <ScrollTop>
                  <Fab color="primary" size="small" aria-label="Scroll Top">
                    <KeyboardArrowUpIcon />
                  </Fab>
                </ScrollTop>
              </>
            )}
          </Loading>
        </div>

        {anchorEl && (
          <AggregationForm
            filterId={filterId}
            metricId={metricId}
            anchorEl={anchorEl}
            handleClose={this.handleClose}
          />
        )}
      </Card>
    );
  }
}

export default PlotlyTab;
