import React, { Component } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { IConfirmValidateItemLocation, IConfirmValidateItemQuantity } from '@actions/routing';

interface IProps {
  confirmed: boolean;
  confirmedQtd: boolean;
  router: any;
  language: string;
  loading: boolean;
  confirmValidateItemLocation: (properties: IConfirmValidateItemLocation) => void;
  confirmValidateItemQuantity: (properties: IConfirmValidateItemQuantity) => void;
}
interface IState {
  interfaceType: 'location' | 'item';
  data: string;
}

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  bottom: 40,
  left: 0,
  right: 0,
  margin: '0 auto',
});

const StyledQtFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  right: 0,
  bottom: -25,
});


class ScanPlaceholder extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      interfaceType: props.router.params.type || 'location',
      data: props.router.params.data || '',
    };

    this.confirm = this.confirm.bind(this);
    this.home = this.home.bind(this);
  }

  public confirm() {
    const { confirmed, confirmedQtd, language, router, confirmValidateItemLocation, confirmValidateItemQuantity } = this.props;
    const { interfaceType } = this.state;

    if (interfaceType === 'location' && !confirmed) {
      confirmValidateItemLocation({
        pickingTaskId: '',
      });
    }
    if (interfaceType === 'location') {
      router.navigate(`/${language}/dashboard/routing/${router.params.floorplanId || ''}/scan/item`);
    }

    if (!confirmedQtd) {
      confirmValidateItemQuantity({
        pickingTaskId: '',
        quantity: 0,
      })
    }

    return router.navigate(`/${language}/dashboard/routing/${router.params.floorplanId || ''}/done`);
  }

  public home() {
    const { language, router } = this.props;

    router.navigate(`/${language}/dashboard/routing`);
  }

  public render() {
    const { data, interfaceType } = this.state;
    const { confirmed, confirmedQtd } = this.props;

    return (
      <>
        <AppBar position="static" color="transparent">
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton onClick={this.home}>
              <HomeIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box className="tab-content-inner" style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Box
            style={{
              position: 'absolute',
              top: 0,
              width:'100%',
              backgroundColor: (interfaceType === 'location' && confirmed) || confirmedQtd ? '#80ff33' : '#F1F1F1',
              textAlign: 'center',
              color: '#333',
            }}
            p={4}
          >
            <Typography variant="h2" style={{ fontWeight: 700 }}>{interfaceType === 'location' ? 'Location' : 'Item'}</Typography>
          </Box>

          <Box
            style={{
              position: 'relative',
              backgroundColor: (interfaceType === 'location' && confirmed) || confirmedQtd ? '#80ff33' : '#F1F1F1',
              textAlign: 'center',
              color: '#333',
              borderRadius: '12px',
            }}
            p={3}
          >
            <Typography variant="h4">{data}</Typography>

            {interfaceType !== 'location' && (<StyledQtFab color={confirmedQtd ? 'success' : 'primary'}>
              <Typography variant="h4">{50}</Typography>
            </StyledQtFab>)}
          </Box>
        
          <StyledFab
            color="inherit"
            onClick={this.confirm} style={{
              backgroundColor: 'white',
              border: (interfaceType === 'location' && confirmed) || confirmedQtd ? '2px solid #80ff33' : '2px solid black'
            }}
          >
            <CheckIcon fontSize="large" style={{ color: (interfaceType === 'location' && confirmed) || confirmedQtd ? '#80ff33' : 'back' }} />
          </StyledFab>
        </Box>
      </>
    );
  }
}

export default ScanPlaceholder;
