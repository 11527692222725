import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { updateEditFeature, updateHoverFeature } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import Draw from './Draw';
import { HoverFeature, MapFeature } from '../../../types';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    editFeature: map.editFeature,
    hoverFeature: map.hoverFeature,
    mapImages: map.mapImages,
    paper: map.paper,
    floorplan: getFloorplanById(state, map.floorplanId)!,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateEditFeature: (id: string, feature?: MapFeature) =>
    dispatch(updateEditFeature(id, feature)),
  updateHoverFeature: (id: string, feature?: HoverFeature) =>
    dispatch(updateHoverFeature(id, feature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Draw);
