import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  createShift,
  ICreateShift,
  IUpdateShift,
  fetchShifts,
  fetchEmployees,
  updateShift,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { withRouter } from '@app/utils/withRouter';
import { getActiveLanguage } from '@selectors/app';
import ShiftForm from './ShiftForm';

import './index.css';

interface IProps {
  router: any;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  error: state.shifts.form.error,
  data: state.shifts.shifts[props.router.params.id],
  loading: state.shifts.form.loading || state.employees.loading,
  language: getActiveLanguage(state),
  employeeMap: state.employees.employees,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchShifts: () => dispatch(fetchShifts.request({ shallow: true })),
  createShift: (properties: ICreateShift) =>
    dispatch(createShift.request(properties)),
  updateShift: (properties: IUpdateShift) =>
    dispatch(updateShift.request(properties)),
  fetchEmployees: (shallow?: boolean) =>
    dispatch(fetchEmployees.request({ shallow })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShiftForm)
);
