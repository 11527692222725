import { imageStore } from '@app/common/cache';

export function loadImage(
  imageSrc: string,
  callback: (err: any, image?: HTMLImageElement) => void
) {
  let image = imageStore.get(imageSrc);

  if (image !== undefined) {
    callback(null, image);
  } else {
    image = new Image();
    image.setAttribute('crossOrigin', 'use-credentials');
    image.onload = () => {
      callback(null, image);

      imageStore.put(image.src, image);
    };
    image.onerror = (err) => callback(err);
    image.src = imageSrc;
  }
}

export function urltoFile(url: string, filename: string, mimeType: string) {
  return fetch(url, { credentials: 'include' })
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }));
}

/**
 * @description Calculates offset of an element recursively
 * @param       {HTMLElement} element Target element
 */
export function getOffsetLeft(element: HTMLElement) {
  let offsetLeft = 0;
  let elem: any = element;

  while (elem.offsetParent) {
    if (!Number.isNaN(elem.offsetLeft)) {
      offsetLeft += elem.offsetLeft;
    }

    elem = elem.offsetParent;
  }

  return offsetLeft;
}
export function getOffsetTop(element: HTMLElement) {
  let offsetTop = 0;
  let elem: any = element;

  while (elem.offsetParent) {
    if (!Number.isNaN(elem.offsetLeft)) {
      offsetTop += elem.offsetTop;
    }

    elem = elem.offsetParent;
  }

  return offsetTop;
}

export default {};
