import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import LiveDataPing from './LiveDataPing';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    activeSensorGroupId: map.activeSensorGroupId,
  };
};

export default connect(mapStateToProps)(LiveDataPing);
