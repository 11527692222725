import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { closeImportOrdersForm, importOrders } from '@actions/index';
import { IImport } from '@actions/types';
import { IAppState } from '@reducers/index';
import { isImportFormLoading } from '@selectors/orders';
import ImportOrdersForm from './ImportOrdersForm';

const mapStateToProps = (state: IAppState) => ({
  error: state.orders.importOrdersForm.error,
  loading: isImportFormLoading(state.orders),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeImportOrdersForm: () => dispatch(closeImportOrdersForm()),
  importOrders: (properties: IImport) =>
    dispatch(importOrders.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportOrdersForm);
