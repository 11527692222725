import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { activateRule, deactivateRule, deleteRule } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getAllFloorplans } from '@selectors/floorplans';
import { getValidRules } from '@selectors/rules';
import { getAllZones } from '@selectors/zones';
import Line from './Line';

import './index.css';

const mapStateToProps = (state: IAppState) => ({
  assets: state.assets.assets,
  floorplans: getAllFloorplans(state),
  measurementUnits: getAccountMeasurementUnits(state.authentication),
  rules: getValidRules(state),
  theme: state.app.theme,
  templates: state.rules.templates,
  warehouses: state.warehouses.warehouses,
  zones: getAllZones(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onRuleActivation: (id: string) => {
    dispatch(activateRule.request({ id }));
  },
  onRuleDeactivation: (id: string) => {
    dispatch(deactivateRule.request({ id }));
  },
  onRuleDelete: (id: string) => {
    dispatch(deleteRule.request({ id }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Line);
