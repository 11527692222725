import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { toggleMapHelp } from '@actions/index';
import Help from './Help';

interface IProps {
  id: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const map = getMapById(state, props.id);

  return {
    help: map.help,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleMapHelp: (id: string, help?: React.ReactElement | string) =>
    dispatch(toggleMapHelp(id, help)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Help);
