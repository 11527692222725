import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import WarningIcon from '@mui/icons-material/Warning';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

interface IProps {
  logout: () => void;
  resume: () => void;
}

const ActivityWarning: FunctionComponent<IProps> = ({
  logout,
  resume,
}: IProps) => (
  <Dialog open>
    <DialogTitle>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <WarningIcon style={{ marginRight: '15px' }} />
        <FormattedMessage
          id="dashboard.dialogs.activitywarning.title"
          defaultMessage="Activity Warning"
        />
      </div>
    </DialogTitle>
    <DialogContent>
      <Typography variant="body1">
        <FormattedMessage
          id="dashboard.dialogs.activitywarning.warning"
          defaultMessage="Your session has been inactive for some time..."
        />
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={logout}>
        <ExitToAppIcon style={{ marginRight: '5px' }} />
        <FormattedMessage
          id="dashboard.dialogs.activitywarning.logout"
          defaultMessage="Logout"
        />
      </Button>
      <Button onClick={resume} variant="contained" color="primary">
        <PresentToAllIcon style={{ marginRight: '5px' }} />
        <FormattedMessage
          id="dashboard.dialogs.activitywarning.resume"
          defaultMessage="Resume"
        />
      </Button>
    </DialogActions>
  </Dialog>
);

export default ActivityWarning;
