import Avatar from '@mui/material/Avatar';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WarningIcon from '@mui/icons-material/Warning';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { INotificationId } from '@actions/notifications';
import { findWarehouseByFloorplanId } from '@app/utils/floorplanUtils';
import { Date, timeZone } from '@dashboard_utils/index';
import { Notification } from '@models/Notification';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import { Warehouses } from '@models/Warehouse';
import { WarehouseWithFloorplans } from '@models/WarehouseWithFloorplans';
import { formatMessage } from '../../../utils/ruleUtils';

import './NotificationsList.css';

interface IProps {
  dismissNotifications: (
    ids: INotificationId[],
    locale: string,
    units: string
  ) => void;
  router: any;
  intl: IntlShape;
  language: string;
  locale: string;
  measurementUnits: string;
  notifications: Record<string, Notification[]>;
  floorplans: IncompleteFloorplans;
  warehouses: Warehouses;
}
interface IState {
  start: number;
}

export interface INotificationWithMainType extends Notification {
  mainType: string;
}

export const messages = defineMessages({
  newReportGenerated: {
    defaultMessage: 'Report ready',
    id: 'dashboard.notifications.newreportgenerated',
  },
});

class NotificationsList extends Component<IProps, IState> {
  private numberOfItems = 10;

  constructor(props: IProps) {
    super(props);

    this.state = {
      start: 0,
    };

    this.updateLoadedList = this.updateLoadedList.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { notifications } = this.props;

    if (
      JSON.stringify(notifications) !== JSON.stringify(prevProps.notifications)
    ) {
      this.updateLoadedList();
    }
  }

  public handleClick(n: INotificationWithMainType) {
    const { router, language } = this.props;

    if (n.mainType === 'alerts') {
      router.navigate(
        `/${language}/dashboard/player?ts=${n.ts}&floorplan=${n.floorplanId}`
      );
    } else if (n.type === 'reports') {
      window.open(
        `${process.env.REACT_APP_API_URL}/reports/${
          ((n.content as any) || ({} as any)).filename
        }`,
        '_black'
      );
    }
  }

  public handleDismissClick(id: string, type: string) {
    return (event: any) => {
      event.stopPropagation();

      const { dismissNotifications, locale, measurementUnits } = this.props;

      dismissNotifications([{ id, type }], locale, measurementUnits);
    };
  }

  public getNotificationList(): INotificationWithMainType[] {
    const { start } = this.state;
    const { notifications } = this.props;

    let list: INotificationWithMainType[] = [];
    Object.keys(notifications).forEach((k) => {
      list = list.concat(
        notifications[k].map((n: Notification) => ({ ...n, mainType: k }))
      );
    });

    return list
      .sort((a, b) => b.ts - a.ts)
      .slice(0, start * this.numberOfItems + this.numberOfItems);
  }

  public updateLoadedList(start = 0) {
    this.setState({ start });
  }

  public render() {
    const { intl, floorplans, warehouses } = this.props;
    const { start } = this.state;

    const list = this.getNotificationList();
    const hasMoreItems =
      start * this.numberOfItems + this.numberOfItems === list.length;

    return (
      <>
        {!hasMoreItems && list.length === 0 ? (
          <Typography variant="body1" className="no-notifications">
            <FormattedMessage
              id="dashboard.notifications.unread"
              defaultMessage="No unread notifications."
            />
          </Typography>
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={this.updateLoadedList}
            hasMore={hasMoreItems}
            loader={<LinearProgress key="loader" variant="query" />}
            useWindow={false}
            threshold={30}
          >
            <List className="notifications-list" key="list">
              {list.map((n) => {
                let borderLeft = '5px solid transparent';
                if (n.mainType === 'notifications') {
                  if (n.type === 'reports') {
                    borderLeft = '5px solid rgba(102, 102, 102, 0.3)';
                  } else if (n.level === 'info') {
                    borderLeft = '5px solid rgba(0, 0, 255, 0.3)';
                  } else if (n.level === 'warn') {
                    borderLeft = '5px solid rgba(255, 165, 0, 0.3)';
                  } else if (n.level === 'critical') {
                    borderLeft = '5px solid rgba(255, 0, 0, 0.3)';
                  } else {
                    borderLeft = '5px solid black';
                  }
                }

                let icon = null;
                if (n.type === 'reports') {
                  icon = <CloudDownloadIcon style={{ color: '#666' }} />;
                } else if (n.level === 'info') {
                  icon = <InfoIcon style={{ color: 'blue' }} />;
                } else if (n.level === 'warn') {
                  icon = (
                    <WarningIcon
                      style={{ color: 'orange', marginBottom: '3px' }}
                    />
                  );
                } else if (n.level === 'critical') {
                  icon = <ErrorIcon style={{ color: 'red' }} />;
                }

                let text;
                if (n.mainType === 'notifications') {
                  if (n.type === 'reports') {
                    text = (
                      <span style={{ color: '#888' }}>
                        {intl.formatMessage(messages.newReportGenerated)}
                      </span>
                    );
                  }
                } else if (n.mainType === 'alerts') {
                  text = (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatMessage(n, true),
                      }}
                    />
                  );
                }

                const warehouse =
                  findWarehouseByFloorplanId(
                    warehouses,
                    floorplans,
                    n.floorplanId
                  ) || ({} as WarehouseWithFloorplans);
                const warehouseTz = warehouse.timezone || timeZone;

                return (
                  <ListItem
                    button
                    key={n.id}
                    onClick={() => this.handleClick(n)}
                    style={{ borderLeft, padding: '0px 10px' }}
                  >
                    <ListItemIcon>
                      <Avatar
                        style={{
                          backgroundColor: '#F9F9F9',
                          border: '1px solid #E7E7E7',
                        }}
                      >
                        {icon}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      secondary={new Date(warehouseTz, n.ts).format()}
                      style={{ whiteSpace: 'normal' }}
                    />
                    <VisibilityOffIcon
                      className="notification-dismiss"
                      onClick={this.handleDismissClick(n.id, n.mainType)}
                    />
                  </ListItem>
                );
              })}
            </List>
          </InfiniteScroll>
        )}
      </>
    );
  }
}

export default injectIntl(NotificationsList);
