import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  abandonBasket,
  CheckoutBasket,
  checkoutBasket,
  fetchCurrentBasket,
  IAbandonBasket,
  removeBasketOrders,
  RemoveBasketOrders,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import Basket from './Basket';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  checkoutLoading: state.routing.checkoutBasket.loading,
  removeLoading: state.routing.removeBasketOrders.loading,
  loading: state.routing.currentBasket.loading,
  currentBasket: state.routing.currentBasket.basket,
  noBasket: state.routing.currentBasket.noBasket,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCurrentBasket: () => dispatch(fetchCurrentBasket.request()),
  abandonBasket: (properties: IAbandonBasket) =>
    dispatch(abandonBasket.request(properties)),
  checkoutBasket: (properties: CheckoutBasket) =>
    dispatch(checkoutBasket.request(properties)),
  removeBasketOrders: (properties: RemoveBasketOrders) =>
    dispatch(removeBasketOrders.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Basket));
