import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import CountryList from 'country-list';

import { ForkliftTypes } from '@app/dashboard/tabs/FinancialsTab/Calc';
import TimezonesSelect from './TimezonesSelect';
import { IState } from './types';

interface IProps {
  name: string;
  site: string;
  timezone: string;
  country: string;
  metricsTotalPalletsMoved?: number;
  metricsFleetUsedCapacity?: number;
  numberOfShifts?: number;
  shiftHours?: number;
  workingDays?: number;
  targetForkliftType?: keyof typeof ForkliftTypes;
  handleChange: (key: keyof IState) => any;
}

const WarehouseFormContent: FunctionComponent<IProps> = ({
  name,
  site,
  timezone,
  country,
  metricsTotalPalletsMoved = undefined,
  metricsFleetUsedCapacity = undefined,
  numberOfShifts = undefined,
  shiftHours = undefined,
  workingDays = undefined,
  targetForkliftType = undefined,
  handleChange,
}: IProps) => (
  <>
    <FormControl required fullWidth margin="normal">
      <TextField
        label={
          <FormattedMessage
            id="dashboard.forms.warehouseform.label_name"
            defaultMessage="Name"
          />
        }
        variant="standard"
        value={name}
        onChange={handleChange('name')}
        helperText={
          <FormattedMessage
            id="dashboard.forms.warehouseform.field_name_helper"
            defaultMessage="Required"
          />
        }
      />
    </FormControl>
    <FormControl required fullWidth margin="normal">
      <TextField
        label={
          <FormattedMessage
            id="dashboard.forms.warehouseform.label_site"
            defaultMessage="Site"
          />
        }
        variant="standard"
        value={site}
        onChange={handleChange('site')}
        helperText={
          <FormattedMessage
            id="dashboard.forms.warehouseform.field_site_helper"
            defaultMessage="Required"
          />
        }
      />
    </FormControl>
    <InputLabel required>
      <FormattedMessage
        id="dashboard.forms.warehouseform.label_timezone"
        defaultMessage="Timezone"
      />
    </InputLabel>
    <TimezonesSelect timezone={timezone} onChange={handleChange('timezone')} />
    <FormHelperText>
      <FormattedMessage
        id="dashboard.forms.warehouseform.field_timezone_helper"
        defaultMessage="Required"
      />
    </FormHelperText>
    <FormControl required fullWidth variant="standard" margin="normal">
      <InputLabel>
        <FormattedMessage
          id="dashboard.forms.warehouseform.label_target_country"
          defaultMessage="Country"
        />
      </InputLabel>
      <Select
        value={country || ''}
        label={
          <FormattedMessage
            id="dashboard.forms.warehouseform.label_target_country"
            defaultMessage="Country"
          />
        }
        onChange={handleChange('country')}
      >
        {CountryList.getData().map((c) => (
          <MenuItem key={c.code} value={c.code}>
            {c.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <Box mt={4}>
      <Typography variant="body2">
        <FormattedMessage
          id="dashboard.forms.warehouseform.fleet_title"
          defaultMessage="Fleet"
        />
      </Typography>
      <Divider />
      <FormControl required fullWidth variant="standard" margin="normal">
        <InputLabel>
          <FormattedMessage
            id="dashboard.forms.warehouseform.label_target_forklift_type"
            defaultMessage="Target forklift type"
          />
        </InputLabel>
        <Select
          value={targetForkliftType || ''}
          label={
            <FormattedMessage
              id="dashboard.forms.warehouseform.label_target_forklift_type"
              defaultMessage="Target forklift type"
            />
          }
          onChange={handleChange('targetForkliftType')}
        >
          {Object.keys(ForkliftTypes).map((k) => (
            <MenuItem key={k} value={k}>
              {ForkliftTypes[k as keyof typeof ForkliftTypes]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
    <Box mt={4}>
      <Typography variant="body2">
        <FormattedMessage
          id="dashboard.forms.warehouseform.workingschedule_title"
          defaultMessage="Working Schedule (FL drivers)"
        />
      </Typography>
      <Divider />
      <FormControl required fullWidth margin="normal">
        <TextField
          label={
            <FormattedMessage
              id="dashboard.forms.warehouseform.label_numberof_shifts"
              defaultMessage="Number of shifts (#)"
            />
          }
          value={numberOfShifts || ''}
          variant="standard"
          type="number"
          inputProps={{ min: 1, max: 24 }}
          onChange={handleChange('numberOfShifts')}
        />
      </FormControl>
      <FormControl required fullWidth margin="normal">
        <TextField
          label={
            <FormattedMessage
              id="dashboard.forms.warehouseform.label_shift_time"
              defaultMessage="Shift time (hours)"
            />
          }
          value={shiftHours || ''}
          variant="standard"
          type="number"
          inputProps={{ min: 4, max: 24 }}
          onChange={handleChange('shiftHours')}
        />
      </FormControl>
      <FormControl required fullWidth margin="normal">
        <TextField
          label={
            <FormattedMessage
              id="dashboard.forms.warehouseform.label_working_days"
              defaultMessage="Working days (year)"
            />
          }
          value={workingDays || ''}
          variant="standard"
          type="number"
          inputProps={{ min: 1, max: 366 }}
          onChange={handleChange('workingDays')}
        />
      </FormControl>
    </Box>
    <Box mt={4}>
      <Typography variant="body2">
        <FormattedMessage
          id="dashboard.forms.warehouseform.metrics_title"
          defaultMessage="Warehouse Metrics"
        />
      </Typography>
      <Divider />
      <FormControl required fullWidth margin="normal">
        <TextField
          label={
            <FormattedMessage
              id="dashboard.forms.warehouseform.label_total_pallets_moved"
              defaultMessage="Total number of pallets moved (year)"
            />
          }
          variant="standard"
          value={metricsTotalPalletsMoved || ''}
          onChange={handleChange('metricsTotalPalletsMoved')}
          type="number"
          helperText={
            <FormattedMessage
              id="dashboard.forms.warehouseform.field_site_helper"
              defaultMessage="Required"
            />
          }
        />
      </FormControl>
      <FormControl required fullWidth margin="normal">
        <TextField
          label={
            <FormattedMessage
              id="dashboard.forms.warehouseform.label_fleet_used_capacity"
              defaultMessage="Used Capacity (Fleet) - %"
            />
          }
          variant="standard"
          value={metricsFleetUsedCapacity || ''}
          onChange={handleChange('metricsFleetUsedCapacity')}
          type="number"
          helperText={
            <FormattedMessage
              id="dashboard.forms.warehouseform.field_site_helper"
              defaultMessage="Required"
            />
          }
        />
      </FormControl>
    </Box>
  </>
);

export default WarehouseFormContent;
