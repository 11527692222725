import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IUpdateFloorplan } from '@actions/floorplans';
import LoadingButton from '@app/common/LoadingButton/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import DefineScaleForm from '../../forms/FloorplanConfigurationForm/DefineScale/DefineScaleForm';

export interface IProps {
  floorplan: IncompleteFloorplan;
  loading: boolean;
  measurementUnits: string;
  onBack: () => void;
  onNext: (scale: number, intl: IntlShape) => void;
  onSkip: () => void;
  intl: IntlShape;
  showSkip: boolean;
  updateFloorplan: (properties: IUpdateFloorplan) => any;
}

export interface IState {
  error: boolean;
  pixels: number;
  value: string;
}

class StepContainer extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      error: false,
      pixels: 1,
      value: '',
    };

    this.onClear = this.onClear.bind(this);
    this.onMeasuredDistanceChange = this.onMeasuredDistanceChange.bind(this);
    this.onFeatureChange = this.onFeatureChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  public handleNext() {
    const { pixels, value } = this.state;
    const { onNext, intl, measurementUnits } = this.props;

    // Convert feet to meters if measurement units defined are imperial
    const meters =
      Number.parseFloat(value) * (measurementUnits === 'si' ? 1 : 0.3048);

    const scale = pixels / meters;

    onNext(scale, intl);
  }

  public onClear() {
    this.setState({ value: '' });
  }

  public onMeasuredDistanceChange(event: any) {
    const { value } = event.target;

    this.setState({
      error: !value.match(/^\d*(\.\d+)?$/) && value !== '',
      value,
    });
  }

  public onFeatureChange(pixels: number) {
    this.setState({ pixels });
  }

  public render() {
    const {
      floorplan,
      onBack,
      onSkip,
      loading,
      measurementUnits,
      showSkip,
      updateFloorplan,
    } = this.props;
    const { value, error } = this.state;

    return (
      <Card id="scale-setup" className="step-card">
        <CardContent style={{ flex: 1, position: 'relative' }}>
          <DefineScaleForm
            error={error}
            floorplan={floorplan}
            measurementUnits={measurementUnits}
            value={value}
            onMeasuredDistanceChange={this.onMeasuredDistanceChange}
            onFeatureChange={this.onFeatureChange}
            updateFloorplan={updateFloorplan}
          />
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Box mr={1}>
            <Button onClick={onBack}>
              <FormattedMessage
                id="dashboard.setup.steps.button_back"
                defaultMessage="Back"
              />
            </Button>
          </Box>
          {showSkip ? (
            <Box mr={1}>
              <Button onClick={onSkip}>
                <FormattedMessage
                  id="dashboard.setup.steps.button_skip"
                  defaultMessage="Skip"
                />
              </Button>
            </Box>
          ) : null}
          <Box mr={1}>
            <LoadingButton
              loading={loading}
              disabled={error || value === ''}
              onClick={this.handleNext}
              color="primary"
              variant="contained"
            >
              <FormattedMessage
                id="dashboard.setup.steps.button_next"
                defaultMessage="Next"
              />
            </LoadingButton>
          </Box>
        </CardActions>
      </Card>
    );
  }
}

export default injectIntl(StepContainer);
