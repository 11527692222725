import { Point, Matrix } from 'paper';
import { Component } from 'react';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { IHitEvent, IToolEvent } from '../../../types';
import { defaultTransformationMatrix } from '../../../consts';
import MapImages from '../../MapImages';
import Paper from '../../Paper';
import { transformPixelsToMeters } from '../../../../../../utils';

interface IProps {
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  paper: Paper;
  onClick: ([x, y]: [number, number]) => void;
}

class Click extends Component<IProps> {
  private dryClick = true;

  constructor(props: IProps) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseDrag = this.handleMouseDrag.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }

  public componentDidMount() {
    const { paper } = this.props;
    const { tool } = paper;

    tool.on('mousedown', this.handleMouseDown);
    tool.on('mouseup', this.handleMouseUp);
    tool.on('mousedrag', this.handleMouseDrag);
    tool.on('mousemove', this.handleMouseMove);
  }

  public componentWillUnmount() {
    const { paper } = this.props;
    const { tool } = paper;

    tool.removeListener('mousedown', this.handleMouseDown);
    tool.removeListener('mouseup', this.handleMouseUp);
    tool.removeListener('mousedrag', this.handleMouseDrag);
    tool.removeListener('mousemove', this.handleMouseMove);
  }

  public handleMouseDown(event: IToolEvent) {
    const { paper } = this.props;

    const [hit]: IHitEvent[] = paper.scope.project!.hitTestAll(event.point!, {
      fill: false,
      match: (h: IHitEvent) => h.type === 'segment',
      segments: true,
      stroke: false,
      tolerance: 5,
    });

    this.dryClick =
      !hit ||
      (!!hit &&
        !!hit.item &&
        !hit.item.featureInfo);
  }

  public handleMouseUp(event: IToolEvent) {
    const { floorplan, mapImages, onClick } =
      this.props;

    const point = new Matrix(1, 0, 0, -1, 0, mapImages.height).transform(
      new Point([event.point!.x || 0, event.point!.y || 0])
    );

    if (this.dryClick) {
      return onClick(
        transformPixelsToMeters(
          [point.x, point.y],
          floorplan.transformationMatrix || defaultTransformationMatrix,
          floorplan.scale || 1
        )
      );
    }
  }

  public handleMouseDrag() {
    this.dryClick = false;
  }

  public handleMouseMove() {
    this.dryClick = false;
  }

  public render() {
    return null;
  }
}

export default Click;
