import Button from '@mui/material/Button';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactJsonSchemaForm, { FormProps } from 'react-jsonschema-form';

import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField';

import ArrayField from './fields/ArrayField';
import Field from './fields/Field';
import ObjectField from './fields/ObjectField';
import DateWidget from './widgets/DateWidget';
import SelectWidget from './widgets/SelectWidget';
import TextWidget from './widgets/TextWidget';

const muiWidgets = {
  DateWidget,
  SelectWidget,
  TextWidget,
};

interface ICustomForm extends FormProps<any> {
  handleNewZone?: () => void;
}

const Form: FunctionComponent<ICustomForm> = ({
  widgets,
  schema,
  handleNewZone = undefined,
  ...props
}: ICustomForm) => {
  const CustomSchemaField = (customProps: any) => {
    const { name } = customProps;

    if (name === 'zones' || name === 'exclusion_zones') {
      return (
        <>
          <SchemaField {...customProps} />
          {handleNewZone && (
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={handleNewZone}
              >
                <FormattedMessage
                  id="dashboard.forms.jsonschema.createzone"
                  defaultMessage="Create new zone"
                />
              </Button>
            </div>
          )}
        </>
      );
    }

    return <SchemaField {...customProps} />;
  };

  const fields = {
    SchemaField: CustomSchemaField,
  };

  return (
    <ReactJsonSchemaForm
      widgets={{ ...muiWidgets, ...widgets }}
      FieldTemplate={Field}
      ArrayFieldTemplate={ArrayField as any}
      ObjectFieldTemplate={ObjectField}
      showErrorList={false}
      schema={schema}
      fields={fields}
      {...props}
    />
  );
};

export default Form;
