import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  createWarehouse,
  ICreateWarehouse,
  IUpdateWarehouse,
  updateWarehouse,
} from '@actions/index';
import WarehouseFormDialog from './WarehouseForm';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  createWarehouse: (properties: ICreateWarehouse) =>
    dispatch(createWarehouse.request(properties)),
  updateWarehouse: (properties: IUpdateWarehouse) =>
    dispatch(updateWarehouse.request(properties)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarehouseFormDialog);
