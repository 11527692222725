import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React, { Component } from 'react';

import { IDeleteDiagram } from '@actions/diagrams';
import Diagram from '@models/Diagram';
import MUITable, { ITableColumn } from '@app/common/Table';
import Loading from '@app/common/Loading';
import { injectIntl, IntlShape } from 'react-intl';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '@app/dialogs/ConfirmDialog/types';

interface IProps {
  confirm: (properties: IConfirmDialog) => void;
  deleteDiagram: (properties: IDeleteDiagram) => void;
  diagrams: Diagram[];
  fetchDiagrams: () => void;
  intl: IntlShape;
  language: string;
  loading: boolean;
  router: any;
}
interface IState {
  columns: ITableColumn[];
  search?: string;
}

class DiagramTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      columns: [
        { label: 'Name', name: 'name' },
        { label: 'Options', name: 'options', options: { interaction: false } },
      ],
    };

    this.customRowRender = this.customRowRender.bind(this);
    this.handleAddDiagram = this.handleAddDiagram.bind(this);
  }

  public handleAddDiagram() {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/diagrams/diagram/new`);
  }

  public handleEdit(id: string) {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/diagrams/diagram/${id}/edit`);
  }

  public handleDelete(id: string) {
    const { confirm, deleteDiagram, intl } = this.props;

    confirm({
      confirmType: ConfirmDialogActions.DELETE,
      message: 'Are you sure that you want to delete this diagram?',
      onConfirmation: () => {
        deleteDiagram({ id, intl });
      },
    });
  }

  public customRowRender(
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    const { diagrams } = this.props;

    const data: Diagram = diagrams[dataIndex];

    if (!data) {
      return <TableRow key={rowIndex} />;
    }

    return (
      <TableRow key={rowIndex}>
        {columns[0] && <TableCell>{rowData[0]}</TableCell>}
        {columns[1] && (
          <TableCell style={{ textAlign: 'right' }}>
            <IconButton size="small" onClick={() => this.handleEdit(data.id)}>
              <EditIcon />
            </IconButton>
            <IconButton size="small" onClick={() => this.handleDelete(data.id)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  }

  public render() {
    const { loading, diagrams } = this.props;
    const { columns } = this.state;

    return (
      <Card>
        <CardHeader
          action={
            <Button id="add-diagram" onClick={this.handleAddDiagram}>
              <AddIcon style={{ marginRight: '5px' }} />
              Add diagram
            </Button>
          }
          title="Diagrams"
        />
        <CardContent>
          <Loading loading={loading}>
            <div className="table-container">
              <MUITable
                columns={columns}
                data={diagrams}
                customRowRender={this.customRowRender}
                tableId="diagrams"
              />
            </div>
          </Loading>
        </CardContent>
      </Card>
    );
  }
}

export default injectIntl(DiagramTab);
