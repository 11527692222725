import { range } from 'lodash';
import React, { Component } from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import CircleIcon from '@mui/icons-material/Circle';

import { IUpdateTask } from '@actions/tasks';
import { Task, TaskStatus } from '@models/Task';
import Loading from '@app/common/Loading';
import { Date } from '@dashboard_utils/index';

import './index.css';

interface IProps {
  tasks: Task[];
  updateTask: (properties: IUpdateTask) => void;
  openForm: (data?: Task) => void;
  intl: IntlShape;
  loading: boolean;
  warehouseTz: string;
  endDate?: Date;
  startDate?: Date;
}

interface IState {
  windowWidth?: number;
}

class TimeLine extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.openModal = this.openModal.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions, {
      passive: true,
    });

    this.updateWindowDimensions();
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  public openModal(task: Task) {
    const { openForm } = this.props;

    openForm(task);
  }

  public updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }

  public render() {  
    const { tasks, loading, startDate, endDate, warehouseTz } = this.props;
    const { windowWidth } = this.state;
    
    const min = (startDate || new Date()).getTime();
    const max = (endDate || new Date()).getTime() + 5 * 24 * 60 * 60 * 1000;

    const intervals = windowWidth
      ? Math.min(Math.floor(windowWidth / 100), 10)
      : 10;

    return (
      <>
        <Loading loading={loading}>
          <div className="tasks-slider-container">
            <div className="tasks-timeslider-container">
              {range(intervals).map((i) => {
                const intervalTs = min + ((max - min) / intervals) * i;
                const dString = new Date(warehouseTz, intervalTs).format(
                  new Date(warehouseTz, intervalTs).format('yyyy-MM-dd') !==
                    new Date(
                      warehouseTz,
                      intervalTs - (max - min) / intervals,
                      'Etc/UTC'
                    ).format('yyyy-MM-dd')
                    ? 'yyyy-MM-dd HH:mm'
                    : 'HH:mm'
                );

                // to center, removes 5px per character, could be improved
                return (
                  i !== 0 && (
                    <div
                      key={i}
                      className="scale"
                      style={{
                        left: `calc(${(i * 100) / intervals}% - ${
                          Math.ceil(dString.length / 2) * 6
                        }px)`,
                      }}
                    >
                      {dString.split(' ').length > 1 ? (
                        <span>
                          <b>{dString.split(' ')[0]}</b>
                          <span> </span>
                          <span>{dString.split(' ')[1]}</span>
                        </span>
                      ) : (
                        dString
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </div>
          <div className="tasks-container">
            {tasks.map((t) => {
              return (
                <div key={t.id} className="tasks-line">
                  <div
                    className="task"
                    onClick={() => this.openModal(t)}
                  >
                    <span>{t.name}</span>

                    {t.events.map((e, i) => (
                      <CircleIcon
                        key={i}
                        style={{
                          position: 'absolute',
                          left: `${((new Date(warehouseTz, e.created).getTime() - min) * 100) / (max - min)}%`,
                          opacity: 0.3,
                        }}
                        color={
                          e.type === TaskStatus.PENDING
                            ? 'secondary'
                            : e.type === TaskStatus.PROGRESS
                              ? 'primary'
                              : 'success'
                        }
                      />
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </Loading>
      </>
    );
  }
}

export default injectIntl(TimeLine);
