import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { fetchMetrics, FetchMetrics, toggleMetric } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getSelectedLocale } from '@selectors/app';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import MetricsProvider from './MetricsProvider';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;

  const filter = state.filter.filters[filterId] || {};

  return {
    warehouseId: filter.warehouseId || '',
    floorplanId: filter.floorplanId || '',
    zoneIds: filter.zoneIds || [],

    tags: filter.tags || [],
    assetIds: filter.assetIds || [],

    templateIds: filter.templateIds || [],
    ruleIds: filter.ruleIds || [],

    locale: getSelectedLocale(state),
    measurementUnits: getAccountMeasurementUnits(state.authentication),

    activeMetrics: state.analytics.activeMetrics[filterId] || {},

    loading: (state.analytics.meta[filterId] || { loading: true }).loading,
    error: (state.analytics.meta[filterId] || {}).error,
    metrics: (state.analytics.meta[filterId] || {}).metrics || [],
    conditions: (state.analytics.meta[filterId] || {}).conditions || {},
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMetrics: (properties: FetchMetrics) =>
    dispatch(fetchMetrics.request(properties)),
  toggleMetric: (
    filterId: string,
    section: 'user' | 'diagnostics',
    metricId: string
  ) => dispatch(toggleMetric(filterId, section, metricId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MetricsProvider);
