import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { deleteAccount, IDeleteAccount } from '@actions/index';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';

import AccountSettings from './AccountSettings';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteAccount: (props: IDeleteAccount) => dispatch(deleteAccount.request(props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
