import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { FunctionComponent } from 'react';

import Filters from '@app/common/Filters';
import { IFilter } from '@reducers/filter';
import { cellSize } from '../../../utils';
import Walkthrough from '../../../utils/Walkthrough';
import SensorsTabContainer from './SensorsTabContainer';

interface IProps {
  filters: Record<string, IFilter>;
}

const SensorsTabGrid: FunctionComponent<IProps> = ({ filters }: IProps) => {
  const filterKeys = Object.keys(filters).filter(
    (k) => k !== 'simulator' && k !== 'popup'
  );

  return (
    <Box className="tab-content-inner" p={2}>
      <Grid container spacing={2}>
        {filterKeys.map((filterId, index) => (
          <Grid key={filterId} item xs={cellSize(filterKeys, index)}>
            <Filters filterId={filterId}>
              <SensorsTabContainer filterId={filterId} />
            </Filters>
          </Grid>
        ))}
      </Grid>
      <Walkthrough type="sensors" />
    </Box>
  );
};

export default SensorsTabGrid;
