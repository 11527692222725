import { ActionType, getType } from 'typesafe-actions';
import React from 'react';

import * as types from '@actions/app';
import * as auth from '@actions/authentication';
import * as sensors from '@actions/sensors';
import { HoverFeature, MapFeature } from '@app/common/FullMap/types';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { LayerScale } from '@app/common/FullMap/Map/LayerScale/LayerScale';
import MapImages from '@app/common/FullMap/Map/MapImages';
import Paper from '@app/common/FullMap/Map/Paper';
import { MapLayers } from '@app/common/FullMap/Map/Layers/types';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from '@app/utils/localStorageUtils';

const version = process.env.REACT_APP_VERSION;

type Actions = ActionType<typeof types | typeof auth | typeof sensors>;

interface IConfirmDialogState {
  formData?: IConfirmDialog;
  open: boolean;
}

export interface IMapState {
  activeSensorGroupId?: string;
  editFeature?: MapFeature;
  feature?: MapFeature;
  floorplanId: string;
  hoverFeature?: HoverFeature;
  help?: React.ReactElement | string;
  scale?: LayerScale;
  mapImages: MapImages;
  paper: Paper;
  layers: MapLayers;
  warnings: Record<string, types.MapWarning>;
}

export interface IAppState {
  readonly confirmDialog: IConfirmDialogState;
  // eslint-disable-next-line prettier/prettier
  readonly language?: string;
  readonly map: Record<string, IMapState>;
  readonly menu: boolean;
  readonly walkthrough: boolean;
  readonly setup: Record<string, boolean>;
  readonly theme: 'light' | 'dark';
  readonly zoneMetrics: boolean;
}

export const initialState = (): IAppState => ({
  confirmDialog: {
    open: false,
  },
  menu: false,
  walkthrough: getFromLocalStorage(`walkthrough_${version}`) !== 'disabled',
  map: {},
  setup: {},
  theme:
    (getFromLocalStorage(`theme_${version}`) as 'light' | 'dark') || 'light',
  zoneMetrics: false,
});

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IAppState = initialState(),
  action: Actions
): IAppState => {
  switch (action.type) {
    case getType(types.changeLanguage): {
      const language = action.payload;

      // @ts-ignore
      if (window.MessageInvoker) {
        // @ts-ignore
        window.MessageInvoker.postMessage(`language|${language}`);
      }

      return {
        ...state,
        language,
      };
    }

    case getType(types.confirmDialog): {
      return {
        ...state,
        confirmDialog: {
          formData: action.payload,
          open: true,
        },
      };
    }

    case getType(types.confirmDialogClose): {
      return {
        ...state,
        confirmDialog: {
          ...state.confirmDialog,
          open: false,
        },
      };
    }

    case getType(types.skipZoneSetup): {
      return {
        ...state,
        setup: {
          ...state.setup,
          [action.payload]: true,
        },
      };
    }

    case getType(types.toggleMenu): {
      return {
        ...state,
        menu: action.payload,
      };
    }

    case getType(types.toggleWalkthrough): {
      return {
        ...state,
        walkthrough: action.payload,
      };
    }

    case getType(auth.updateAccountSettings.success): {
      const { accountMeta } = action.payload;

      return {
        ...state,
        language: ((accountMeta || {}).locale || '').split('-')[0],
      };
    }

    case getType(sensors.fetchFloorplanSensorGroupStatus.success): {
      const { floorplanId, sensorGroup } = action.payload;
      const { map } = state;

      Object.keys(state.map)
        .filter((mapId) => state.map[mapId].floorplanId === floorplanId)
        .forEach((mapId) => {
          map[mapId] = {
            ...map[mapId],
            activeSensorGroupId: sensorGroup.id,
          };
        });

      return { ...state, map };
    }

    case getType(types.initMap): {
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.id]: {
            layers: [],
            warnings: {},
            ...action.payload,
          },
        },
      };
    }

    case getType(types.destroyMap): {
      const { map } = state;

      const newMap: Record<string, IMapState> = {};
      Object.keys(map)
        .filter((id) => id !== action.payload)
        .forEach((id) => {
          newMap[id] = map[id];
        });

      return { ...state, map: newMap };
    }

    case getType(types.updateSelectedFeature): {
      const { id, feature } = action.payload;

      return {
        ...state,
        map: {
          ...state.map,
          [id]: {
            ...state.map[id],
            feature,
          },
        },
      };
    }

    case getType(types.updateEditFeature): {
      const { id, feature } = action.payload;

      return {
        ...state,
        map: {
          ...state.map,
          [id]: {
            ...state.map[id],
            editFeature: feature,
          },
        },
      };
    }

    case getType(types.updateHoverFeature): {
      const { id, feature } = action.payload;

      return {
        ...state,
        map: {
          ...state.map,
          [id]: {
            ...state.map[id],
            hoverFeature: feature,
          },
        },
      };
    }

    case getType(types.toggleMapHelp): {
      const { id, help } = action.payload;

      return {
        ...state,
        map: {
          ...state.map,
          [id]: {
            ...state.map[id],
            help,
          },
        },
      };
    }

    case getType(types.updateScale): {
      const { id, scale } = action.payload;

      /** Action can be triggered after map was destroy
       *  this avoids creating a new empty one
       *  ie.: Heatmap clear();
       */
      if (!state.map[id]) {
        return state;
      }

      return {
        ...state,
        map: {
          ...state.map,
          [id]: {
            ...state.map[id],
            scale,
          },
        },
      };
    }

    case getType(types.toggleZoneMetrics): {
      const { id } = action.payload;

      return { ...state, zoneMetrics: !!id };
    }

    case getType(types.toggleTheme): {
      const theme = state.theme === 'light' ? 'dark' : 'light';

      saveToLocalStorage(`theme_${version}`, theme);

      return { ...state, theme };
    }

    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState() };
    }

    default:
      return state;
  }
};
