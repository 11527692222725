import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { InputLabelProps } from '@mui/material/InputLabel/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Select from '@mui/material/Select';
import { SelectProps } from '@mui/material/Select/Select';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import MenuIcon from '@mui/icons-material/Menu';
import React, { Component, FunctionComponent, RefAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ws from '@api/websocket';
import NotificationsMenu from '@app/common/notifications';
import AvatarComponent from '@app/utils/Avatar';
import Account from '@models/Account';
import { Notification } from '@models/Notification';
import ActivityWarning from './ActivityWarning';
import { PRIMARY_COLOR } from '../../utils/colors';

interface IWSNotification {
  type: string;
  notification: Notification;
}

const StyledSelect = styled(Select)(() => ({
  color: '#FFF',
  fontSize: '14px',
}));

const StyledInputLabel = styled(InputLabel)(() => ({
  color: '#FFF',
  fontSize: '14px',
}));

const useStyles = makeStyles(() => ({
  icon: { fill: '#FFF' },
}));

const CustomSelect: FunctionComponent<Omit<SelectProps, "ref"> & RefAttributes<HTMLDivElement>> = React.forwardRef(
  (properties: any, ref: any) => {
    const { children } = properties;

    const classes = useStyles();

    return (
      <StyledSelect ref={ref} classes={classes} {...properties}>
        {children}
      </StyledSelect>
    );
  }
);

const CustomInputLabel: FunctionComponent<Omit<InputLabelProps, "ref"> & RefAttributes<HTMLDivElement>> = React.forwardRef(
  (properties: any, ref: any) => {
    const { children } = properties;

    return (
      <StyledInputLabel ref={ref} {...properties}>
        {children}
      </StyledInputLabel>
    );
  }
);

interface IProps {
  account?: Account;
  authenticated: boolean;
  handleNotification: (notification: Notification, type: string) => void;
  router: any;
  language: string;
  loading: boolean;
  logout: () => void;
  open: boolean;
  openModeSelector: () => void;
  toggleMenu: (open: boolean) => void;
  floating: boolean;
}

interface IState {
  anchorEl?: HTMLElement;
  inactiveUser: boolean;
}

class Navbar extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: undefined,
      inactiveUser: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAccountLink = this.handleAccountLink.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleWSNotification = this.handleWSNotification.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.setInactiveUser = this.setInactiveUser.bind(this);
    this.logoutInactiveUser = this.logoutInactiveUser.bind(this);
    this.resumeInactiveUser = this.resumeInactiveUser.bind(this);
  }

  public componentDidMount() {
    ws.on('notification', this.handleWSNotification);
    window.addEventListener('NoMapActivityDetected', this.setInactiveUser, {
      passive: true,
    });
  }

  public componentWillUnmount() {
    ws.removeListener('notification', this.handleWSNotification);
    window.removeEventListener('NoMapActivityDetected', this.setInactiveUser);
  }

  public handleWSNotification(data: IWSNotification) {
    const { handleNotification } = this.props;

    handleNotification(
      data.notification,
      data.type === 'rule-alerts' ? 'alerts' : 'notifications'
    );
  }

  public handleClick(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleClose() {
    this.setState({ anchorEl: undefined });
  }

  public handleModeChange() {
    const { openModeSelector } = this.props;

    this.setState({ anchorEl: undefined }, openModeSelector);
  }

  public handleAccountLink() {
    const { router, language } = this.props;

    this.setState({ anchorEl: undefined }, () => {
      router.navigate(`/${language}/dashboard/account/settings`);
    });
  }

  public handleLogout() {
    const { logout } = this.props;

    logout();

    this.setState({ anchorEl: undefined });
  }

  public handleLanguageChange(event: any) {
    const { router, language } = this.props;

    router.navigate(
      router.params['*'].replace(
        `/${language}/`,
        `/${event.target.value.split('-')[0]}/`
      )
    );
  }

  public setInactiveUser() {
    this.setState({ inactiveUser: true });
  }

  public toggleMenu() {
    const { toggleMenu, open } = this.props;

    toggleMenu(!open);
  }

  public resumeInactiveUser() {
    this.setState({ inactiveUser: false }, () => {
      window.dispatchEvent(new Event('MapActivityResumed'));
    });
  }

  public logoutInactiveUser() {
    const { logout } = this.props;

    this.setState({ inactiveUser: false }, logout);
  }

  private authenticatedActions() {
    const { account, floating, loading } = this.props;
    const { anchorEl, inactiveUser } = this.state;

    const { organisationId } = account || {};
    const organisations = (account || {}).organisations || {};
    const organisation = organisations[organisationId || ''] || {};

    return (
      <>
        {!floating && organisationId ? <NotificationsMenu /> : null}
        <span>
          <IconButton
            aria-label="More"
            aria-owns={anchorEl ? 'long-menu' : undefined}
            aria-haspopup="true"
            color="inherit"
            onClick={this.handleClick}
          >
            {(account || {}).image ? (
              <AvatarComponent account={account || ({} as Account)} size={24} />
            ) : (
              <AccountCircleIcon />
            )}
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={this.handleClose}
          >
            <Box p={2} pt={1} pb={1}>
              {(account || {}).name}
              {organisation.id ? <span> / </span> : null}
              {organisation.id ? (
                <b style={{ color: PRIMARY_COLOR }}>{organisation.name}</b>
              ) : null}
            </Box>
            <Divider />
            <MenuList>
              {!floating && organisationId ? (
                <MenuItem color="inherit" onClick={this.handleAccountLink}>
                  <ListItemIcon>
                    {(account || {}).image ? (
                      <AvatarComponent
                        account={account || ({} as Account)}
                        size={24}
                      />
                    ) : (
                      <AccountCircleIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="navbar.accountmenu.account_settings"
                        defaultMessage="Account Settings"
                      />
                    }
                  />
                </MenuItem>
              ) : null}
              {!floating && Object.values(organisations).length > 1 ? (
                <MenuItem
                  color="inherit"
                  onClick={this.handleModeChange}
                  style={{ color: '#f50057' }}
                >
                  <ListItemIcon>
                    <FilterNoneIcon style={{ color: '#f50057' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="navbar.accountmenu.change_mode"
                        defaultMessage="Change Mode"
                      />
                    }
                  />
                </MenuItem>
              ) : null}
              <MenuItem
                onClick={this.handleLogout}
                color="inherit"
                disabled={loading}
              >
                <ListItemIcon>
                  {loading === true ? (
                    <CircularProgress
                      className="loading-button"
                      color="primary"
                      size={24}
                    />
                  ) : (
                    <ExitToAppIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="navbar.accountmenu.logout"
                      defaultMessage="Log out"
                    />
                  }
                />
              </MenuItem>
            </MenuList>
          </Menu>
          {inactiveUser ? (
            <ActivityWarning
              logout={this.logoutInactiveUser}
              resume={this.resumeInactiveUser}
            />
          ) : null}
        </span>
      </>
    );
  }

  public render() {
    const { account, authenticated, floating, language } = this.props;

    return (
      <>
        <AppBar
          className="navbar"
          position={floating ? 'absolute' : 'static'}
          style={{
            width: !floating ? '100%' : 'unset',
            backgroundColor: !floating ? undefined : 'unset',
            color: !floating ? undefined : 'unset',
          }}
        >
          <Toolbar className="toolbar" variant="dense">
            {!floating && authenticated && (account || {}).organisationId ? (
              <IconButton
                className="navbar-menu-button"
                color="inherit"
                aria-label="Menu"
                onClick={this.toggleMenu}
                style={{ padding: '6px' }}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            ) : null}
            {
              !floating ? <>
                <Link id="app-title" to={`/${language}/`}>
                  <img
                    src={process.env.REACT_APP_ORGANISATION_LOGO}
                    alt={`${process.env.REACT_APP_ORGANISATION_NAME} Logo`}
                    height="30px"
                    width="100px"
                    style={{ marginBottom: '-5px' }}
                  />
                </Link>
                <div className="navbar-fill-div" />
                <FormControl variant="standard">
                  <CustomInputLabel htmlFor="nav-language">
                    <FormattedMessage
                      id="navbar.header.label_language"
                      defaultMessage="Language"
                    />
                  </CustomInputLabel>
                  <CustomSelect
                    disableUnderline
                    id="nav-language"
                    value={language}
                    onChange={this.handleLanguageChange}
                    input={<Input name="language" id="nav-language" />}
                    style={{ marginTop: '5px' }}
                  >
                    <MenuItem value="de">Deutsche (DE)</MenuItem>
                    <MenuItem value="en">English (GB)</MenuItem>
                    <MenuItem value="es">Español (ES)</MenuItem>
                    <MenuItem value="fr">Français (FR)</MenuItem>
                    <MenuItem value="it">Italiano (IT)</MenuItem>
                    <MenuItem value="pt">Português (PT)</MenuItem>
                    <MenuItem value="ro">Română (RO)</MenuItem>
                  </CustomSelect>
                </FormControl>
              </> : null
            }
            {authenticated ? this.authenticatedActions() : null}
          </Toolbar>
        </AppBar>
      </>
    );
  }
}

export default Navbar;
