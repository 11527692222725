import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  addOrderToCurrentBasket,
  fetchOrders,
  quickOrder,
  IAddOrderToBasket,
  IFetchOrders,
  fetchCurrentBasket,
  addOrderToNewBasket,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import Routing from './Routing';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  loading: state.routing.orders.loading,
  orders: state.routing.orders.list,
  quickOrderLoading: state.routing.quickOrder.loading,
  quickOrderData: state.routing.quickOrder.order,
  currentBasket: state.routing.currentBasket.basket,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCurrentBasket: () => dispatch(fetchCurrentBasket.request()),
  fetchOrders: (properties: IFetchOrders) =>
    dispatch(fetchOrders.request(properties)),
  addOrderToCurrentBasket: (properties: IAddOrderToBasket) =>
    dispatch(addOrderToCurrentBasket.request(properties)),
  addOrderToNewBasket: (properties: IAddOrderToBasket) =>
    dispatch(addOrderToNewBasket.request(properties)),
  quickOrder: () => dispatch(quickOrder.request()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routing));
