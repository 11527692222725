import { range } from 'lodash';
import React, { Component } from 'react';

import { Date } from '@dashboard_utils/index';
import { getOffsetLeft } from '../../../../../../utils/mapUtils';

import './index.css';

export interface IProps {
  length: number;
  onChange: (value: number) => void;
  start: number;
  value: number;
}

interface IState {
  dragging: boolean;
  startPosition?: number;
  startValue?: number;
  windowWidth?: number;
}

class TimeSlider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      dragging: false,
    };

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onClick = this.onClick.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  public componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove, { passive: true });
    document.addEventListener('mouseup', this.onMouseUp, { passive: true });
    window.addEventListener('resize', this.updateWindowDimensions, {
      passive: true,
    });

    this.updateWindowDimensions();
  }

  public componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  public onMouseDown(event: any) {
    const { value } = this.props;

    event.stopPropagation();

    this.setState({
      dragging: true,
      startPosition: event.pageX,
      startValue: value,
    });
  }

  public onMouseUp(event: any) {
    event.stopPropagation();

    this.setState({ dragging: false });
  }

  public onMouseMove(event: any) {
    const { dragging, startPosition, startValue } = this.state;
    const { onChange, length, start } = this.props;

    if (!dragging) {
      return;
    }

    event.stopPropagation();

    const moved = event.pageX - (startPosition || 0);
    const parent: any = document.getElementsByClassName(
      'timeslider-container'
    )[0];
    const relativeMovement = (moved * 100) / parent.clientWidth;

    let newValue =
      (startValue || 0) +
      Math.ceil(((length - start) * relativeMovement) / 100);
    if (newValue < start) {
      newValue = start;
    }
    if (newValue > length) {
      newValue = length;
    }

    onChange(newValue);
  }

  public onClick(event: any) {
    const { onChange, length, start } = this.props;

    const clickX = event.pageX;
    const element: any = document.getElementsByClassName(
      'timeslider-container'
    )[0];

    const relativeMovement =
      ((clickX - getOffsetLeft(element)) * 100) / element.clientWidth;

    onChange(start + Math.ceil(((length - start) * relativeMovement) / 100));
  }

  public updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }

  public render() {
    const { length, start, value } = this.props;
    const { windowWidth } = this.state;

    const intervals = windowWidth
      ? Math.min(Math.floor(windowWidth / 100), 10)
      : 10;

    return (
      <div className="slider-container">
        <div
          style={{ left: `${((value - start) * 100) / (length - start)}%` }}
          className="timeslider-pointer"
        />
        <div className="timeslider-container">
          <div
            className="timeslider"
            role="button"
            aria-label=" "
            tabIndex={0}
            onClick={this.onClick}
            onKeyDown={this.onClick}
          />
          <div
            style={{ left: `${((value - start) * 100) / (length - start)}%` }}
            className="slider-handle"
            role="button"
            aria-label=" "
            tabIndex={0}
            onMouseDown={this.onMouseDown}
          />
          {range(intervals).map((i) => {
            const ts = start + (length - start) / intervals;
            const dString = new Date('Etc/UTC', ts * i).format(
              new Date('Etc/UTC', ts * i).getDate() !==
                new Date('Etc/UTC', ts * (i - 1)).getDate()
                ? 'dd HH:mm'
                : 'HH:mm'
            );

            return (
              i !== 0 && (
                <div
                  key={i}
                  className="scale"
                  style={{
                    left: `calc(${(i * 100) / intervals}% - ${
                      Math.ceil(dString.length / 2) * 6
                    }px)`,
                  }}
                >
                  {dString.split(' ').length > 1 ? (
                    <span>
                      <b>{dString.split(' ')[0]}</b>
                      <span>{dString.split(' ')[1]}</span>
                    </span>
                  ) : (
                    dString
                  )}
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  }
}

export default TimeSlider;
