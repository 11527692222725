import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';

import { IUpdateTask } from '@actions/tasks';
import { Task, TaskStatus } from '@models/Task';
import Loading from '@app/common/Loading';
import { Employees } from '@models/Employee';
import TaskContainer from '../TaskContainer';

export interface IAnchorEL {
  menu: HTMLElement | undefined;
}

const useStyles = (theme: Theme) => ({
  headerPending: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    textAlign: 'center' as const,
  },
  headerProgress: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center' as const,
  },
  headerDone: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    textAlign: 'center' as const,
  },
});

interface IProps {
  tasks: Task[];
  updateTask: (properties: IUpdateTask) => void;
  openForm: (data?: Task) => void;
  intl: IntlShape;
  loading: boolean;
  employees: Employees;
  classes: any;
}

class KanbanBoard extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.onTaskDrop = this.onTaskDrop.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  
  public onTaskDrop(task: Task, newStatus: TaskStatus) {
    if (task.status !== newStatus) {
      const { updateTask, intl } = this.props;

      const {
        id,
        warehouseId,
        floorplanId,
        zoneId,
        name,
        notes,
        assets,
        employees,
        teams,
        subtasks,
        priority,
        type,
        dueDate,
        pictures,
      } = task;
    
      updateTask({
        id,
        warehouseId,
        floorplanId,
        zoneId,
        name,
        notes,
        assets,
        employees,
        teams,
        priority,
        status: newStatus,
        type,
        dueDate,
        subtasks,
        pictures,
        intl,
      });
    }
  }

  public openModal(task: Task) {
    const { openForm } = this.props;

    openForm(task);
  }

  public render() {  
    const { classes, employees, loading, tasks } = this.props;

    return (
      <>
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          <Loading loading={loading}>
            <Grid container spacing={2}>
              <Grid item md={4} sm={12} xs={12}>
                <Box mt={2} pb={2}>
                  <Paper className={classes.headerPending}>
                    <Box p={1}>
                      <Typography variant="body1" component="span">Pending</Typography>
                    </Box>
                  </Paper>
                  <Box p={2} className="task-container">
                    <TaskContainer
                      onTaskDrop={this.onTaskDrop}
                      openModal={this.openModal}
                      status={TaskStatus.PENDING}
                      tasks={tasks}
                      employees={employees}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box mt={2} pb={2}>
                  <Paper className={classes.headerProgress}>
                    <Box p={1}>
                      <Typography variant="body1" component="span">In Progress</Typography>
                    </Box>
                  </Paper>
                  <Box p={2} className="task-container">
                    <TaskContainer
                      onTaskDrop={this.onTaskDrop}
                      openModal={this.openModal}
                      status={TaskStatus.PROGRESS}
                      tasks={tasks}
                      employees={employees}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box mt={2} pb={2}>
                  <Paper className={classes.headerDone}>
                    <Box p={1}>
                      <Typography variant="body1" component="span">Done</Typography>
                    </Box>
                  </Paper>
                  <Box p={2} className="task-container">
                    <TaskContainer
                      onTaskDrop={this.onTaskDrop}
                      openModal={this.openModal}
                      status={TaskStatus.DONE}
                      tasks={tasks}
                      employees={employees}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Loading>
        </DndProvider>
      </>
    );
  }
}

export default withStyles(useStyles)(injectIntl(KanbanBoard));
