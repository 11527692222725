import { JSONSchema6 } from 'json-schema';

export function injectDefinitions(
  schema: JSONSchema6,
  definitions: Record<string, any>
) {
  return {
    ...schema,
    definitions: {
      ...schema.definitions,
      ...definitions,
    },
  };
}

export function toDefinition(
  objects: { id: string; name: string }[]
): JSONSchema6 {
  return {
    anyOf: objects.map((object) => ({
      enum: [object.id],
      title: object.name,
      type: 'string' as 'string',
    })),
  };
}

export default {};
