import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import Loading from '@app/common/Loading';
import { findWarehouseByFloorplanId } from '@app/utils/floorplanUtils';
import { Date, timeZone } from '@dashboard_utils/index';
import CompleteAsset from '@models/CompleteAsset';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import Sensor from '@models/Sensor';
import { Warehouses } from '@models/Warehouse';
import { WarehouseWithFloorplans } from '@models/WarehouseWithFloorplans';
import { getAssetTypeName } from '../../../../forms/AssetForm/utils';
import TicketForm from './forms/TicketForm';
import PreCheck from './forms/PreCheck';
import Maintenance from './forms/Maintenance';
import ManagementDashboard from './views/ManagementDashboard';
import TicketDashboard from './views/TicketDashboard';

const messages = defineMessages({
  imageAlt: {
    defaultMessage: 'Asset image',
    id: 'dashboard.assets.imagealt',
  },
});

interface IProps {
  asset?: CompleteAsset;
  intl: IntlShape;
  router: any;
  fetchSensors: (floorplanId: string) => void;
  openTicketForm: () => void;
  openMaintenanceForm: (assetId: string) => void;
  openPreCheckListForm: (preCheckId: string, assetId: string) => void;
  language: string;
  loading: boolean;
  sensors: Sensor[];
  ticketForm: boolean;
  preCheckForm: boolean;
  maintenanceForm: boolean;
  floorplans: IncompleteFloorplans;
  warehouses: Warehouses;
}

class Management extends Component<IProps> {
  public componentDidMount() {
    const { fetchSensors, asset } = this.props;

    if (asset) {
      fetchSensors(asset.floorplanId);
    }
  }

  public render() {
    const {
      asset,
      router,
      intl,
      openTicketForm,
      openMaintenanceForm,
      openPreCheckListForm,
      language,
      loading,
      sensors,
      ticketForm,
      preCheckForm,
      maintenanceForm,
      floorplans,
      warehouses,
    } = this.props;

    if (loading) {
      return (
        <Box m={2} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      );
    }

    if (!asset) {
      return (
        <Alert severity="error">
          <FormattedMessage
            id="dashboard.assets.management.assetnotfound"
            defaultMessage="Asset not found"
          />
        </Alert>
      );
    }

    const warehouse =
      findWarehouseByFloorplanId(warehouses, floorplans, asset.floorplanId) ||
      ({} as WarehouseWithFloorplans);
    const warehouseTz = warehouse.timezone || timeZone;

    const activeSensorAssociation = (
      sensors
        .sort((a, b) =>
          new Date(a.created).getTime() > new Date(b.created).getTime() ? -1 : 1
        )
        .find((s) => s.physicalAddress === asset.physicalAddress) || {}
    ).id;

    const goToLiveMap = () =>
      router.navigate(
        `/${language}/dashboard/live?sensorId=${activeSensorAssociation}`
      );

    return (
      <>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Avatar
                alt={intl.formatMessage(messages.imageAlt)}
                src={asset.image || '/assets/forkliftPlaceholder.png'}
                style={{
                  width: 200,
                  height: 200,
                  opacity: asset.image ? 1 : 0.1,
                }}
              />
            </div>

            <Box mt={2} mb={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={openTicketForm}
              >
                <FormattedMessage
                  id="dashboard.assets.management.btn_reportbreakdown"
                  defaultMessage="Report Breakdown"
                />
              </Button>
            </Box>

            <Box mb={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => openMaintenanceForm(asset.id)}
              >
                <FormattedMessage
                  id="dashboard.assets.management.btn_maintenance"
                  defaultMessage="Maintenance"
                />
              </Button>
            </Box>

            <Box mb={2}>
              <Button
                variant="contained"
                color="secondary"
                disabled={!asset.preCheckId}
                fullWidth
                onClick={() =>
                  openPreCheckListForm(asset.preCheckId!, asset.id)
                }
              >
                <FormattedMessage
                  id="dashboard.assets.management.btn_precheck"
                  defaultMessage="Pre Check"
                />
              </Button>
            </Box>

            <Card>
              <CardContent>
                <Table className="fleet-information-table">
                  <TableBody>
                    <TableRow hover={false}>
                      <TableCell>
                        <FormattedMessage
                          id="dashboard.assets.label_name"
                          defaultMessage="Name"
                        />
                      </TableCell>
                      <TableCell>{asset.name}</TableCell>
                    </TableRow>
                    <TableRow hover={false}>
                      <TableCell>
                        <FormattedMessage
                          id="dashboard.assets.label_type"
                          defaultMessage="Type"
                        />
                      </TableCell>
                      <TableCell>{getAssetTypeName(asset.type)}</TableCell>
                    </TableRow>
                    <TableRow hover={false}>
                      <TableCell>
                        <FormattedMessage
                          id="dashboard.assets.label_color"
                          defaultMessage="Colour"
                        />
                      </TableCell>
                      <TableCell>
                        {asset.color ? (
                          <div
                            style={{
                              backgroundColor: asset.color,
                              height: '20px',
                              width: '50px',
                            }}
                          />
                        ) : null}
                      </TableCell>
                    </TableRow>
                    <TableRow hover={false}>
                      <TableCell>
                        <FormattedMessage
                          id="dashboard.assets.label_warehouse"
                          defaultMessage="Warehouse"
                        />
                      </TableCell>
                      <TableCell>{asset.warehouseName}</TableCell>
                    </TableRow>
                    <TableRow hover={false}>
                      <TableCell>
                        <FormattedMessage
                          id="dashboard.assets.label_floorplan"
                          defaultMessage="Floor plan"
                        />
                      </TableCell>
                      <TableCell>{asset.floorplanName}</TableCell>
                    </TableRow>
                    <TableRow hover={false}>
                      <TableCell>
                        <FormattedMessage
                          id="dashboard.assets.label_currentsensor"
                          defaultMessage="Current Sensor"
                        />
                      </TableCell>
                      <TableCell>{asset.physicalAddress || ''}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Typography
                  variant="h6"
                  style={{
                    marginTop: '40px',
                    borderBottom: '1px solid #CCC',
                  }}
                >
                  <FormattedMessage
                    id="dashboard.assets.sensorassociations.title"
                    defaultMessage="Sensor Associations"
                  />
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: 10 }}
                        >
                          <FormattedMessage
                            id="dashboard.assets.sensorassociations.from"
                            defaultMessage="From"
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: 10 }}
                        >
                          <FormattedMessage
                            id="dashboard.assets.sensorassociations.to"
                            defaultMessage="To"
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: 10 }}
                        >
                          <FormattedMessage
                            id="dashboard.assets.sensorassociations.sensor"
                            defaultMessage="Sensor"
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {asset.sensorAssociations.map((a, index) => {
                      const start = new Date(warehouseTz, a.from).format();

                      let end = null;
                      if (a.to) {
                        end = new Date(warehouseTz, a.to).format();
                      }

                      return (
                        <TableRow key={index} hover={false}>
                          <TableCell>{start}</TableCell>
                          <TableCell>{end}</TableCell>
                          <TableCell>{a.physicalAddress}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Loading loading={loading}>
                  {activeSensorAssociation ? (
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={goToLiveMap}
                      >
                        <FormattedMessage
                          id="dashboard.assets.management.btn_localize"
                          defaultMessage="Localize"
                        />
                      </Button>
                    </Box>
                  ) : null}
                </Loading>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={9}>
            <Routes>
              <Route
                path="/tab/:tab"
                element={<ManagementDashboard />}
              />
              <Route
                path="/tab/:tab/ticketid/:ticketId"
                element={<TicketDashboard />}
              />
            </Routes>
          </Grid>
        </Grid>
        {ticketForm ? <TicketForm asset={asset} /> : null}
        {preCheckForm ? <PreCheck /> : null}
        {maintenanceForm ? <Maintenance /> : null}
      </>
    );
  }
}

export default injectIntl(Management);
