import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { Component, Fragment } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { messages } from '@definitions/plots';
import MetricMeta from '@models/MetricMeta';
import MetricsProvider from '../../AnalyticsTab/MetricsProvider';

const localMessages = defineMessages({
  selected: {
    defaultMessage: '{active}/{count} selected',
    id: 'dashboard.report.plots.selected',
  },
});

interface IProps {
  filterId: string;
  loading: boolean;
  floorplanSensorPlacement: boolean;
  plots: Record<string, Record<string, any>>;
  handleChange: (e: any) => void;
  handleSectionChange: (
    e: any,
    analytics: { type: string; id: string }[]
  ) => void;
  handleSelectionChange: (
    metridId: string,
    type: string,
    id: string
  ) => (e: any) => void;
  intl: IntlShape;
  metrics: MetricMeta[];
}

interface IState {
  section: Record<string, boolean>;
}

class ReportCompositionForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      section: {},
    };
  }

  public expand(sectionId: string) {
    const { section } = this.state;

    section[sectionId] = !section[sectionId];

    this.setState({ section });
  }

  public countSectionActivePlots(sectionId: string) {
    const { plots } = this.props;

    const pkeys = Object.keys(plots[sectionId] || {});
    let count = 0;
    for (let i = 0; i < pkeys.length; i += 1) {
      if (plots[sectionId][pkeys[i]]) {
        count += 1;
      }
    }

    return count;
  }

  public render() {
    const {
      filterId,
      loading,
      floorplanSensorPlacement,
      handleChange,
      handleSectionChange,
      handleSelectionChange,
      intl,
      metrics,
      plots,
    } = this.props;
    const { section } = this.state;

    return (
      <>
        <div id="floorplan-options">
          <Typography variant="h6" style={{ marginTop: '30px' }}>
            <FormattedMessage
              id="dashboard.report.floorplan.title"
              defaultMessage="Floorplan"
            />
          </Typography>
          <Divider />
          <FormControl required fullWidth margin="normal">
            <FormControlLabel
              control={
                <Checkbox
                  checked={floorplanSensorPlacement}
                  name="floorplanSensorPlacement"
                  onChange={handleChange}
                  disabled={loading}
                />
              }
              label={
                <FormattedMessage
                  id="dashboard.report.floorplan.sensorplacement"
                  defaultMessage="Warehouse floor plan sensor placement"
                />
              }
            />
          </FormControl>
        </div>
        <MetricsProvider section="user" filterId={filterId}>
          <div id="plots-selection" style={{ overflow: 'hidden' }}>
            <Typography variant="h6" style={{ marginTop: '30px' }}>
              <FormattedMessage
                id="dashboard.report.plots.title"
                defaultMessage="Plots"
              />
            </Typography>
            <Divider />
            <Grid container>
              <Card
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  overflow: 'visible',
                  width: '100%',
                }}
              >
                {(metrics || []).map((m) => {
                  const aggregations = Object.values(
                    m.defaultAggregations || {}
                  );
                  let activePlots = 0;
                  let totalPlots = 0;

                  const activeArray: { id: string; type: string }[] = [];
                  if (m.returns.indexOf('fixed_plots') !== -1) {
                    totalPlots += 1;

                    if (((plots[m.id] || {}).fixed_plots || {}).default) {
                      activePlots += 1;
                    }

                    activeArray.push({
                      id: 'default',
                      type: 'fixed_plots',
                    });
                  }

                  Object.values(m.defaultAggregations || {}).forEach(
                    (plot, index) => {
                      totalPlots += 1;

                      if (
                        ((plots[m.id] || {}).aggregation_plots || {})[
                          String(index)
                        ]
                      ) {
                        activePlots += 1;
                      }

                      activeArray.push({
                        id: String(index),
                        type: 'aggregation_plots',
                      });
                    }
                  );

                  (m.returns || [])
                    .filter(
                      (r: string) =>
                        r === 'grid_heatmap' ||
                        r === 'zone_heatmap' ||
                        r === 'trajectories' ||
                        r === 'zone_trajectories' ||
                        r === 'scatter'
                    )
                    .forEach((r) => {
                      totalPlots += 1;

                      if (((plots[m.id] || {}).maps || {})[`${m.id}|${r}`]) {
                        activePlots += 1;
                      }

                      activeArray.push({
                        id: `${m.id}|${r}`,
                        type: 'maps',
                      });
                    });

                  return (
                    <Fragment key={m.id}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginLeft: '-25px',
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            cursor: 'pointer',
                            height: '100%',
                            marginLeft: '60px',
                            position: 'absolute',
                            width: '100%',
                          }}
                          role="button"
                          aria-label=" "
                          tabIndex={0}
                          onClick={() => this.expand(`plot_${m.id}`)}
                          onKeyDown={() => this.expand(`plot_${m.id}`)}
                        />
                        <CardHeader
                          avatar={
                            <Checkbox
                              checked={activePlots === totalPlots}
                              onChange={(e: any) =>
                                handleSectionChange(e, activeArray)
                              }
                              name={m.id}
                              value="default_all"
                              color="primary"
                              disabled={loading}
                            />
                          }
                          title={m.title}
                          subheader={intl.formatMessage(
                            localMessages.selected,
                            {
                              active: activePlots,
                              count: totalPlots,
                            }
                          )}
                        />
                        <div style={{ padding: '25px 5px' }}>
                          {section[`plot_${m.id}`] ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </div>
                      </div>
                      <Divider />
                      <Collapse in={section[`plot_${m.id}`]}>
                        <CardContent>
                          <Grid container>
                            {aggregations.length ? (
                              <>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle2">
                                    <FormattedMessage
                                      id="dashboard.plots.tab.aggregation_plots"
                                      defaultMessage="Aggregation Plots"
                                    />
                                  </Typography>
                                  <Divider />
                                </Grid>
                                {aggregations.map((plot: any, index) => {
                                  let label = '';
                                  if (plot.level1) {
                                    label += ` by ${intl.formatMessage(
                                      messages[`optionType_${plot.level1}`]
                                    )}: ${plot.level1Option}`;
                                  }
                                  if (plot.level2) {
                                    label += `, ${intl.formatMessage(
                                      messages[`optionType_${plot.level2}`]
                                    )}: ${plot.level2Option}`;
                                  }
                                  if (plot.level3) {
                                    label += `, ${intl.formatMessage(
                                      messages[`optionType_${plot.level3}`]
                                    )}: ${plot.level3Option}`;
                                  }

                                  return (
                                    <Grid
                                      key={String(index)}
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <FormControl
                                        required
                                        fullWidth
                                        margin="normal"
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={
                                                ((plots[m.id] || {})
                                                  .aggregation_plots || {})[
                                                  String(index)
                                                ] || false
                                              }
                                              name={String(index)}
                                              onChange={handleSelectionChange(
                                                m.id,
                                                'aggregation_plots',
                                                String(index)
                                              )}
                                              disabled={loading}
                                            />
                                          }
                                          label={
                                            <>
                                              <Typography variant="body1">
                                                {m.title}
                                              </Typography>
                                              <Typography variant="caption">
                                                {label}
                                              </Typography>
                                            </>
                                          }
                                        />
                                      </FormControl>
                                    </Grid>
                                  );
                                })}
                              </>
                            ) : null}
                            {m.returns.indexOf('fixed_plots') !== -1 ? (
                              <>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle2">
                                    <FormattedMessage
                                      id="dashboard.plots.tab.fixed_plots"
                                      defaultMessage="Fixed Plots"
                                    />
                                  </Typography>
                                  <Divider />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <FormControl
                                    required
                                    fullWidth
                                    margin="normal"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            (
                                              (plots[m.id] || {}).fixed_plots ||
                                              {}
                                            ).default || false
                                          }
                                          name="default"
                                          onChange={handleSelectionChange(
                                            m.id,
                                            'fixed_plots',
                                            'default'
                                          )}
                                          disabled={loading}
                                        />
                                      }
                                      label={
                                        <>
                                          <Typography variant="body1">
                                            {m.title}
                                          </Typography>
                                          <Typography variant="caption">
                                            {intl.formatMessage(
                                              messages.returnType_fixed_plots
                                            )}
                                          </Typography>
                                        </>
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                              </>
                            ) : null}
                            {m.returns.indexOf('grid_heatmap') !== -1 ||
                            m.returns.indexOf('zone_heatmap') !== -1 ||
                            m.returns.indexOf('trajectories') !== -1 ||
                            m.returns.indexOf('zone_trajectories') !== -1 ||
                            m.returns.indexOf('scatter') !== -1 ||
                            m.returns.indexOf('trajectories') !== -1 ? (
                              <>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle2">
                                    <FormattedMessage
                                      id="dashboard.plots.tab.maps"
                                      defaultMessage="Maps"
                                    />
                                  </Typography>
                                  <Divider />
                                </Grid>
                                {(m.returns || [])
                                  .filter(
                                    (r: string) =>
                                      r === 'grid_heatmap' ||
                                      r === 'zone_heatmap' ||
                                      r === 'grid_trajectories' ||
                                      r === 'zone_trajectories' ||
                                      r === 'scatter' ||
                                      r === 'trajectories'
                                  )
                                  .map((r) => (
                                    <Grid
                                      key={`${m.id}|${r}`}
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <FormControl
                                        required
                                        fullWidth
                                        margin="normal"
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={
                                                ((plots[m.id] || {}).maps ||
                                                  {})[`${m.id}|${r}`] || false
                                              }
                                              name={`${m.id}|${r}`}
                                              onChange={handleSelectionChange(
                                                m.id,
                                                'maps',
                                                `${m.id}|${r}`
                                              )}
                                              disabled={loading}
                                            />
                                          }
                                          label={
                                            <>
                                              <Typography variant="body1">
                                                {m.title}
                                              </Typography>
                                              <Typography variant="caption">
                                                {intl.formatMessage(
                                                  messages[`returnType_${r}`]
                                                )}
                                              </Typography>
                                            </>
                                          }
                                        />
                                      </FormControl>
                                    </Grid>
                                  ))}
                              </>
                            ) : null}
                          </Grid>
                        </CardContent>
                      </Collapse>
                    </Fragment>
                  );
                })}
              </Card>
            </Grid>
          </div>
        </MetricsProvider>
      </>
    );
  }
}

export default injectIntl(ReportCompositionForm);
