import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closeMaintenanceForm,
  createMaintenance,
  ICreateMaintenance,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import Maintenance from './Maintenance';

const mapStateToProps = (state: IAppState) => ({
  loading: state.maintenances.form.loading,
  error: state.maintenances.form.error,
  assetId: state.maintenances.form.assetId!,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    close: () => dispatch(closeMaintenanceForm()),
    createMaintenance: (properties: ICreateMaintenance) =>
      dispatch(createMaintenance.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
