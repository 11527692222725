import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import {
  getFloorplanSensorGroups,
  isFloorplanSensorGroupsLoading,
} from '@selectors/sensors';
import ConfigFileFormContent from './ConfigFileFormContent';

interface IProps {
  floorplanId?: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  loading: isFloorplanSensorGroupsLoading(state, props.floorplanId || ''),
  sensorGroups: getFloorplanSensorGroups(state, props.floorplanId || ''),
});

export default connect(mapStateToProps)(ConfigFileFormContent);
