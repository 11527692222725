import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  addFilter,
  fetchFloorplan,
  fetchSimulation,
  onChange,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { IFilter, IFilterChange } from '@reducers/filter';
import { getActiveLanguage } from '@selectors/app';
import { getSimulation } from '@selectors/simulations';
import Analytics from './Analytics';

interface IProps {
  router: any;
}

const mapStateToProps = (state: IAppState, props: IProps) => ({
  id: props.router.params.id,
  language: getActiveLanguage(state),
  loading: state.simulations.loading || state.floorplans.loading,
  filters: state.filter.filters,
  simulation: { ...getSimulation(state.simulations, props.router.params.id) },
  simulationRunId: props.router.params.simulationRunId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addFilter: (properties: IFilter) => dispatch(addFilter(properties)),
  fetchFloorplan: (id: string) => dispatch(fetchFloorplan.request({ id })),
  fetchSimulation: (id: string) => dispatch(fetchSimulation.request({ id })),
  onChange: (properties: IFilterChange) => dispatch(onChange(properties)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Analytics)
);
