import { Point, Raster } from 'paper';
import { Component } from 'react';
import uuid from 'uuid/v4';

import HeatMap from '@models/HeatMap';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import ZoneHeatMap from '@models/ZoneHeatMap';
import { Zones } from '@models/Zone';
import {
  generateHeatMapCanvas,
  generateZoneHeatMapCanvas,
} from '@dashboard_utils/index';
import mapEvents, { orderLayers } from '../../eventEmitter';
import MapImages from '../../MapImages';
import Paper from '../../Paper';
import { LayerScale } from '../../LayerScale/LayerScale';
import { defaultTransformationMatrix } from '../../../consts';

interface IProps {
  id: string;
  data: HeatMap | ZoneHeatMap;
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  loading: boolean;
  type: 'zone' | 'grid';
  paper: Paper;
  zones: Zones;
  updateScale: (id: string, scale?: LayerScale) => void;
}

class HeatMapLayer extends Component<IProps> {
  private mounted = false;

  private loadId: string | undefined;

  private elements: paper.Raster | undefined;

  constructor(props: IProps) {
    super(props);

    this.sendToBack = this.sendToBack.bind(this);
  }

  public componentDidMount() {
    this.mounted = true;
    this.loadId = uuid();

    mapEvents.on('order_layers', this.sendToBack);

    this.load(this.loadId);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { data } = this.props;

    if (JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    this.mounted = false;

    mapEvents.removeListener('order_layers', this.sendToBack);

    this.clear();
  }

  public load(loadId: string) {
    const {
      data,
      floorplan,

      type,

      id,
      mapImages,
      paper,
      updateScale,
      zones,
    } = this.props;

    if (type === 'grid') {
      const gridData = data as HeatMap;
      generateHeatMapCanvas(
        mapImages.width,
        mapImages.height,
        gridData,
        floorplan.transformationMatrix || defaultTransformationMatrix,
        floorplan.scale || 1
      ).then((canvas: HTMLCanvasElement) => {
        if (!this.mounted || this.loadId !== loadId) {
          return;
        }

        paper.scope.activate();
        this.elements = new Raster({
          position: new Point(mapImages.width / 2, mapImages.height / 2),
          source: canvas.toDataURL(),
        });

        orderLayers();

        updateScale(id, {
          max: gridData.max_weight || 0,
          min: gridData.min_weight || 0,
          logarithmicScale: gridData.log_scale === true,
          units: gridData.units,
        });

        /*
          *Heatmap cell positions
          mapItems.forEach(mi => {
            const draw = new paper.Path.Circle(
              new paper.Point(
                transformMetersToPixels(
                  mi.coordinates,
                  transformationMatrix,
                  scale
                )
              ),
              4
            ) as ICircleFeature;
            draw.fillColor = 'black';
            draw.transform(new paper.Matrix(1, 0, 0, -1, 0, this.height));
            draw.onClick = () => {
              console.log(
                mi.coordinates,
                transformMetersToPixels(
                  mi.coordinates,
                  transformationMatrix,
                  scale
                )
              )
            }
          });
        */
      });
    } else {
      const zoneData = data as ZoneHeatMap;
      generateZoneHeatMapCanvas(
        mapImages.width,
        mapImages.height,
        zoneData,
        floorplan.transformationMatrix || defaultTransformationMatrix,
        floorplan.scale || 1,
        zones
      ).then((canvas: HTMLCanvasElement) => {
        if (!this.mounted || this.loadId !== loadId) {
          return;
        }

        paper.scope.activate();
        this.elements = new Raster({
          position: new Point(mapImages.width / 2, mapImages.height / 2),
          source: canvas.toDataURL(),
        });

        orderLayers();

        updateScale(id, {
          max: zoneData.max_weight || 0,
          min: zoneData.min_weight || 0,
          logarithmicScale: zoneData.log_scale === true,
          units: zoneData.units,
        });
      });
    }
  }

  public reload() {
    this.loadId = uuid();

    this.clear();

    this.load(this.loadId);
  }

  public clear() {
    const { id, updateScale } = this.props;

    updateScale(id);
    if (this.elements) {
      this.elements.remove();
    }
  }

  public sendToBack(layerType: string) {
    if (layerType === 'heatmap' && this.elements) {
      this.elements.sendToBack();
    }
  }

  public render() {
    return null;
  }
}

export default HeatMapLayer;
