import { take } from 'lodash';
import { Color, Path, Point, Matrix } from 'paper';
import { Component } from 'react';
import sstc from 'string-to-color';

import { transformMetersToPixels } from '@dashboard_utils/index';
import CoverageMap from '@models/CoverageMap';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Sensor from '@models/Sensor';
import mapEvents, { orderLayers } from '../../eventEmitter';
import MapImages from '../../MapImages';
import Paper from '../../Paper';
import { IPathFeature, IRegularFeature } from '../../../types';
import { defaultTransformationMatrix } from '../../../consts';

interface IProps {
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  paper: Paper;
  sensors: Sensor[];
  loading: boolean;
  data: CoverageMap;
}

interface ISensorShadow {
  physicalAddress: string;
  sensorPath: IRegularFeature;
}

class CoverageMapLayer extends Component<IProps> {
  private elements: IPathFeature[] = [];

  private sensorsShadow: ISensorShadow[] = [];

  constructor(props: IProps) {
    super(props);

    this.reload = this.reload.bind(this);
  }

  public componentDidMount() {
    this.load();

    mapEvents.on('resized', this.reload);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { loading } = this.props;

    if (prevProps.loading !== loading) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    mapEvents.removeListener('resized', this.reload);

    this.clear();
  }

  public load() {
    const { data, floorplan, mapImages, paper, sensors } = this.props;

    paper.scope.activate();

    this.elements = (data.anchors || []).map((anchor, index) => {
      const anchorPath = new Path() as IPathFeature;
      anchor.contours.forEach((anchorSpot) => {
        anchorSpot.forEach((position) => {
          anchorPath.add(
            new Point(
              transformMetersToPixels(
                [position[0], position[1]],
                floorplan.transformationMatrix || defaultTransformationMatrix,
                floorplan.scale || 1
              )
            )
          );
        });
      });

      const ancorColor = sstc(anchor.anchor);
      const color = new Color(ancorColor);
      anchorPath.strokeColor = color;
      anchorPath.strokeWidth = 3;
      const fillColor = new Color(ancorColor);
      fillColor.alpha = 0.1;
      anchorPath.fillColor = fillColor;
      anchorPath.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

      anchorPath.featureInfo = {
        id: String(index),
        title: anchor.anchor,
        type: 'coverage',
      };

      if (
        !this.sensorsShadow.find((s) => s.physicalAddress === anchor.anchor)
      ) {
        const sensor = sensors.find((s) => s.physicalAddress === anchor.anchor);

        if (sensor) {
          let sensorPosition = [0, 0];
          if (sensor.position) {
            sensorPosition = transformMetersToPixels(
              take(sensor.position, 2) as [number, number],
              floorplan.transformationMatrix || defaultTransformationMatrix,
              floorplan.scale || 1
            );
          }

          const point = new Point(sensorPosition);
          const sensorPath = new Path.RegularPolygon(
            point,
            4,
            12 / paper.getZoom()
          ) as IRegularFeature;
          sensorPath.featureInfo = {
            id: sensor.id,
            props: {
              physicalAddress: sensor.physicalAddress,
              type: sensor.type,
              x: (sensor.position || [0, 0, 0])[0].toFixed(2),
              y: (sensor.position || [0, 0, 0])[1].toFixed(2),
              z: (sensor.position || [0, 0, 0])[2].toFixed(2),
            },
            title: 'Stationary',
            type: 'sensors',
          };
          sensorPath.fillColor = color;
          sensorPath.strokeColor = color;
          sensorPath.strokeWidth = 4;
          sensorPath.strokeScaling = false;
          sensorPath.rotate(45, point);
          sensorPath.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));

          this.sensorsShadow.push({
            physicalAddress: sensor.physicalAddress,
            sensorPath,
          });
        }
      }

      return anchorPath;
    });

    this.sensorsShadow.forEach((e) => e.sensorPath.bringToFront());

    orderLayers();
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((e) => e.remove());
    this.sensorsShadow.forEach((e) => e.sensorPath.remove());

    this.elements = [];
    this.sensorsShadow = [];
  }

  public render() {
    return null;
  }
}

export default CoverageMapLayer;
