/* eslint-disable react/prop-types */
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { FunctionComponent } from 'react';
import { WidgetProps } from 'react-jsonschema-form';

const handleChange = (onChange: (value: any) => void) => (event: any) =>
  onChange(event.target.value);

interface ISelectOption {
  value: string;
  label: string;
}

interface ISelectProps {
  options: ISelectOption[];
  onChange: (event: any) => void;
}

interface IMultiSelectProps extends ISelectProps {
  value?: ISelectOption['value'][];
}

interface ISingleSelectProps extends ISelectProps {
  value?: ISelectOption['value'];
}

function renderMultiSelectValue(options: ISelectOption[]) {
  return (value: any) => {
    const values: string[] = value;

    return values.map((v) => {
      const option = options.find((o) => o.value === v);

      return (
        <Chip
          style={{ margin: 1 }}
          key={v}
          label={(option && option.label) || ''}
        />
      );
    });
  };
}

const MultiSelect: FunctionComponent<IMultiSelectProps> = ({
  onChange,
  value = [],
  options,
}: IMultiSelectProps) => (
  <Select
    multiple
    value={value}
    onChange={onChange}
    renderValue={renderMultiSelectValue(options)}
  >
    {options.map((v: ISelectOption) => (
      <MenuItem key={v.value} value={v.value}>
        <Checkbox checked={value.indexOf(v.value) !== -1} />
        <ListItemText primary={v.label} />
      </MenuItem>
    ))}
  </Select>
);

const SingleSelect: FunctionComponent<ISingleSelectProps> = ({
  onChange,
  value = undefined,
  options,
}: ISingleSelectProps) => (
  <Select value={value || ''} onChange={onChange}>
    {options.map((v: ISelectOption) => (
      <MenuItem key={v.value} value={v.value}>
        {v.label}
      </MenuItem>
    ))}
  </Select>
);

const SelectWidget: FunctionComponent<WidgetProps> = ({
  // @ts-ignore
  multiple,
  options,
  value,
  onChange,
  schema: { title, description },
  required,
  rawErrors = [],
  ...props
}: WidgetProps) => {
  const enumOptions: ISelectOption[] = (options as any).enumOptions || [];

  return (
    <FormControl required={required} fullWidth margin="normal">
      <InputLabel>{title}</InputLabel>
      {multiple ? (
        <MultiSelect
          {...props}
          onChange={handleChange(onChange)}
          value={value}
          options={enumOptions}
        />
      ) : (
        <SingleSelect
          {...props}
          onChange={handleChange(onChange)}
          value={value}
          options={enumOptions}
        />
      )}
      {description ? <FormHelperText>{description}</FormHelperText> : null}
      {rawErrors.map((error: string) => (
        <FormHelperText key={error} error>
          {error}
        </FormHelperText>
      ))}
    </FormControl>
  );
};

export default SelectWidget;
