import { ReactourStep } from 'reactour';
import analytics from './analyticsWalkthrough';
import dashboard from './dashboardWalkthrough';
import live from './liveWalkthrough';
import player from './playerWalkthrough';
import reports from './reportsWalkthrough';
import rules from './rulesWalkthrough';
import sensors from './sensorsWalkthrough';
import wellcome from './wellcomeWalkthrough';

export default (
  section: string,
  disableTour: () => void,
  disabledTour: boolean
): ReactourStep[] => {
  switch (section) {
    case 'analytics':
      return analytics(disableTour, disabledTour);
    case 'dashboard':
      return dashboard(disableTour, disabledTour);
    case 'live':
      return live(disableTour, disabledTour);
    case 'player':
      return player(disableTour, disabledTour);
    case 'reports':
      return reports(disableTour, disabledTour);
    case 'rules':
      return rules(disableTour, disabledTour);
    case 'sensors':
      return sensors(disableTour, disabledTour);
    case 'wellcome':
      return wellcome(disableTour, disabledTour);
    default:
      return [];
  }
};
