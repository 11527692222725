import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  createAssetTicketOperation,
  CreateAssetTicketOperation,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import TicketOperationForm from './TicketOperationForm';

const mapStateToProps = (state: IAppState) => ({
  error: state.assets.tickets.operationForm.error,
  loading: state.assets.tickets.operationForm.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createAssetTicketOperation: (properties: CreateAssetTicketOperation) =>
    dispatch(createAssetTicketOperation.request(properties)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketOperationForm);
