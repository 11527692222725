import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  dismissNotifications,
  INotificationId,
  unreadNotifications,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getActiveLanguage, getSelectedLocale } from '@selectors/app';
import { isDismissLoading } from '@selectors/notifications';
import {
  getRuleAlerts,
  getValidRules,
  isRuleAlertsLoading,
} from '@selectors/rules';
import AlertsTable from './AlertsTable';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;

  const filter = state.filter.filters[filterId] || {};

  return {
    dismissLoading: isDismissLoading(state),
    language: getActiveLanguage(state),
    loading:
      state.rules.loading ||
      state.rules.templatesLoading ||
      isRuleAlertsLoading(state, filterId),
    locale: getSelectedLocale(state),
    measurementUnits: getAccountMeasurementUnits(state.authentication),
    ruleAlerts: getRuleAlerts(state, filterId),
    rules: getValidRules(state),
    templates: state.rules.templates,

    warehouseId: filter.warehouseId || '',
    warehouses: state.warehouses.warehouses,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dismissNotifications: (
    ids: INotificationId[],
    locale: string,
    units: string
  ) => dispatch(dismissNotifications.request({ ids, locale, units })),
  unreadNotifications: (
    ids: INotificationId[],
    locale: string,
    units: string
  ) => dispatch(unreadNotifications.request({ ids, locale, units })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AlertsTable)
);
