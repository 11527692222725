import {
  // @ts-ignore
  create,
  // @ts-ignore
  multiplyDependencies,
} from 'mathjs';

import { IGeoMapping } from '@models/IncompleteFloorplan';
import { transformMeters } from '@dashboard_utils/index';
import { TransformationMatrix2D } from '../../../utils';

const { multiply } = create({
  multiplyDependencies,
});

interface ILatLong {
  latitude: number;
  longitude: number;
}
interface IECEF {
  x: number;
  y: number;
  z: number;
}
interface IENU {
  up: number;
  east: number;
  north: number;
}
interface IDelta {
  angleDiff: number;
  transformationAngle: number;
}

// https://en.wikipedia.org/wiki/World_Geodetic_System#WGS84
const semiMajorAxis = 6378137.0;
const semiMinorAxis = 6356752.31424518;

const radsToDegrees = (rad: number) => (rad * 180) / Math.PI;
const degreesToRad = (degrees: number) => (degrees * Math.PI) / 180;

/**
 * @description Convert ECEF (meters) to geodetic coordinates
 *              based on:
 *              You, Rey-Jer. (2000). Transformation of Cartesian to Geodetic Coordinates without Iterations.
 *              Journal of Surveying Engineering. doi: 10.1061/(ASCE)0733-9453
 * @param       {number} x Target x ECEF coordinate (meters)
 * @param       {number} y Target y ECEF coordinate (meters)
 * @param       {number} z Target z ECEF coordinate (meters)
 */
export const ecef2geodetic = (x: number, y: number, z: number) => {
  const r = Math.sqrt(x ** 2 + y ** 2 + z ** 2);
  const E = Math.sqrt(semiMajorAxis ** 2 - semiMinorAxis ** 2);
  const u = Math.sqrt(
    0.5 * (r ** 2 - E ** 2) +
      0.5 * Math.sqrt((r ** 2 - E ** 2) ** 2 + 4 * E ** 2 * z ** 2)
  );
  const Q = Math.hypot(x, y);
  const huE = Math.hypot(u, E);

  let Beta;
  try {
    Beta = Math.atan(((huE / u) * z) / Math.hypot(x, y));
  } catch (e) {
    if (z >= 0) {
      Beta = Math.PI / 2;
    } else {
      Beta = -Math.PI / 2;
    }
  }

  const eps =
    ((semiMinorAxis * u - semiMajorAxis * huE + E ** 2) * Math.sin(Beta)) /
    ((semiMajorAxis * huE * 1) / Math.cos(Beta) - E ** 2 * Math.cos(Beta));

  Beta += eps;
  const lat = Math.atan((semiMajorAxis / semiMinorAxis) * Math.tan(Beta));
  const long = Math.atan2(y, x);
  let alt = Math.hypot(
    z - semiMinorAxis * Math.sin(Beta),
    Q - semiMajorAxis * Math.cos(Beta)
  );

  const inside =
    x ** 2 / semiMajorAxis ** 2 +
      y ** 2 / semiMajorAxis ** 2 +
      z ** 2 / semiMinorAxis ** 2 <
    1;

  if (inside) {
    alt = -alt;
  }

  return { lat: radsToDegrees(lat), long: radsToDegrees(long), alt };
};

export const geodetic2ecef = (position: ILatLong): IECEF => {
  // !This implementation assumes 0 altitude
  const altitude = 0;

  const latitude = degreesToRad(position.latitude);
  const longitude = degreesToRad(position.longitude);

  // Radius of curvature of the prime vertical section
  const N =
    semiMajorAxis ** 2 /
    Math.sqrt(
      semiMajorAxis ** 2 * Math.cos(latitude) ** 2 +
        semiMinorAxis ** 2 * Math.sin(latitude) ** 2
    );
  // Compute cartesian (geocentric) coordinates given (curvilinear) geodetic coordinates.
  const x = (N + altitude) * Math.cos(latitude) * Math.cos(longitude);
  const y = (N + altitude) * Math.cos(latitude) * Math.sin(longitude);

  const z =
    (N * (semiMinorAxis / semiMajorAxis) ** 2 + altitude) * Math.sin(latitude);

  return { x, y, z };
};

const uvw2enu = (u: number, v: number, w: number, center: ILatLong): IENU => {
  const latitude = degreesToRad(center.latitude);
  const longitude = degreesToRad(center.longitude);

  const t = Math.cos(longitude) * u + Math.sin(longitude) * v;
  const east = -Math.sin(longitude) * u + Math.cos(longitude) * v;
  const up = Math.cos(latitude) * t + Math.sin(latitude) * w;
  const north = -Math.sin(latitude) * t + Math.cos(latitude) * w;

  return { east, up, north };
};

/**
 * @description ENU to UVW
 * @param       {number}   east     Target east ENU coordinate (meters)
 * @param       {number}   north    Target north ENU coordinate (meters)
 * @param       {number}   up       Target up ENU coordinate (meters)
 * @param       {ILatLong} position Lat/long position to convert
 */
export const enu2uvw = (
  east: number,
  north: number,
  up: number,
  position: ILatLong
) => {
  const latitude = degreesToRad(position.latitude);
  const longitude = degreesToRad(position.longitude);

  const t = Math.cos(latitude) * up - Math.sin(latitude) * north;
  const w = Math.sin(latitude) * up + Math.cos(latitude) * north;

  const u = Math.cos(longitude) * t - Math.sin(longitude) * east;
  const v = Math.sin(longitude) * t + Math.cos(longitude) * east;

  return [u, v, w];
};

/**
 * @description Converts geodetic coordinates to enu, transcripted from:
 *              https://github.com/scivision/pymap3d/blob/master/pymap3d/enu.py
 * @param       {ILatLong} position Lat/long position to convert
 * @param       {ILatLong} center   Lat/long center reference
 */
export const geodetic2enu = (position: ILatLong, center: ILatLong): IENU => {
  const positionECEF: IECEF = geodetic2ecef(position);
  const centerECEF: IECEF = geodetic2ecef(center);

  return uvw2enu(
    positionECEF.x - centerECEF.x,
    positionECEF.y - centerECEF.y,
    positionECEF.z - centerECEF.z,
    center
  );
};

/**
 * @description ENU to ECEF
 * @param       {number} e1         Target east ENU coordinate (meters)
 * @param       {number} n1         Target north ENU coordinate (meters)
 * @param       {number} u1         Target up ENU coordinate (meters)
 * @param       {ILatLong} position Lat/long observer position
 */
export const enu2ecef = (
  e1: number,
  n1: number,
  u1: number,
  position: ILatLong
) => {
  const { x, y, z } = geodetic2ecef(position);
  const [dx, dy, dz] = enu2uvw(e1, n1, u1, position);

  return [x + dx, y + dy, z + dz];
};

/**
 * @description Converts enu coordinates to geodetic, transcripted from:
 *              https://github.com/scivision/pymap3d/blob/master/pymap3d/enu.py
 * @param       {number}  e         East to target to geodetic coordinates
 * @param       {number}  n         North to target to geodetic coordinates
 * @param       {number}  u         Up to target to geodetic coordinates
 * @param       {ILatLong} position Lat/long observer position
 */

export const enu2geodetic = (
  e: number,
  n: number,
  u: number,
  position: ILatLong
) => {
  const [x, y, z] = enu2ecef(e, n, u, position);

  return ecef2geodetic(x, y, z);
};

class ENULocations {
  private geoMapping: [IGeoMapping, IGeoMapping];

  private transformationMatrix: TransformationMatrix2D;

  constructor(
    geoMapping: [IGeoMapping, IGeoMapping],
    transformationMatrix: TransformationMatrix2D
  ) {
    this.geoMapping = [geoMapping[0], geoMapping[1]];
    this.transformationMatrix = transformationMatrix;
  }

  /**
   * @description Devolves rotation in degrees
   */
  public getDeltas(degrees = true): IDelta {
    const center = this.getCenter();

    // Find rotation of local coordinate points in relation to floorplan image
    const transformedO1Vector = transformMeters(
      [1, 0],
      this.transformationMatrix
    );
    const transformedOrigin = transformMeters(
      [0, 0],
      this.transformationMatrix
    );
    const transformationDeltaX = transformedO1Vector[0] - transformedOrigin[0];
    const transformationDeltaY = transformedO1Vector[1] - transformedOrigin[1];
    const transformationAngle = -Math.atan2(
      transformationDeltaY,
      transformationDeltaX
    );

    // Find rotation of local coordinate points in the geo mapping
    const deltaX =
      this.geoMapping[1].position[0] - this.geoMapping[0].position[0];
    const deltaY =
      this.geoMapping[1].position[1] - this.geoMapping[0].position[1];
    const localAngle = Math.atan2(deltaY, deltaX);

    const coord1ENU = geodetic2enu(this.geoMapping[0].coordinate, center);
    const coord2ENU = geodetic2enu(this.geoMapping[1].coordinate, center);
    const deltaNorth = coord2ENU.north - coord1ENU.north;
    const deltaEast = coord2ENU.east - coord1ENU.east;
    const enuAngle = Math.atan2(deltaNorth, deltaEast);

    const angleDiff =
      ((localAngle - enuAngle + 3 * Math.PI) % (2 * Math.PI)) - Math.PI;

    return {
      angleDiff: degrees ? radsToDegrees(angleDiff) : angleDiff,
      transformationAngle: degrees
        ? radsToDegrees(transformationAngle)
        : transformationAngle,
    };
  }

  /**
   * @description Devolves ENU offset in meters
   */
  public getENUCenter(): [number, number] {
    return [
      (this.geoMapping[0].position[0] + this.geoMapping[1].position[0]) / 2,
      (this.geoMapping[0].position[1] + this.geoMapping[1].position[1]) / 2,
    ];
  }

  /**
   * @description Devolves geodetic center of reference points
   */
  public getCenter(): ILatLong {
    // Find center for ENU transformation
    const point1: ILatLong = this.geoMapping[0].coordinate;
    const point2: ILatLong = this.geoMapping[1].coordinate;

    return {
      latitude: (point1.latitude + point2.latitude) / 2,
      longitude: (point1.longitude + point2.longitude) / 2,
    };
  }

  /**
   * @description Devolves geo transformation matrix
   */
  public getENUTransformationMatrix(): TransformationMatrix2D | undefined {
    const enuCenter = this.getENUCenter();
    const deltas = this.getDeltas(false);
    const rotation = [
      [Math.cos(deltas.angleDiff), -Math.sin(deltas.angleDiff), 0],
      [Math.sin(deltas.angleDiff), Math.cos(deltas.angleDiff), 0],
      [0, 0, 1],
    ];

    const translation = [
      [1, 0, enuCenter[0]],
      [0, 1, enuCenter[1]],
      [0, 0, 1],
    ];

    return multiply(translation, rotation) as TransformationMatrix2D;
  }
}

export default ENULocations;
