import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchFloorplanSensorGroupStatus,
  fetchFloorplanSensorsStatus,
  IFetchFloorplanSensors,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import { getCompleteFloorplans } from '@selectors/index';
import {
  getFloorplanSensorGroupWithStatus,
  getFloorplanSensorsWithStatus,
  isFloorplanSensorGroupWithStatusLoading,
  isFloorplanSensorsWithStatusLoading,
} from '@selectors/sensors';
import SensorsTab from './SensorsTab';

import './index.css';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const filter = state.filter.filters[props.filterId] || {};

  return {
    account: state.authentication.account!,
    floorplanId: filter.floorplanId || '',
    floorplans: getCompleteFloorplans(state),
    group: getFloorplanSensorGroupWithStatus(state, filter.floorplanId || ''),
    groupLoading: isFloorplanSensorGroupWithStatusLoading(
      state,
      filter.floorplanId || ''
    ),
    language: getActiveLanguage(state),
    loading: isFloorplanSensorsWithStatusLoading(
      state,
      filter.floorplanId || ''
    ),
    sensors: getFloorplanSensorsWithStatus(state, filter.floorplanId || ''),
    warehouseId: filter.warehouseId || '',
    warehouses: state.warehouses.warehouses,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchGroupsStatus: (properties: IFetchFloorplanSensors) =>
    dispatch(fetchFloorplanSensorGroupStatus.request(properties)),
  fetchSensorsStatus: (properties: IFetchFloorplanSensors) =>
    dispatch(fetchFloorplanSensorsStatus.request(properties)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SensorsTab)
);
