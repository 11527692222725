export const data = {
  countries: [
    {
      countryCode: 'GB',
      name: 'United Kingdom',
      expenses: { trip: 150, stays: 100, meals: 50 },
      measurementUnits: 'imperial',
      currency: 'pound',
    },
    {
      countryCode: 'PT',
      name: 'Portugal',
      expenses: { trip: 50, stays: 60, meals: 30 },
      measurementUnits: 'si',
      currency: 'euro',
    },
    {
      countryCode: 'FR',
      name: 'France',
      expenses: { trip: 150, stays: 100, meals: 40 },
      measurementUnits: 'si',
      currency: 'euro',
    },
    {
      countryCode: 'ES',
      name: 'Spain',
      expenses: { trip: 150, stays: 100, meals: 40 },
      measurementUnits: 'si',
      currency: 'euro',
    },
    {
      countryCode: 'RO',
      name: 'Romenia',
      expenses: { trip: 250, stays: 40, meals: 30 },
      measurementUnits: 'si',
      currency: 'euro',
    },
  ],
  services: [{ code: 'consulting', name: 'Consulting Services', cost: 200 }],
  packages: [
    { code: 'essentials', name: 'Essentials', cost: 0 },
    { code: 'advanced', name: 'Advanced', cost: 1000 },
    { code: 'enterprise', name: 'Enterprise', cost: 2000 },
  ],
  subscriptions: [
    { code: 'pilot', name: 'Pilot', costs: { wifi: 100, cellular: 105 } },
    { code: 'partner', name: 'Partner', costs: { wifi: 50, cellular: 53 } },
  ],
  tagCosts: { outdoor: 60, indoor: 50 },
  anchorCosts: { wired: 60, battery: 100 },
  additionalCosts: { wire: 2, wireLengthByAnchor: 12.5 },
  materialConsumption: {
    openSpace: { twoDimension: 1, threeDimension: 2, area: 500 },
    corridor: { twoDimension: 2, threeDimension: 3, length: 50 },
  },
  labor: { manHour: 125, anchor: { wired: 0.17, battery: 0.08 }, tag: 0.17 },
  leasing: {
    hardwareCoefficient: 0.1,
    hardwareDepreciation: 3,
    costOfCapital: 0.1,
  },
  installTime: {
    install: { twoDimension: 7, threeDimension: 9 },
    uninstall: { twoDimension: 5, threeDimension: 6 },
    uninstallRatio: 0.6,
  },
  maintenanceCosts: { twoDimension: 900, threeDimension: 1000 },
};

export const PALLET_PER_TRUCK = 33;
export const PALLET_MOVEMENT_PRICE = 5.28;

export const YEARLY_COSTS = 18294.2;
export const DAYS_DOWN_AFTER_CRASH = 5;
export const COMPLIANCE_FEES = 1100;

export enum ForkliftTypes {
  gas = 'Gas',
  diesel = 'Diesel',
  electric = 'Electric',
}

interface IGoal {
  code: string;
  name: string;
}

export const goals: IGoal[] = [
  {
    code: 'productivity',
    name: 'Productivity - Increase Capacity',
  },
  {
    code: 'efficiency',
    name: 'Efficiency - Decrease costs',
  },
];

export enum Dimensions {
  twoD = 'twoDimension',
  threeD = 'threeDimension',
}

interface IForkliftCosts {
  newPrice: number;
  depreciation: number;
  maintenance: number;
  fuelCost: number;
  hourlyConsumption: number;
}

export interface ICountry {
  countryCode: string;
  expenses: {
    trip: number;
    stays: number;
    meals: number;
  };
  measurementUnits: 'si' | 'imperial';
  currency: 'euro' | 'pound';
}

export interface IService {
  code: string;
  name: string;
  cost: number;
}

export interface IPackage {
  code: string;
  name: string;
  cost: number;
}

export interface ISubscription {
  code: string;
  name: string;
  costs: {
    wifi: number;
    cellular: number;
  };
}

export interface ITagCosts {
  outdoor: number;
  indoor: number;
}

export interface IAnchorCosts {
  wired: number;
  battery: number;
}

export interface IAdditionalCosts {
  wire: number;
  wireLengthByAnchor: number;
}

export interface IMaterialConsumption {
  openSpace: { twoDimension: number; threeDimension: number; area: number };
  corridor: { twoDimension: number; threeDimension: number; length: number };
}

export interface ILeasing {
  hardwareCoefficient: number; // %
  hardwareDepreciation: number; // years
  costOfCapital: number; // %
}

export interface ILabor {
  manHour: number;
  anchor: {
    wired: number;
    battery: number;
  };
  tag: number;
}

export interface IInstallTime {
  install: {
    twoDimension: number;
    threeDimension: number;
  };
  uninstall: {
    twoDimension: number;
    threeDimension: number;
  };
  uninstallRatio: number;
}

export interface IMaintenanceCosts {
  twoDimension: number;
  threeDimension: number;
}

export interface CalculatorData {
  countries: ICountry[];
  services: IService[];
  packages: IPackage[];
  subscriptions: ISubscription[];
  anchorCosts: IAnchorCosts;
  tagCosts: ITagCosts;
  additionalCosts: IAdditionalCosts;
  materialConsumption: IMaterialConsumption;
  leasing: ILeasing;
  labor: ILabor;
  installTime: IInstallTime;
  maintenanceCosts: IMaintenanceCosts;
}

export interface IAdditionalService extends IService {
  days: number;
}

export const FORKLIFT_DATA: Record<keyof typeof ForkliftTypes, IForkliftCosts> =
  {
    gas: {
      newPrice: 23000,
      depreciation: 7,
      maintenance: 1.15,
      fuelCost: 0.53,
      hourlyConsumption: 5.04,
    },
    electric: {
      newPrice: 34000,
      depreciation: 7,
      maintenance: 0.9,
      fuelCost: 0.1,
      hourlyConsumption: 4,
    },
    diesel: {
      newPrice: 25000,
      depreciation: 7,
      maintenance: 1.15,
      fuelCost: 1.4,
      hourlyConsumption: 3,
    },
  };

export const calcTotalCostsOfForklift = (
  type: keyof typeof ForkliftTypes,
  hours: number
) => {
  const fkData = FORKLIFT_DATA[type];

  const depreciationCost = fkData.newPrice / fkData.depreciation;

  const maintenanceCosts = fkData.maintenance * hours;

  const cost = fkData.fuelCost * fkData.hourlyConsumption * hours;

  return depreciationCost + maintenanceCosts + cost;
};

export const calcEmissionsOfForklift = (
  type: keyof typeof ForkliftTypes,
  hours: number
) => {
  const fkData = FORKLIFT_DATA[type];

  return hours + fkData.hourlyConsumption;
};

export const formatPrice = (price: number | null) =>
  `€ ${(Math.round((price || 0) * 100) / 100)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;

export const roundValue = (value: number | null, decimals = 1) =>
  Math.round((value || 0) * 10 ** decimals) / 10 ** decimals;

class Calc {
  private data: any;

  private country: string;

  private numberOfForklifts: number;

  private contractType: string;

  private anchorType: string;

  private tracking: string;

  private connectivity: string;

  private additionalServices: IAdditionalService[];

  private warehouseArea: number;

  private subscriptionType: string;

  private hardwareOption: string;

  private pilotDuration: number;

  private aisleLength: number;

  private numberOfAisles: number;

  private numberOfOpenAreas: number;

  private openAreasSpace: number[];

  private goal: string;

  private numberOfShifts: number;

  private shiftHours: number;

  private workingDays: number;

  private forkliftType: keyof typeof ForkliftTypes;

  private numberOfMovedPallets: number;

  public usedFleetCapacity: number;

  private palletMovementPrice: number;

  private margin: number;

  private numberOfAccidents: number;

  private avgReparationCost: number;

  private daysDownAfterCrash: number;

  private complianceFees: number;

  private estimatedGainsInPalletMoved: number;

  private estimatedReductionInAccidents: number;

  private dimension: Dimensions;

  constructor(
    d: any,

    country = 'PT',
    numberOfForklifts = 100,
    contractType = 'partner',
    anchorType = 'wired',
    tracking = 'indoor',
    connectivity = 'wifi',
    additionalServices: IAdditionalService[] = [],
    warehouseArea = 1000,
    subscriptionType = 'essentials',
    hardwareOption = 'leased',
    pilotDuration = 6,
    aisleLength = 200,
    numberOfAisles = 50,
    numberOfOpenAreas = 1,
    openAreasSpace: number[] = [500],

    goal = 'productivity',
    numberOfShifts = 3,
    shiftHours = 8,
    workingDays = 225,
    forkliftType: keyof typeof ForkliftTypes = 'electric',
    numberOfMovedPallets = 100000,
    usedFleetCapacity = 70,
    palletMovementPrice = 4.8,
    margin = 20,
    numberOfAccidents = 1,
    avgReparationCost = 750,
    daysDownAfterCrash = 5,
    complianceFees = 1000,
    estimatedGainsInPalletMoved = 20,
    estimatedReductionInAccidents = 10,

    dimension: Dimensions = Dimensions.threeD
  ) {
    this.data = d;

    this.country = country;
    this.numberOfForklifts = numberOfForklifts;
    this.contractType = contractType;
    this.anchorType = anchorType;
    this.tracking = tracking;
    this.connectivity = connectivity;
    this.additionalServices = additionalServices;
    this.warehouseArea = warehouseArea;
    this.subscriptionType = subscriptionType;
    this.hardwareOption = hardwareOption;
    this.pilotDuration = pilotDuration;
    this.aisleLength = aisleLength;
    this.numberOfAisles = numberOfAisles;
    this.numberOfOpenAreas = numberOfOpenAreas;
    this.openAreasSpace = openAreasSpace;

    this.goal = goal;
    this.numberOfShifts = numberOfShifts;
    this.shiftHours = shiftHours;
    this.workingDays = workingDays;
    this.forkliftType = forkliftType;
    this.numberOfMovedPallets = numberOfMovedPallets;
    this.usedFleetCapacity = usedFleetCapacity;
    this.palletMovementPrice = palletMovementPrice;
    this.margin = margin;
    this.numberOfAccidents = numberOfAccidents;
    this.avgReparationCost = avgReparationCost;
    this.daysDownAfterCrash = daysDownAfterCrash;
    this.complianceFees = complianceFees;
    this.estimatedGainsInPalletMoved = estimatedGainsInPalletMoved;
    this.estimatedReductionInAccidents = estimatedReductionInAccidents;

    this.dimension = dimension;
  }

  public getNumberOfCorridorAnchors() {
    const numberOfAnchors =
      this.data.materialConsumption.corridor[this.dimension];
    const spaceCovered = this.data.materialConsumption.corridor.length;

    return Math.ceil(this.aisleLength / spaceCovered) * numberOfAnchors;
  }

  public getNumberOfOpenAreasAnchors() {
    const numberOfAnchors =
      this.data.materialConsumption.openSpace[this.dimension];
    const spaceCovered = this.data.materialConsumption.openSpace.area;

    return this.openAreasSpace
      .map((a) => Math.ceil(a / spaceCovered) * numberOfAnchors)
      .reduce((a, b) => a + b, 0);
  }

  public getTotalOfAnchors() {
    return (
      this.getNumberOfCorridorAnchors() * this.numberOfAisles +
      this.getNumberOfOpenAreasAnchors()
    );
  }

  public getAncorAreaRatio() {
    return (
      Math.round((this.warehouseArea / this.getTotalOfAnchors()) * 10) / 10
    );
  }

  public getAnchorsCosts() {
    return (
      this.getTotalOfAnchors() *
      this.data.anchorCosts[this.anchorType as 'wired' | 'battery']
    );
  }

  public getTagsCosts() {
    return (
      this.numberOfForklifts *
      this.data.tagCosts[this.tracking as 'indoor' | 'outdoor']
    );
  }

  public getAdditionalCosts() {
    return (
      this.getTotalOfAnchors() *
      this.data.additionalCosts.wire *
      this.data.additionalCosts.wireLengthByAnchor
    );
  }

  public getLeasingCost() {
    if (this.contractType === 'pilot' || this.hardwareOption !== 'leased') {
      return null;
    }

    const hardwareCost =
      this.getAnchorsCosts() + this.getTagsCosts() + this.getAdditionalCosts();
    const hardwareCostWithCoefficient =
      hardwareCost * (1 + this.data.leasing.hardwareCoefficient);
    const hardwareFutureValue =
      hardwareCostWithCoefficient *
      (1 + this.data.leasing.costOfCapital) **
        (this.data.leasing.hardwareDepreciation - 1);

    return (
      (hardwareFutureValue * this.data.leasing.costOfCapital) /
      ((1 + this.data.leasing.costOfCapital) **
        this.data.leasing.hardwareDepreciation -
        1)
    );
  }

  public getHardwareCost() {
    if (this.contractType === 'pilot' || this.hardwareOption !== 'leased') {
      return (
        this.getTagsCosts() + this.getAdditionalCosts() + this.getAnchorsCosts()
      );
    }

    return this.getLeasingCost();
  }

  public getSoftwareCost() {
    const subscriptionData = this.data.subscriptions.find(
      (subscription: any) => subscription.code === this.contractType
    );
    const subscriptionCost =
      subscriptionData!.costs[(this.connectivity as 'wifi') || 'cellular'] *
      (this.contractType === 'pilot' ? this.pilotDuration : 12);

    return Math.round(subscriptionCost * this.numberOfForklifts * 100) / 100;
  }

  public getAnchorsInstallationCosts() {
    const installCostOfAnchor =
      this.data.labor.anchor[this.anchorType as 'wired' | 'battery'];

    return (
      this.getTotalOfAnchors() * installCostOfAnchor * this.data.labor.manHour
    );
  }

  public getTagInstallationCosts() {
    return (
      this.numberOfForklifts * this.data.labor.tag * this.data.labor.manHour
    );
  }

  public getUninstallementCosts() {
    return (
      this.getAnchorsInstallationCosts() *
        this.data.installTime.uninstallRatio +
      this.getTagInstallationCosts() * this.data.installTime.uninstallRatio
    );
  }

  public getTripExpenses(type: 'install' | 'uninstall') {
    const countryData = this.data.countries.find(
      (c: any) => c.countryCode === this.country
    ) || { expenses: { trip: 250, stays: 150, meals: 50 } };
    const countryExpensesByDay =
      countryData.expenses.trip +
      countryData.expenses.stays +
      countryData.expenses.meals;

    if (type === 'install') {
      const installTime = this.data.installTime.install[this.dimension];

      return countryExpensesByDay * installTime;
    }

    if (this.contractType === 'pilot') {
      const uninstallTime = this.data.installTime.uninstall[this.dimension];

      return countryExpensesByDay * uninstallTime;
    }

    return 0;
  }

  public getInstallationCost() {
    return (
      this.getTagInstallationCosts() +
      this.getAnchorsInstallationCosts() +
      this.getTripExpenses('install') +
      this.getTripExpenses('uninstall')
    );
  }

  public getAdditionalServicesCosts() {
    let cost = 0;
    this.additionalServices.forEach((service) => {
      cost += service.cost * service.days;
    });
    return cost;
  }

  public getPilotCost() {
    return (
      this.getAnchorsCosts() +
      this.getTagsCosts() +
      this.getAdditionalCosts() +
      this.getSoftwareCost() +
      this.getInstallationCost() +
      this.getAdditionalServicesCosts()
    );
  }

  public getPartnerUpfrontCost() {
    let hardwareCost = 0;
    if (this.hardwareOption === 'purchased') {
      hardwareCost +=
        this.getAnchorsCosts() +
        this.getTagsCosts() +
        this.getAdditionalCosts();
    }

    return (
      hardwareCost +
      this.getInstallationCost() +
      this.getAdditionalServicesCosts()
    );
  }

  public getPartnerSubscriptionCost() {
    return (
      this.data.maintenanceCosts[this.dimension] +
      (this.getLeasingCost() || 0) +
      this.getSoftwareCost()
    );
  }

  public getTotalCost() {
    if (this.contractType === 'partner') {
      return (
        this.getPartnerUpfrontCost() +
        this.getPartnerSubscriptionCost() +
        (this.getPackage().cost || 0)
      );
    }

    return this.getPilotCost();
  }

  public getPackage() {
    return (
      ((this.data || {}).packages || []).find(
        (p: any) => p.code === this.subscriptionType
      ) || ({} as IPackage)
    );
  }

  public calcROI() {
    const numberOfCrashesBefore = this.numberOfAccidents;
    const numberOfCrashesAfter =
      this.numberOfAccidents -
      this.numberOfAccidents * this.estimatedReductionInAccidents * 0.01;

    const repairCostsBefore = numberOfCrashesBefore * this.avgReparationCost;
    const repairCostsAfter = numberOfCrashesAfter * this.avgReparationCost;

    const downtimeHoursBefore =
      numberOfCrashesBefore *
      DAYS_DOWN_AFTER_CRASH *
      this.numberOfShifts *
      this.shiftHours;
    const downtimeHoursAfter =
      numberOfCrashesAfter *
      DAYS_DOWN_AFTER_CRASH *
      this.numberOfShifts *
      this.shiftHours;
    const downTimeGain = downtimeHoursAfter - downtimeHoursBefore;

    const availableTime =
      this.numberOfShifts * this.shiftHours * this.workingDays;

    const operatingTimeBefore = availableTime * this.usedFleetCapacity * 0.01;
    const operatingTimeAfter =
      availableTime * this.usedFleetCapacity * 0.01 - downTimeGain;

    const movedPalletsByForkliftBefore =
      this.numberOfMovedPallets / operatingTimeBefore / this.numberOfForklifts;
    const movedPalletsByForkliftAfter =
      this.numberOfMovedPallets / operatingTimeAfter / this.numberOfForklifts;

    const timeToMovePalletMinBefore = 60 / movedPalletsByForkliftBefore;
    const timeToMovePalletMinAfter =
      timeToMovePalletMinBefore -
      timeToMovePalletMinBefore * this.estimatedGainsInPalletMoved * 0.01;

    const avgNumberOfPalletsMovedDayForkBefore =
      ((this.numberOfShifts * this.shiftHours) /
        (timeToMovePalletMinBefore / 60)) *
      this.usedFleetCapacity *
      0.01;
    const avgNumberOfPalletsMovedDayForkAfter =
      ((this.numberOfShifts * this.shiftHours) /
        (timeToMovePalletMinAfter / 60)) *
      this.usedFleetCapacity *
      0.01;

    const avgNumberOfPalletsMovedDayBefore =
      ((this.numberOfShifts * this.shiftHours * this.usedFleetCapacity * 0.01) /
        (timeToMovePalletMinBefore / 60)) *
      this.numberOfForklifts;
    const avgNumberOfPalletsMovedDayAfter =
      ((this.numberOfShifts * this.shiftHours * this.usedFleetCapacity * 0.01) /
        (timeToMovePalletMinAfter / 60)) *
      this.numberOfForklifts;

    const totalPalletsMovedYearBefore =
      avgNumberOfPalletsMovedDayForkBefore *
      this.workingDays *
      this.numberOfForklifts;
    const totalPalletsMovedYearAfter =
      avgNumberOfPalletsMovedDayForkAfter *
      this.workingDays *
      this.numberOfForklifts;

    const maxNumberOfTrucksServedDailyBefore =
      (avgNumberOfPalletsMovedDayForkBefore * this.numberOfForklifts) /
      PALLET_PER_TRUCK;
    const maxNumberOfTrucksServedDailyAfter =
      (avgNumberOfPalletsMovedDayForkAfter * this.numberOfForklifts) /
      PALLET_PER_TRUCK;

    const revenuesBefore =
      avgNumberOfPalletsMovedDayForkBefore *
      this.numberOfForklifts *
      PALLET_MOVEMENT_PRICE *
      this.workingDays;
    const revenuesAfter =
      avgNumberOfPalletsMovedDayForkAfter *
      this.numberOfForklifts *
      PALLET_MOVEMENT_PRICE *
      this.workingDays;

    const profitBefore = revenuesBefore * this.margin * 0.01;
    const profitAfter = revenuesAfter * this.margin * 0.01;
    const profitGain = profitAfter - profitBefore;

    const efficiencyCostsBefore =
      (calcTotalCostsOfForklift(
        this.forkliftType,
        availableTime * this.usedFleetCapacity * 0.01
      ) +
        this.numberOfShifts * YEARLY_COSTS) /
      this.workingDays;
    const efficiencyCostsAfter =
      (calcTotalCostsOfForklift(
        this.forkliftType,
        availableTime * this.usedFleetCapacity * 0.01
      ) +
        this.numberOfShifts * YEARLY_COSTS) /
      this.workingDays;

    const operatorCostsByPalletBefore =
      efficiencyCostsBefore / avgNumberOfPalletsMovedDayForkBefore;
    const operatorCostsByPalletAfter =
      efficiencyCostsAfter / avgNumberOfPalletsMovedDayForkAfter;

    const totalCostsYearBefore =
      operatorCostsByPalletBefore *
      avgNumberOfPalletsMovedDayForkBefore *
      this.workingDays *
      this.numberOfShifts;
    const totalCostsYearAfter =
      operatorCostsByPalletAfter *
      avgNumberOfPalletsMovedDayForkAfter *
      this.workingDays *
      this.numberOfShifts;

    const savingsBefore = 0;
    const savingsAfter =
      (operatorCostsByPalletBefore - operatorCostsByPalletAfter) *
      avgNumberOfPalletsMovedDayForkAfter *
      this.workingDays *
      this.numberOfShifts;
    const savingsGain = savingsAfter - savingsBefore;

    const profitDueToLessAccidentsBefore = 0;
    const profitDueToLessAccidentsAfter =
      ((downTimeGain * 60) / timeToMovePalletMinAfter) *
      PALLET_MOVEMENT_PRICE *
      (100 + this.margin) *
      0.01;

    const reparationCostsYearBefore =
      repairCostsBefore * this.numberOfForklifts;
    const reparationCostsYearAfter = repairCostsAfter * this.numberOfForklifts;

    const profitDueToLessAccidentsYearBefore =
      profitDueToLessAccidentsBefore * this.numberOfForklifts;
    const profitDueToLessAccidentsYearAfter =
      profitDueToLessAccidentsAfter * this.numberOfForklifts;
    const profitDueToLessAccidentsYearGains =
      profitDueToLessAccidentsYearBefore - profitDueToLessAccidentsYearAfter;

    const complianceFeesBefore = COMPLIANCE_FEES;
    const complianceFeesAfter =
      COMPLIANCE_FEES -
      COMPLIANCE_FEES * this.estimatedReductionInAccidents * 0.01;

    const totalCostsBefore = reparationCostsYearBefore + complianceFeesBefore;
    const totalCostsAfter = reparationCostsYearAfter + complianceFeesAfter;
    const totalCostsGain = totalCostsBefore - totalCostsAfter;

    const co2EmissionsBefore = calcEmissionsOfForklift(
      this.forkliftType,
      availableTime * this.usedFleetCapacity * 0.01
    );
    const co2EmissionsAfter = calcEmissionsOfForklift(
      this.forkliftType,
      availableTime * this.usedFleetCapacity * 0.01 - downTimeGain
    );

    const co2EmissionsByPalletBefore =
      (co2EmissionsBefore * this.numberOfForklifts) /
      totalPalletsMovedYearBefore;
    const co2EmissionsByPalletAfter =
      (co2EmissionsAfter * this.numberOfForklifts) / totalPalletsMovedYearAfter;
    const co2EmissionsByPalletGain =
      co2EmissionsByPalletBefore - co2EmissionsByPalletAfter;

    const co2EmissionsTotalTonsYearBefore =
      (co2EmissionsByPalletBefore * totalPalletsMovedYearBefore) / 1000;
    const co2EmissionsTotalTonsYearAfter =
      (co2EmissionsByPalletAfter * totalPalletsMovedYearAfter) / 1000;

    const co2SavingsBefore = 0;
    const co2SavingsAfter =
      (totalPalletsMovedYearAfter * co2EmissionsByPalletGain) / 1000;

    const profits =
      (profitGain - profitDueToLessAccidentsYearGains) /
      (this.contractType === 'pilot' ? 12 / this.pilotDuration : 1);

    const efficiency =
      savingsGain /
      (this.contractType === 'pilot' ? 12 / this.pilotDuration : 1);

    const safety =
      (profitDueToLessAccidentsYearGains + totalCostsGain) /
      (this.contractType === 'pilot' ? 12 / this.pilotDuration : 1);

    return {
      availableTime,
      operatingTime: {
        before: operatingTimeBefore,
        after: operatingTimeAfter,
        gain: operatingTimeBefore - operatingTimeAfter,
      },
      movedPalletsByForklift: {
        before: movedPalletsByForkliftBefore,
        after: movedPalletsByForkliftAfter,
        gain: movedPalletsByForkliftAfter - movedPalletsByForkliftBefore,
      },
      timeToMovePalletMin: {
        before: timeToMovePalletMinBefore,
        after: timeToMovePalletMinAfter,
        gain: timeToMovePalletMinAfter - timeToMovePalletMinBefore,
      },
      avgNumberOfPalletsMovedDayFork: {
        before: avgNumberOfPalletsMovedDayForkBefore,
        after: avgNumberOfPalletsMovedDayForkAfter,
        gain:
          avgNumberOfPalletsMovedDayForkAfter -
          avgNumberOfPalletsMovedDayForkBefore,
      },
      avgNumberOfPalletsMovedDay: {
        before: avgNumberOfPalletsMovedDayBefore,
        after: avgNumberOfPalletsMovedDayAfter,
        gain:
          avgNumberOfPalletsMovedDayAfter - avgNumberOfPalletsMovedDayBefore,
      },
      totalPalletsMovedYear: {
        before: totalPalletsMovedYearBefore,
        after: totalPalletsMovedYearAfter,
        gain: totalPalletsMovedYearAfter - totalPalletsMovedYearBefore,
      },
      maxNumberOfTrucksServedDaily: {
        before: maxNumberOfTrucksServedDailyBefore,
        after: maxNumberOfTrucksServedDailyAfter,
        gain:
          maxNumberOfTrucksServedDailyAfter -
          maxNumberOfTrucksServedDailyBefore,
      },
      revenues: {
        before: revenuesBefore,
        after: revenuesAfter,
        gain: revenuesAfter - revenuesBefore,
      },
      profit: {
        before: profitBefore,
        after: profitAfter,
        gain: profitGain,
      },
      efficiencyCosts: {
        before: efficiencyCostsBefore,
        after: efficiencyCostsAfter,
        gain: efficiencyCostsAfter - efficiencyCostsBefore,
      },
      operatorCostsByPallet: {
        before: operatorCostsByPalletBefore,
        after: operatorCostsByPalletAfter,
        gain: operatorCostsByPalletAfter - operatorCostsByPalletBefore,
      },
      totalCostsYear: {
        before: totalCostsYearBefore,
        after: totalCostsYearAfter,
        gain: totalCostsYearAfter - totalCostsYearBefore,
      },
      savings: {
        before: savingsBefore,
        after: savingsAfter,
        gain: savingsGain,
      },
      numberOfCrashes: {
        before: numberOfCrashesBefore,
        after: numberOfCrashesAfter,
        gain: numberOfCrashesAfter - numberOfCrashesBefore,
      },
      repairCosts: {
        before: repairCostsBefore,
        after: repairCostsAfter,
        gain: repairCostsAfter - repairCostsBefore,
      },
      downtimeHours: {
        before: downtimeHoursBefore,
        after: downtimeHoursAfter,
        gain: downtimeHoursAfter - downtimeHoursBefore,
      },
      profitDueToLessAccidents: {
        before: profitDueToLessAccidentsBefore,
        after: profitDueToLessAccidentsAfter,
        gain: profitDueToLessAccidentsBefore - profitDueToLessAccidentsAfter,
      },
      reparationCostsYear: {
        before: reparationCostsYearBefore,
        after: reparationCostsYearAfter,
        gain: reparationCostsYearAfter - reparationCostsYearBefore,
      },
      profitDueToLessAccidentsYear: {
        before: profitDueToLessAccidentsYearBefore,
        after: profitDueToLessAccidentsYearAfter,
        gain: profitDueToLessAccidentsYearGains,
      },
      complianceFees: {
        before: complianceFeesBefore,
        after: complianceFeesAfter,
        gain: complianceFeesBefore - complianceFeesAfter,
      },
      totalCosts: {
        before: totalCostsBefore,
        after: totalCostsAfter,
        gain: totalCostsGain,
      },
      co2Emissions: {
        before: co2EmissionsBefore,
        after: co2EmissionsAfter,
        gain: co2EmissionsAfter - co2EmissionsBefore,
      },
      co2EmissionsByPallet: {
        before: co2EmissionsByPalletBefore,
        after: co2EmissionsByPalletAfter,
        gain: co2EmissionsByPalletAfter - co2EmissionsByPalletBefore,
      },
      co2EmissionsTotalTonsYear: {
        before: co2EmissionsTotalTonsYearBefore,
        after: co2EmissionsTotalTonsYearAfter,
        gain: co2EmissionsTotalTonsYearAfter - co2EmissionsTotalTonsYearBefore,
      },
      co2Savings: {
        before: co2SavingsBefore,
        after: co2SavingsAfter,
        gain: co2SavingsBefore - co2SavingsAfter,
      },

      profits,
      efficiency,
      safety,
      saving: profits + efficiency + safety,
    };
  }
}

export default Calc;
