import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closePreCheckListForm,
  createPreCheckAnswer,
  fetchPreCheck,
  ICreateAnswer,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import PreCheck from './PreCheck';

const mapStateToProps = (state: IAppState) => ({
  loading: state.prechecks.precheckAnswerForm.loading,
  loadingSubmit: state.prechecks.precheckAnswerForm.loadingSubmit,
  error: state.prechecks.precheckAnswerForm.error,
  assetId: state.prechecks.precheckAnswerForm.assetId!,
  preCheck: state.prechecks.precheckAnswerForm.data,
  preCheckId: state.prechecks.precheckAnswerForm.preCheckId!,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  close: () => dispatch(closePreCheckListForm()),
  createPreCheckAnswer: (properties: ICreateAnswer) =>
    dispatch(createPreCheckAnswer.request(properties)),
  fetchPreCheck: (id: string) => dispatch(fetchPreCheck.request({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreCheck);
