import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Loading from '@app/common/Loading';
import Account from '@models/Account';

export interface IProps {
  account?: Account;
  closeModeSelector: () => void;
  loading: boolean;
  logout: () => void;
  selectMode: (event: any) => void;
}

const ModeSelector: FunctionComponent<IProps> = (properties: IProps) => {
  const { account, closeModeSelector, loading, logout, selectMode } = properties;

  const handleLogout = () => {
    logout();
  };

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={closeModeSelector}>
      <DialogTitle>
        <FormattedMessage
          id="navbar.modeselector.title"
          defaultMessage="Change Mode"
        />
      </DialogTitle>
      <DialogContent>
        <Loading loading={loading}>
          {Object.values((account || {}).organisations || []).length ? (
            <FormControl required fullWidth variant="standard" margin="normal">
              <Select
                label={
                  <FormattedMessage
                    id="navbar.modeselector.label_mode"
                    defaultMessage="Select a mode"
                  />
                }
                value={(account || {}).organisationId || ''}
                onChange={selectMode}
              >
                {Object.values((account || {}).organisations || []).map(
                  (organisation) => (
                    <MenuItem key={organisation.id} value={organisation.id}>
                      {organisation.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          ) : (
            <Alert severity="warning">
              <FormattedMessage
                id="navbar.modeselector.noactivemodes"
                defaultMessage="This account has no active modes"
              />
            </Alert>
          )}

          <Box mt={4} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button onClick={handleLogout}>
              <FormattedMessage
                id="navbar.accountmenu.logout"
                defaultMessage="Log out"
              />
            </Button>
          </Box>
        </Loading>
      </DialogContent>
    </Dialog>
  );
};

export default ModeSelector;
