import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BarChartIcon from '@mui/icons-material/BarChart';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LockIcon from '@mui/icons-material/Lock';
import React, { PureComponent } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import { IDeleteAccount } from '@actions/authentication';
import { withRouter } from '@app/utils/withRouter';
import Basic from './Basic';
import Contacts from './Contacts';
import Password from './Password';
import Reports from './Reports';

interface IProps {
  language: string;
  deleteAccount: (props: IDeleteAccount) => void;
  intl: IntlShape;

  router: any;
}
interface IState {
  activeItem: string;
  confirm: boolean;
}

class Settings extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeItem: props.router.params.setting || 'basic',
      confirm: false,
    };

    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.closeDeleteAccount = this.closeDeleteAccount.bind(this);
    this.deleteAccountConfirmed = this.deleteAccountConfirmed.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { router } = this.props;

    if (router.params.setting !== prevProps.router.params.setting) {
      this.setActive(router.params.setting || 'basic');
    }
  }

  public handleItemSelect(tab: string) {
    this.setState({ activeItem: tab }, () => {
      const { router, language } = this.props;

      router.navigate(`/${language}/dashboard/account/settings/${tab}`);
    });
  }

  public setActive(activeItem: string) {
    this.setState({ activeItem });
  }

  public deleteAccount() {
    this.setState({ confirm: true });
  }

  public closeDeleteAccount() {
    this.setState({ confirm: false });
  }

  public deleteAccountConfirmed() {
    const { deleteAccount, intl } = this.props;

    deleteAccount({ intl });
  }

  public render() {
    const { activeItem, confirm } = this.state;

    const title = (
      <FormattedMessage
        id="dashboard.account.settings.title"
        defaultMessage="Account Settings"
      />
    );

    let activeSection = null;
    if (activeItem === 'basic') {
      activeSection = <Basic />;
    } else if (activeItem === 'contacts') {
      activeSection = <Contacts />;
    } else if (activeItem === 'password') {
      activeSection = <Password />;
    } else if (activeItem === 'reports') {
      activeSection = <Reports />;
    }

    return (
      <Box className="tab-content-inner" p={2}>
        <CardHeader title={title} />
        <Divider style={{ marginBottom: '20px' }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={3}>
            <Paper>
              <List>
                <ListItem
                  button
                  onClick={() => this.handleItemSelect('basic')}
                  selected={activeItem === 'basic'}
                >
                  <ListItemIcon>
                    <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.account.settings.menu.account_basics"
                        defaultMessage="Account Basics"
                      />
                    }
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => this.handleItemSelect('contacts')}
                  selected={activeItem === 'contacts'}
                >
                  <ListItemIcon>
                    <ContactPhoneIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.account.settings.menu.account_contacts"
                        defaultMessage="My Contacts"
                      />
                    }
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => this.handleItemSelect('password')}
                  selected={activeItem === 'password'}
                >
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.account.settings.menu.account_password"
                        defaultMessage="Change password"
                      />
                    }
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => this.handleItemSelect('reports')}
                  selected={activeItem === 'reports'}
                >
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.account.settings.menu.account_reports"
                        defaultMessage="Reports"
                      />
                    }
                  />
                </ListItem>
              </List>
            </Paper>

            <Box mt={2}>
              <Button
                color="error"
                fullWidth
                onClick={this.deleteAccount}
              >
                <DeleteIcon />
                <FormattedMessage
                  id="dashboard.account.settings.delete"
                  defaultMessage="Delete My Account"
                />
              </Button>
            </Box>

            {confirm ? (
              <Dialog open>
                <DialogTitle>
                  <FormattedMessage
                    id="dashboard.account.settings.confirm.title"
                    defaultMessage="Are you sure?"
                  />
                </DialogTitle>
                <DialogContent>
                  <Typography variant="body1">
                    <FormattedMessage
                      id="dashboard.account.settings.confirm.warning"
                      defaultMessage="Are you sure that you want to delete your account? This operation is not reversible."
                    />
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeDeleteAccount} variant="contained" color="primary">
                    <FormattedMessage
                      id="dashboard.account.settings.confirm.cancel"
                      defaultMessage="No, keep my account"
                    />
                  </Button>
                  <Button onClick={this.deleteAccountConfirmed}>
                    <FormattedMessage
                      id="dashboard.account.settings.confirm.do"
                      defaultMessage="Yes, delete my account"
                    />
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null}
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            {activeSection}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default injectIntl(withRouter(Settings));
