import Popover from '@mui/material/Popover';
import React, { FunctionComponent } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

interface IProps {
  anchorEl: HTMLElement;
  open: boolean;
  value: string;
  onChangeComplete: (color: ColorResult) => void;
  onClose: () => void;
}

const PickerDialog: FunctionComponent<IProps> = ({
  anchorEl,
  open,
  value,
  onClose,
  onChangeComplete,
}: IProps) => (
  <Popover
    open={open}
    onClose={onClose}
    anchorEl={anchorEl}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
  >
    <SketchPicker
      disableAlpha
      color={value}
      onChangeComplete={onChangeComplete}
    />
  </Popover>
);

export default PickerDialog;
