import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getSelectedLocale } from '@selectors/app';
import Landing from './Landing';

const mapStateToProps = (state: IAppState) => ({
  measurementUnits: getAccountMeasurementUnits(state.authentication),
  locale: getSelectedLocale(state),
  theme: state.app.theme,
});

export default connect(mapStateToProps)(Landing);
