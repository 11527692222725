import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IUpdateAccountContacts, updateAccountContacts } from '@actions/index';
import { IAppState } from '@reducers/index';
import {
  getAccountLocale,
  isAccountSettingsBasicLoading,
} from '@selectors/accounts';

import Contacts from './Contacts';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account!,
  changed: state.authentication.settings.contacts.changed,
  error: state.authentication.settings.contacts.error,
  loading: isAccountSettingsBasicLoading(state.authentication),
  locale: getAccountLocale(state.authentication),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateAccountContacts: (properties: IUpdateAccountContacts) =>
    dispatch(updateAccountContacts.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
