import { defineMessages } from 'react-intl';

const messages = defineMessages({
  asset: {
    defaultMessage: 'Asset',
    id: 'dashboard.livemap.labels_asset',
  },
  color: {
    defaultMessage: 'Color',
    id: 'dashboard.livemap.labels_color',
  },
  colorize: {
    defaultMessage: 'Colorize',
    id: 'dashboard.datamap.colorize',
  },
  created: {
    defaultMessage: 'Created',
    id: 'dashboard.livemap.labels_created',
  },
  direction: {
    defaultMessage: 'Direction',
    id: 'dashboard.livemap.labels_direction',
  },
  duration: {
    defaultMessage: 'Duration',
    id: 'dashboard.livemap.labels_duration',
  },
  fitView: {
    defaultMessage: 'Fit view',
    id: 'dashboard.livemap.fit_view',
  },
  height: {
    defaultMessage: 'Height',
    id: 'dashboard.livemap.labels_height',
  },
  help: {
    defaultMessage: 'To zoom in/out use SHIFT + scroll',
    id: 'dashboard.livemap.help',
  },
  horizontalVelocity: {
    defaultMessage: 'Horizontal velocity',
    id: 'dashboard.livemap.labels_horizontalvelocity',
  },
  hours: {
    defaultMessage: 'Hours',
    id: 'dashboard.livemap.labels_hours',
  },
  id: {
    defaultMessage: 'ID',
    id: 'dashboard.livemap.labels_id',
  },
  latitude: {
    defaultMessage: 'Latitude',
    id: 'dashboard.livemap.labels_latitude',
  },
  longitude: {
    defaultMessage: 'Longitude',
    id: 'dashboard.livemap.labels_longitude',
  },
  name: {
    defaultMessage: 'Name',
    id: 'dashboard.livemap.labels_name',
  },
  none: {
    defaultMessage: 'None',
    id: 'dashboard.livemap.labels_none',
  },
  physicalAddress: {
    defaultMessage: 'Physical Address',
    id: 'dashboard.livemap.labels_physicaladdress',
  },
  // eslint-disable-next-line camelcase
  sensor_beacon: {
    defaultMessage: 'Moving',
    id: 'dashboard.livemap.sensor_beacon',
  },
  // eslint-disable-next-line camelcase
  sensor_stationary_beacon: {
    defaultMessage: 'Stationary',
    id: 'dashboard.livemap.sensor_stationary_beacon',
  },
  start: {
    defaultMessage: 'Start',
    id: 'dashboard.livemap.labels_start',
  },
  stopped: {
    defaultMessage: 'Stopped',
    id: 'dashboard.livemap.labels_stopped',
  },
  stops: {
    defaultMessage: 'Stops',
    id: 'dashboard.livemap.labels_stops',
  },
  type: {
    defaultMessage: 'Type',
    id: 'dashboard.livemap.labels_type',
  },
  // eslint-disable-next-line camelcase
  type_boundary: {
    defaultMessage: 'Boundary',
    id: 'dashboard.livemap.type_boundary',
  },
  // eslint-disable-next-line camelcase
  type_obstacle: {
    defaultMessage: 'Obstacle',
    id: 'dashboard.livemap.type_obstacle',
  },
  // eslint-disable-next-line camelcase
  type_rack: {
    defaultMessage: 'Rack',
    id: 'dashboard.livemap.type_rack',
  },
  verticalVelocity: {
    defaultMessage: 'Vertical velocity',
    id: 'dashboard.livemap.labels_verticalvelocity',
  },
  x: {
    defaultMessage: 'X',
    id: 'dashboard.livemap.labels_x',
  },
  y: {
    defaultMessage: 'Y',
    id: 'dashboard.livemap.labels_y',
  },
  z: {
    defaultMessage: 'Height',
    id: 'dashboard.livemap.labels_z',
  },
  zoneName: {
    defaultMessage: 'Zone name',
    id: 'dashboard.livemap.zone_name',
  },
  zoneType: {
    defaultMessage: 'Zone type',
    id: 'dashboard.livemap.zone_type',
  },
  zoomIn: {
    defaultMessage: 'Zoom in',
    id: 'dashboard.livemap.zoom_in',
  },
  zoomOut: {
    defaultMessage: 'Zoom out',
    id: 'dashboard.livemap.zoom_out',
  },

  totalCount: {
    defaultMessage: 'Number of movements into this zone',
    id: 'dashboard.map.layers.zoneflow_info_totalcount',
  },
}) as any;

export default messages;
