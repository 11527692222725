import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/lab/Autocomplete';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { AssignAssetTicket } from '@actions/index';
import Loading from '@app/common/Loading';
import LoadingButton from '@app/common/LoadingButton';
import AccountMeta from '@models/AccountMeta';
import Account from '@models/Account';
import FetchError from '@models/FetchError';

interface IState {
  accountId: string;
  selectMyself: boolean;
}

interface IProps {
  account: Account;
  error?: FetchError;
  ticketId: string;
  close: () => void;
  assignAssetTicket: (properties: AssignAssetTicket) => void;
  fetchAccounts: () => void;
  accounts: AccountMeta[];
  formLoading: boolean;
  loading: boolean;
}

class AssignmentForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      accountId: '',
      selectMyself: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectMyself = this.handleSelectMyself.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    const { fetchAccounts } = this.props;

    fetchAccounts();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { close, loading, error } = this.props;

    if (!loading && !error && prevProps.loading) {
      close();
    }
  }

  public handleChange(e: any, value: any) {
    this.setState({
      accountId: value ? value.value : '',
      selectMyself: false,
    });
  }

  public handleSelectMyself(e: any) {
    const { checked } = e.target;
    const { account } = this.props;
    let { accountId } = this.state;

    if (checked) {
      accountId = account.id;
    }

    this.setState({ selectMyself: checked, accountId });
  }

  public handleCancel() {
    const { close } = this.props;

    close();
  }

  public handleSubmit() {
    const { assignAssetTicket, ticketId } = this.props;
    const { accountId } = this.state;

    assignAssetTicket({ ticketId, accountId });
  }

  public render() {
    const { accounts, formLoading, loading } = this.props;
    const { accountId, selectMyself } = this.state;

    const error = accountId.length === 0;

    const buttonDisabled = error;

    const accountsArray = accounts.map((account) => ({
      value: account.id,
      label: account.authName,
      image: account.authImage,
    }));

    return (
      <Dialog open maxWidth="md" fullWidth>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.assets.management.assigneeform.title"
            defaultMessage="Assign Ticket"
          />
        </DialogTitle>
        <DialogContent>
          <Loading loading={formLoading}>
            <FormControl required fullWidth margin="normal">
              <Autocomplete
                id="assign-form-accountid"
                options={accountsArray}
                disabled={selectMyself}
                getOptionLabel={(option) => option.label}
                value={accountsArray.find((a) => a.value === accountId) || null}
                onChange={this.handleChange}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Avatar
                      alt={option.label}
                      src={option.image}
                      style={{ float: 'left' }}
                    />
                    <Typography
                      noWrap
                      style={{ float: 'left', marginLeft: '5px' }}
                    >
                      {option.label}
                    </Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={params.inputProps || ''}
                    fullWidth
                    label={
                      <FormattedMessage
                        id="dashboard.assets.management.assigneeform.label_assignee"
                        defaultMessage="Assignee"
                      />
                    }
                    variant="outlined"
                  />
                )}
              />
              {!accountId && (
                <FormHelperText>
                  <FormattedMessage
                    id="dashboard.assets.management.assigneeform.field_assignee_helper"
                    defaultMessage="An assignee must be selected"
                  />
                </FormHelperText>
              )}
            </FormControl>
            <FormControl required fullWidth margin="normal">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectMyself}
                    onChange={this.handleSelectMyself}
                    name="selectMyself"
                  />
                }
                label={
                  <FormattedMessage
                    id="dashboard.assets.management.assigneeform.label_selectmyself"
                    defaultMessage="Select myself"
                  />
                }
              />
            </FormControl>
          </Loading>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            <FormattedMessage
              id="dashboard.assets.management.assigneeform.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color="secondary"
            disabled={buttonDisabled}
            loading={loading}
          >
            <FormattedMessage
              id="dashboard.assets.management.assigneeform.buttom_submit"
              defaultMessage="Assign"
            />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AssignmentForm;
