import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '@app/dialogs/ConfirmDialog/types';
import AssetDocument from '@models/AssetDocument';
import CompleteAsset from '@models/CompleteAsset';
import DocumentForm from '../../forms/DocumentForm';

const messages = defineMessages({
  thType: {
    defaultMessage: 'Type',
    id: 'dashboard.assets.management.documents.table.type',
  },
  thName: {
    defaultMessage: 'Filename',
    id: 'dashboard.assets.management.documents.table.name',
  },
  thLink: {
    defaultMessage: 'Link',
    id: 'dashboard.assets.management.documents.table.link',
  },
  thCreated: {
    defaultMessage: 'Created at',
    id: 'dashboard.assets.management.documents.table.created',
  },
  thOptions: {
    defaultMessage: 'Options',
    id: 'dashboard.assets.management.documents.table.options',
  },
  deleteMsg: {
    defaultMessage: 'Are you sure that you want to delete this document?',
    id: 'dashboard.assets.management.documents.delete_msg',
  },
});

interface IProps {
  confirm: (props: IConfirmDialog) => void;
  asset: CompleteAsset;
  deleteAssetDocument: (id: string, intl: IntlShape) => void;
  fetchAssetDocuments: (assetId: string) => void;
  intl: IntlShape;
  documents: AssetDocument[];
  loading: boolean;
}
interface IState {
  columns: ITableColumn[];
  showDocumentForm: boolean;
}

class Documents extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      columns: [
        { label: intl.formatMessage(messages.thType), name: 'type' },
        {
          label: intl.formatMessage(messages.thName),
          name: 'name',
        },
        { label: intl.formatMessage(messages.thLink), name: 'link' },
        { label: intl.formatMessage(messages.thCreated), name: 'datetime' },
        {
          label: intl.formatMessage(messages.thOptions),
          name: 'id',
        },
      ],
      showDocumentForm: false,
    };

    this.customRowRender = this.customRowRender.bind(this);
    this.closeDocumentForm = this.closeDocumentForm.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }

  public componentDidMount() {
    const { asset, fetchAssetDocuments } = this.props;

    fetchAssetDocuments(asset.id);
  }

  public handleAdd() {
    this.setState({ showDocumentForm: true });
  }

  public handleDelete(id: string) {
    const { confirm, deleteAssetDocument, intl } = this.props;

    confirm({
      confirmType: ConfirmDialogActions.DELETE,
      message: intl.formatMessage(messages.deleteMsg),
      onConfirmation: () => {
        deleteAssetDocument(id, intl);
      },
    });
  }

  public closeDocumentForm() {
    this.setState({ showDocumentForm: false });
  }

  public customRowRender(
    columns: boolean[],
    rowData: any,
    dataIndex: number,
    rowIndex: number
  ) {
    let docTypes;
    switch (rowData[0]) {
      case 'manual':
        docTypes = (
          <FormattedMessage
            id="dashboard.assets.management.documentform.label_type_manual"
            defaultMessage="Manual"
          />
        );
        break;
      case 'service_manual':
        docTypes = (
          <FormattedMessage
            id="dashboard.assets.management.documentform.label_type_service_manual"
            defaultMessage="Service Manual"
          />
        );
        break;
      case 'service_inspection':
        docTypes = (
          <FormattedMessage
            id="dashboard.assets.management.documentform.label_type_service_inspection"
            defaultMessage="Service Inspection"
          />
        );
        break;
      case 'repair_note':
        docTypes = (
          <FormattedMessage
            id="dashboard.assets.management.documentform.label_type_repair_note"
            defaultMessage="Repair Note"
          />
        );
        break;
      default:
        docTypes = '';
        break;
    }

    return (
      <TableRow key={rowIndex}>
        {columns[0] && <TableCell>{docTypes}</TableCell>}
        {columns[1] && <TableCell>{rowData[1]}</TableCell>}
        {columns[2] && (
          <TableCell>
            <Link href={rowData[2]} target="_blank" component="a">
              {rowData[2]}
            </Link>
          </TableCell>
        )}
        {columns[3] && (
          <TableCell>{format(new Date(rowData[3]), 'yyyy-MM-dd')}</TableCell>
        )}
        {columns[4] && (
          <TableCell style={{ textAlign: 'right' }}>
            <IconButton
              id="add-map"
              size="small"
              onClick={() => this.handleDelete(rowData[4])}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  }

  public render() {
    const { asset, documents, loading } = this.props;
    const { columns, showDocumentForm } = this.state;

    return (
      <>
        <CardHeader
          action={
            <Button id="add-asset-document" onClick={this.handleAdd}>
              <AddIcon style={{ marginRight: '5px' }} />
              <FormattedMessage
                id="dashboard.assets.management.documents.adddoc"
                defaultMessage="Add Asset Document"
              />
            </Button>
          }
          title={
            <FormattedMessage
              id="dashboard.assets.management.documents.title"
              defaultMessage="Documents"
            />
          }
        />
        <Loading loading={loading}>
          <MUITable
            columns={columns}
            customRowRender={this.customRowRender}
            data={documents}
            tableId="asset-documents"
          />
        </Loading>
        {showDocumentForm ? (
          <DocumentForm asset={asset} close={this.closeDocumentForm} />
        ) : null}
      </>
    );
  }
}

export default injectIntl(Documents);
