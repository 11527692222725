import { Color, Path, Point, Matrix } from 'paper';
import { Component } from 'react';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { transformMetersToPixels } from '@dashboard_utils/index';
import mapEvents from '../../eventEmitter';
import Paper from '../../Paper';
import MapImages from '../../MapImages';
import { IPathFeature } from '../../../types';
import { defaultTransformationMatrix } from '../../../consts';
import { PRIMARY_COLOR } from '../../../../../utils/colors';

interface IProps {
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  paper: Paper;
}

class ObstacleMapLayer extends Component<IProps> {
  private elements: IPathFeature[] = [];

  constructor(props: IProps) {
    super(props);

    this.sendToBack = this.sendToBack.bind(this);
  }

  public componentDidMount() {
    mapEvents.on('order_layers', this.sendToBack);

    this.load();
  }

  public componentWillUnmount() {
    mapEvents.removeListener('order_layers', this.sendToBack);

    this.clear();
  }

  public load() {
    const { floorplan, mapImages, paper } = this.props;

    paper.scope.activate();

    (floorplan.obstacles || []).forEach((obstacle, index) => {
      const path = new Path() as IPathFeature;
      path.strokeColor = new Color(PRIMARY_COLOR);
      path.strokeWidth = obstacle.meta.type !== 'wall' ? 6 : 1;
      path.fillColor = new Color(`${PRIMARY_COLOR}40`);
      obstacle.coordinates.forEach((c) =>
        path.add(
          new Point(
            transformMetersToPixels(
              c as [number, number],
              floorplan.transformationMatrix || defaultTransformationMatrix,
              floorplan.scale || 1
            )
          )
        )
      );
      path.strokeScaling = false;
      path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
      path.closePath();

      path.featureInfo = {
        id: `obstacle_${index}`,
        title: obstacle.meta.name || `Obstacle #${index}`,
        type: 'obstacle',
      };

      this.elements.push(path);
    });
  }

  public clear() {
    this.elements.forEach((e) => e.remove());

    this.elements = [];
  }

  public sendToBack(layerType: string) {
    if (layerType === 'obstacles' && this.elements.length) {
      this.elements.forEach((e) => e.sendToBack());
    }
  }

  public render() {
    return null;
  }
}

export default ObstacleMapLayer;
