import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import SnackbarContent from '@mui/material/SnackbarContent';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { FormattedMessage } from 'react-intl';

import React, { Component } from 'react';

import { IImport } from '@actions/types';
import LoadingButton from '@app/common/LoadingButton';
import FetchError from '@models/FetchError';

const handleFileUploadClick = (event: any) => {
  event.stopPropagation();

  document.getElementById('form-csv')!.click();
};

export interface IState {
  csv?: string;
}

interface IProps {
  closeImportItemsForm: () => void;
  error?: FetchError;
  importItems: (properties: IImport) => void;
  loading: boolean;
}

class ImportItemsForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      csv: undefined,
    };

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleCancel() {
    const { closeImportItemsForm } = this.props;

    closeImportItemsForm();
  }

  public handleSubmit() {
    const { csv } = this.state;

    const { importItems } = this.props;

    importItems({ csv: csv || '' });
  }

  public handleFileUpload(event: any) {
    const { files } = event.target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (e.target !== null) {
          this.setState({ csv: e.target.result });
        }
      };

      reader.readAsText(files[0]);

      this.setState({
        csv: files[0],
      });
    }
  }

  public render() {
    const { error, loading } = this.props;
    const { csv } = this.state;

    const buttonDisabled = !csv;

    const successMessage = (
      <span
        style={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <CheckIcon />
        <FormattedMessage
          id="dashboard.forms.importitemsform.upload_success"
          defaultMessage="File uploaded successfully"
        />
      </span>
    );

    return (
      <Dialog open>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.forms.importitemsform.title"
            defaultMessage="Import Items"
          />
        </DialogTitle>
        <DialogContent>
          <label htmlFor="form-csv">
            <Button
              style={{ width: '100%', height: '250px' }}
              variant="outlined"
              color="primary"
              onClick={handleFileUploadClick}
            >
              <FormattedMessage
                id="dashboard.forms.importitemsform.upload_button"
                defaultMessage="Upload CSV file"
              />
            </Button>
            <input
              id="form-csv"
              accept="csv/*"
              type="file"
              style={{ display: 'none' }}
              onInput={this.handleFileUpload}
            />
          </label>
          <FormHelperText>
            <FormattedMessage
              id="dashboard.forms.importitemsform.csv_helper"
              defaultMessage="CSV file, UTF-8 comma limited, containing a list of Code,Description entries"
            />
          </FormHelperText>
          {csv ? (
            <SnackbarContent
              style={{
                backgroundColor: green[600],
                marginTop: '15px',
              }}
              message={successMessage}
            />
          ) : null}
          {error ? (
            <SnackbarContent
              message={
                error.errorCode === 'NoData' ? (
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <ErrorIcon />
                    <span style={{ paddingLeft: '5px' }}>
                      <FormattedMessage
                        id="dashboard.forms.importitemsform.errors_nodate"
                        defaultMessage="No data find in the file"
                      />
                    </span>
                  </div>
                ) : (
                  ''
                )
              }
              style={{
                backgroundColor: '#d32f2f',
                marginTop: '30px',
              }}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            <FormattedMessage
              id="dashboard.forms.importitemsform.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <LoadingButton
            onClick={this.handleSubmit}
            variant="contained"
            color="primary"
            disabled={buttonDisabled}
            loading={loading}
          >
            <FormattedMessage
              id="dashboard.forms.importitemsform.button_submit"
              defaultMessage="Import"
            />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ImportItemsForm;
