import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  createAssetTicketComment,
  CreateAssetTicketComment,
  fetchFloorplanSensors,
  openTicketForm,
  openPreCheckListForm,
  openMaintenanceForm,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { getActiveLanguage } from '@selectors/app';
import { getAsset, getCompleteAsset } from '@selectors/assets';
import {
  getFloorplanSensors,
  isSensorByFloorplanLoading,
} from '@selectors/sensors';
import { IAppState } from '@reducers/index';
import Management from './Management';

import './index.css';

interface IProps {
  router: any;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const asset = getAsset(state, props.router.params.id);
  const completeAsset = asset
    ? getCompleteAsset(
        state.warehouses.warehouses,
        state.floorplans.floorplans,
        asset!
      )
    : undefined;

  return {
    asset: completeAsset,
    language: getActiveLanguage(state),
    ticketForm: state.assets.tickets.ticketForm.active,
    maintenanceForm: !!state.maintenances.form.assetId,
    preCheckForm: !!state.prechecks.precheckAnswerForm.preCheckId,
    floorplans: state.floorplans.floorplans,
    loading: asset
      ? isSensorByFloorplanLoading(state, asset.floorplanId || '')
      : false,
    sensors: asset ? getFloorplanSensors(state, asset.floorplanId || '') : [],
    warehouses: state.warehouses.warehouses,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openTicketForm: () => dispatch(openTicketForm()),
  openMaintenanceForm: (assetId: string) =>
    dispatch(openMaintenanceForm(assetId)),
  openPreCheckListForm: (preCheckId: string, assetId: string) =>
    dispatch(openPreCheckListForm(preCheckId, assetId)),
  fetchSensors: (floorplanId: string) => {
    dispatch(
      fetchFloorplanSensors.request({
        floorplanId,
      })
    );
  },
  createAssetTicketComment: (properties: CreateAssetTicketComment) =>
    dispatch(createAssetTicketComment.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Management));
