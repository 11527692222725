import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import { sum } from 'lodash';
import Lightbox from 'react-image-lightbox';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  CreateAssetTicketComment,
  DeleteAssetTicketOperation,
} from '@actions/index';
import Loading from '@app/common/Loading';
import timeAgo from '@app/utils/timeAgo';
import CompleteAsset from '@models/CompleteAsset';
import AssetTicket, { AssetTicketStatus } from '@models/AssetTicket';
import TicketOperationForm from '../../forms/TicketOperationForm';
import {
  ConfirmDialogActions,
  IConfirmDialog,
} from '../../../../../../dialogs/ConfirmDialog/types';

import 'react-image-lightbox/style.css';

interface IProps {
  asset: CompleteAsset;
  confirm: (properties: IConfirmDialog) => void;
  intl: IntlShape;
  loading: boolean;
  commentLoading: boolean;
  commentError?: object;
  ticket: AssetTicket;
  createAssetTicketComment: (properties: CreateAssetTicketComment) => void;
  deleteAssetTicketOperation: (properties: DeleteAssetTicketOperation) => void;
  fetchAssetTicket: (ticketId: string) => void;
  language: string;
  locale: string;
  router: any;
}
interface IState {
  photoIndex: number;
  isOpen: boolean;
  comment: string;
  addOperation: boolean;
}

class TicketDashboard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpen: false,
      photoIndex: 0,
      comment: '',
      addOperation: false,
    };

    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleAddOperation = this.handleAddOperation.bind(this);
    this.closeOperationForm = this.closeOperationForm.bind(this);
    this.deleteOperation = this.deleteOperation.bind(this);
  }

  public componentDidMount() {
    const { router, fetchAssetTicket } = this.props;

    fetchAssetTicket(router.params.ticketId);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { commentLoading } = this.props;

    if (!commentLoading && prevProps.commentLoading !== commentLoading) {
      this.resetComment();
    }
  }

  public handleAddOperation() {
    this.setState({ addOperation: true });
  }

  public handleCommentChange(e: any) {
    const { name, value, type, checked } = e.target;

    this.setState({
      [name]: type === 'checkbox' ? checked : value || '',
    } as Pick<IState, 'comment'>);
  }

  public handleComment() {
    const { createAssetTicketComment, ticket } = this.props;
    const { comment } = this.state;

    createAssetTicketComment({
      ticketId: ticket.id,
      comment,
    });
  }

  public closeOperationForm() {
    this.setState({ addOperation: false });
  }

  public resetComment() {
    this.setState({ comment: '' });
  }

  public deleteOperation(id: string) {
    const { confirm, deleteAssetTicketOperation, intl } = this.props;

    confirm({
      confirmType: ConfirmDialogActions.DELETE,
      message: intl.formatMessage({
        defaultMessage: 'Are you shure that you want to delete this operation?',
        id: 'dashboard.assets.management.ticketform.delete_message',
      }),
      onConfirmation: () => deleteAssetTicketOperation({ id }),
    });
  }

  public render() {
    const { addOperation, comment, isOpen, photoIndex } = this.state;
    const { asset, commentLoading, language, loading, locale, router, ticket } =
      this.props;

    const files = (ticket.files || []).map((f) => f.file);

    let ticketType;
    switch (ticket.type) {
      case 'breakdown':
        ticketType = (
          <FormattedMessage
            id="dashboard.assets.management.ticketform.label_type_breakdown"
            defaultMessage="Breakdown"
          />
        );
        break;
      case 'maintenance_needed':
        ticketType = (
          <FormattedMessage
            id="dashboard.assets.management.ticketform.label_type_maintenance_needed"
            defaultMessage="Maintenance Needed"
          />
        );
        break;
      default:
        ticketType = '';
        break;
    }

    let status;
    switch (ticket.status) {
      case AssetTicketStatus.PENDING:
        status = (
          <Chip
            color="secondary"
            label={
              <FormattedMessage
                id="dashboard.assets.management.tickets.status_pending"
                defaultMessage="Pending"
              />
            }
          />
        );
        break;

      case AssetTicketStatus.ASSIGNED:
        status = (
          <Chip
            color="primary"
            label={
              <FormattedMessage
                id="dashboard.assets.management.tickets.status_assigned"
                defaultMessage="Assigned"
              />
            }
          />
        );
        break;

      case AssetTicketStatus.DONE:
        status = (
          <Chip
            style={{ backgroundColor: '#008000', color: '#ffffff' }}
            label={
              <FormattedMessage
                id="dashboard.assets.management.tickets.status_done"
                defaultMessage="Resolved"
              />
            }
          />
        );
        break;

      default:
        status = '';
        break;
    }

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink
            color="inherit"
            component={Link}
            to={`/${language}/dashboard/assets`}
          >
            <FormattedMessage
              id="dashboard.itab.assets"
              defaultMessage="Assets"
            />
          </MuiLink>
          <MuiLink
            color="inherit"
            component={Link}
            to={`/${language}/dashboard/assets/assetid/${
              asset.id
            }/management/tab/${router.params.tab || 0}`}
          >
            <FormattedMessage
              id="dashboard.assets.assetmanagement"
              defaultMessage="Asset Management"
            />
          </MuiLink>
          <Typography color="textPrimary">
            <FormattedMessage
              id="dashboard.assets.management.ticket_title"
              defaultMessage="Ticket #{ticketId}"
              values={{ ticketId: ticket.subject }}
            />
          </Typography>
        </Breadcrumbs>

        <Box mt={2}>
          <Card>
            <Loading loading={loading}>
              <CardContent style={{ backgroundColor: '#f3f3f3' }}>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Typography variant="h4">
                      <FormattedMessage
                        id="dashboard.assets.management.ticket_title"
                        defaultMessage="Ticket #{ticketId}"
                        values={{ ticketId: ticket.subject }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    {status}
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="caption" className="view-caption">
                      <FormattedMessage
                        id="dashboard.assets.management.ticketform.label_type"
                        defaultMessage="Type"
                      />
                    </Typography>
                    <Typography variant="body1">{ticketType}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="caption" className="view-caption">
                      <FormattedMessage
                        id="dashboard.assets.management.ticketform.label_eventdate"
                        defaultMessage="Event Date"
                      />
                    </Typography>
                    <Typography variant="body1">
                      {ticket.eventDate
                        ? format(new Date(ticket.eventDate), 'yyyy-MM-dd')
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="caption" className="view-caption">
                      <FormattedMessage
                        id="dashboard.assets.management.ticketform.label_workimpairingissue"
                        defaultMessage="Work impairing issue"
                      />
                    </Typography>
                    <Typography variant="body1">
                      {ticket.workImpairingIssue ? (
                        <FormattedMessage id="yes" defaultMessage="Yes" />
                      ) : (
                        <FormattedMessage id="no" defaultMessage="No" />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="caption" className="view-caption">
                      <FormattedMessage
                        id="dashboard.assets.management.ticketform.label_subject"
                        defaultMessage="Subject of the issue"
                      />
                    </Typography>
                    <Typography variant="body1">{ticket.subject}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="caption" className="view-caption">
                      <FormattedMessage
                        id="dashboard.assets.management.ticketform.label_description"
                        defaultMessage="Description of the issue"
                      />
                    </Typography>
                    <Typography variant="body1">
                      {ticket.description}
                      {files.length}
                    </Typography>
                  </Grid>
                  {files.length ? (
                    <Grid item sm={12}>
                      <div className="image-list-container">
                        <ImageList
                          style={{
                            flexWrap: 'nowrap',
                            transform: 'translateZ(0)',
                          }}
                          cols={2.5}
                        >
                          {files.map((file, index) => (
                            <ImageListItem
                              key={file}
                              onClick={() =>
                                this.setState({
                                  isOpen: true,
                                  photoIndex: index,
                                })
                              }
                              style={{ cursor: 'pointer' }}
                            >
                              <img src={file} alt="" />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
                {isOpen && (
                  <Lightbox
                    mainSrc={files[photoIndex]}
                    nextSrc={files[(photoIndex + 1) % files.length]}
                    prevSrc={
                      files[(photoIndex + files.length - 1) % files.length]
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex:
                          (photoIndex + files.length - 1) % files.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % files.length,
                      })
                    }
                  />
                )}
              </CardContent>
              <CardContent style={{ backgroundColor: '#fcfcfc' }}>
                <Grid container spacing={2}>
                  <Grid item sm={8}>
                    <CardHeader
                      action={
                        <Button
                          id="add-asset-operation"
                          onClick={this.handleAddOperation}
                        >
                          <AddIcon style={{ marginRight: '5px' }} />
                          <FormattedMessage
                            id="dashboard.assets.management.ticket.operations.addoperation"
                            defaultMessage="Add Operation"
                          />
                        </Button>
                      }
                      title={
                        <FormattedMessage
                          id="dashboard.assets.management.ticket.operations.title_operations"
                          defaultMessage="Operations"
                        />
                      }
                    />

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <FormattedMessage
                              id="dashboard.assets.management.ticket.operations.table.create"
                              defaultMessage="Registered at"
                            />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage
                              id="dashboard.assets.management.ticket.operations.table.title"
                              defaultMessage="Title"
                            />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage
                              id="dashboard.assets.management.ticket.operations.table.description"
                              defaultMessage="Description"
                            />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage
                              id="dashboard.assets.management.ticket.operations.table.cost"
                              defaultMessage="Cost"
                            />
                          </TableCell>
                          <TableCell style={{ width: '70px' }} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(ticket.operations || []).map((c) => (
                          <TableRow key={c.id}>
                            <TableCell>
                              {format(new Date(c.datetime), 'yyyy-MM-dd')}
                            </TableCell>
                            <TableCell>{c.name}</TableCell>
                            <TableCell>{c.description}</TableCell>
                            <TableCell>{c.cost.toFixed(2)}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => this.deleteOperation(c.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell>
                            {sum(
                              (ticket.operations || []).map((c) => c.cost)
                            ).toFixed(2)}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </Grid>
                  <Grid item sm={4}>
                    <div
                      style={{
                        backgroundColor: '#f9f9f9',
                        border: '1px solid #f0f0f0',
                        borderRadius: '6px',
                        padding: '20px 10px 10px 10px',
                      }}
                    >
                      {(ticket.comments || []).map((c) => (
                        <Box key={c.id} mb={2}>
                          <Card>
                            <CardContent>
                              <Grid container wrap="nowrap">
                                <Grid item>
                                  <Box p={2}>
                                    <Avatar
                                      alt={c.accountName}
                                      src={c.accountImage}
                                    />
                                    <Typography variant="caption">
                                      {c.accountName}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    variant="body1"
                                    style={{ textAlign: 'left' }}
                                  >
                                    {c.comment}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    style={{ textAlign: 'left', color: 'gray' }}
                                  >
                                    {timeAgo(c.datetime, locale)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Box>
                      ))}
                      <FormControl required fullWidth margin="normal">
                        <TextField
                          label={
                            <FormattedMessage
                              id="dashboard.assets.management.ticket.label_comment"
                              defaultMessage="Comment"
                            />
                          }
                          name="comment"
                          variant="standard"
                          onChange={this.handleCommentChange}
                          multiline
                          rows={5}
                          value={comment}
                          required
                        />
                      </FormControl>
                      <CardActions
                        style={{
                          backgroundColor: '#fcfcfc',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {comment ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.handleComment}
                            disabled={commentLoading}
                          >
                            <FormattedMessage
                              id="dashboard.assets.management.ticket.btn_comment"
                              defaultMessage="Push Comment"
                            />
                          </Button>
                        ) : null}
                      </CardActions>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Loading>
          </Card>
        </Box>
        {addOperation ? (
          <TicketOperationForm
            ticket={ticket}
            close={this.closeOperationForm}
          />
        ) : null}
      </>
    );
  }
}

export default injectIntl(TicketDashboard);
