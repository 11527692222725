import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';

import React, { Component } from 'react';

import { IGeoMapping } from '@models/IncompleteFloorplan';

export interface IState {
  latitude: number | null;
  longitude: number | null;
}

interface IProps {
  handleCancel: () => void;
  handleMapping: (mapping: IGeoMapping) => void;
  position: [number, number];
}

class MappingForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      latitude: null,
      longitude: null,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(e: any) {
    const { name } = e.target;
    const { value } = e.target;

    this.setState({
      [name]: value,
    } as Pick<IState, 'latitude'>);
  }

  public handleCancel() {
    const { handleCancel } = this.props;

    handleCancel();
  }

  public handleSubmit() {
    const { position, handleMapping } = this.props;
    const { latitude, longitude } = this.state;

    handleMapping({
      coordinate: {
        latitude: latitude || 0,
        longitude: longitude || 0,
      },
      position,
    });
  }

  public render() {
    const { position } = this.props;
    const { latitude, longitude } = this.state;

    const error = !latitude || !longitude;

    const buttonDisabled = error;

    return (
      <Dialog open>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.geoalignment.mappingform.title"
            defaultMessage="Map Geo Location"
          />
        </DialogTitle>
        <DialogContent>
          <FormControl
            variant="standard"
            required={false}
            fullWidth
            margin="normal"
          >
            <InputLabel htmlFor="form-position">
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.geoalignment.mappingform.position_name"
                defaultMessage="Position"
              />
            </InputLabel>
            <Input
              disabled
              id="form-position"
              name="position"
              value={position}
            />
          </FormControl>

          <FormControl variant="standard" required fullWidth margin="normal">
            <InputLabel htmlFor="form-latitude">
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.geoalignment.mappingform.latitude_name"
                defaultMessage="Latitude"
              />
            </InputLabel>
            <Input
              id="form-latitude"
              name="latitude"
              type="number"
              value={latitude || ''}
              onChange={this.handleChange}
            />
            {latitude === null && (
              <FormHelperText>
                <FormattedMessage
                  id="dashboard.forms.floorplanconfigurationform.geoalignment.mappingform.latitude_helper"
                  defaultMessage="Must indicate the position latitude."
                />
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="standard" required fullWidth margin="normal">
            <InputLabel htmlFor="form-longitude">
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.geoalignment.mappingform.longitude_name"
                defaultMessage="Logitude"
              />
            </InputLabel>
            <Input
              id="form-longitude"
              name="longitude"
              type="number"
              value={longitude || ''}
              onChange={this.handleChange}
            />
            {longitude === null && (
              <FormHelperText>
                <FormattedMessage
                  id="dashboard.forms.floorplanconfigurationform.geoalignment.mappingform.longitude_helper"
                  defaultMessage="Must indicate the position longitude."
                />
              </FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            <FormattedMessage
              id="dashboard.forms.floorplanconfigurationform.geoalignment.mappingform.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            onClick={this.handleSubmit}
            variant="contained"
            color="primary"
            disabled={buttonDisabled}
          >
            <FormattedMessage
              id="dashboard.forms.floorplanconfigurationform.geoalignment.mappingform.buttom_submit_edit"
              defaultMessage="Save"
            />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default MappingForm;
