import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Calc, { formatPrice, roundValue } from './Calc';

interface IProps {
  calc: Calc;
}

const Result: FunctionComponent<IProps> = ({ calc }: IProps) => {
  const dtl = calc.calcROI();

  return (
    <>
      <Card>
        <CardHeader
          title={
            <FormattedMessage
              id="dashboard.financials.results_title"
              defaultMessage="Financial Results"
            />
          }
        />

        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell>Before</TableCell>
                <TableCell>After</TableCell>
                <TableCell>Gain</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="bold">
                <TableCell rowSpan={12}>Increased profits</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Available time / FL (Hours / Year)</b>
                </TableCell>
                <TableCell>{roundValue(dtl.availableTime, 2)}</TableCell>
                <TableCell>{roundValue(dtl.availableTime, 2)}</TableCell>
                <TableCell> </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>FL Usage</b>
                </TableCell>
                <TableCell>
                  {calc.usedFleetCapacity}
                  <span>%</span>
                </TableCell>
                <TableCell>
                  {calc.usedFleetCapacity}
                  <span>%</span>
                </TableCell>
                <TableCell> </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Operating time / FL (Hours / Year)</b>
                </TableCell>
                <TableCell>{roundValue(dtl.operatingTime.before, 2)}</TableCell>
                <TableCell>{roundValue(dtl.operatingTime.after, 2)}</TableCell>
                <TableCell className="result">
                  {roundValue(dtl.operatingTime.gain, 2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Pallets moved / Forklift (Pallets / Hour)</b>
                </TableCell>
                <TableCell>
                  {roundValue(dtl.movedPalletsByForklift.before, 2)}
                </TableCell>
                <TableCell>
                  {roundValue(dtl.movedPalletsByForklift.after, 2)}
                </TableCell>
                <TableCell className="result">
                  {roundValue(
                    dtl.movedPalletsByForklift.after -
                      dtl.movedPalletsByForklift.before,
                    2
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Time to move 1 pallet (min)</b>
                </TableCell>
                <TableCell>
                  {roundValue(dtl.timeToMovePalletMin.before, 2)}
                </TableCell>
                <TableCell>
                  {roundValue(dtl.timeToMovePalletMin.after, 2)}
                </TableCell>
                <TableCell className="result">
                  {roundValue(dtl.timeToMovePalletMin.gain, 2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Average number of pallets moved / day / forklift</b>
                </TableCell>
                <TableCell>
                  {roundValue(dtl.avgNumberOfPalletsMovedDayFork.before, 2)}
                </TableCell>
                <TableCell>
                  {roundValue(dtl.avgNumberOfPalletsMovedDayFork.after, 2)}
                </TableCell>
                <TableCell className="result">
                  {roundValue(
                    dtl.avgNumberOfPalletsMovedDayFork.after -
                      dtl.avgNumberOfPalletsMovedDayFork.before,
                    2
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Average number of pallets moved / day</b>
                </TableCell>
                <TableCell>
                  {roundValue(dtl.avgNumberOfPalletsMovedDay.before, 2)}
                </TableCell>
                <TableCell>
                  {roundValue(dtl.avgNumberOfPalletsMovedDay.after, 2)}
                </TableCell>
                <TableCell className="result">
                  {roundValue(dtl.avgNumberOfPalletsMovedDay.gain, 2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Pallets moved - Total Year</b>
                </TableCell>
                <TableCell>
                  {roundValue(dtl.totalPalletsMovedYear.before, 2)}
                </TableCell>
                <TableCell>
                  {roundValue(dtl.totalPalletsMovedYear.after, 2)}
                </TableCell>
                <TableCell className="result">
                  {roundValue(dtl.totalPalletsMovedYear.gain, 2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Max Number of TableRowucks served / day</b>
                </TableCell>
                <TableCell>
                  {roundValue(dtl.maxNumberOfTrucksServedDaily.before, 2)}
                </TableCell>
                <TableCell>
                  {roundValue(dtl.maxNumberOfTrucksServedDaily.after, 2)}
                </TableCell>
                <TableCell className="result">
                  {roundValue(dtl.maxNumberOfTrucksServedDaily.gain, 2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Revenues (Year)</b>
                </TableCell>
                <TableCell>{formatPrice(dtl.revenues.before)}</TableCell>
                <TableCell>{formatPrice(dtl.revenues.after)}</TableCell>
                <TableCell className="result">
                  {formatPrice(dtl.revenues.gain)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Profit (Year)</b>
                </TableCell>
                <TableCell>{formatPrice(dtl.profit.before)}</TableCell>
                <TableCell>{formatPrice(dtl.profit.after)}</TableCell>
                <TableCell className="result">
                  {formatPrice(dtl.profit.gain)}
                </TableCell>
              </TableRow>
              <TableRow className="bold">
                <TableCell rowSpan={5}>Efficiency</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Costs (day) / Forklift + Operator</b>
                </TableCell>
                <TableCell>{formatPrice(dtl.efficiencyCosts.before)}</TableCell>
                <TableCell>{formatPrice(dtl.efficiencyCosts.after)}</TableCell>
                <TableCell className="result">
                  {formatPrice(dtl.efficiencyCosts.gain)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>FL + Operator Costs / Pallet</b>
                </TableCell>
                <TableCell>
                  {formatPrice(dtl.operatorCostsByPallet.before)}
                </TableCell>
                <TableCell>
                  {formatPrice(dtl.operatorCostsByPallet.after)}
                </TableCell>
                <TableCell className="result">
                  {formatPrice(dtl.operatorCostsByPallet.gain)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Total Costs (year)</b>
                </TableCell>
                <TableCell>{formatPrice(dtl.totalCostsYear.before)}</TableCell>
                <TableCell>{formatPrice(dtl.totalCostsYear.after)}</TableCell>
                <TableCell className="result">
                  {formatPrice(dtl.totalCostsYear.gain)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Savings</b>
                </TableCell>
                <TableCell>{formatPrice(dtl.savings.before)}</TableCell>
                <TableCell>{formatPrice(dtl.savings.after)}</TableCell>
                <TableCell className="result">
                  {formatPrice(dtl.savings.gain)}
                </TableCell>
              </TableRow>
              <TableRow className="bold">
                <TableCell rowSpan={9}>Safety & Compliance</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Number of crashes (FL/Year)</b>
                </TableCell>
                <TableCell>
                  {roundValue(dtl.numberOfCrashes.before, 1)}
                </TableCell>
                <TableCell>
                  {roundValue(dtl.numberOfCrashes.after, 1)}
                </TableCell>
                <TableCell className="result">
                  {roundValue(dtl.numberOfCrashes.gain, 1)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Reparation costs (FL/Year)</b>
                </TableCell>
                <TableCell>{formatPrice(dtl.repairCosts.before)}</TableCell>
                <TableCell>{formatPrice(dtl.repairCosts.after)}</TableCell>
                <TableCell className="result">
                  {formatPrice(dtl.repairCosts.gain)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Downtime Hours (FL/Year)</b>
                </TableCell>
                <TableCell>{roundValue(dtl.downtimeHours.before, 2)}</TableCell>
                <TableCell>{roundValue(dtl.downtimeHours.after, 2)}</TableCell>
                <TableCell className="result">
                  {roundValue(dtl.downtimeHours.gain, 2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Profit gain due to less FL downtime (FL/Year)</b>
                </TableCell>
                <TableCell>
                  {formatPrice(dtl.profitDueToLessAccidents.before)}
                </TableCell>
                <TableCell>
                  {formatPrice(dtl.profitDueToLessAccidents.after)}
                </TableCell>
                <TableCell className="result">
                  {formatPrice(dtl.profitDueToLessAccidents.gain)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Reparation costs (Year)</b>
                </TableCell>
                <TableCell>
                  {formatPrice(dtl.reparationCostsYear.before)}
                </TableCell>
                <TableCell>
                  {formatPrice(dtl.reparationCostsYear.after)}
                </TableCell>
                <TableCell className="result">
                  {formatPrice(dtl.reparationCostsYear.gain)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Profit gain due to less FL downtime (Year)</b>
                </TableCell>
                <TableCell>
                  {formatPrice(dtl.profitDueToLessAccidentsYear.before)}
                </TableCell>
                <TableCell>
                  {formatPrice(dtl.profitDueToLessAccidentsYear.after)}
                </TableCell>
                <TableCell className="result">
                  {formatPrice(dtl.profitDueToLessAccidentsYear.gain)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Compliance fees (Year)</b>
                </TableCell>
                <TableCell>{formatPrice(dtl.complianceFees.before)}</TableCell>
                <TableCell>{formatPrice(dtl.complianceFees.after)}</TableCell>
                <TableCell className="result">
                  {formatPrice(dtl.complianceFees.gain)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Total costs (Year)</b>
                </TableCell>
                <TableCell>{formatPrice(dtl.totalCosts.before)}</TableCell>
                <TableCell>{formatPrice(dtl.totalCosts.after)}</TableCell>
                <TableCell className="result">
                  {formatPrice(dtl.totalCosts.gain)}
                </TableCell>
              </TableRow>
              <TableRow className="bold">
                <TableCell rowSpan={5}>Sustainability</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>C02 emission (Kg/FL/Year)</b>
                </TableCell>
                <TableCell>{roundValue(dtl.co2Emissions.before, 2)}</TableCell>
                <TableCell>{roundValue(dtl.co2Emissions.after, 2)}</TableCell>
                <TableCell className="result">
                  {roundValue(dtl.co2Emissions.gain, 2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>C02 emission (kg/Pallet moved)</b>
                </TableCell>
                <TableCell>
                  {roundValue(dtl.co2EmissionsByPallet.before, 2)}
                </TableCell>
                <TableCell>
                  {roundValue(dtl.co2EmissionsByPallet.after, 2)}
                </TableCell>
                <TableCell className="result">
                  {roundValue(dtl.co2EmissionsByPallet.gain, 2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Total C02 emission (Tons / Year)</b>
                </TableCell>
                <TableCell>
                  {roundValue(dtl.co2EmissionsTotalTonsYear.before, 2)}
                </TableCell>
                <TableCell>
                  {roundValue(dtl.co2EmissionsTotalTonsYear.after, 2)}
                </TableCell>
                <TableCell className="result">
                  {roundValue(dtl.co2EmissionsTotalTonsYear.gain, 2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Savings (Tons of C02) Year</b>
                </TableCell>
                <TableCell>{roundValue(dtl.co2Savings.before, 2)}</TableCell>
                <TableCell>{roundValue(dtl.co2Savings.after, 2)}</TableCell>
                <TableCell className="result">
                  {roundValue(dtl.co2Savings.gain, 2)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default Result;
