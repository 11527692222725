import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchOrdersWithItems,
  IFetchOrdersWithItems,
  openImportOrdersForm,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { getOrderList, isOrderListLoading } from '@selectors/orders';
import OrdersTab from './OrdersTab';

const mapStateToProps = (state: IAppState) => ({
  importOrdersFormOpen: state.orders.importOrdersForm.open,
  loading: isOrderListLoading(state.orders),
  orders: getOrderList(state.orders),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchOrdersWithItems: (properties: IFetchOrdersWithItems) =>
    dispatch(fetchOrdersWithItems.request(properties)),
  openImportOrdersForm: () => dispatch(openImportOrdersForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTab);
