export enum ConfirmDialogActions {
  ACTION = 'action',
  DELETE = 'delete',
  HIDE = 'hide',
  SHOW = 'show',
  STOP = 'stop',
}

export interface IConfirmDialog {
  confirmType: ConfirmDialogActions;
  message: string;
  onCancelation?: () => void;
  onConfirmation: () => void;
}

export default {};
