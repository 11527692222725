import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { onChange } from '@actions/filter';
import {
  addFilter,
  deleteFilter,
  fetchTemplates,
  fetchRules,
  IFetchTemplates,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { IFilter, IFilterChange } from '@reducers/filter';
import {
  getAccountLocale,
  getAccountMeasurementUnits,
} from '@selectors/accounts';
import { getAssetsByTag, mergeAssetsTags } from '@selectors/assets';
import { getCompleteFloorplans } from '@selectors/index';
import { getValidRules, getRuleAlerts } from '@selectors/rules';
import { getCompleteWarehouses } from '@selectors/warehouses';

import Filters from './Filters';

import './index.css';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;

  const filter = state.filter.filters[filterId] || {};

  return {
    account: state.authentication.account!,
    assets: getAssetsByTag(state, filter.tags || []),
    assetsTags: mergeAssetsTags(state.assets, []),
    employees: state.employees.employees,
    teams: state.teams.teams,
    filter,
    filterKeys: Object.keys(state.filter.filters || {}).filter(
      (key) => key !== 'simulator' && key !== 'popup'
    ),
    floorplans: getCompleteFloorplans(state),
    locale: getAccountLocale(state.authentication),
    measurementUnits: getAccountMeasurementUnits(state.authentication),
    ruleAlerts: getRuleAlerts(state, filterId),
    rules: getValidRules(state),
    templates: state.rules.templates,
    warehouses: getCompleteWarehouses(state),
    zones: Object.values(state.zones.zones),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addFilter: (properties: IFilter) => dispatch(addFilter(properties)),
  deleteFilter: (properties: { id: string }) =>
    dispatch(deleteFilter(properties)),
  fetchTemplates: (properties: IFetchTemplates) =>
    dispatch(fetchTemplates.request(properties)),
  fetchRules: () => dispatch(fetchRules.request({})),
  onChange: (properties: IFilterChange) => dispatch(onChange(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filters));
