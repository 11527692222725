import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';

import React, { Component } from 'react';

interface IManualFormProps {
  scale?: number;
  handleCancel: () => void;
  handleSubmit: (scale: number) => void;
}
interface IManualFormState {
  scale?: number;
}
class ManualScaleForm extends Component<IManualFormProps, IManualFormState> {
  constructor(props: IManualFormProps) {
    super(props);

    const { scale } = this.props;

    this.state = { scale: scale || 1 };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(e: any) {
    const { value } = e.target;

    this.setState({ scale: value ? Number(value) : undefined });
  }

  public handleSubmit() {
    const { handleSubmit } = this.props;
    const { scale } = this.state;

    handleSubmit(scale || 1);
  }

  public render() {
    const { handleCancel } = this.props;
    const { scale } = this.state;

    const error = scale === undefined;

    const buttonDisabled = error;

    return (
      <Dialog open>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.forms.floorplanconfigurationform.definescale.manualform.title_edit"
            defaultMessage="Edit scale value manually"
          />
        </DialogTitle>
        <DialogContent>
          <FormControl required fullWidth margin="normal">
            <InputLabel htmlFor="form-scale">
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.definescale.manualform.label_scale"
                defaultMessage="Scale"
              />
            </InputLabel>
            <Input
              id="form-scale"
              value={scale}
              type="number"
              onChange={this.handleChange}
            />
            <FormHelperText>
              <FormattedMessage
                id="dashboard.forms.floorplanconfigurationform.definescale.manualform.field_scale_helper"
                defaultMessage="A scale value must be specified."
              />
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            <FormattedMessage
              id="dashboard.forms.floorplanconfigurationform.definescale.manualform.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            color="secondary"
            disabled={buttonDisabled}
            onClick={this.handleSubmit}
            variant="contained"
          >
            <FormattedMessage
              id="dashboard.forms.floorplanconfigurationform.definescale.manualform.button_edit"
              defaultMessage="Edit"
            />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ManualScaleForm;
