import React, { FunctionComponent, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { withRouter } from '@app/utils/withRouter';

interface IProps {
  language: string;
  router: any;
}
const Landing: FunctionComponent<IProps> = ({
  router,
  language,
}: IProps) => {
  const [state, setAnchor] = useState({ anchorEl: undefined });
  const { anchorEl } = state;

  return (
    <Box mr={2}>
      <Card>
        <CardHeader
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <HelpOutlineIcon />
              <span style={{ paddingLeft: '5px' }}>Tickets & Support</span>
            </div>
          }
          action={
            <>
              <IconButton
                aria-label="More"
                aria-owns={anchorEl ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={(event: any) => setAnchor({ anchorEl: event.target })}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchor({ anchorEl: undefined })}
              >
                <MenuItem
                  onClick={() =>
                    router.navigate(`/${language}/dashboard/tickets/new`)
                  }
                >
                  <ListItemIcon>
                    <LiveHelpIcon />
                  </ListItemIcon>
                  <ListItemText primary="New help ticket" />
                </MenuItem>
              </Menu>
            </>
          }
        />
        <CardContent />
      </Card>
    </Box>
  );
};

export default withRouter(Landing);
