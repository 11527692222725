import { size } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchFloorplanSensorGroupStatus,
  IFetchFloorplanSensors,
  IUpdateFloorplan,
  updateFloorplan,
} from '@actions/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import Sensor from '@models/Sensor';
import { IAppState } from '@reducers/index';
import { getFloorplanSensorGroupWithStatus } from '@selectors/sensors';
import SensorAlignment from './SensorAlignment';

interface IProps {
  floorplan: IncompleteFloorplan;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
  sensors: Sensor[];
}

const mapStateToProps = (state: IAppState, { floorplan, sensors }: IProps) => ({
  activeSensorGroup: getFloorplanSensorGroupWithStatus(
    state,
    floorplan.id || ''
  ),
  installationComplete: size(sensors) > 1,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchActiveSensorGroup: (properties: IFetchFloorplanSensors) => {
    dispatch(fetchFloorplanSensorGroupStatus.request(properties));
  },
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorAlignment);
