import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import React, { Component } from 'react';

import { ICreateAnswer } from '@actions/index';
import PreCheck, {
  IPreCheckAnswer,
  IPreCheckQuestion,
  ResponseTypes,
} from '@models/PreCheck';
import LoadingButton from '@app/common/LoadingButton';
import FetchError from '@models/FetchError';

export interface IProps {
  assetId: string;
  createPreCheckAnswer: (properties: ICreateAnswer) => void;
  error?: FetchError;
  fetchPreCheck: (id: string) => void;
  intl: IntlShape;
  loading: boolean;
  loadingSubmit: boolean;
  preCheckId: string;
  preCheck?: PreCheck;
  close: () => void;
}
interface IState {
  questionIndex: number;
  preCheckId: string;
  answers: Record<string, IPreCheckAnswer>;
}

class PreCheckComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { preCheckId } = props;

    this.state = {
      answers: {},
      preCheckId,
      questionIndex: 0,
    };

    this.back = this.back.bind(this);
    this.scaleAnswer = this.scaleAnswer.bind(this);
    this.submit = this.submit.bind(this);
  }

  public componentDidMount() {
    const { fetchPreCheck, preCheckId } = this.props;

    fetchPreCheck(preCheckId);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { close, loadingSubmit, error } = this.props;

    if (!loadingSubmit && !error && prevProps.loadingSubmit) {
      close();
    }
  }

  public answer(
    answer1: boolean | null,
    answer2: number | null,
    index: number
  ) {
    const { answers, questionIndex } = this.state;

    if (answer1 !== null || answer2 !== null) {
      answers[String(questionIndex)] = {
        answerBoolean: answer1 !== null ? answer1 : undefined,
        answerNumber: answer2 !== null ? answer2 : undefined,
        index,
      };

      this.setState({ answers, questionIndex: questionIndex + 1 });
    }
  }

  public scaleAnswer(question: IPreCheckQuestion) {
    return (event: any) =>
      this.answer(null, Number(event.target.value), question.index);
  }

  public back() {
    const { questionIndex } = this.state;

    this.setState({ questionIndex: questionIndex - 1 });
  }

  public submit() {
    const { assetId, createPreCheckAnswer, intl } = this.props;
    const { preCheckId, answers } = this.state;

    createPreCheckAnswer({
      answers: Object.values(answers),
      assetId,
      id: preCheckId,
      intl,
    });
  }

  public render() {
    const { close, loading, loadingSubmit, preCheck } = this.props;
    const { questionIndex } = this.state;

    let content = (
      <>
        <Box mt={2} mb={2}>
          <Alert severity="success">
            <FormattedMessage
              id="dashboard.assets.management.precheck.completed"
              defaultMessage="Pre-check completed! Please submit your answers."
            />
          </Alert>
        </Box>
        <LoadingButton
          onClick={this.submit}
          variant="contained"
          color="secondary"
          fullWidth
          loading={loadingSubmit}
        >
          <FormattedMessage
            id="dashboard.assets.management.precheck.submit"
            defaultMessage="Submit Pre-Check"
          />
        </LoadingButton>
      </>
    );

    if (!preCheck || loading === true) {
      content = (
        <Box mt={2} mb={2} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      );
    } else {
      const sortedQuestions = preCheck.questions.sort((a, b) =>
        a.index > b.index ? 1 : -1
      );
      const question = sortedQuestions[questionIndex];

      if (question) {
        if (question.responseType === ResponseTypes.YESNO) {
          content = (
            <>
              <Box mt={2} mb={2}>
                <Typography variant="body1" style={{ textAlign: 'center' }}>
                  {question.description}
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: 'green' }}
                    onClick={() => this.answer(true, null, question.index)}
                  >
                    <CheckIcon />
                    <FormattedMessage id="yes" defaultMessage="Yes" />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: 'red' }}
                    onClick={() => this.answer(false, null, question.index)}
                  >
                    <CloseIcon />
                    <FormattedMessage id="no" defaultMessage="No" />
                  </Button>
                </Grid>
              </Grid>
            </>
          );
        } else {
          content = (
            <FormControl required fullWidth margin="normal">
              <InputLabel htmlFor="precheck-form">
                {question.description}
              </InputLabel>
              <Select
                id="precheck-form"
                variant="standard"
                onChange={this.scaleAnswer(question)}
                name="precheck-form"
              >
                <MenuItem value="" disabled />
                {Array.from(new Array(question.scale || 1).keys()).map(
                  (a, index) => (
                    <MenuItem key={a} value={String(index)}>
                      {String(index + 1)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          );
        }
      }
    }

    return (
      <Dialog open maxWidth="md" fullWidth>
        <DialogTitle>
          {(preCheck || {}).name}
          <IconButton
            aria-label="close"
            onClick={close}
            style={{ position: 'absolute', top: '16px', right: '16px' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {questionIndex > 0 ? (
            <Button onClick={this.back}>
              <ChevronLeftIcon />
              <FormattedMessage id="back" defaultMessage="Back" />
            </Button>
          ) : null}

          {preCheck && questionIndex + 1 <= preCheck.questions.length ? (
            <Breadcrumbs aria-label="breadcrumb" style={{ float: 'right' }}>
              <Typography color="textPrimary">{questionIndex + 1}</Typography>
              <Typography color="textPrimary">
                {preCheck.questions.length}
              </Typography>
            </Breadcrumbs>
          ) : null}
          {content}
        </DialogContent>
      </Dialog>
    );
  }
}

export default injectIntl(PreCheckComponent);
