import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IEventsFetch } from '@actions/player';
import {
  fetchEventsActivity,
  fetchRuleAlerts,
  fetchSensorsData,
  IFetchRuleAlerts,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getAccountMeasurementUnits } from '@selectors/accounts';
import { getSelectedLocale } from '@selectors/app';
import {
  getEventsActivity,
  getEventsActivityError,
  isEventsActivityLoading,
} from '@selectors/player';
import { getDataRange, getDataRangeError } from '@selectors/plots';
import {
  getRuleAlerts,
  getRuleAlertsError,
  isRuleAlertsLoading,
} from '@selectors/rules';
import { getSensorsData, getSensorsDataIntervals } from '@selectors/sensors';
import { getCompleteWarehouses } from '@selectors/warehouses';

import PlayerTab from './PlayerTab';

interface IProps {
  filterId: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { filterId } = props;
  const filter = state.filter.filters[filterId] || {};

  return {
    assets: Object.values(state.assets.assets),
    data: getSensorsData(state, filter.floorplanId || ''),
    dataRange: getDataRange(state, filterId),
    error:
      getDataRangeError(state, filterId) ||
      getEventsActivityError(state, filterId) ||
      state.assets.error ||
      getRuleAlertsError(state),
    eventsActivity: getEventsActivity(state, filterId),
    intervals: getSensorsDataIntervals(state, filter.floorplanId || ''),
    loading:
      isEventsActivityLoading(state, filterId) ||
      state.assets.loading ||
      isRuleAlertsLoading(state, filterId),
    locale: getSelectedLocale(state),
    measurementUnits: getAccountMeasurementUnits(state.authentication),
    ruleAlerts: getRuleAlerts(state, filterId),
    warehouses: getCompleteWarehouses(state),

    warehouseId: filter.warehouseId || '',
    floorplanId: filter.floorplanId || '',
    zoneIds: filter.zoneIds || [],

    assetIds: filter.assetIds || [],
    tags: filter.tags || [],

    ruleIds: filter.ruleIds || [],
    templateIds: filter.templateIds || [],

    eventIds: filter.eventIds || [],

    endDate: filter.endDate,
    startDate: filter.startDate,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchEventsActivity: (properties: IEventsFetch) =>
    dispatch(fetchEventsActivity.request(properties)),
  fetchRuleAlerts: (properties: IFetchRuleAlerts) =>
    dispatch(fetchRuleAlerts.request(properties)),
  fetchSensorsData: (
    floorplanId: string,
    assetIds: string[],
    timestamp: number
  ) => dispatch(fetchSensorsData.request({ assetIds, floorplanId, timestamp })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerTab));
