import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper/Stepper';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import FloatLoading from '@app/common/FloatLoading';
import IncompleteFloorplan, { IGeoMapping } from '@models/IncompleteFloorplan';
import { WarehouseWithFloorplans } from '@models/WarehouseWithFloorplans';
import { TransformationMatrix2D } from '../../utils';
import { loadImage, urltoFile } from '../utils/mapUtils';
import DefineScaleStep from './DefineScaleStep/index';
import DrawDefinition from './DrawDefinitionStep/index';
import ErrorDialogHOC from './ErrorDialogHOC';
import FloorplanStep from './floorplanStep/FloorplanStep';
import GeoAlignment from './GeoAlignmentStep/index';
import ZoneStep from './ZoneStep/index';
import SensorAlignment from './SensorAlignmentStep/index';
import SensorInstallation from './SensorInstallationStep/index';
import WarehouseStep from './warehouseStep/WarehouseStep';

import './Setup.css';

export const STEPS_LABELS = [
  'Create Warehouse',
  'Floorplan Upload',
  'Scale Definition',
  'Sensor Installation',
  'Sensor Alignment',
  'Geo Alignment (optional)',
  'Exterior Boundaries Definition (optional)',
  'Boundaries Definition (optional)',
  'Zone Definition (optional)',
  'Obstacles Definition (optional)',
  'Rack Definition (optional)',
];

export interface IProps {
  activeStep: number;
  error?: object;
  fetchStationarySensors: (floorplanId: string) => void;
  floorplan?: IncompleteFloorplan;
  intl: IntlShape;
  loading: boolean;
  onSkipInstallation: (
    image: File | undefined,
    intl: IntlShape,
    name: string,
    scale: number,
    transformationMatrix: TransformationMatrix2D,
    geoMapping: IGeoMapping[],
    warehouseId: string,
    floorplanId?: string
  ) => void;
  skipZoneSetup: (floorplanId: string) => void;
  warehouse?: WarehouseWithFloorplans;
}

export interface IState {
  currentStep: number;
}

class Setup extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { activeStep } = this.props;

    this.state = {
      currentStep: activeStep,
    };

    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSkipInstallation = this.handleSkipInstallation.bind(this);
  }

  public componentDidMount() {
    const { floorplan, fetchStationarySensors } = this.props;

    if (floorplan) {
      fetchStationarySensors(floorplan.id);
    }
  }

  public handleBack() {
    this.setState(({ currentStep }) => ({ currentStep: currentStep - 1 }));
  }

  public handleNext(zone?: boolean) {
    const { floorplan, skipZoneSetup } = this.props;

    if (zone) {
      skipZoneSetup(floorplan!.id);
    }

    this.setState(({ currentStep }) => ({
      currentStep: currentStep + 1,
    }));
  }

  public handleSkipInstallation(name: string, imageSrc?: string) {
    const { floorplan, intl, onSkipInstallation, warehouse } = this.props;

    const { id } = floorplan || ({} as IncompleteFloorplan);

    const updateFloorplan = (image: HTMLImageElement, file: File) => {
      const scale = 1;
      const transformationMatrix: TransformationMatrix2D = [
        [1, 0, image.width / 2 / scale],
        [0, 1, image.height / 2 / scale],
        [0, 0, 1],
      ];
      const geoMapping: IGeoMapping[] = [];

      onSkipInstallation(
        file,
        intl,
        name,
        scale,
        transformationMatrix,
        geoMapping,
        warehouse!.id,
        id
      );
    };

    if (imageSrc !== undefined && imageSrc.indexOf('http') !== -1) {
      loadImage(imageSrc, (err, image) => {
        if (image) {
          const scale = (floorplan || ({} as IncompleteFloorplan)).scale || 50;
          const transformationMatrix: TransformationMatrix2D = [
            [1, 0, image.width / 2 / scale],
            [0, 1, image.height / 2 / scale],
            [0, 0, 1],
          ];
          const geoMapping =
            (floorplan || ({} as IncompleteFloorplan)).geoMapping || [];

          onSkipInstallation(
            undefined,
            intl,
            name,
            scale,
            transformationMatrix,
            geoMapping,
            warehouse!.id,
            id
          );
        }
      });
    } else if (imageSrc) {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        urltoFile(image.src, 'floorplan.png', 'image/png').then((file) => {
          updateFloorplan(image, file);
        });
      };
    } else {
      loadImage('/assets/empty_floorplan.png', (err, image) => {
        if (image) {
          urltoFile(image.src, 'default_fp.png', 'image/png').then((file) => {
            updateFloorplan(image, file);
          });
        }
      });
    }
  }

  public getStepContent(step: number): React.ReactNode {
    const { floorplan, warehouse } = this.props;

    switch (step) {
      case 0:
        return (
          <WarehouseStep
            onNext={this.handleNext}
            warehouseId={warehouse && warehouse.id}
          />
        );
      case 1:
        return (
          <FloorplanStep
            onBack={this.handleBack}
            onNext={this.handleNext}
            floorplanId={floorplan && floorplan.id}
            warehouseId={warehouse!.id}
            onSkip={this.handleSkipInstallation}
          />
        );
      case 2:
        return (
          <DefineScaleStep
            floorplanId={floorplan!.id}
            onBack={this.handleBack}
            onNext={this.handleNext}
            onSkip={this.handleNext}
            warehouseId={warehouse!.id}
          />
        );
      case 3:
        return (
          <SensorInstallation
            floorplanId={floorplan!.id}
            onBack={this.handleBack}
            onNext={this.handleNext}
            warehouseId={warehouse!.id}
          />
        );
      case 4:
        return (
          <SensorAlignment
            floorplanId={floorplan!.id}
            onBack={this.handleBack}
            onNext={this.handleNext}
            warehouseId={warehouse!.id}
          />
        );
      case 5:
        return (
          <GeoAlignment
            floorplanId={floorplan!.id}
            onBack={this.handleBack}
            onNext={this.handleNext}
            onSkip={this.handleNext}
            warehouseId={warehouse!.id}
          />
        );
      case 6:
        return (
          <DrawDefinition
            features={floorplan!.exteriorBoundaries || []}
            floorplanId={floorplan!.id}
            onBack={this.handleBack}
            onNext={this.handleNext}
            onSkip={this.handleNext}
            warehouseId={warehouse!.id}
            minNumberFeatures={1}
            type="exteriorBoundaries"
          />
        );
      case 7:
        return (
          <DrawDefinition
            features={floorplan!.boundaries || []}
            floorplanId={floorplan!.id}
            onBack={this.handleBack}
            onNext={this.handleNext}
            onSkip={this.handleNext}
            warehouseId={warehouse!.id}
            minNumberFeatures={1}
            type="boundaries"
          />
        );
      case 8:
        return (
          <ZoneStep
            floorplanId={floorplan!.id}
            onBack={this.handleBack}
            onNext={() => this.handleNext(true)}
            onSkip={() => this.handleNext(true)}
          />
        );
      case 9:
        return (
          <DrawDefinition
            features={floorplan!.obstacles || []}
            floorplanId={floorplan!.id}
            onBack={this.handleBack}
            onNext={this.handleNext}
            onSkip={this.handleNext}
            warehouseId={warehouse!.id}
            minNumberFeatures={1}
            type="obstacles"
          />
        );
      case 10:
        return (
          <DrawDefinition
            features={floorplan!.racks || []}
            floorplanId={floorplan!.id}
            onBack={this.handleBack}
            onNext={this.handleNext}
            onSkip={this.handleNext}
            warehouseId={warehouse!.id}
            minNumberFeatures={1}
            type="racks"
          />
        );
      default:
        return null;
    }
  }

  public render() {
    const { currentStep } = this.state;
    const { activeStep, error, loading } = this.props;
    const actualStep = Math.min(currentStep, activeStep);

    let tourStep = actualStep + 1 < 9 ? actualStep + 1 : 8;
    // Show welcome
    if (tourStep === 1) {
      tourStep = 0;
    }

    return (
      <FloatLoading loading={loading}>
        <div className="setup-wrapper">
          <Typography className="setup-title" variant="h4">
            <FormattedMessage
              id="dashboard.setup.title"
              defaultMessage="Dashboard Setup"
            />
          </Typography>
          <Stepper
            className="setup-stepper"
            activeStep={actualStep}
            alternativeLabel
          >
            {STEPS_LABELS.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div
            className="setup-steps"
            style={{
              alignItems:
                actualStep === 0 || actualStep === 1 ? 'flex-start' : 'unset',
            }}
          >
            <ErrorDialogHOC error={error}>
              {this.getStepContent(actualStep)}
            </ErrorDialogHOC>
          </div>
        </div>
      </FloatLoading>
    );
  }
}

export default injectIntl(Setup);
