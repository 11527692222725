import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { FormattedMessage } from 'react-intl';

import React, { Component } from 'react';

import Loading from '@app/common/Loading';
import AccountMeta from '@models/AccountMeta';

interface IState {
  permissions: Record<string, boolean>;
}

interface IProps {
  account: AccountMeta;
  availablePermissions: string[];
  fetchAvailablePermissions: () => void;
  onCancel: () => void;
  onOk: (permissions: string[]) => void;
  loading: boolean;
}

class AccountPermissionsForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const accountPermissions = props.account.permissions;
    const permissions = {} as Record<string, boolean>;
    accountPermissions.forEach((p) => {
      permissions[p] = true;
    });

    this.state = {
      permissions,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    const { fetchAvailablePermissions } = this.props;

    fetchAvailablePermissions();
  }

  public componentDidUpdate(prevProps: any) {
    const { availablePermissions } = this.props;

    if (
      JSON.stringify(prevProps.availablePermissions) !==
      JSON.stringify(availablePermissions)
    ) {
      this.setPermissions();
    }
  }

  public handleChange(permission: string) {
    const { permissions } = this.state;

    permissions[permission] = !permissions[permission];

    this.setState({ permissions });
  }

  public handleCancel() {
    const { onCancel } = this.props;

    onCancel();
  }

  public handleSubmit() {
    const { onOk } = this.props;
    const { permissions } = this.state;

    const keys = Object.keys(permissions);
    const activePermissions = [];
    for (let i = 0; i < keys.length; i += 1) {
      if (permissions[keys[i]] === true) {
        activePermissions.push(keys[i]);
      }
    }

    onOk(activePermissions);
  }

  public setPermissions() {
    const { availablePermissions, account } = this.props;

    const newPermissions = {} as Record<string, boolean>;

    for (let i = 0; i < account.permissions.length; i += 1) {
      if (availablePermissions.indexOf(account.permissions[i]) !== -1) {
        newPermissions[account.permissions[i]] = true;
      }
    }

    for (let i = 0; i < availablePermissions.length; i += 1) {
      if (newPermissions[availablePermissions[i]] === undefined) {
        newPermissions[availablePermissions[i]] = false;
      }
    }

    this.setState({ permissions: newPermissions });
  }

  public render() {
    const { account, loading } = this.props;
    const { permissions } = this.state;

    return (
      <Dialog open>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.forms.accountpermissions.title"
            defaultMessage="Account permissions for {name}"
            values={{ name: account.authName }}
          />
        </DialogTitle>
        <DialogContent>
          <Loading loading={loading}>
            {Object.keys(permissions).map((p) => {
              const control = (
                <Checkbox
                  checked={permissions[p] || false}
                  onChange={() => this.handleChange(p)}
                  value="true"
                />
              );

              return (
                <FormControl required fullWidth key={p}>
                  <FormGroup>
                    <FormControlLabel control={control} label={p} />
                  </FormGroup>
                </FormControl>
              );
            })}
          </Loading>
        </DialogContent>
        {loading !== true && (
          <DialogActions>
            <Button onClick={this.handleCancel}>
              <FormattedMessage
                id="dashboard.forms.accountpermissions.button_cancel"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="secondary"
            >
              <FormattedMessage
                id="dashboard.forms.accountpermissions.button_submit"
                defaultMessage="Edit"
              />
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

export default AccountPermissionsForm;
