import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { parse } from 'qs';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PasswordReset } from '@actions/authentication';
import FetchError from '@models/FetchError';
import PasswordFields from '../../forms/AccountForm/PasswordFields';

interface IProps {
  language: string;
  loading: boolean;
  router: any;
  passwordReset: (properties: PasswordReset) => void;
  error?: FetchError;
}

interface IState {
  password: string;
  repassword: string;
  error?: FetchError;
}

class PasswordRecoveryPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      password: '',
      repassword: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { error, language, loading, router } = this.props;

    if (prevProps.error !== error) {
      this.showError();
    }
    if (prevProps.loading !== loading && !loading && !error) {
      router.navigate(`/${language}/login?reseted=true`);
    }
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'password'>);
    };
  }

  public handleSubmit() {
    const { password } = this.state;
    const { passwordReset, router } = this.props;

    const query = parse(router.query.search, { ignoreQueryPrefix: true });

    passwordReset({
      email: query.email as string,
      code: query.code as string,
      password,
    });
  }

  public showError() {
    const { error } = this.props;

    this.setState({ error });
  }

  public closeSnackbar() {
    this.setState({ error: undefined });
  }

  public render() {
    const { password, repassword, error } = this.state;
    const { loading } = this.props;

    let errorMessage = (
      <FormattedMessage
        id="external.passwordreset.error"
        defaultMessage="Something went wrong, please try again or contact our support."
      />
    );

    if (error && error.errorCode === 'RecoveryCodeNotFound') {
      errorMessage = (
        <FormattedMessage
          id="external.passwordreset.notfound"
          defaultMessage="Recovery code not found."
        />
      );
    } else if (error && error.errorCode === 'RecoveryCodeExpired') {
      errorMessage = (
        <FormattedMessage
          id="external.passwordreset.expired"
          defaultMessage="Recovery code expired."
        />
      );
    } else if (error && error.errorCode === 'RecoveryAlreadyUsed') {
      errorMessage = (
        <FormattedMessage
          id="external.passwordreset.alreadyused"
          defaultMessage="Recovery code already used."
        />
      );
    }

    const doPasswordsMatch =
      password.length > 0 && repassword.length > 0
        ? password === repassword
        : true;

    const hasError =
      password.length === 0 ||
      password.length < 8 ||
      repassword.length === 0 ||
      !doPasswordsMatch;

    return (
      <>
        <div id="banner">
          <video muted autoPlay loop poster="/assets/background.jpg">
            <source src="/assets/banner.mp4" type="video/mp4" />
            <track kind="captions" />
          </video>
        </div>

        <Grid container justifyContent="center" style={{ zIndex: 1 }}>
          <Grid item sm={4}>
            <Box mt={12} ml={2} mr={2} mb={2}>
              <Card className="container">
                <CardHeader
                  title={
                    <FormattedMessage
                      id="external.passwordreset.title"
                      defaultMessage="Password Reset"
                    />
                  }
                />
                <CardContent>
                  <PasswordFields
                    doPasswordsMatch={doPasswordsMatch}
                    handleChange={this.handleChange}
                    password={password}
                    repassword={repassword}
                  />
                  <Button
                    onClick={this.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={hasError || loading}
                    fullWidth
                  >
                    <FormattedMessage
                      id="external.passwordreset.button_submit"
                      defaultMessage="Reset password"
                    />
                  </Button>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>

        <Snackbar
          open={!!error && !loading}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={this.closeSnackbar}
          autoHideDuration={3000}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      </>
    );
  }
}

export default PasswordRecoveryPage;
