import { defineMessages } from 'react-intl';

export const dropZoneMessages = defineMessages({
  dropzoneText: {
    defaultMessage: 'Drag and drop photos here',
    id: 'dashboard.dropzone.dropzonetext',
  },
  dropzoneTextSingleFile: {
    defaultMessage: 'Drag and drop a file here',
    id: 'dashboard.dropzone.dropzonetext_singlefile',
  },
  exceededMessage: {
    defaultMessage: 'Maximum number of files exceeded. Only {limit} allowed',
    id: 'dashboard.dropzone.exceededmessage',
  },
  addedMessage: {
    defaultMessage: 'File {fileName} added with success',
    id: 'dashboard.dropzone.addedmessage',
  },
  removedMessage: {
    defaultMessage: 'File {fileName} removed',
    id: 'dashboard.dropzone.removedmessage',
  },
  unsupportedFile: {
    defaultMessage: 'File {fileName} is of an unsupported type',
    id: 'dashboard.dropzone.unsupportedfile',
  },
  fileTooBig: {
    defaultMessage: 'File {fileName} is too big. Max. size is {size}',
    id: 'dashboard.dropzone.filetoobig',
  },
});

export default {};
