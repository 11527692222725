import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import ShallowLoading from './ShallowLoading';

const mapStateToProps = (state: IAppState) => ({
  loading:
    Object.keys(state.shallowLoading.loading)
      .map((id) => state.shallowLoading.loading[id])
      .find((l) => l === true) || false,
  reconnecting: state.shallowLoading.reconnecting,
});

export default connect(mapStateToProps)(ShallowLoading);
