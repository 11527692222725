import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import RoutingTab from './RoutingTab';

const mapStateToProps = (state: IAppState) => ({
  filters: state.filter.filters,
});

export default connect(mapStateToProps)(RoutingTab);
