import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import Ajv from 'ajv';
import localize from 'ajv-i18n';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { IChangeEvent } from 'react-jsonschema-form';

import { ICreateRule, IUpdateRule } from '@actions/rules';
import LoadingButton from '@app/common/LoadingButton';
import { Assets } from '@models/Asset';
import FetchError from '@models/FetchError';
import { Floorplans } from '@models/Floorplan';
import { Rule } from '@models/Rule';
import { RuleTemplates } from '@models/RuleTemplate';
import { WarehousesWithCompleteFloorplans } from '@models/WarehouseWithCompleteFloorplans';
import { Zones } from '@models/Zone';
import Form from '../../jsonschema-form/Form';
import { injectDefinitions, toDefinition } from '../../jsonschema-form/utils';

export interface IState {
  formData: Record<string, any>;
  id: string;
  name: string;
  notifyEmail: boolean;
  notifyMobile: boolean;
  notifyWhatsapp: boolean;
  templateId: string;
}

export interface IProps {
  createNewZone: (state: IState) => void;
  createRule: (properties: ICreateRule) => void;
  data?: Rule;
  stateData?: IState;
  error?: FetchError;
  floorplanId: string;
  loading: boolean;
  locale: string;
  onClose: () => void;
  templates: RuleTemplates;
  updateRule: (properties: IUpdateRule) => void;
  assets: Assets;
  floorplans: Floorplans;
  warehouses: WarehousesWithCompleteFloorplans;
  zones: Zones;
}

class RuleForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { assets, data, stateData, zones } = props;

    if (stateData) {
      this.state = { ...stateData };
    } else {
      const formData = {} as Record<string, any>;

      if (data !== undefined) {
        formData.applicableTo = {
          ...(data.applicableTo || {}),
          assets: (data.applicableTo.assets || []).filter(
            (id: string) => Object.keys(assets).indexOf(id) !== -1
          ),
          zones: (data.applicableTo.zones || []).filter(
            (id: string) => Object.keys(zones).indexOf(id) !== -1
          ),
        };

        formData.options = data.options;
      }

      this.state = {
        formData,
        id: data !== undefined ? data.id : '',
        name: data !== undefined ? data.name : '',
        notifyEmail: data !== undefined ? data.notifyEmail : false,
        notifyMobile: data !== undefined ? data.notifyMobile : false,
        notifyWhatsapp: data !== undefined ? data.notifyWhatsapp : false,
        templateId: data !== undefined ? data.templateId : '',
      };
    }

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.createNewZone = this.createNewZone.bind(this);
  }

  public handleFormChange({ formData }: IChangeEvent<any>) {
    this.setState({ formData });
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'name'>);
    };
  }

  public handleCheckChange(key: keyof IState) {
    return (e: any) => {
      const value: boolean = e.target.checked;

      this.setState({
        [key]: value,
      } as Pick<IState, 'notifyEmail'>);
    };
  }

  public handleSubmit() {
    const { createRule, updateRule } = this.props;
    const {
      formData,
      id,
      name,
      notifyEmail,
      notifyMobile,
      notifyWhatsapp,
      templateId,
    } = this.state;

    if (id !== '') {
      updateRule({
        id,
        name,
        notifyEmail,
        notifyMobile,
        notifyWhatsapp,
        options: formData,
        templateId,
      });
    } else {
      createRule({
        name,
        notifyEmail,
        notifyMobile,
        notifyWhatsapp,
        options: formData,
        templateId,
      });
    }
  }

  public handleCancel() {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  }

  public createNewZone() {
    const { createNewZone } = this.props;

    createNewZone(this.state);
  }

  public render() {
    const {
      id,
      name,
      notifyEmail,
      notifyMobile,
      notifyWhatsapp,
      templateId,
      formData,
    } = this.state;
    const {
      assets,
      error,
      floorplans,
      loading,
      locale,
      templates,
      warehouses,
      zones,
    } = this.props;

    let isJSONFormValid = false;
    let schema;
    let validate;
    if (templateId) {
      const definitions = {
        assets: toDefinition(Object.values(assets)),
        floorplans: toDefinition(Object.values(floorplans)),
        warehouses: toDefinition(Object.values(warehouses)),
        zones: toDefinition(Object.values(zones)),
      };

      schema = injectDefinitions(templates[templateId].schema, definitions);

      try {
        const ajv = Ajv({ coerceTypes: true });
        validate = ajv.compile(schema);

        isJSONFormValid = validate(formData) as boolean;

        if (locale === 'en-GB') {
          localize.en(validate.errors);
        } else if (locale === 'pt-PT') {
          localize['pt-BR'](validate.errors);
        } else if (locale === 'ro-RO') {
          localize.en(validate.errors);
        }
      } catch (err) {
        isJSONFormValid = false;
      }
    }

    return (
      <Dialog open>
        <DialogTitle>
          {id !== '' ? (
            <FormattedMessage
              id="dashboard.forms.ruleform.title_edit"
              defaultMessage="Edit Rule"
            />
          ) : (
            <FormattedMessage
              id="dashboard.forms.ruleform.title_create"
              defaultMessage="Add Rule"
            />
          )}
        </DialogTitle>
        <DialogContent>
          <FormControl variant="standard" required fullWidth margin="normal">
            <InputLabel htmlFor="add-rule-form-name">
              <FormattedMessage
                id="dashboard.forms.ruleform.label_name"
                defaultMessage="Name"
              />
            </InputLabel>
            <Input
              id="add-rule-form-name"
              value={name}
              onChange={this.handleChange('name')}
            />
            <FormHelperText>
              <FormattedMessage
                id="dashboard.forms.ruleform.field_name_helper"
                defaultMessage="A name must be specified"
              />
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" required fullWidth margin="normal">
            <InputLabel htmlFor="add-rule-form-template">
              <FormattedMessage
                id="dashboard.forms.ruleform.label_template"
                defaultMessage="Template"
              />
            </InputLabel>
            <Select
              value={templateId}
              onChange={this.handleChange('templateId')}
              inputProps={{
                id: 'add-rule-form-template',
                name: 'templateId',
              }}
            >
              {Object.values(templates).map((t) => (
                <MenuItem key={t.id} value={t.id}>
                  {t.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              <FormattedMessage
                id="dashboard.forms.ruleform.field_template_helper"
                defaultMessage="A template must be specified"
              />
            </FormHelperText>
          </FormControl>
          {templates[templateId] !== undefined && schema !== undefined && (
            <>
              <Typography
                style={{ marginTop: 32, marginBottom: 8 }}
                variant="h6"
              >
                Options
              </Typography>
              <Form
                formData={formData}
                schema={schema}
                uiSchema={templates[templateId].uiSchema}
                onChange={this.handleFormChange}
                handleNewZone={this.createNewZone}
              >
                <></>
              </Form>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="legend">
                  <FormattedMessage
                    id="dashboard.forms.ruleform.field_notification"
                    defaultMessage="Send out notifications:"
                  />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={notifyEmail}
                        onChange={this.handleCheckChange('notifyEmail')}
                        value
                      />
                    }
                    label={
                      <FormattedMessage
                        id="dashboard.forms.ruleform.field_notification_email"
                        defaultMessage="Email"
                      />
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={notifyMobile}
                        onChange={this.handleCheckChange('notifyMobile')}
                        value
                      />
                    }
                    label={
                      <FormattedMessage
                        id="dashboard.forms.ruleform.field_notification_mobile"
                        defaultMessage="Mobile"
                      />
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={notifyWhatsapp}
                        disabled
                        onChange={this.handleCheckChange('notifyWhatsapp')}
                        value
                      />
                    }
                    label={
                      <FormattedMessage
                        id="dashboard.forms.ruleform.field_notification_whatsapp"
                        defaultMessage="WhatsApp"
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            </>
          )}

          {validate && validate.errors && validate.errors.length > 0 ? (
            <SnackbarContent
              message={validate.errors.map((e, index) => (
                <div
                  key={index}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <ErrorIcon />
                  <span style={{ paddingLeft: '5px' }}>
                    {(e.message || '').charAt(0).toUpperCase() +
                      (e.message || '').substring(1)}
                  </span>
                </div>
              ))}
              style={{
                backgroundColor: '#d32f2f',
                marginTop: '30px',
              }}
            />
          ) : null}
          {error !== undefined ? (
            <SnackbarContent
              message={
                error.errorCode === 'InvalidRule' ? (
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <ErrorIcon />
                    <span style={{ paddingLeft: '5px' }}>
                      <FormattedMessage
                        id="dashboard.forms.ruleform.errors_invalidrule"
                        defaultMessage="Invalid rule"
                      />
                    </span>
                  </div>
                ) : (
                  ''
                )
              }
              style={{
                backgroundColor: '#d32f2f',
                marginTop: '30px',
              }}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            <FormattedMessage
              id="dashboard.forms.ruleform.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            disabled={
              name.length === 0 ||
              templateId.length === 0 ||
              isJSONFormValid === false
            }
            onClick={this.handleSubmit}
          >
            {id !== '' ? (
              <FormattedMessage
                id="dashboard.forms.ruleform.button_edit"
                defaultMessage="Edit"
              />
            ) : (
              <FormattedMessage
                id="dashboard.forms.ruleform.button_create"
                defaultMessage="Create"
              />
            )}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default RuleForm;
