import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import React, { Component } from 'react';

import { Date } from '@dashboard_utils/index';
import RangeSlider from '../RangeSlider';

import './DateTimeRangeSlider.css';

const PRECISION_MODIFIER = 10000;

export interface IDateTimeRangeSliderProps {
  disabled?: boolean;
  hotMinDate?: Date;
  hotMaxDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  leftDate: Date;
  rightDate: Date;
  onChange: (leftDate: Date, rightDate: Date) => void;
  warehouseTz: string;
}

interface IState {
  lastProps: IDateTimeRangeSliderProps;
  leftDate: Date;
  rightDate: Date;
}

class DateTimeRangeSlider extends Component<IDateTimeRangeSliderProps, IState> {
  public static getDerivedStateFromProps(
    props: IDateTimeRangeSliderProps,
    state: IState
  ) {
    if (props === state.lastProps) {
      return state;
    }

    return {
      ...state,
      lastProps: props,
      leftDate: props.leftDate,
      rightDate: props.rightDate,
    };
  }

  constructor(props: IDateTimeRangeSliderProps) {
    super(props);

    const { leftDate, rightDate } = props;

    this.state = {
      lastProps: props,
      leftDate,
      rightDate,
    };

    this.handleLeftPickerChange = this.handleLeftPickerChange.bind(this);
    this.handleRightPickerChange = this.handleRightPickerChange.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleSliderSlideEnd = this.handleSliderSlideEnd.bind(this);
  }

  private handleSliderSlideEnd() {
    const { leftDate, rightDate } = this.state;
    const { onChange } = this.props;

    onChange(leftDate, rightDate);
  }

  private handleLeftPickerChange(date: any) {
    const { leftDate, rightDate, onChange } = this.props;

    if (date && !Number.isNaN(date.getTime())) {
      onChange(new Date(leftDate.timeZone, date, leftDate.timeZone), rightDate);
    }
  }

  private handleRightPickerChange(date: any) {
    const { leftDate, rightDate, onChange } = this.props;

    if (date && !Number.isNaN(date.getTime())) {
      onChange(
        leftDate,
        new Date(rightDate.timeZone, date, rightDate.timeZone)
      );
    }
  }

  private handleSliderChange(values: ReadonlyArray<number>) {
    const leftDate = this.calculateDate(values[0]);
    const rightDate = this.calculateDate(values[1]);

    if (
      !Number.isNaN(leftDate.getTime()) &&
      !Number.isNaN(rightDate.getTime())
    ) {
      this.setState({ leftDate, rightDate });
    }
  }

  private calculateSliderValues(dates: Date[]): number[] {
    const { hotMinDate, hotMaxDate } = this.props;

    return dates.map((date) =>
      Math.round(
        hotMaxDate!.getTime() - hotMinDate!.getTime() > 0
          ? ((date.getTime() - hotMinDate!.getTime()) /
              (hotMaxDate!.getTime() - hotMinDate!.getTime())) *
              PRECISION_MODIFIER
          : 0
      )
    );
  }

  private calculateDate(percentage: number) {
    const { hotMinDate, hotMaxDate, warehouseTz } = this.props;

    return new Date(
      warehouseTz,
      Math.round(
        +hotMinDate!.getTime() +
          ((hotMaxDate!.getTime() - hotMinDate!.getTime()) * percentage) /
            PRECISION_MODIFIER
      )
    );
  }

  public render() {
    const { leftDate, rightDate } = this.state;
    const { disabled, minDate, maxDate, warehouseTz } = this.props;

    return (
      <div className="date-time-range-slider-wrapper">
        <MobileDateTimePicker
          value={leftDate.getDateInCurrentZone()}
          maxDate={rightDate.getDateInCurrentZone()}
          minDate={(minDate || new Date(warehouseTz)).getDateInCurrentZone()}
          onChange={this.handleLeftPickerChange}
          slotProps={{
            textField: { variant: 'standard' }
          }}
          ampm={false}
        />
        <RangeSlider
          values={this.calculateSliderValues([leftDate, rightDate])}
          step={1}
          domain={[0, PRECISION_MODIFIER]}
          onChange={this.handleSliderChange}
          onSlideEnd={this.handleSliderSlideEnd}
          disabled={disabled}
        />
        <MobileDateTimePicker
          disabled={disabled}
          maxDate={(maxDate || new Date(warehouseTz)).getDateInCurrentZone()}
          minDate={leftDate.getDateInCurrentZone()}
          value={rightDate.getDateInCurrentZone()}
          onChange={this.handleRightPickerChange}
          slotProps={{
            textField: { variant: 'standard' }
          }}
          ampm={false}
        />
      </div>
    );
  }
}

export default DateTimeRangeSlider;
