import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import React, { Component } from 'react';

import { Notification } from '@models/Notification';
import { formatMessage } from '../../../../../utils/ruleUtils';

import '../index.css';

export interface IProps {
  alert: Notification;
  left: number;
}

interface IState {
  show: boolean;
  positionX: number;
}

class RuleAlert extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      positionX: 0,
      show: false,
    };

    this.showEvent = this.showEvent.bind(this);
    this.moveShowEvent = this.moveShowEvent.bind(this);
  }

  /**
   * @description Display buble information for an given event
   * @param       {any} event Mouse event - mouse enter or mouse leave
   */
  private showEvent = (event: any) => {
    const { show } = this.state;
    const currentTargetRect = event.currentTarget.getBoundingClientRect();

    const currentShow = !show;
    const currentPositionX = event.pageX - currentTargetRect.left;

    this.setState({ positionX: currentPositionX, show: currentShow });
  };

  /**
   * @description Updates event info buble position for an given event
   * @param       {any} event Mouse move
   */
  private moveShowEvent = (event: any) => {
    const currentTargetRect = event.currentTarget.getBoundingClientRect();

    const currentPositionX = event.pageX - currentTargetRect.left;

    this.setState({ positionX: currentPositionX });
  };

  public render() {
    const { alert, left } = this.props;
    const { show, positionX } = this.state;

    let color = '';
    if (alert.level === 'info') {
      color = 'blue';
    } else if (alert.level === 'warn') {
      color = 'orange';
    } else if (alert.level === 'critical') {
      color = 'red';
    }

    let icon = null;
    if (alert.level === 'info') {
      icon = (
        <InfoIcon
          onMouseEnter={this.showEvent}
          onMouseLeave={this.showEvent}
          onMouseMove={this.moveShowEvent}
          style={{
            color,
            height: '16px',
            marginBottom: '-3px',
            width: '16px',
          }}
        />
      );
    } else if (alert.level === 'warn') {
      icon = (
        <WarningIcon
          onMouseEnter={this.showEvent}
          onMouseLeave={this.showEvent}
          onMouseMove={this.moveShowEvent}
          style={{
            color,
            height: '16px',
            marginBottom: '-3px',
            width: '16px',
          }}
        />
      );
    } else if (alert.level === 'critical') {
      icon = (
        <ErrorIcon
          onMouseEnter={this.showEvent}
          onMouseLeave={this.showEvent}
          onMouseMove={this.moveShowEvent}
          style={{
            color,
            height: '16px',
            marginBottom: '-3px',
            width: '16px',
          }}
        />
      );
    }

    let innerIcon = null;
    if (alert.level === 'info') {
      innerIcon = (
        <InfoIcon
          style={{
            height: '16px',
            marginBottom: '-3px',
            width: '16px',
          }}
        />
      );
    } else if (alert.level === 'warn') {
      innerIcon = (
        <WarningIcon
          style={{
            height: '16px',
            marginBottom: '-3px',
            width: '16px',
          }}
        />
      );
    } else if (alert.level === 'critical') {
      innerIcon = (
        <ErrorIcon
          style={{
            height: '16px',
            marginBottom: '-3px',
            width: '16px',
          }}
        />
      );
    }

    return (
      <div className="chunk" style={{ left: `${left}%` }}>
        {icon}

        {show && (
          <div className="alert-info" style={{ left: `${positionX}px` }}>
            <span className="buble" style={{ background: color }}>
              {innerIcon}
              <span style={{ paddingLeft: '3px' }}>
                {formatMessage(alert, false)}
              </span>
            </span>
            <span className="buble-v" style={{ borderTopColor: color }} />
          </div>
        )}
      </div>
    );
  }
}

export default RuleAlert;
