import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import clsx from 'clsx';
import React, { Component } from 'react';
import { format } from 'date-fns';

import { ICreateMessage } from '@actions/chats';
import ChatMessage from '@models/ChatMessage';

import './index.css';

interface IProps {
  accountId: string;
  messages: ChatMessage[];
  loading: boolean;
  createMessage: (props: ICreateMessage) => void;
}
interface IState {
  message?: string;
}

class Chat extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      message: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { loading } = this.props;

    if (loading === false && prevProps.loading === true) {
      const obj = document.getElementsByClassName('chat-container')[0] || {};
      obj.scrollTo({ top: obj.scrollHeight, behavior: 'smooth' });
    }
  }

  public handleChange(key: keyof IState, e: any) {
    this.setState({
      [key]: e.target.value || '',
    });
  }

  public handleMessage(e: any) {
    if (e.which === 13) {
      this.sendMessage();
    }
  }

  public sendMessage() {
    const { accountId, createMessage } = this.props;
    const { message } = this.state;

    if (!message) {
      return;
    }

    this.setState({ message: '' }, () => {
      createMessage({
        toAccountId: accountId,
        message,
      })
    });
  }

  public render() {
    const { message } = this.state;
    const { accountId, loading, messages } = this.props;

    return (
      <>
        <CardContent>
          <div className="chat">
            {messages.reverse().map((m, index) => {
              const chatClass = clsx('chat-bubble', {
                sent: m.toAccountId == accountId,
                received: m.toAccountId != accountId,
                broadcast: m.broadcast,
              });
              const chatIClass = clsx('chat-bubble-i', {
                sent: m.toAccountId == accountId,
                received: m.toAccountId != accountId,
                broadcast: m.broadcast,
              });

              return (
                <>
                  <div key={index} className={chatClass}>
                    <span>{m.message}</span>
                  </div>
                  {!messages[index + 1] ||
                    messages[index + 1].toAccountId !== m.toAccountId ||
                    new Date(m.created).getTime() - new Date(messages[index + 1].created).getTime() > 60 * 1000
                    ? <i className={chatIClass}>{m.broadcast ? 'For everyone ' : ''}{format(new Date(m.created), 'yyyy-MM-dd HH:mm')}</i>
                    : null}
                </>
              );
            })}
          </div>
        </CardContent>
        <CardActions>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Send Message"
              variant="standard"
              value={message || ''}
              onChange={(e) => this.handleChange('message', e)}
              onKeyUp={this.handleMessage}
              placeholder="Send Message"
            />
          </FormControl>
          <IconButton aria-label="send" onClick={this.sendMessage} disabled={loading}>
            <SendIcon />
          </IconButton>
        </CardActions>
      </>
    );
  }
}

export default Chat;