import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { passwordRecovery } from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import PasswordRecoveryPage from './PasswordRecoveryPage';

import './index.css';

const mapStateToProps = (state: IAppState) => ({
  error: state.authentication.passwordRecovery.error,
  language: getActiveLanguage(state),
  loading: state.authentication.passwordRecovery.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    passwordRecovery: (email: string) =>
      dispatch(passwordRecovery.request({ email })),
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordRecoveryPage));
