import MenuItem from '@mui/material/MenuItem';
import MuiPaper from '@mui/material/Paper';
import LayersIcon from '@mui/icons-material/Layers';
import React, { Component } from 'react';

import { IItem, IToolEvent, MapFeature, MapMetricData } from '../../../types';
import Paper from '../../Paper';

interface IProps {
  editFeature?: MapFeature;
  id: string;
  filterId?: string;
  paper: Paper;
  metricData?: MapMetricData;
  toggleZoneMetrics: (id?: string, filterId?: string) => void;
  updateSelectedFeature: (id: string, feature?: MapFeature) => void;
}

interface IState {
  featuredHits?: any[];
  hitPosition?: [number, number];
}

class Hover extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.clickZone = this.clickZone.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }

  public componentDidMount() {
    const { paper } = this.props;
    const { tool } = paper;

    tool.on('mouseup', this.handleMouseUp);
  }

  public componentWillUnmount() {
    const { paper } = this.props;
    const { tool } = paper;

    tool.removeListener('mouseup', this.handleMouseUp);
  }

  public handleMouseUp(event: IToolEvent) {
    const {
      id,
      editFeature,
      filterId,
      paper,
      metricData,
      toggleZoneMetrics,
      updateSelectedFeature,
    } = this.props;
    const { hitPosition } = this.state;

    if (editFeature) {
      return;
    }

    if (hitPosition) {
      this.setState({
        featuredHits: undefined,
        hitPosition: undefined,
      });
    }

    if (!event.item || !event.item.featureInfo) {
      return;
    }

    const isThereMoreHits = paper.scope.project.hitTestAll(event.point);
    const featuredHits = isThereMoreHits.filter((feature) => {
      const item = feature.item as IItem;
      return !!item.featureInfo;
    });

    if (featuredHits.length > 1) {
      this.setState({
        featuredHits,
        hitPosition: [event.event.layerX, event.event.layerY],
      });

      return;
    }

    if (
      (metricData || {}).tab === 'zone_metrics' &&
      event.item.featureInfo.type === 'zone'
    ) {
      toggleZoneMetrics((event.item.featureInfo.props || {}).id, filterId);

      return;
    }

    updateSelectedFeature(id, event.item as MapFeature);
  }

  public clickZone(index: number) {
    const {
      id,
      filterId,
      metricData,
      toggleZoneMetrics,
      updateSelectedFeature,
    } = this.props;
    const { featuredHits } = this.state;

    if (featuredHits && featuredHits[index]) {
      const item = featuredHits[index].item as MapFeature;

      this.setState(
        {
          featuredHits: undefined,
          hitPosition: undefined,
        },
        () => {
          if (
            (metricData || {}).tab === 'zone_metrics' &&
            item.featureInfo.type === 'zone'
          ) {
            toggleZoneMetrics((item.featureInfo.props || {}).id, filterId);

            return;
          }

          updateSelectedFeature(id, item);
        }
      );
    }
  }

  public render() {
    const { featuredHits, hitPosition } = this.state;

    if (!hitPosition || !featuredHits) {
      return null;
    }

    return (
      <MuiPaper
        style={{
          position: 'absolute',
          top: `${hitPosition[1]}px`,
          left: `${hitPosition[0]}px`,
        }}
      >
        {featuredHits.map((fh, index) => {
          const item = fh.item as IItem;

          if (!item || !item.featureInfo) {
            return null;
          }

          return (
            <MenuItem
              key={item.featureInfo.id}
              onClick={() => this.clickZone(index)}
            >
              <LayersIcon color="disabled" />
              <span
                dangerouslySetInnerHTML={{
                  __html: item.featureInfo.title,
                }}
                style={{ marginLeft: '5px' }}
              />
            </MenuItem>
          );
        })}
      </MuiPaper>
    );
  }
}

export default Hover;
