import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import LiveHelp from '@mui/icons-material/LiveHelp';
import React, { Component } from 'react';

import HeatMap from '@models/HeatMap';
import { MetricState } from '@reducers/analytics';

interface IProps {
  metricState: MetricState;
}

interface IState {
  open: boolean;
}

class MapLayerHelp extends Component<IProps, IState> {
  private wrapperRef!: HTMLDivElement;

  constructor(props: IProps) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, {
      passive: true,
    });
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public handleClickOutside(event: any) {
    const { open } = this.state;

    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      open === true
    ) {
      this.setState({ open: false });
    }
  }

  public setWrapperRef(node: HTMLDivElement) {
    this.wrapperRef = node;
  }

  public toggleTooltip() {
    const { open } = this.state;

    this.setState({ open: !open });
  }

  public render() {
    const { open } = this.state;
    const { metricState } = this.props;

    return Object.keys(metricState.data || {})
      .filter((k) => k === 'grid_heatmap')
      .map((k) => {
        const info = (metricState.data[k as 'grid_heatmap'] || {}) as HeatMap;

        const title = (
          <Typography style={{ color: '#FFF', padding: '7px' }} variant="body2">
            {info.help}
          </Typography>
        );

        return (
          !metricState.loading &&
          !metricState.error &&
          info.data !== undefined &&
          info.help !== undefined &&
          info.help !== '' && (
            <Tooltip
              key={k}
              onClick={this.toggleTooltip}
              open={open}
              ref={this.setWrapperRef}
              title={title}
            >
              <IconButton aria-label={info.help}>
                <LiveHelp />
              </IconButton>
            </Tooltip>
          )
        );
      });
  }
}

export default MapLayerHelp;
